import moment from 'moment'

export function dateAndTimeFormatFunction(
  date = moment.utc().clone().add(9, 'hours')
) {
  if (!moment.isMoment(date)) {
    console.error('Provided value is not a Moment object:', date)
    date = moment.utc().add(9, 'hours')
  }
  return date.format('YYYY.MM.DD HH:mm')
}
