import React from 'react';
import styled from 'styled-components';

const RadioInput = styled.input`
  position: absolute;
  opacity: 0;

  &:checked + label {
    background-color: #fff;
    border-color: #ccc;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #000;
    }
  }
`;

const RadioButtonLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 50%;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: transparent;
  }
`;

const RadioBtn = ({ id, value, name, selectedId, setSelectedId, ...props }) => {
  const handleInputChange = (event) => {
    setSelectedId(event.target.value);
  };

  return (
    <>
      <RadioInput
        type="radio"
        id={id}
        value={value}
        name={name}
        checked={selectedId === value.toString()}
        onChange={handleInputChange}
        {...props}
      />
      <RadioButtonLabel htmlFor={id} />
    </>
  );
};

export default RadioBtn;
