import styled from 'styled-components'
import { terms_and_conditions_inner_router_url } from '../../../communication_system/inner_router_url/inner_router_url'
import { c_353431, c_3ed9cc, c_ddc2ab, c_eba55d, c_ffcc96 } from '../../colors'

export const Footer = () => {
  return (
    <FooterContainer>
      <p>Copyright 2023 DOOGOM</p>
      <p
        style={{
          margin: '0 10px',
        }}
      >
        |
      </p>
      <p>All Rights Reserved</p>
      <p
        style={{
          margin: '0 10px',
        }}
      >
        |
      </p>
      <span
        // href={terms_and_conditions_inner_router_url}
        // target="_blank"
        style={{
          textDecoration: 'none',
          color: c_ffcc96,
          cursor: 'pointer',
        }}
        onClick={() => {
          const baseUrl = window.location.origin
          const path = `${terms_and_conditions_inner_router_url}`
          const url = `${baseUrl}${path}`

          window.open(url, '_blank')
        }}
      >
        사이트 이용약관
      </span>
    </FooterContainer>
  )
}

const FooterContainer = styled.div`
  display: inline;
  width: 100%;
  height: 60px;
  min-height: 60px;
  background: #b49275;
  color: #fff;
  font-size: 1rem;
  font-family: Circular Std Book, Tahoma, SansSerif;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  @media (max-width: 650px) {
    display: none;
  }
`
