import { createContext, useContext, useState } from 'react'

// Context 이름 변경
const SelectDefaultGuideHeaderContext = createContext()

// Hook 이름 변경
export const useSelectDefaultGuideHeader = () => {
  return useContext(SelectDefaultGuideHeaderContext)
}

export function SelectDefaultGuideHeaderProvider({ children }) {
  const [selectDefaultGuideHeaderIndex, setSelectDefaultGuideHeaderIndex] =
    useState(
      parseInt(window.localStorage.getItem('selectDefaultGuideHeaderIndex')) ||
        0
    )

  // 함수명 변경
  const setDefaultGuideHeaderIndex = (index) => {
    window.localStorage.setItem('selectDefaultGuideHeaderIndex', index)
    setSelectDefaultGuideHeaderIndex(index)
  }

  const value = {
    selectDefaultGuideHeaderIndex,
    setDefaultGuideHeaderIndex,
  }

  return (
    <SelectDefaultGuideHeaderContext.Provider value={value}>
      {children}
    </SelectDefaultGuideHeaderContext.Provider>
  )
}
