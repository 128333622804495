import { createContext, useContext, useState } from 'react';

const SelectHeaderIndexContext = createContext();

export const useSelectHeader = () => {
  return useContext(SelectHeaderIndexContext);
};

export function SelectHeaderProvider({ children }) {
  const [selectHeaderIndex, setSelectHeaderIndex] = useState(
    parseInt(window.localStorage.getItem('selectHeaderIndex')) || 0,
  );

  const setHeaderIndex = (index) => {
    window.localStorage.setItem('selectHeaderIndex', index);
    setSelectHeaderIndex(index);
  };

  const value = {
    selectHeaderIndex,
    setHeaderIndex,
  };

  return (
    <SelectHeaderIndexContext.Provider value={value}>
      {children}
    </SelectHeaderIndexContext.Provider>
  );
}
