import styled from 'styled-components'
import { MainGuidesContainer } from '../../design_system/components/main_guides_container/MainGuidesContainer'
import { MiddleHeader } from '../../design_system/components/middle_header/MiddleHeader'
import IconTextCircleBtn from '../../design_system/components/btn/pc/icon_text_circle_btn/IconTextCircleBtn'
import { useEffect, useRef, useState } from 'react'
import NormalModal from '../../design_system/components/normal_modal/NormalModal'
import { TitleBigDiv } from '../../design_system/components/title_big_div/TitleBigDiv'
import { TextInput } from '../../design_system/components/text_input/TextInput'
import { generate_UUID } from '../../data_system/data_function/generate_UUID'
import IconTooltip from '../../design_system/components/icon_tooltip/IconTooltip'
import { SettingsItem } from '../../design_system/components/settings_item/SettingsItem'
import { Dropdown } from '../../design_system/components/dropdown/Dropdown'
import StandardModal from '../../design_system/components/standard_modal/StandardModal'
import { TextArea } from '../../design_system/components/text_area/TextArea'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import { show_guide_inner_router_url } from '../../communication_system/inner_router_url/inner_router_url'
import {
  deleteImage,
  deleteTopicApi,
  deleteUserProductByUserProductId,
  getStore,
  getStorePaymentHistories,
  getStorePaymentHistoriesByUserIdForExcel,
  postTopic,
  postUserProduct,
  putTopic,
  putUserProduct,
  uploadImage,
} from '../../communication_system/axios_apis/axios_apis'
import { useLoading } from '../../data_system/context/loading_context/LoadingContext'
import { usePopup } from '../../data_system/context/popup_context/PopupContext'
import { convertToRaw, convertFromRaw, EditorState, Modifier } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useSelectStoreHeader } from '../../data_system/context/select_store_header_index_context/SelectStoreHeaderIndexContext'
import {
  ArrowDownIcon,
  ArrowDownIconWhite,
  CameraIcon,
  CameraIconFixed,
  ExternalLinkIcon,
  PlusIcon,
  QuestionMarkInCircle,
  TrashIconFixed,
  VerticalThreeDotsIcon,
} from '../../design_system/icons/icons'
import { onlyNumberCheck } from '../../data_system/data_function/only_number_check_function'
import { dateAndTimeFormatFunction } from '../../data_system/data_function/date_and_time_format_function'
import { priceFormatFunction } from '../../data_system/data_function/price_format_function'
import { PaymentHistoryLongTile } from '../../design_system/components/payment_history_long_tile/PaymentHistoryLongTile'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { ko } from 'date-fns/locale'
import { saveAs } from 'file-saver'
import * as XLSX from 'xlsx'
import TextCircleBtn from '../../design_system/components/btn/pc/text_circle_btn/TextCircleBtn'
import { formatPhoneNumber } from '../../data_system/data_function/format_phone_number'

const MainGuidesStorePage = () => {
  const { showLoading, hideLoading } = useLoading()
  const { show } = usePopup()
  const { selectStoreHeaderIndex, setStoreHeaderIndex } = useSelectStoreHeader()
  const [userProducts, setUserProducts] = useState([])
  const [addUserProductModalVisible, setAddUserProductModalVisible] =
    useState(false)
  const [editUserProductModalVisible, setEditUserProductModalVisible] =
    useState(false)
  const [deleteUserProductModalVisible, setDeleteUserProductModalVisible] =
    useState(false)
  const [addAddMediaModalVisible, setAddAddMediaModalVisible] = useState(false)
  const [addChangeMediaModalVisible, setAddChangeMediaModalVisible] =
    useState(false)
  const [editAddMediaModalVisible, setEditAddMediaModalVisible] =
    useState(false)
  const [editChangeMediaModalVisible, setEditChangeMediaModalVisible] =
    useState(false)
  const [deleteAddMediaModalVisible, setDeleteAddMediaModalVisible] =
    useState(false)
  const [deleteEditMediaModalVisible, setDeleteEditMediaModalVisible] =
    useState(false)
  const [userProductTitleText, setUserProductTitleText] = useState('')
  const [userProductPriceText, setUserProductPriceText] = useState('')
  const [userProductId, setUserProductId] = useState(null)
  const [imgFile, setImgFile] = useState(null) // 이미지 파일
  const [imgUrlText, setImgUrlText] = useState('')
  const [uploadImgUrl, setUploadImgUrl] = useState('')
  const [defaultGuide, setDefaultGuide] = useState([])
  const [selectedGuideIndex, setSelectedGuideIndex] = useState(0)
  const [previewMenuVisible, setPreviewMenuVisible] = useState(false)

  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [isComposing, setIsComposing] = useState(false)

  const handleEditorChange = (state) => {
    if (!isComposing) {
      setEditorState(state)
    }
  }

  const handleCompositionStart = () => {
    setIsComposing(true)
  }

  const handleCompositionEnd = () => {
    setIsComposing(false)
    // Force update the editor state to ensure compositions are rendered correctly.
    const currentContent = editorState.getCurrentContent()
    const selection = editorState.getSelection()
    const newText = ' ' // Temporary space to force refresh
    const newContent = Modifier.replaceText(currentContent, selection, newText)
    const newEditorState = EditorState.push(
      editorState,
      newContent,
      'insert-characters'
    )
    setEditorState(EditorState.forceSelection(newEditorState, selection))
  }

  function imageBlockRenderer(contentBlock) {
    const type = contentBlock.getType()

    // Convert image type to mediaComponent
    if (type === 'atomic') {
      return {
        component: MediaComponent,
        editable: false,
        props: {
          foo: 'bar',
        },
      }
    }
  }

  // 이미지가 입력될 경우 img 태그로 변환
  function MediaComponent({ block, contentState, blockProps }) {
    const data = contentState.getEntity(block.getEntityAt(0)).getData()
    const emptyHtml = ' '
    return (
      <div>
        {emptyHtml}
        <img
          src={data.src}
          alt={data.alt || ''}
          style={{ height: data.height || 'auto', width: data.width || 'auto' }}
        />
      </div>
    )
  }

  const fileInputRef = useRef(null)

  const store_header_content = [
    {
      id: 0,
      title: '스토어',
    },
    {
      id: 1,
      title: '결제 내역',
    },
  ]

  const addUserProduct = (newUserProductData) => {
    console.log(newUserProductData)
    const newUserProduct = {
      id: newUserProductData.id,
      title: newUserProductData.title,
      content: newUserProductData.content,
      price: newUserProductData.price,
      imgUrl: newUserProductData.imgUrl,
    }
    setUserProducts([...userProducts, newUserProduct])
  }

  const editUserProduct = (userProductId, editUserProductData) => {
    const updatedUserProducts = userProducts.map((userProduct) => {
      if (userProduct.id === userProductId) {
        return {
          ...userProduct,
          title: editUserProductData.title,
          content: editUserProductData.content,
          price: editUserProductData.price,
          imgUrl: editUserProductData.imgUrl,
        }
      }
      return userProduct
    })
    setUserProducts(updatedUserProducts)
  }

  const deleteUserProduct = (userProductId) => {
    const updatedUserProducts = userProducts.filter(
      (userProduct) => userProduct.id !== userProductId
    )
    setUserProducts(updatedUserProducts)
  }

  const toggleUserProductVisibility = (userProductId) => {
    setUserProducts((prevUserProducts) =>
      prevUserProducts.map((userProduct) => {
        if (userProduct.id === userProductId) {
          return {
            ...userProduct,
            menuVisible: !userProduct.menuVisible,
          }
        }
        return {
          ...userProduct,
          menuVisible: false,
        }
      })
    )
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    setImgFile(file)
    console.log(file)
    if (
      file &&
      (file.type === 'image/png' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/jpg')
    ) {
      const localImageUrl = URL.createObjectURL(file)
      console.log(localImageUrl)
      setImgUrlText(localImageUrl)
    } else {
      alert('Please select a valid image (png, jpg, jpeg).')
    }
  }

  const [visible, setVisible] = useState(false)

  const handleVisible = () => {
    setVisible((pre) => !pre)
  }

  const [storePaymentHistories, setStorePaymentHistories] = useState([])

  const [selectedDate, setSelectedDate] = useState(new Date())

  const handleDateChange = (date) => {
    setSelectedDate(date)
  }

  useEffect(() => {
    async function getData() {
      showLoading()
      const response1 = await getStore()
      console.log(response1)
      setUserProducts(response1.data.store.userProducts)
      setDefaultGuide(response1.data.defaultGuide)

      const response2 = await getStorePaymentHistories()
      console.log(response2)
      setStorePaymentHistories(response2.data.storePaymentHistories)

      hideLoading()
    }

    getData()
  }, [])

  return (
    <>
      <MainGuidesContainer>
        <StoreHeader>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <StoreHeaderTitle>기본 스토어</StoreHeaderTitle>
            <div
              style={{
                marginLeft: 10,
              }}
            >
              <IconTooltip
                icon={
                  <QuestionMarkInCircle
                    onClick={handleVisible}
                  ></QuestionMarkInCircle>
                }
                handleClick={handleVisible}
                visible={visible}
                tooltipTop={2.5}
                tooltipLeft={30}
                tooltipDefaultWidth={450}
                tooltipChangeWidth={290}
              >
                <div
                  style={{
                    padding: 20,
                  }}
                >
                  기본 스토어는 판매할 상품과 서비스 목록을 등록하는 곳입니다.
                </div>
              </IconTooltip>
            </div>
          </div>
        </StoreHeader>
        <MiddleHeader
          selectHeaderIndex={selectStoreHeaderIndex}
          headerContent={store_header_content}
          setHeaderIndex={setStoreHeaderIndex}
          rightSideVisible={true}
          rightSideComponents={
            <div
              style={{
                paddingBottom: 20,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {/* <IconTooltip
                icon={

                }
                visible={previewMenuVisible}
                handleClick={() => {
                  setPreviewMenuVisible(!previewMenuVisible)
                }}
                tooltipDefaultWidth={160}
                tooltipChangeWidth={160}
                tooltipTop={30}
                tooltipRight={0}
              >
                <SettingsItem
                  key={defaultGuide.id}
                  style={{
                    padding: '10px 20px',
                  }}
                  onClick={(e) => {
                    
                  }}
                >
                  {defaultGuide.guideName}
                </SettingsItem>
              </IconTooltip> */}
              {/* <IconTextCircleBtn
                text={'미리보기'}
                borderColor={'#b49275'}
                backgroundColor={'#b49275'}
                textColor={'#fff'}
                onClick={() => {
                  window.open(defaultGuide.shareUrl, '_blank')
                }}
              >
                <ExternalLinkIcon></ExternalLinkIcon>
              </IconTextCircleBtn> */}
            </div>
          }
        ></MiddleHeader>
        {selectStoreHeaderIndex === 0 && (
          <StoreContentBody1>
            <IconTextCircleBtn
              text={'서비스 추가하기'}
              borderColor={'#b49275'}
              backgroundColor={'#b49275'}
              textColor={'#fff'}
              onClick={() => {
                setUserProductTitleText('')
                setEditorState(EditorState.createEmpty())
                setUserProductPriceText('')
                setImgUrlText('')
                setUploadImgUrl('')
                setImgFile(null)
                setAddUserProductModalVisible(true)
              }}
            >
              <PlusIcon></PlusIcon>
            </IconTextCircleBtn>
          </StoreContentBody1>
        )}
        {selectStoreHeaderIndex === 1 && (
          <StoreContentBody1>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                dateFormat="yyyy/MM"
                showMonthYearPicker
                locale={ko}
                customInput={
                  <TextCircleBtn
                    text={
                      selectedDate
                        ? `${selectedDate.getFullYear()}/${
                            selectedDate.getMonth() + 1
                          }`
                        : 'Select Month'
                    }
                    borderColor={'#b49275'}
                    backgroundColor={'#b49275'}
                    textColor={'#fff'}
                  ></TextCircleBtn>
                }
              />
              <div
                style={{
                  width: 10,
                }}
              ></div>
              <IconTextCircleBtn
                text={'엑셀 다운로드'}
                borderColor={'#b49275'}
                backgroundColor={'#b49275'}
                textColor={'#fff'}
                onClick={async () => {
                  const year = selectedDate.getFullYear()
                  const month = selectedDate.getMonth() + 1
                  const response =
                    await getStorePaymentHistoriesByUserIdForExcel(year, month)

                  console.log(response.data.storePaymentHistories)
                  const customData = response.data.storePaymentHistories.map(
                    (row, index) => [
                      index + 1,
                      dateAndTimeFormatFunction(
                        moment(row.createdAt).add(-9, 'hours')
                      ),
                      row.payerName,
                      row.payerEmail,
                      row.payerHp,
                      row.userProduct.title,
                      row.number,
                      row.userProduct.price,
                      row.totalPrice,
                      row.paymentTypeId === 2
                        ? '국내카드'
                        : row.paymentTypeId === 4
                        ? '해외카드'
                        : '',
                    ]
                  )
                  // 첫 번째 행에 열 제목을 추가
                  const worksheet = XLSX.utils.aoa_to_sheet([
                    [
                      'No.',
                      '날짜',
                      '결제자(이름)',
                      '결제자 이메일',
                      '결제자 핸드폰 번호',
                      '상품명',
                      '판매 개수',
                      '가격',
                      '총 금액',
                      '결제수단',
                    ],
                  ])
                  XLSX.utils.sheet_add_aoa(worksheet, customData, {
                    origin: -1,
                  })
                  const workbook = XLSX.utils.book_new()
                  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
                  // 열 너비 설정 (선택 사항)
                  const colWidths = [
                    { wpx: 50 },
                    { wpx: 150 },
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 50 },
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 },
                  ]
                  worksheet['!cols'] = colWidths
                  const excelBuffer = XLSX.write(workbook, {
                    bookType: 'xlsx',
                    type: 'array',
                  })
                  const blob = new Blob([excelBuffer], {
                    type: 'application/octet-stream',
                  })
                  saveAs(blob, 'payments.xlsx')
                }}
              >
                <ArrowDownIconWhite></ArrowDownIconWhite>
              </IconTextCircleBtn>
            </div>
          </StoreContentBody1>
        )}
        {selectStoreHeaderIndex === 0 && (
          <StoreContentBody2>
            <StoreContentBody2Inner>
              <Container>
                {userProducts.map((userProduct) => (
                  <div key={userProduct.id}>
                    <AccordionButton
                      onClick={(e) => {
                        e.preventDefault()
                      }}
                    >
                      <span>{userProduct.title}</span>
                      <IconContainer>
                        <IconTooltip
                          icon={
                            <VerticalThreeDotsIcon
                              onClick={() => {
                                toggleUserProductVisibility(userProduct.id)
                              }}
                            ></VerticalThreeDotsIcon>
                          }
                          visible={userProduct.menuVisible}
                          handleClick={() => {
                            toggleUserProductVisibility(userProduct.id)
                          }}
                          tooltipDefaultWidth={160}
                          tooltipChangeWidth={160}
                          tooltipTop={30}
                          tooltipRight={0}
                        >
                          <SettingsItem
                            style={{
                              padding: '10px 20px',
                            }}
                            onClick={(e) => {
                              console.log(userProduct)
                              setUserProductId(userProduct.id)
                              setUserProductTitleText(userProduct.title)
                              const rawContentFromServer = JSON.parse(
                                userProduct.content
                              )
                              const contentState =
                                convertFromRaw(rawContentFromServer)
                              const newEditorState =
                                EditorState.createWithContent(contentState)
                              setEditorState(newEditorState)
                              setUserProductPriceText(
                                userProduct.price.toString()
                              )
                              setImgUrlText(userProduct.imgUrl)
                              setUploadImgUrl(userProduct.imgUrl)
                              setImgFile(null)
                              setEditUserProductModalVisible(true)
                            }}
                          >
                            서비스 수정하기
                          </SettingsItem>
                          <SettingsItem
                            style={{
                              padding: '10px 20px',
                            }}
                            onClick={(e) => {
                              setUserProductId(userProduct.id)
                              setDeleteUserProductModalVisible(true)
                            }}
                          >
                            서비스 삭제하기
                          </SettingsItem>
                        </IconTooltip>
                      </IconContainer>
                    </AccordionButton>
                  </div>
                ))}
                <BigDashedButton
                  onClick={() => {
                    setUserProductTitleText('')
                    setEditorState(EditorState.createEmpty())
                    setUserProductPriceText('')
                    setImgUrlText('')
                    setUploadImgUrl('')
                    setImgFile(null)
                    setAddUserProductModalVisible(true)
                  }}
                >
                  서비스 추가하기
                </BigDashedButton>
              </Container>
            </StoreContentBody2Inner>
          </StoreContentBody2>
        )}
        {selectStoreHeaderIndex === 1 && (
          <StoreContentBody2>
            <StoreContentBody2Inner>
              <Container>
                <div>
                  {storePaymentHistories.map((storePaymentHistory) => {
                    return (
                      <PaymentHistoryLongTile
                        key={storePaymentHistory.id}
                        title={
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                            }}
                          >
                            <div>
                              날짜:{' '}
                              {dateAndTimeFormatFunction(
                                moment
                                  .utc(storePaymentHistory.createdAt)
                                  .clone()
                              )}
                            </div>
                            <br></br>
                            <div>결제자: {storePaymentHistory.payerName}</div>
                            <br></br>
                            <div>
                              결제자 이메일: {storePaymentHistory.payerEmail}
                            </div>
                            <br></br>
                            <div>
                              결제자 핸드폰 번호: {storePaymentHistory.payerHp}
                            </div>
                            <br></br>
                            <div>
                              상품명: {storePaymentHistory.userProduct.title}
                            </div>
                            <br></br>
                            <div>판매 개수: {storePaymentHistory.number}</div>
                            <br></br>
                            <div>
                              가격:{' '}
                              {priceFormatFunction(
                                storePaymentHistory.userProduct.price
                              )}
                            </div>
                            <br></br>
                            <div>
                              총 금액:{' '}
                              {priceFormatFunction(
                                storePaymentHistory.totalPrice
                              )}
                            </div>
                            <br></br>
                            <div>
                              결제수단:{' '}
                              <span>
                                {storePaymentHistory.paymentTypeId === 2
                                  ? '국내카드'
                                  : storePaymentHistory.paymentTypeId === 3
                                  ? '계좌이체'
                                  : storePaymentHistory.paymentTypeId === 4
                                  ? '해외카드'
                                  : ''}
                              </span>
                            </div>
                          </div>
                        }
                        btnSection={<></>}
                        questionVisible={false}
                        questionSection={<></>}
                      ></PaymentHistoryLongTile>
                    )
                  })}
                </div>
              </Container>
            </StoreContentBody2Inner>
          </StoreContentBody2>
        )}
      </MainGuidesContainer>
      {addUserProductModalVisible && (
        <NormalModal
          titleText={`서비스 추가하기`}
          leftBtnVisible={true}
          modalBodyComponent={
            <div>
              <TitleBigDiv>제목</TitleBigDiv>
              <TextInput
                value={userProductTitleText}
                onChange={(e) => {
                  setUserProductTitleText(e.target.value)
                }}
                backgroundColor={'#ffffff'}
              ></TextInput>
              <TitleBigDiv>내용</TitleBigDiv>
              <EditorTotalDiv>
                <Editor
                  editorState={editorState}
                  onEditorStateChange={handleEditorChange}
                  onCompositionStart={handleCompositionStart}
                  onCompositionEnd={handleCompositionEnd}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  customBlockRenderFunc={imageBlockRenderer}
                />
              </EditorTotalDiv>
              <TitleBigDiv>가격(원)</TitleBigDiv>
              <TextInput
                value={userProductPriceText}
                onChange={(e) => {
                  if (
                    onlyNumberCheck.test(e.target.value) ||
                    e.target.value === ''
                  ) {
                    setUserProductPriceText(e.target.value)
                  }
                }}
                backgroundColor={'#ffffff'}
              ></TextInput>
              <TitleBigDiv>대표 이미지</TitleBigDiv>
              {imgUrlText === '' || imgUrlText === null ? (
                <IconTextCircleBtn
                  text={'이미지 첨부'}
                  borderColor={'#ededed'}
                  backgroundColor={'#ededed'}
                  textColor={'#000'}
                  onClick={() => {
                    setAddAddMediaModalVisible(true)
                  }}
                >
                  <CameraIconFixed></CameraIconFixed>
                </IconTextCircleBtn>
              ) : (
                <div
                  style={{
                    height: 200,
                    color: '#d3d3d3',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat',
                    boxSizing: 'border-box',
                    backgroundImage: `url(${imgUrlText || 'unknown'})`,
                    position: 'relative',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      position: 'absolute',
                      left: 10,
                      bottom: 10,
                    }}
                  >
                    <IconTextCircleBtn
                      text={'수정하기'}
                      borderColor={'#ededed'}
                      backgroundColor={'#ededed'}
                      textColor={'#000'}
                      onClick={() => {
                        setAddChangeMediaModalVisible(true)
                      }}
                    >
                      <CameraIconFixed></CameraIconFixed>
                    </IconTextCircleBtn>
                    <div
                      style={{
                        marginLeft: 10,
                      }}
                    >
                      <IconTextCircleBtn
                        text={'삭제하기'}
                        borderColor={'#f56c6c'}
                        backgroundColor={'#f56c6c'}
                        textColor={'#fff'}
                        onClick={() => {
                          setDeleteAddMediaModalVisible(true)
                        }}
                      >
                        <TrashIconFixed></TrashIconFixed>
                      </IconTextCircleBtn>
                    </div>
                  </div>
                </div>
              )}
            </div>
          }
          onRightBtnClick={async () => {
            showLoading()

            const content = editorState.getCurrentContent()
            const rawContent = convertToRaw(content)
            const contentString = JSON.stringify(rawContent)

            if (userProductTitleText !== '' && userProductPriceText !== '') {
              const response = await postUserProduct({
                title: userProductTitleText,
                content: contentString,
                price: parseInt(userProductPriceText),
                imgUrl: uploadImgUrl,
              })

              console.log(response)
              if (response.status === 201) {
                addUserProduct({
                  title: userProductTitleText,
                  content: contentString,
                  price: parseInt(userProductPriceText),
                  imgUrl: imgUrlText,
                  id: response.data.newUserProduct.id,
                })

                setUserProductTitleText('')
                setEditorState(EditorState.createEmpty())
                setUserProductPriceText('')
                setImgUrlText('')
                setUploadImgUrl('')
                setImgFile(null)
                setAddUserProductModalVisible(false)
              }
            }

            hideLoading()
          }}
          onClose={async () => {
            if (uploadImgUrl !== '' && uploadImgUrl !== null) {
              showLoading()

              const deleteImageResponse = await deleteImage(uploadImgUrl)

              if (deleteImageResponse.status === 200) {
                setUserProductTitleText('')
                setEditorState(EditorState.createEmpty())
                setUserProductPriceText('')
                setImgUrlText('')
                setUploadImgUrl('')
                setImgFile(null)
                setAddUserProductModalVisible(false)
              }

              hideLoading()
            } else {
              setUserProductTitleText('')
              setEditorState(EditorState.createEmpty())
              setUserProductPriceText('')
              setImgUrlText('')
              setUploadImgUrl('')
              setImgFile(null)
              setAddUserProductModalVisible(false)
            }
          }}
          rightBtnText={'저장'}
          rightBtnDisabled={
            userProductTitleText === '' || userProductPriceText === ''
          }
          questionSection={<p>---</p>}
          questionDefaultWidth={500}
          questionChangeWidth={180}
        ></NormalModal>
      )}
      {editUserProductModalVisible && (
        <NormalModal
          titleText={`서비스 수정하기`}
          leftBtnVisible={true}
          modalBodyComponent={
            <div>
              <TitleBigDiv>제목</TitleBigDiv>
              <TextInput
                value={userProductTitleText}
                onChange={(e) => {
                  setUserProductTitleText(e.target.value)
                }}
                backgroundColor={'#ffffff'}
              ></TextInput>
              <TitleBigDiv>내용</TitleBigDiv>
              <EditorTotalDiv>
                <Editor
                  editorState={editorState}
                  onEditorStateChange={handleEditorChange}
                  onCompositionStart={handleCompositionStart}
                  onCompositionEnd={handleCompositionEnd}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  customBlockRenderFunc={imageBlockRenderer}
                />
              </EditorTotalDiv>
              <TitleBigDiv>가격(원)</TitleBigDiv>
              <TextInput
                value={userProductPriceText}
                onChange={(e) => {
                  if (
                    onlyNumberCheck.test(e.target.value) ||
                    e.target.value === ''
                  ) {
                    setUserProductPriceText(e.target.value)
                  }
                }}
                backgroundColor={'#ffffff'}
              ></TextInput>
              <TitleBigDiv>대표 이미지</TitleBigDiv>
              {imgUrlText === '' || imgUrlText === null ? (
                <IconTextCircleBtn
                  text={'이미지 첨부'}
                  borderColor={'#ededed'}
                  backgroundColor={'#ededed'}
                  textColor={'#000'}
                  onClick={() => {
                    setEditAddMediaModalVisible(true)
                  }}
                >
                  <CameraIconFixed></CameraIconFixed>
                </IconTextCircleBtn>
              ) : (
                <div
                  style={{
                    height: 200,
                    color: '#d3d3d3',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat',
                    boxSizing: 'border-box',
                    backgroundImage: `url(${imgUrlText || 'unknown'})`,
                    position: 'relative',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      position: 'absolute',
                      left: 10,
                      bottom: 10,
                    }}
                  >
                    <IconTextCircleBtn
                      text={'수정하기'}
                      borderColor={'#ededed'}
                      backgroundColor={'#ededed'}
                      textColor={'#000'}
                      onClick={() => {
                        setEditChangeMediaModalVisible(true)
                      }}
                    >
                      <CameraIconFixed></CameraIconFixed>
                    </IconTextCircleBtn>
                    <div
                      style={{
                        marginLeft: 10,
                      }}
                    >
                      <IconTextCircleBtn
                        text={'삭제하기'}
                        borderColor={'#f56c6c'}
                        backgroundColor={'#f56c6c'}
                        textColor={'#fff'}
                        onClick={() => {
                          setDeleteEditMediaModalVisible(true)
                        }}
                      >
                        <TrashIconFixed></TrashIconFixed>
                      </IconTextCircleBtn>
                    </div>
                  </div>
                </div>
              )}
            </div>
          }
          onRightBtnClick={async () => {
            showLoading()

            const content = editorState.getCurrentContent()
            const rawContent = convertToRaw(content)
            const contentString = JSON.stringify(rawContent)

            if (userProductTitleText !== '' && userProductPriceText !== '') {
              const response = await putUserProduct(userProductId, {
                title: userProductTitleText,
                content: contentString,
                price: parseInt(userProductPriceText),
                imgUrl: uploadImgUrl,
              })

              if (response.status === 200) {
                editUserProduct(userProductId, {
                  title: userProductTitleText,
                  content: contentString,
                  price: parseInt(userProductPriceText),
                  imgUrl: imgUrlText,
                })
                setUserProductTitleText('')
                setEditorState(EditorState.createEmpty())
                setUserProductPriceText('')
                setImgUrlText('')
                setUploadImgUrl('')
                setImgFile(null)
                setEditUserProductModalVisible(false)
              }
            }

            hideLoading()
          }}
          onClose={async () => {
            setUserProductTitleText('')
            setEditorState(EditorState.createEmpty())
            setUserProductPriceText('')
            setImgUrlText('')
            setUploadImgUrl('')
            setImgFile(null)
            setEditUserProductModalVisible(false)
          }}
          rightBtnText={'저장'}
          rightBtnDisabled={
            userProductTitleText === '' || userProductPriceText === ''
          }
          questionSection={<p>---</p>}
          questionDefaultWidth={500}
          questionChangeWidth={180}
        ></NormalModal>
      )}
      {deleteUserProductModalVisible && (
        <StandardModal
          titleText={'이 서비스를 삭제 하시겠습니까?'}
          modalBodyComponent={<div>서비스의 모든 내용이 삭제 됩니다.</div>}
          onRightBtnClick={async () => {
            showLoading()

            const response = await deleteUserProductByUserProductId(
              userProductId
            )

            if (response.status === 200) {
              deleteUserProduct(userProductId)
              setDeleteUserProductModalVisible(false)
            }

            hideLoading()
          }}
          onClose={() => {
            setDeleteUserProductModalVisible(false)
          }}
          rightBtnText={'삭제'}
        ></StandardModal>
      )}
      {addAddMediaModalVisible && (
        <NormalModal
          titleText={`이미지 첨부`}
          leftBtnVisible={true}
          modalBodyComponent={
            <div>
              <div
                style={{
                  height: 175,
                  color: '#d3d3d3',
                  backgroundSize: 'cover',
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundPosition: 'center center',
                  backgroundRepeat: 'no-repeat',
                  alignItems: 'center',
                  justifyContent: 'center',
                  boxSizing: 'border-box',
                  backgroundImage: `url(${imgUrlText || 'unknown'})`,
                }}
              >
                {imgUrlText === '' || imgUrlText === null ? (
                  <CameraIcon></CameraIcon>
                ) : (
                  <></>
                )}
              </div>
              <TitleBigDiv>이미지 삽입</TitleBigDiv>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <input
                  type="file"
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  accept=".png, .jpg, .jpeg"
                />
                <IconTextCircleBtn
                  text={'이미지 첨부'}
                  borderColor={'#ededed'}
                  backgroundColor={'#ededed'}
                  textColor={'#000'}
                  onClick={() => {
                    fileInputRef.current.click()
                  }}
                >
                  <CameraIconFixed></CameraIconFixed>
                </IconTextCircleBtn>
              </div>
            </div>
          }
          onRightBtnClick={async () => {
            showLoading()

            const imageUploadResponse = await uploadImage(imgFile)
            console.log(imageUploadResponse)

            if (imageUploadResponse.status === 200) {
              setImgUrlText(imageUploadResponse.data.imageUrl)
              setUploadImgUrl(imageUploadResponse.data.imageUrl)
              setImgFile(null)
              setAddAddMediaModalVisible(false)
            }

            hideLoading()
          }}
          onClose={() => {
            setImgUrlText('')
            setUploadImgUrl('')
            setImgFile(null)
            setAddAddMediaModalVisible(false)
          }}
          rightBtnText={'저장'}
          rightBtnDisabled={false}
          questionSection={<p>---</p>}
          questionDefaultWidth={500}
          questionChangeWidth={180}
        ></NormalModal>
      )}
      {addChangeMediaModalVisible && (
        <NormalModal
          titleText={`Change Media`}
          leftBtnVisible={true}
          modalBodyComponent={
            <div>
              <div
                style={{
                  height: 175,
                  color: '#d3d3d3',
                  backgroundSize: 'cover',
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundPosition: 'center center',
                  backgroundRepeat: 'no-repeat',
                  alignItems: 'center',
                  justifyContent: 'center',
                  boxSizing: 'border-box',
                  backgroundImage: `url(${imgUrlText || 'unknown'})`,
                }}
              >
                {imgUrlText === '' || imgUrlText === null ? (
                  <CameraIcon></CameraIcon>
                ) : (
                  <></>
                )}
              </div>
              <TitleBigDiv>이미지 삽입</TitleBigDiv>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <input
                  type="file"
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  accept=".png, .jpg, .jpeg"
                />
                <IconTextCircleBtn
                  text={'이미지 첨부'}
                  borderColor={'#ededed'}
                  backgroundColor={'#ededed'}
                  textColor={'#000'}
                  onClick={() => {
                    fileInputRef.current.click()
                  }}
                >
                  <CameraIconFixed></CameraIconFixed>
                </IconTextCircleBtn>
              </div>
            </div>
          }
          onRightBtnClick={async () => {
            if (imgFile !== null) {
              showLoading()

              const imageDeleteResponse = await deleteImage(uploadImgUrl)

              if (imageDeleteResponse.status === 200) {
                const imageUploadResponse = await uploadImage(imgFile)
                console.log(imageUploadResponse)

                if (imageUploadResponse.status === 200) {
                  setImgUrlText(imageUploadResponse.data.imageUrl)
                  setUploadImgUrl(imageUploadResponse.data.imageUrl)
                  setImgFile(null)
                  setAddChangeMediaModalVisible(false)
                }
              }

              hideLoading()
            } else {
              showLoading()

              setImgUrlText('')
              setUploadImgUrl('')
              setImgFile(null)
              setAddChangeMediaModalVisible(false)

              hideLoading()
            }
          }}
          onClose={() => {
            setImgUrlText(uploadImgUrl)
            setImgFile(null)
            setAddChangeMediaModalVisible(false)
          }}
          rightBtnText={'저장'}
          rightBtnDisabled={false}
          questionSection={<p>---</p>}
          questionDefaultWidth={500}
          questionChangeWidth={180}
        ></NormalModal>
      )}
      {editAddMediaModalVisible && (
        <NormalModal
          titleText={`이미지 첨부`}
          leftBtnVisible={true}
          modalBodyComponent={
            <div>
              <div
                style={{
                  height: 175,
                  color: '#d3d3d3',
                  backgroundSize: 'cover',
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundPosition: 'center center',
                  backgroundRepeat: 'no-repeat',
                  alignItems: 'center',
                  justifyContent: 'center',
                  boxSizing: 'border-box',
                  backgroundImage: `url(${imgUrlText || 'unknown'})`,
                }}
              >
                {imgUrlText === '' || imgUrlText === null ? (
                  <CameraIcon></CameraIcon>
                ) : (
                  <></>
                )}
              </div>
              <TitleBigDiv>이미지 삽입</TitleBigDiv>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <input
                  type="file"
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  accept=".png, .jpg, .jpeg"
                />
                <IconTextCircleBtn
                  text={'이미지 첨부'}
                  borderColor={'#ededed'}
                  backgroundColor={'#ededed'}
                  textColor={'#000'}
                  onClick={() => {
                    fileInputRef.current.click()
                  }}
                >
                  <CameraIconFixed></CameraIconFixed>
                </IconTextCircleBtn>
              </div>
            </div>
          }
          onRightBtnClick={async () => {
            showLoading()

            const imageUploadResponse = await uploadImage(imgFile)
            console.log(imageUploadResponse)

            if (imageUploadResponse.status === 200) {
              setImgUrlText(imageUploadResponse.data.imageUrl)
              setUploadImgUrl(imageUploadResponse.data.imageUrl)
              setImgFile(null)
              setEditAddMediaModalVisible(false)
            }

            hideLoading()
          }}
          onClose={() => {
            setImgUrlText('')
            setUploadImgUrl('')
            setImgFile(null)
            setEditAddMediaModalVisible(false)
          }}
          rightBtnText={'저장'}
          rightBtnDisabled={false}
          questionSection={<p>---</p>}
          questionDefaultWidth={500}
          questionChangeWidth={180}
        ></NormalModal>
      )}
      {editChangeMediaModalVisible && (
        <NormalModal
          titleText={`이미지 변경`}
          leftBtnVisible={true}
          modalBodyComponent={
            <div>
              <div
                style={{
                  height: 175,
                  color: '#d3d3d3',
                  backgroundSize: 'cover',
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundPosition: 'center center',
                  backgroundRepeat: 'no-repeat',
                  alignItems: 'center',
                  justifyContent: 'center',
                  boxSizing: 'border-box',
                  backgroundImage: `url(${imgUrlText || 'unknown'})`,
                }}
              >
                {imgUrlText === '' || imgUrlText === null ? (
                  <CameraIcon></CameraIcon>
                ) : (
                  <></>
                )}
              </div>
              <TitleBigDiv>이미지 삽입</TitleBigDiv>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <input
                  type="file"
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  accept=".png, .jpg, .jpeg"
                />
                <IconTextCircleBtn
                  text={'이미지 첨부'}
                  borderColor={'#ededed'}
                  backgroundColor={'#ededed'}
                  textColor={'#000'}
                  onClick={() => {
                    fileInputRef.current.click()
                  }}
                >
                  <CameraIconFixed></CameraIconFixed>
                </IconTextCircleBtn>
              </div>
            </div>
          }
          onRightBtnClick={async () => {
            if (imgFile !== null) {
              showLoading()

              const imageDeleteResponse = await deleteImage(uploadImgUrl)

              if (imageDeleteResponse.status === 200) {
                const imageUploadResponse = await uploadImage(imgFile)
                console.log(imageUploadResponse)

                if (imageUploadResponse.status === 200) {
                  setImgUrlText(imageUploadResponse.data.imageUrl)
                  setUploadImgUrl(imageUploadResponse.data.imageUrl)
                  setImgFile(null)
                  setEditChangeMediaModalVisible(false)
                }
              }

              hideLoading()
            } else {
              showLoading()

              setImgUrlText(uploadImgUrl)
              setImgFile(null)
              setEditChangeMediaModalVisible(false)

              hideLoading()
            }
          }}
          onClose={() => {
            setImgUrlText(uploadImgUrl)
            setImgFile(null)
            setEditChangeMediaModalVisible(false)
          }}
          rightBtnText={'저장'}
          rightBtnDisabled={false}
          questionSection={<p>---</p>}
          questionDefaultWidth={500}
          questionChangeWidth={180}
        ></NormalModal>
      )}
      {deleteAddMediaModalVisible && (
        <StandardModal
          titleText={'이미지 삭제'}
          modalBodyComponent={<div>이미지를 삭제하시겠습니까?</div>}
          onRightBtnClick={async () => {
            showLoading()

            const imageDeleteResponse = await deleteImage(uploadImgUrl)
            console.log(imageDeleteResponse)

            if (imageDeleteResponse.status === 200) {
              setImgUrlText('')
              setUploadImgUrl('')
              setImgFile(null)
              setDeleteAddMediaModalVisible(false)
            }

            hideLoading()
          }}
          onClose={() => {
            setDeleteAddMediaModalVisible(false)
          }}
          rightBtnText={'삭제'}
        ></StandardModal>
      )}
      {deleteEditMediaModalVisible && (
        <StandardModal
          titleText={'이미지 삭제'}
          modalBodyComponent={<div>이미지를 삭제하시겠습니까?</div>}
          onRightBtnClick={async () => {
            showLoading()

            const imageDeleteResponse = await deleteImage(uploadImgUrl)
            console.log(imageDeleteResponse)

            if (imageDeleteResponse.status === 200) {
              setImgUrlText('')
              setUploadImgUrl('')
              setImgFile(null)
              setDeleteEditMediaModalVisible(false)
            }

            hideLoading()
          }}
          onClose={() => {
            setDeleteEditMediaModalVisible(false)
          }}
          rightBtnText={'삭제'}
        ></StandardModal>
      )}
    </>
  )
}

export default MainGuidesStorePage

const EditorTotalDiv = styled.div`
  .editorClassName {
    height: 250px; /* 높이 설정 */
    overflow: auto; /* 내용이 넘칠 때 스크롤바 자동 생성 */
    border: 1px solid #ddd; /* 에디터 테두리 (선택사항) */
    padding: 10px; /* 내부 여백 (선택사항) */
    border: 1px solid #ccc;
  }

  .rdw-dropdownoption-default.rdw-fontsize-option {
    font-size: 12px;
    width: 30px;
  }

  .rdw-colorpicker-modal-style-label {
    font-size: 12px;
  }
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

const Container = styled.div`
  margin-top: 10px;
  width: 100%;
  margin-bottom: 10px;
`

const AccordionButton = styled.div`
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  background-color: #b49275;
  color: white;
  padding: 20px 10px;
  border-radius: 5px;
  cursor: pointer;
`

const BigDashedButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #b49275;
  color: #b49275;
  padding: 20px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: #b49275;
    color: white;
  }
`

const StoreHeader = styled.div`
  padding-left: 10%;
  padding-right: 10%;
  background-color: #fff;
  height: 100px;
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  align-items: center;
  justify-content: space-between;
  display: flex;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    padding-left: 15px;
    padding-right: 15px;
    height: 70px;
  }
`

const StoreHeaderTitle = styled.div`
  font-size: 2.25rem;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    font-size: 1.5rem;
    line-height: 3.75rem;
  }
`

const StoreContentBody1 = styled.div`
  height: 60px;
  background-color: #f8f8f8;
  padding: 10px 10%;
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  border-bottom: 1px solid #e1e1e1;
  align-items: center;
  justify-content: space-between;
  display: flex;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    background-color: inherit;
    padding: 20px;
    border-bottom: none;
  }
`

const StoreContentBody2 = styled.div`
  position: relative;
  padding: 0 10%;
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-basis: auto;
  overflow-y: auto;
  box-sizing: border-box;
  align-items: center;

  @media (max-width: 1024px) {
    padding: 0;
  }
`

const StoreContentBody2Inner = styled.div`
  position: absolute;
  top: 0;
  width: 80%;
  padding: 0;

  @media (max-width: 1024px) {
    width: 95%;
  }
`
