const imageUrls = [
  'https://doogomimages3.s3.ap-northeast-2.amazonaws.com/default_guide/1.(1)welcome.jpg',
  'https://doogomimages3.s3.ap-northeast-2.amazonaws.com/default_guide/1.(2)A.drivingdirection.jpg',
  'https://doogomimages3.s3.ap-northeast-2.amazonaws.com/default_guide/1.(2)B.publictransport.jpg',
  'https://doogomimages3.s3.ap-northeast-2.amazonaws.com/default_guide/1.(3)checkintime.jpg',
  'https://doogomimages3.s3.ap-northeast-2.amazonaws.com/default_guide/1.(3)checkininstructions.jpg',
  'https://doogomimages3.s3.ap-northeast-2.amazonaws.com/default_guide/1.(4)parking.jpg',
  'https://doogomimages3.s3.ap-northeast-2.amazonaws.com/default_guide/2.wifidetails.jpg',
  'https://doogomimages3.s3.ap-northeast-2.amazonaws.com/default_guide/3.(1)wharweprovide.jpg',
  'https://doogomimages3.s3.ap-northeast-2.amazonaws.com/default_guide/3.(2)A.heating.jpg',
  'https://doogomimages3.s3.ap-northeast-2.amazonaws.com/default_guide/3.(2)B.cooling.jpg',
  'https://doogomimages3.s3.ap-northeast-2.amazonaws.com/default_guide/3.(2)C.hotwater.jpg',
  'https://doogomimages3.s3.ap-northeast-2.amazonaws.com/default_guide/3.(3)A.kitchenequipment.jpg',
  'https://doogomimages3.s3.ap-northeast-2.amazonaws.com/default_guide/3.(3)B.dishwasher.jpg',
  'https://doogomimages3.s3.ap-northeast-2.amazonaws.com/default_guide/3.(3)C.oven.jpg',
  'https://doogomimages3.s3.ap-northeast-2.amazonaws.com/default_guide/3.(3)D.induction.jpg',
  'https://doogomimages3.s3.ap-northeast-2.amazonaws.com/default_guide/3.(3)E.laundry.jpg',
  'https://doogomimages3.s3.ap-northeast-2.amazonaws.com/default_guide/3.(4)A.generalwaste.jpg',
  'https://doogomimages3.s3.ap-northeast-2.amazonaws.com/default_guide/3.(4)B.recylce.jpg',
  'https://doogomimages3.s3.ap-northeast-2.amazonaws.com/default_guide/3.(4)C.foodwaste.jpg',
  'https://doogomimages3.s3.ap-northeast-2.amazonaws.com/default_guide/3.(5)tv.jpg',
  'https://doogomimages3.s3.ap-northeast-2.amazonaws.com/default_guide/4.(1)A.smokingpolicy.jpg',
  'https://doogomimages3.s3.ap-northeast-2.amazonaws.com/default_guide/4.(1)B.pet.jpg',
  'https://doogomimages3.s3.ap-northeast-2.amazonaws.com/default_guide/4.(1)C.quiet.jpg',
  'https://doogomimages3.s3.ap-northeast-2.amazonaws.com/default_guide/4.(1)D.party.jpg',
  'https://doogomimages3.s3.ap-northeast-2.amazonaws.com/default_guide/4.(1)E.breakages.jpg',
  'https://doogomimages3.s3.ap-northeast-2.amazonaws.com/default_guide/4.(2).otherrules.jpg',
  'https://doogomimages3.s3.ap-northeast-2.amazonaws.com/default_guide/5.(1)checkouttime.jpg',
  'https://doogomimages3.s3.ap-northeast-2.amazonaws.com/default_guide/5.(2)checkoutprocess.jpg',
  'https://doogomimages3.s3.ap-northeast-2.amazonaws.com/default_guide/5.(3)thanksmessage.jpg',
  'https://doogomimages3.s3.ap-northeast-2.amazonaws.com/default_guide/6.(1)faq.jpg',
  'https://doogomimages3.s3.ap-northeast-2.amazonaws.com/default_guide/6.(2)ifyouhaveaquestion.jpg',
  'https://doogomimages3.s3.ap-northeast-2.amazonaws.com/default_guide/7.(2)freeKOREAhotline.png',
  'https://doogomimages3.s3.ap-northeast-2.amazonaws.com/default_guide/7.(3)firstaidkit.jpg',
  'https://doogomimages3.s3.ap-northeast-2.amazonaws.com/default_guide/7.(4)fireextinguisher.jpg',
  'https://doogomimages3.s3.ap-northeast-2.amazonaws.com/default_guide/7.(5)cctv.jpg',
]

export function isMatchingDefaultGuideImageURL(url) {
  for (let i = 0; i < imageUrls.length; i++) {
    if (url === imageUrls[i]) {
      return true
    }
  }
  return false
}
