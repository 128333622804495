import styled from 'styled-components'
import IconTooltip from '../icon_tooltip/IconTooltip'
import { QuestionMarkInCircle } from '../../icons/icons'
import { useState } from 'react'

export const PaymentHistoryLongTile = ({
  title,
  btnSection,
  questionVisible = false,
  questionSection,
}) => {
  const [visible, setVisible] = useState(false)

  const handleVisible = () => {
    setVisible((pre) => !pre)
  }

  return (
    <LongTileFrame>
      <TileHeader>
        <div
          style={{
            display: 'flex',
          }}
        >
          <TileHeaderTitle>{title}</TileHeaderTitle>
          {questionVisible && (
            <div
              style={{
                marginLeft: 8,
              }}
            >
              <IconTooltip
                icon={
                  <QuestionMarkInCircle
                    onClick={handleVisible}
                  ></QuestionMarkInCircle>
                }
                handleClick={handleVisible}
                visible={visible}
                tooltipTop={2.5}
                tooltipLeft={30}
                tooltipDefaultWidth={450}
                tooltipChangeWidth={290}
              >
                <div
                  style={{
                    padding: 20,
                  }}
                >
                  {questionSection}
                </div>
              </IconTooltip>
            </div>
          )}
        </div>
        <TileBtnSection>{btnSection}</TileBtnSection>
      </TileHeader>
    </LongTileFrame>
  )
}

const LongTileFrame = styled.div`
  background-color: #fff;
  margin-bottom: 24px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-width: 0;
`

const TileHeader = styled.header`
  height: 350px;
  border-bottom: none;
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  font-size: 1.125rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
  flex-shrink: 0;

  @media (max-width: 1024px) {
    flex-wrap: wrap;
    height: auto !important;
    font-size: 0.825rem;
    padding: 15px;
  }
`

const TileHeaderTitle = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  font-size: 1.125rem;
  line-height: 18px;

  @media (max-width: 1024px) {
    font-size: 0.825rem;
  }
`

const TileBtnSection = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
`
