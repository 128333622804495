import styled from 'styled-components'
import { useSelectSettingsSubHeader } from '../../data_system/context/select_settings_sub_header_index_context/SelectSettingsSubHeaderIndexContext'
import { MainGuidesContainer } from '../../design_system/components/main_guides_container/MainGuidesContainer'
import {
  MinusCircleIcon,
  PenIcon,
  PlusCircleIcon,
  QuestionMarkInCircle,
} from '../../design_system/icons/icons'
import IconTooltip from '../../design_system/components/icon_tooltip/IconTooltip'
import { useEffect, useRef, useState } from 'react'
import { MiddleHeader } from '../../design_system/components/middle_header/MiddleHeader'
import { LongTile } from '../../design_system/components/long_tile/LongTile'
import { VerySmallOpacityBtn } from '../../design_system/components/btn/pc/very_small_opacity_btn/VerySmallOpacityBtn'
import { BigOpacityBtn } from '../../design_system/components/btn/pc/big_opacity_btn/BigOpacityBtn'
import NormalModal from '../../design_system/components/normal_modal/NormalModal'
import { TitleDiv } from '../../design_system/components/title_div/TitleDiv'
import { TextInput } from '../../design_system/components/text_input/TextInput'
import { useSelectHeader } from '../../data_system/context/select_header_index_context/SelectHeaderIndexContext'
import { useSelectHelpAndSupportHeader } from '../../data_system/context/select_help_and_support_header_index_context/SelectHelpAndSupportHeaderIndexContext'
import {
  main_guides_help_support_inner_router_url,
  main_guides_payment_history_inner_router_url,
  terms_and_conditions_inner_router_url,
} from '../../communication_system/inner_router_url/inner_router_url'
import { useSubscription } from '../../data_system/context/subscription_context/SubscriptionContext'
import IndexToggleBtn from '../../design_system/components/btn/pc/index_toggle_btn/IndexToggleBtn'
import CheckBox from '../../design_system/components/check_box/CheckBox'
import { dateFormatFunction } from '../../data_system/data_function/date_format_function'
import {
  changePassword,
  getAccountEmailAndName,
  getPaymentTokenCheck,
  postAdditionalSubscriptionPayment,
  postChangeSubscriptionPayment,
  putAccountUserBankInfo,
  putAccountUserName,
} from '../../communication_system/axios_apis/axios_apis'
import { emailCheck } from '../../data_system/data_function/email_check_function'
import { usePopup } from '../../data_system/context/popup_context/PopupContext'
import { PasswordInput } from '../../design_system/components/password_input/PasswordInput'
import { useLoading } from '../../data_system/context/loading_context/LoadingContext'
import { priceFormatFunction } from '../../data_system/data_function/price_format_function'
import { dateAndTimeFormatFunction } from '../../data_system/data_function/date_and_time_format_function'
import StandardModal from '../../design_system/components/standard_modal/StandardModal'
import { generate_UUID } from '../../data_system/data_function/generate_UUID'
import { koreanPhoneNumberCheck } from '../../data_system/data_function/korean_phone_number_check_function'
import { set } from 'date-fns'
import { Dropdown } from '../../design_system/components/dropdown/Dropdown'
import { onlyNumberCheck } from '../../data_system/data_function/only_number_check_function'
import { isValidAccountNumber } from '../../data_system/data_function/is_valid_account_number'

const MainGuidesSettingsPage = () => {
  const { show } = usePopup()
  const { showLoading, hideLoading } = useLoading()
  const { selectSettingsSubHeaderIndex, setSettingsSubHeaderIndex } =
    useSelectSettingsSubHeader()
  const { setHeaderIndex } = useSelectHeader()
  const { setHelpAndSupportHeaderIndex } = useSelectHelpAndSupportHeader()
  const {
    daysLeft,
    maxGuideNumber,
    subscriptionPrice,
    subscriptionStartDate,
    subscriptionEndDate,
    startPayment,
    setStartPayment,
    nextPaymentDate,
    fetchSubscriptionDataDirectly,
    productId,
    productType,
    paymentTypeId,
    currentGuideNumber,
  } = useSubscription()

  const [trialAccountTooltipVisible, setTrialAccountTooltipVisible] =
    useState(false)

  const [changeLoginEmailModalVisible, setChangeLoginEmailModalVisible] =
    useState(false)
  const [changePasswordModalVisible, setChangePasswordModalVisible] =
    useState(false)
  const [personalDetailsEditModalVisible, setPersonalDetailsEditModalVisible] =
    useState(false)
  const [bankAccountModalVisible, setBankAccountModalVisible] = useState(false)
  const [activateNowModalVisible, setActivateNowModalVisible] = useState(false)
  const [upgradePlanModalVisible, setUpgradePlanModalVisible] = useState(false)
  const [checkGuideNumberModalVisible, setCheckGuideNumberModalVisible] =
    useState(false)
  const [sameGuidePaymentModalVisible, setSameGuidePaymentModalVisible] =
    useState(false)

  const [numberOfGuides, setNumberOfGuides] = useState(1)
  const [numberOfProperties, setNumberOfProperties] = useState(1)
  const [selectPaymentTypeIndex, setSelectPaymentTypeIndex] = useState(0)
  const [selectProductTypeIndex, setSelectProductTypeIndex] = useState(0)
  const [selectMonthlyAnnualIndex, setSelectMonthlyAnnualIndex] = useState(0)
  const [price, setPrice] = useState(19900)
  const [totalPrice, setTotalPrice] = useState(null)
  const [activateAgreeChecked, setActivateAgreeChecked] = useState(false)
  const [upgradePlanAgreeChecked, setUpgradePlanAgreeChecked] = useState(false)
  const handleTrialAccountTooltip = () => {
    setTrialAccountTooltipVisible((pre) => !pre)
  }

  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [bankAccountNumber, setBankAccountNumber] = useState('')
  const [bankAccountName, setBankAccountName] = useState('')
  const [selectBankIndex, setSelectBankIndex] = useState(0)
  const [oldPassword, setOldPassword] = useState('')
  const [oldPasswordVisible, setOldPasswordVisible] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordVisible, setNewPasswordVisible] = useState(false)
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('')
  const [newPasswordConfirmationVisible, setNewPasswordConfirmationVisible] =
    useState(false)

  const [nameOfPayment, setNameOfPayment] = useState('')
  const [phoneNumberOfPayment, setPhoneNumberOfPayment] = useState('')
  const [emailOfPayment, setEmailOfPayment] = useState('')

  const settings_header_content = [
    {
      id: 0,
      title: '계정 정보',
    },
    {
      id: 1,
      title: '결제 정보',
    },
    {
      id: 2,
      title: '공지 및 알림',
    },
  ]

  const settings_header_index_0_tile_content = [
    {
      id: 0,
      title: '로그인 정보',
      btnSection: (
        <>
          <VerySmallOpacityBtn
            fontColor={'#000'}
            backgroundColor={'#ededed'}
            borderColor={'#ededed'}
            onClick={() => {
              setChangeLoginEmailModalVisible(true)
            }}
          >
            로그인 이메일 보기
          </VerySmallOpacityBtn>
          <div
            style={{
              marginLeft: 10,
            }}
          >
            <VerySmallOpacityBtn
              fontColor={'#000'}
              backgroundColor={'#ededed'}
              borderColor={'#ededed'}
              onClick={() => {
                setChangePasswordModalVisible(true)
              }}
            >
              <div
                style={{
                  marginRight: 8,
                }}
              >
                <PenIcon></PenIcon>
              </div>
              비밀번호 변경하기
            </VerySmallOpacityBtn>
          </div>
        </>
      ),
      questionSection: <>비밀번호를 변경하실 수 있습니다.</>,
    },
    {
      id: 1,
      title: '개인 정보',
      btnSection: (
        <>
          <VerySmallOpacityBtn
            fontColor={'#000'}
            backgroundColor={'#ededed'}
            borderColor={'#ededed'}
            onClick={() => {
              setPersonalDetailsEditModalVisible(true)
            }}
          >
            <div
              style={{
                marginRight: 8,
              }}
            >
              <PenIcon></PenIcon>
            </div>
            수정하기
          </VerySmallOpacityBtn>
        </>
      ),
      questionSection: <>개인 정보를 수정하실 수 있습니다.</>,
    },
    {
      id: 2,
      title: '계좌 정보',
      btnSection: (
        <>
          <VerySmallOpacityBtn
            fontColor={'#000'}
            backgroundColor={'#ededed'}
            borderColor={'#ededed'}
            onClick={() => {
              setBankAccountModalVisible(true)
            }}
          >
            <div
              style={{
                marginRight: 8,
              }}
            >
              <PenIcon></PenIcon>
            </div>
            수정하기
          </VerySmallOpacityBtn>
        </>
      ),
      questionSection: (
        <>스토어에서 판매된 금액 정산 받을 계좌 번호를 입력해 주세요.</>
      ),
    },
  ]

  const calculatePrice = (
    selectProductTypeIndex,
    selectMonthlyAnnualIndex,
    numberOfProperties
  ) => {
    const basicPrices = [
      19900, 18900, 17900, 17400, 16900, 16400, 15900, 15400, 14900, 14500,
    ]
    const standardPrices = [
      29900, 28900, 27900, 27400, 26900, 26400, 26400, 26400, 26400, 26400,
    ]

    const getCalculatedPrice = (prices) => {
      if (numberOfProperties <= 10) {
        return prices[numberOfProperties - 1]
      } else {
        return prices[9]
      }
    }

    let pricePerProperty
    if (selectProductTypeIndex === 0) {
      pricePerProperty = getCalculatedPrice(basicPrices)
    } else if (selectProductTypeIndex === 1) {
      pricePerProperty = getCalculatedPrice(standardPrices)
    }

    const totalPrice =
      pricePerProperty *
      (selectMonthlyAnnualIndex === 0 ? 1 : 12) *
      numberOfProperties
    return totalPrice
  }

  const [payplePaymentContent, setPayplePaymentContent] = useState({
    pcd_pay_type: 'card', // 결제수단 <- 필수
    pcd_payer_name: `${nameOfPayment}`, // 결제자 이름
    pcd_payer_hp: `${phoneNumberOfPayment}`, // 결제자 휴대폰 번호
    pcd_payer_email: `${emailOfPayment}`, // 결제자 Email
    pcd_pay_goods: '두곰 구독 정기결제', // 결제 상품
    pcd_pay_total: `${19900}`, // 결제 금액
    pcd_pay_taxtotal: `${Math.round(19900 / 11)}`, // 부가세(복합과세인 경우 필수)
  })

  const getPaypleFirstPaymentResult = async (res) => {
    console.log(res)
    if (res.PCD_PAY_RST === 'success') {
      const response = await postAdditionalSubscriptionPayment(
        selectPaymentTypeIndex === 0 ? 'card' : 'transfer',
        numberOfGuides,
        totalPrice,
        selectProductTypeIndex === 0 ? 'basic' : 'standard',
        selectMonthlyAnnualIndex === 0 ? 1 : 12,
        res.PCD_PAYER_ID,
        res.PCD_PAYER_NAME,
        res.PCD_PAYER_HP,
        res.PCD_PAYER_EMAIL,
        res.PCD_PAY_OID,
        res.PCD_PAY_TAXTOTAL
      )
      if (parseInt(response.status) === 201) {
        show('결제가 완료되었습니다.')
        const response = await fetchSubscriptionDataDirectly()
        console.log(response)
        setStartPayment(true)
        setActivateNowModalVisible(false)
      }
    } else {
      // 결제 실패일 경우 알림 메시지
      window.alert(res.PCD_PAY_MSG)
    }
  }

  const getPaypleChangePaymentResult = async (res) => {
    console.log(res)
    if (res.PCD_PAY_RST === 'success') {
      const response = await postChangeSubscriptionPayment(
        selectPaymentTypeIndex === 0 ? 'card' : 'transfer',
        numberOfGuides,
        totalPrice,
        selectProductTypeIndex === 0 ? 'basic' : 'standard',
        selectMonthlyAnnualIndex === 0 ? 1 : 12,
        res.PCD_PAYER_ID,
        res.PCD_PAYER_NAME,
        res.PCD_PAYER_HP,
        res.PCD_PAYER_EMAIL,
        res.PCD_PAY_OID,
        res.PCD_PAY_TAXTOTAL
      )

      if (parseInt(response.status) === 201) {
        show('결제가 완료되었습니다.')
        const response = await fetchSubscriptionDataDirectly()
        console.log(response)
        setStartPayment(true)
        setUpgradePlanModalVisible(false)
      }
    } else {
      // 결제 실패일 경우 알림 메시지
      window.alert(res.PCD_PAY_MSG)
    }
  }

  const paypleFirstPaymentClick = (e) => {
    e.preventDefault()
    const obj = {}

    obj.PCD_PAY_TYPE = payplePaymentContent.pcd_pay_type // (필수) 결제 방법 (transfer | card)
    obj.PCD_PAY_WORK = 'PAY'
    if (payplePaymentContent.pcd_pay_type === 'card') {
      obj.PCD_CARD_VER = '01'
    }

    obj.PCD_PAY_GOODS = payplePaymentContent.pcd_pay_goods // (필수) 결제 상품
    obj.PCD_PAY_TOTAL = payplePaymentContent.pcd_pay_total // (필수) 결제 금액
    obj.PCD_RST_URL = '/main-guides/settings'

    // 테스트 클라이언트 키
    // obj.clientKey = 'tech_93E8EBDE6367803C1249D1D4C3CA89BF'
    // 실결제 파트너 인증 - 클라이언트 키(clientKey)
    obj.clientKey = '5FFF101F02FE08F41E7733B84D591E3C'

    obj.PCD_PAYER_NAME = payplePaymentContent.pcd_payer_name
    obj.PCD_PAYER_HP = payplePaymentContent.pcd_payer_hp
    obj.PCD_PAYER_EMAIL = payplePaymentContent.pcd_payer_email
    obj.PCD_PAY_ISTAX = 'Y'

    if (payplePaymentContent.pcd_pay_type === 'transfer') {
      obj.PCD_TAXSAVE_FLAG = 'Y'
    }

    obj.callbackFunction = getPaypleFirstPaymentResult

    console.log(obj)
    window.PaypleCpayAuthCheck(obj)
  }

  const paypleChangePaymentClick = (e) => {
    e.preventDefault()
    const obj = {}

    obj.PCD_PAY_TYPE = payplePaymentContent.pcd_pay_type // (필수) 결제 방법 (transfer | card)
    obj.PCD_PAY_WORK = 'PAY'
    if (payplePaymentContent.pcd_pay_type === 'card') {
      obj.PCD_CARD_VER = '01'
    }

    obj.PCD_PAY_GOODS = payplePaymentContent.pcd_pay_goods // (필수) 결제 상품
    obj.PCD_PAY_TOTAL = payplePaymentContent.pcd_pay_total // (필수) 결제 금액
    obj.PCD_RST_URL = '/main-guides/settings'

    // 테스트 클라이언트 키
    // obj.clientKey = 'tech_93E8EBDE6367803C1249D1D4C3CA89BF'
    // 실결제 파트너 인증 - 클라이언트 키(clientKey)
    obj.clientKey = '5FFF101F02FE08F41E7733B84D591E3C'

    obj.PCD_PAYER_NAME = payplePaymentContent.pcd_payer_name
    obj.PCD_PAYER_HP = payplePaymentContent.pcd_payer_hp
    obj.PCD_PAYER_EMAIL = payplePaymentContent.pcd_payer_email
    obj.PCD_PAY_ISTAX = 'Y'

    if (payplePaymentContent.pcd_pay_type === 'transfer') {
      obj.PCD_TAXSAVE_FLAG = 'Y'
    }

    obj.callbackFunction = getPaypleChangePaymentResult

    console.log(obj)
    window.PaypleCpayAuthCheck(obj)
  }

  useEffect(() => {
    setPayplePaymentContent((pre) => {
      return {
        ...pre,
        pcd_pay_type: selectPaymentTypeIndex === 0 ? 'card' : 'transfer',
      }
    })
  }, [selectPaymentTypeIndex])

  useEffect(() => {
    const calculatedPrice = calculatePrice(
      selectProductTypeIndex,
      selectMonthlyAnnualIndex,
      numberOfGuides
    )
    setTotalPrice(calculatedPrice)
    setPrice(
      selectMonthlyAnnualIndex === 0
        ? calculatedPrice / numberOfGuides
        : calculatedPrice / 12 / numberOfGuides
    )
    setPayplePaymentContent((pre) => {
      return {
        ...pre,
        pcd_pay_total: calculatedPrice,
        pcd_pay_taxtotal: Math.round(calculatedPrice / 11),
      }
    })
  }, [selectProductTypeIndex, selectMonthlyAnnualIndex, numberOfGuides])

  useEffect(() => {
    async function getData() {
      const response = await getAccountEmailAndName()
      console.log(response)
      if (parseInt(response.status) === 200) {
        setEmail(response.data.user.email)
        setName(response.data.user.name)
        console.log(response.data.user)
        setSelectBankIndex(
          response.data.user.bankTypeId === null
            ? 0
            : parseInt(response.data.user.bankTypeId)
        )
        setBankAccountNumber(
          response.data.user.bankAccountNumber === null
            ? ''
            : response.data.user.bankAccountNumber
        )
        setBankAccountName(
          response.data.user.bankAccountName === null
            ? ''
            : response.data.user.bankAccountName
        )
      }
    }

    getData()
  }, [])

  return (
    <MainGuidesContainer>
      <TopSection></TopSection>
      <MiddleHeader
        selectHeaderIndex={selectSettingsSubHeaderIndex}
        headerContent={settings_header_content}
        setHeaderIndex={setSettingsSubHeaderIndex}
      ></MiddleHeader>
      <BottomSection>
        {selectSettingsSubHeaderIndex === 0 && (
          <div>
            {settings_header_index_0_tile_content.map((content) => {
              return (
                <LongTile
                  key={content.id}
                  title={content.title}
                  btnSection={content.btnSection}
                  questionVisible={true}
                  questionSection={content.questionSection}
                ></LongTile>
              )
            })}
          </div>
        )}
        {selectSettingsSubHeaderIndex === 1 &&
          (!startPayment ? (
            <div>
              <BillingTotalSection>
                <BillingTotalSectionHeader>
                  <BillingTotalSectionHeaderLeft>
                    무료 이용 계정
                    {/* <div
                      style={{
                        marginLeft: 8,
                      }}
                    >
                      <IconTooltip
                        icon={
                          <QuestionMarkInCircle
                            onClick={handleTrialAccountTooltip}
                          ></QuestionMarkInCircle>
                        }
                        handleClick={handleTrialAccountTooltip}
                        visible={trialAccountTooltipVisible}
                        tooltipTop={2.5}
                        tooltipLeft={30}
                        tooltipDefaultWidth={500}
                        tooltipChangeWidth={350}
                      >
                        <div
                          style={{
                            padding: 20,
                          }}
                        >
                          These are the details of your current subscription.
                          You can update your card from the Billing Portal. Use
                          the Upgrade Plan button to change your plan volume.
                        </div>
                      </IconTooltip>
                    </div> */}
                  </BillingTotalSectionHeaderLeft>
                  <VerySmallOpacityBtn
                    fontColor={'#000'}
                    backgroundColor={'#ededed'}
                    borderColor={'#ededed'}
                    onClick={() => {
                      setHeaderIndex(4)
                      setHelpAndSupportHeaderIndex(1)
                      window.open(
                        main_guides_help_support_inner_router_url,
                        '_blank'
                      )
                    }}
                  >
                    고객센터
                  </VerySmallOpacityBtn>
                </BillingTotalSectionHeader>
                <BillingTotalSectionBody>
                  <p>
                    현재 무료 이용 중입니다. 무료 이용은 1달간 숙소 안내문 1개를
                    이용할 수 있습니다. 1달이 지난 이후에는 안내문이 열리지
                    않습니다. 무료 이용기간이 지나더라도 안내문의 내용은
                    삭제되지 않으니 걱정하지 마세요. 결제 이후에 작업해 두었던
                    안내문 그대로 사용이 가능합니다.
                  </p>
                  <p
                    style={{
                      marginTop: '1rem',
                    }}
                  >
                    아래 결제하기 버튼을 클릭하여 월 정기결제를 신청해 주세요.
                  </p>
                  <p
                    style={{
                      marginTop: '1rem',
                    }}
                  >
                    무료 이용기간:{' '}
                    <b>
                      {dateAndTimeFormatFunction(subscriptionStartDate)} ~{' '}
                      {dateAndTimeFormatFunction(subscriptionEndDate)}
                    </b>
                  </p>
                </BillingTotalSectionBody>
              </BillingTotalSection>
              <BillingActivateNowDiv>
                <BigOpacityBtn
                  fontColor={'#fff'}
                  backgroundColor={'#b49275'}
                  borderColor={'#b49275'}
                  onClick={() => {
                    setActivateNowModalVisible(true)
                  }}
                >
                  결제하기
                </BigOpacityBtn>
              </BillingActivateNowDiv>
            </div>
          ) : (
            <div>
              <BillingTotalSection>
                <BillingTotalSectionHeader>
                  <BillingTotalSectionHeaderLeft>
                    현재 요금제
                    <div
                      style={{
                        marginLeft: 8,
                      }}
                    >
                      <IconTooltip
                        icon={
                          <QuestionMarkInCircle
                            onClick={handleTrialAccountTooltip}
                          ></QuestionMarkInCircle>
                        }
                        handleClick={handleTrialAccountTooltip}
                        visible={trialAccountTooltipVisible}
                        tooltipTop={2.5}
                        tooltipLeft={30}
                        tooltipDefaultWidth={500}
                        tooltipChangeWidth={350}
                      >
                        <div
                          style={{
                            padding: 20,
                          }}
                        >
                          현재 이용 중인 요금제 정보입니다. 요금제를 변경하거나
                          안내문 추가 결제를 할 수 있습니다. 취소 및 환불은
                          고객센터 혹은 doogomcs@doogom.com으로 요청
                          부탁드립니다.
                        </div>
                      </IconTooltip>
                    </div>
                  </BillingTotalSectionHeaderLeft>
                  <div
                    style={{
                      display: 'flex',
                    }}
                  >
                    <VerySmallOpacityBtn
                      fontColor={'#000'}
                      backgroundColor={'#ededed'}
                      borderColor={'#ededed'}
                      onClick={() => {
                        setHeaderIndex(3)
                        setHelpAndSupportHeaderIndex(1)
                        window.open(
                          main_guides_help_support_inner_router_url,
                          '_blank'
                        )
                      }}
                    >
                      고객센터
                    </VerySmallOpacityBtn>
                    <div
                      style={{
                        marginLeft: 10,
                      }}
                    >
                      <VerySmallOpacityBtn
                        fontColor={'#fff'}
                        backgroundColor={'#b49275'}
                        borderColor={'#b49275'}
                        onClick={() => {
                          console.log(productId)
                          setNameOfPayment('')
                          setPhoneNumberOfPayment('')
                          setEmailOfPayment('')
                          setSelectPaymentTypeIndex(
                            paymentTypeId === 1 || paymentTypeId === 2 ? 0 : 1
                          )
                          setSelectProductTypeIndex(
                            productId === 1 || productId === 3 ? 1 : 0
                          )
                          setSelectMonthlyAnnualIndex(0)
                          setNumberOfGuides(maxGuideNumber)
                          setActivateAgreeChecked(false)
                          setPayplePaymentContent((pre) => {
                            return {
                              ...pre,
                              pcd_payer_name: '',
                              pcd_payer_hp: '',
                              pcd_payer_email: '',
                              pcd_pay_type:
                                paymentTypeId === 1 || paymentTypeId === 2
                                  ? 'card'
                                  : 'transfer',
                              pcd_pay_total: `${calculatePrice(
                                productId === 1 || productId === 3 ? 1 : 0,
                                0,
                                maxGuideNumber
                              )}`,
                              pcd_pay_taxtotal: Math.round(
                                calculatePrice(
                                  productId === 1 || productId === 3 ? 1 : 0,
                                  0,
                                  maxGuideNumber
                                ) / 11
                              ),
                            }
                          })
                          setUpgradePlanModalVisible(true)
                        }}
                      >
                        결제 변경
                      </VerySmallOpacityBtn>
                    </div>
                  </div>
                </BillingTotalSectionHeader>
                <BillingTotalSectionDuringPaymentBody>
                  <p>
                    <b>요금제 종류</b>
                    <span
                      style={{
                        padding: '0 30px',
                      }}
                    >
                      {productId === 1 || productId === 3
                        ? '스탠다드'
                        : '베이직'}
                    </span>
                    <b>최대 {maxGuideNumber}개 숙소</b>
                    <span
                      style={{
                        padding: '0 30px',
                      }}
                    >
                      {priceFormatFunction(subscriptionPrice)}원 / 월
                    </span>
                  </p>
                  <div
                    style={{
                      marginTop: '1rem',
                      display: 'flex',
                    }}
                  >
                    <span>
                      <b>시작:</b>{' '}
                      {dateAndTimeFormatFunction(subscriptionStartDate)}
                    </span>
                    <span
                      style={{
                        padding: '0 30px',
                      }}
                    >
                      <b>종료:</b>{' '}
                      {dateAndTimeFormatFunction(subscriptionEndDate)}
                    </span>
                    <span>
                      <b>다음 자동결제일:</b>{' '}
                      {dateAndTimeFormatFunction(nextPaymentDate)}
                    </span>
                  </div>
                </BillingTotalSectionDuringPaymentBody>
              </BillingTotalSection>
              <BillingActivateNowDiv>
                <BigOpacityBtn
                  fontColor={'#fff'}
                  backgroundColor={'#b49275'}
                  borderColor={'#b49275'}
                  onClick={() => {
                    const baseUrl = window.location.origin
                    const path = `${main_guides_payment_history_inner_router_url}`
                    const url = `${baseUrl}${path}`

                    window.open(url, '_blank')
                  }}
                >
                  결제 내역
                </BigOpacityBtn>
              </BillingActivateNowDiv>
            </div>
          ))}
        {selectSettingsSubHeaderIndex === 2 && (
          <AnnouncementFrame>
            <AnnouncementFrameInner>
              <AnnouncementForm>
                <AnnouncementHeader>두곰 공지사항</AnnouncementHeader>
                <AnnouncementBody>
                  <div>안녕하세요. 두곰입니다.</div>
                  <br></br>
                  <div>
                    두곰은 빠르고 간편하게 숙소 안내문을 제작할 수 있는
                    플랫폼입니다. 호스트는 두곰을 통해 숙소 안내문을 만들고,
                    완성된 안내문 url을 게스트에게 보내주기만 하면 됩니다.
                  </div>
                  <br></br>
                  <div>
                    안내문을 만드는 방법은 쉽습니다. 기본 안내문의 내용을
                    호스트의 숙소에 맞게 수정하기만 하면 됩니다. 좀 더 상세한
                    내용은 FAQ를 참조해 주세요.
                  </div>
                  <br></br>
                  <div>
                    문의사항은 고객센터, 혹은 이메일 doogomcs@doogom.com 으로
                    부탁드립니다.
                  </div>
                  <br></br>
                  <div>감사합니다.</div>
                  <br></br>
                </AnnouncementBody>
              </AnnouncementForm>
            </AnnouncementFrameInner>
          </AnnouncementFrame>
        )}
      </BottomSection>
      {changeLoginEmailModalVisible && (
        <NormalModal
          titleText={'로그인 이메일'}
          modalBodyComponent={
            <div>
              <TitleDiv>이메일 주소</TitleDiv>
              <TextInput
                backgroundColor="#fff"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
                disabled={true}
              ></TextInput>
            </div>
          }
          onRightBtnClick={async () => {
            setChangeLoginEmailModalVisible(false)
          }}
          onClose={() => {
            setChangeLoginEmailModalVisible(false)
          }}
          rightBtnText={'확인'}
          questionSection={
            <p>
              The account email address is used for the login and also any
              support ticket replies or service update emails.
            </p>
          }
          questionDefaultWidth={500}
          questionChangeWidth={180}
        ></NormalModal>
      )}
      {changePasswordModalVisible && (
        <NormalModal
          titleText={'비밀번호 변경하기'}
          modalBodyComponent={
            <div>
              <TitleDiv>현재 비밀번호 *</TitleDiv>
              <PasswordInput
                backgroundColor="#fff"
                password={oldPassword}
                setPassword={setOldPassword}
                passwordVisible={oldPasswordVisible}
                setPasswordVisible={setOldPasswordVisible}
              ></PasswordInput>
              <TitleDiv>새로운 비밀번호 *</TitleDiv>
              <PasswordInput
                backgroundColor="#fff"
                password={newPassword}
                setPassword={setNewPassword}
                passwordVisible={newPasswordVisible}
                setPasswordVisible={setNewPasswordVisible}
              ></PasswordInput>
              <TitleDiv>새로운 비밀번호 확인 *</TitleDiv>
              <PasswordInput
                backgroundColor="#fff"
                password={newPasswordConfirmation}
                setPassword={setNewPasswordConfirmation}
                passwordVisible={newPasswordConfirmationVisible}
                setPasswordVisible={setNewPasswordConfirmationVisible}
              ></PasswordInput>
            </div>
          }
          onRightBtnClick={async () => {
            const response = await changePassword(oldPassword, newPassword)

            if (parseInt(response.status) === 200) {
              show('비밀번호가 변경되었습니다.')
              setChangePasswordModalVisible(false)
            }
          }}
          onClose={() => {
            setChangePasswordModalVisible(false)
          }}
          rightBtnText={'저장'}
          rightBtnDisabled={
            oldPassword !== '' &&
            newPassword !== '' &&
            newPasswordConfirmation !== '' &&
            newPassword === newPasswordConfirmation
              ? false
              : true
          }
          questionSection={<p>Change the password to access your account.</p>}
          questionDefaultWidth={500}
          questionChangeWidth={180}
        ></NormalModal>
      )}
      {personalDetailsEditModalVisible && (
        <NormalModal
          titleText={'개인 정보'}
          modalBodyComponent={
            <div>
              <TitleDiv>이름 *</TitleDiv>
              <TextInput
                backgroundColor="#fff"
                value={name}
                onChange={(e) => {
                  setName(e.target.value)
                }}
              ></TextInput>
            </div>
          }
          onRightBtnClick={async () => {
            if (name !== '') {
              const response = await putAccountUserName(name)
              console.log(response)

              if (parseInt(response.status) === 200) {
                show('수정 완료')
                setPersonalDetailsEditModalVisible(false)
              }
            }
          }}
          onClose={() => {
            setPersonalDetailsEditModalVisible(false)
          }}
          rightBtnText={'수정'}
          questionSection={<p>---</p>}
          questionDefaultWidth={500}
          questionChangeWidth={180}
        ></NormalModal>
      )}
      {bankAccountModalVisible && (
        <NormalModal
          titleText={'계좌 정보'}
          modalBodyComponent={
            <div>
              <TitleDiv>은행 *</TitleDiv>
              <Dropdown
                backgroundColor="#fff"
                options={[
                  {
                    id: 0,
                    title: '은행 선택',
                  },
                  {
                    id: 1,
                    title: '신한은행',
                  },
                  {
                    id: 2,
                    title: '우리은행',
                  },
                  {
                    id: 3,
                    title: '카카오은행',
                  },
                  {
                    id: 4,
                    title: '케이뱅크',
                  },
                  {
                    id: 5,
                    title: '토스뱅크',
                  },
                  {
                    id: 6,
                    title: 'NH농협은행',
                  },
                  {
                    id: 7,
                    title: '단위농협',
                  },
                  {
                    id: 8,
                    title: '대구은행',
                  },
                  {
                    id: 9,
                    title: '수협은행',
                  },
                  {
                    id: 10,
                    title: '새마을금고',
                  },
                  {
                    id: 11,
                    title: 'KB국민은행',
                  },
                  {
                    id: 12,
                    title: '하나은행',
                  },
                  {
                    id: 13,
                    title: '한국산업은행',
                  },
                  {
                    id: 14,
                    title: '한국씨티은행',
                  },
                  {
                    id: 15,
                    title: '광주은행',
                  },
                  {
                    id: 16,
                    title: 'BNK경남은행',
                  },
                  {
                    id: 17,
                    title: '제주은행',
                  },
                  {
                    id: 18,
                    title: '중소기업은행',
                  },
                  {
                    id: 19,
                    title: '우체국',
                  },
                ]}
                selectIndex={selectBankIndex}
                setSelectIndex={setSelectBankIndex}
              ></Dropdown>
              <TitleDiv>계좌번호 *</TitleDiv>
              <TextInput
                backgroundColor="#fff"
                value={bankAccountNumber}
                onChange={(e) => {
                  if (
                    onlyNumberCheck.test(e.target.value) ||
                    e.target.value === ''
                  ) {
                    setBankAccountNumber(e.target.value)
                  }
                }}
              ></TextInput>
              <TitleDiv>계좌주명 *</TitleDiv>
              <TextInput
                backgroundColor="#fff"
                value={bankAccountName}
                onChange={(e) => {
                  setBankAccountName(e.target.value)
                }}
              ></TextInput>
            </div>
          }
          onRightBtnClick={async () => {
            console.log(selectBankIndex)
            console.log(bankAccountNumber)
            console.log(bankAccountName)
            console.log(
              isValidAccountNumber(selectBankIndex, bankAccountNumber)
            )
            if (
              selectBankIndex !== 0 &&
              bankAccountNumber !== '' &&
              isValidAccountNumber(selectBankIndex, bankAccountNumber) &&
              bankAccountName !== ''
            ) {
              const response = await putAccountUserBankInfo(
                selectBankIndex,
                bankAccountNumber,
                bankAccountName
              )
              console.log(response)

              if (parseInt(response.status) === 200) {
                show('수정 완료')
                setBankAccountModalVisible(false)
              }
            } else {
              show('계좌 정보를 확인해 주세요.', false)
            }
          }}
          onClose={() => {
            setBankAccountModalVisible(false)
          }}
          rightBtnText={'수정'}
          questionSection={<p>---</p>}
          questionDefaultWidth={500}
          questionChangeWidth={180}
        ></NormalModal>
      )}
      {activateNowModalVisible && (
        <NormalModal
          titleText={'요금제를 선택하세요'}
          modalBodyComponent={
            <ActivateModalBody>
              <TitleDiv>이름 *</TitleDiv>
              <TextInput
                backgroundColor="#fff"
                value={nameOfPayment}
                onChange={(e) => {
                  setNameOfPayment(e.target.value)
                  setPayplePaymentContent((pre) => {
                    return {
                      ...pre,
                      pcd_payer_name: e.target.value,
                    }
                  })
                }}
              ></TextInput>
              <TitleDiv>휴대폰 번호 *</TitleDiv>
              <TextInput
                backgroundColor="#fff"
                placeholder={'- 없이 입력하세요'}
                value={phoneNumberOfPayment}
                onChange={(e) => {
                  setPhoneNumberOfPayment(e.target.value)
                  setPayplePaymentContent((pre) => {
                    return {
                      ...pre,
                      pcd_payer_hp: e.target.value,
                    }
                  })
                }}
              ></TextInput>
              <TitleDiv>이메일 *</TitleDiv>
              <TextInput
                backgroundColor="#fff"
                value={emailOfPayment}
                onChange={(e) => {
                  setEmailOfPayment(e.target.value)
                  setPayplePaymentContent((pre) => {
                    return {
                      ...pre,
                      pcd_payer_email: e.target.value,
                    }
                  })
                }}
              ></TextInput>
              <ActivateModalDivSection>
                <ActivateModalTitleDiv>
                  <ActivateModalTitle>결제 방법</ActivateModalTitle>
                </ActivateModalTitleDiv>
                <IndexToggleBtn
                  index_toggle_data={[
                    { id: 0, title: '카드' },
                    { id: 1, title: '계좌이체' },
                  ]}
                  frameWidth={'66.66667%'}
                  btnPadding={'12px 20px'}
                  btnWidth={'50%'}
                  selectIndex={selectPaymentTypeIndex}
                  setSelectIndex={setSelectPaymentTypeIndex}
                ></IndexToggleBtn>
              </ActivateModalDivSection>
              <ActivateModalDivSection>
                <ActivateModalTitleDiv>
                  <ActivateModalTitle>요금제</ActivateModalTitle>
                </ActivateModalTitleDiv>
                <IndexToggleBtn
                  index_toggle_data={[
                    { id: 0, title: '베이직' },
                    { id: 1, title: '스탠다드' },
                  ]}
                  frameWidth={'66.66667%'}
                  btnPadding={'12px 20px'}
                  btnWidth={'50%'}
                  selectIndex={selectProductTypeIndex}
                  setSelectIndex={setSelectProductTypeIndex}
                ></IndexToggleBtn>
              </ActivateModalDivSection>
              <ActivateModalDivSection>
                <ActivateModalTitleDiv>
                  <ActivateModalTitle>납부방법</ActivateModalTitle>
                </ActivateModalTitleDiv>
                <IndexToggleBtn
                  index_toggle_data={[{ id: 0, title: '월 정기결제' }]}
                  frameWidth={'66.66667%'}
                  btnPadding={'12px 20px'}
                  btnWidth={'50%'}
                  selectIndex={selectMonthlyAnnualIndex}
                  setSelectIndex={setSelectMonthlyAnnualIndex}
                ></IndexToggleBtn>
              </ActivateModalDivSection>
              <ActivateModalDivSection>
                <ActivateModalTitle2Div>
                  <ActivateModalTitle>안내문 개수</ActivateModalTitle>
                </ActivateModalTitle2Div>
                <NumberInputFrame>
                  <MinusCircleIcon
                    onClick={() => {
                      if (numberOfGuides > 1) {
                        setNumberOfGuides((pre) => pre - 1)
                      }
                    }}
                    active={numberOfGuides > 1}
                  ></MinusCircleIcon>
                  {numberOfGuides}
                  <PlusCircleIcon
                    onClick={() => {
                      setNumberOfGuides((pre) => pre + 1)
                    }}
                  ></PlusCircleIcon>
                </NumberInputFrame>
              </ActivateModalDivSection>
              <PriceText>
                합계: {priceFormatFunction(totalPrice)} 원 / 월(1개{' '}
                {priceFormatFunction(price)} 원)
              </PriceText>
              <AgreeDiv>
                <CheckBox
                  checked={activateAgreeChecked}
                  onClick={() => {
                    setActivateAgreeChecked((pre) => !pre)
                  }}
                ></CheckBox>
                <AgreeText>
                  본 서비스의 매월 자동 결제에 동의하고, 환불 및 취소 처리는{' '}
                  <a
                    href={terms_and_conditions_inner_router_url}
                    target="_blank"
                    style={{
                      color: '#eba55d',
                      textDecoration: 'none',
                    }}
                    rel="noreferrer"
                  >
                    이용약관
                  </a>
                  에 명시된 규정에 따라 처리됨을 동의합니다.
                </AgreeText>
              </AgreeDiv>
            </ActivateModalBody>
          }
          onRightBtnClick={async (e) => {
            showLoading()

            const response = await getPaymentTokenCheck()

            if (parseInt(response.status) === 200) {
              paypleFirstPaymentClick(e)
            }

            hideLoading()
          }}
          onClose={() => {
            setNameOfPayment('')
            setPhoneNumberOfPayment('')
            setEmailOfPayment('')
            setSelectPaymentTypeIndex(0)
            setSelectProductTypeIndex(0)
            setSelectMonthlyAnnualIndex(0)
            setActivateAgreeChecked(false)
            setPayplePaymentContent((pre) => {
              return {
                ...pre,
                pcd_payer_name: '',
                pcd_payer_hp: '',
                pcd_payer_email: '',
                pcd_pay_type: 'card',
                pcd_pay_total: `${19900}`,
                pcd_pay_taxtotal: Math.round(19900 / 11),
              }
            })
            setActivateNowModalVisible(false)
          }}
          rightBtnText={'결제하기'}
          rightBtnDisabled={
            !activateAgreeChecked ||
            nameOfPayment === '' ||
            phoneNumberOfPayment === '' ||
            koreanPhoneNumberCheck.test(phoneNumberOfPayment) === false ||
            emailOfPayment === '' ||
            emailCheck.test(emailOfPayment) === false
          }
          questionSection={<p>---</p>}
          questionDefaultWidth={500}
          questionChangeWidth={180}
        ></NormalModal>
      )}
      {upgradePlanModalVisible && (
        <NormalModal
          titleText={'결제 변경'}
          modalBodyComponent={
            <ActivateModalBody>
              <TitleDiv>이름 *</TitleDiv>
              <TextInput
                backgroundColor="#fff"
                value={nameOfPayment}
                onChange={(e) => {
                  setNameOfPayment(e.target.value)
                  setPayplePaymentContent((pre) => {
                    return {
                      ...pre,
                      pcd_payer_name: e.target.value,
                    }
                  })
                }}
              ></TextInput>
              <TitleDiv>휴대폰 번호 *</TitleDiv>
              <TextInput
                backgroundColor="#fff"
                placeholder={'- 없이 입력하세요'}
                value={phoneNumberOfPayment}
                onChange={(e) => {
                  setPhoneNumberOfPayment(e.target.value)
                  setPayplePaymentContent((pre) => {
                    return {
                      ...pre,
                      pcd_payer_hp: e.target.value,
                    }
                  })
                }}
              ></TextInput>
              <TitleDiv>이메일 *</TitleDiv>
              <TextInput
                backgroundColor="#fff"
                value={emailOfPayment}
                onChange={(e) => {
                  setEmailOfPayment(e.target.value)
                  setPayplePaymentContent((pre) => {
                    return {
                      ...pre,
                      pcd_payer_email: e.target.value,
                    }
                  })
                }}
              ></TextInput>
              <ActivateModalDivSection>
                <ActivateModalTitleDiv>
                  <ActivateModalTitle>결제 방법</ActivateModalTitle>
                </ActivateModalTitleDiv>
                <IndexToggleBtn
                  index_toggle_data={[
                    { id: 0, title: '카드' },
                    { id: 1, title: '계좌이체' },
                  ]}
                  frameWidth={'66.66667%'}
                  btnPadding={'12px 20px'}
                  btnWidth={'50%'}
                  selectIndex={selectPaymentTypeIndex}
                  setSelectIndex={setSelectPaymentTypeIndex}
                ></IndexToggleBtn>
              </ActivateModalDivSection>
              <ActivateModalDivSection>
                <ActivateModalTitleDiv>
                  <ActivateModalTitle>요금제</ActivateModalTitle>
                </ActivateModalTitleDiv>
                <IndexToggleBtn
                  index_toggle_data={[
                    { id: 0, title: '베이직' },
                    { id: 1, title: '스탠다드' },
                  ]}
                  frameWidth={'66.66667%'}
                  btnPadding={'12px 20px'}
                  btnWidth={'50%'}
                  selectIndex={selectProductTypeIndex}
                  setSelectIndex={setSelectProductTypeIndex}
                ></IndexToggleBtn>
              </ActivateModalDivSection>
              <ActivateModalDivSection>
                <ActivateModalTitleDiv>
                  <ActivateModalTitle>납부방법</ActivateModalTitle>
                </ActivateModalTitleDiv>
                <IndexToggleBtn
                  index_toggle_data={[{ id: 0, title: '월 정기결제' }]}
                  frameWidth={'66.66667%'}
                  btnPadding={'12px 20px'}
                  btnWidth={'50%'}
                  selectIndex={selectMonthlyAnnualIndex}
                  setSelectIndex={setSelectMonthlyAnnualIndex}
                ></IndexToggleBtn>
              </ActivateModalDivSection>
              <ActivateModalDivSection>
                <ActivateModalTitle2Div>
                  <ActivateModalTitle>안내문 개수</ActivateModalTitle>
                </ActivateModalTitle2Div>
                <NumberInputFrame>
                  <MinusCircleIcon
                    onClick={() => {
                      if (numberOfGuides > 1) {
                        setNumberOfGuides((pre) => pre - 1)
                      }
                    }}
                    active={numberOfGuides > 1}
                  ></MinusCircleIcon>
                  {numberOfGuides}
                  <PlusCircleIcon
                    onClick={() => {
                      setNumberOfGuides((pre) => pre + 1)
                    }}
                  ></PlusCircleIcon>
                </NumberInputFrame>
              </ActivateModalDivSection>
              <PriceText>
                합계: {priceFormatFunction(totalPrice)} 원 / 월(1개{' '}
                {priceFormatFunction(price)} 원)
              </PriceText>
              <AgreeDiv>
                <CheckBox
                  checked={activateAgreeChecked}
                  onClick={() => {
                    setActivateAgreeChecked((pre) => !pre)
                  }}
                ></CheckBox>
                <AgreeText>
                  본 서비스의 매월 자동 결제에 동의하고, 환불 및 취소 처리는{' '}
                  <a
                    href={terms_and_conditions_inner_router_url}
                    target="_blank"
                    style={{
                      color: '#eba55d',
                      textDecoration: 'none',
                    }}
                    rel="noreferrer"
                  >
                    이용약관
                  </a>
                  에 명시된 규정에 따라 처리됨을 동의합니다.
                </AgreeText>
              </AgreeDiv>
            </ActivateModalBody>
          }
          onRightBtnClick={async (e) => {
            console.log('numberOfGuides', numberOfGuides)
            console.log('currentGuideNumber', currentGuideNumber)
            if (
              numberOfGuides === maxGuideNumber &&
              (((productId === 1 || productId === 3) &&
                selectProductTypeIndex === 1) ||
                (productId === 2 && selectProductTypeIndex === 0)) &&
              (((paymentTypeId === 1 || paymentTypeId === 2) &&
                selectPaymentTypeIndex === 0) ||
                (paymentTypeId === 3 && selectPaymentTypeIndex === 1))
            ) {
              setSameGuidePaymentModalVisible(true)
            } else if (numberOfGuides < currentGuideNumber) {
              setCheckGuideNumberModalVisible(true)
            } else {
              showLoading()

              const response = await getPaymentTokenCheck()

              if (parseInt(response.status) === 200) {
                paypleChangePaymentClick(e)
              }

              hideLoading()
            }
          }}
          onClose={() => {
            setNameOfPayment('')
            setPhoneNumberOfPayment('')
            setEmailOfPayment('')
            setSelectPaymentTypeIndex(
              paymentTypeId === 1 || paymentTypeId === 2 ? 0 : 1
            )
            setSelectProductTypeIndex(
              productId === 1 || productId === 3 ? 1 : 0
            )
            setSelectMonthlyAnnualIndex(0)
            setNumberOfGuides(maxGuideNumber)
            setActivateAgreeChecked(false)
            setPayplePaymentContent((pre) => {
              return {
                ...pre,
                pcd_payer_name: '',
                pcd_payer_hp: '',
                pcd_payer_email: '',
                pcd_pay_type:
                  paymentTypeId === 1 || paymentTypeId === 2
                    ? 'card'
                    : 'transfer',
                pcd_pay_total: `${calculatePrice(
                  productId === 1 || productId === 3 ? 1 : 0,
                  0,
                  maxGuideNumber
                )}`,
                pcd_pay_taxtotal: Math.round(
                  calculatePrice(
                    productId === 1 || productId === 3 ? 1 : 0,
                    0,
                    maxGuideNumber
                  ) / 11
                ),
              }
            })
            setUpgradePlanModalVisible(false)
          }}
          rightBtnText={'결제하기'}
          rightBtnDisabled={
            !activateAgreeChecked ||
            nameOfPayment === '' ||
            phoneNumberOfPayment === '' ||
            koreanPhoneNumberCheck.test(phoneNumberOfPayment) === false ||
            emailOfPayment === '' ||
            emailCheck.test(emailOfPayment) === false
          }
          questionSection={<p>---</p>}
          questionDefaultWidth={500}
          questionChangeWidth={180}
        ></NormalModal>
      )}
      {sameGuidePaymentModalVisible && (
        <StandardModal
          titleText={'결제 변경 사항이 없습니다.'}
          modalBodyComponent={
            <div>원래 결제하셨던 내용과 같은 결제 내용입니다.</div>
          }
          useLeftBtn={false}
          onRightBtnClick={async () => {
            setSameGuidePaymentModalVisible(false)
          }}
          onClose={() => {
            setSameGuidePaymentModalVisible(false)
          }}
          rightBtnText={'확인'}
        ></StandardModal>
      )}
      {checkGuideNumberModalVisible && (
        <StandardModal
          titleText={'가이드를 삭제하고 와주세요.'}
          modalBodyComponent={
            <div>
              현재 만들어진 가이드가 변경할 가이드의 수보다 많습니다. 가이드를
              삭제하고 와주세요.
            </div>
          }
          useLeftBtn={false}
          onRightBtnClick={async () => {
            setCheckGuideNumberModalVisible(false)
          }}
          onClose={() => {
            setCheckGuideNumberModalVisible(false)
          }}
          rightBtnText={'확인'}
        ></StandardModal>
      )}
    </MainGuidesContainer>
  )
}

export default MainGuidesSettingsPage

const TopSection = styled.div`
  height: 60px;
  background: #ffffff;
  box-sizing: border-box;
`

const BottomSection = styled.div`
  padding: 20px 10%;
  height: 100%;
  background: #f3f3f3;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    padding: 20px 10px;
    background: #f3f3f3;
  }
`

const BillingTotalSection = styled.div`
  background-color: #fff;
  margin-bottom: 24px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-width: 0;
`

const BillingTotalSectionHeader = styled.div`
  height: 60px;
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  font-size: 1.125rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
  flex-shrink: 0;

  @media (max-width: 1024px) {
    font-size: 0.825rem;
    padding: 15px;
    height: 50px !important;
  }
`

const BillingTotalSectionBody = styled.div`
  padding: 20px;
  font-size: 0.875rem;
  line-height: 1rem;
  box-sizing: border-box;
`

const BillingTotalSectionDuringPaymentBody = styled.div`
  padding: 20px;
  font-size: 1rem;
  line-height: 1rem;
  box-sizing: border-box;
`

const BillingTotalSectionHeaderLeft = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  font-size: 1.125rem;

  @media (max-width: 1024px) {
    font-size: 0.825rem;
  }
`

const BillingActivateNowDiv = styled.div`
  display: flex;
  justify-content: center;
  box-sizing: border-box;
`

const ActivateModalBody = styled.div`
  color: #000;
  font-size: 14px;
  word-break: break-all;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    width: 100%;
    padding: 0 10px;
  }
`

const ActivateModalDivSection = styled.div`
  padding-top: 15px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    padding-top: 0px;
    flex-direction: column;
  }
`

const ActivateModalTitleDiv = styled.div`
  width: 33.33333%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    width: 100%;
  }
`

const ActivateModalTitle = styled.span`
  font-size: 1rem;
  padding: 15px 0;
  display: flex;
  color: #000 !important;
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  box-sizing: border-box;
`

const ActivateModalTitle2Div = styled.div`
  width: 33.33333%;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    width: 100%;
  }
`

const NumberInputFrame = styled.div`
  width: 66.66667%;
  position: relative;
  display: inline-block;
  line-height: 38px;
  box-sizing: border-box;
  font-size: 13px;
  color: #303133;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 8px;
  padding: 0 8px;
  border: 1px solid #dcdfe6;
  border-radius: 8px;

  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
`

const PriceText = styled.div`
  font-size: 1.25rem;
  padding: 15px 0;
  color: #000 !important;
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  box-sizing: border-box;
`

const AgreeDiv = styled.div`
  word-break: break-word;
  padding: 15px 0;
  color: #000;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  box-sizing: border-box;
`

const AgreeText = styled.span`
  white-space: normal;
  color: #000;
  font-family: Circular Std Book, Tahoma, SansSerif;
  word-break: normal;
  display: inline-block;
  padding-left: 10px;
  line-height: 19px;
  font-size: 14px;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    font-size: 0.75rem;
  }
`

const AnnouncementFrame = styled.section`
  flex: 1 0 auto;
  display: flex;
  box-sizing: border-box;
  height: 10px;
`

const AnnouncementFrameInner = styled.section`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 35px 10%;
  flex-direction: column;
  flex: 1;
  flex-basis: auto;
  box-sizing: border-box;
  min-width: 0;

  @media (max-width: 1024px) {
    padding: 25px 20px;
  }
`

const AnnouncementForm = styled.div`
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  background-color: #fff;
  width: 100%;
  border-radius: 8px;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    width: 55%;
  }

  @media (max-width: 650px) {
    width: 95%;
  }
`

const AnnouncementHeader = styled.div`
  font-size: 1.375rem;
  padding: 25px;
  border-bottom: 1px solid #dedede;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`

const AnnouncementBody = styled.div`
  flex-direction: column;
  padding: 25px;
  display: flex;
  box-sizing: border-box;
  height: 50vh;
  overflow-y: auto;
`
