import React, { createContext, useContext, useState } from 'react'

const PopupContext = createContext()

export const usePopup = () => useContext(PopupContext)

export const PopupProvider = ({ children }) => {
  const [showPopup, setShowPopup] = useState(false)
  const [message, setMessage] = useState('')
  const [yesOrNotState, setYesOrNotState] = useState(true)

  const show = (msg, yesOrNot = true) => {
    setMessage(msg)
    setYesOrNotState(yesOrNot)
    setShowPopup(true)

    // 팝업이 3초 후에 사라짐
    setTimeout(() => {
      setShowPopup(false)
    }, 1500)
  }

  return (
    <PopupContext.Provider value={{ showPopup, message, show, yesOrNotState }}>
      {children}
    </PopupContext.Provider>
  )
}
