import styled from 'styled-components';
import { rgba_62_217_204_0d1 } from '../../colors';

export const TooltipItem = styled.div`
  line-height: 36px;
  padding: 0 20px;
  margin: 0;
  font-size: 14px;
  cursor: pointer;
  outline: 0;
  color: #000 !important;
  position: relative;
  box-sizing: border-box;

  &:hover {
    background-color: ${rgba_62_217_204_0d1};
  }
`;
