import styled from 'styled-components'
import { c_244556, c_b49275, c_eba55d } from '../../colors'

export const TitleH1 = styled.h1`
  font-size: 3rem;
  line-height: 1.5;
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  letter-spacing: 1px;
  color: ${c_eba55d};
  margin: 10px 0;
  width: max-content;

  @media (max-width: 1600px) {
    font-size: 2.25rem;
    line-height: 2.25rem;
  }

  @media (max-width: 1024px) {
    padding: 10px 50px 20px 0;
  }

  @media (max-width: 650px) {
    font-size: 2rem;
    line-height: 2.25rem;
    width: 100%;
    max-width: 375px;
  }
`
