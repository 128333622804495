import React, { useState } from 'react'
import { Footer } from '../../design_system/components/footer/Footer'
import PcSubHeader from '../../design_system/components/header/pc_sub_header/PcSubHeader'
import { Section } from '../../design_system/components/section/Section'
import Page0 from './page0/Page0'
// import Page1 from './page1/Page1'
import ProgressBar from '../../design_system/components/progress_bar/ProgressBar'
import Page2 from './page2/Page2'
import Page3 from './page3/Page3'

const MainGuidesCreateGuidePage = () => {
  const [pageNumber, setPageNumber] = useState(0)
  const [guideName, setGuideName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [address, setAddress] = useState('')
  const [additionalAddress, setAdditionalAddress] = useState('')
  const [position, setPosition] = useState({
    latitude: null,
    longitude: null,
  })
  return (
    <>
      <PcSubHeader>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        ></div>
        <div></div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        ></div>
      </PcSubHeader>
      <Section>
        {pageNumber === 0 && <Page0 setPageNumber={setPageNumber}></Page0>}
        {/* {pageNumber === 1 && <Page1 setPageNumber={setPageNumber}></Page1>} */}
        {pageNumber === 2 && (
          <Page2
            setPageNumber={setPageNumber}
            guideName={guideName}
            setGuideName={setGuideName}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
          ></Page2>
        )}
        {(pageNumber === 3 || pageNumber === 4) && (
          <Page3
            setPageNumber={setPageNumber}
            guideName={guideName}
            phoneNumber={phoneNumber}
            address={address}
            setAddress={setAddress}
            additionalAddress={additionalAddress}
            setAdditionalAddress={setAdditionalAddress}
            position={position}
            setPosition={setPosition}
          ></Page3>
        )}
        {pageNumber !== 0 && (
          <ProgressBar pageNumber={pageNumber}></ProgressBar>
        )}
        <Footer></Footer>
      </Section>
    </>
  )
}

export default MainGuidesCreateGuidePage
