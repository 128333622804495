import styled from 'styled-components'
import IconTextCircleBtn from '../../../design_system/components/btn/pc/icon_text_circle_btn/IconTextCircleBtn'
import {
  CameraIcon,
  CameraIconFixed,
  LocationIconFixed,
  PenIconFixed,
} from '../../../design_system/icons/icons'
import { useCallback, useEffect, useRef, useState } from 'react'
import NormalModal from '../../../design_system/components/normal_modal/NormalModal'
import { TitleBigDiv } from '../../../design_system/components/title_big_div/TitleBigDiv'
import { TextInput } from '../../../design_system/components/text_input/TextInput'
import { GoogleMap, MarkerF } from '@react-google-maps/api'
import {
  deleteImage,
  putDefaultGuideIconImageUrl,
  putDefaultGuideLogoImageRounded,
  putDefaultGuideLogoImageUrl,
  putDefaultGuideMobileImageUrl,
  putDefaultGuidePcImageUrl,
  putDefaultGuideRenameTabs,
  putEditDefaultGuideCoverDetails,
  putEditDefaultGuidePinLocation,
  uploadImage,
} from '../../../communication_system/axios_apis/axios_apis'
import { useLoading } from '../../../data_system/context/loading_context/LoadingContext'
import { googleMapsApiKey } from '../../../communication_system/keys/keys'
import { usePopup } from '../../../data_system/context/popup_context/PopupContext'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { formatPhoneNumber } from '../../../data_system/data_function/format_phone_number'
import {
  default_icon_image_url,
  default_logo_image_url,
  default_mobile_image_url,
  default_pc_image_url,
} from '../../../data_system/static_data/image_static_data'
import { emailCheck } from '../../../data_system/data_function/email_check_function'
import { koreanPhoneNumberCheck } from '../../../data_system/data_function/korean_phone_number_check_function'
import { TitleDiv } from '../../../design_system/components/title_div/TitleDiv'
import { QuestionTooltip } from '../../../design_system/components/question_tooltip/QuestionTooltip'
import { VerySmallOpacityBtn } from '../../../design_system/components/btn/pc/very_small_opacity_btn/VerySmallOpacityBtn'
import RadioBtnGroup from '../../../design_system/components/radio_btn_group/RadioBtnGroup'
import StandardModal from '../../../design_system/components/standard_modal/StandardModal'
import { useGoogleMaps } from '../../../data_system/context/google_maps_context/GoogleMapsContext'

const MainGuidesDefaultGuideCoverTab = ({
  defaultGuide,
  setDefaultGuide,
  logoImgUrlText,
  setLogoImgUrlText,
  iconImgUrlText,
  setIconImgUrlText,
  logoUploadImgUrl,
  setLogoUploadImgUrl,
  iconUploadImgUrl,
  setIconUploadImgUrl,
  selectedLogoRoundedStateRadioId,
  setSelectedLogoRoundedStateRadioId,
  logoRoundedStateRadioItems,
}) => {
  const { isLoaded, loadError } = useGoogleMaps()
  const { showLoading, hideLoading } = useLoading()
  const { show } = usePopup()

  const [editLocationModalVisible, setEditLocationModalVisible] =
    useState(false)
  const [coverImgFile, setCoverImgFile] = useState(null)
  const [coverImgUrlText, setCoverImgUrlText] = useState('')
  const [coverUploadImgUrl, setCoverUploadImgUrl] = useState('')
  const [logoImgFile, setLogoImgFile] = useState(null)
  const [iconImgFile, setIconImgFile] = useState(null)

  const [address, setAddress] = useState('')
  const [suggestions, setSuggestions] = useState([])
  const [zoom, setZoom] = useState(15)
  const [userInput, setUserInput] = useState(false) // 사용자 입력을 추적하는 상태

  const [mapWidth, setMapWidth] = useState(600)

  const [map, setMap] = useState(null)

  const onLoad = useCallback(function callback(map) {
    map.setZoom(zoom)

    setMap(map)
  }, [])

  const onUnmount = useCallback(function callback(map) {
    setMap(null)
  }, [])

  const [tab1Name, setTab1Name] = useState('')
  const [tab2Name, setTab2Name] = useState('')
  const [tab3Name, setTab3Name] = useState('')
  const [tab4Name, setTab4Name] = useState('')
  const [guideName, setGuideName] = useState('')
  const [hostName, setHostName] = useState('')
  const [email, setEmail] = useState('')
  const [hostPhoneNumber, setHostPhoneNumber] = useState('')

  const [editPcCoverPhotoModalVisible, setEditPcCoverPhotoModalVisible] =
    useState(false)

  const [
    editMobileCoverPhotoModalVisible,
    setEditMobileCoverPhotoModalVisible,
  ] = useState(false)

  const [
    editGuideCoverDetailsModalVisible,
    setEditGuideCoverDetailsModalVisible,
  ] = useState(false)

  const [editLogoModalVisible, setEditLogoModalVisible] = useState(false)
  const [editIconModalVisible, setEditIconModalVisible] = useState(false)

  const [logoDeleteModalVisible, setLogoDeleteModalVisible] = useState(false)
  const [iconDeleteModalVisible, setIconDeleteModalVisible] = useState(false)

  const fileInputRef = useRef(null)

  const logoFileInputRef = useRef(null)

  const iconFileInputRef = useRef(null)

  const handleLogoImgFileClick = (event) => {
    const file = event.target.files[0]
    setLogoImgFile(file)
    console.log(file)
    if (
      file &&
      (file.type === 'image/png' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/jpg')
    ) {
      const localImageUrl = URL.createObjectURL(file)
      console.log(localImageUrl)
      setLogoImgUrlText(localImageUrl)
    } else {
      alert('Please select a valid image (png, jpg, jpeg).')
    }
  }

  const handleIconImgFileClick = (event) => {
    const file = event.target.files[0]
    setIconImgFile(file)
    console.log(file)
    if (
      file &&
      (file.type === 'image/png' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/jpg')
    ) {
      const localImageUrl = URL.createObjectURL(file)
      console.log(localImageUrl)
      setIconImgUrlText(localImageUrl)
    } else {
      alert('Please select a valid image (png, jpg, jpeg).')
    }
  }

  const [position, setPosition] = useState({
    latitude: 37.5665,
    longitude: 126.978,
  })

  const [additionalAddress, setAdditionalAddress] = useState('')
  const [editRenameTabsModalVisible, setEditRenameTabsModalVisible] =
    useState(false)

  const onMarkerDragEnd = (event) => {
    setPosition({
      latitude: event.latLng.lat(),
      longitude: event.latLng.lng(),
    })
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    setCoverImgFile(file)
    console.log(file)
    if (
      file &&
      (file.type === 'image/png' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/jpg')
    ) {
      const localImageUrl = URL.createObjectURL(file)
      console.log(localImageUrl)
      setCoverImgUrlText(localImageUrl)
    } else {
      alert('Please select a valid image (png, jpg, jpeg).')
    }
  }

  const handleInputChange = (e) => {
    setAddress(e.target.value)
    setUserInput(true) // 사용자 입력이 변경되면 true로 설정
  }

  const getPlaceDetails = (placeId) => {
    const placesService = new window.google.maps.places.PlacesService(
      document.createElement('div')
    )

    placesService.getDetails(
      {
        placeId: placeId,
        fields: ['geometry'],
        language: 'ko',
      },
      (place, status) => {
        if (
          status === window.google.maps.places.PlacesServiceStatus.OK &&
          place.geometry
        ) {
          setPosition({
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng(),
          })
          setZoom(15)
          setSuggestions([]) // 해당 위치를 선택한 후에는 제안 목록을 초기화
        }
      }
    )
  }

  const handleSuggestionClick = (description, placeId) => {
    setAddress(description)
    setUserInput(false)
    getPlaceDetails(placeId) // 제안 클릭시에는 getPlaceDetails를 직접 호출
  }

  useEffect(() => {
    if (!address || !userInput) {
      setSuggestions([])
      return
    }

    if (window.google && window.google.maps && window.google.maps.places) {
      const autocompleteService =
        new window.google.maps.places.AutocompleteService()

      autocompleteService.getPlacePredictions(
        {
          input: address,
          componentRestrictions: { country: 'kr' },
          language: 'ko',
        },
        (predictions, status) => {
          if (
            status === window.google.maps.places.PlacesServiceStatus.OK &&
            predictions
          ) {
            setSuggestions(predictions)
          }
        }
      )
    }
  }, [address, userInput])

  useEffect(() => {
    const handleResize = () => {
      const mapDiv = document.querySelector('.map-div')
      if (mapDiv) {
        setMapWidth(mapDiv.offsetWidth)
      } else {
        setMapWidth(600)
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize() // 초기 실행

    return () => {
      window.removeEventListener('resize', handleResize) // 컴포넌트가 언마운트될 때 리스너를 제거합니다.
    }
  }, [])

  return (
    <>
      <GuideDetailsBody>
        <GuideLogoTabsTile>
          <GuideLogoTabsTileHeader>
            로고
            <IconTextCircleBtn
              text={'수정'}
              borderColor={'#ededed'}
              backgroundColor={'#ededed'}
              textColor={'#000'}
              onClick={() => {
                console.log(defaultGuide)
                setLogoImgUrlText(defaultGuide.logoImageUrl)
                setLogoUploadImgUrl(defaultGuide.logoImageUrl)
                setEditLogoModalVisible(true)
              }}
            >
              <PenIconFixed></PenIconFixed>
            </IconTextCircleBtn>
          </GuideLogoTabsTileHeader>
        </GuideLogoTabsTile>
        <GuideIconTabsTile>
          <GuideIconTabsTileHeader>
            아이콘
            <IconTextCircleBtn
              text={'수정'}
              borderColor={'#ededed'}
              backgroundColor={'#ededed'}
              textColor={'#000'}
              onClick={() => {
                setIconImgUrlText(defaultGuide.iconImageUrl)
                setIconUploadImgUrl(defaultGuide.iconImageUrl)
                setEditIconModalVisible(true)
              }}
            >
              <PenIconFixed></PenIconFixed>
            </IconTextCircleBtn>
          </GuideIconTabsTileHeader>
        </GuideIconTabsTile>
        <GuideCoverPhotosTile>
          <GuideCoverPhotosTileHeader>
            안내문 커버 이미지
          </GuideCoverPhotosTileHeader>
          <GuideCoverPhotosTileBody>
            <GuideCoverPhotosTileBodyLeft
              style={{
                backgroundImage: `url(${defaultGuide.pcImageUrl})`,
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  bottom: 15,
                  right: 15,
                }}
              >
                <IconTextCircleBtn
                  text={'PC버전 이미지'}
                  borderColor={'#ededed'}
                  backgroundColor={'#ededed'}
                  textColor={'#000'}
                  onClick={() => {
                    setCoverImgUrlText(defaultGuide.pcImageUrl)
                    setCoverUploadImgUrl(defaultGuide.pcImageUrl)
                    setEditPcCoverPhotoModalVisible(true)
                  }}
                >
                  <CameraIconFixed></CameraIconFixed>
                </IconTextCircleBtn>
              </div>
            </GuideCoverPhotosTileBodyLeft>
            <GuideCoverPhotosTileBodyRight
              style={{
                backgroundImage: `url(${defaultGuide.mobileImageUrl})`,
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  bottom: 15,
                  right: 15,
                }}
              >
                <IconTextCircleBtn
                  text={'모바일버전 이미지'}
                  borderColor={'#ededed'}
                  backgroundColor={'#ededed'}
                  textColor={'#000'}
                  onClick={() => {
                    setCoverImgUrlText(defaultGuide.mobileImageUrl)
                    setCoverUploadImgUrl(defaultGuide.mobileImageUrl)
                    setEditMobileCoverPhotoModalVisible(true)
                  }}
                >
                  <CameraIconFixed></CameraIconFixed>
                </IconTextCircleBtn>
              </div>
            </GuideCoverPhotosTileBodyRight>
          </GuideCoverPhotosTileBody>
        </GuideCoverPhotosTile>
        <GuideCoverDetailsTile>
          <GuideCoverDetailsTileHeader>
            안내문 상세 내용
            <IconTextCircleBtn
              text={'수정'}
              borderColor={'#ededed'}
              backgroundColor={'#ededed'}
              textColor={'#000'}
              onClick={() => {
                setGuideName(defaultGuide.guideName)
                setHostName(defaultGuide.hostName)
                setEmail(defaultGuide.email)
                setHostPhoneNumber(defaultGuide.hostPhoneNumber)
                setEditGuideCoverDetailsModalVisible(true)
              }}
            >
              <PenIconFixed></PenIconFixed>
            </IconTextCircleBtn>
          </GuideCoverDetailsTileHeader>
          <GuideCoverDetailsTileBody>
            <GuideCoverDetailsTileBodyPart1>
              <GuideCoverDetailsTileBodyTitle>
                숙소/안내문 이름
              </GuideCoverDetailsTileBodyTitle>
              <GuideCoverDetailsTileBodyP>
                {defaultGuide.guideName}
              </GuideCoverDetailsTileBodyP>
            </GuideCoverDetailsTileBodyPart1>
            <GuideCoverDetailsTileBodyPart2>
              <GuideCoverDetailsTileBodyTitle>
                호스트 이름/인사말
              </GuideCoverDetailsTileBodyTitle>
              <GuideCoverDetailsTileBodyP>
                {defaultGuide.hostName}
              </GuideCoverDetailsTileBodyP>
            </GuideCoverDetailsTileBodyPart2>
            <GuideCoverDetailsTileBodyPart3>
              <GuideCoverDetailsTileBodyTitle>
                이메일
              </GuideCoverDetailsTileBodyTitle>
              <GuideCoverDetailsTileBodyP>
                {defaultGuide.email}
              </GuideCoverDetailsTileBodyP>
            </GuideCoverDetailsTileBodyPart3>
            <GuideCoverDetailsTileBodyPart4>
              <GuideCoverDetailsTileBodyTitle>
                연락처
              </GuideCoverDetailsTileBodyTitle>
              <GuideCoverDetailsTileBodyP>
                {defaultGuide.hostPhoneNumber !== null &&
                defaultGuide.hostPhoneNumber !== '' &&
                defaultGuide.hostPhoneNumber !== undefined
                  ? formatPhoneNumber(defaultGuide.hostPhoneNumber)
                  : ''}
              </GuideCoverDetailsTileBodyP>
            </GuideCoverDetailsTileBodyPart4>
          </GuideCoverDetailsTileBody>
        </GuideCoverDetailsTile>
        <GuidePropertyAddressLocationTile>
          <GuidePropertyAddressLocationTileHeader>
            숙소 주소/위치
          </GuidePropertyAddressLocationTileHeader>
          <GuidePropertyAddressLocationTileBody>
            <GuidePropertyAddressLocationTileBodyPart1>
              <GuidePropertyAddressLocationTileBodyPart1Map
                $position={position}
                $mapWidth={mapWidth}
              ></GuidePropertyAddressLocationTileBodyPart1Map>
            </GuidePropertyAddressLocationTileBodyPart1>
            <GuidePropertyAddressLocationTileBodyPart2>
              <GuidePropertyAddressLocationTileBodyPart2Title>
                숙소 위치
              </GuidePropertyAddressLocationTileBodyPart2Title>
              <div
                style={{
                  paddingTop: 25,
                  boxSizing: 'border-box',
                }}
              >
                <IconTextCircleBtn
                  text={'지도 핀 조정하기'}
                  borderColor={'#ededed'}
                  backgroundColor={'#ededed'}
                  textColor={'#000'}
                  onClick={() => {
                    setPosition({
                      latitude:
                        defaultGuide.latitude !== null
                          ? defaultGuide.latitude
                          : 37.5665,
                      longitude:
                        defaultGuide.longitude !== null
                          ? defaultGuide.longitude
                          : 126.978,
                    })
                    setAddress(
                      defaultGuide.address !== null ? defaultGuide.address : ''
                    )
                    setAdditionalAddress(
                      defaultGuide.additionalAddress !== null
                        ? defaultGuide.additionalAddress
                        : ''
                    )
                    setEditLocationModalVisible(true)
                  }}
                >
                  <LocationIconFixed></LocationIconFixed>
                </IconTextCircleBtn>
              </div>
            </GuidePropertyAddressLocationTileBodyPart2>
          </GuidePropertyAddressLocationTileBody>
        </GuidePropertyAddressLocationTile>
        <GuideRenameTabsTile>
          <GuideRenameTabsTileHeader>
            하단 메뉴 수정
            <IconTextCircleBtn
              text={'수정'}
              borderColor={'#ededed'}
              backgroundColor={'#ededed'}
              textColor={'#000'}
              onClick={() => {
                console.log(defaultGuide)
                setTab1Name(defaultGuide.tab1Name)
                setTab2Name(defaultGuide.tab2Name)
                setTab3Name(defaultGuide.tab3Name)
                setTab4Name(defaultGuide.tab4Name)
                setEditRenameTabsModalVisible(true)
              }}
            >
              <PenIconFixed></PenIconFixed>
            </IconTextCircleBtn>
          </GuideRenameTabsTileHeader>
        </GuideRenameTabsTile>
      </GuideDetailsBody>
      {editPcCoverPhotoModalVisible && (
        <NormalModal
          titleText={`PC버전 이미지 수정하기`}
          leftBtnVisible={true}
          middleBtnText={'삭제'}
          middleBtnBackgroundColor="#f56c6c"
          middleBtnVisible={true}
          modalBodyComponent={
            <div>
              <div
                style={{
                  height: 175,
                  color: '#d3d3d3',
                  backgroundSize: 'cover',
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundPosition: 'center center',
                  backgroundRepeat: 'no-repeat',
                  alignItems: 'center',
                  justifyContent: 'center',
                  boxSizing: 'border-box',
                  backgroundImage: `url(${coverImgUrlText || 'unknown'})`,
                }}
              >
                {coverImgUrlText === '' ? <CameraIcon></CameraIcon> : <></>}
              </div>
              <TitleBigDiv>이미지 추가하기</TitleBigDiv>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <input
                  type="file"
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  accept=".png, .jpg, .jpeg"
                />
                <IconTextCircleBtn
                  text={'이미지 업로드'}
                  borderColor={'#ededed'}
                  backgroundColor={'#ededed'}
                  textColor={'#000'}
                  onClick={() => {
                    fileInputRef.current.click()
                  }}
                >
                  <CameraIconFixed></CameraIconFixed>
                </IconTextCircleBtn>
              </div>
            </div>
          }
          onRightBtnClick={async () => {
            if (coverImgFile !== null) {
              showLoading()

              if (defaultGuide.pcImageUrl !== default_pc_image_url) {
                const imageDeleteResponse = await deleteImage(coverUploadImgUrl)

                if (imageDeleteResponse.status === 200) {
                  const imageUploadResponse = await uploadImage(coverImgFile)
                  console.log(imageUploadResponse)

                  if (imageUploadResponse.status === 200) {
                    const putDefaultGuidePcImageUrlResponse =
                      await putDefaultGuidePcImageUrl(defaultGuide.id, {
                        pcImageUrl: imageUploadResponse.data.imageUrl,
                      })

                    if (putDefaultGuidePcImageUrlResponse.status === 200) {
                      setCoverImgUrlText(imageUploadResponse.data.imageUrl)
                      setCoverUploadImgUrl(imageUploadResponse.data.imageUrl)
                      setCoverImgFile(null)
                      setDefaultGuide({
                        ...defaultGuide,
                        pcImageUrl: imageUploadResponse.data.imageUrl,
                      })
                      setEditPcCoverPhotoModalVisible(false)
                    }
                  }
                }
              } else {
                const imageUploadResponse = await uploadImage(coverImgFile)
                console.log(imageUploadResponse)

                if (imageUploadResponse.status === 200) {
                  const putDefaultGuidePcImageUrlResponse =
                    await putDefaultGuidePcImageUrl(defaultGuide.id, {
                      pcImageUrl: imageUploadResponse.data.imageUrl,
                    })

                  if (putDefaultGuidePcImageUrlResponse.status === 200) {
                    setCoverImgUrlText(imageUploadResponse.data.imageUrl)
                    setCoverUploadImgUrl(imageUploadResponse.data.imageUrl)
                    setCoverImgFile(null)
                    setDefaultGuide({
                      ...defaultGuide,
                      pcImageUrl: imageUploadResponse.data.imageUrl,
                    })
                    setEditPcCoverPhotoModalVisible(false)
                  }
                }
              }

              hideLoading()
            } else {
              console.log('no file')
              showLoading()

              setCoverImgUrlText('')
              setCoverUploadImgUrl('')
              setCoverImgFile(null)
              setEditPcCoverPhotoModalVisible(false)

              hideLoading()
            }
          }}
          onMiddleBtnClick={async () => {
            console.log(defaultGuide.pcImageUrl)
            if (defaultGuide.pcImageUrl !== default_pc_image_url) {
              showLoading()

              const imageDeleteResponse = await deleteImage(coverUploadImgUrl)
              console.log(imageDeleteResponse)

              if (imageDeleteResponse.status === 200) {
                const putDefaultGuidePcImageUrlResponse =
                  await putDefaultGuidePcImageUrl(defaultGuide.id, {
                    pcImageUrl: default_pc_image_url,
                  })

                if (putDefaultGuidePcImageUrlResponse.status === 200) {
                  setDefaultGuide({
                    ...defaultGuide,
                    pcImageUrl: default_pc_image_url,
                  })
                  setCoverImgUrlText(default_pc_image_url)
                  setCoverUploadImgUrl('')
                  setCoverImgFile(null)
                }
              }

              hideLoading()
            } else {
              show('already default image', false)
            }
          }}
          onClose={() => {
            setCoverImgUrlText(coverUploadImgUrl)
            setCoverImgFile(null)
            setEditPcCoverPhotoModalVisible(false)
          }}
          rightBtnText={'저장'}
          rightBtnDisabled={false}
          questionSection={<p>---</p>}
          questionDefaultWidth={500}
          questionChangeWidth={180}
        ></NormalModal>
      )}
      {editMobileCoverPhotoModalVisible && (
        <NormalModal
          titleText={`모바일버전 이미지 수정하기`}
          leftBtnVisible={true}
          middleBtnText={'삭제'}
          middleBtnBackgroundColor="#f56c6c"
          middleBtnVisible={true}
          modalBodyComponent={
            <div>
              <div
                style={{
                  height: 175,
                  color: '#d3d3d3',
                  backgroundSize: 'cover',
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundPosition: 'center center',
                  backgroundRepeat: 'no-repeat',
                  alignItems: 'center',
                  justifyContent: 'center',
                  boxSizing: 'border-box',
                  backgroundImage: `url(${coverImgUrlText || 'unknown'})`,
                }}
              >
                {coverImgUrlText === '' ? <CameraIcon></CameraIcon> : <></>}
              </div>
              <TitleBigDiv>이미지 추가하기</TitleBigDiv>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <input
                  type="file"
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  accept=".png, .jpg, .jpeg"
                />
                <IconTextCircleBtn
                  text={'이미지 업로드'}
                  borderColor={'#ededed'}
                  backgroundColor={'#ededed'}
                  textColor={'#000'}
                  onClick={() => {
                    fileInputRef.current.click()
                  }}
                >
                  <CameraIconFixed></CameraIconFixed>
                </IconTextCircleBtn>
              </div>
            </div>
          }
          onRightBtnClick={async () => {
            if (coverImgFile !== null) {
              showLoading()

              if (defaultGuide.mobileImageUrl !== default_mobile_image_url) {
                const imageDeleteResponse = await deleteImage(coverUploadImgUrl)

                if (imageDeleteResponse.status === 200) {
                  const imageUploadResponse = await uploadImage(coverImgFile)
                  console.log(imageUploadResponse)

                  if (imageUploadResponse.status === 200) {
                    const putDefaultGuideMobileImageUrlResponse =
                      await putDefaultGuideMobileImageUrl(defaultGuide.id, {
                        mobileImageUrl: imageUploadResponse.data.imageUrl,
                      })

                    if (putDefaultGuideMobileImageUrlResponse.status === 200) {
                      setCoverImgUrlText(imageUploadResponse.data.imageUrl)
                      setCoverUploadImgUrl(imageUploadResponse.data.imageUrl)
                      setCoverImgFile(null)
                      setDefaultGuide({
                        ...defaultGuide,
                        mobileImageUrl: imageUploadResponse.data.imageUrl,
                      })
                      setEditMobileCoverPhotoModalVisible(false)
                    }
                  }
                }
              } else {
                const imageUploadResponse = await uploadImage(coverImgFile)
                console.log(imageUploadResponse)

                if (imageUploadResponse.status === 200) {
                  const putDefaultGuideMobileImageUrlResponse =
                    await putDefaultGuideMobileImageUrl(defaultGuide.id, {
                      mobileImageUrl: imageUploadResponse.data.imageUrl,
                    })

                  if (putDefaultGuideMobileImageUrlResponse.status === 200) {
                    setCoverImgUrlText(imageUploadResponse.data.imageUrl)
                    setCoverUploadImgUrl(imageUploadResponse.data.imageUrl)
                    setCoverImgFile(null)
                    setDefaultGuide({
                      ...defaultGuide,
                      mobileImageUrl: imageUploadResponse.data.imageUrl,
                    })
                    setEditMobileCoverPhotoModalVisible(false)
                  }
                }
              }

              hideLoading()
            } else {
              showLoading()

              setCoverImgUrlText('')
              setCoverUploadImgUrl('')
              setCoverImgFile(null)
              setEditMobileCoverPhotoModalVisible(false)

              hideLoading()
            }
          }}
          onMiddleBtnClick={async () => {
            console.log(defaultGuide.mobileImageUrl)
            if (defaultGuide.mobileImageUrl !== default_mobile_image_url) {
              showLoading()

              const imageDeleteResponse = await deleteImage(coverUploadImgUrl)
              console.log(imageDeleteResponse)

              if (imageDeleteResponse.status === 200) {
                const putDefaultGuideMobileImageUrlResponse =
                  await putDefaultGuideMobileImageUrl(defaultGuide.id, {
                    mobileImageUrl: default_mobile_image_url,
                  })

                if (putDefaultGuideMobileImageUrlResponse.status === 200) {
                  setDefaultGuide({
                    ...defaultGuide,
                    mobileImageUrl: default_mobile_image_url,
                  })
                  setCoverImgUrlText(default_mobile_image_url)
                  setCoverUploadImgUrl('')
                  setCoverImgFile(null)
                }
              }

              hideLoading()
            } else {
              show('already default image', false)
            }
          }}
          onClose={() => {
            setCoverImgUrlText(coverUploadImgUrl)
            setCoverImgFile(null)
            setEditMobileCoverPhotoModalVisible(false)
          }}
          rightBtnText={'저장'}
          rightBtnDisabled={false}
          questionSection={<p>---</p>}
          questionDefaultWidth={500}
          questionChangeWidth={180}
        ></NormalModal>
      )}
      {editGuideCoverDetailsModalVisible && (
        <NormalModal
          titleText={`안내문 상세 내용 수정하기`}
          leftBtnVisible={true}
          modalBodyComponent={
            <div>
              <TitleBigDiv>숙소/안내문 이름</TitleBigDiv>
              <TextInput
                backgroundColor="#fff"
                value={guideName}
                onChange={(e) => {
                  setGuideName(e.target.value)
                }}
              ></TextInput>
              <TitleBigDiv>호스트 이름/인사말</TitleBigDiv>
              <TextInput
                backgroundColor="#fff"
                value={hostName}
                onChange={(e) => {
                  setHostName(e.target.value)
                }}
              ></TextInput>
              <TitleBigDiv>이메일</TitleBigDiv>
              <TextInput
                backgroundColor="#fff"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
              ></TextInput>
              <TitleBigDiv>연락처</TitleBigDiv>
              <TextInput
                backgroundColor="#fff"
                value={hostPhoneNumber}
                onChange={(e) => {
                  setHostPhoneNumber(e.target.value)
                }}
              ></TextInput>
            </div>
          }
          onRightBtnClick={async () => {
            showLoading()

            if (
              guideName === '' ||
              hostName === '' ||
              email === '' ||
              hostPhoneNumber === '' ||
              emailCheck.test(email) === false ||
              koreanPhoneNumberCheck.test(hostPhoneNumber) === false
            ) {
              show('모든 항목을 입력해주세요', false)
              hideLoading()
              return
            }

            const putEditDefaultGuideCoverDetailsResponse =
              await putEditDefaultGuideCoverDetails(defaultGuide.id, {
                guideName: guideName,
                hostName: hostName,
                email: email,
                hostPhoneNumber: hostPhoneNumber,
              })

            if (putEditDefaultGuideCoverDetailsResponse.status === 200) {
              setDefaultGuide({
                ...defaultGuide,
                guideName: guideName,
                hostName: hostName,
                email: email,
                hostPhoneNumber: hostPhoneNumber,
              })
              setEditGuideCoverDetailsModalVisible(false)
            }

            hideLoading()
          }}
          onClose={() => {
            setGuideName(defaultGuide.guideName)
            setHostName(defaultGuide.hostName)
            setEmail(defaultGuide.email)
            setHostPhoneNumber(defaultGuide.hostPhoneNumber)
            setEditGuideCoverDetailsModalVisible(false)
          }}
          rightBtnText={'저장'}
          rightBtnDisabled={false}
          questionSection={<p>---</p>}
          questionDefaultWidth={500}
          questionChangeWidth={180}
        ></NormalModal>
      )}
      {editLocationModalVisible && (
        <NormalModal
          titleText={`핀 조정하기`}
          leftBtnVisible={true}
          modalBodyComponent={
            <div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    width: '45%',
                  }}
                >
                  <TitleBigDiv>위도</TitleBigDiv>
                  <TextInput
                    backgroundColor="#fff"
                    value={position.latitude}
                  ></TextInput>
                </div>
                <div
                  style={{
                    width: '45%',
                  }}
                >
                  <TitleBigDiv>경도</TitleBigDiv>
                  <TextInput
                    backgroundColor="#fff"
                    value={position.longitude}
                  ></TextInput>
                </div>
              </div>
              {isLoaded && (
                <div>
                  <TitleBigDiv>주소로 검색하기</TitleBigDiv>
                  <TextInput
                    backgroundColor="#fff"
                    value={address}
                    onChange={handleInputChange}
                  ></TextInput>
                  {suggestions.length !== 0 && (
                    <div
                      style={{
                        border: '1px solid #dcdfe6',
                        borderRadius: '8px',
                        boxSizing: 'border-box',
                        maxHeight: '200px',
                        overflowY: 'auto',
                        width: '100%',
                        backgroundColor: '#ffffff',
                        marginTop: 15,
                      }}
                    >
                      {suggestions.map((suggestion) => (
                        <div
                          key={suggestion.place_id}
                          style={{
                            padding: '8px 15px',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            handleSuggestionClick(
                              suggestion.description,
                              suggestion.place_id
                            )
                          }}
                        >
                          {suggestion.description}
                        </div>
                      ))}
                    </div>
                  )}
                  <TitleBigDiv>상세 주소</TitleBigDiv>
                  <TextInput
                    backgroundColor="#fff"
                    value={additionalAddress}
                    onChange={(e) => {
                      setAdditionalAddress(e.target.value)
                    }}
                  ></TextInput>
                  <TitleBigDiv>핀 드래그 & 조정</TitleBigDiv>
                  <GoogleMap
                    mapContainerStyle={{ width: '100%', height: '400px' }}
                    center={{
                      lat: position.latitude,
                      lng: position.longitude,
                    }}
                    zoom={zoom} // 상태로 관리되는 줌 레벨 사용
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                  >
                    <MarkerF
                      position={{
                        lat: position.latitude,
                        lng: position.longitude,
                      }}
                      draggable={true}
                      icon={{
                        url: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
                        scaledSize: new window.google.maps.Size(40, 40),
                      }}
                      onDragEnd={onMarkerDragEnd}
                    />
                  </GoogleMap>
                </div>
              )}
            </div>
          }
          onRightBtnClick={async () => {
            showLoading()

            const putEditDefaultGuidePinLocationResponse =
              await putEditDefaultGuidePinLocation(defaultGuide.id, {
                address: address,
                additionalAddress: additionalAddress,
                latitude: position.latitude,
                longitude: position.longitude,
              })

            if (putEditDefaultGuidePinLocationResponse.status === 200) {
              setDefaultGuide({
                ...defaultGuide,
                address: address,
                additionalAddress: additionalAddress,
                latitude: position.latitude,
                longitude: position.longitude,
              })
              setEditLocationModalVisible(false)
            }

            hideLoading()
          }}
          onClose={() => {
            setPosition({
              latitude:
                defaultGuide.latitude !== null
                  ? defaultGuide.latitude
                  : 37.5665,
              longitude:
                defaultGuide.longitude !== null
                  ? defaultGuide.longitude
                  : 126.978,
            })
            setAddress(
              defaultGuide.address !== null ? defaultGuide.address : ''
            )
            setAdditionalAddress(
              defaultGuide.additionalAddress !== null
                ? defaultGuide.additionalAddress
                : ''
            )
            setEditLocationModalVisible(false)
          }}
          rightBtnText={'저장'}
          rightBtnDisabled={
            address !== '' &&
            position.latitude !== null &&
            position.longitude !== null
              ? false
              : true
          }
          questionSection={<p>---</p>}
          questionDefaultWidth={500}
          questionChangeWidth={180}
        ></NormalModal>
      )}
      {editRenameTabsModalVisible && (
        <NormalModal
          titleText={`하단 메뉴 수정`}
          leftBtnVisible={true}
          modalBodyComponent={
            <div>
              <TitleBigDiv>첫번째 메뉴</TitleBigDiv>
              <TextInput
                backgroundColor="#fff"
                value={tab1Name}
                onChange={(e) => {
                  setTab1Name(e.target.value)
                }}
              ></TextInput>
              <TitleBigDiv>두번째 메뉴</TitleBigDiv>
              <TextInput
                backgroundColor="#fff"
                value={tab2Name}
                onChange={(e) => {
                  setTab2Name(e.target.value)
                }}
              ></TextInput>
              <TitleBigDiv>상점 메뉴</TitleBigDiv>
              <TextInput
                backgroundColor="#fff"
                value={tab3Name}
                onChange={(e) => {
                  setTab3Name(e.target.value)
                }}
              ></TextInput>
              <TitleBigDiv>지도 메뉴</TitleBigDiv>
              <TextInput
                backgroundColor="#fff"
                value={tab4Name}
                onChange={(e) => {
                  setTab4Name(e.target.value)
                }}
              ></TextInput>
            </div>
          }
          onRightBtnClick={async () => {
            showLoading()

            const putDefaultGuideRenameTabsResponse =
              await putDefaultGuideRenameTabs(defaultGuide.id, {
                tab1Name: tab1Name,
                tab2Name: tab2Name,
                tab3Name: tab3Name,
                tab4Name: tab4Name,
              })

            if (putDefaultGuideRenameTabsResponse.status === 200) {
              setDefaultGuide({
                ...defaultGuide,
                tab1Name: tab1Name,
                tab2Name: tab2Name,
                tab3Name: tab3Name,
                tab4Name: tab4Name,
              })
              setEditRenameTabsModalVisible(false)
            }

            hideLoading()
          }}
          onClose={() => {
            setTab1Name(defaultGuide.tab1Name)
            setTab2Name(defaultGuide.tab2Name)
            setTab3Name(defaultGuide.tab3Name)
            setTab4Name(defaultGuide.tab4Name)
            setEditRenameTabsModalVisible(false)
          }}
          rightBtnText={'저장'}
          rightBtnDisabled={false}
          questionSectionShow={true}
          questionSection={
            <p>
              게스트에게 노출되는 안내문의 하단 메뉴 이름을 수정할 수 있습니다.
            </p>
          }
          questionDefaultWidth={500}
          questionChangeWidth={180}
        ></NormalModal>
      )}
      {editLogoModalVisible && (
        <NormalModal
          titleText={'로고 수정'}
          modalBodyComponent={
            <div
              style={{
                display: 'block',
              }}
            >
              <EditLogoBody>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexBasis: '100%',
                  }}
                >
                  <TitleDiv>로고 수정하기</TitleDiv>
                  <div
                    style={{
                      marginLeft: 8,
                    }}
                  >
                    <QuestionTooltip
                      questionSection={
                        <p>
                          온라인 안내문의 메인화면 로고가 수정됩니다. 권장사이즈
                          50~200px, .jpg 혹은 .png 파일로 업로드 해주세요.
                        </p>
                      }
                      defaultWidth={500}
                      changeWidth={180}
                    ></QuestionTooltip>
                  </div>
                </div>
                <EditLogoWrapper>
                  <EditLogoCameraWrapper $beingImage={logoImgUrlText !== null}>
                    {logoImgUrlText !== null ? (
                      <StyledImage
                        src={logoImgUrlText}
                        alt="Uploaded logo"
                        $shape={selectedLogoRoundedStateRadioId}
                      />
                    ) : (
                      <CameraIcon></CameraIcon>
                    )}
                  </EditLogoCameraWrapper>
                </EditLogoWrapper>
                <EditLogoBtnWrapper>
                  <input
                    type="file"
                    style={{ display: 'none' }}
                    ref={logoFileInputRef}
                    onChange={handleLogoImgFileClick}
                    accept=".png, .jpg, .jpeg"
                  />
                  <VerySmallOpacityBtn
                    fontColor={'#000'}
                    backgroundColor={'#ededed'}
                    borderColor={'#ededed'}
                    onClick={() => {
                      logoFileInputRef.current.click()
                    }}
                  >
                    로고 업로드
                  </VerySmallOpacityBtn>
                  <div
                    style={{
                      marginTop: 10,
                    }}
                  >
                    <VerySmallOpacityBtn
                      fontColor={'#fff'}
                      backgroundColor={'#f56c6c'}
                      borderColor={'#f56c6c'}
                      onClick={() => {
                        setLogoDeleteModalVisible(true)
                      }}
                    >
                      삭제
                    </VerySmallOpacityBtn>
                  </div>
                </EditLogoBtnWrapper>
                <EditLogoBodyRadioSection>
                  <RadioBtnGroup
                    radioItems={logoRoundedStateRadioItems}
                    selectedId={selectedLogoRoundedStateRadioId}
                    setSelectedId={setSelectedLogoRoundedStateRadioId}
                  ></RadioBtnGroup>
                </EditLogoBodyRadioSection>
              </EditLogoBody>
            </div>
          }
          onRightBtnClick={async () => {
            showLoading()
            console.log(logoImgFile)
            if (logoImgFile !== null) {
              if (defaultGuide.logoImageUrl !== default_logo_image_url) {
                const imageDeleteResponse = await deleteImage(logoUploadImgUrl)

                if (imageDeleteResponse.status === 200) {
                  const imageUploadResponse = await uploadImage(logoImgFile)
                  console.log(imageUploadResponse)

                  if (imageUploadResponse.status === 200) {
                    const putDefaultGuideLogoImageUrlResponse =
                      await putDefaultGuideLogoImageUrl(defaultGuide.id, {
                        logoImageUrl: imageUploadResponse.data.imageUrl,
                      })

                    if (putDefaultGuideLogoImageUrlResponse.status === 200) {
                      const putDefaultGuideLogoImageRoundedResponse =
                        await putDefaultGuideLogoImageRounded(defaultGuide.id, {
                          logoImageRounded:
                            selectedLogoRoundedStateRadioId === '1'
                              ? true
                              : false,
                        })

                      if (
                        putDefaultGuideLogoImageRoundedResponse.status === 200
                      ) {
                        setLogoImgUrlText(imageUploadResponse.data.imageUrl)
                        setLogoUploadImgUrl(imageUploadResponse.data.imageUrl)
                        setLogoImgFile(null)
                        setDefaultGuide((preDefaultGuide) => {
                          return {
                            ...preDefaultGuide,
                            logoImageUrl: imageUploadResponse.data.imageUrl,
                            logoImageRounded:
                              selectedLogoRoundedStateRadioId === '1'
                                ? true
                                : false,
                          }
                        })
                        setEditLogoModalVisible(false)
                      }
                    }
                  }
                }
              } else {
                const imageUploadResponse = await uploadImage(logoImgFile)
                console.log(imageUploadResponse)

                if (imageUploadResponse.status === 200) {
                  const putDefaultGuideLogoImageUrlResponse =
                    await putDefaultGuideLogoImageUrl(defaultGuide.id, {
                      logoImageUrl: imageUploadResponse.data.imageUrl,
                    })

                  if (putDefaultGuideLogoImageUrlResponse.status === 200) {
                    const putDefaultGuideLogoImageRoundedResponse =
                      await putDefaultGuideLogoImageRounded(defaultGuide.id, {
                        logoImageRounded:
                          selectedLogoRoundedStateRadioId === '1'
                            ? true
                            : false,
                      })

                    if (
                      putDefaultGuideLogoImageRoundedResponse.status === 200
                    ) {
                      setLogoImgUrlText(imageUploadResponse.data.imageUrl)
                      setLogoUploadImgUrl(imageUploadResponse.data.imageUrl)
                      setLogoImgFile(null)
                      setDefaultGuide((preDefaultGuide) => {
                        return {
                          ...preDefaultGuide,
                          logoImageUrl: imageUploadResponse.data.imageUrl,
                          logoImageRounded:
                            selectedLogoRoundedStateRadioId === '1'
                              ? true
                              : false,
                        }
                      })
                      setEditLogoModalVisible(false)
                    }
                  }
                }
              }
            } else {
              const putDefaultGuideLogoImageRoundedResponse =
                await putDefaultGuideLogoImageRounded(defaultGuide.id, {
                  logoImageRounded:
                    selectedLogoRoundedStateRadioId === '1' ? true : false,
                })

              if (putDefaultGuideLogoImageRoundedResponse.status === 200) {
                setLogoImgUrlText(defaultGuide.logoImageUrl)
                setLogoUploadImgUrl(defaultGuide.logoImageUrl)
                setLogoImgFile(null)
                setDefaultGuide((preDefaultGuide) => {
                  return {
                    ...preDefaultGuide,
                    logoImageRounded:
                      selectedLogoRoundedStateRadioId === '1' ? true : false,
                  }
                })
                setEditLogoModalVisible(false)
              }
            }

            hideLoading()
          }}
          onClose={() => {
            setLogoImgUrlText(defaultGuide.logoImageUrl)
            setLogoUploadImgUrl(defaultGuide.logoImageUrl)
            setLogoImgFile(null)
            setEditLogoModalVisible(false)
          }}
          rightBtnText={'저장'}
          questionSection={
            <p>Update the default logo and icon for your guides from here.</p>
          }
          questionDefaultWidth={500}
          questionChangeWidth={180}
        ></NormalModal>
      )}
      {editIconModalVisible && (
        <NormalModal
          titleText={'아이콘 수정'}
          modalBodyComponent={
            <div
              style={{
                display: 'block',
              }}
            >
              <EditIconBody>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexBasis: '100%',
                  }}
                >
                  <TitleDiv>아이콘 수정하기</TitleDiv>
                  <div
                    style={{
                      marginLeft: 8,
                    }}
                  >
                    <QuestionTooltip
                      questionSection={
                        <p>
                          PC에서 볼 때 브라우저 탭에 표시되는 파비콘입니다.
                          권장사이즈 192x192px, .jpg 혹은 .png 파일로 업로드
                          해주세요.
                        </p>
                      }
                      defaultWidth={500}
                      changeWidth={180}
                    ></QuestionTooltip>
                  </div>
                </div>
                <EditIconWrapper>
                  <EditIconCameraWrapper $beingImage={iconImgUrlText !== null}>
                    {iconImgUrlText !== null ? (
                      <StyledImage
                        src={iconImgUrlText}
                        alt="Uploaded icon"
                        $shape={'0'}
                      />
                    ) : (
                      <CameraIcon></CameraIcon>
                    )}
                  </EditIconCameraWrapper>
                </EditIconWrapper>
                <EditIconBtnWrapper>
                  <input
                    type="file"
                    style={{ display: 'none' }}
                    ref={iconFileInputRef}
                    onChange={handleIconImgFileClick}
                    accept=".png, .jpg, .jpeg"
                  />
                  <VerySmallOpacityBtn
                    fontColor={'#000'}
                    backgroundColor={'#ededed'}
                    borderColor={'#ededed'}
                    onClick={() => {
                      iconFileInputRef.current.click()
                    }}
                  >
                    아이콘 업로드
                  </VerySmallOpacityBtn>
                  <div
                    style={{
                      marginTop: 10,
                    }}
                  >
                    <VerySmallOpacityBtn
                      fontColor={'#fff'}
                      backgroundColor={'#f56c6c'}
                      borderColor={'#f56c6c'}
                      onClick={() => {
                        setIconDeleteModalVisible(true)
                      }}
                    >
                      삭제
                    </VerySmallOpacityBtn>
                  </div>
                </EditIconBtnWrapper>
              </EditIconBody>
            </div>
          }
          onRightBtnClick={async () => {
            showLoading()
            console.log(iconImgFile)
            if (iconImgFile !== null) {
              if (defaultGuide.iconImageUrl !== default_icon_image_url) {
                const imageDeleteResponse = await deleteImage(iconUploadImgUrl)

                if (imageDeleteResponse.status === 200) {
                  const imageUploadResponse = await uploadImage(iconImgFile)
                  console.log(imageUploadResponse)

                  if (imageUploadResponse.status === 200) {
                    const putDefaultGuideIconImageUrlResponse =
                      await putDefaultGuideIconImageUrl(defaultGuide.id, {
                        iconImageUrl: imageUploadResponse.data.imageUrl,
                      })

                    if (putDefaultGuideIconImageUrlResponse.status === 200) {
                      setIconImgUrlText(imageUploadResponse.data.imageUrl)
                      setIconUploadImgUrl(imageUploadResponse.data.imageUrl)
                      setIconImgFile(null)
                      setDefaultGuide((preDefaultGuide) => {
                        return {
                          ...preDefaultGuide,
                          iconImageUrl: imageUploadResponse.data.imageUrl,
                        }
                      })
                      setEditIconModalVisible(false)
                    }
                  }
                }
              } else {
                const imageUploadResponse = await uploadImage(iconImgFile)
                console.log(imageUploadResponse)

                if (imageUploadResponse.status === 200) {
                  const putDefaultGuideIconImageUrlResponse =
                    await putDefaultGuideIconImageUrl(defaultGuide.id, {
                      iconImageUrl: imageUploadResponse.data.imageUrl,
                    })

                  if (putDefaultGuideIconImageUrlResponse.status === 200) {
                    setIconImgUrlText(imageUploadResponse.data.imageUrl)
                    setIconUploadImgUrl(imageUploadResponse.data.imageUrl)
                    setIconImgFile(null)
                    setDefaultGuide((preDefaultGuide) => {
                      return {
                        ...preDefaultGuide,
                        iconImageUrl: imageUploadResponse.data.imageUrl,
                      }
                    })
                    setEditIconModalVisible(false)
                  }
                }
              }
            } else {
              setIconImgUrlText(defaultGuide.iconImageUrl)
              setIconUploadImgUrl(defaultGuide.iconImageUrl)
              setIconImgFile(null)
              setDefaultGuide((preDefaultGuide) => {
                return {
                  ...preDefaultGuide,
                }
              })
              setEditIconModalVisible(false)
            }

            hideLoading()
          }}
          onClose={() => {
            setIconImgUrlText(defaultGuide.logoImageUrl)
            setIconUploadImgUrl(defaultGuide.logoImageUrl)
            setIconImgFile(null)
            setEditIconModalVisible(false)
          }}
          rightBtnText={'저장'}
          questionSection={
            <p>Update the default logo and icon for your guides from here.</p>
          }
          questionDefaultWidth={500}
          questionChangeWidth={180}
        ></NormalModal>
      )}
      {logoDeleteModalVisible && (
        <StandardModal
          titleText={'로고 삭제'}
          modalBodyComponent={
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              정말로 로고를 삭제하시겠습니까?(기본 로고로 변경됩니다.)
            </div>
          }
          onRightBtnClick={async () => {
            if (defaultGuide.logoImageUrl !== default_logo_image_url) {
              showLoading()

              const imageDeleteResponse = await deleteImage(logoUploadImgUrl)
              console.log(imageDeleteResponse)

              if (imageDeleteResponse.status === 200) {
                const putDefaultGuideLogoImageUrlResponse =
                  await putDefaultGuideLogoImageUrl(defaultGuide.id, {
                    logoImageUrl: default_logo_image_url,
                  })

                if (putDefaultGuideLogoImageUrlResponse.status === 200) {
                  setDefaultGuide({
                    ...defaultGuide,
                    logoImageUrl: default_logo_image_url,
                  })
                  setLogoImgUrlText(default_logo_image_url)
                  setLogoUploadImgUrl('')
                  setLogoImgFile(null)
                  setLogoDeleteModalVisible(false)
                }
              }

              hideLoading()
            } else {
              show('already default image', false)
            }
          }}
          onClose={() => {
            setLogoDeleteModalVisible(false)
          }}
          rightBtnText={'삭제'}
        ></StandardModal>
      )}
      {iconDeleteModalVisible && (
        <StandardModal
          titleText={'아이콘 삭제'}
          modalBodyComponent={
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              정말로 아이콘을 삭제하시겠습니까?(기본 아이콘으로 변경됩니다.)
            </div>
          }
          onRightBtnClick={async () => {
            if (defaultGuide.iconImageUrl !== default_icon_image_url) {
              showLoading()

              const imageDeleteResponse = await deleteImage(iconUploadImgUrl)
              console.log(imageDeleteResponse)

              if (imageDeleteResponse.status === 200) {
                const putDefaultGuideIconImageUrlResponse =
                  await putDefaultGuideIconImageUrl(defaultGuide.id, {
                    iconImageUrl: default_icon_image_url,
                  })

                if (putDefaultGuideIconImageUrlResponse.status === 200) {
                  setDefaultGuide({
                    ...defaultGuide,
                    iconImageUrl: default_icon_image_url,
                  })
                  setIconImgUrlText(default_icon_image_url)
                  setIconUploadImgUrl('')
                  setIconImgFile(null)
                  setIconDeleteModalVisible(false)
                }
              }

              hideLoading()
            } else {
              show('already default image', false)
            }
          }}
          onClose={() => {
            setIconDeleteModalVisible(false)
          }}
          rightBtnText={'삭제'}
        ></StandardModal>
      )}
    </>
  )
}

export default MainGuidesDefaultGuideCoverTab

const GuideDetailsBody = styled.div`
  padding: 20px 10%;
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-basis: auto;
  box-sizing: border-box;
  min-width: 0;
  overflow-y: auto;
  height: calc(100vh - 315px);

  @media (max-width: 1024px) {
    padding: 20px;
  }
`

const GuideCoverPhotosTile = styled.div`
  flex: 0 0 320px;
  background-color: #fff;
  margin-bottom: 24px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-width: 0;

  @media (max-width: 1024px) {
    flex: 0 0 250px;
  }
`

const GuideCoverPhotosTileHeader = styled.div`
  height: 60px;
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  font-size: 1.125rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
  justify-content: flex-start !important;
  padding: 0 20px;
  box-sizing: border-box;
  flex-shrink: 0;

  @media (max-width: 1024px) {
    font-size: 0.825rem;
    padding: 15px;
    height: 50px !important;
  }
`

const GuideCoverPhotosTileBody = styled.div`
  flex: 1;
  display: flex;
  box-sizing: border-box;
`

const GuideCoverPhotosTileBodyLeft = styled.div`
  width: 66.66667%;
  float: left;
  box-sizing: border-box;
  position: relative;
  border-bottom-left-radius: 8px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 100%;
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
`

const GuideCoverPhotosTileBodyRight = styled.div`
  width: 33.33333%;
  float: left;
  box-sizing: border-box;
  position: relative;
  border-bottom-right-radius: 8px;
  margin-left: 4px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 100%;
  font-family: Circular Std Book Bold, Tahoma, SansSerif;

  @media (max-width: 1024px) {
    margin-left: 0;
  }
`

const GuideCoverDetailsTile = styled.div`
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  background-color: #fff;
  margin-bottom: 24px;
  border-radius: 8px;
  flex-direction: column;
  display: flex;
  flex-basis: auto;
  box-sizing: border-box;
  min-width: 0;
`

const GuideCoverDetailsTileHeader = styled.div`
  height: 60px;
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  font-size: 1.125rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
  flex-shrink: 0;

  @media (max-width: 1024px) {
    font-size: 0.825rem;
    padding: 15px;
    height: 50px !important;
  }
`

const GuideCoverDetailsTileBody = styled.div`
  padding: 10px 20px;
  display: flex;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  @media (max-width: 768px) {
    padding: 15px;
  }
`

const GuideCoverDetailsTileBodyPart1 = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  width: 50%;
  float: left;
  box-sizing: border-box;

  @media only screen and (min-width: 1200px) {
    width: 25%;
  }

  @media (max-width: 1024px) {
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
  }
`

const GuideCoverDetailsTileBodyPart2 = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  width: 50%;
  float: left;
  box-sizing: border-box;

  @media only screen and (min-width: 1200px) {
    width: 25%;
  }

  @media (max-width: 1024px) {
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
  }
`

const GuideCoverDetailsTileBodyPart3 = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  width: 50%;
  float: left;
  box-sizing: border-box;

  @media only screen and (min-width: 1200px) {
    width: 50%;
  }

  @media (max-width: 1024px) {
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
  }
`

const GuideCoverDetailsTileBodyPart4 = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  width: 50%;
  float: left;
  box-sizing: border-box;

  @media only screen and (min-width: 1200px) {
    width: 25%;
  }

  @media (max-width: 1024px) {
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
  }
`

const GuideCoverDetailsTileBodyTitle = styled.span`
  color: #737679;
  font-size: 0.875rem;
  display: flex;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    font-size: 0.625rem;
  }
`

const GuideCoverDetailsTileBodyP = styled.p`
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  padding-bottom: 20px;
  font-size: 1.125rem;
  line-height: 2rem;
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  box-sizing: border-box;
  margin: 0;

  @media (max-width: 1024px) {
    padding-bottom: 0;
    font-size: 0.825rem;
    line-height: 2rem;
  }
`

const GuidePropertyAddressLocationTile = styled.div`
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  background-color: #fff;
  margin-bottom: 24px;
  border-radius: 8px;
  flex-direction: column;
  display: flex;
  flex-basis: auto;
  box-sizing: border-box;
  min-width: 0;
`

const GuidePropertyAddressLocationTileHeader = styled.div`
  height: 60px;
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  font-size: 1.125rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
  flex-shrink: 0;

  @media (max-width: 1024px) {
    font-size: 0.825rem;
    padding: 15px;
    height: 50px !important;
  }
`

const GuidePropertyAddressLocationTileBody = styled.div`
  display: flex;
  box-sizing: border-box;
`

const GuidePropertyAddressLocationTileBodyPart1 = styled.div`
  width: 41.66667%;
  float: left;
  box-sizing: border-box;

  @media only screen and (min-width: 1920px) {
    width: 25%;
  }

  @media only screen and (max-width: 767px) {
    width: 50%;
  }

  @media only screen and (max-width: 600px) {
    width: 60%;
  }

  @media only screen and (max-width: 540px) {
    width: 65%;
  }
`

const GuidePropertyAddressLocationTileBodyPart2 = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  float: left;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    padding-top: 5px;
    padding-bottom: 20px;
  }

  @media only screen and (max-width: 767px) {
    width: 50%;
  }
`

const GuidePropertyAddressLocationTileBodyPart1Map = styled.div`
  background-image: url(https://maps.googleapis.com/maps/api/staticmap?center=${(
    props
  ) => props.$position.latitude},${(props) =>
    props.$position.longitude}&zoom=15&size=${(props) =>
    (props.$mapWidth * 260) / 600}x${(props) =>
    (props.$mapWidth * 260) /
    600}&key=AIzaSyDBzwhnMMky4OXEgk6aF1oT1n9yei-roOo&markers=color:red|${(
    props
  ) => props.$position.latitude},${(props) => props.$position.longitude});
  margin-right: 30px;
  position: relative;
  width: ${(props) => (props.$mapWidth * 260) / 600}px;
  height: ${(props) => (props.$mapWidth * 260) / 600}px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom-left-radius: 8px;
  box-sizing: border-box;
`

const GuidePropertyAddressLocationTileBodyPart2Title = styled.span`
  color: #737679;
  font-size: 0.875rem;
  display: flex;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    font-size: 0.625rem;
  }
`

const GuideLogoTabsTile = styled.div`
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  background-color: #fff;
  margin-bottom: 24px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-basis: auto;
  box-sizing: border-box;
  min-width: 0;
`

const GuideLogoTabsTileHeader = styled.div`
  height: 60px;
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  font-size: 1.125rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
  flex-shrink: 0;

  @media (max-width: 1024px) {
    font-size: 0.825rem;
    padding: 15px;
    height: 50px !important;
  }
`

const GuideIconTabsTile = styled.div`
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  background-color: #fff;
  margin-bottom: 24px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-basis: auto;
  box-sizing: border-box;
  min-width: 0;
`

const GuideIconTabsTileHeader = styled.div`
  height: 60px;
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  font-size: 1.125rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
  flex-shrink: 0;

  @media (max-width: 1024px) {
    font-size: 0.825rem;
    padding: 15px;
    height: 50px !important;
  }
`

const GuideRenameTabsTile = styled.div`
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  background-color: #fff;
  margin-bottom: 24px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-basis: auto;
  box-sizing: border-box;
  min-width: 0;
`

const GuideRenameTabsTileHeader = styled.div`
  height: 60px;
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  font-size: 1.125rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
  flex-shrink: 0;

  @media (max-width: 1024px) {
    font-size: 0.825rem;
    padding: 15px;
    height: 50px !important;
  }
`

const EditLogoBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 50%;
  font-size: 14px;
  word-break: break-all;
`

const EditLogoWrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  flex-basis: 50%;
  width: 50%;
  box-sizing: border-box;
  float: left;
`

const EditLogoCameraWrapper = styled.div`
  max-width: 95%;
  margin-right: 15px;
  height: 80px;
  object-fit: contain;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.$beingImage ? 'left' : 'center')};
  background-color: ${(props) =>
    props.$beingImage ? 'transparent' : '#ededed'};
  box-sizing: border-box;
`

const EditIconBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 50%;
  font-size: 14px;
  word-break: break-all;
`

const EditIconWrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  flex-basis: 50%;
  width: 50%;
  box-sizing: border-box;
  float: left;
`

const EditIconCameraWrapper = styled.div`
  max-width: 95%;
  margin-right: 15px;
  height: 80px;
  object-fit: contain;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.$beingImage ? 'left' : 'center')};
  background-color: ${(props) =>
    props.$beingImage ? 'transparent' : '#ededed'};
  box-sizing: border-box;
`

const StyledImage = styled.img`
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  border-radius: ${(props) => (props.$shape === '0' ? '0' : '50%')};
`

const EditLogoBtnWrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  flex-basis: 50%;
  width: 100%;
  float: left;
  box-sizing: border-box;

  @media only screen and (min-width: 992px) {
    width: 50%;
  }
`

const EditLogoBodyRadioSection = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 20px;
  flex-basis: 100%;
  display: flex;
  width: 100%;
  float: left;
  box-sizing: border-box;
`

const EditIconBtnWrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  flex-basis: 50%;
  width: 100%;
  float: left;
  box-sizing: border-box;

  @media only screen and (min-width: 992px) {
    width: 50%;
  }
`

const EditIconBodyRadioSection = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 20px;
  flex-basis: 100%;
  display: flex;
  width: 100%;
  float: left;
  box-sizing: border-box;
`
