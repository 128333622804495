import styled from 'styled-components'

export const PasswordInput = ({
  password,
  setPassword,
  passwordVisible,
  setPasswordVisible,
  backgroundColor = '#f2f2f2',
}) => {
  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Input
        type={`${passwordVisible ? 'text' : 'password'}`}
        value={password}
        onChange={(e) => {
          setPassword(e.target.value)
        }}
        autoComplete="off"
        $backgroundColor={backgroundColor}
      ></Input>
      <div
        style={{
          position: 'absolute',
          right: 10,
        }}
      >
        <PasswordIcon
          onClick={() => {
            setPasswordVisible(!passwordVisible)
          }}
        >
          visibility
        </PasswordIcon>
      </div>
    </div>
  )
}

export const Input = styled.input`
  height: 45px;
  border-radius: 8px;
  color: #353431;
  background-color: ${(props) => props.$backgroundColor};
  font-size: 1rem;
  line-height: normal;
  background-image: none;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  display: inline-block;
  outline: 0;
  padding-top: 0;
  padding-left: 15px;
  padding-right: 35px;
  padding-bottom: 0;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;

  @media (max-width: 650px) {
    height: 40px;
  }

  &:hover {
    border-color: #c5c4c3;
    outline: 0;
  }

  &:focus {
    border-color: #ddc2ab;
    outline: 0;
  }
`

const PasswordIcon = styled.i.attrs({
  className: 'material-icons',
})`
  color: #c0c4cc;
  font-size: 14px;
  cursor: pointer;
  transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  height: 100%;
  width: 25px;
  text-align: center;
  line-height: 40px;
  font-family: 'Material Icons' !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  vertical-align: baseline;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;

  &:hover {
    color: #8a8a8a;
  }
`
