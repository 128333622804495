import { useState } from 'react';
import { QuestionMarkInCircle } from '../../icons/icons';
import IconTooltip from '../icon_tooltip/IconTooltip';

export const QuestionTooltip = ({
  questionSection,
  defaultWidth,
  changeWidth,
}) => {
  const [visible, setVisible] = useState(false);

  const handleVisible = () => {
    setVisible((pre) => !pre);
  };

  return (
    <IconTooltip
      icon={
        <QuestionMarkInCircle onClick={handleVisible}></QuestionMarkInCircle>
      }
      handleClick={handleVisible}
      visible={visible}
      tooltipTop={2.5}
      tooltipLeft={30}
      tooltipDefaultWidth={defaultWidth}
      tooltipChangeWidth={changeWidth}
    >
      <div
        style={{
          padding: 20,
        }}
      >
        {questionSection}
      </div>
    </IconTooltip>
  );
};
