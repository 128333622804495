import styled from 'styled-components';

export const SmallChangeBtn = ({
  beforeBackgroudColor,
  beforeBorderColor,
  beforeFontColor,
  afterBackgroudColor,
  afterBorderColor,
  afterFontColor,
  children,
  onClick,
}) => {
  return (
    <ChangeBtnStyled
      onClick={onClick}
      $beforeBackgroudColor={beforeBackgroudColor}
      $beforeBorderColor={beforeBorderColor}
      $beforeFontColor={beforeFontColor}
      $afterBackgroudColor={afterBackgroudColor}
      $afterBorderColor={afterBorderColor}
      $afterFontColor={afterFontColor}
    >
      {children}
    </ChangeBtnStyled>
  );
};

const ChangeBtnStyled = styled.div`
  position: relative;
  padding: 10px 24px;
  border-radius: 4px;
  font-family: 'paybooc-Light', sans-serif;
  text-decoration: none;
  font-weight: 700;
  transition: 0.25s;
  line-height: 1.25rem;
  font-size: 0.75rem;
  background-color: ${(props) => props.$beforeBackgroudColor};
  border: 2px solid ${(props) => props.$beforeBorderColor};
  color: ${(props) => props.$beforeFontColor};
  box-sizing: border-box;

  &:hover {
    background-color: ${(props) => props.$afterBackgroudColor};
    border: 2px solid ${(props) => props.$afterBorderColor};
    color: ${(props) => props.$afterFontColor};
  }
`;
