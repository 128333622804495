import styled from 'styled-components'
import { useSelectHelpAndSupportHeader } from '../../data_system/context/select_help_and_support_header_index_context/SelectHelpAndSupportHeaderIndexContext'
import { MainGuidesContainer } from '../../design_system/components/main_guides_container/MainGuidesContainer'
import { MiddleHeader } from '../../design_system/components/middle_header/MiddleHeader'
import { TitleDiv } from '../../design_system/components/title_div/TitleDiv'
import { TextInput } from '../../design_system/components/text_input/TextInput'
import { TextArea } from '../../design_system/components/text_area/TextArea'
import { OpacityBtn } from '../../design_system/components/btn/pc/opacity_btn/OpacityBtn'
import { useEffect, useState } from 'react'
import { postHelpAndSupport } from '../../communication_system/axios_apis/axios_apis'
import { usePopup } from '../../data_system/context/popup_context/PopupContext'

const MainGuidesHelpSupportPage = () => {
  console.log('MainGuidesPage is being rendered')
  const { show } = usePopup()

  const { selectHelpAndSupportHeaderIndex, setHelpAndSupportHeaderIndex } =
    useSelectHelpAndSupportHeader()

  const [subjectText, setSubjectText] = useState('')
  const [yourMessageText, setYourMessageText] = useState('')
  const [sendDisable, setSendDisable] = useState(true)

  const help_and_support_header_content = [
    { id: 0, title: 'FAQ' },
    { id: 1, title: '문의하기' },
  ]

  useEffect(() => {
    if (subjectText !== '' && yourMessageText !== '') {
      setSendDisable(false)
    } else {
      setSendDisable(true)
    }
  }, [subjectText, yourMessageText])

  return (
    <MainGuidesContainer>
      <MiddleHeader
        selectHeaderIndex={selectHelpAndSupportHeaderIndex}
        headerContent={help_and_support_header_content}
        setHeaderIndex={setHelpAndSupportHeaderIndex}
        rightSideVisible={true}
        rightSideComponents={
          <div
            style={{
              paddingBottom: 20,
              display: 'flex',
              alignItems: 'center',
            }}
          ></div>
        }
      ></MiddleHeader>
      {selectHelpAndSupportHeaderIndex === 0 && (
        <FAQFrame>
          <FAQFrameInner>
            <FAQForm>
              <FAQHeader>두곰 FAQ</FAQHeader>
              <FAQBody>
                <div>
                  두곰 사용설명서 및 자주 묻는 질문은 아래를 클릭해 확인해
                  주세요.
                </div>
                <br></br>
                <div>
                  <a
                    href="https://www.notion.so/FAQ-927ae31ff537420db6b64b10e802300d?pvs=4"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      textDecoration: 'none',
                      color: 'black',
                    }}
                  >
                    &gt; FAQ 바로가기
                  </a>
                </div>
              </FAQBody>
            </FAQForm>
          </FAQFrameInner>
        </FAQFrame>
      )}
      {selectHelpAndSupportHeaderIndex === 1 && (
        <ContactUsFrame>
          <ContactUsFrameInner>
            <ContactUsForm>
              <ContactUsHeader>
                고객센터 문의하기
                <div
                  style={{
                    fontSize: 15,
                    fontWeight: 400,
                    marginTop: 10,
                    color: '#000',
                  }}
                >
                  24시간 이내에 최대한 답변 드립니다. 첨부파일 등은
                  doogomcs@doogom.com으로 보내주세요. (혹은 카카오톡 '두곰'으로
                  검색하여 문의 가능합니다.)
                </div>
              </ContactUsHeader>
              <ContactUsBody>
                <TitleDiv>제목 *</TitleDiv>
                <TextInput
                  backgroundColor="#fff"
                  value={subjectText}
                  onChange={(e) => {
                    setSubjectText(e.target.value)
                  }}
                ></TextInput>
                <TitleDiv>내용 *</TitleDiv>
                <TextArea
                  backgroundColor="#fff"
                  value={yourMessageText}
                  onChange={(e) => {
                    setYourMessageText(e.target.value)
                  }}
                ></TextArea>
                <span
                  style={{
                    height: 25,
                  }}
                ></span>
                <OpacityBtn
                  fontColor={'#fff'}
                  backgroundColor={'#b49275'}
                  disabled={sendDisable}
                  onClick={async () => {
                    const response = await postHelpAndSupport(
                      subjectText,
                      yourMessageText
                    )
                    console.log('response', response)
                    if (parseInt(response.status) === 200) {
                      setSubjectText('')
                      setYourMessageText('')
                      show('전송이 완료되었습니다.')
                    }
                  }}
                >
                  보내기
                </OpacityBtn>
              </ContactUsBody>
            </ContactUsForm>
          </ContactUsFrameInner>
        </ContactUsFrame>
      )}
    </MainGuidesContainer>
  )
}

export default MainGuidesHelpSupportPage

const ContactUsFrame = styled.section`
  flex: 1 0 auto;
  display: flex;
  box-sizing: border-box;
  overflow-y: auto;
`

const ContactUsFrameInner = styled.section`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #e5e5e5;
  padding: 35px 10%;
  flex-direction: column;
  flex: 1;
  flex-basis: auto;
  box-sizing: border-box;
  min-width: 0;
  overflow-y: auto;
  max-height: calc(100vh - 205px);

  @media (max-width: 1024px) {
    padding: 25px 20px;
    max-height: calc(100vh - 125px);
  }
`

const ContactUsForm = styled.div`
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  background-color: #fff;
  width: 50%;
  border-radius: 8px;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    width: 55%;
  }

  @media (max-width: 650px) {
    width: 95%;
  }
`

const ContactUsHeader = styled.div`
  font-size: 1.375rem;
  padding: 25px;
  border-bottom: 1px solid #dedede;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`

const ContactUsBody = styled.div`
  flex-direction: column;
  padding: 25px;
  display: flex;
  box-sizing: border-box;
`

const FAQFrame = styled.section`
  flex: 1 0 auto;
  display: flex;
  box-sizing: border-box;
`

const FAQFrameInner = styled.section`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #e5e5e5;
  padding: 35px 10%;
  flex-direction: column;
  flex: 1;
  flex-basis: auto;
  box-sizing: border-box;
  min-width: 0;
  overflow-y: auto;
  max-height: calc(100vh - 205px);

  @media (max-width: 1024px) {
    padding: 25px 20px;
    max-height: calc(100vh - 125px);
  }
`

const FAQForm = styled.div`
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  background-color: #fff;
  width: 100%;
  border-radius: 8px;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    width: 55%;
  }

  @media (max-width: 650px) {
    width: 95%;
  }
`

const FAQHeader = styled.div`
  font-size: 1.375rem;
  padding: 25px;
  border-bottom: 1px solid #dedede;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`

const FAQBody = styled.div`
  flex-direction: column;
  padding: 25px;
  display: flex;
  box-sizing: border-box;
  height: 61vh;
  overflow-y: auto;
`
