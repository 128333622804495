import styled from 'styled-components'
import RootModal from '../root_modal/RootModal'
import { XIconHover } from '../../icons/icons'
import { c_ffcc96, rgba_62_217_204_0d1 } from '../../colors'
import { QuestionTooltip } from '../question_tooltip/QuestionTooltip'

const NormalModal = ({
  onClose,
  titleText,
  modalBodyComponent,
  leftBtnText = '취소',
  middleBtnText,
  middleBtnBackgroundColor = '#b49275',
  rightBtnText = '저장',
  leftBtnVisible = true,
  middleBtnVisible = false,
  middleBtnDisabled = false,
  rightBtnDisabled = false,
  onMiddleBtnClick,
  onRightBtnClick,
  questionSectionShow = false,
  questionSection,
  questionDefaultWidth,
  questionChangeWidth,
  leftBtnChangeOnClickState = false,
  onLeftBtnClick,
}) => {
  return (
    <RootModal onClose={onClose}>
      <ModalContent>
        <ModalHeader>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <ModalHeaderTitle>{titleText}</ModalHeaderTitle>
            {questionSectionShow && (
              <div
                style={{
                  marginLeft: 8,
                }}
              >
                <QuestionTooltip
                  questionSection={questionSection}
                  defaultWidth={questionDefaultWidth}
                  changeWidth={questionChangeWidth}
                ></QuestionTooltip>
              </div>
            )}
          </div>
          <XIconHover onClick={onClose}></XIconHover>
        </ModalHeader>
        <ModalBody>{modalBodyComponent}</ModalBody>
        <ModalBottom>
          {leftBtnVisible && (
            <LeftBtn
              onClick={leftBtnChangeOnClickState ? onLeftBtnClick : onClose}
            >
              {leftBtnText}
            </LeftBtn>
          )}
          {middleBtnVisible && (
            <div
              style={{
                marginLeft: 10,
              }}
            >
              <MiddleBtn
                onClick={onMiddleBtnClick}
                disabled={middleBtnDisabled}
                $middleBtnBackgroundColor={middleBtnBackgroundColor}
              >
                {middleBtnText}
              </MiddleBtn>
            </div>
          )}
          <div
            style={{
              marginLeft: 10,
            }}
          >
            <RightBtn
              onClick={onRightBtnClick}
              disabled={rightBtnDisabled}
              $middleBtnBackgroundColor={middleBtnBackgroundColor}
            >
              {rightBtnText}
            </RightBtn>
          </div>
        </ModalBottom>
      </ModalContent>
    </RootModal>
  )
}

export default NormalModal

const ModalContent = styled.div`
  margin: auto;
  border-radius: 8px;
  width: 40vw;
  position: relative;
  box-shadow: 0 1px 3px #0000004d;
  box-sizing: border-box;
  background: #fff;

  @media (max-width: 1024px) {
    width: 80vw;
  }
`

const ModalHeader = styled.div`
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: 1px solid #e1e1e1;
  background-color: #f8f8f9;
  padding: 20px;
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ModalHeaderTitle = styled.div`
  padding-left: 0;
  margin-bottom: 0;
  font-size: 18px;
  line-height: 1;
  color: #303133;
  box-sizing: border-box;
`

const ModalBody = styled.div`
  padding: 0 20px 20px;
  color: #000;
  font-size: 14px;
  word-break: break-all;
  box-sizing: border-box;
`

const ModalBottom = styled.div`
  padding: 10px 20px 20px;
  text-align: right;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: right;
`

const LeftBtn = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 9px 15px;
  font-size: 1.125rem;
  border-radius: 3px;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: #fff;
  color: #606266;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  transition: 0.1s;
  font-weight: 500;
  border: 1px solid #dcdfe6;
  overflow: visible;

  &:hover {
    background-color: ${c_ffcc96};
    border: 1px solid #b49275;
    color: #000;
  }
`

const MiddleBtn = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 9px 15px;
  font-size: 1.125rem;
  border-radius: 3px;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: ${(props) => props.$middleBtnBackgroundColor};
  color: #fff;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  transition: 0.1s;
  font-weight: 500;
  border: 1px solid ${(props) => props.$middleBtnBackgroundColor};
  overflow: visible;

  &:hover {
    opacity: 0.7;
  }

  &:disabled {
    color: #c0c4cc !important;
    background-color: #f6f6f6 !important;
    border-color: #f6f6f6 !important;
    cursor: not-allowed;
  }
`

const RightBtn = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 9px 15px;
  font-size: 1.125rem;
  border-radius: 3px;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: #b49275;
  color: #fff;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  transition: 0.1s;
  font-weight: 500;
  border: 1px solid #b49275;
  overflow: visible;

  &:hover {
    opacity: 0.7;
  }

  &:disabled {
    color: #c0c4cc !important;
    background-color: #f6f6f6 !important;
    border-color: #f6f6f6 !important;
    cursor: not-allowed;
  }
`
