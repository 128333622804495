import styled from 'styled-components';

export const TitleBigDiv = styled.div`
  font-size: 1.125rem;
  display: flex;
  padding: 15px 0;
  color: #000 !important;
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  box-sizing: border-box;
`;
