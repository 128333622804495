import PcMainHeader from '../../design_system/components/header/pc_main_header/PcMainHeader'
import { ChangeBtnA } from '../../design_system/components/btn/pc/change_btn_a/ChangeBtnA'
import {
  c_000000,
  c_112b38,
  c_353431,
  c_3ed9cc,
  c_908d88,
  c_b49275,
  c_ddc2ab,
  c_eba55d,
  c_ffcc96,
  c_ffffff,
} from '../../design_system/colors'
import {
  about_us_inner_router_url,
  customer_service_center_inner_router_url,
  faq_inner_router_url,
  login_inner_router_url,
  price_inner_router_url,
  privacy_policy_inner_router_url,
  sign_up_inner_router_url,
  terms_and_conditions_inner_router_url,
} from '../../communication_system/inner_router_url/inner_router_url'
import styled, { keyframes } from 'styled-components'
import {
  circle_image1_1,
  circle_image1_2,
  circle_image2_1,
  circle_image2_2,
  circle_image3_1,
  circle_image3_2,
  circle_image4_1,
  circle_image4_2,
  description_image1,
  description_image2,
  main_background_phone_image,
  main_middle_image1,
  main_middle_image2,
  main_middle_image3,
  main_middle_image4,
  main_middle_image5,
  main_top_cycle_image1,
  main_top_cycle_image2,
  main_top_cycle_image3,
  main_top_cycle_image4,
  main_top_cycle_image5,
  main_top_cycle_image6,
  main_top_cycle_image7,
  main_top_cycle_image8,
  review_background1,
  review_background2,
  review_background3,
  three_phone_background_image,
} from '../../design_system/images/images_settings'
import { PenIconFixed } from '../../design_system/icons/icons'
import { Logo2x } from '../../design_system/icons/icons'
import { useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import {
  noto_sans_kr_bold,
  noto_sans_kr_medium,
  noto_sans_kr_regular,
} from '../../design_system/fonts/fonts'
import BigMainHeader from '../../design_system/components/header/big_main_header/BigMainHeader'
import { useInView } from 'react-intersection-observer'
import { useSpring, animated } from 'react-spring'
import { gsap } from 'gsap'
import MainAnimatedView from '../../design_system/components/main_animated_view/MainAnimatedView'
import { MainFooter } from '../../design_system/components/main_footer/MainFooter'
import { postFindOrCreateVisitorSessionOrUpdate } from '../../communication_system/axios_apis/axios_apis'

const MainPage = () => {
  const [isPc, setIsPc] = useState(false)
  const totalContainerRef = useRef(null)

  const ref1 = useRef(null)
  const ref2 = useRef(null)
  const ref3 = useRef(null)
  const ref4 = useRef(null)
  const ref5 = useRef(null)
  const ref6 = useRef(null)
  const ref7 = useRef(null)
  const ref8 = useRef(null)
  const ref9 = useRef(null)
  const ref10 = useRef(null)
  const ref11 = useRef(null)
  const ref12 = useRef(null)
  const ref13 = useRef(null)
  const ref14 = useRef(null)
  const ref15 = useRef(null)
  const ref16 = useRef(null)
  const ref17 = useRef(null)
  const ref18 = useRef(null)
  const ref19 = useRef(null)
  const ref20 = useRef(null)
  const ref21 = useRef(null)
  const ref22 = useRef(null)
  const ref23 = useRef(null)
  const ref24 = useRef(null)
  const ref25 = useRef(null)
  const ref26 = useRef(null)
  const ref27 = useRef(null)

  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  useEffect(() => {
    // 화면 너비를 업데이트하는 함수
    const handleResize = () => {
      setScreenWidth(window.innerWidth)
    }

    // resize 이벤트 리스너 추가
    window.addEventListener('resize', handleResize)

    // 컴포넌트 해제 시 이벤트 리스너 제거
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (isMobile) {
      setIsPc(false)
    } else {
      setIsPc(true)
    }
  }, [])

  useEffect(() => {
    const tl = gsap.timeline({ repeat: -1, yoyo: true })
    tl.to('.rotateCard1', { rotationX: 0, duration: 3, ease: 'power1.inOut' }) // 0도로 3초간
      .to('.rotateCard1', { rotationX: 0, duration: 6, ease: 'none' }) // 0도로 2초간 유지
      .to('.rotateCard1', {
        rotationX: -180,
        duration: 3,
        ease: 'power1.inOut',
      }) // -180도로 3초간
      .to('.rotateCard1', { rotationX: -180, duration: 6, ease: 'none' }) // -180도로 2초간 유지
      .to('.rotateCard1', { rotationX: 0, duration: 3, ease: 'power1.inOut' }) // 다시 0도로 3초간
      .to('.rotateCard1', { rotationX: 0, duration: 6, ease: 'none' }) // 0도로 2초간 유지
      .to('.rotateCard1', {
        rotationX: -180,
        duration: 3,
        ease: 'power1.inOut',
      }) // 다시 -180도로 3초간

    const t2 = gsap.timeline({ repeat: -1, yoyo: true, delay: 9 })
    t2.to('.rotateCard2', { rotationX: 0, duration: 3, ease: 'power1.inOut' }) // 0도로 3초간
      .to('.rotateCard2', { rotationX: 0, duration: 6, ease: 'none' }) // 0도로 2초간 유지
      .to('.rotateCard2', {
        rotationX: -180,
        duration: 3,
        ease: 'power1.inOut',
      }) // -180도로 3초간
      .to('.rotateCard2', { rotationX: -180, duration: 6, ease: 'none' }) // -180도로 2초간 유지
      .to('.rotateCard2', { rotationX: 0, duration: 3, ease: 'power1.inOut' }) // 다시 0도로 3초간
      .to('.rotateCard2', { rotationX: 0, duration: 6, ease: 'none' }) // 0도로 2초간 유지
      .to('.rotateCard2', {
        rotationX: -180,
        duration: 3,
        ease: 'power1.inOut',
      }) // 다시 -180도로 3초간
  }, [])

  useEffect(() => {
    let totalHeight = 0
    // ;[
    //   ref1,
    //   ref2,
    //   ref3,
    //   ref4,
    //   ref5,
    //   ref6,
    //   ref7,
    //   ref8,
    //   ref9,
    //   ref10,
    //   ref11,
    //   ref12,
    //   ref13,
    //   ref14,
    //   ref15,
    // ].forEach((ref) => {
    //   if (ref.current) {
    //     totalHeight += ref.current.offsetHeight
    //   }
    // })

    // console.log(totalHeight)
    // if (screenWidth <= 991) {
    //   totalHeight += 400
    // } else {
    //   totalHeight += 800
    // }

    if (screenWidth <= 991) {
      if (screenWidth <= 491) {
        totalHeight = 1187 + 400
      } else if (screenWidth <= 591) {
        totalHeight = 1240 + 400
      } else if (screenWidth <= 691) {
        totalHeight = 1353 + 400
      } else if (screenWidth <= 791) {
        totalHeight = 1445 + 400
      } else if (screenWidth <= 891) {
        totalHeight = 1600 + 400
      } else if (screenWidth <= 991) {
        totalHeight = 1755 + 400
      }
    } else {
      if (screenWidth <= 1091) {
        totalHeight = 1913 + 800
      } else if (screenWidth <= 1191) {
        totalHeight = 1969 + 800
      } else if (screenWidth <= 1291) {
        totalHeight = 2015 + 800
      } else if (screenWidth <= 1391) {
        totalHeight = 2069 + 800
      } else if (screenWidth <= 1491) {
        totalHeight = 2151 + 800
      } else if (screenWidth <= 1591) {
        totalHeight = 2205 + 800
      } else if (screenWidth <= 1691) {
        totalHeight = 2315 + 800
      } else if (screenWidth <= 1791) {
        totalHeight = 2435 + 800
      } else if (screenWidth <= 1891) {
        totalHeight = 2550 + 800
      } else if (screenWidth <= 1991) {
        totalHeight = 2670 + 800
      } else if (screenWidth <= 2091) {
        totalHeight = 2747 + 800
      } else if (screenWidth <= 2191) {
        totalHeight = 2834 + 800
      } else if (screenWidth <= 2291) {
        totalHeight = 2949 + 800
      } else if (screenWidth <= 2391) {
        totalHeight = 3036 + 800
      } else if (screenWidth <= 2491) {
        totalHeight = 3113 + 800
      } else if (screenWidth <= 2591) {
        totalHeight = 3200 + 800
      } else if (screenWidth <= 2691) {
        totalHeight = 3287 + 800
      } else if (screenWidth <= 2791) {
        totalHeight = 3374 + 800
      } else if (screenWidth <= 2891) {
        totalHeight = 3461 + 800
      } else if (screenWidth <= 2991) {
        totalHeight = 3548 + 800
      } else if (screenWidth <= 3091) {
        totalHeight = 3635 + 800
      } else if (screenWidth <= 3191) {
        totalHeight = 3722 + 800
      } else if (screenWidth <= 3291) {
        totalHeight = 3809 + 800
      }
    }

    let additionalHeight = 0
    if (screenWidth <= 991) {
      if (screenWidth <= 491) {
        additionalHeight = 470
      } else if (screenWidth <= 591) {
        additionalHeight = 520
      } else if (screenWidth <= 691) {
        additionalHeight = 570
      } else if (screenWidth <= 791) {
        additionalHeight = 620
      } else if (screenWidth <= 891) {
        additionalHeight = 670
      } else if (screenWidth <= 991) {
        additionalHeight = 730
      }
    } else {
      if (screenWidth <= 1091) {
        additionalHeight = 780
      } else if (screenWidth <= 1191) {
        additionalHeight = 820
      } else if (screenWidth <= 1291) {
        additionalHeight = 860
      } else if (screenWidth <= 1391) {
        additionalHeight = 900
      } else if (screenWidth <= 1491) {
        additionalHeight = 940
      } else if (screenWidth <= 1591) {
        additionalHeight = 980
      } else if (screenWidth <= 1691) {
        additionalHeight = 1020
      } else if (screenWidth <= 1791) {
        additionalHeight = 1060
      } else if (screenWidth <= 1891) {
        additionalHeight = 1100
      } else if (screenWidth <= 1991) {
        additionalHeight = 1140
      } else if (screenWidth <= 2091) {
        additionalHeight = 1180
      } else if (screenWidth <= 2191) {
        additionalHeight = 1220
      } else if (screenWidth <= 2291) {
        additionalHeight = 1260
      } else if (screenWidth <= 2391) {
        additionalHeight = 1300
      } else if (screenWidth <= 2491) {
        additionalHeight = 1340
      } else if (screenWidth <= 2591) {
        additionalHeight = 1380
      } else if (screenWidth <= 2691) {
        additionalHeight = 1420
      } else if (screenWidth <= 2791) {
        additionalHeight = 1460
      } else if (screenWidth <= 2891) {
        additionalHeight = 1500
      } else if (screenWidth <= 2991) {
        additionalHeight = 1540
      } else if (screenWidth <= 3091) {
        additionalHeight = 1580
      } else if (screenWidth <= 3191) {
        additionalHeight = 1620
      } else if (screenWidth <= 3291) {
        additionalHeight = 1660
      }
    }

    if (totalContainerRef.current) {
      totalContainerRef.current.style.height = `${
        totalHeight + additionalHeight
      }px`
    }
  }, [screenWidth])

  console.log(ref1.current)

  useEffect(() => {
    async function getData() {
      await postFindOrCreateVisitorSessionOrUpdate()
    }

    getData()
  }, [])

  return (
    <>
      <BigMainHeader>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => {
            const baseUrl = window.location.origin
            const url = `${baseUrl}`
            window.open(url, '_self')
          }}
        >
          <Logo2x></Logo2x>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ChangeBtnA
            href={login_inner_router_url}
            beforeBackgroudColor={c_ffffff}
            beforeBorderColor={c_eba55d}
            beforeFontColor={c_353431}
            afterBackgroudColor={c_b49275}
            afterBorderColor={c_b49275}
            afterFontColor={c_ffffff}
          >
            로그인
          </ChangeBtnA>
          <div
            style={{
              marginLeft: 15,
            }}
          >
            <ChangeBtnA
              href={sign_up_inner_router_url}
              beforeBackgroudColor={c_b49275}
              beforeBorderColor={c_b49275}
              beforeFontColor={c_ffffff}
              afterBackgroudColor={c_eba55d}
              afterBorderColor={c_eba55d}
              afterFontColor={c_353431}
            >
              무료 시작
            </ChangeBtnA>
          </div>
        </div>
      </BigMainHeader>
      <GridView>
        <GridViewLeft>
          <OneSection>
            <OneSectionWrapper>
              <OneSectionHeaderWrapper>
                <OneSectionHeaderWrapperFirst>
                  <OneSectionHeaderWrapperFirstFront>
                    It’s your&nbsp;<br></br>
                  </OneSectionHeaderWrapperFirstFront>
                  <OneSectionHeaderWrapperFirstBack>
                    <PartnerText>partner</PartnerText>
                    <GuideText>guide</GuideText>
                    <StoreText>store</StoreText>
                    <SolutionText>solution</SolutionText>
                  </OneSectionHeaderWrapperFirstBack>
                </OneSectionHeaderWrapperFirst>
                <OneSectionHeaderWrapperSecond>
                  in one hand.
                </OneSectionHeaderWrapperSecond>
              </OneSectionHeaderWrapper>
              <OneSectionSubText>
                프로페셔널 호스트의 필수 아이템, 두곰 온라인 안내문. 두곰은
                빠르고 간편하게 숙소 안내문을 제작할 수 있는 플랫폼입니다.
              </OneSectionSubText>
              <OneSectionButtonPart>
                <ChangeBtnA
                  href={sign_up_inner_router_url}
                  beforeBackgroudColor={c_b49275}
                  beforeBorderColor={c_b49275}
                  beforeFontColor={c_ffffff}
                  afterBackgroudColor={c_eba55d}
                  afterBorderColor={c_eba55d}
                  afterFontColor={c_353431}
                >
                  무료로 시작하기
                </ChangeBtnA>
              </OneSectionButtonPart>
            </OneSectionWrapper>
          </OneSection>
        </GridViewLeft>
        <GridViewRight>
          <GridViewRightInner>
            <GridViewRightInnerSpinner>
              <GridViewRightInnerSpinnerPart1>
                <GridViewRightInnerSpinnerCard1>
                  <GridViewRightInnerSpinnerPart1CardImg
                    src={main_top_cycle_image1}
                    alt=""
                  ></GridViewRightInnerSpinnerPart1CardImg>
                </GridViewRightInnerSpinnerCard1>
                <GridViewRightInnerSpinnerCard2>
                  <GridViewRightInnerSpinnerPart1CardImg
                    src={main_top_cycle_image2}
                    alt=""
                  ></GridViewRightInnerSpinnerPart1CardImg>
                </GridViewRightInnerSpinnerCard2>
              </GridViewRightInnerSpinnerPart1>
              <GridViewRightInnerSpinnerPart2>
                <GridViewRightInnerSpinnerCard1>
                  <GridViewRightInnerSpinnerPart1CardImg
                    src={main_top_cycle_image3}
                    alt=""
                  ></GridViewRightInnerSpinnerPart1CardImg>
                </GridViewRightInnerSpinnerCard1>
                <GridViewRightInnerSpinnerCard2>
                  <GridViewRightInnerSpinnerPart1CardImg
                    src={main_top_cycle_image4}
                    alt=""
                  ></GridViewRightInnerSpinnerPart1CardImg>
                </GridViewRightInnerSpinnerCard2>
              </GridViewRightInnerSpinnerPart2>
              <GridViewRightInnerSpinnerPart3>
                <GridViewRightInnerSpinnerCard1>
                  <GridViewRightInnerSpinnerPart1CardImg
                    src={main_top_cycle_image5}
                    alt=""
                  ></GridViewRightInnerSpinnerPart1CardImg>
                </GridViewRightInnerSpinnerCard1>
                <GridViewRightInnerSpinnerCard2 className="rotateCard1">
                  <GridViewRightInnerSpinnerPart1CardImg
                    src={main_top_cycle_image6}
                    alt=""
                  ></GridViewRightInnerSpinnerPart1CardImg>
                </GridViewRightInnerSpinnerCard2>
              </GridViewRightInnerSpinnerPart3>
              <GridViewRightInnerSpinnerPart4>
                <GridViewRightInnerSpinnerCard1 className="rotateCard2">
                  <GridViewRightInnerSpinnerPart1CardImg
                    src={main_top_cycle_image7}
                    alt=""
                  ></GridViewRightInnerSpinnerPart1CardImg>
                </GridViewRightInnerSpinnerCard1>
                <GridViewRightInnerSpinnerCard2>
                  <GridViewRightInnerSpinnerPart1CardImg
                    src={main_top_cycle_image8}
                    alt=""
                  ></GridViewRightInnerSpinnerPart1CardImg>
                </GridViewRightInnerSpinnerCard2>
              </GridViewRightInnerSpinnerPart4>
            </GridViewRightInnerSpinner>
          </GridViewRightInner>
        </GridViewRight>
      </GridView>
      <div
        style={{
          height: screenWidth > 991 ? 100 : 50,
        }}
      ></div>
      <UpViewFrame ref={totalContainerRef}>
        <UpViewFramePart1>
          <MainAnimatedView
            ref={ref1}
            translateYFirstNum={38}
            translateYLastNum={0}
            scaleFirstNum={1}
            scaleLastNum={0.9}
          >
            <UpViewFramePart1Inner>
              <UpViewFramePart1InnerInner
                src={main_middle_image1}
                alt=""
              ></UpViewFramePart1InnerInner>
            </UpViewFramePart1Inner>
          </MainAnimatedView>
        </UpViewFramePart1>
        <UpViewFramePart2>
          <MainAnimatedView
            ref={ref2}
            translateYFirstNum={38}
            translateYLastNum={-76}
            scaleFirstNum={1}
            scaleLastNum={1}
          >
            <UpViewFramePart2Inner $screenWidth={screenWidth}>
              <UpViewFramePart2InnerInner>
                게스트 질문 감소
              </UpViewFramePart2InnerInner>
            </UpViewFramePart2Inner>
          </MainAnimatedView>
        </UpViewFramePart2>
        <UpViewFramePart3>
          <MainAnimatedView
            ref={ref3}
            translateYFirstNum={33}
            translateYLastNum={-38}
            scaleFirstNum={1}
            scaleLastNum={1}
          >
            <UpViewFramePart3Inner $screenWidth={screenWidth}>
              <UpViewFramePart3InnerInner $screenWidth={screenWidth}>
                숙소에 방문하는 게스트들은 대부분 비슷한 질문을 많이 합니다.
                두곰 안내문은 이러한 동일하고 반복적인 게스트의 질문을 감소시켜
                줍니다.
              </UpViewFramePart3InnerInner>
            </UpViewFramePart3Inner>
          </MainAnimatedView>
        </UpViewFramePart3>
        <div
          style={{
            height: screenWidth > 991 ? 200 : 100,
          }}
        ></div>
        <UpViewFramePart1>
          <MainAnimatedView
            ref={ref4}
            translateYFirstNum={38}
            translateYLastNum={0}
            scaleFirstNum={1}
            scaleLastNum={0.9}
          >
            <UpViewFramePart1Inner>
              <UpViewFramePart1InnerInner
                src={main_middle_image2}
                alt=""
              ></UpViewFramePart1InnerInner>
            </UpViewFramePart1Inner>
          </MainAnimatedView>
        </UpViewFramePart1>
        <UpViewFramePart2>
          <MainAnimatedView
            ref={ref5}
            translateYFirstNum={38}
            translateYLastNum={-76}
            scaleFirstNum={1}
            scaleLastNum={1}
          >
            <UpViewFramePart2Inner $screenWidth={screenWidth}>
              <UpViewFramePart2InnerInner>
                게스트 응대 시간 절약
              </UpViewFramePart2InnerInner>
            </UpViewFramePart2Inner>
          </MainAnimatedView>
        </UpViewFramePart2>
        <UpViewFramePart3>
          <MainAnimatedView
            ref={ref6}
            translateYFirstNum={33}
            translateYLastNum={-38}
            scaleFirstNum={1}
            scaleLastNum={1}
          >
            <UpViewFramePart3Inner $screenWidth={screenWidth}>
              <UpViewFramePart3InnerInner $screenWidth={screenWidth}>
                질문 감소로 인해 게스트의 불필요한 연락이 자연스럽게 줄어듭니다.
                이로 인해 호스트의 여유로운 시간이 보장됩니다.
              </UpViewFramePart3InnerInner>
            </UpViewFramePart3Inner>
          </MainAnimatedView>
        </UpViewFramePart3>
        <div
          style={{
            height: screenWidth > 991 ? 200 : 100,
          }}
        ></div>
        <UpViewFramePart1>
          <MainAnimatedView
            ref={ref7}
            translateYFirstNum={38}
            translateYLastNum={0}
            scaleFirstNum={1}
            scaleLastNum={0.9}
          >
            <UpViewFramePart1Inner>
              <UpViewFramePart1InnerInner
                src={main_middle_image3}
                alt=""
              ></UpViewFramePart1InnerInner>
            </UpViewFramePart1Inner>
          </MainAnimatedView>
        </UpViewFramePart1>
        <UpViewFramePart2>
          <MainAnimatedView
            ref={ref8}
            translateYFirstNum={38}
            translateYLastNum={-76}
            scaleFirstNum={1}
            scaleLastNum={1}
          >
            <UpViewFramePart2Inner $screenWidth={screenWidth}>
              <UpViewFramePart2InnerInner>
                게스트 만족도 증가
              </UpViewFramePart2InnerInner>
            </UpViewFramePart2Inner>
          </MainAnimatedView>
        </UpViewFramePart2>
        <UpViewFramePart3>
          <MainAnimatedView
            ref={ref9}
            translateYFirstNum={33}
            translateYLastNum={-38}
            scaleFirstNum={1}
            scaleLastNum={1}
          >
            <UpViewFramePart3Inner $screenWidth={screenWidth}>
              <UpViewFramePart3InnerInner $screenWidth={screenWidth}>
                클릭 한 번만으로 숙소에 필요한 모든 정보를 한곳에서 확인할 수
                있습니다. 게스트의 숙소 만족도가 높아집니다.
              </UpViewFramePart3InnerInner>
            </UpViewFramePart3Inner>
          </MainAnimatedView>
        </UpViewFramePart3>
        <div
          style={{
            height: screenWidth > 991 ? 200 : 100,
          }}
        ></div>
        <UpViewFramePart1>
          <MainAnimatedView
            ref={ref10}
            translateYFirstNum={38}
            translateYLastNum={0}
            scaleFirstNum={1}
            scaleLastNum={0.9}
          >
            <UpViewFramePart1Inner>
              <UpViewFramePart1InnerInner
                src={main_middle_image4}
                alt=""
              ></UpViewFramePart1InnerInner>
            </UpViewFramePart1Inner>
          </MainAnimatedView>
        </UpViewFramePart1>
        <UpViewFramePart2>
          <MainAnimatedView
            ref={ref11}
            translateYFirstNum={38}
            translateYLastNum={-76}
            scaleFirstNum={1}
            scaleLastNum={1}
          >
            <UpViewFramePart2Inner $screenWidth={screenWidth}>
              <UpViewFramePart2InnerInner>추가 수익</UpViewFramePart2InnerInner>
            </UpViewFramePart2Inner>
          </MainAnimatedView>
        </UpViewFramePart2>
        <UpViewFramePart3>
          <MainAnimatedView
            ref={ref12}
            translateYFirstNum={33}
            translateYLastNum={-38}
            scaleFirstNum={1}
            scaleLastNum={1}
          >
            <UpViewFramePart3Inner $screenWidth={screenWidth}>
              <UpViewFramePart3InnerInner $screenWidth={screenWidth}>
                호스트는 두곰의 결제 기능을 사용하여 월 2건의 얼리 체크인,
                레이트 체크아웃만으로도 연 150만원 이상의 추가 수익이
                가능합니다.
              </UpViewFramePart3InnerInner>
            </UpViewFramePart3Inner>
          </MainAnimatedView>
        </UpViewFramePart3>
        <div
          style={{
            height: screenWidth > 991 ? 200 : 100,
          }}
        ></div>
        <UpViewFramePart1>
          <MainAnimatedView
            ref={ref13}
            translateYFirstNum={38}
            translateYLastNum={0}
            scaleFirstNum={1}
            scaleLastNum={0.9}
          >
            <UpViewFramePart1Inner>
              <UpViewFramePart1InnerInner
                src={main_middle_image5}
                alt=""
              ></UpViewFramePart1InnerInner>
            </UpViewFramePart1Inner>
          </MainAnimatedView>
        </UpViewFramePart1>
        <UpViewFramePart2>
          <MainAnimatedView
            ref={ref14}
            translateYFirstNum={38}
            translateYLastNum={-76}
            scaleFirstNum={1}
            scaleLastNum={1}
          >
            <UpViewFramePart2Inner $screenWidth={screenWidth}>
              <UpViewFramePart2InnerInner>
                서비스 수익
              </UpViewFramePart2InnerInner>
            </UpViewFramePart2Inner>
          </MainAnimatedView>
        </UpViewFramePart2>
        <UpViewFramePart3>
          <MainAnimatedView
            ref={ref15}
            translateYFirstNum={33}
            translateYLastNum={-38}
            scaleFirstNum={1}
            scaleLastNum={1}
          >
            <UpViewFramePart3Inner $screenWidth={screenWidth}>
              <UpViewFramePart3InnerInner $screenWidth={screenWidth}>
                호스트는 두곰의 스토어 페이지에서 짐 보관, 배달 주문 대행, 투어
                연계 등 개별 상품과 서비스 판매를 통해 연 200만원 이상의 추가
                수익이 가능합니다.
              </UpViewFramePart3InnerInner>
            </UpViewFramePart3Inner>
          </MainAnimatedView>
        </UpViewFramePart3>
      </UpViewFrame>
      <ThreeFactorViewFrame>
        <ThreeFactorViewFrameLeft>
          <ThreeFactorViewFrameLeftDiv1>
            <DescriptionTitle>
              두곰은 여러 개로 나눠진 숙소 안내 방식을 통일하여 잘 정돈된 웹앱
              형태의 온라인 안내문을 제공합니다.
            </DescriptionTitle>
            <DescriptionSubtitle>
              숙소의 체크인/체크아웃 방법, 하우스 룰, 찾아오는 법, 숙소 주변
              맛집 및 추천 장소 등 게스트가 알아야 하는 모든 정보를 한곳에서 볼
              수 있습니다.
            </DescriptionSubtitle>
          </ThreeFactorViewFrameLeftDiv1>
        </ThreeFactorViewFrameLeft>
        <ThreeFactorViewFrameRight>
          <ThreeFactorViewFrameRightTile>
            <ThreeFactorViewFrameRightTileLeft>
              <MainAnimatedView
                ref={ref16}
                translateYFirstNum={10}
                translateYLastNum={0}
                scaleFirstNum={1}
                scaleLastNum={1}
                delay={0}
              >
                <span
                  class="material-symbols-outlined"
                  style={{
                    fontSize: 28,
                  }}
                >
                  feed
                </span>
                <br></br>
                쉽고 편리한<br></br>사용법
              </MainAnimatedView>
            </ThreeFactorViewFrameRightTileLeft>
            <ThreeFactorViewFrameRightTileRight>
              <MainAnimatedView
                ref={ref17}
                translateYFirstNum={10}
                translateYLastNum={0}
                scaleFirstNum={1}
                scaleLastNum={1}
                delay={200}
              >
                두곰 온라인 안내문은 누구나 쉽게 사용이 가능합니다. 호스트가
                원하는 대로 안내문 수정이 가능해 빠르게 제작할 수 있습니다.
                <br></br>
                <br></br>이미지나 동영상을 삽입할 수도 있고, 숙소 규칙, 숙소
                근처 맛집, 추천 장소 등을 언제든지 추가하거나 삭제할 수
                있습니다.
              </MainAnimatedView>
            </ThreeFactorViewFrameRightTileRight>
          </ThreeFactorViewFrameRightTile>
          <ThreeFactorViewFrameRightTile>
            <ThreeFactorViewFrameRightTileLeft>
              <MainAnimatedView
                ref={ref18}
                translateYFirstNum={10}
                translateYLastNum={0}
                scaleFirstNum={1}
                scaleLastNum={1}
                delay={0}
              >
                <span
                  class="material-symbols-outlined"
                  style={{
                    fontSize: 28,
                  }}
                >
                  touch_app
                </span>
                <br></br>
                다운로드가<br></br>필요 없는<br></br>웹앱
              </MainAnimatedView>
            </ThreeFactorViewFrameRightTileLeft>
            <ThreeFactorViewFrameRightTileRight>
              <MainAnimatedView
                ref={ref19}
                translateYFirstNum={10}
                translateYLastNum={0}
                scaleFirstNum={1}
                scaleLastNum={1}
                delay={200}
              >
                게스트에게 url 복사를 통해 안내문을 간편하게 전달할 수 있습니다.
                게스트는 번거롭게 앱을 다운로드하거나 회원가입할 필요가
                없습니다. <br></br>
                <br></br>호스트가 전달해 준 url 클릭 한 번만으로 게스트는 언제
                어디서나 핸드폰으로 손쉽게 안내문을 확인할 수 있습니다.
              </MainAnimatedView>
            </ThreeFactorViewFrameRightTileRight>
          </ThreeFactorViewFrameRightTile>
          <ThreeFactorViewFrameRightTile>
            <ThreeFactorViewFrameRightTileLeft>
              <MainAnimatedView
                ref={ref20}
                translateYFirstNum={10}
                translateYLastNum={0}
                scaleFirstNum={1}
                scaleLastNum={1}
                delay={0}
              >
                <span
                  class="material-symbols-outlined"
                  style={{
                    fontSize: 28,
                  }}
                >
                  lift_to_talk
                </span>
                <br></br>
                보기 편한<br></br>레이아웃
              </MainAnimatedView>
            </ThreeFactorViewFrameRightTileLeft>
            <ThreeFactorViewFrameRightTileRight>
              <MainAnimatedView
                ref={ref21}
                translateYFirstNum={10}
                translateYLastNum={0}
                scaleFirstNum={1}
                scaleLastNum={1}
                delay={200}
              >
                카카오톡, 위챗, 왓츠앱, 라인 등의 SNS 메신저, 문자, PDF, 이메일
                혹은 프린트 책자 등으로 제공하던 일반적인 안내문은 세로로 길어질
                수밖에 없어 보지 않는 게스트가 많았습니다. <br></br>
                <br></br>두곰 안내문은 기존 안내문과 달리 웹앱 형태의 깔끔한
                레이아웃으로 가독성이 뛰어납니다.
              </MainAnimatedView>
            </ThreeFactorViewFrameRightTileRight>
          </ThreeFactorViewFrameRightTile>
        </ThreeFactorViewFrameRight>
      </ThreeFactorViewFrame>
      <ThreeFactorViewFrame>
        <ThreeFactorViewFrameLeft>
          <ThreeFactorViewFrameLeftDiv2>
            <DescriptionTitle>
              호스트의 효율적인 숙소 운영과 수익 창출, 게스트의 높은 만족도를
              위한 필수 서비스입니다.
            </DescriptionTitle>
            <DescriptionSubtitle>
              반복적이고 빈번한 게스트의 질문을 줄여 호스트의 시간을 여유롭게
              만들어 주고, 수익은 늘려주며, 게스트의 만족도는 상승합니다.
            </DescriptionSubtitle>
          </ThreeFactorViewFrameLeftDiv2>
        </ThreeFactorViewFrameLeft>
        <ThreeFactorViewFrameRight>
          <ThreeFactorViewFrameRightTile>
            <ThreeFactorViewFrameRightTileLeft>
              <MainAnimatedView
                ref={ref22}
                translateYFirstNum={10}
                translateYLastNum={0}
                scaleFirstNum={1}
                scaleLastNum={1}
                delay={0}
              >
                <span
                  class="material-symbols-outlined"
                  style={{
                    fontSize: 28,
                  }}
                >
                  monetization_on
                </span>
                <br></br>
                추가 수익의<br></br>발생
              </MainAnimatedView>
            </ThreeFactorViewFrameRightTileLeft>
            <ThreeFactorViewFrameRightTileRight>
              <MainAnimatedView
                ref={ref23}
                translateYFirstNum={10}
                translateYLastNum={0}
                scaleFirstNum={1}
                scaleLastNum={1}
                delay={200}
              >
                호스트가 게스트에게 선결제를 받을 수 있는 스토어 페이지를 제공해
                드립니다. <br></br>
                <br></br>얼리 체크인, 레이트 체크아웃, 짐 보관 등 호스트의 개별
                서비스 및 호스트가 제공 가능한 상품 판매가 가능하여 숙박으로
                인한 수익 외에 추가 수익이 발생합니다.
              </MainAnimatedView>
            </ThreeFactorViewFrameRightTileRight>
          </ThreeFactorViewFrameRightTile>
          <ThreeFactorViewFrameRightTile>
            <ThreeFactorViewFrameRightTileLeft>
              <MainAnimatedView
                ref={ref24}
                translateYFirstNum={10}
                translateYLastNum={0}
                scaleFirstNum={1}
                scaleLastNum={1}
                delay={0}
              >
                <span
                  class="material-symbols-outlined"
                  style={{
                    fontSize: 28,
                  }}
                >
                  night_shelter
                </span>
                <br></br>
                차별화된<br></br>숙소
              </MainAnimatedView>
            </ThreeFactorViewFrameRightTileLeft>
            <ThreeFactorViewFrameRightTileRight>
              <MainAnimatedView
                ref={ref25}
                translateYFirstNum={10}
                translateYLastNum={0}
                scaleFirstNum={1}
                scaleLastNum={1}
                delay={200}
              >
                두곰 온라인 안내문 제공만으로도 게스트는 본인의 숙소 선택이
                옳았다는 안도감과 만족감을 느끼게 됩니다.<br></br>
                <br></br>안내문을 통한 숙소 자체의 브랜딩이 가능해 다른 숙소와는
                다르게 준비된 숙소라는 인식을 갖게 됩니다.
              </MainAnimatedView>
            </ThreeFactorViewFrameRightTileRight>
          </ThreeFactorViewFrameRightTile>
          <ThreeFactorViewFrameRightTile>
            <ThreeFactorViewFrameRightTileLeft>
              <MainAnimatedView
                ref={ref26}
                translateYFirstNum={10}
                translateYLastNum={0}
                scaleFirstNum={1}
                scaleLastNum={1}
                delay={0}
              >
                <span
                  class="material-symbols-outlined"
                  style={{
                    fontSize: 28,
                  }}
                >
                  hotel_class
                </span>
                <br></br>
                우리 숙소의<br></br>컨시어지
              </MainAnimatedView>
            </ThreeFactorViewFrameRightTileLeft>
            <ThreeFactorViewFrameRightTileRight>
              <MainAnimatedView
                ref={ref27}
                translateYFirstNum={10}
                translateYLastNum={0}
                scaleFirstNum={1}
                scaleLastNum={1}
                delay={200}
              >
                두곰 온라인 안내문을 통해 호텔 프런트 데스크에서만 가능했던
                컨시어지 서비스를 게스트에게 제공해 줄 수 있습니다.<br></br>
                <br></br>게스트는 기대하지 않았던 호스트의 환대를 경험하며, 숙소
                만족도가 상승하여 좋은 리뷰를 받는 선순환 구조가 만들어집니다.
              </MainAnimatedView>
            </ThreeFactorViewFrameRightTileRight>
          </ThreeFactorViewFrameRightTile>
        </ThreeFactorViewFrameRight>
      </ThreeFactorViewFrame>
      <div
        style={{
          height: screenWidth > 991 ? 200 : 100,
        }}
      ></div>
      <ReviewTile1>
        <ReviewTileInnerTop>
          <ReviewTileInnerTopComponent>★★★★★</ReviewTileInnerTopComponent>
        </ReviewTileInnerTop>
        <ReviewTileInnerBottom>
          두곰으로 안내해 주고 나서부터는 여유로운 시간 활용이 가능해졌어요.
          게스트한테 url만 보내주면 되니까 너무 편해요.<br></br>
          <br></br>
          <ReviewTileInnerBottomHost>호스트 김성민님</ReviewTileInnerBottomHost>
        </ReviewTileInnerBottom>
      </ReviewTile1>
      <div
        style={{
          height: screenWidth > 991 ? 100 : 50,
        }}
      ></div>
      <ReviewTile2>
        <ReviewTileInnerTop>
          <ReviewTileInnerTopComponent>★★★★★</ReviewTileInnerTopComponent>
        </ReviewTileInnerTop>
        <ReviewTileInnerBottom>
          호스트뿐만 아니라 게스트들의 만족도가 굉장히 높습니다. 숙소를 운영하는
          호스트라면 반드시 사용해야 하는 솔루션입니다.<br></br>
          <br></br>
          <ReviewTileInnerBottomHost>호스트 박지현님</ReviewTileInnerBottomHost>
        </ReviewTileInnerBottom>
      </ReviewTile2>
      <div
        style={{
          height: screenWidth > 991 ? 100 : 50,
        }}
      ></div>
      <ReviewTile3>
        <ReviewTileInnerTop>
          <ReviewTileInnerTopComponent>★★★★★</ReviewTileInnerTopComponent>
          <ReviewTileInnerBottom>
            매달 두곰에 지불하는 비용보다 수익이 항상 더 많아서 공짜로 사용하는
            기분입니다. 아직도 안 쓰는 사람이 있나요?<br></br>
            <br></br>
            <ReviewTileInnerBottomHost>
              호스트 이정인님
            </ReviewTileInnerBottomHost>
          </ReviewTileInnerBottom>
        </ReviewTileInnerTop>
      </ReviewTile3>
      <BottomCircleSection>
        <BottomCircleSectionInner>
          <BottomCircleSectionInnerLeft>
            <BottomCircleSectionInnerLeftInner>
              <BottomCircleSectionInnerLeftInnerInner>
                <BottomCircleSectionInnerLeftInnerInnerTag>
                  DOOGOM
                </BottomCircleSectionInnerLeftInnerInnerTag>
                <BottomCircleSectionInnerLeftInnerInnerH2>
                  Your guest is waiting. Ready to join us?
                </BottomCircleSectionInnerLeftInnerInnerH2>
                <BottomCircleSectionInnerLeftInnerInnerP>
                  호스트는 더 적게 일하고, 수익은 늘어나며, 게스트에게는 더 나은
                  경험을 주는 것, 이것이 두곰을 사용하는 이유입니다.
                </BottomCircleSectionInnerLeftInnerInnerP>
                <BottomCircleSectionInnerLeftInnerInnerDiv>
                  <ChangeBtnA
                    href={sign_up_inner_router_url}
                    beforeBackgroudColor={c_b49275}
                    beforeBorderColor={c_b49275}
                    beforeFontColor={c_ffffff}
                    afterBackgroudColor={c_eba55d}
                    afterBorderColor={c_eba55d}
                    afterFontColor={c_353431}
                  >
                    무료로 시작하기
                  </ChangeBtnA>
                </BottomCircleSectionInnerLeftInnerInnerDiv>
              </BottomCircleSectionInnerLeftInnerInner>
            </BottomCircleSectionInnerLeftInner>
          </BottomCircleSectionInnerLeft>
          <BottomCircleSectionInnerRight>
            <BottomCircleSectionInnerRightInner>
              <BottomCircleSectionInnerRightInnerPart1>
                <BottomCircleSectionInnerRightInnerPart1Circle>
                  <BottomCircleSectionInnerRightInnerPart1CircleImg1
                    src={circle_image1_1}
                  ></BottomCircleSectionInnerRightInnerPart1CircleImg1>
                </BottomCircleSectionInnerRightInnerPart1Circle>
                <BottomCircleSectionInnerRightInnerPart1Circle>
                  <BottomCircleSectionInnerRightInnerPart1CircleImg1
                    src={circle_image1_2}
                  ></BottomCircleSectionInnerRightInnerPart1CircleImg1>
                </BottomCircleSectionInnerRightInnerPart1Circle>
              </BottomCircleSectionInnerRightInnerPart1>
              <BottomCircleSectionInnerRightInnerPart2>
                <BottomCircleSectionInnerRightInnerPart1Circle>
                  <BottomCircleSectionInnerRightInnerPart1CircleImg2
                    src={circle_image2_1}
                  ></BottomCircleSectionInnerRightInnerPart1CircleImg2>
                </BottomCircleSectionInnerRightInnerPart1Circle>
                <BottomCircleSectionInnerRightInnerPart1Circle>
                  <BottomCircleSectionInnerRightInnerPart1CircleImg2
                    src={circle_image2_2}
                  ></BottomCircleSectionInnerRightInnerPart1CircleImg2>
                </BottomCircleSectionInnerRightInnerPart1Circle>
              </BottomCircleSectionInnerRightInnerPart2>
              <BottomCircleSectionInnerRightInnerPart3>
                <BottomCircleSectionInnerRightInnerPart1Circle>
                  <BottomCircleSectionInnerRightInnerPart1CircleImg3
                    src={circle_image3_1}
                  ></BottomCircleSectionInnerRightInnerPart1CircleImg3>
                </BottomCircleSectionInnerRightInnerPart1Circle>
                <BottomCircleSectionInnerRightInnerPart1Circle>
                  <BottomCircleSectionInnerRightInnerPart1CircleImg3
                    src={circle_image3_2}
                  ></BottomCircleSectionInnerRightInnerPart1CircleImg3>
                </BottomCircleSectionInnerRightInnerPart1Circle>
              </BottomCircleSectionInnerRightInnerPart3>
              <BottomCircleSectionInnerRightInnerPart4>
                <BottomCircleSectionInnerRightInnerPart1Circle>
                  <BottomCircleSectionInnerRightInnerPart1CircleImg4
                    src={circle_image4_1}
                  ></BottomCircleSectionInnerRightInnerPart1CircleImg4>
                </BottomCircleSectionInnerRightInnerPart1Circle>
                <BottomCircleSectionInnerRightInnerPart1Circle>
                  <BottomCircleSectionInnerRightInnerPart1CircleImg4
                    src={circle_image4_2}
                  ></BottomCircleSectionInnerRightInnerPart1CircleImg4>
                </BottomCircleSectionInnerRightInnerPart1Circle>
              </BottomCircleSectionInnerRightInnerPart4>
            </BottomCircleSectionInnerRightInner>
          </BottomCircleSectionInnerRight>
        </BottomCircleSectionInner>
      </BottomCircleSection>
      <div
        style={{
          height: screenWidth > 991 ? 100 : 50,
        }}
      ></div>
      <MainFooter></MainFooter>
      <FloatingBtnPlusSection></FloatingBtnPlusSection>
      <FloatingBtnWrap>
        <FloatingBtnInner>
          <FloatingBtnInnerFrontTextSection>
            <FloatingBtnInnerFrontTextSectionTop>
              호스트의 매출 증대, 별 5개 후기 증가, 여유로운 시간
            </FloatingBtnInnerFrontTextSectionTop>
            <FloatingBtnInnerFrontTextSectionBottom>
              상위 1% 호스트가 되는 비법
            </FloatingBtnInnerFrontTextSectionBottom>
          </FloatingBtnInnerFrontTextSection>
          <FloatingBtnInnerBackTextSection>
            <FloatingBtnInnerBackTextSectionPart1>
              <ChangeBtnA
                href={sign_up_inner_router_url}
                beforeBackgroudColor={c_eba55d}
                beforeBorderColor={c_eba55d}
                beforeFontColor={c_ffffff}
                afterBackgroudColor={c_eba55d}
                afterBorderColor={c_eba55d}
                afterFontColor={c_ffffff}
              >
                무료로 시작하기
              </ChangeBtnA>
            </FloatingBtnInnerBackTextSectionPart1>
            <FloatingBtnInnerBackTextSectionPart2
              onClick={() => {
                const baseUrl = window.location.origin
                const path = `${sign_up_inner_router_url}`
                const url = `${baseUrl}${path}`

                window.open(url, '_blank')
              }}
            >
              무료로 시작하기
            </FloatingBtnInnerBackTextSectionPart2>
          </FloatingBtnInnerBackTextSection>
        </FloatingBtnInner>
      </FloatingBtnWrap>
    </>
  )
}

export default MainPage

const FloatingBtnPlusSection = styled.div`
  height: 121px;
  background-color: #f4f4f4;
  font-family: ${noto_sans_kr_bold};

  @media screen and (max-width: 768px) {
  }
`

const FloatingBtnWrap = styled.div`
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 8px;
  z-index: 9999;
  text-align: center;
  box-sizing: border-box;
  font-family: ${noto_sans_kr_bold};

  @media screen and (max-width: 768px) {
    bottom: 0px;
    background-color: ${c_b49275};
  }
`

const FloatingBtnInner = styled.div`
  background-color: ${c_b49275};
  width: 100%;
  max-width: 1200px;
  overflow: hidden;
  border-radius: 11px;
  padding: 0 45px 0 63px;
  display: inline-block;
  box-sizing: border-box;
  text-align: left;
  font-family: ${noto_sans_kr_bold};

  @media screen and (max-width: 1024px) {
    max-width: 95%;
  }

  @media screen and (max-width: 768px) {
    max-width: 100%;
    text-align: center;
    padding: 8px 15px;
    border-radius: 0px;
  }
`

const FloatingBtnInnerFrontTextSection = styled.div`
  float: left;
  color: white;
  height: 95px;
  padding: 20px 0 0 0;
  box-sizing: border-box;
  font-family: ${noto_sans_kr_bold};

  @media screen and (max-width: 768px) {
    float: none;
    display: inline-block;
    height: auto;
    width: 100%;
    padding: 0;
  }
`

const FloatingBtnInnerFrontTextSectionTop = styled.div`
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: bold;
  font-family: ${noto_sans_kr_bold};

  @media screen and (max-width: 768px) {
    margin-bottom: 5px;
    font-size: 14px;
  }
`

const FloatingBtnInnerFrontTextSectionBottom = styled.div`
  font-size: 16px;
  color: #dcdcdc;
  font-family: ${noto_sans_kr_regular};

  @media screen and (max-width: 768px) {
    font-size: 13px;
    display: none;
  }
`

const FloatingBtnInnerBackTextSection = styled.div`
  height: 95px;
  cursor: pointer;
  float: right;
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    float: none;
    height: auto;
    display: inline-block;
    font-size: 15px;
    font-weight: bold;
    background-color: ${c_eba55d};
    border-radius: 4px;
    color: #fff;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    margin-top: 6px;
  }
`

const FloatingBtnInnerBackTextSectionPart1 = styled.div`
  display: block;

  @media screen and (max-width: 768px) {
    display: none;
  }
`

const FloatingBtnInnerBackTextSectionPart2 = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
  }
`

const DescriptionTitle = styled.div`
  line-height: 44px;
  color: #ffffff;
  font-size: 36px;
  font-family: ${noto_sans_kr_bold};
  font-weight: 900;
  letter-spacing: -0.5px;
  word-break: normal;
  vertical-align: middle;
  width: 70%;
  box-sizing: content-box;
  text-align: center;

  @media screen and (max-width: 991px) {
    font-size: 22px;
    line-height: 28px;
    width: 70vw;
  }
`

const DescriptionSubtitle = styled.div`
  line-height: 24px;
  color: #ffffff;
  font-size: 18px;
  font-family: ${noto_sans_kr_regular};
  font-weight: 400;
  word-break: normal;
  vertical-align: middle;
  width: 85%;
  box-sizing: content-box;
  text-align: center;
  margin-top: 30px;

  @media screen and (max-width: 991px) {
    font-size: 14px;
    letter-spacing: -0.5px;
    line-height: 21px;
    width: 80vw;
  }
`

const BottomCircleSection = styled.section`
  z-index: 1;
  background-color: #fff;
  position: relative;
  box-sizing: border-box;
  margin-top: 100px;

  @media screen and (max-width: 991px) {
    margin-top: 0px;
  }
`

const BottomCircleSectionInner = styled.div`
  grid-column-gap: 4em;
  justify-content: space-between;
  display: flex;
  overflow: hidden;
  box-sizing: border-box;

  @media screen and (max-width: 991px) {
    flex-direction: column;
  }
`

const BottomCircleSectionInnerLeft = styled.div`
  width: 50vw;
  flex: none;
  justify-content: flex-end;
  display: flex;
  box-sizing: border-box;

  @media screen and (max-width: 991px) {
    width: 100%;
    justify-content: center;
  }

  @media screen and (max-width: 767px) {
    width: auto;
  }
`

const BottomCircleSectionInnerLeftInner = styled.div`
  width: 660px;
  align-items: center;
  margin-left: 40px;
  padding-left: 0;
  display: flex;
  box-sizing: border-box;

  @media screen and (max-width: 991px) {
    margin-left: 0;
    width: 100%;
    padding-left: 32px;
    padding-right: 32px;
  }
`

const BottomCircleSectionInnerLeftInnerInner = styled.div`
  width: 500px;
  min-height: auto;
  grid-row-gap: 1.8em;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: relative;
  box-sizing: border-box;

  @media screen and (max-width: 991px) {
    width: 100%;
    margin-top: 63px;
  }
`

const BottomCircleSectionInnerLeftInnerInnerTag = styled.div`
  grid-column-gap: 8px;
  background-color: #eba55d;
  color: #fff;
  text-transform: capitalize;
  border-radius: 5px;
  align-items: center;
  padding: 8px;
  font-family: ${noto_sans_kr_bold};
  font-size: 1.5em;
  font-weight: 600;
  line-height: 1;
  display: flex;
  box-sizing: border-box;

  @media screen and (max-width: 991px) {
    align-self: center;
  }
`

const BottomCircleSectionInnerLeftInnerInnerH2 = styled.h2`
  max-width: none;
  transform-origin: 50% 100%;
  color: #eba55d;
  letter-spacing: -0.01em;
  text-transform: none;
  margin-top: 0;
  margin-bottom: 0;
  font-family: ${noto_sans_kr_bold};
  font-size: 4.3em;
  font-weight: 400;
  line-height: 1;
  box-sizing: border-box;

  @media screen and (max-width: 991px) {
    text-align: center;
    font-size: 3.2em;
    line-height: 1.1;
  }

  @media screen and (max-width: 767px) {
    font-size: 48px;
  }
`

const BottomCircleSectionInnerLeftInnerInnerP = styled.p`
  color: #eba55d;
  letter-spacing: 0;
  margin-bottom: 11px;
  font-family: ${noto_sans_kr_regular};
  font-size: 1.4em;
  font-weight: 400;
  line-height: 1.5;
  box-sizing: border-box;

  @media screen and (max-width: 991px) {
    text-align: center;
  }
`

const BottomCircleSectionInnerLeftInnerInnerDiv = styled.div`
  flex-direction: row;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  margin-left: 0px;
  padding-left: 0;
  display: flex;
  max-width: 940px;

  @media screen and (max-width: 991px) {
    margin-left: auto;
    margin-right: auto;
    max-width: 728px;
  }

  @media screen and (max-width: 767px) {
    max-width: none;
  }
`

const BottomCircleSectionInnerRight = styled.div`
  width: 50vw;
  height: 80em;
  flex: none;
  position: relative;
  right: -2.5vw;
  overflow: hidden;
  box-sizing: border-box;

  @media screen and (max-width: 991px) {
    width: 100%;
    height: 100vw;
    margin-top: 50px;
    right: auto;
  }
`

const rotateZBottom = keyframes`
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
`

const BottomCircleSectionInnerRightInner = styled.div`
  width: 80em;
  height: 80em;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  box-sizing: border-box;
  transform-style: preserve-3d;
  animation: ${rotateZBottom} 45s linear infinite;

  @media screen and (max-width: 991px) {
    width: 100%;
    height: 100vw;
    margin-left: auto;
    margin-right: auto;
  }
`

const BottomCircleSectionInnerRightInnerPart1 = styled.div`
  width: 22em;
  transform: rotate(-45deg);
  height: 80em;
  flex-direction: column;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0%;
  left: 0%;
  right: 0%;
  box-sizing: border-box;

  @media screen and (max-width: 991px) {
    width: 25vw;
    height: 100vw;
  }
`

const rotateZBottomCircle = keyframes`
  from {
    transform: rotateZ(360deg);
  }
  to {
    transform: rotateZ(0deg);
  }
`

const BottomCircleSectionInnerRightInnerPart1Circle = styled.div`
  transform-style: preserve-3d;
  animation: ${rotateZBottomCircle} 45s linear infinite;
  width: 22em;
  height: 22em;
  background-color: #1b1b1b;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
  box-sizing: border-box;

  @media screen and (max-width: 991px) {
    width: 25vw;
    height: 25vw;
    flex: none;
  }
`

const BottomCircleSectionInnerRightInnerPart2 = styled.div`
  width: 22em;
  transform: rotate(45deg);
  height: 80em;
  flex-direction: column;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0%;
  left: 0%;
  right: 0%;
  box-sizing: border-box;

  @media screen and (max-width: 991px) {
    width: 25vw;
    height: 100vw;
  }
`

const BottomCircleSectionInnerRightInnerPart3 = styled.div`
  width: 22em;
  height: 80em;
  flex-direction: column;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0%;
  left: 0%;
  right: 0%;
  box-sizing: border-box;

  @media screen and (max-width: 991px) {
    width: 25vw;
    height: 100vw;
  }
`

const BottomCircleSectionInnerRightInnerPart4 = styled.div`
  width: 22em;
  height: 80em;
  flex-direction: column;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0%;
  left: 0%;
  right: 0%;
  transform: rotate(90deg);
  box-sizing: border-box;

  @media screen and (max-width: 991px) {
    width: 25vw;
    height: 100vw;
  }
`

const BottomCircleSectionInnerRightInnerPart1CircleImg1 = styled.img`
  transform: rotate(45deg);
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  border: 0;
  box-sizing: border-box;
  overflow-clip-margin: content-box;
  overflow: clip;
`

const BottomCircleSectionInnerRightInnerPart1CircleImg2 = styled.img`
  transform: rotate(-45deg);
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  border: 0;
  box-sizing: border-box;
  overflow-clip-margin: content-box;
  overflow: clip;
`

const BottomCircleSectionInnerRightInnerPart1CircleImg3 = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  border: 0;
  box-sizing: border-box;
  overflow-clip-margin: content-box;
  overflow: clip;
`

const BottomCircleSectionInnerRightInnerPart1CircleImg4 = styled.img`
  transform: rotate(-90deg);
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  border: 0;
  box-sizing: border-box;
  overflow-clip-margin: content-box;
  overflow: clip;
`

const ReviewTile1 = styled.div`
  width: 75vw;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 4em;
  background-color: #1b4f4a;
  box-sizing: border-box;
  border-radius: 28px;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: 991px) {
    padding: 2.8em;
  }
`

const ReviewTile2 = styled.div`
  width: 75vw;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 4em;
  background-color: #f38dc9;
  box-sizing: border-box;
  border-radius: 28px;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: 991px) {
    padding: 2.8em;
  }
`

const ReviewTile3 = styled.div`
  width: 75vw;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 4em;
  background-color: #395fd0;
  box-sizing: border-box;
  border-radius: 28px;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: 991px) {
    padding: 2.8em;
  }
`

const ReviewTileInnerTop = styled.div`
  width: 100%;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
  box-sizing: border-box;
`

const ReviewTileInnerTopComponent = styled.div`
  background-color: #a9eff6;
  justify-content: center;
  color: #395fd0;
  border-radius: 5px;
  align-items: center;
  padding: 8px;
  font-family: ${noto_sans_kr_bold};
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1;
  display: flex;
  box-sizing: border-box;
`

const ReviewTileInnerBottom = styled.p`
  color: #fff;
  text-align: center;
  margin-top: 30px;
  font-family: ${noto_sans_kr_regular};
  font-size: 2em;
  letter-spacing: -0.02em;
  line-height: 1.5;
  box-sizing: border-box;

  @media screen and (max-width: 991px) {
    text-align: center;
    font-size: 1.5em;
    letter-spacing: -0.01em;
  }

  @media screen and (max-width: 767px) {
    font-size: 20px;
    line-height: 1.6;
  }
`

const ReviewTileInnerBottomHost = styled.p`
  color: #fff;
  text-align: center;
  font-family: ${noto_sans_kr_regular};
  font-size: 24px;
  letter-spacing: -0.02em;
  line-height: 1.5;
  box-sizing: border-box;

  @media screen and (max-width: 991px) {
    text-align: center;
    font-size: 20px;
    letter-spacing: -0.01em;
  }

  @media screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 1.6;
  }
`

const ThreeFactorViewFrame = styled.div`
  width: 100vw !important;
  max-width: 100%;
  position: relative;
  overflow: hidden;
  box-sizing: content-box;
  display: flex;

  @media screen and (max-width: 991px) {
    flex-direction: column;
  }
`

const ThreeFactorViewFrameLeft = styled.div`
  width: 50vw !important;
  max-width: 50%;
  box-sizing: border-box;
  background-color: rgba(241, 235, 218, 0.5);

  @media screen and (max-width: 991px) {
    width: 100vw !important;
    max-width: 100%;
  }
`

const ThreeFactorViewFrameLeftDiv1 = styled.div`
  background-image: url(${description_image1});
  background-color: #3d3215;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border-width: 0px;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media screen and (max-width: 991px) {
    width: 100vw;
    height: 150vw;
  }
`

const ThreeFactorViewFrameLeftDiv2 = styled.div`
  background-image: url(${description_image2});
  background-color: #3d3215;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media screen and (max-width: 991px) {
    width: 100vw;
    height: 150vw;
  }
`

const ThreeFactorViewFrameRight = styled.div`
  width: 50vw !important;
  max-width: 50%;
  box-sizing: border-box;
  background-color: rgba(241, 235, 218, 0.5);

  @media screen and (max-width: 991px) {
    width: 100vw !important;
    max-width: 100%;
  }
`

const ThreeFactorViewFrameRightTile = styled.div`
  padding: 60px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 991px) {
    padding: 30px;
  }
`

const ThreeFactorViewFrameRightTileLeft = styled.div`
  width: 25vw !important;
  max-width: 100%;
  line-height: 28px;
  color: #eba55d;
  font-size: 22px;
  font-family: ${noto_sans_kr_bold};

  @media screen and (max-width: 991px) {
    font-size: 20px;
    width: 50vw !important;
    max-width: 100%;
    line-height: 26px;
  }
`

const ThreeFactorViewFrameRightTileRight = styled.div`
  width: 25vw !important;
  max-width: 100%;
  color: #000000;
  font-size: 16px;
  line-height: 20px;
  font-family: ${noto_sans_kr_regular};

  @media screen and (max-width: 991px) {
    font-size: 14px;
    width: 50vw !important;
    max-width: 100%;
    line-height: 17px;
  }
`

const UpViewFrame = styled.div`
  min-height: 0;
  width: 100vw !important;
  max-width: 100%;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
`

const UpViewFramePart1 = styled.div`
  zoom: 1.322;
  z-index: 3;
  visibility: visible;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const UpViewFramePart1Inner = styled.div`
  display: flex;
  width: inherit;
  height: inherit;
  box-sizing: content-box;
  flex-direction: column;
  align-items: center;
`

const UpViewFramePart1InnerInner = styled.img`
  transform-origin: center center;
  background-position: center center;
  border-color: transparent;
  border-style: solid;
  vertical-align: middle;
  width: 66.66%;
  box-sizing: content-box;

  @media screen and (max-width: 991px) {
    width: 100%;
  }
`

const UpViewFramePart2 = styled.div`
  zoom: 1.322;
  z-index: 4;
  visibility: visible;
  box-sizing: border-box;
  display: table;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const UpViewFramePart2Inner = styled.div`
  display: table;
  width: inherit;
  height: inherit;
  box-sizing: content-box;
`

const UpViewFramePart2InnerInner = styled.div`
  transform-origin: center center;
  background-position: center center;
  border-color: transparent;
  border-style: solid;
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  box-sizing: content-box;
  font-family: ${noto_sans_kr_bold};
  font-size: 5vw;
  color: ${c_eba55d};
  line-height: 1.5;
`

const UpViewFramePart3 = styled.div`
  zoom: 1.322;
  z-index: 4;
  visibility: visible;
  box-sizing: border-box;
  display: table;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const UpViewFramePart3Inner = styled.div`
  display: table;
  width: inherit;
  height: inherit;
  box-sizing: content-box;
`

const UpViewFramePart3InnerInner = styled.div`
  transform-origin: center center;
  background-position: center center;
  border-color: transparent;
  border-style: solid;
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  box-sizing: content-box;
  font-family: ${noto_sans_kr_bold};
  font-size: ${(props) => (props.$screenWidth > 991 ? 22 : 14)}px;
  color: ${c_b49275};
  padding-left: ${(props) => (props.$screenWidth > 991 ? 200 : 38)}px;
  padding-right: ${(props) => (props.$screenWidth > 991 ? 200 : 38)}px;
  text-align: center;
  line-height: 1.5;
`

const GridView = styled.div`
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  width: 100%;
  display: grid;
  position: relative;
  box-sizing: border-box;

  @media screen and (max-width: 991px) {
    height: auto;
    margin-top: -60px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const GridViewRight = styled.div`
  grid-area: 1 / 2 / 2 / 3;
  min-height: 125vh;
  overflow: hidden;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: block;

  @media screen and (max-width: 991px) {
    min-height: auto;
    padding-top: 40px;
    grid-area: 2 / 1 / 3 / 3;
    padding-bottom: 10px;
  }

  @media screen and (min-width: 1280px) {
    padding-left: 8vw;
  }
`

const GridViewRightInner = styled.div`
  width: auto;
  height: 100%;
  display: block;
  position: relative;
  overflow: visible;
  box-sizing: border-box;
  padding-top: 20px;
  @media screen and (min-width: 1280px) {
    padding-left: 15px;
  }

  @media only screen and (min-width: 1560px) {
    font-size: 0.975em;
  }

  @media screen and (max-width: 991px) {
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;
  }
`

const rotateZ = keyframes`
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
`

const GridViewRightInnerSpinner = styled.div`
  width: 80vw;
  height: 80vw;
  border-radius: 100%;
  box-sizing: border-box;
  display: block;
  position: relative;
  transform-style: preserve-3d;
  animation: ${rotateZ} 45s linear infinite;

  @media screen and (min-width: 1560px) {
    font-size: 0.975em;
  }

  @media screen and (max-width: 991px) {
    margin-left: auto;
    margin-right: auto;
    width: 100vw;
    height: 100vw;
  }
`

const GridViewRightInnerSpinnerPart1 = styled.div`
  perspective: 2000px;
  transform: rotate(-45deg);
  width: 20vw;
  height: 80vw;
  flex-direction: column;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;
  box-sizing: border-box;

  @media screen and (max-width: 991px) {
    width: 25vw;
    height: 100vw;
  }
`

const GridViewRightInnerSpinnerCard1 = styled.div`
  z-index: 3;
  width: 100%;
  height: 25vw;
  border-radius: 40px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  display: block;
  background-color: ${c_000000};

  @media screen and (max-width: 991px) {
    height: 30vw;
    border-radius: 30px;
  }
`

const GridViewRightInnerSpinnerCard2 = styled.div`
  transform: rotate(180deg);
  z-index: 3;
  width: 100%;
  height: 25vw;
  border-radius: 40px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  display: block;
  background-color: ${c_000000};

  @media screen and (max-width: 991px) {
    height: 30vw;
    border-radius: 30px;
  }
`

const GridViewRightInnerSpinnerPart1CardImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  box-sizing: border-box;
  border: 0;
`

const GridViewRightInnerSpinnerPart2 = styled.div`
  perspective: 2000px;
  transform: rotate(45deg);
  width: 20vw;
  height: 80vw;
  flex-direction: column;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;
  box-sizing: border-box;

  @media screen and (max-width: 991px) {
    width: 25vw;
    height: 100vw;
  }
`

const GridViewRightInnerSpinnerPart3 = styled.div`
  perspective: 2000px;
  transform: rotate(90deg);
  width: 20vw;
  height: 80vw;
  flex-direction: column;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;
  box-sizing: border-box;

  @media screen and (max-width: 991px) {
    width: 25vw;
    height: 100vw;
  }
`

const GridViewRightInnerSpinnerPart4 = styled.div`
  perspective: 2000px;
  width: 20vw;
  height: 80vw;
  flex-direction: column;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;
  box-sizing: border-box;

  @media screen and (max-width: 991px) {
    width: 25vw;
    height: 100vw;
  }
`

const GridViewLeft = styled.div`
  grid-area: 1 / 1 / 2 / 3;
  align-items: center;
  justify-content: start;
  height: 100vh;
  max-width: none;
  justify-content: center;
  align-items: center;
  padding-left: 0px;
  padding-right: 0px;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: auto;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;

  @media screen and (max-width: 991px) {
    grid-area: 1 / 1 / 2 / 3;
    height: auto;
    padding-top: 75px;
    position: relative;
  }
`

const OneSection = styled.div`
  z-index: 2;
  width: 100%;
  max-width: 1480px;
  flex: 0 auto;
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px;
  position: relative;
  box-sizing: border-box;
  display: block;

  @media screen and (max-width: 991px) {
    flex: 0 auto;
    order: -1;
    padding-left: 20px;
    padding-right: 20px;
  }
`

const OneSectionWrapper = styled.div`
  z-index: 2;
  min-height: auto;
  grid-row-gap: 2em;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  display: flex;
  position: relative;
  box-sizing: border-box;

  @media screen and (max-width: 991px) {
    width: auto;
    text-align: center;
    flex-direction: column;
    align-items: center;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    display: flex;
  }
`

const OneSectionHeaderWrapper = styled.div`
  box-sizing: border-box;
  position: relative;

  @media screen and (max-width: 991px) {
    text-align: center;
  }
`

const OneSectionHeaderWrapperFirst = styled.div`
  height: 6.6em;
  align-items: flex-end;
  display: flex;
  box-sizing: border-box;

  @media screen and (max-width: 991px) {
    height: auto;
    flex-direction: column;
    align-items: center;
    font-family: ${noto_sans_kr_bold};
  }
`

const OneSectionHeaderWrapperFirstFront = styled.div`
  text-align: left;
  letter-spacing: -0.02em;
  text-transform: none;
  margin-top: 0;
  margin-bottom: 0;
  font-family: ${noto_sans_kr_bold};
  color: ${c_eba55d};
  font-size: 5em;
  font-weight: 600;
  line-height: 1.1;
  box-sizing: border-box;

  @media screen and (min-width: 1280px) {
    font-size: 6em;
  }

  @media screen and (max-width: 991px) {
    text-align: center;
    font-size: 60px;
    line-height: 1.2;
  }

  @media screen and (max-width: 767px) {
    font-size: 55px;
  }
`

const OneSectionHeaderWrapperFirstBack = styled.div`
  flex-direction: column;
  justify-content: flex-end;
  display: flex;
  position: relative;
  box-sizing: border-box;

  @media screen and (max-width: 991px) {
    align-items: center;
  }
`

const rotatePartner = keyframes`
  0%, 2% { transform: rotateX(-90deg); opacity: 0; }
  3%, 23% { transform: rotateX(0deg); opacity: 1; }
  24%, 27% { transform: rotateX(-90deg); opacity: 1; }
  28%, 100% { transform: rotateX(-90deg); opacity: 1; }
`

const rotateGuide = keyframes`
  0%, 27% { transform: rotateX(-90deg); opacity: 0; }
  28%, 48% { transform: rotateX(0deg); opacity: 1; }
  49%, 52% { transform: rotateX(-90deg); opacity: 1; }
  53%, 100% { transform: rotateX(-90deg); opacity: 1; }
`

const rotateStore = keyframes`
  0%, 52% { transform: rotateX(-90deg); opacity: 0; }
  53%, 73% { transform: rotateX(0deg); opacity: 1; }
  74%, 77% { transform: rotateX(-90deg); opacity: 1; }
  78%, 100% { transform: rotateX(-90deg); opacity: 1; }
`

const rotateSolution = keyframes`
  0%, 77% { transform: rotateX(-90deg); opacity: 0; }
  78%, 98% { transform: rotateX(0deg); opacity: 1; }
  99%, 100% { transform: rotateX(-90deg); opacity: 1; }
`

const Text = styled.p`
  transform-style: preserve-3d;
  position: absolute;
  color: ${c_eba55d};
  transform-origin: 50% 100%;
  font-size: 5em;
  text-align: left;
  letter-spacing: -0.02em;
  text-transform: none;
  margin-top: 0;
  margin-bottom: 0;
  font-family: ${noto_sans_kr_bold};
  font-weight: 600;
  line-height: 1.1;

  @media screen and (min-width: 1280px) {
    font-size: 6em;
  }

  @media screen and (max-width: 991px) {
    text-align: center;
    font-size: 60px;
    line-height: 1.2;
    top: 0%;
    bottom: 0%;
  }

  @media screen and (max-width: 767px) {
    font-size: 55px;
  }
`

const PartnerText = styled(Text)`
  animation: ${rotatePartner} 16s infinite ease-in-out;
`

const GuideText = styled(Text)`
  animation: ${rotateGuide} 16s infinite ease-in-out;
`

const StoreText = styled(Text)`
  animation: ${rotateStore} 16s infinite ease-in-out;
`

const SolutionText = styled(Text)`
  animation: ${rotateSolution} 16s infinite ease-in-out;
`

const OneSectionHeaderWrapperSecond = styled.div`
  text-align: left;
  letter-spacing: -0.02em;
  text-transform: none;
  margin-top: 0;
  margin-bottom: 0;
  font-family: ${noto_sans_kr_bold};
  font-size: 5em;
  font-weight: 600;
  line-height: 1.1;
  box-sizing: border-box;
  color: ${c_eba55d};
  @media screen and (min-width: 1280px) {
    font-size: 6em;
  }

  @media screen and (max-width: 991px) {
    text-align: center;
    margin-top: 65px;
    font-size: 60px;
    line-height: 1.2;
  }

  @media screen and (max-width: 767px) {
    margin-top: 60px;
    font-size: 55px;
  }
`

const OneSectionSubText = styled.div`
  max-width: 30em;
  font-family: ${noto_sans_kr_medium};
  font-size: 1.4em;
  font-weight: 400;
  line-height: 1.5;
  box-sizing: border-box;
  display: block;
  color: ${c_eba55d};

  @media screen and (max-width: 991px) {
    text-align: center;
  }
`

const OneSectionButtonPart = styled.div`
  display: flex;
  max-width: 940px;
  box-sizing: border-box;

  @media screen and (max-width: 991px) {
    margin-left: auto;
    margin-right: auto;
    max-width: 728px;
  }
`

const Part10Title = styled.div`
  font-size: 22px;
  line-height: 1.2 !important;
  font-family: noto_sans_kr_bold;
  color: #000;

  @media (max-width: 992px) {
    font-size: 16px !important;
  }
`

const Part11Content = styled.div`
  font-size: 12px;
  line-height: 1.2 !important;
  font-family: noto_sans_kr_regular;
  color: #fff;
`

const Part11ContentSection = styled.div`
  font-size: 12px;
  line-height: 1.2 !important;
  font-family: noto_sans_kr_regular;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`
