import styled from 'styled-components'
import { c_ffffff } from '../../../colors'
import React from 'react'

const BigMainHeader = ({ children }) => {
  return (
    <HeaderFrame>
      <HeaderContainer>{children}</HeaderContainer>
    </HeaderFrame>
  )
}

export default React.memo(BigMainHeader)

const HeaderFrame = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  background-color: ${c_ffffff};
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  max-width: 1480px;
  padding-left: 40px;
  padding-right: 40px;
  position: relative;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 1440px) {
    width: 100%;
    padding-left: 35px;
    padding-right: 35px;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
`
