import styled from 'styled-components'
import { c_ffffff } from '../../../colors'
import React from 'react'

const PcMainHeader = ({ children }) => {
  return (
    <HeaderFrame>
      <HeaderContainer>{children}</HeaderContainer>
    </HeaderFrame>
  )
}

export default React.memo(PcMainHeader)

const HeaderFrame = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  background-color: ${c_ffffff};

  @media (min-width: 1020px) {
    padding: 20px 0;
  }
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  width: 100%;
  box-sizing: border-box;

  // @media (min-width: 375px) {
  //   width: 100%;
  //   padding-left: 15px;
  //   padding-right: 15px;
  // }

  // @media (min-width: 768px) {
  //   width: 100%;
  //   padding-left: 15px;
  //   padding-right: 15px;
  // }

  // @media (min-width: 1020px) {
  //   width: 960px;
  //   padding-left: 30px;
  //   padding-right: 30px;
  // }

  // @media (min-width: 1300px) {
  //   width: 1240px;
  //   padding-left: 30px;
  //   padding-right: 30px;
  // }

  // @media (min-width: 1500px) {
  //   width: 1440px;
  //   padding-left: 30px;
  //   padding-right: 30px;
  // }
`
