import styled from 'styled-components'
import { MainGuidesContainer } from '../../design_system/components/main_guides_container/MainGuidesContainer'
import {
  Activity10Png,
  Activity1Png,
  Activity2Png,
  Activity3Png,
  Activity4Png,
  Activity5Png,
  Activity6Png,
  Activity7Png,
  Activity8Png,
  Activity9Png,
  BarsPub10Png,
  BarsPub1Png,
  BarsPub2Png,
  BarsPub3Png,
  BarsPub4Png,
  BarsPub5Png,
  BarsPub6Png,
  BarsPub7Png,
  BarsPub8Png,
  BarsPub9Png,
  Cafe10Png,
  Cafe1Png,
  Cafe2Png,
  Cafe3Png,
  Cafe4Png,
  Cafe5Png,
  Cafe6Png,
  Cafe7Png,
  Cafe8Png,
  Cafe9Png,
  ExternalLinkIcon,
  GettingAround10Png,
  GettingAround1Png,
  GettingAround2Png,
  GettingAround3Png,
  GettingAround4Png,
  GettingAround5Png,
  GettingAround6Png,
  GettingAround7Png,
  GettingAround8Png,
  GettingAround9Png,
  Home10Png,
  Home1Png,
  Home2Png,
  Home3Png,
  Home4Png,
  Home5Png,
  Home6Png,
  Home7Png,
  Home8Png,
  Home9Png,
  MedicalFacility10Png,
  MedicalFacility1Png,
  MedicalFacility2Png,
  MedicalFacility3Png,
  MedicalFacility4Png,
  MedicalFacility5Png,
  MedicalFacility6Png,
  MedicalFacility7Png,
  MedicalFacility8Png,
  MedicalFacility9Png,
  PlusIcon,
  Restaurant10Png,
  Restaurant1Png,
  Restaurant2Png,
  Restaurant3Png,
  Restaurant4Png,
  Restaurant5Png,
  Restaurant6Png,
  Restaurant7Png,
  Restaurant8Png,
  Restaurant9Png,
  Shopping10Png,
  Shopping1Png,
  Shopping2Png,
  Shopping3Png,
  Shopping4Png,
  Shopping5Png,
  Shopping6Png,
  Shopping7Png,
  Shopping8Png,
  Shopping9Png,
  Supermarket10Png,
  Supermarket1Png,
  Supermarket2Png,
  Supermarket3Png,
  Supermarket4Png,
  Supermarket5Png,
  Supermarket6Png,
  Supermarket7Png,
  Supermarket8Png,
  Supermarket9Png,
  VerticalThreeDotsIcon,
  activity10_image_s3,
  activity1_image_s3,
  activity2_image_s3,
  activity3_image_s3,
  activity4_image_s3,
  activity5_image_s3,
  activity6_image_s3,
  activity7_image_s3,
  activity8_image_s3,
  activity9_image_s3,
  bars_pub10_image_s3,
  bars_pub1_image_s3,
  bars_pub2_image_s3,
  bars_pub3_image_s3,
  bars_pub4_image_s3,
  bars_pub5_image_s3,
  bars_pub6_image_s3,
  bars_pub7_image_s3,
  bars_pub8_image_s3,
  bars_pub9_image_s3,
  cafe10_image_s3,
  cafe1_image_s3,
  cafe2_image_s3,
  cafe3_image_s3,
  cafe4_image_s3,
  cafe5_image_s3,
  cafe6_image_s3,
  cafe7_image_s3,
  cafe8_image_s3,
  cafe9_image_s3,
  gettingaround10_image_s3,
  gettingaround1_image_s3,
  gettingaround2_image_s3,
  gettingaround3_image_s3,
  gettingaround4_image_s3,
  gettingaround5_image_s3,
  gettingaround6_image_s3,
  gettingaround7_image_s3,
  gettingaround8_image_s3,
  gettingaround9_image_s3,
  home10_image_s3,
  home1_image_s3,
  home2_image_s3,
  home3_image_s3,
  home4_image_s3,
  home5_image_s3,
  home6_image_s3,
  home7_image_s3,
  home8_image_s3,
  home9_image_s3,
  medicalfacility10_image_s3,
  medicalfacility1_image_s3,
  medicalfacility2_image_s3,
  medicalfacility3_image_s3,
  medicalfacility4_image_s3,
  medicalfacility5_image_s3,
  medicalfacility6_image_s3,
  medicalfacility7_image_s3,
  medicalfacility8_image_s3,
  medicalfacility9_image_s3,
  restaurant10_image_s3,
  restaurant1_image_s3,
  restaurant2_image_s3,
  restaurant3_image_s3,
  restaurant4_image_s3,
  restaurant5_image_s3,
  restaurant6_image_s3,
  restaurant7_image_s3,
  restaurant8_image_s3,
  restaurant9_image_s3,
  shopping10_image_s3,
  shopping1_image_s3,
  shopping2_image_s3,
  shopping3_image_s3,
  shopping4_image_s3,
  shopping5_image_s3,
  shopping6_image_s3,
  shopping7_image_s3,
  shopping8_image_s3,
  shopping9_image_s3,
  supermarket10_image_s3,
  supermarket1_image_s3,
  supermarket2_image_s3,
  supermarket3_image_s3,
  supermarket4_image_s3,
  supermarket5_image_s3,
  supermarket6_image_s3,
  supermarket7_image_s3,
  supermarket8_image_s3,
  supermarket9_image_s3,
} from '../../design_system/icons/icons'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { main_guides_settings_inner_router_url } from '../../communication_system/inner_router_url/inner_router_url'
import IconTextCircleBtn from '../../design_system/components/btn/pc/icon_text_circle_btn/IconTextCircleBtn'
import StandardModal from '../../design_system/components/standard_modal/StandardModal'
import { useSelectHeader } from '../../data_system/context/select_header_index_context/SelectHeaderIndexContext'
import { useSelectSettingsSubHeader } from '../../data_system/context/select_settings_sub_header_index_context/SelectSettingsSubHeaderIndexContext'
import IconTooltip from '../../design_system/components/icon_tooltip/IconTooltip'
import { TooltipItem } from '../../design_system/components/tooltip_item/TooltipItem'
import { MiddleHeader } from '../../design_system/components/middle_header/MiddleHeader'
import { useSelectGuideDetailHeader } from '../../data_system/context/select_guide_detail_header_index_context/SelectGuideDetailHeaderIndexContext'
import NormalModal from '../../design_system/components/normal_modal/NormalModal'
import { TitleBigDiv } from '../../design_system/components/title_big_div/TitleBigDiv'
import { TextInput } from '../../design_system/components/text_input/TextInput'
import {
  deleteGuide,
  getUserAndGuides,
  postGuide,
} from '../../communication_system/axios_apis/axios_apis'
import { useLoading } from '../../data_system/context/loading_context/LoadingContext'
import { isMobile } from 'react-device-detect'
import { usePopup } from '../../data_system/context/popup_context/PopupContext'
import MainGuidesGuidesCoverTab from './main_guides_guides_cover_tab/MainGuidesGuidesCoverTab'
import MainGuidesGuidesShareTab from './main_guides_guides_share_tab/MainGuidesGuidesShareTab'
import MainGuidesGuidesContentTab from './main_guides_guides_content_tab/MainGuidesGuidesContentTab'
import { useGoogleMaps } from '../../data_system/context/google_maps_context/GoogleMapsContext'
import { GoogleMap, MarkerF } from '@react-google-maps/api'
import { useSubscription } from '../../data_system/context/subscription_context/SubscriptionContext'

const MainGuidesGuidesPage = () => {
  const { isLoaded, loadError } = useGoogleMaps()
  console.log(isLoaded, loadError)
  const { showLoading, hideLoading } = useLoading()
  const { show } = usePopup()

  let navigate = useNavigate()
  const { setHeaderIndex } = useSelectHeader()
  const { setSettingsSubHeaderIndex } = useSelectSettingsSubHeader()
  const { selectGuideDetailHeaderIndex, setGuideDetailHeaderIndex } =
    useSelectGuideDetailHeader()

  const { currentGuideNumber, setCurrentGuideNumber } = useSubscription()

  const [guideDetailShow, setGuideDetailShow] = useState(false)

  const [guideDetailDropdownVisible, setGuideDetailDropdownVisible] =
    useState(false)

  const [showUpgradeSubscriptionModal, setShowUpgradeSubscriptionModal] =
    useState(false)

  const [invitationsList, setInvitationsList] = useState([])

  const [addGuideModalVisible, setAddGuideModalVisible] = useState(false)

  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const [guides, setGuides] = useState(null)
  const [oneGuide, setOneGuide] = useState(null)
  const [user, setUser] = useState(null)
  const [guideViewNumber, setGuideViewNumber] = useState(0)
  const [selectedGuideViewNumberIndex, setSelectedGuideViewNumberIndex] =
    useState(0)

  const [logoImgUrlText, setLogoImgUrlText] = useState('')
  const [iconImgUrlText, setIconImgUrlText] = useState('')
  const [logoUploadImgUrl, setLogoUploadImgUrl] = useState('')
  const [iconUploadImgUrl, setIconUploadImgUrl] = useState('')

  const logoRoundedStateRadioItems = [
    { id: '0', title: '원본' },
    { id: '1', title: '둥글게' },
  ]

  const [selectedLogoRoundedStateRadioId, setSelectedLogoRoundedStateRadioId] =
    useState(logoRoundedStateRadioItems[0].id)

  const [guideNameText, setGuideNameText] = useState('')

  const guide_detail_header_content = [
    {
      id: 0,
      title: '표지',
    },
    {
      id: 1,
      title: '내용',
    },
    {
      id: 2,
      title: '공유',
    },
  ]

  const removeGuideById = (id) => {
    setGuides((prevGuides) => prevGuides.filter((guide) => guide.id !== id))
  }

  const addGuide = (newGuide) => {
    console.log(newGuide)
    setGuides((prevGuides) => [...prevGuides, ...newGuide])
  }

  const [isPc, setIsPc] = useState(true)
  const [dropdownVisible, setDropdownVisible] = useState({})

  const [categories, setCategories] = useState([])
  const [addCategoryModalVisible, setAddCategoryModalVisible] = useState(false)

  const [addLocationModalVisible, setAddLocationModalVisible] = useState(false)
  const [editLocationModalVisible, setEditLocationModalVisible] =
    useState(false)

  const [showSelectMapPinIconPopup, setShowSelectMapPinIconPopup] =
    useState(false)

  const [selectedCategoryIcon, setSelectedCategoryIcon] = useState(null)

  const [selectedMapPinIcon, setSelectedMapPinIcon] = useState(null)

  const [map, setMap] = useState(null)

  const onLoad = useCallback(function callback(map) {
    map.setZoom(zoom)

    setMap(map)
  }, [])

  const onUnmount = useCallback(function callback(map) {
    setMap(null)
  }, [])

  const [choosePosition, setChoosePosition] = useState(false)
  const [position, setPosition] = useState({
    latitude: 37.5665,
    longitude: 126.978,
  })
  const [topicPosition, setTopicPosition] = useState({
    latitude: null,
    longitude: null,
  })

  const [zoom, setZoom] = useState(15)

  const [address, setAddress] = useState('')
  const [suggestions, setSuggestions] = useState([])

  const [userInput, setUserInput] = useState(false)

  const [mapWidth, setMapWidth] = useState(600)

  const onMarkerDragEnd = (event) => {
    setPosition({
      latitude: event.latLng.lat(),
      longitude: event.latLng.lng(),
    })
  }

  const handleInputChange = (e) => {
    setAddress(e.target.value)
    setUserInput(true) // 사용자 입력이 변경되면 true로 설정
  }

  const getPlaceDetails = (placeId) => {
    const placesService = new window.google.maps.places.PlacesService(
      document.createElement('div')
    )

    placesService.getDetails(
      {
        placeId: placeId,
        fields: ['geometry'],
        language: 'ko',
      },
      (place, status) => {
        if (
          status === window.google.maps.places.PlacesServiceStatus.OK &&
          place.geometry
        ) {
          setPosition({
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng(),
          })
          setZoom(15)
          setSuggestions([]) // 해당 위치를 선택한 후에는 제안 목록을 초기화
        }
      }
    )
  }

  const handleSuggestionClick = (description, placeId) => {
    setAddress(description)
    setUserInput(false)
    getPlaceDetails(placeId) // 제안 클릭시에는 getPlaceDetails를 직접 호출
  }

  useEffect(() => {
    async function getData() {
      showLoading()

      const response = await getUserAndGuides()
      console.log(response.data)
      setGuides(response.data.guides)
      setUser(response.data.user)
      console.log(oneGuide)

      if (isMobile) {
        setIsPc(false)
      } else {
        setIsPc(true)
      }

      hideLoading()
    }

    getData()
  }, [])

  useEffect(() => {
    if (guides !== null && guides.length === 0) {
      setGuideDetailShow(false)
    }
  }, [guides])

  useEffect(() => {
    if (!address || !userInput) {
      setSuggestions([])
      return
    }

    if (window.google && window.google.maps && window.google.maps.places) {
      const autocompleteService =
        new window.google.maps.places.AutocompleteService()

      autocompleteService.getPlacePredictions(
        {
          input: address,
          componentRestrictions: { country: 'kr' },
          language: 'ko',
        },
        (predictions, status) => {
          if (
            status === window.google.maps.places.PlacesServiceStatus.OK &&
            predictions
          ) {
            setSuggestions(predictions)
          }
        }
      )
    }
  }, [address, userInput])

  useEffect(() => {
    const handleResize = () => {
      const mapDiv = document.querySelector('.map-div')
      if (mapDiv) {
        setMapWidth(mapDiv.offsetWidth)
      } else {
        setMapWidth(600)
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize() // 초기 실행

    return () => {
      window.removeEventListener('resize', handleResize) // 컴포넌트가 언마운트될 때 리스너를 제거합니다.
    }
  }, [choosePosition])

  // useEffect(() => {
  //   async function getData() {
  //     if (oneGuide !== null) {
  //       const response = await getGuideViewNumber(
  //         oneGuide.id,
  //         selectedGuideViewNumberIndex
  //       )
  //       setGuideViewNumber(response.data.viewNumber)
  //     }
  //   }

  //   getData()
  // }, [selectedGuideViewNumberIndex, guideViewNumber, oneGuide])

  return (
    guides &&
    user && (
      <>
        {!guideDetailShow && (
          <MainGuidesContainer>
            <GuidesHeader>
              <GuidesHeaderTitle>모든 안내문</GuidesHeaderTitle>
              <GuidesHeaderLeftPart>
                <GuidesHeaderLeftInPart>
                  <IconTextCircleBtn
                    text={'안내문 추가'}
                    borderColor={'#b49275'}
                    backgroundColor={'#b49275'}
                    textColor={'#fff'}
                    onClick={() => {
                      console.log(guides.length, user.maxGuideNumber)
                      if (guides.length < user.maxGuideNumber) {
                        setAddGuideModalVisible(true)
                      } else {
                        setShowUpgradeSubscriptionModal(true)
                      }
                    }}
                  >
                    <PlusIcon></PlusIcon>
                  </IconTextCircleBtn>
                </GuidesHeaderLeftInPart>
              </GuidesHeaderLeftPart>
            </GuidesHeader>
            <GuidesBody1>
              <GuidesBody1Left>{guides.length} 안내문</GuidesBody1Left>
              <GuidesBody1Right>
                <GuidesBody1RightInner></GuidesBody1RightInner>
              </GuidesBody1Right>
            </GuidesBody1>
            <GuidesBody2>
              {guides.map((guide) => {
                return (
                  <GuidesItemSection key={guide.id}>
                    <GuideImg
                      src={isPc ? guide.pcImageUrl : guide.mobileImageUrl}
                      alt=""
                    ></GuideImg>
                    <GuideTitle>
                      <GuideTitleInner
                        onClick={() => {
                          console.log(guide)
                          setOneGuide(guide)
                          setCategories(guide.categories)
                          setLogoImgUrlText(guide.logoImageUrl)
                          setIconImgUrlText(guide.iconImageUrl)
                          setLogoUploadImgUrl(guide.logoImageUrl)
                          setIconUploadImgUrl(guide.iconImageUrl)
                          setSelectedLogoRoundedStateRadioId(
                            guide.logoImageRounded === false
                              ? logoRoundedStateRadioItems[0].id
                              : logoRoundedStateRadioItems[1].id
                          )
                          setGuideDetailShow(true)
                        }}
                      >
                        {guide.guideName}
                      </GuideTitleInner>
                    </GuideTitle>
                    <GuidePart3></GuidePart3>
                    <GuidePart4></GuidePart4>
                    <GuidePart5>
                      <GuidePart5ViewsText>
                        {guide.guideViewNumber} 조회
                      </GuidePart5ViewsText>
                    </GuidePart5>
                    <GuidePart6></GuidePart6>
                    <GuidePart7>
                      <IconTextCircleBtn
                        text={'미리보기'}
                        borderColor={'#b49275'}
                        backgroundColor={'#b49275'}
                        textColor={'#fff'}
                        onClick={() => {
                          window.open(guide.shareUrl, '_blank')
                        }}
                      >
                        <ExternalLinkIcon></ExternalLinkIcon>
                      </IconTextCircleBtn>
                      <IconTooltip
                        icon={
                          <VerticalThreeDotsIcon
                            onClick={() => {
                              setDropdownVisible((prevState) => ({
                                ...prevState,
                                [guide.id]: !prevState[guide.id],
                              }))
                            }}
                          ></VerticalThreeDotsIcon>
                        }
                        handleClick={() => {
                          setDropdownVisible((prevState) => ({
                            ...prevState,
                            [guide.id]: !prevState[guide.id],
                          }))
                        }}
                        visible={dropdownVisible[guide.id] || false}
                        tooltipTop={30}
                        tooltipRight={0}
                        tooltipDefaultWidth={140}
                        tooltipChangeWidth={140}
                      >
                        <TooltipItem
                          onClick={() => {
                            setOneGuide(guide)
                            setShowDeleteModal(true)
                          }}
                        >
                          삭제하기
                        </TooltipItem>
                      </IconTooltip>
                    </GuidePart7>
                  </GuidesItemSection>
                )
              })}
            </GuidesBody2>
          </MainGuidesContainer>
        )}
        {guideDetailShow && (
          <MainGuidesContainer>
            <GuidesHeader>
              <GuidesHeaderTitle>{oneGuide.guideName}</GuidesHeaderTitle>
              <GuidesHeaderLeftPart>
                <GuidesHeaderLeftInPart>
                  <IconTextCircleBtn
                    text={'미리보기'}
                    borderColor={'#b49275'}
                    backgroundColor={'#b49275'}
                    textColor={'#fff'}
                    onClick={() => {
                      window.open(oneGuide.shareUrl, '_blank')
                    }}
                  >
                    <ExternalLinkIcon></ExternalLinkIcon>
                  </IconTextCircleBtn>
                  <div
                    style={{
                      marginLeft: 10,
                    }}
                  >
                    <IconTooltip
                      icon={
                        <VerticalThreeDotsIcon
                          onClick={() => {
                            setGuideDetailDropdownVisible((pre) => !pre)
                          }}
                        ></VerticalThreeDotsIcon>
                      }
                      handleClick={() => {
                        setGuideDetailDropdownVisible((pre) => !pre)
                      }}
                      visible={guideDetailDropdownVisible}
                      tooltipTop={30}
                      tooltipRight={0}
                      tooltipDefaultWidth={140}
                      tooltipChangeWidth={140}
                    >
                      <TooltipItem
                        onClick={() => {
                          setShowDeleteModal(true)
                        }}
                      >
                        삭제하기
                      </TooltipItem>
                    </IconTooltip>
                  </div>
                </GuidesHeaderLeftInPart>
              </GuidesHeaderLeftPart>
            </GuidesHeader>
            <MiddleHeader
              selectHeaderIndex={selectGuideDetailHeaderIndex}
              headerContent={guide_detail_header_content}
              setHeaderIndex={setGuideDetailHeaderIndex}
              rightSideVisible={true}
              rightSideComponents={
                <div
                  style={{
                    paddingBottom: 20,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                ></div>
              }
            ></MiddleHeader>
            {selectGuideDetailHeaderIndex === 1 && (
              <GuideContentBody1>
                <IconTextCircleBtn
                  text={'카테고리 추가하기'}
                  borderColor={'#b49275'}
                  backgroundColor={'#b49275'}
                  textColor={'#fff'}
                  onClick={() => {
                    setSelectedCategoryIcon(null)
                    setAddCategoryModalVisible(true)
                  }}
                >
                  <PlusIcon></PlusIcon>
                </IconTextCircleBtn>
              </GuideContentBody1>
            )}
            {selectGuideDetailHeaderIndex === 0 && (
              <MainGuidesGuidesCoverTab
                oneGuide={oneGuide}
                setOneGuide={setOneGuide}
                logoImgUrlText={logoImgUrlText}
                setLogoImgUrlText={setLogoImgUrlText}
                iconImgUrlText={iconImgUrlText}
                setIconImgUrlText={setIconImgUrlText}
                logoUploadImgUrl={logoUploadImgUrl}
                setLogoUploadImgUrl={setLogoUploadImgUrl}
                iconUploadImgUrl={iconUploadImgUrl}
                setIconUploadImgUrl={setIconUploadImgUrl}
                selectedLogoRoundedStateRadioId={
                  selectedLogoRoundedStateRadioId
                }
                setSelectedLogoRoundedStateRadioId={
                  setSelectedLogoRoundedStateRadioId
                }
                logoRoundedStateRadioItems={logoRoundedStateRadioItems}
              ></MainGuidesGuidesCoverTab>
            )}
            {selectGuideDetailHeaderIndex === 1 && (
              <MainGuidesGuidesContentTab
                guide={oneGuide}
                categories={categories}
                setCategories={setCategories}
                addCategoryModalVisible={addCategoryModalVisible}
                setAddCategoryModalVisible={setAddCategoryModalVisible}
                selectedCategoryIcon={selectedCategoryIcon}
                setSelectedCategoryIcon={setSelectedCategoryIcon}
                setAddLocationModalVisible={setAddLocationModalVisible}
                setEditLocationModalVisible={setEditLocationModalVisible}
                choosePosition={choosePosition}
                setChoosePosition={setChoosePosition}
                position={position}
                setPosition={setPosition}
                topicPosition={topicPosition}
                setTopicPosition={setTopicPosition}
                mapWidth={mapWidth}
                selectedMapPinIcon={selectedMapPinIcon}
                setSelectedMapPinIcon={setSelectedMapPinIcon}
                address={address}
                setAddress={setAddress}
              ></MainGuidesGuidesContentTab>
            )}
            {selectGuideDetailHeaderIndex === 2 && (
              <MainGuidesGuidesShareTab
                oneGuide={oneGuide}
                invitationsList={invitationsList}
                setInvitationsList={setInvitationsList}
                guideViewNumber={guideViewNumber}
                selectedGuideViewNumberIndex={selectedGuideViewNumberIndex}
                setSelectedGuideViewNumberIndex={
                  setSelectedGuideViewNumberIndex
                }
              ></MainGuidesGuidesShareTab>
            )}
          </MainGuidesContainer>
        )}
        {addGuideModalVisible && (
          <NormalModal
            titleText={`안내문 추가`}
            leftBtnVisible={true}
            modalBodyComponent={
              <div>
                <TitleBigDiv>숙소/안내문 이름 *</TitleBigDiv>
                <TextInput
                  value={guideNameText}
                  onChange={(e) => {
                    setGuideNameText(e.target.value)
                  }}
                  backgroundColor={'#ffffff'}
                ></TextInput>
              </div>
            }
            onRightBtnClick={async () => {
              showLoading()

              const response = await postGuide({
                guideName: guideNameText,
              })
              console.log(response)

              if (response.status === 201) {
                addGuide(response.data.guide)
                setGuideNameText('')
                setCurrentGuideNumber(currentGuideNumber + 1)
                setAddGuideModalVisible(false)
              }

              hideLoading()
            }}
            onClose={async () => {
              setGuideNameText('')
              setAddGuideModalVisible(false)
            }}
            rightBtnText={'제출하기'}
            rightBtnDisabled={guideNameText === ''}
            questionSection={<p>---</p>}
            questionDefaultWidth={500}
            questionChangeWidth={180}
          ></NormalModal>
        )}
        {showUpgradeSubscriptionModal && (
          <StandardModal
            titleText={'안내문 추가하기'}
            modalBodyComponent={
              <div>안내문 추가를 위한 결제페이지로 이동합니다.</div>
            }
            onRightBtnClick={() => {
              setHeaderIndex(4)
              setSettingsSubHeaderIndex(1)
              navigate(main_guides_settings_inner_router_url)
            }}
            onClose={() => {
              setShowUpgradeSubscriptionModal(false)
            }}
            rightBtnText={'이동'}
          ></StandardModal>
        )}
        {showDeleteModal && (
          <StandardModal
            titleText={'삭제하기'}
            modalBodyComponent={
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                삭제 후 복구 되지 않습니다. 정말 삭제하시겠습니까?
              </div>
            }
            onRightBtnClick={async () => {
              showLoading()

              const response = await deleteGuide(oneGuide.id)

              if (response.status === 200) {
                removeGuideById(oneGuide.id)
                setCurrentGuideNumber(currentGuideNumber - 1)
                setShowDeleteModal(false)
              }

              hideLoading()
            }}
            onClose={() => {
              setShowDeleteModal(false)
            }}
            rightBtnText={'삭제'}
          ></StandardModal>
        )}
        {addLocationModalVisible && (
          <NormalModal
            titleText={`위치 수정하기`}
            leftBtnVisible={true}
            modalBodyComponent={
              <div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    style={{
                      width: '45%',
                    }}
                  >
                    <TitleBigDiv>위도</TitleBigDiv>
                    <TextInput
                      backgroundColor="#fff"
                      value={position.latitude}
                      disabled={true}
                    ></TextInput>
                  </div>
                  <div
                    style={{
                      width: '45%',
                    }}
                  >
                    <TitleBigDiv>경도</TitleBigDiv>
                    <TextInput
                      backgroundColor="#fff"
                      value={position.longitude}
                      disabled={true}
                    ></TextInput>
                  </div>
                </div>
                {isLoaded && (
                  <div>
                    <TitleBigDiv>주소로 검색하기 *</TitleBigDiv>
                    <TextInput
                      backgroundColor="#fff"
                      value={address}
                      onChange={handleInputChange}
                    ></TextInput>
                    {suggestions.length !== 0 && (
                      <div
                        style={{
                          border: '1px solid #dcdfe6',
                          borderRadius: '8px',
                          boxSizing: 'border-box',
                          maxHeight: '200px',
                          overflowY: 'auto',
                          width: '100%',
                          backgroundColor: '#ffffff',
                          marginTop: 15,
                        }}
                      >
                        {suggestions.map((suggestion) => (
                          <div
                            key={suggestion.place_id}
                            style={{
                              padding: '8px 15px',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              handleSuggestionClick(
                                suggestion.description,
                                suggestion.place_id
                              )
                            }}
                          >
                            {suggestion.description}
                          </div>
                        ))}
                      </div>
                    )}
                    <TitleBigDiv>핀 조정하기</TitleBigDiv>
                    <GoogleMap
                      mapContainerStyle={{ width: '100%', height: '400px' }}
                      center={{
                        lat: position.latitude,
                        lng: position.longitude,
                      }}
                      zoom={zoom} // 상태로 관리되는 줌 레벨 사용
                      onLoad={onLoad}
                      onUnmount={onUnmount}
                    >
                      <MarkerF
                        position={{
                          lat: position.latitude,
                          lng: position.longitude,
                        }}
                        draggable={true}
                        icon={{
                          url: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
                          scaledSize: new window.google.maps.Size(40, 40),
                        }}
                        onDragEnd={onMarkerDragEnd}
                      />
                    </GoogleMap>
                  </div>
                )}

                <TitleBigDiv>맵 핀 아이콘 *</TitleBigDiv>
                <MapPinIconSection>
                  <MapPinIconSectionInner>
                    <MapPinIconSectionInnerBtn
                      onClick={() => {
                        setShowSelectMapPinIconPopup((pre) => !pre)
                      }}
                    >
                      아이콘 선택
                    </MapPinIconSectionInnerBtn>
                    <MapPinIconSectionInnerIcon>
                      {selectedMapPinIcon !== null && (
                        <img
                          src={selectedMapPinIcon}
                          alt=""
                          style={{
                            width: 40,
                            height: 40,
                            objectFit: 'contain',
                          }}
                        ></img>
                      )}
                    </MapPinIconSectionInnerIcon>
                  </MapPinIconSectionInner>
                  {showSelectMapPinIconPopup && (
                    <MapPinIconSectionPopup>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(activity1_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Activity1Png></Activity1Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(activity2_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Activity2Png></Activity2Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(activity3_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Activity3Png></Activity3Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(activity4_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Activity4Png></Activity4Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(activity5_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Activity5Png></Activity5Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(activity6_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Activity6Png></Activity6Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(activity7_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Activity7Png></Activity7Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(activity8_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Activity8Png></Activity8Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(activity9_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Activity9Png></Activity9Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(activity10_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Activity10Png></Activity10Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(bars_pub1_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <BarsPub1Png></BarsPub1Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(bars_pub2_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <BarsPub2Png></BarsPub2Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(bars_pub3_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <BarsPub3Png></BarsPub3Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(bars_pub4_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <BarsPub4Png></BarsPub4Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(bars_pub5_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <BarsPub5Png></BarsPub5Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(bars_pub6_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <BarsPub6Png></BarsPub6Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(bars_pub7_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <BarsPub7Png></BarsPub7Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(bars_pub8_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <BarsPub8Png></BarsPub8Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(bars_pub9_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <BarsPub9Png></BarsPub9Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(bars_pub10_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <BarsPub10Png></BarsPub10Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(cafe1_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Cafe1Png></Cafe1Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(cafe2_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Cafe2Png></Cafe2Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(cafe3_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Cafe3Png></Cafe3Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(cafe4_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Cafe4Png></Cafe4Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(cafe5_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Cafe5Png></Cafe5Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(cafe6_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Cafe6Png></Cafe6Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(cafe7_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Cafe7Png></Cafe7Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(cafe8_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Cafe8Png></Cafe8Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(cafe9_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Cafe9Png></Cafe9Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(cafe10_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Cafe10Png></Cafe10Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(gettingaround1_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <GettingAround1Png></GettingAround1Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(gettingaround2_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <GettingAround2Png></GettingAround2Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(gettingaround3_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <GettingAround3Png></GettingAround3Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(gettingaround4_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <GettingAround4Png></GettingAround4Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(gettingaround5_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <GettingAround5Png></GettingAround5Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(gettingaround6_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <GettingAround6Png></GettingAround6Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(gettingaround7_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <GettingAround7Png></GettingAround7Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(gettingaround8_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <GettingAround8Png></GettingAround8Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(gettingaround9_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <GettingAround9Png></GettingAround9Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(gettingaround10_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <GettingAround10Png></GettingAround10Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(home1_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Home1Png></Home1Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(home2_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Home2Png></Home2Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(home3_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Home3Png></Home3Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(home4_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Home4Png></Home4Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(home5_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Home5Png></Home5Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(home6_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Home6Png></Home6Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(home7_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Home7Png></Home7Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(home8_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Home8Png></Home8Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(home9_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Home9Png></Home9Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(home10_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Home10Png></Home10Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(medicalfacility1_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <MedicalFacility1Png></MedicalFacility1Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(medicalfacility2_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <MedicalFacility2Png></MedicalFacility2Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(medicalfacility3_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <MedicalFacility3Png></MedicalFacility3Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(medicalfacility4_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <MedicalFacility4Png></MedicalFacility4Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(medicalfacility5_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <MedicalFacility5Png></MedicalFacility5Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(medicalfacility6_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <MedicalFacility6Png></MedicalFacility6Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(medicalfacility7_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <MedicalFacility7Png></MedicalFacility7Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(medicalfacility8_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <MedicalFacility8Png></MedicalFacility8Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(medicalfacility9_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <MedicalFacility9Png></MedicalFacility9Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(medicalfacility10_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <MedicalFacility10Png></MedicalFacility10Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(restaurant1_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Restaurant1Png></Restaurant1Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(restaurant2_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Restaurant2Png></Restaurant2Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(restaurant3_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Restaurant3Png></Restaurant3Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(restaurant4_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Restaurant4Png></Restaurant4Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(restaurant5_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Restaurant5Png></Restaurant5Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(restaurant6_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Restaurant6Png></Restaurant6Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(restaurant7_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Restaurant7Png></Restaurant7Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(restaurant8_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Restaurant8Png></Restaurant8Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(restaurant9_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Restaurant9Png></Restaurant9Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(restaurant10_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Restaurant10Png></Restaurant10Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(shopping1_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Shopping1Png></Shopping1Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(shopping2_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Shopping2Png></Shopping2Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(shopping3_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Shopping3Png></Shopping3Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(shopping4_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Shopping4Png></Shopping4Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(shopping5_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Shopping5Png></Shopping5Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(shopping6_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Shopping6Png></Shopping6Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(shopping7_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Shopping7Png></Shopping7Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(shopping8_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Shopping8Png></Shopping8Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(shopping9_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Shopping9Png></Shopping9Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(shopping10_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Shopping10Png></Shopping10Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(supermarket1_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Supermarket1Png></Supermarket1Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(supermarket2_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Supermarket2Png></Supermarket2Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(supermarket3_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Supermarket3Png></Supermarket3Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(supermarket4_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Supermarket4Png></Supermarket4Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(supermarket5_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Supermarket5Png></Supermarket5Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(supermarket6_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Supermarket6Png></Supermarket6Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(supermarket7_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Supermarket7Png></Supermarket7Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(supermarket8_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Supermarket8Png></Supermarket8Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(supermarket9_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Supermarket9Png></Supermarket9Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(supermarket10_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Supermarket10Png></Supermarket10Png>
                      </MapPinIconSectionPopupIcon>
                    </MapPinIconSectionPopup>
                  )}
                </MapPinIconSection>
              </div>
            }
            onRightBtnClick={() => {
              if (selectedMapPinIcon === null) {
                alert('맵 핀 아이콘을 선택해주세요')
              } else {
                if (address === '') {
                  alert('주소를 입력해주세요')
                } else {
                  setChoosePosition(true)
                  setPosition({
                    latitude: position.latitude,
                    longitude: position.longitude,
                  })
                  setTopicPosition({
                    latitude: position.latitude,
                    longitude: position.longitude,
                  })
                  setAddLocationModalVisible(false)
                }
              }
            }}
            onClose={() => {
              setSelectedMapPinIcon(null)
              setShowSelectMapPinIconPopup(false)
              setAddLocationModalVisible(false)
            }}
            rightBtnText={'저장'}
            questionSection={<p>---</p>}
            questionWidth={500}
            questionChangeWidth={180}
          ></NormalModal>
        )}
        {editLocationModalVisible && (
          <NormalModal
            titleText={`위치 수정하기`}
            leftBtnVisible={true}
            modalBodyComponent={
              <div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    style={{
                      width: '45%',
                    }}
                  >
                    <TitleBigDiv>위도</TitleBigDiv>
                    <TextInput
                      backgroundColor="#fff"
                      value={position.latitude}
                      disabled={true}
                    ></TextInput>
                  </div>
                  <div
                    style={{
                      width: '45%',
                    }}
                  >
                    <TitleBigDiv>경도</TitleBigDiv>
                    <TextInput
                      backgroundColor="#fff"
                      value={position.longitude}
                      disabled={true}
                    ></TextInput>
                  </div>
                </div>
                {isLoaded && (
                  <div>
                    <TitleBigDiv>주소로 검색하기 *</TitleBigDiv>
                    <TextInput
                      backgroundColor="#fff"
                      value={address}
                      onChange={handleInputChange}
                    ></TextInput>
                    {suggestions.length !== 0 && (
                      <div
                        style={{
                          border: '1px solid #dcdfe6',
                          borderRadius: '8px',
                          boxSizing: 'border-box',
                          maxHeight: '200px',
                          overflowY: 'auto',
                          width: '100%',
                          backgroundColor: '#ffffff',
                          marginTop: 15,
                        }}
                      >
                        {suggestions.map((suggestion) => (
                          <div
                            key={suggestion.place_id}
                            style={{
                              padding: '8px 15px',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              handleSuggestionClick(
                                suggestion.description,
                                suggestion.place_id
                              )
                            }}
                          >
                            {suggestion.description}
                          </div>
                        ))}
                      </div>
                    )}
                    <TitleBigDiv>핀 조정하기</TitleBigDiv>
                    <GoogleMap
                      mapContainerStyle={{ width: '100%', height: '400px' }}
                      center={{
                        lat: position.latitude,
                        lng: position.longitude,
                      }}
                      zoom={zoom} // 상태로 관리되는 줌 레벨 사용
                      onLoad={onLoad}
                      onUnmount={onUnmount}
                    >
                      <MarkerF
                        position={{
                          lat: position.latitude,
                          lng: position.longitude,
                        }}
                        draggable={true}
                        icon={{
                          url: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
                          scaledSize: new window.google.maps.Size(40, 40),
                        }}
                        onDragEnd={onMarkerDragEnd}
                      />
                    </GoogleMap>
                  </div>
                )}
                <TitleBigDiv>맵 핀 아이콘 *</TitleBigDiv>
                <MapPinIconSection>
                  <MapPinIconSectionInner>
                    <MapPinIconSectionInnerBtn
                      onClick={() => {
                        setShowSelectMapPinIconPopup((pre) => !pre)
                      }}
                    >
                      아이콘 선택
                    </MapPinIconSectionInnerBtn>
                    <MapPinIconSectionInnerIcon>
                      {selectedMapPinIcon !== null && (
                        <img
                          src={selectedMapPinIcon}
                          alt=""
                          style={{
                            width: 40,
                            height: 40,
                            objectFit: 'contain',
                          }}
                        ></img>
                      )}
                    </MapPinIconSectionInnerIcon>
                  </MapPinIconSectionInner>
                  {showSelectMapPinIconPopup && (
                    <MapPinIconSectionPopup>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(activity1_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Activity1Png></Activity1Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(activity2_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Activity2Png></Activity2Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(activity3_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Activity3Png></Activity3Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(activity4_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Activity4Png></Activity4Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(activity5_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Activity5Png></Activity5Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(activity6_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Activity6Png></Activity6Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(activity7_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Activity7Png></Activity7Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(activity8_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Activity8Png></Activity8Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(activity9_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Activity9Png></Activity9Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(activity10_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Activity10Png></Activity10Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(bars_pub1_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <BarsPub1Png></BarsPub1Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(bars_pub2_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <BarsPub2Png></BarsPub2Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(bars_pub3_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <BarsPub3Png></BarsPub3Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(bars_pub4_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <BarsPub4Png></BarsPub4Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(bars_pub5_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <BarsPub5Png></BarsPub5Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(bars_pub6_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <BarsPub6Png></BarsPub6Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(bars_pub7_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <BarsPub7Png></BarsPub7Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(bars_pub8_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <BarsPub8Png></BarsPub8Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(bars_pub9_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <BarsPub9Png></BarsPub9Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(bars_pub10_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <BarsPub10Png></BarsPub10Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(cafe1_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Cafe1Png></Cafe1Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(cafe2_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Cafe2Png></Cafe2Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(cafe3_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Cafe3Png></Cafe3Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(cafe4_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Cafe4Png></Cafe4Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(cafe5_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Cafe5Png></Cafe5Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(cafe6_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Cafe6Png></Cafe6Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(cafe7_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Cafe7Png></Cafe7Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(cafe8_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Cafe8Png></Cafe8Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(cafe9_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Cafe9Png></Cafe9Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(cafe10_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Cafe10Png></Cafe10Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(gettingaround1_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <GettingAround1Png></GettingAround1Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(gettingaround2_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <GettingAround2Png></GettingAround2Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(gettingaround3_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <GettingAround3Png></GettingAround3Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(gettingaround4_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <GettingAround4Png></GettingAround4Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(gettingaround5_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <GettingAround5Png></GettingAround5Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(gettingaround6_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <GettingAround6Png></GettingAround6Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(gettingaround7_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <GettingAround7Png></GettingAround7Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(gettingaround8_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <GettingAround8Png></GettingAround8Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(gettingaround9_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <GettingAround9Png></GettingAround9Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(gettingaround10_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <GettingAround10Png></GettingAround10Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(home1_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Home1Png></Home1Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(home2_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Home2Png></Home2Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(home3_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Home3Png></Home3Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(home4_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Home4Png></Home4Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(home5_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Home5Png></Home5Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(home6_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Home6Png></Home6Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(home7_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Home7Png></Home7Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(home8_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Home8Png></Home8Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(home9_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Home9Png></Home9Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(home10_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Home10Png></Home10Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(medicalfacility1_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <MedicalFacility1Png></MedicalFacility1Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(medicalfacility2_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <MedicalFacility2Png></MedicalFacility2Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(medicalfacility3_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <MedicalFacility3Png></MedicalFacility3Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(medicalfacility4_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <MedicalFacility4Png></MedicalFacility4Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(medicalfacility5_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <MedicalFacility5Png></MedicalFacility5Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(medicalfacility6_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <MedicalFacility6Png></MedicalFacility6Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(medicalfacility7_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <MedicalFacility7Png></MedicalFacility7Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(medicalfacility8_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <MedicalFacility8Png></MedicalFacility8Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(medicalfacility9_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <MedicalFacility9Png></MedicalFacility9Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(medicalfacility10_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <MedicalFacility10Png></MedicalFacility10Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(restaurant1_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Restaurant1Png></Restaurant1Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(restaurant2_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Restaurant2Png></Restaurant2Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(restaurant3_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Restaurant3Png></Restaurant3Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(restaurant4_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Restaurant4Png></Restaurant4Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(restaurant5_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Restaurant5Png></Restaurant5Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(restaurant6_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Restaurant6Png></Restaurant6Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(restaurant7_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Restaurant7Png></Restaurant7Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(restaurant8_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Restaurant8Png></Restaurant8Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(restaurant9_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Restaurant9Png></Restaurant9Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(restaurant10_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Restaurant10Png></Restaurant10Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(shopping1_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Shopping1Png></Shopping1Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(shopping2_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Shopping2Png></Shopping2Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(shopping3_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Shopping3Png></Shopping3Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(shopping4_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Shopping4Png></Shopping4Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(shopping5_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Shopping5Png></Shopping5Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(shopping6_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Shopping6Png></Shopping6Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(shopping7_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Shopping7Png></Shopping7Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(shopping8_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Shopping8Png></Shopping8Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(shopping9_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Shopping9Png></Shopping9Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(shopping10_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Shopping10Png></Shopping10Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(supermarket1_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Supermarket1Png></Supermarket1Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(supermarket2_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Supermarket2Png></Supermarket2Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(supermarket3_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Supermarket3Png></Supermarket3Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(supermarket4_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Supermarket4Png></Supermarket4Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(supermarket5_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Supermarket5Png></Supermarket5Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(supermarket6_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Supermarket6Png></Supermarket6Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(supermarket7_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Supermarket7Png></Supermarket7Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(supermarket8_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Supermarket8Png></Supermarket8Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(supermarket9_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Supermarket9Png></Supermarket9Png>
                      </MapPinIconSectionPopupIcon>
                      <MapPinIconSectionPopupIcon
                        onClick={() => {
                          setSelectedMapPinIcon(supermarket10_image_s3)
                          setShowSelectMapPinIconPopup(false)
                        }}
                      >
                        <Supermarket10Png></Supermarket10Png>
                      </MapPinIconSectionPopupIcon>
                    </MapPinIconSectionPopup>
                  )}
                </MapPinIconSection>
              </div>
            }
            onRightBtnClick={() => {
              if (selectedMapPinIcon === null) {
                alert('맵 핀 아이콘을 선택해주세요')
              } else {
                if (address === '') {
                  alert('주소를 입력해주세요')
                } else {
                  setChoosePosition(true)
                  setPosition({
                    latitude: position.latitude,
                    longitude: position.longitude,
                  })
                  setTopicPosition({
                    latitude: position.latitude,
                    longitude: position.longitude,
                  })
                  setEditLocationModalVisible(false)
                }
              }
            }}
            onClose={() => {
              setSelectedMapPinIcon(null)
              setShowSelectMapPinIconPopup(false)
              setEditLocationModalVisible(false)
            }}
            rightBtnText={'저장'}
            questionSection={<p>---</p>}
            questionWidth={500}
            questionChangeWidth={180}
          ></NormalModal>
        )}
      </>
    )
  )
}

export default MainGuidesGuidesPage

const GuidesHeader = styled.div`
  padding-left: 10%;
  padding-right: 10%;
  background-color: #fff;
  min-height: 100px;
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  align-items: center;
  justify-content: space-between;
  display: flex;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    padding-left: 15px;
    padding-right: 15px;
    height: 70px;
  }
`

const GuidesHeaderTitle = styled.div`
  font-size: 2.25rem;
  width: 50%;
  float: left;
  box-sizing: border-box;
  overflow-wrap: break-word;
  @media only screen and (min-width: 992px) {
    width: 33.33333%;
  }
  @media (max-width: 1024px) {
    font-size: 1.5rem;
    line-height: 3.75rem;
  }
`

const GuidesHeaderLeftPart = styled.div`
  width: 50%;
  box-sizing: border-box;
  float: left;
  @media only screen and (min-width: 992px) {
    width: 66.66667%;
  }
`

const GuidesHeaderLeftInPart = styled.div`
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  align-items: center;
`

const GuidesBody1 = styled.div`
  padding-left: 10%;
  padding-right: 10%;
  background-color: #f8f8f8;
  height: 50px;
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  align-items: center;
  justify-content: space-between;
  display: flex;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`

const GuidesBody1Left = styled.div`
  width: 25%;
  box-sizing: border-box;
  float: left;
`

const GuidesBody1Right = styled.div`
  width: 75%;
  box-sizing: border-box;
  float: left;
`

const GuidesBody1RightInner = styled.div`
  align-items: center;
  justify-content: flex-end;
  display: flex;
  box-sizing: border-box;
`

// const GuidesQSpan = styled.span`
//   padding-right: 5px;
//   display: inline-flex;
//   align-items: center;
//   box-sizing: border-box;
//   justify-content: center;
// `;

const GuidesBody2 = styled.div`
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 24px;
  flex: 1 0 auto;
  flex-direction: column;
  display: flex;
  box-sizing: border-box;
  overflow-y: auto;
  height: calc(100vh - 285px);
  @media (max-width: 1024px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`

const GuidesItemSection = styled.section`
  flex: 0 1 auto;
  border-radius: 8px;
  width: 100%;
  background-color: #fff;
  margin-bottom: 24px;
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  display: flex;
  align-items: center;
  flex-direction: row;
  box-sizing: border-box;
  min-width: 0;
  line-height: 18px;
`

const GuideImg = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 8px;
  border: 0px solid #ffffff;
`

const GuideTitle = styled.div`
  min-width: 0;
  width: 20.83333%;
  float: left;
  box-sizing: border-box;
  display: flex;

  @media only screen and (min-width: 1200px) {
    width: 25%;
  }
`

const GuideTitleInner = styled.span`
  display: block;
  margin: 0 20px;
  padding-bottom: 2px;
  line-height: 18px;
  border-bottom: 2px solid black;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000;
  text-decoration: none;
  background-color: transparent;
  box-sizing: border-box;
`

const GuidePart3 = styled.div`
  min-width: 0;
  width: 12.5%;
  float: left;
  box-sizing: border-box;

  @media only screen and (min-width: 1200px) {
    width: 16.66667%;
  }
`

const GuidePart4 = styled.div`
  min-width: 0;
  width: 16.66667%;
  float: left;
  box-sizing: border-box;
`

const GuidePart5 = styled.div`
  min-width: 0;
  width: 12.5%;
  float: left;
  box-sizing: border-box;
  display: flex;
`

const GuidePart5ViewsText = styled.div`
  color: #848789;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
  text-align: center;
  box-sizing: border-box;
  display: block;
`

const GuidePart6 = styled.div`
  min-width: 0;
  width: 12.5%;
  float: left;
  box-sizing: border-box;
  display: flex;
  cursor: pointer;
  justify-content: center;
  position: relative;

  @media only screen and (min-width: 1200px) {
    width: 8.33333%;
  }
`

const GuidePart7 = styled.div`
  min-width: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 29.16667%;
  float: left;
  box-sizing: border-box;

  @media only screen and (min-width: 1200px) {
    width: 20.83333%;
  }
`

const GuideContentBody1 = styled.div`
  height: 60px;
  background-color: #f8f8f8;
  padding: 10px 10%;
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  border-bottom: 1px solid #e1e1e1;
  align-items: center;
  justify-content: space-between;
  display: flex;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    background-color: inherit;
    padding: 20px;
    border-bottom: none;
  }
`

const MapPinIconSection = styled.div`
  margin-bottom: 20px;
  position: relative;
  box-sizing: border-box;
`

const MapPinIconSectionInner = styled.div`
  align-items: center;
  display: flex;
  box-sizing: border-box;
`

const MapPinIconSectionInnerBtn = styled.button`
  padding: 8px 16px;
  border-radius: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #000;
  background-color: #ededed;
  border-color: #ededed;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  border: 1px solid #dcdfe6;

  @media (max-width: 1024px) {
    padding: 8px;
    font-size: 10px;
  }
`

const MapPinIconSectionInnerIcon = styled.div`
  margin-left: 15px;
  font-size: 1.5rem;
  box-sizing: border-box;
  color: #000;
`

const MapPinIconSectionPopup = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
  background-color: #fff;
  z-index: 10001;
  box-shadow: 0 1px 9px #00000040;
  width: 100%;
  max-height: 300px;
  overflow: auto;
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`

const MapPinIconSectionPopupIcon = styled.div`
  cursor: pointer;
  padding: 5px;
  transition: background-color 0.1s ease-in-out;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  box-sizing: border-box;
  word-break: break-all;
`
