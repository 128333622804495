import PcMainHeader from '../../design_system/components/header/pc_main_header/PcMainHeader'
import { ChangeBtnA } from '../../design_system/components/btn/pc/change_btn_a/ChangeBtnA'
import {
  c_112b38,
  c_353431,
  c_b49275,
  c_eba55d,
  c_ffffff,
} from '../../design_system/colors'
import {
  login_inner_router_url,
  sign_up_inner_router_url,
} from '../../communication_system/inner_router_url/inner_router_url'
import {
  noto_sans_kr_bold,
  noto_sans_kr_medium,
  noto_sans_kr_regular,
} from '../../design_system/fonts/fonts'
import styled from 'styled-components'
import { Logo2x } from '../../design_system/icons/icons'

const TermsAndConditionsPage = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        overflowX: 'hidden',
        overflowY: 'auto',
      }}
    >
      <PcMainHeader>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => {
            const baseUrl = window.location.origin
            const url = `${baseUrl}`
            window.open(url, '_self')
          }}
        >
          <Logo2x></Logo2x>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ChangeBtnA
            href={login_inner_router_url}
            beforeBackgroudColor={c_ffffff}
            beforeBorderColor={c_eba55d}
            beforeFontColor={c_353431}
            afterBackgroudColor={c_b49275}
            afterBorderColor={c_b49275}
            afterFontColor={c_ffffff}
          >
            로그인
          </ChangeBtnA>
          <div
            style={{
              marginLeft: 15,
            }}
          >
            <ChangeBtnA
              href={sign_up_inner_router_url}
              beforeBackgroudColor={c_b49275}
              beforeBorderColor={c_b49275}
              beforeFontColor={c_ffffff}
              afterBackgroudColor={c_eba55d}
              afterBorderColor={c_eba55d}
              afterFontColor={c_353431}
            >
              무료 시작
            </ChangeBtnA>
          </div>
        </div>
      </PcMainHeader>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <ContentBody>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_bold,
              color: c_112b38,
              marginTop: 50,
            }}
          >
            이용약관
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            제1조 목적
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            본 이용약관은 “두곰” (이하 "사이트")의 서비스의 이용조건과 운영에
            관한 제반 사항 규정을 목적으로 합니다.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            제2조 용어의 정의
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            본 약관에서 사용되는 주요한 용어의 정의는 다음과 같습니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            1. 회원 : 사이트의 약관에 동의하고 개인정보를 제공하여 회원등록을 한
            자로서, 사이트와의 이용계약을 체결하고 사이트를 이용하는 이용자를
            말합니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            2. 이용계약 : 사이트 이용과 관련하여 사이트와 회원간에 체결하는
            계약을 말합니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            3. 회원 아이디(이하 "ID") : 회원의 식별과 회원의 서비스 이용을
            위하여 회원별로 부여하는 고유한 문자와 숫자의 조합을 말합니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            4. 비밀번호 : 회원이 부여받은 ID와 일치된 회원임을 확인하고 회원의
            권익 보호를 위하여 회원이 선정한 문자와 숫자의 조합을 말합니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            5. 운영자 : 서비스에 홈페이지를 개설하여 운영하는 운영자를 말합니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            6. 해지 : 회원이 이용계약을 해약하는 것을 말합니다.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            제3조 약관 외 준칙
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            운영자는 필요한 경우 별도로 운영정책을 공지 안내할 수 있으며, 본
            약관과 운영정책이 중첩될 경우 운영정책이 우선 적용됩니다.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            제4조 이용계약 체결
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            1. 이용계약은 회원으로 등록하여 사이트를 이용하려는 자의 본 약관
            내용에 대한 동의와 가입신청에 대하여 운영자의 이용승낙으로
            성립합니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            2. 회원으로 등록하여 서비스를 이용하는 행위는 본 사이트의 이용약관에
            동의하는 것과 동일한 효력이 있습니다.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            제5조 서비스 이용 신청
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            1. 회원으로 등록하여 사이트를 이용하려는 이용자는 사이트에서
            요청하는 제반정보(이용자ID, 비밀번호, 이름, 닉네임 등)를 제공해야
            합니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            2. 타인의 정보를 도용하거나 허위의 정보를 등록하는 등 본인의 진정한
            정보를 등록하지 않은 회원은 사이트 이용과 관련하여 아무런 권리를
            주장할 수 없으며, 관계 법령에 따라 처벌받을 수 있습니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            3. 만 14세 미만의 경우에는 사이트에 가입할 수 없고, 허위 정보를
            사용하여 사이트를 이용하다 적발되는 경우에는 즉각 회원 탈퇴처리 및
            회원 데이터는 삭제되며, 관계 법령에 따라 처벌받을 수 있습니다.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            제6조 개인정보처리방침
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            사이트 및 운영자는 회원가입 시 제공한 개인정보 중 비밀번호를 가지고
            있지 않으며 이와 관련된 부분은 사이트의 개인정보처리방침을 따릅니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            운영자는 관계 법령이 정하는 바에 따라 회원등록정보를 포함한 회원의
            개인정보를 보호하기 위하여 노력합니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            회원의 개인정보보호에 관하여 관계법령 및 사이트가 정하는
            개인정보처리방침에 정한 바에 따릅니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            단, 회원의 귀책 사유로 인해 노출된 정보에 대해 운영자는 일체의
            책임을 지지 않습니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            운영자는 회원이 미풍양속에 저해되거나 국가안보에 위배되는 게시물 등
            위법한 게시물을 등록/배포할 경우 관련 기관의 요청이 있을 시 회원의
            자료를 열람 및 해당 자료를 관련 기관에 제출할 수 있습니다.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            제7조 운영자의 의무
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            1. 운영자는 이용회원으로부터 제기되는 의견이나 불만이 정당하다고
            인정할 경우에는 가급적 빨리 처리하여야 합니다. 다만, 개인적인
            사정으로 신속한 처리가 곤란한 경우에는 사후에 공지 또는 이용회원에게
            전자우편, 전화 등을 통해 최선을 다해 처리합니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            2. 운영자는 계속적이고 안정적인 사이트 제공을 위하여 설비에 장애가
            생기거나 유실된 때에는 이를 지체 없이 수리 또는 복구할 수 있도록
            사이트에 요구할 수 있습니다. 다만, 천재지변 또는 사이트나 운영자에
            부득이한 사유가 있는 경우, 사이트 운영을 일시 정지할 수 있습니다.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            제8조 회원의 의무
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            1. 회원은 본 약관에서 규정하는 사항과 운영자가 정한 제반 규정,
            공지사항 및 운영정책 등 사이트가 공지하는 사항 및 관계 법령을
            준수하여야 하며, 기타 사이트의 업무에 방해가 되는 행위, 사이트의
            명예를 손상하는 행위를 해서는 안 됩니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            2. 회원은 사이트의 명시적 동의가 없는 한 서비스의 이용 권한, 기타
            이용계약상 지위를 타인에게 양도, 증여할 수 없으며, 이를 담보로
            제공할 수 없습니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            3. 이용고객은 아이디 및 비밀번호 관리에 상당한 주의를 기울여야 하며,
            운영자나 사이트의 동의 없이 제3자에게 아이디를 제공하여 이용하게 할
            수 없습니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            4. 회원은 운영자와 사이트 및 제3자의 지적 재산권을 침해해서는 안
            됩니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            5. 회원은 서비스를 이용하며 발생하는 문제에 대해 운영자의 과실 혹은
            사이트의 과실이 아닌 경우 회원이 직접 문제를 해결해야하며, 문제
            발생시 운영자는 회원에게 최대한 협조합니다.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            제9조 서비스 이용 시간
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            1. 서비스 이용 시간은 업무상 또는 기술상 특별한 지장이 없는 한
            연중무휴 1일 24시간을 원칙으로 합니다. 단, 사이트는 시스템 정기점검,
            증설 및 교체를 위해 사이트가 정한 날이나 시간에 서비스를 일시중단 할
            수 있으며 예정된 작업으로 인한 서비스 일시 중단은 사이트의
            홈페이지에 사전에 공지하오니 수시로 참고하시길 바랍니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            2. 단, 사이트는 다음 경우에 대하여 사전 공지나 예고 없이 서비스를
            일시적 혹은 영구적으로 중단할 수 있습니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            - 긴급한 시스템 점검, 증설, 교체, 고장 혹은 오동작을 일으키는 경우
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            - 국가비상사태, 정전, 천재지변 등의 불가항력적인 사유가 있는 경우
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            - 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지한
            경우
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            - 서비스 이용의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            3. 전항에 의한 서비스 중단의 경우 사이트는 사전에 공지사항 등을
            통하여 회원에게 통지합니다. 단, 사이트가 통제할 수 없는 사유로
            발생한 서비스의 중단에 대하여 사전공지가 불가능한 경우에는
            사후공지로 대신합니다.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            제10조 서비스 이용 해지
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            1. 회원이 사이트가 제공하는 유료서비스 이용계약을 해지하고자 하는
            경우에는 회원 본인이 사이트의 고객센터를 통하여 서비스 해지 신청을
            하여야 합니다. 한편, 사이트 유료서비스 이용 해지와는 별개로 사이트
            탈퇴 신청은 별도로 고객센터에 요청하셔야 합니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            2. 사이트 탈퇴 신청 이후 사이트 관리 페이지에서 회원의 정보는
            삭제됨으로 운영자는 더 이상 탈퇴 신청자의 정보를 볼 수 없습니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            3. 사이트는 회원에게 대금 등을 환불할 때에 기 지불한 대금의 결제와
            동일한 방법으로 전부 또는 일부를 환불합니다. 다만, 동일한 방법으로
            환불이 불가능할 때에는 이를 사전에 고지합니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            4. 회원이 환불을 하고자 하는 경우 사이트의 고객센터를 통하여 그
            의사표시를 하여야 하며, 사이트는 환불 요청을 접수하고 5영업일 이내에
            사이트의 환불 규정에 따라 서비스 이용료를 환불합니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            5. 사이트의 환불 규정은 아래와 같습니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            (1) 사이트가 제공하는 유료서비스의 내용이 사이트가 표시·광고한
            내용과 다른 경우 그 서비스를 공급받은 날로부터 30일 이내에는 전액
            환불이 가능합니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            (2) 유료서비스 반환 금액은 서비스 해지 요청 또는 환불을 신청한 날을
            기준으로 일할 계산하여 소급 적용됩니다. 소급 적용은 한 달 30일 또는
            31일(2월은 28일)을 기준으로 월별 적용됩니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            예&#41; 한 달 30일 기준, 12일 이용 후 환불 신청 시 미이용 18일에
            대한 금액이 환불됩니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            예&#41; 한 달 31일 기준, 12일 이용 후 환불 신청 시 미이용 19일에
            대한 금액이 환불됩니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            (3) 사이트의 귀책사유가 없는, 회원이 이미 이용한 날짜에 대한 환불은
            불가능합니다.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            제11조 서비스 이용 제한
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            회원은 다음 각호에 해당하는 행위를 하여서는 아니 되며 해당 행위를 한
            경우에 사이트는 회원의 서비스 이용 제한 및 적법한 조치를 할 수
            있으며 이용계약을 해지하거나 기간을 정하여 서비스를 중지할 수
            있습니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            1. 회원 가입시 혹은 가입 후 정보 변경 시 허위 내용을 등록하는 행위
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            2. 타인의 사이트 이용을 방해하거나 정보를 도용하는 행위
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            3. 사이트의 운영진, 직원 또는 관계자를 사칭하는 행위
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            4. 사이트, 기타 제3자의 인격권 또는 지적재산권을 침해하거나 업무를
            방해하는 행위
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            5. 다른 회원의 ID를 부정하게 사용하는 행위
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            6. 다른 회원에 대한 개인정보를 그 동의 없이 수집, 저장, 공개하는
            행위
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            7. 범죄와 결부된다고 객관적으로 판단되는 행위
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            8. 유료서비스 신청하여 이용 시 정기결제가 제대로 이루어지지 않는
            경우
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            9. 기타 관련 법령에 위배되는 행위
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            제12조 회원의 사이트 내용물에 대한 저작권
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            1. 회원이 사이트 내에 생성한 내용물의 저작권은 회원에게 귀속됩니다.
            단, 사이트는 회원의 동의 없이 내용물을 본 사이트의 광고/홍보 등의
            상업활동에 활용할 수 있습니다. 또한, 서비스 내의 게재권을 갖습니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            2. 회원은 서비스를 이용하여 취득한 정보를 임의 가공, 판매하는 행위
            등 서비스에 게재된 자료를 상업적으로 사용할 수 없습니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            3. 운영자는 회원이 등록하는 사이트 내의 내용물, 작성 내용에 대해
            제11조 각호에 해당한다고 판단되는 경우 사전통지 없이 삭제하거나 이동
            또는 등록 거부할 수 있습니다.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            제13조 손해배상
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            1. 본 사이트의 사용으로 발생한 모든 민, 형법상 책임은 회원 본인에게
            있습니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            2. 본 사이트로부터 회원이 받은 손해가 천재지변 등 불가항력적이거나
            회원의 고의 또는 과실로 인하여 발생한 때에는 손해배상을 하지
            않습니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            3. 본 사이트가 제공하는 유료서비스 이용과 관련하여 회사의 귀책사유로
            사전고지 없이 24시간이상 연속하여 서비스가 중지되거나 장애가 발생한
            경우, 사이트는 유료서비스 계정에 한하여 서비스 중지·장애시간에
            해당하는 이용 기간을 무료로 연장하고, 이때 회원은 사이트에 대하여
            별도의 손해배상을 청구할 수 없습니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            4. 서버점검 등의 사유로 서비스 중지·장애를 사전에 고지하였으나
            서비스 중지·장애시간이 24시간을 초과하는 경우에는, 그 초과된
            시간만큼의 기간을 유료서비스 계정에 한하여 무료로 연장하고, 이때
            회원은 사이트에 대하여 별도의 손해배상을 청구할 수 없습니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            5. 회원과 회원, 또는 회원과 제3자 상호간의 서비스, 물품 거래 등
            일체의 활동에 대하여 사이트는 책임을 지지 않으며, 회원은 그에 대한
            손해배상을 청구할 수 없습니다.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            제14조 면책
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            1. 운영자는 회원이 사이트의 서비스 제공으로부터 기대되는 이익을 얻지
            못하였거나 서비스 자료에 대한 취사선택 또는 이용으로 발생하는 손해
            등에 대해서는 책임이 면제됩니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            2. 운영자는 본 사이트의 서비스 기반 및 타 통신업자가 제공하는
            전기통신 서비스의 장애로 인한 경우에는 책임이 면제되며 본 사이트의
            서비스 기반과 관련되어 발생한 손해에 대해서는 사이트의 이용약관에
            준합니다
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            3. 운영자는 회원이 저장, 생성 또는 전송한 자료와 관련하여 일체의
            책임을 지지 않습니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            4. 운영자는 회원의 귀책 사유로 인하여 서비스 이용의 장애가 발생한
            경우에는 책임지지 아니합니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            5. 운영자는 회원 상호 간 또는 회원과 제3자 상호 간, 기타 회원의 본
            서비스 내외를 불문한 일체의 활동(데이터 전송, 기타 커뮤니티 활동
            포함)에 대하여 책임을 지지 않습니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            6. 운영자는 회원이 작성 또는 전송한 자료 및 본 사이트로 회원이
            제공받을 수 있는 모든 자료들의 진위, 신뢰도, 정확성 등 그 내용에
            대해서는 책임지지 아니합니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            7. 운영자는 회원 상호 간 또는 회원과 제3자 상호 간에 서비스를 매개로
            하여 물품거래 등을 한 경우에 그로부터 발생하는 일체의 손해에 대하여
            책임지지 아니합니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            8. 운영자는 운영자의 귀책 사유 없이 회원간 또는 회원과 제3자간에
            발생한 일체의 분쟁에 대하여 책임지지 아니합니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            9. 운영자는 서버 등 설비의 관리, 점검, 보수, 교체 과정 또는
            소프트웨어의 운용 과정에서 고의 또는 고의에 준하는 중대한 과실 없이
            발생할 수 있는 시스템의 장애, 제3자의 공격으로 인한 시스템의 장애,
            국내외의 저명한 연구기관이나 보안 관련 업체에 의해 대응 방법이
            개발되지 아니한 컴퓨터 바이러스 등의 유포나 기타 운영자가 통제할 수
            없는 불가항력적 사유로 인한 회원의 손해에 대하여 책임지지 않습니다.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            부칙
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            이 약관은 &lt;사이트 개설일&gt;부터 시행합니다.
          </div>
          <div
            style={{
              height: 50,
            }}
          ></div>
        </ContentBody>
      </div>
    </div>
  )
}

export default TermsAndConditionsPage

const ContentBody = styled.div`
  width: 1280px;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  box-sizing: border-box;

  @media (max-width: 1580px) {
    width: 80%;
  }

  @media (max-width: 768px) {
    width: 90%;
  }
`
