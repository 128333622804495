import MainTopCycleImage1 from './main_top_cycle_image1.jpg'
import MainTopCycleImage2 from './main_top_cycle_image2.jpg'
import MainTopCycleImage3 from './main_top_cycle_image3.jpg'
import MainTopCycleImage4 from './main_top_cycle_image4.jpg'
import MainTopCycleImage5 from './main_top_cycle_image5.jpg'
import MainTopCycleImage6 from './main_top_cycle_image6.jpg'
import MainTopCycleImage7 from './main_top_cycle_image7.jpg'
import MainTopCycleImage8 from './main_top_cycle_image8.jpg'
import MainMiddleImage1 from './main_middle_image1.png'
import MainMiddleImage2 from './main_middle_image2.png'
import MainMiddleImage3 from './main_middle_image3.png'
import MainMiddleImage4 from './main_middle_image4.png'
import MainMiddleImage5 from './main_middle_image5.png'
import CircleImage1_1 from './circle_image1_1.png'
import CircleImage1_2 from './circle_image1_2.png'
import CircleImage2_1 from './circle_image2_1.png'
import CircleImage2_2 from './circle_image2_2.png'
import CircleImage3_1 from './circle_image3_1.png'
import CircleImage3_2 from './circle_image3_2.png'
import CircleImage4_1 from './circle_image4_1.png'
import CircleImage4_2 from './circle_image4_2.png'
import DescriptionImage1 from './description_image1.png'
import DescriptionImage2 from './description_image2.png'
import CompanyIntroMiddleImage from './company_intro_middle_image.jpg'
import Image1330 from './image_1330.png'

export const image_1330 = Image1330
export const main_top_cycle_image1 = MainTopCycleImage1
export const main_top_cycle_image2 = MainTopCycleImage2
export const main_top_cycle_image3 = MainTopCycleImage3
export const main_top_cycle_image4 = MainTopCycleImage4
export const main_top_cycle_image5 = MainTopCycleImage5
export const main_top_cycle_image6 = MainTopCycleImage6
export const main_top_cycle_image7 = MainTopCycleImage7
export const main_top_cycle_image8 = MainTopCycleImage8
export const main_middle_image1 = MainMiddleImage1
export const main_middle_image2 = MainMiddleImage2
export const main_middle_image3 = MainMiddleImage3
export const main_middle_image4 = MainMiddleImage4
export const main_middle_image5 = MainMiddleImage5
export const circle_image1_1 = CircleImage1_1
export const circle_image1_2 = CircleImage1_2
export const circle_image2_1 = CircleImage2_1
export const circle_image2_2 = CircleImage2_2
export const circle_image3_1 = CircleImage3_1
export const circle_image3_2 = CircleImage3_2
export const circle_image4_1 = CircleImage4_1
export const circle_image4_2 = CircleImage4_2
export const description_image1 = DescriptionImage1
export const description_image2 = DescriptionImage2
export const company_intro_middle_image = CompanyIntroMiddleImage
