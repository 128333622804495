import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { MainGuidesContainer } from '../../design_system/components/main_guides_container/MainGuidesContainer'
import { MiddleHeader } from '../../design_system/components/middle_header/MiddleHeader'
import { useSelectPaymentHistoryHeader } from '../../data_system/context/select_payment_history_header_index_context/SelectPaymentHistoryHeaderIndexContext'
import { dateAndTimeFormatFunction } from '../../data_system/data_function/date_and_time_format_function'
import { priceFormatFunction } from '../../data_system/data_function/price_format_function'
import { PaymentHistoryLongTile } from '../../design_system/components/payment_history_long_tile/PaymentHistoryLongTile'
import { getSubscribePaymentsWithUserByUserId } from '../../communication_system/axios_apis/axios_apis'
import moment from 'moment'

const PaymentHistoryPage = () => {
  const { selectPaymentHistoryHeaderIndex, setPaymentHistoryHeaderIndex } =
    useSelectPaymentHistoryHeader()
  const [loading, setLoading] = useState(true)
  const [paymentHistories, setPaymentHistories] = useState([])
  const payment_history_header_content = [
    {
      id: 0,
      title: '결제 내역',
    },
  ]

  useEffect(() => {
    async function getData() {
      setLoading(true)
      const response = await getSubscribePaymentsWithUserByUserId()

      console.log(response)
      if (response) {
        setPaymentHistories(response.data.payments)
      }
      setLoading(false)
    }

    getData()
  }, [])

  return (
    <MainGuidesContainer>
      <TopSection></TopSection>
      <MiddleHeader
        selectHeaderIndex={selectPaymentHistoryHeaderIndex}
        headerContent={payment_history_header_content}
        setHeaderIndex={setPaymentHistoryHeaderIndex}
      ></MiddleHeader>
      <BottomSection>
        {selectPaymentHistoryHeaderIndex === 0 && (
          <div>
            {paymentHistories.map((paymentHistory) => {
              return (
                <PaymentHistoryLongTile
                  key={paymentHistory.id}
                  title={
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                      }}
                    >
                      <div>
                        날짜:{' '}
                        {dateAndTimeFormatFunction(
                          moment.utc(paymentHistory.createdAt).clone()
                        )}
                      </div>
                      <br></br>
                      <div>
                        요금제:{' '}
                        {paymentHistory.productId === 2
                          ? '베이직'
                          : paymentHistory.productId === 3
                          ? '스탠다드'
                          : ''}
                      </div>
                      <br></br>
                      <div>안내문 개수: {paymentHistory.number}</div>
                      <br></br>
                      <div>
                        금액: {priceFormatFunction(paymentHistory.totalPrice)}
                      </div>
                      <br></br>
                      <div>
                        결제수단:{' '}
                        <span>
                          {paymentHistory.paymentTypeId === 2
                            ? '신용카드'
                            : paymentHistory.paymentTypeId === 3
                            ? '계좌이체'
                            : ''}
                        </span>
                      </div>
                    </div>
                  }
                  btnSection={<></>}
                  questionVisible={false}
                  questionSection={<></>}
                ></PaymentHistoryLongTile>
              )
            })}
          </div>
        )}
      </BottomSection>
    </MainGuidesContainer>
  )
}

export default PaymentHistoryPage

const TopSection = styled.div`
  height: 60px;
  background: #ffffff;
  box-sizing: border-box;
`

const BottomSection = styled.div`
  padding: 20px 10%;
  height: calc(100vh - 266px);
  background: #f3f3f3;
  box-sizing: border-box;
  overflow-y: auto;

  @media (max-width: 1024px) {
    padding: 20px 10px;
    background: #f3f3f3;
  }
`
