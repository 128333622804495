import React from 'react'
import styled from 'styled-components'

const CheckBox = ({ onClick, checked }) => {
  return (
    <CheckBoxInput onClick={onClick} checked={checked} readOnly></CheckBoxInput>
  )
}

export default React.memo(CheckBox)

const CheckBoxInput = styled.input.attrs({ type: 'checkbox' })`
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  width: 24px;
  height: 24px;
  border: 1px solid #dcdfe6;
  border-radius: 2px;
  margin: 0px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  flex-shrink: 0;

  &::after {
    content: '';
    box-sizing: content-box;
    border: 1px solid #fff;
    border-left: 0;
    border-top: 0;
    transform: rotate(45deg);
    width: 25%;
    height: 50%;
    left: 35%;
    top: 15%;
  }
  &:checked {
    background-color: #eba55d;
    border-color: #eba55d;
  }
`
