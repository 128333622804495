import styled from 'styled-components'
import { MainGuidesContainer } from '../../design_system/components/main_guides_container/MainGuidesContainer'
import { MiddleHeader } from '../../design_system/components/middle_header/MiddleHeader'
import { useSelectDefaultGuideHeader } from '../../data_system/context/select_default_guide_header_index_context/SelectDefaultGuideHeaderIndexContext'
import IconTextCircleBtn from '../../design_system/components/btn/pc/icon_text_circle_btn/IconTextCircleBtn'
import {
  ExternalLinkIcon,
  PlusIcon,
  QuestionMarkInCircle,
} from '../../design_system/icons/icons'
import { useEffect, useRef, useState, useCallback } from 'react'
import IconTooltip from '../../design_system/components/icon_tooltip/IconTooltip'
import { SettingsItem } from '../../design_system/components/settings_item/SettingsItem'
import { getDefaultGuide } from '../../communication_system/axios_apis/axios_apis'
import { useLoading } from '../../data_system/context/loading_context/LoadingContext'
import { usePopup } from '../../data_system/context/popup_context/PopupContext'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import MainGuidesDefaultGuideContentTab from './main_guides_default_guide_content_tab/MainGuidesDefaultGuideContentTab'
import MainGuidesDefaultGuideCoverTab from './main_guides_default_guide_cover_tab/MainGuidesDefaultGuideCoverTab'
import {
  Activity1Png,
  Activity2Png,
  activity1_image_s3,
  activity2_image_s3,
  activity3_image_s3,
  Activity3Png,
  activity4_image_s3,
  Activity4Png,
  activity5_image_s3,
  Activity5Png,
  activity6_image_s3,
  Activity6Png,
  activity7_image_s3,
  Activity7Png,
  activity8_image_s3,
  Activity8Png,
  activity9_image_s3,
  Activity9Png,
  activity10_image_s3,
  Activity10Png,
  bars_pub1_image_s3,
  BarsPub1Png,
  bars_pub2_image_s3,
  BarsPub2Png,
  bars_pub3_image_s3,
  BarsPub3Png,
  bars_pub4_image_s3,
  BarsPub4Png,
  bars_pub5_image_s3,
  BarsPub5Png,
  bars_pub6_image_s3,
  BarsPub6Png,
  bars_pub7_image_s3,
  BarsPub7Png,
  bars_pub8_image_s3,
  BarsPub8Png,
  bars_pub9_image_s3,
  BarsPub9Png,
  bars_pub10_image_s3,
  BarsPub10Png,
  cafe1_image_s3,
  Cafe1Png,
  cafe2_image_s3,
  Cafe2Png,
  cafe3_image_s3,
  Cafe3Png,
  cafe4_image_s3,
  Cafe4Png,
  cafe5_image_s3,
  Cafe5Png,
  cafe6_image_s3,
  Cafe6Png,
  cafe7_image_s3,
  Cafe7Png,
  cafe8_image_s3,
  Cafe8Png,
  cafe9_image_s3,
  Cafe9Png,
  cafe10_image_s3,
  Cafe10Png,
  gettingaround1_image_s3,
  GettingAround1Png,
  gettingaround2_image_s3,
  GettingAround2Png,
  medicalfacility1_image_s3,
  MedicalFacility1Png,
  supermarket1_image_s3,
  gettingaround3_image_s3,
  GettingAround3Png,
  gettingaround4_image_s3,
  GettingAround4Png,
  gettingaround5_image_s3,
  GettingAround5Png,
  gettingaround6_image_s3,
  GettingAround6Png,
  gettingaround7_image_s3,
  GettingAround7Png,
  gettingaround8_image_s3,
  GettingAround8Png,
  gettingaround9_image_s3,
  GettingAround9Png,
  gettingaround10_image_s3,
  GettingAround10Png,
  home1_image_s3,
  Home1Png,
  home2_image_s3,
  Home2Png,
  home3_image_s3,
  Home3Png,
  home4_image_s3,
  Home4Png,
  home5_image_s3,
  Home5Png,
  home6_image_s3,
  Home6Png,
  home7_image_s3,
  Home7Png,
  home8_image_s3,
  Home8Png,
  home9_image_s3,
  home10_image_s3,
  Home9Png,
  Home10Png,
  medicalfacility2_image_s3,
  MedicalFacility2Png,
  medicalfacility3_image_s3,
  MedicalFacility3Png,
  medicalfacility4_image_s3,
  MedicalFacility4Png,
  medicalfacility5_image_s3,
  MedicalFacility5Png,
  medicalfacility6_image_s3,
  MedicalFacility6Png,
  medicalfacility7_image_s3,
  MedicalFacility7Png,
  medicalfacility8_image_s3,
  MedicalFacility8Png,
  medicalfacility9_image_s3,
  MedicalFacility9Png,
  medicalfacility10_image_s3,
  MedicalFacility10Png,
  restaurant1_image_s3,
  Restaurant1Png,
  restaurant2_image_s3,
  Restaurant2Png,
  restaurant3_image_s3,
  Restaurant3Png,
  restaurant4_image_s3,
  Restaurant4Png,
  restaurant5_image_s3,
  Restaurant5Png,
  restaurant6_image_s3,
  Restaurant6Png,
  restaurant7_image_s3,
  Restaurant7Png,
  restaurant8_image_s3,
  Restaurant8Png,
  restaurant9_image_s3,
  Restaurant9Png,
  restaurant10_image_s3,
  Restaurant10Png,
  shopping1_image_s3,
  Shopping1Png,
  shopping2_image_s3,
  Shopping2Png,
  shopping3_image_s3,
  Shopping3Png,
  shopping4_image_s3,
  Shopping4Png,
  shopping5_image_s3,
  Shopping5Png,
  shopping6_image_s3,
  Shopping6Png,
  shopping7_image_s3,
  Shopping7Png,
  shopping8_image_s3,
  Shopping8Png,
  shopping9_image_s3,
  Shopping9Png,
  shopping10_image_s3,
  Shopping10Png,
  supermarket2_image_s3,
  Supermarket2Png,
  supermarket3_image_s3,
  Supermarket3Png,
  supermarket4_image_s3,
  Supermarket4Png,
  supermarket5_image_s3,
  Supermarket5Png,
  supermarket6_image_s3,
  Supermarket6Png,
  supermarket7_image_s3,
  Supermarket7Png,
  supermarket8_image_s3,
  Supermarket8Png,
  supermarket9_image_s3,
  Supermarket9Png,
  supermarket10_image_s3,
  Supermarket10Png,
  Supermarket1Png,
} from '../../design_system/icons/icons'
import { GoogleMap, MarkerF } from '@react-google-maps/api'
import NormalModal from '../../design_system/components/normal_modal/NormalModal'
import { TitleBigDiv } from '../../design_system/components/title_big_div/TitleBigDiv'
import { TextInput } from '../../design_system/components/text_input/TextInput'
import { useGoogleMaps } from '../../data_system/context/google_maps_context/GoogleMapsContext'

const MainGuidesDefaultGuidePage = () => {
  const { isLoaded, loadError } = useGoogleMaps()
  console.log(isLoaded, loadError)
  const { showLoading, hideLoading } = useLoading()
  const { show } = usePopup()
  const { selectDefaultGuideHeaderIndex, setDefaultGuideHeaderIndex } =
    useSelectDefaultGuideHeader()
  const [defaultCategories, setDefaultCategories] = useState([])
  const [addDefaultCategoryModalVisible, setAddDefaultCategoryModalVisible] =
    useState(false)

  const [defaultGuide, setDefaultGuide] = useState([])
  const [logoImgUrlText, setLogoImgUrlText] = useState(null)
  const [iconImgUrlText, setIconImgUrlText] = useState(null)
  const [logoUploadImgUrl, setLogoUploadImgUrl] = useState('')
  const [iconUploadImgUrl, setIconUploadImgUrl] = useState('')

  const [addLocationModalVisible, setAddLocationModalVisible] = useState(false)
  const [editLocationModalVisible, setEditLocationModalVisible] =
    useState(false)

  const [showSelectMapPinIconPopup, setShowSelectMapPinIconPopup] =
    useState(false)

  const [selectedCategoryIcon, setSelectedCategoryIcon] = useState(null)

  const [selectedMapPinIcon, setSelectedMapPinIcon] = useState(null)

  const [map, setMap] = useState(null)

  const onLoad = useCallback(function callback(map) {
    map.setZoom(zoom)

    setMap(map)
  }, [])

  const onUnmount = useCallback(function callback(map) {
    setMap(null)
  }, [])

  const [choosePosition, setChoosePosition] = useState(false)
  const [position, setPosition] = useState({
    latitude: 37.5665,
    longitude: 126.978,
  })
  const [defaultTopicPosition, setDefaultTopicPosition] = useState({
    latitude: null,
    longitude: null,
  })

  const [zoom, setZoom] = useState(15)

  const [address, setAddress] = useState('')
  const [suggestions, setSuggestions] = useState([])

  const [userInput, setUserInput] = useState(false)

  const [mapWidth, setMapWidth] = useState(600)

  const onMarkerDragEnd = (event) => {
    setPosition({
      latitude: event.latLng.lat(),
      longitude: event.latLng.lng(),
    })
  }

  const handleInputChange = (e) => {
    setAddress(e.target.value)
    setUserInput(true) // 사용자 입력이 변경되면 true로 설정
  }

  const getPlaceDetails = (placeId) => {
    const placesService = new window.google.maps.places.PlacesService(
      document.createElement('div')
    )

    placesService.getDetails(
      {
        placeId: placeId,
        fields: ['geometry'],
        language: 'ko',
      },
      (place, status) => {
        if (
          status === window.google.maps.places.PlacesServiceStatus.OK &&
          place.geometry
        ) {
          setPosition({
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng(),
          })
          setZoom(15)
          setSuggestions([]) // 해당 위치를 선택한 후에는 제안 목록을 초기화
        }
      }
    )
  }

  const handleSuggestionClick = (description, placeId) => {
    setAddress(description)
    setUserInput(false)
    getPlaceDetails(placeId) // 제안 클릭시에는 getPlaceDetails를 직접 호출
  }

  const default_guide_header_content = [
    {
      id: 0,
      title: '표지',
    },
    {
      id: 1,
      title: '내용',
    },
  ]

  const [visible, setVisible] = useState(false)

  const handleVisible = () => {
    setVisible((pre) => !pre)
  }

  const logoRoundedStateRadioItems = [
    { id: '0', title: '원본' },
    { id: '1', title: '둥글게' },
  ]

  const [selectedLogoRoundedStateRadioId, setSelectedLogoRoundedStateRadioId] =
    useState(logoRoundedStateRadioItems[0].id)

  useEffect(() => {
    async function getData() {
      showLoading()
      const response = await getDefaultGuide()
      console.log(response)
      setDefaultCategories(response.data.defaultCategories)
      setDefaultGuide(response.data.defaultGuide)
      setLogoImgUrlText(response.data.defaultGuide.logoImageUrl)
      setIconImgUrlText(response.data.defaultGuide.iconImageUrl)
      setLogoUploadImgUrl(response.data.defaultGuide.logoImageUrl)
      setIconUploadImgUrl(response.data.defaultGuide.iconImageUrl)
      setSelectedLogoRoundedStateRadioId(
        response.data.defaultGuide.logoImageRounded === false
          ? logoRoundedStateRadioItems[0].id
          : logoRoundedStateRadioItems[1].id
      )
      hideLoading()
    }

    getData()
  }, [])

  useEffect(() => {
    if (!address || !userInput) {
      setSuggestions([])
      return
    }

    if (window.google && window.google.maps && window.google.maps.places) {
      const autocompleteService =
        new window.google.maps.places.AutocompleteService()

      autocompleteService.getPlacePredictions(
        {
          input: address,
          componentRestrictions: { country: 'kr' },
          language: 'ko',
        },
        (predictions, status) => {
          if (
            status === window.google.maps.places.PlacesServiceStatus.OK &&
            predictions
          ) {
            setSuggestions(predictions)
          }
        }
      )
    }
  }, [address, userInput])

  useEffect(() => {
    const handleResize = () => {
      const mapDiv = document.querySelector('.map-div')
      if (mapDiv) {
        setMapWidth(mapDiv.offsetWidth)
      } else {
        setMapWidth(600)
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize() // 초기 실행

    return () => {
      window.removeEventListener('resize', handleResize) // 컴포넌트가 언마운트될 때 리스너를 제거합니다.
    }
  }, [choosePosition])

  return (
    <>
      <MainGuidesContainer>
        <DefaultGuideHeader>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <DefaultGuideHeaderTitle>기본 안내문</DefaultGuideHeaderTitle>
            <div
              style={{
                marginLeft: 10,
              }}
            >
              <IconTooltip
                icon={
                  <QuestionMarkInCircle
                    onClick={handleVisible}
                  ></QuestionMarkInCircle>
                }
                handleClick={handleVisible}
                visible={visible}
                tooltipTop={2.5}
                tooltipLeft={30}
                tooltipDefaultWidth={450}
                tooltipChangeWidth={290}
              >
                <div
                  style={{
                    padding: 20,
                  }}
                >
                  기본 안내문은 추가 생성 안내문의 기본 틀 입니다. 1개의
                  안내문을 사용하는 이용자가 안내문을 추가 생성하면, 새로
                  생성되는 안내문은 기본 안내문의 내용이 나오게 됩니다. 각각의
                  안내문 수정은 안내문탭&gt;숙소명&gt;내용 부분을 수정해
                  주세요.(숙소 안내문을 1개만 사용하는 이용자라면 기본 안내문을
                  특별히 수정하지 않아도 됩니다.)
                </div>
              </IconTooltip>
            </div>
          </div>
        </DefaultGuideHeader>
        <MiddleHeader
          selectHeaderIndex={selectDefaultGuideHeaderIndex}
          headerContent={default_guide_header_content}
          setHeaderIndex={setDefaultGuideHeaderIndex}
          rightSideVisible={true}
          rightSideComponents={
            <div
              style={{
                paddingBottom: 20,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <IconTextCircleBtn
                text={'미리보기'}
                borderColor={'#b49275'}
                backgroundColor={'#b49275'}
                textColor={'#fff'}
                onClick={() => {
                  window.open(defaultGuide.shareUrl, '_blank')
                }}
              >
                <ExternalLinkIcon></ExternalLinkIcon>
              </IconTextCircleBtn>
            </div>
          }
        ></MiddleHeader>
        {selectDefaultGuideHeaderIndex === 1 && (
          <DefaultGuideContentBody1>
            <IconTextCircleBtn
              text={'카테고리 추가하기'}
              borderColor={'#b49275'}
              backgroundColor={'#b49275'}
              textColor={'#fff'}
              onClick={() => {
                setSelectedCategoryIcon(null)
                setAddDefaultCategoryModalVisible(true)
              }}
            >
              <PlusIcon></PlusIcon>
            </IconTextCircleBtn>
          </DefaultGuideContentBody1>
        )}
        {selectDefaultGuideHeaderIndex === 0 && (
          <MainGuidesDefaultGuideCoverTab
            defaultGuide={defaultGuide}
            setDefaultGuide={setDefaultGuide}
            logoImgUrlText={logoImgUrlText}
            setLogoImgUrlText={setLogoImgUrlText}
            iconImgUrlText={iconImgUrlText}
            setIconImgUrlText={setIconImgUrlText}
            logoUploadImgUrl={logoUploadImgUrl}
            setLogoUploadImgUrl={setLogoUploadImgUrl}
            iconUploadImgUrl={iconUploadImgUrl}
            setIconUploadImgUrl={setIconUploadImgUrl}
            selectedLogoRoundedStateRadioId={selectedLogoRoundedStateRadioId}
            setSelectedLogoRoundedStateRadioId={
              setSelectedLogoRoundedStateRadioId
            }
            logoRoundedStateRadioItems={logoRoundedStateRadioItems}
          ></MainGuidesDefaultGuideCoverTab>
        )}
        {selectDefaultGuideHeaderIndex === 1 && (
          <MainGuidesDefaultGuideContentTab
            defaultGuide={defaultGuide}
            defaultCategories={defaultCategories}
            setDefaultCategories={setDefaultCategories}
            addDefaultCategoryModalVisible={addDefaultCategoryModalVisible}
            setAddDefaultCategoryModalVisible={
              setAddDefaultCategoryModalVisible
            }
            selectedCategoryIcon={selectedCategoryIcon}
            setSelectedCategoryIcon={setSelectedCategoryIcon}
            setAddLocationModalVisible={setAddLocationModalVisible}
            setEditLocationModalVisible={setEditLocationModalVisible}
            choosePosition={choosePosition}
            setChoosePosition={setChoosePosition}
            position={position}
            setPosition={setPosition}
            defaultTopicPosition={defaultTopicPosition}
            setDefaultTopicPosition={setDefaultTopicPosition}
            mapWidth={mapWidth}
            selectedMapPinIcon={selectedMapPinIcon}
            setSelectedMapPinIcon={setSelectedMapPinIcon}
            address={address}
            setAddress={setAddress}
          ></MainGuidesDefaultGuideContentTab>
        )}
      </MainGuidesContainer>
      {addLocationModalVisible && (
        <NormalModal
          titleText={`위치 수정하기`}
          leftBtnVisible={true}
          modalBodyComponent={
            <div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    width: '45%',
                  }}
                >
                  <TitleBigDiv>위도</TitleBigDiv>
                  <TextInput
                    backgroundColor="#fff"
                    value={position.latitude}
                    disabled={true}
                  ></TextInput>
                </div>
                <div
                  style={{
                    width: '45%',
                  }}
                >
                  <TitleBigDiv>경도</TitleBigDiv>
                  <TextInput
                    backgroundColor="#fff"
                    value={position.longitude}
                    disabled={true}
                  ></TextInput>
                </div>
              </div>
              {isLoaded && (
                <div>
                  <TitleBigDiv>주소로 검색하기 *</TitleBigDiv>
                  <TextInput
                    backgroundColor="#fff"
                    value={address}
                    onChange={handleInputChange}
                  ></TextInput>
                  {suggestions.length !== 0 && (
                    <div
                      style={{
                        border: '1px solid #dcdfe6',
                        borderRadius: '8px',
                        boxSizing: 'border-box',
                        maxHeight: '200px',
                        overflowY: 'auto',
                        width: '100%',
                        backgroundColor: '#ffffff',
                        marginTop: 15,
                      }}
                    >
                      {suggestions.map((suggestion) => (
                        <div
                          key={suggestion.place_id}
                          style={{
                            padding: '8px 15px',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            handleSuggestionClick(
                              suggestion.description,
                              suggestion.place_id
                            )
                          }}
                        >
                          {suggestion.description}
                        </div>
                      ))}
                    </div>
                  )}
                  <TitleBigDiv>핀 조정하기</TitleBigDiv>
                  <GoogleMap
                    mapContainerStyle={{ width: '100%', height: '400px' }}
                    center={{
                      lat: position.latitude,
                      lng: position.longitude,
                    }}
                    zoom={zoom}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                  >
                    <MarkerF
                      position={{
                        lat: position.latitude,
                        lng: position.longitude,
                      }}
                      draggable={true}
                      icon={{
                        url: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
                        scaledSize: new window.google.maps.Size(40, 40),
                      }}
                      onDragEnd={onMarkerDragEnd}
                    />
                  </GoogleMap>
                </div>
              )}

              <TitleBigDiv>맵 핀 아이콘 *</TitleBigDiv>
              <DefaultMapPinIconSection>
                <DefaultMapPinIconSectionInner>
                  <DefaultMapPinIconSectionInnerBtn
                    onClick={() => {
                      setShowSelectMapPinIconPopup((pre) => !pre)
                    }}
                  >
                    아이콘 선택
                  </DefaultMapPinIconSectionInnerBtn>
                  <DefaultMapPinIconSectionInnerIcon>
                    {selectedMapPinIcon !== null && (
                      <img
                        src={selectedMapPinIcon}
                        alt=""
                        style={{
                          width: 40,
                          height: 40,
                          objectFit: 'contain',
                        }}
                      ></img>
                    )}
                  </DefaultMapPinIconSectionInnerIcon>
                </DefaultMapPinIconSectionInner>
                {showSelectMapPinIconPopup && (
                  <DefaultMapPinIconSectionPopup>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(activity1_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Activity1Png></Activity1Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(activity2_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Activity2Png></Activity2Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(activity3_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Activity3Png></Activity3Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(activity4_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Activity4Png></Activity4Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(activity5_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Activity5Png></Activity5Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(activity6_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Activity6Png></Activity6Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(activity7_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Activity7Png></Activity7Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(activity8_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Activity8Png></Activity8Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(activity9_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Activity9Png></Activity9Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(activity10_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Activity10Png></Activity10Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(bars_pub1_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <BarsPub1Png></BarsPub1Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(bars_pub2_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <BarsPub2Png></BarsPub2Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(bars_pub3_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <BarsPub3Png></BarsPub3Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(bars_pub4_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <BarsPub4Png></BarsPub4Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(bars_pub5_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <BarsPub5Png></BarsPub5Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(bars_pub6_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <BarsPub6Png></BarsPub6Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(bars_pub7_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <BarsPub7Png></BarsPub7Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(bars_pub8_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <BarsPub8Png></BarsPub8Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(bars_pub9_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <BarsPub9Png></BarsPub9Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(bars_pub10_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <BarsPub10Png></BarsPub10Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(cafe1_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Cafe1Png></Cafe1Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(cafe2_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Cafe2Png></Cafe2Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(cafe3_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Cafe3Png></Cafe3Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(cafe4_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Cafe4Png></Cafe4Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(cafe5_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Cafe5Png></Cafe5Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(cafe6_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Cafe6Png></Cafe6Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(cafe7_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Cafe7Png></Cafe7Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(cafe8_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Cafe8Png></Cafe8Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(cafe9_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Cafe9Png></Cafe9Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(cafe10_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Cafe10Png></Cafe10Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(gettingaround1_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <GettingAround1Png></GettingAround1Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(gettingaround2_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <GettingAround2Png></GettingAround2Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(gettingaround3_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <GettingAround3Png></GettingAround3Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(gettingaround4_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <GettingAround4Png></GettingAround4Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(gettingaround5_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <GettingAround5Png></GettingAround5Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(gettingaround6_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <GettingAround6Png></GettingAround6Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(gettingaround7_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <GettingAround7Png></GettingAround7Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(gettingaround8_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <GettingAround8Png></GettingAround8Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(gettingaround9_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <GettingAround9Png></GettingAround9Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(gettingaround10_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <GettingAround10Png></GettingAround10Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(home1_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Home1Png></Home1Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(home2_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Home2Png></Home2Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(home3_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Home3Png></Home3Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(home4_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Home4Png></Home4Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(home5_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Home5Png></Home5Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(home6_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Home6Png></Home6Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(home7_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Home7Png></Home7Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(home8_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Home8Png></Home8Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(home9_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Home9Png></Home9Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(home10_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Home10Png></Home10Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(medicalfacility1_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <MedicalFacility1Png></MedicalFacility1Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(medicalfacility2_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <MedicalFacility2Png></MedicalFacility2Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(medicalfacility3_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <MedicalFacility3Png></MedicalFacility3Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(medicalfacility4_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <MedicalFacility4Png></MedicalFacility4Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(medicalfacility5_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <MedicalFacility5Png></MedicalFacility5Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(medicalfacility6_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <MedicalFacility6Png></MedicalFacility6Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(medicalfacility7_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <MedicalFacility7Png></MedicalFacility7Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(medicalfacility8_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <MedicalFacility8Png></MedicalFacility8Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(medicalfacility9_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <MedicalFacility9Png></MedicalFacility9Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(medicalfacility10_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <MedicalFacility10Png></MedicalFacility10Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(restaurant1_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Restaurant1Png></Restaurant1Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(restaurant2_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Restaurant2Png></Restaurant2Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(restaurant3_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Restaurant3Png></Restaurant3Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(restaurant4_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Restaurant4Png></Restaurant4Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(restaurant5_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Restaurant5Png></Restaurant5Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(restaurant6_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Restaurant6Png></Restaurant6Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(restaurant7_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Restaurant7Png></Restaurant7Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(restaurant8_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Restaurant8Png></Restaurant8Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(restaurant9_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Restaurant9Png></Restaurant9Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(restaurant10_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Restaurant10Png></Restaurant10Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(shopping1_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Shopping1Png></Shopping1Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(shopping2_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Shopping2Png></Shopping2Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(shopping3_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Shopping3Png></Shopping3Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(shopping4_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Shopping4Png></Shopping4Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(shopping5_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Shopping5Png></Shopping5Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(shopping6_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Shopping6Png></Shopping6Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(shopping7_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Shopping7Png></Shopping7Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(shopping8_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Shopping8Png></Shopping8Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(shopping9_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Shopping9Png></Shopping9Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(shopping10_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Shopping10Png></Shopping10Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(supermarket1_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Supermarket1Png></Supermarket1Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(supermarket2_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Supermarket2Png></Supermarket2Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(supermarket3_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Supermarket3Png></Supermarket3Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(supermarket4_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Supermarket4Png></Supermarket4Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(supermarket5_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Supermarket5Png></Supermarket5Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(supermarket6_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Supermarket6Png></Supermarket6Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(supermarket7_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Supermarket7Png></Supermarket7Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(supermarket8_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Supermarket8Png></Supermarket8Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(supermarket9_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Supermarket9Png></Supermarket9Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(supermarket10_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Supermarket10Png></Supermarket10Png>
                    </DefaultMapPinIconSectionPopupIcon>
                  </DefaultMapPinIconSectionPopup>
                )}
              </DefaultMapPinIconSection>
            </div>
          }
          onRightBtnClick={() => {
            if (selectedMapPinIcon === null) {
              alert('맵 핀 아이콘을 선택해주세요')
            } else {
              if (address === '') {
                alert('주소를 입력해주세요')
              } else {
                setChoosePosition(true)
                setPosition({
                  latitude: position.latitude,
                  longitude: position.longitude,
                })
                setDefaultTopicPosition({
                  latitude: position.latitude,
                  longitude: position.longitude,
                })
                setAddLocationModalVisible(false)
              }
            }
          }}
          onClose={() => {
            setSelectedMapPinIcon(null)
            setShowSelectMapPinIconPopup(false)
            setAddLocationModalVisible(false)
          }}
          rightBtnText={'저장'}
          questionSection={<p>---</p>}
          questionDefaultWidth={500}
          questionChangeWidth={180}
        ></NormalModal>
      )}
      {editLocationModalVisible && (
        <NormalModal
          titleText={`위치 수정하기`}
          leftBtnVisible={true}
          modalBodyComponent={
            <div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    width: '45%',
                  }}
                >
                  <TitleBigDiv>위도</TitleBigDiv>
                  <TextInput
                    backgroundColor="#fff"
                    value={position.latitude}
                    disabled={true}
                  ></TextInput>
                </div>
                <div
                  style={{
                    width: '45%',
                  }}
                >
                  <TitleBigDiv>경도</TitleBigDiv>
                  <TextInput
                    backgroundColor="#fff"
                    value={position.longitude}
                    disabled={true}
                  ></TextInput>
                </div>
              </div>
              {isLoaded && (
                <div>
                  <TitleBigDiv>주소로 검색하기 *</TitleBigDiv>
                  <TextInput
                    backgroundColor="#fff"
                    value={address}
                    onChange={handleInputChange}
                  ></TextInput>
                  {suggestions.length !== 0 && (
                    <div
                      style={{
                        border: '1px solid #dcdfe6',
                        borderRadius: '8px',
                        boxSizing: 'border-box',
                        maxHeight: '200px',
                        overflowY: 'auto',
                        width: '100%',
                        backgroundColor: '#ffffff',
                        marginTop: 15,
                      }}
                    >
                      {suggestions.map((suggestion) => (
                        <div
                          key={suggestion.place_id}
                          style={{
                            padding: '8px 15px',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            handleSuggestionClick(
                              suggestion.description,
                              suggestion.place_id
                            )
                          }}
                        >
                          {suggestion.description}
                        </div>
                      ))}
                    </div>
                  )}
                  <TitleBigDiv>핀 조정하기</TitleBigDiv>
                  <GoogleMap
                    mapContainerStyle={{ width: '100%', height: '400px' }}
                    center={{ lat: position.latitude, lng: position.longitude }}
                    zoom={zoom} // 상태로 관리되는 줌 레벨 사용
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                  >
                    <MarkerF
                      position={{
                        lat: position.latitude,
                        lng: position.longitude,
                      }}
                      draggable={true}
                      icon={{
                        url: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
                        scaledSize: new window.google.maps.Size(40, 40),
                      }}
                      onDragEnd={onMarkerDragEnd}
                    />
                  </GoogleMap>
                </div>
              )}
              <TitleBigDiv>맵 핀 아이콘 *</TitleBigDiv>
              <DefaultMapPinIconSection>
                <DefaultMapPinIconSectionInner>
                  <DefaultMapPinIconSectionInnerBtn
                    onClick={() => {
                      setShowSelectMapPinIconPopup((pre) => !pre)
                    }}
                  >
                    아이콘 선택
                  </DefaultMapPinIconSectionInnerBtn>
                  <DefaultMapPinIconSectionInnerIcon>
                    {selectedMapPinIcon !== null && (
                      <img
                        src={selectedMapPinIcon}
                        alt=""
                        style={{
                          width: 40,
                          height: 40,
                          objectFit: 'contain',
                        }}
                      ></img>
                    )}
                  </DefaultMapPinIconSectionInnerIcon>
                </DefaultMapPinIconSectionInner>
                {showSelectMapPinIconPopup && (
                  <DefaultMapPinIconSectionPopup>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(activity1_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Activity1Png></Activity1Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(activity2_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Activity2Png></Activity2Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(activity3_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Activity3Png></Activity3Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(activity4_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Activity4Png></Activity4Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(activity5_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Activity5Png></Activity5Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(activity6_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Activity6Png></Activity6Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(activity7_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Activity7Png></Activity7Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(activity8_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Activity8Png></Activity8Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(activity9_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Activity9Png></Activity9Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(activity10_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Activity10Png></Activity10Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(bars_pub1_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <BarsPub1Png></BarsPub1Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(bars_pub2_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <BarsPub2Png></BarsPub2Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(bars_pub3_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <BarsPub3Png></BarsPub3Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(bars_pub4_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <BarsPub4Png></BarsPub4Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(bars_pub5_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <BarsPub5Png></BarsPub5Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(bars_pub6_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <BarsPub6Png></BarsPub6Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(bars_pub7_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <BarsPub7Png></BarsPub7Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(bars_pub8_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <BarsPub8Png></BarsPub8Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(bars_pub9_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <BarsPub9Png></BarsPub9Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(bars_pub10_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <BarsPub10Png></BarsPub10Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(cafe1_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Cafe1Png></Cafe1Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(cafe2_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Cafe2Png></Cafe2Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(cafe3_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Cafe3Png></Cafe3Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(cafe4_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Cafe4Png></Cafe4Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(cafe5_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Cafe5Png></Cafe5Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(cafe6_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Cafe6Png></Cafe6Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(cafe7_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Cafe7Png></Cafe7Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(cafe8_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Cafe8Png></Cafe8Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(cafe9_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Cafe9Png></Cafe9Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(cafe10_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Cafe10Png></Cafe10Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(gettingaround1_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <GettingAround1Png></GettingAround1Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(gettingaround2_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <GettingAround2Png></GettingAround2Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(gettingaround3_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <GettingAround3Png></GettingAround3Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(gettingaround4_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <GettingAround4Png></GettingAround4Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(gettingaround5_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <GettingAround5Png></GettingAround5Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(gettingaround6_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <GettingAround6Png></GettingAround6Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(gettingaround7_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <GettingAround7Png></GettingAround7Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(gettingaround8_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <GettingAround8Png></GettingAround8Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(gettingaround9_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <GettingAround9Png></GettingAround9Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(gettingaround10_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <GettingAround10Png></GettingAround10Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(home1_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Home1Png></Home1Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(home2_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Home2Png></Home2Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(home3_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Home3Png></Home3Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(home4_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Home4Png></Home4Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(home5_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Home5Png></Home5Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(home6_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Home6Png></Home6Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(home7_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Home7Png></Home7Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(home8_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Home8Png></Home8Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(home9_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Home9Png></Home9Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(home10_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Home10Png></Home10Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(medicalfacility1_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <MedicalFacility1Png></MedicalFacility1Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(medicalfacility2_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <MedicalFacility2Png></MedicalFacility2Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(medicalfacility3_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <MedicalFacility3Png></MedicalFacility3Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(medicalfacility4_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <MedicalFacility4Png></MedicalFacility4Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(medicalfacility5_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <MedicalFacility5Png></MedicalFacility5Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(medicalfacility6_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <MedicalFacility6Png></MedicalFacility6Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(medicalfacility7_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <MedicalFacility7Png></MedicalFacility7Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(medicalfacility8_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <MedicalFacility8Png></MedicalFacility8Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(medicalfacility9_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <MedicalFacility9Png></MedicalFacility9Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(medicalfacility10_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <MedicalFacility10Png></MedicalFacility10Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(restaurant1_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Restaurant1Png></Restaurant1Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(restaurant2_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Restaurant2Png></Restaurant2Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(restaurant3_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Restaurant3Png></Restaurant3Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(restaurant4_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Restaurant4Png></Restaurant4Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(restaurant5_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Restaurant5Png></Restaurant5Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(restaurant6_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Restaurant6Png></Restaurant6Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(restaurant7_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Restaurant7Png></Restaurant7Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(restaurant8_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Restaurant8Png></Restaurant8Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(restaurant9_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Restaurant9Png></Restaurant9Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(restaurant10_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Restaurant10Png></Restaurant10Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(shopping1_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Shopping1Png></Shopping1Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(shopping2_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Shopping2Png></Shopping2Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(shopping3_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Shopping3Png></Shopping3Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(shopping4_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Shopping4Png></Shopping4Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(shopping5_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Shopping5Png></Shopping5Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(shopping6_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Shopping6Png></Shopping6Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(shopping7_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Shopping7Png></Shopping7Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(shopping8_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Shopping8Png></Shopping8Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(shopping9_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Shopping9Png></Shopping9Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(shopping10_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Shopping10Png></Shopping10Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(supermarket1_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Supermarket1Png></Supermarket1Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(supermarket2_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Supermarket2Png></Supermarket2Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(supermarket3_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Supermarket3Png></Supermarket3Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(supermarket4_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Supermarket4Png></Supermarket4Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(supermarket5_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Supermarket5Png></Supermarket5Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(supermarket6_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Supermarket6Png></Supermarket6Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(supermarket7_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Supermarket7Png></Supermarket7Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(supermarket8_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Supermarket8Png></Supermarket8Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(supermarket9_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Supermarket9Png></Supermarket9Png>
                    </DefaultMapPinIconSectionPopupIcon>
                    <DefaultMapPinIconSectionPopupIcon
                      onClick={() => {
                        setSelectedMapPinIcon(supermarket10_image_s3)
                        setShowSelectMapPinIconPopup(false)
                      }}
                    >
                      <Supermarket10Png></Supermarket10Png>
                    </DefaultMapPinIconSectionPopupIcon>
                  </DefaultMapPinIconSectionPopup>
                )}
              </DefaultMapPinIconSection>
            </div>
          }
          onRightBtnClick={() => {
            if (selectedMapPinIcon === null) {
              alert('맵 핀 아이콘을 선택해주세요')
            } else {
              if (address === '') {
                alert('주소를 입력해주세요')
              } else {
                console.log('position', position)
                setChoosePosition(true)
                setPosition({
                  latitude: position.latitude,
                  longitude: position.longitude,
                })
                setDefaultTopicPosition({
                  latitude: position.latitude,
                  longitude: position.longitude,
                })
                setEditLocationModalVisible(false)
              }
            }
          }}
          onClose={() => {
            setSelectedMapPinIcon(null)
            setShowSelectMapPinIconPopup(false)
            setEditLocationModalVisible(false)
          }}
          rightBtnText={'저장'}
          questionSection={<p>---</p>}
          questionDefaultWidth={500}
          questionChangeWidth={180}
        ></NormalModal>
      )}
    </>
  )
}

export default MainGuidesDefaultGuidePage

const DefaultGuideHeader = styled.div`
  padding-left: 10%;
  padding-right: 10%;
  background-color: #fff;
  height: 100px;
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  align-items: center;
  justify-content: space-between;
  display: flex;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    padding-left: 15px;
    padding-right: 15px;
    height: 70px;
  }
`

const DefaultGuideHeaderTitle = styled.div`
  font-size: 2.25rem;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    font-size: 1.5rem;
    line-height: 3.75rem;
  }
`

const DefaultGuideContentBody1 = styled.div`
  height: 60px;
  background-color: #f8f8f8;
  padding: 10px 10%;
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  border-bottom: 1px solid #e1e1e1;
  align-items: center;
  justify-content: space-between;
  display: flex;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    background-color: inherit;
    padding: 20px;
    border-bottom: none;
  }
`

const DefaultMapPinIconSection = styled.div`
  margin-bottom: 20px;
  position: relative;
  box-sizing: border-box;
`

const DefaultMapPinIconSectionInner = styled.div`
  align-items: center;
  display: flex;
  box-sizing: border-box;
`

const DefaultMapPinIconSectionInnerBtn = styled.button`
  padding: 8px 16px;
  border-radius: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #000;
  background-color: #ededed;
  border-color: #ededed;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  border: 1px solid #dcdfe6;

  @media (max-width: 1024px) {
    padding: 8px;
    font-size: 10px;
  }
`

const DefaultMapPinIconSectionInnerIcon = styled.div`
  margin-left: 15px;
  font-size: 1.5rem;
  box-sizing: border-box;
  color: #000;
`

const DefaultMapPinIconSectionPopup = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
  background-color: #fff;
  z-index: 10001;
  box-shadow: 0 1px 9px #00000040;
  width: 100%;
  max-height: 300px;
  overflow: auto;
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`

const DefaultMapPinIconSectionPopupIcon = styled.div`
  cursor: pointer;
  padding: 5px;
  transition: background-color 0.1s ease-in-out;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  box-sizing: border-box;
  word-break: break-all;
`
