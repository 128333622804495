export const c_fcf0d6 = '#fcf0d6'
export const c_908d88 = '#908d88'
export const c_82e4ea = '#82e4ea'
export const c_000000 = '#000000'
export const c_6aafe6 = '#6aafe6'
export const c_6e6e6e = '#6e6e6e'
export const c_d4dfe6 = '#d4dfe6'
export const c_ffffff = '#ffffff'
export const rgba_0_0_0_0d2 = 'rgba(0, 0, 0, 0.2)'

export const c_112b38 = '#112b38'
export const c_3ed9cc = '#3ed9cc'
export const c_244556 = '#244556'
export const rgba_62_217_204_0d1 = 'rgba(62, 217, 204, 0.1)'

export const c_ddc2ab = '#ddc2ab'
export const c_b49275 = '#b49275'
export const c_ffcc96 = '#ffcc96'
export const c_eba55d = '#eba55d'
export const c_c5c4c3 = '#c5c4c3'
export const c_353431 = '#353431'
