import styled from 'styled-components'
import { Container } from '../../../design_system/components/container/Container'
import { OpacityBtn } from '../../../design_system/components/btn/pc/opacity_btn/OpacityBtn'
import {
  c_244556,
  c_3ed9cc,
  c_b49275,
  c_ffffff,
} from '../../../design_system/colors'

const Page0 = ({ setPageNumber }) => {
  return (
    <Container>
      <div>
        <Page0Body>
          <Page0InsideBody>
            <Page0TitleH1>
              Welcome!<br></br>이제 우리 숙소의 안내문을 만들 수 있습니다.
            </Page0TitleH1>
            <Page0ContentP>
              쉽고 간편하게 만들 수 있어요. 시작해 볼까요?
            </Page0ContentP>
            <div
              style={{
                paddingTop: 30,
                boxSizing: 'border-box',
              }}
            >
              <OpacityBtn
                backgroundColor={c_b49275}
                borderColor={c_b49275}
                fontColor={c_ffffff}
                onClick={() => {
                  setPageNumber(2)
                }}
              >
                시작하기
              </OpacityBtn>
            </div>
          </Page0InsideBody>
        </Page0Body>
      </div>
    </Container>
  )
}

export default Page0

const Page0Body = styled.div`
  width: 100%;
  float: left;
  box-sizing: border-box;
`

const Page0InsideBody = styled.div`
  width: 100%;
  height: 60vh;
  box-sizing: border-box;

  @media (max-width: 1600px) {
    width: 50%;
  }

  @media (max-width: 1024px) {
    width: 70%;
  }

  @media (max-width: 768px) {
    width: 90%;
  }

  @media (max-width: 650px) {
    width: 100%;
    padding-top: 25px;
  }
`

const Page0TitleH1 = styled.h1`
  width: auto;
  line-height: 3.375rem;
  font-size: 3rem;
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  letter-spacing: 1px;
  color: #eba55d;
  margin: 10px 0;
  box-sizing: border-box;

  @media (max-width: 1600px) {
    font-size: 2.25rem;
    line-height: 2.25rem;
  }

  @media (max-width: 1024px) {
    width: auto;
    line-height: 2.375rem;
    padding: 10px 50px 20px 0;
  }

  @media (max-width: 768px) {
    padding: 0;
  }

  @media (max-width: 650px) {
    font-size: 2rem;
    line-height: 2.25rem;
    width: 100%;
    max-width: 375px;
  }
`

const Page0ContentP = styled.p`
  margin: 30px 0 20px;
  line-height: 1.5;
  font-size: 1rem;
  color: ${c_244556};
  font-family: Circular Std Book, Tahoma, SansSerif;
  box-sizing: border-box;
`
