import styled from 'styled-components';

export const OpacityBtn = ({
  backgroundColor,
  fontColor,
  onClick,
  children,
  disabled,
}) => {
  return (
    <Btn
      $backgroundColor={backgroundColor}
      $fontColor={fontColor}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Btn>
  );
};

const Btn = styled.button`
  border-radius: 8px;
  border: none;
  color: ${(props) => props.$fontColor};
  background-color: ${(props) => props.$backgroundColor};
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  height: 45px;
  transition: opacity 0.3s ease-in-out;
  font-size: 1rem;
  box-sizing: border-box;
  padding: 12px 20px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  text-decoration: none;

  @media (max-width: 650px) {
    font-size: 0.875rem;
  }

  &:hover {
    opacity: 0.7;
  }

  &:disabled {
    color: #c0c4cc !important;
    background-color: #f6f6f6 !important;
    border-color: #f6f6f6 !important;
    cursor: not-allowed;
    background-image: none;
  }
`;
