import styled from 'styled-components';

export const Body = styled.div`
  max-width: 35%;
  height: 60vh;
  box-sizing: border-box;

  @media (max-width: 1600px) {
    max-width: 50%;
  }

  @media (max-width: 1024px) {
    max-width: 70%;
  }

  @media (max-width: 768px) {
    max-width: 90%;
  }

  @media (max-width: 650px) {
    max-width: 100%;
    padding-top: 25px;
  }
`;
