import styled from 'styled-components'

export const MainGuidesContainer = styled.div`
  background-color: #f3f3f3;
  flex: 1 0 auto;
  flex-direction: column;
  display: flex;
  box-sizing: border-box;
  min-width: 0;
  overflow-y: auto;
`
