import styled from 'styled-components'
import {
  c_244556,
  c_353431,
  c_3ed9cc,
  c_b49275,
  c_ffcc96,
  c_ffffff,
  rgba_62_217_204_0d1,
} from '../../design_system/colors'
import { useEffect, useState } from 'react'
import { Footer } from '../../design_system/components/footer/Footer'
import { PasswordInput } from '../../design_system/components/password_input/PasswordInput'
import { TextInput } from '../../design_system/components/text_input/TextInput'
import { ChangeBtnA } from '../../design_system/components/btn/pc/change_btn_a/ChangeBtnA'
import PcSubHeader from '../../design_system/components/header/pc_sub_header/PcSubHeader'
import { TitleH1 } from '../../design_system/components/title_h1/TitleH1'
import { Section } from '../../design_system/components/section/Section'
import { Container } from '../../design_system/components/container/Container'
import { Body } from '../../design_system/components/body/Body'
import { TextDiv } from '../../design_system/components/text_div/TextDiv'
import { OpacityBtnA } from '../../design_system/components/btn/pc/opacity_btn_a/OpacityBtnA'
import {
  forgotten_password_inner_router_url,
  main_guides_inner_router_url,
  s_guides_inner_router_url,
  sign_up_inner_router_url,
} from '../../communication_system/inner_router_url/inner_router_url'
import { OpacityBtn } from '../../design_system/components/btn/pc/opacity_btn/OpacityBtn'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../data_system/context/auth_context/AuthContext'
import { emailCheck } from '../../data_system/data_function/email_check_function'
import { useSelectHeader } from '../../data_system/context/select_header_index_context/SelectHeaderIndexContext'
import { Logo2x } from '../../design_system/icons/icons'
import { usePopup } from '../../data_system/context/popup_context/PopupContext'

const LoginPage = () => {
  const { show } = usePopup()

  const [email, setEmail] = useState('')
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [password, setPassword] = useState('')

  let navigate = useNavigate()
  const { handleLogin, logout, completeInitialSetup } = useAuth()

  const { setHeaderIndex } = useSelectHeader()

  const totalHandleLogin = async (email, password) => {
    const response = await handleLogin(email, password)
    console.log(response)
    if (parseInt(response.status) === 200) {
      setHeaderIndex(0)
      navigate(main_guides_inner_router_url + s_guides_inner_router_url)
    }

    if (parseInt(response.status) === 400) {
      show('아이디와 비밀번호를 확인해주세요.', false)
    }

    if (parseInt(response.status) === 401) {
      show('접속이 불가능한 계정입니다. 고객 센터로 문의 부탁드립니다.', false)
    }
  }

  useEffect(() => {
    logout()
  }, [])

  return (
    <>
      <PcSubHeader>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => {
            const baseUrl = window.location.origin
            const url = `${baseUrl}`
            window.open(url, '_self')
          }}
        >
          <Logo2x></Logo2x>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ChangeBtnA
            href={sign_up_inner_router_url}
            beforeBackgroudColor={c_ffffff}
            beforeBorderColor={c_b49275}
            beforeFontColor={c_353431}
            afterBackgroudColor={c_ffcc96}
            afterBorderColor={c_b49275}
            afterFontColor={c_353431}
          >
            무료로 시작하기
          </ChangeBtnA>
        </div>
      </PcSubHeader>
      <Section overflow={'hidden'}>
        <Container>
          <div>
            <Body>
              <TitleH1>로그인</TitleH1>
              <TextDiv>이메일*</TextDiv>
              <TextInput
                type="text"
                autoComplete="off"
                placeholder=""
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
              ></TextInput>
              <div
                style={{
                  marginTop: 15,
                }}
              >
                <TextDiv>비밀번호*</TextDiv>
              </div>
              <PasswordInput
                password={password}
                setPassword={setPassword}
                passwordVisible={passwordVisible}
                setPasswordVisible={setPasswordVisible}
              ></PasswordInput>
              <div
                style={{
                  paddingTop: 30,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <OpacityBtn
                  fontColor={c_ffffff}
                  backgroundColor={c_b49275}
                  onClick={() => {
                    if (
                      emailCheck.test(email) &&
                      email !== '' &&
                      password !== ''
                    ) {
                      totalHandleLogin(email, password)
                    }
                  }}
                >
                  로그인
                </OpacityBtn>
                <OpacityBtnA
                  href={forgotten_password_inner_router_url}
                  fontColor={c_353431}
                  backgroundColor={c_ffcc96}
                >
                  비밀번호 찾기
                </OpacityBtnA>
              </div>
              <div
                style={{
                  paddingTop: 30,
                }}
              >
                <MarginChange>
                  <OpacityBtnA
                    href={sign_up_inner_router_url}
                    fontColor={c_ffffff}
                    backgroundColor={c_b49275}
                    width={'100%'}
                  >
                    회원이 아니라면? 가입하고 무료로 이용해 보세요.
                  </OpacityBtnA>
                </MarginChange>
              </div>
            </Body>
          </div>
        </Container>
        <Footer></Footer>
      </Section>
    </>
  )
}

export default LoginPage

const MarginChange = styled.div`
  @media (max-width: 650px) {
    margin-top: 120px;
  }
`
