import { createContext, useContext, useState } from 'react';

// Context 이름 변경
const SelectGuideDetailHeaderContext = createContext();

// Hook 이름 변경
export const useSelectGuideDetailHeader = () => {
  return useContext(SelectGuideDetailHeaderContext);
};

export function SelectGuideDetailHeaderProvider({ children }) {
  const [selectGuideDetailHeaderIndex, setSelectGuideDetailHeaderIndex] =
    useState(
      parseInt(window.localStorage.getItem('selectGuideDetailHeaderIndex')) ||
        0,
    );

  // 함수명 변경
  const setGuideDetailHeaderIndex = (index) => {
    window.localStorage.setItem('selectGuideDetailHeaderIndex', index);
    setSelectGuideDetailHeaderIndex(index);
  };

  const value = {
    selectGuideDetailHeaderIndex,
    setGuideDetailHeaderIndex,
  };

  return (
    <SelectGuideDetailHeaderContext.Provider value={value}>
      {children}
    </SelectGuideDetailHeaderContext.Provider>
  );
}
