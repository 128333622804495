import styled from 'styled-components';
import { c_244556 } from '../../colors';

export const ContentText = styled.div`
  font-size: 1.125rem;
  line-height: 1.5rem;
  opacity: 0.6;
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  color: ${c_244556};
  font-size: 16px;
  font-weight: 500;

  @media (max-width: 650px) {
    font-size: 0.75rem;
    line-height: 1.5rem;
  }
`;
