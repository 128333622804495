import styled from 'styled-components'
import { rgba_62_217_204_0d1 } from '../../colors'

export const SettingsItem = styled.div`
  padding: 10px 20px;
  white-space: nowrap;
  width: auto;

  &:hover {
    background-color: ${rgba_62_217_204_0d1};
  }
`
