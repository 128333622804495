import { createGlobalStyle } from "styled-components";
import NotoSansKRBold from "./NotoSansKR_Bold.otf";
import NotoSansKRMedium from "./NotoSansKR_Medium.otf";
import NotoSansKRRegular from "./NotoSansKR_Regular.otf";
import NotoSansKRBlack from "./NotoSansKR_Black.otf";
import AppleSDGothicNeoR from "./AppleSDGothicNeo_R.ttf";
import AppleSDGothicNeoM from "./AppleSDGothicNeo_M.ttf";
import AppleSDGothicNeoB from "./AppleSDGothicNeo_B.ttf";
import InterRegular from "./Inter_Regular.ttf";
import InterMedium from "./Inter_Medium.ttf";
import InterBold from "./Inter_Bold.ttf";
import RobotoBold from "./Roboto_Bold.ttf";
import RobotoMedium from "./Roboto_Medium.ttf";
import RobotoRegular from "./Roboto_Regular.ttf";
import NanumSquareRoundOTFEB from "./NanumSquareRoundOTFEB.otf";
import FoundersGroteskRegular from "./FoundersGrotesk-Regular.otf";
import FoundersGroteskMedium from "./FoundersGrotesk-Medium.otf";
import FoundersGroteskBold from "./FoundersGrotesk-Bold.otf";
import ManropeBold from "./Manrope-Bold.ttf";
import ManropeMedium from "./Manrope-Medium.ttf";
import ManropeRegular from "./Manrope-Regular.ttf";
import ManropeLight from "./Manrope-Light.ttf";
import PlusJakartaSansBold from "./PlusJakartaSans-Bold.ttf";
import PlusJakartaSansMedium from "./PlusJakartaSans-Medium.ttf";
import PlusJakartaSansRegular from "./PlusJakartaSans-Regular.ttf";
import PlusJakartaSansLight from "./PlusJakartaSans-Light.ttf";

export const noto_sans_kr_bold = "noto_sans_kr_bold";
export const noto_sans_kr_medium = "noto_sans_kr_medium";
export const noto_sans_kr_regular = "noto_sans_kr_regular";
export const noto_sans_kr_black = "noto_sans_kr_black";
export const apple_sd_gothic_neo_bold = "apple_sd_gothic_neo_bold";
export const apple_sd_gothic_neo_medium = "apple_sd_gothic_neo_medium";
export const apple_sd_gothic_neo_regular = "apple_sd_gothic_neo_regular";
export const inter_bold = "inter_bold";
export const inter_medium = "inter_medium";
export const inter_regular = "inter_regular";
export const roboto_bold = "roboto_bold";
export const roboto_medium = "roboto_medium";
export const roboto_regular = "roboto_regular";
export const nanum_square_round_otf_eb = "nanum_square_round_otf_eb";
export const founders_grotesk_bold = "founders_grotesk_bold";
export const founders_grotesk_medium = "founders_grotesk_medium";
export const founders_grotesk_regular = "founders_grotesk_regular";
export const manrope_bold = "manrope_bold";
export const manrope_medium = "manrope_medium";
export const manrope_regular = "manrope_regular";
export const manrope_light = "manrope_light";
export const plus_jakarta_sans_bold = "plus_jakarta_sans_bold";
export const plus_jakarta_sans_medium = "plus_jakarta_sans_medium";
export const plus_jakarta_sans_regular = "plus_jakarta_sans_regular";
export const plus_jakarta_sans_light = "plus_jakarta_sans_light";

const GlobalStyleFonts = createGlobalStyle`
  @font-face {
    font-family: '${noto_sans_kr_bold}';
    font-style: normal;
    src: url(${NotoSansKRBold}) format('opentype');
  }

  @font-face {
    font-family: '${noto_sans_kr_medium}';
    font-style: normal;
    src: url(${NotoSansKRMedium}) format('opentype');
  }

  @font-face {
    font-family: '${noto_sans_kr_regular}';
    font-style: normal;
    src: url(${NotoSansKRRegular}) format('opentype');
  }

  @font-face {
    font-family: '${noto_sans_kr_black}';
    font-style: normal;
    src: url(${NotoSansKRBlack}) format('opentype');
  }

  @font-face {
    font-family: '${apple_sd_gothic_neo_regular}';
    font-style: normal;
    src: url(${AppleSDGothicNeoR}) format('truetype');
  }

  @font-face {
    font-family: '${apple_sd_gothic_neo_medium}';
    font-style: normal;
    src: url(${AppleSDGothicNeoM}) format('truetype');
  }

  @font-face {
    font-family: '${apple_sd_gothic_neo_bold}';
    font-style: normal;
    src: url(${AppleSDGothicNeoB}) format('truetype');
  }

  @font-face {
    font-family: '${inter_regular}';
    font-style: normal;
    src: url(${InterRegular}) format('truetype');
  }

  @font-face {
    font-family: '${inter_medium}';
    font-style: normal;
    src: url(${InterMedium}) format('truetype');
  }

  @font-face {
    font-family: '${inter_bold}';
    font-style: normal;
    src: url(${InterBold}) format('truetype');
  }

  @font-face {
    font-family: '${roboto_bold}';
    font-style: normal;
    src: url(${RobotoBold}) format('truetype');
  }

  @font-face {
    font-family: '${roboto_medium}';
    font-style: normal;
    src: url(${RobotoMedium}) format('truetype');
  }

  @font-face {
    font-family: '${roboto_regular}';
    font-style: normal;
    src: url(${RobotoRegular}) format('truetype');
  }

  @font-face {
    font-family: '${nanum_square_round_otf_eb}';
    font-style: normal;
    src: url(${NanumSquareRoundOTFEB}) format('opentype');
  }

  @font-face {
    font-family: '${founders_grotesk_regular}';
    font-style: normal;
    src: url(${FoundersGroteskRegular}) format('opentype');
  }

  @font-face {
    font-family: '${founders_grotesk_medium}';
    font-style: normal;
    src: url(${FoundersGroteskMedium}) format('opentype');
  }

  @font-face {
    font-family: '${founders_grotesk_bold}';
    font-style: normal;
    src: url(${FoundersGroteskBold}) format('opentype');
  }

  @font-face {
    font-family: '${manrope_bold}';
    font-style: normal;
    src: url(${ManropeBold}) format('truetype');
  }

  @font-face {
    font-family: '${manrope_medium}';
    font-style: normal;
    src: url(${ManropeMedium}) format('truetype');
  }

  @font-face {
    font-family: '${manrope_regular}';
    font-style: normal;
    src: url(${ManropeRegular}) format('truetype');
  }

  @font-face {
    font-family: '${manrope_light}';
    font-style: normal;
    src: url(${ManropeLight}) format('truetype');
  }

  @font-face {
    font-family: '${plus_jakarta_sans_bold}';
    font-style: normal;
    src: url(${PlusJakartaSansBold}) format('truetype');
  }

  @font-face {
    font-family: '${plus_jakarta_sans_medium}';
    font-style: normal;
    src: url(${PlusJakartaSansMedium}) format('truetype');
  }

  @font-face {
    font-family: '${plus_jakarta_sans_regular}';
    font-style: normal;
    src: url(${PlusJakartaSansRegular}) format('truetype');
  }

  @font-face {
    font-family: '${plus_jakarta_sans_light}';
    font-style: normal;
    src: url(${PlusJakartaSansLight}) format('truetype');
  }
`;

export default GlobalStyleFonts;
