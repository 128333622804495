export function isValidAccountNumber(bankTypeId, bankAccountNumber) {
  if (
    bankTypeId === undefined ||
    bankTypeId === null ||
    bankAccountNumber === undefined ||
    bankAccountNumber === null ||
    bankTypeId < 1 ||
    bankTypeId > 20
  ) {
    return false
  }

  const accountNumberGroups = [
    [3, 3, 6],
    [4, 3, 6],
    [4, 2, 7],
    [3, 3, 6],
    [4, 4, 4],
    [3, 4, 4, 2],
    [3, 4, 4, 2],
    [3, 2, 6, 1],
    [4, 4, 4],
    [4, 4, 4, 1],
    [6, 2, 6],
    [3, 2, 6],
    [3, 6, 5],
    [3, 4, 4, 3],
    [3, 5, 3, 2],
    [3, 3, 6],
    [3, 2, 7],
    [2, 2, 6],
    [3, 6, 2, 3],
    [6, 2, 6],
  ]

  const groups = accountNumberGroups[bankTypeId - 1]

  // 계좌 번호 숫자 개수 체크
  const expectedLength = groups.reduce((sum, length) => sum + length, 0)
  return bankAccountNumber.length === expectedLength
}
