import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { ArrowDownBlackIcon, ArrowUpBlackIcon } from '../../icons/icons'

export const Dropdown = ({
  backgroundColor = '#f2f2f2',
  options = [
    { id: 0, title: 'Phone' },
    { id: 1, title: 'SMS' },
  ],
  selectIndex,
  setSelectIndex,
}) => {
  const [visible, setVisible] = useState(false)
  const [titleText, setTitleText] = useState(options[selectIndex].title)

  const dropdownRef = useRef()

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        visible &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setVisible(false)
      }
    }

    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [visible])

  const handleDropdownClick = (event) => {
    event.stopPropagation()
    setVisible((prev) => !prev)
  }

  return (
    <div
      ref={dropdownRef}
      style={{
        position: 'relative',
      }}
      onClick={handleDropdownClick}
    >
      <DropdownFrame $backgroundColor={backgroundColor} $visible={visible}>
        {titleText}
        {visible ? (
          <ArrowUpBlackIcon></ArrowUpBlackIcon>
        ) : (
          <ArrowDownBlackIcon></ArrowDownBlackIcon>
        )}
      </DropdownFrame>
      {visible && (
        <DropdownItemFrame $numberOfItems={options.length}>
          {options.map((option) => (
            <DropdownItem
              key={option.id}
              $select={selectIndex === option.id}
              onClick={(event) => {
                event.stopPropagation()
                setSelectIndex(option.id)
                setTitleText(options[option.id].title)
                setVisible(false)
              }}
            >
              {option.title}
            </DropdownItem>
          ))}
        </DropdownItemFrame>
      )}
    </div>
  )
}

const DropdownFrame = styled.div`
  height: 45px;
  border-radius: 8px;
  color: #244556;
  background-color: ${(props) => props.$backgroundColor};
  font-size: 1rem;
  line-height: normal;
  background-image: none;
  border: 1px solid ${(props) => (props.$visible ? '#3ed9cc' : '#dcdfe6')};
  box-sizing: border-box;
  display: inline-block;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 650px) {
    height: 40px;
  }

  &:hover {
    border-color: ${(props) =>
      props.$visible ? '#3ed9cc' : 'rgba(0, 0, 0, 0.2)'};
    outline: 0;
  }
`

const DropdownItemFrame = styled.div`
  position: absolute;
  padding: 6px 0;
  margin: 0;
  font-size: 16px;
  line-height: 1.15;
  box-sizing: border-box;
  background: #fff;
  overflow-y: scroll;
  height: ${(props) => props.$numberOfItems * 34 + 12}px;
  width: 100%;
  max-height: 274px;
  box-shadow: 0 2px 12px #0000001a;
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  z-index: 99;
`

const DropdownItem = styled.div`
  color: ${(props) => (props.$select ? '#3ed9cc' : '#244556')};
  font-weight: 700;
  font-family: Circular Std Book, Tahoma, SansSerif;
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 34px;
  line-height: 34px;
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    background-color: #f5f7fa;
  }
`
