import PcMainHeader from '../../design_system/components/header/pc_main_header/PcMainHeader'
import { ChangeBtnA } from '../../design_system/components/btn/pc/change_btn_a/ChangeBtnA'
import {
  c_112b38,
  c_353431,
  c_b49275,
  c_eba55d,
  c_ffffff,
} from '../../design_system/colors'
import {
  login_inner_router_url,
  sign_up_inner_router_url,
} from '../../communication_system/inner_router_url/inner_router_url'
import {
  noto_sans_kr_bold,
  noto_sans_kr_medium,
  noto_sans_kr_regular,
} from '../../design_system/fonts/fonts'
import styled from 'styled-components'
import { Logo2x } from '../../design_system/icons/icons'

const StoreTermsAndConditionsPage = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        overflowX: 'hidden',
        overflowY: 'auto',
      }}
    >
      <PcMainHeader>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => {
            const baseUrl = window.location.origin
            const url = `${baseUrl}`
            window.open(url, '_self')
          }}
        >
          <Logo2x></Logo2x>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ChangeBtnA
            href={login_inner_router_url}
            beforeBackgroudColor={c_ffffff}
            beforeBorderColor={c_eba55d}
            beforeFontColor={c_353431}
            afterBackgroudColor={c_b49275}
            afterBorderColor={c_b49275}
            afterFontColor={c_ffffff}
          >
            로그인
          </ChangeBtnA>
          <div
            style={{
              marginLeft: 15,
            }}
          >
            <ChangeBtnA
              href={sign_up_inner_router_url}
              beforeBackgroudColor={c_b49275}
              beforeBorderColor={c_b49275}
              beforeFontColor={c_ffffff}
              afterBackgroudColor={c_eba55d}
              afterBorderColor={c_eba55d}
              afterFontColor={c_353431}
            >
              무료 시작
            </ChangeBtnA>
          </div>
        </div>
      </PcMainHeader>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <ContentBody>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_bold,
              color: c_112b38,
              marginTop: 50,
            }}
          >
            TERMS & CONDITIONS
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            Article 1 (Purpose)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            This Agreement aims at stipulating rights, obligations and
            responsibilities of the Cyber Mall (hereinafter referred to as the
            “DOOGOM”) and the User in respect of the use of internet-related
            services provided by the Mall (hereinafter referred to as the
            “Service”) in the operation of Mall. (e-commerce business). 「In
            addition, this Agreement shall be applied to the e-commerce using PC
            communication, mobile, et cetera, unless it is not against the its
            nature.」
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            Article 2 (Definition)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ① The term "mall" means an online intermediary platform established
            by a member of Dodom using information and communication facilities
            such as computers to provide goods or services (hereinafter referred
            to as "goods, etc.") to users of "mall" and shall also be used as a
            meaning of a business operator operating a cyber mall.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ② “User” means a member or a non-member who uses Service provided by
            the “DOOGOM” in accordance with this Agreement through the access to
            the “DOOGOM”.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ③ “Member” means a person who subscribed the membership by providing
            his or her personal information to the “DOOGOM”, for which the
            member may be informed of the “DOOGOM” on a continuous basis and use
            the Service offered by the “DOOGOM”.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ④ “Non-Member” means a person who use the Service offered by the
            “DOOGOM” not subscribing to the membership.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            Article 3 (Display, Explanation & Modification of User Agreement)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ① The “Mall” shall, for easier recognition by Users, display the
            contents of this Agreement, name of company and president, business
            address (including the address where customer complaints may be
            treated), telephone number, fax number, email address, business
            registration number, mail-order business registration number, staff
            in charge of privacy management, et cetera on the initial service
            page of the cyber mall. Notwithstanding the foregoing, the contents
            of this Agreement may be exposed to Users through a link page.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ② The “Mall” shall obtain the confirmation of a User for important
            matters such as cancellation of purchase, delivery obligation,
            refund terms, et cetera through a separate link page or pop-up
            window before the User agrees on this Agreement so that the User may
            understand such important matters.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ③ The “Mall” may modify this Agreement within the extent that it
            does not violate applicable laws, e.g. the Law related to Protection
            of Consumer on E-commerce, Et Cetera, the Law related to Limitation
            of Agreement, Basic Law of Electronic Transaction, Electronic
            Signature Law, the Law related to Stimulation of Use of Information
            and Communication Network & Information Protection, the Law related
            to Call Sales, Et Cetera, the Consumer Protection Law, et cetera.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ④ In the event of any modification of this Agreement, the
            announcement stipulating the date of application and the cause of
            modification shall be displayed on the initial page from 7 days
            prior to the date of application to the previous day of the same
            date. Notwithstanding the foregoing, in the event that such
            modification becomes disadvantageous Users, such modification shall
            be announced at least 30 days prior to the date of application. The
            announcement shall include a table specifying the modification on a
            before-and-after basis for easier understanding of Users.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ⑤ In the event of any modification of this Agreement, the modified
            Agreement shall be applied only to the contracts to be concluded
            after the date of application, whereas the provisions of this
            Agreement prior to the modification shall be applied to the
            contracts which have been previously concluded prior to the same
            date. Notwithstanding the foregoing, in the event that a User who
            already entered into the Contract sends his or her intent to be
            applied by the provisions of the modified Agreement to the “Mall”
            within the period of announcement for the modification of this
            Agreement in accordance with Clause ③ and acquires the approval of
            the “Mall”, the provisions of the modified Agreement shall be
            applied accordingly.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ⑥ The matters not stipulated herein and the interpretation of this
            Agreement shall be in accordance with the Law related to Protection
            of Consumer on E-commerce, Et Cetera, the Law related to Limitation
            of Agreement, the Consumer Protection Policy on E-commerce, Et
            Cetera stipulated by the Fair Trade Commission, and other applicable
            laws and commercial practices.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            Article 4 (Provision & Replacement of Service)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ① The “Mall” shall perform the following duties:
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            1. The platform provision of information regarding product or
            service and conclusion of purchase contracts
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            2. Providing the online platform for the delivery of product or
            service on purchase contract.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            3. Other duties designated by the “Mall”
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ② The “Mall” may replace product or service provided by the contract
            to be concluded in the event that product or service is sold out or
            technical specifications are changed. In this case, the “Mall” shall
            immediately announce the replacement of the product or service and
            the date of application on the page where the present product or
            service is displayed.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ③ In the event that product or service on the contract with a User
            needs to be replaced because the product or service is sold out or
            technical specifications are changed, The “Mall” shall immediately
            notify the cause to the address of the User.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ④ In the event of the occurrence of the previous clause, the “Mall”
            shall compensate the User for damages. Notwithstanding the
            foregoing, this shall not apply if the “Mall” proves that such event
            is not caused by its intention or negligence.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            Article 5 (Suspension of Service)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ① The “Mall” may temporarily suspend the provision of Service in the
            event of any repair, inspection, replacement, breakdown of
            information and communication equipment such as computers, or
            interruption of communication.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ② The “Mall” shall compensate User or 3rd party for damages caused
            by the temporary suspension of provision of Service due to the
            cause(s) in Clause ①. Notwithstanding the foregoing, this shall not
            apply if the “Mall” proves that such event is not caused by its
            intention or negligence.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ③ In the event of any unavailability of provision of Service due to
            the conversion of business item, abandon of business, integration
            between businesses, et cetera, the “Mall” shall notify the fact to
            Users in the manner stipulated in Article 8, and compensate
            consumers in accordance with the conditions which are initially
            suggested by the “Mall”. Notwithstanding the foregoing, in the event
            that the “Mall” has not notified the criteria for compensation, the
            “Mall” shall pay Users for their mileage or reserve in kind or cash
            of which value corresponds to the currency being used at the “Mall”.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            Article 6 (Membership)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ① The User shall apply for the membership by expressing his or her
            intent to agree on this Agreement after filling out the form
            designated by the “Mall” with the member information.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ② The “Mall” shall register the User who applied for the membership
            in the manner stipulated in Clause ① as a Member provided that the
            User is not engaged in one of the following items. In the event
            that:
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            1. the applicant ever lost its membership in the past in accordance
            with Clause 3 of Article 7 of this Agreement. Notwithstanding the
            foregoing, this shall not apply to those acquired the approval of
            the “Mall” for re-subscription of the membership since 3 years has
            passed from the date of loss of membership in accordance with Clause
            3 of Article 7 of this Agreement;
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            2. there is false information or omission in the registered
            contents; or
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            3. the “Mall” deems that the applicant may cause significant
            inconvenience to be registered as a Member.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ③ The Membership shall be effective at the time that the Member
            receives the approval of the “Mall”.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ④ The Member shall notify any change of its information to the
            “Mall” in accordance with Clause 1 of Article 15 through email or
            other communication method.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            Article 7 (Withdrawal from Membership & Loss of Eligibility)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ① The Member may at any time request for the withdrawal from its
            membership to the “Mall”, and the “Mall” shall proceed with the
            withdrawal on the request of the Member.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ② In the event that a Member is engaged in one of the following
            items, the “Mall” may limit or suspend the eligibility of the
            Member. In the event that the Member:
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            1. registered false information at the time of the application for
            membership;
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            2. has not paid the price of goods it purchased through the “Mall”
            or other liability borne by the Member in respect of the use of the
            “Mall”;
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            3. interrupts others to use the “Mall” or threats the order of
            e-commerce, e.g. illegal of personal information; or
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            4. takes any action being against the laws, this Agreement and good
            public order and customers by using the “Mall”.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ③ The “Mall” may cancel the membership of the Member whose
            membership was suspended or limited, and repeated the same action
            twice or more, or the cause is not corrected within 30 days.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ④ In the event of the cancellation of membership, the “Mall” shall
            notify it to the Member, and give the Member 30 days or more to
            grant an opportunity to explain the cause prior to the cancellation.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            Article 8 (Notification to Members)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ① In the event of any notification of the “Mall” to a Member, it may
            be delivered via an email address designated by the Member through
            the agreement with the “Mall”.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ② The “Mall”, in the event of the notification to unspecified
            Members, may replace individual notification by displaying such
            notification on the board linked in the website of the “Mall” for 1
            week or more. Notwithstanding the foregoing, the “Mall” shall give
            individual notice to a Member in respect of any matter which may
            have a significant influence on the Member regarding his or her
            transaction.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            Article 9 (Request for Purchase)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            The User shall apply for purchase in accordance with the following
            manner or other similar manner at the “Mall”, and the “Mall” shall
            provide the User with the following information in the form which
            the User may easily understand in his or her request for purchase.
            Notwithstanding the foregoing, the Member may be exempted from the
            application of Item 2 or Item 4 below.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            1. Search and selection of goods, et cetera;
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            2. Entering name, address, telephone number, email address (or
            mobile phone number);
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            3. Confirmation of matter(s) in respect of the contents of this
            Agreement, the service(s) of which right of cancellation of purchase
            is limited, obligation of delivery fees and installation fees, et
            cetera;
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            4. Expression to agree on this Agreement, or confirm or refuse the
            Item 3 above (e.g. mouse click);
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            5. Application for purchase of goods and confirmation of such
            application, or agreement on the confirmation of the “Mall”; or
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            6. Selection of payment method.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            Article 10 (Conclusion of Contract)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ① The “Mall” may not accept the request for purchase in Article 9 if
            it falls one of the following items.. Notwithstanding the foregoing,
            in the event of the conclusion of contract with a minor, the “Mall”
            shall notify that the failure to acquire the agreement of the legal
            representative may cause the cancellation of the contract by the
            minor him(her)self or the legal representative. In the event that:
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            1. there is false information or omission in the registered
            contents;
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            2. the minor purchase any product or service restricted in the Youth
            Protection Law, e.g. cigarette or liquor;
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            3. the “Mall” deems that the applicant may cause significant
            inconvenience to accept the application for purchase; or
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ② The contract is deemed to be concluded when the acceptance of the
            “Mall” is delivered to the User in the form of the confirmation of
            receipt stipulated in Clause 1 of Article 12.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ③ The acceptance of the “Mall” shall include the information such as
            confirmation of the application for purchase of the User,
            availability of sales, and correction or cancellation of the
            application for purchase.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            Article 11 (Payment Method)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            The method of payment for a product or service purchased through the
            “Mall” may be selected among the following items. Notwithstanding
            the foregoing, the “Mall” may not collect any additional fees on the
            amount of the product or service.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            1. Account transfer, e.g. phone banking, internet banking, mail
            banking, et cetera;
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            2. card payment, e.g. prepaid card, debit card, credit card, et
            cetera;
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            3. Online non-bankbook deposit;
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            4. Electronic money;
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            5. Pay-on-receipt;
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            6. Point offered by the “Mall”, e.g. mileage;
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            7. Gift voucher contracted or approved by the “Mall”; or
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            8. Other payment by electronic means.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            Article 12 (Notice of Receipt, Change and Cancellation of
            Application for Purchase)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ① In the event of an application for purchase of a User, the “Mall”
            shall notify the User with the receipt of the application.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ② In the event that there is any discordance between expressions of
            intent, the User who received the notice of receipt may change or
            cancel the application for purchase immediately after the User
            receives the notice, and in the event of any request of a User prior
            to the delivery, the “Mall” shall deal with the request without any
            delay. Notwithstanding the foregoing, in the event that the payment
            has been already made, it shall be in accordance with the
            stipulation related to the cancellation of purchase in Article 15.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            Article 13 (Provision of Goods)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ① Unless otherwise stipulated herein in respect of the schedule for
            the provision of goods, the “Mall” shall take necessary measures to
            deliver the goods within 7 days from the date of purchase by a User
            such as customized production or packaging. Notwithstanding the
            foregoing, in the event that the “Mall” has received the whole or
            the part of the payment for the goods, such measures shall be taken
            within 2 business days from the date of receipt of the payment. At
            that time, the “Mall” shall take necessary measures to make the User
            check the procedures of provision of the goods and delivery process.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ② The “Mall” shall specify, for the goods purchased by a User, the
            delivery method, payer of delivery fees by method, period of
            delivery by method, et cetera. In the event that the “Mall” exceeds
            the designated period of delivery, it shall compensate for damages
            to the User. Notwithstanding the foregoing, this shall not apply if
            the “Mall” proves that such event is not caused by its intention or
            negligence.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            Article 14 (Refund)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            In the event that the product or service which a User applied for
            purchase may not be provided or delivered due to sold-out or other
            cause, the “Mall” shall notify the situation to the User without any
            delay, and in the event that it has received the payment for the
            product or service in advance, it shall refund the payment or take
            necessary measures within 2 business days from the date of receipt
            of the payment.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            Article 15 (Cancellation of Purchase)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ① The User who concluded the contract for the purchase of goods with
            the “Mall” may cancel the purchase within 7 days from the date on
            which the User received the notice of receipt.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ② The User may not return or change the good which he or she
            received through delivery in the event of one of the following
            items. In the event that:
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            1. the goods delivered is lost or damaged with the responsibility of
            the User (Notwithstanding the foregoing, the cancellation of
            purchase may be acceptable in the event of the damage on packaging
            for checking the contents in the box.);
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            2. the value of the goods is significantly decreased due to the use
            or consumption by the User;
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            3. the value of the goods is significantly decreased thus not
            available for re-sale due to the lapse of time;
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            4. the packaging is damaged, if the goods may be reproduced to the
            goods showing the same performance;
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ③ The cancellation of purchase by User shall not be limited if, in
            the case of Item 2 or Item 4 of Clause 2, the “Mall” did not specify
            the fact that the cancellation of purchase is limited for easier
            recognition by consumer or not take necessary measures, e.g.
            provision of a sample.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ④ Notwithstanding Clause 1 and Clause 2, the User may cancel his or
            her purchase of goods within 3 months from the date of receipt of
            the goods or within 30 days from the date on which he or she
            recognized or could recognize that the contents of goods differ from
            the advertisement or the provision of contract.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            Article 16 (Effect of Cancellation of Purchase)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ① In the event of the return of goods from a User, the “Mall” shall
            refund the payment for the goods within 3 business days from the
            date on which it had received the payment. In the event that the
            “Mall” caused a delay of the refund to the User, it shall pay
            interest calculated with overdue interest rate announced by the Fair
            Trade Commission for the number of days of delay.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ② Regarding the above-mentioned refund and in the event that the
            User made a payment for the goods with credit card or electronic
            money, the “Mall” shall request the business who provided such
            payment method to suspend or cancel the payment without any delay.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ③ In the event of any cancellation of purchase, the User shall bear
            the cost for return of the goods provided. The “Mall” shall not
            claim for cancellation charge or compensation for damages to the
            User in respect of the cancellation of purchase. Notwithstanding the
            foregoing, in the event of the cancellation of purchase caused by
            that the contents of goods differ from the advertisement or the
            provision of contract, the “Mall” shall bear the cost for return of
            the goods.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ④ In the event that User bore the delivery fees when he or she
            received the goods, the “Mall” shall specify who would bear the cost
            for the cancellation of purchase for easier recognition of the User.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            Article 17 (Protection of Personal Information)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ① The “Mall” collects minimum information necessary for the
            execution of the purchase contract with Users. The following items
            shall be necessary information and others are optional.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            1. Name;
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            2. Address;
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            3. Telephone number;
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            4. ID (for Members);
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            5. Password (for Members, Confirmation for order inquiry for
            non-members);
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            6. Email address (or mobile phone number).
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ② In the event that the “Mall” collects personal information
            available for identification of User, it must obtain the approval of
            the User.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ③ The personal information may not be used for any purpose and
            provided to any 3rd party without the approval of the User, and the
            “Mall” shall be responsible for the matter. Notwithstanding the
            foregoing, it shall not apply to the following items. In the event
            that:
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            1. the “Mall” informs the delivery service provider with minimum
            information of User for performing delivery duty (e.g. name,
            address, telephone number);
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            2. such personal information is necessary for statistics, academic
            research or market research, and provided in the form that a certain
            individual may not be identified;
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            3. such personal information is necessary for the settlement of
            payment for transacting products, et cetera;
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            4. such personal information is necessary for the identification
            against illegal use; or
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            5. there is inevitable cause by regulation or law.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ④ In the event that the “Mall” is required to acquire the approval
            of User by Clause 2 and 3, it shall specify or notify the provision
            stipulated in Clause of Article 22 of the Law related to Stimulation
            of Use of Information and Communication Network & Information
            Protection, e.g. identity of the personal information manager (post,
            name, telephone number, other contact), purpose of collection and
            use of information, matters related to the provision of information
            to 3rd party (recipient, purpose of provision and information to be
            provided), et cetera.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ⑤ Users may at any time request for the confirmation and correction
            of error on their personal information possessed by the “Mall”, the
            “Mall” shall be responsible for taking any necessary measures
            without any delay. In the event that a User requests for the
            correction of an error, the “Mall” shall not use the applicable
            personal information until it corrects the error.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ⑥ The “Mall” shall limit the number of managers for protecting
            personal information, and be responsible for any damages of User
            caused by loss, disclosure or falsification or personal information
            of the User including credit card and bank account.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ⑦ The “Mall” or any 3rd party who received personal information from
            the “Mall” , shall without any delay destroy personal information
            after it achieves its purpose of collection of the personal
            information.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            Article 18 (Obligations of “Mall”)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ① The “Mall” shall not take any action restricted by the law and
            this Agreement or being against the good public order and customs,
            and put its best efforts to provide products and services on a
            stable basis in accordance with the provisions in this Agreement.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ② The “Mall” shall equip the security system for the protection of
            personal information of Users (including credit information) so that
            the Users may safely use online services.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ③ The “Mall” shall be responsible for the compensation to User(s) if
            it has caused damages to the User(s) by displaying or adding unjust
            or unreasonable advertisement for a certain product or service in
            accordance with Article 3 of the Law related to Fairness of Display
            and Advertisement.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ④ The “Mall” shall not send any profit-making emails which are not
            wanted by Users.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            Article 19 (Obligations for ID & Password of Member)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ① Each Member shall be responsible for the management of his or her
            ID and password, except the case in Article 17.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ② Each Member shall not allow any 3rd party to use his or her ID and
            password.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ③ In the event that a Member recognizes that his or her ID and/or
            password is stolen or used by a 3rd party, the Member shall
            immediately notify the fact to the “Mall”, and follow the
            instruction of the “Mall”, if required.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            Article 20 (Obligations of User)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            Users shall not:
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            1. register false information at the time of its application or
            change of information;
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            2. steal others’ personal information;
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            3. change of information displayed on the “Mall”;
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            4. remit or display any information other than the information
            selected by the “Mall” (computer program, et cetera);
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            5. infringe the copyright or the intellectual property right of the
            “Mall” or a 3rd party;
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            6. take any action to bring disgrace on or interrupt the operation
            of the “Mall” or a 3rd party; or
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            7. disclose or display any information containing indecent or
            violent message, video, voice, and other information being against
            the good public order and customs.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            Article 21 (Relationship between Liking Mall & Linked Mall)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ① In the event that the main mall and the sub-mall are linked with
            the hyperlink (e.g. the subject of hyperlink includes text, image
            and video), the former is called as the Linking Mall (Website) and
            the latter is called as the Linked Mall (Website).
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ② The Liking Mall shall not be responsible for any transaction with
            the User for any product or service independently provided by the
            Linked Mall if the Liking Mall specify its intent of such
            non-guarantee on the initial page at the website of the Liking Mall
            or pop-up window.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            Article 22 (Copyright & Limitation of Use)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ① The copyright and other intellectual property right for the works
            produced by the “Mall” shall be belonged to the “Mall”.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ② The User may not use or cause any 3rd party to use the information
            of which intellectual property right is belonged to the “Mall” among
            those acquired in the course of the use of the “Mall” for the
            reproduction, transmission, publishing, distribution, broadcasting
            or other profit-making use without the prior consent of the “Mall”.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ③ The “Mall” shall notify the User when using the copyright belonged
            to the applicable User in accordance with mutual agreement.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            Article 23 (Resolution of Dispute)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ① The “Mall” shall reflect the reasonable opinion or complaint made
            by User(s), and install and operate an organization for dealing with
            compensation for damages.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ② The “Mall” shall give priority to any opinion or complaint made by
            User(s). Notwithstanding the foregoing, in the event that it may not
            promptly deal with the matter, the “Mall” shall notify the User with
            the cause and the schedule to make the treatment done.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ③ In the event that there is any application for remedy by a User
            regarding the dispute raised between the “Mall” and the User, the
            matter may be treated in accordance with the settlement of the Fair
            Trade Commission or the arbitration commissioned by the Mayor or the
            jurisdiction.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            Article 24 (Jurisdiction & Governing Law)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ① Any and all e-commerce-related lawsuits raised between the “Mall”
            and a User shall be in accordance with the address of the User at
            that time of the suing, and in the event of there is no address,
            such lawsuit shall be exclusively controlled by the competent court
            of jurisdiction. Notwithstanding the foregoing, in the event that
            the address or residence of the User at that time of the suing is
            not clear or in the event of the foreigner, the lawsuit shall be
            filed to the competent court on the Civil Procedures Code.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ② Any and all e-commerce-related lawsuits raised between the “Mall”
            and a User shall be governed by the law of the Republic of Korea.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            ** Supplementary Provision (Effective Date)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            This Agreement shall be effective from February 01, 2011. KOREAN.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_bold,
              color: c_112b38,
              marginTop: 50,
            }}
          >
            이용약관
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            제1조(목적)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            이 약관은 두곰(전자상거래 사업자)이 운영하는 사이트(이하 “몰”이라
            한다)에서 제공하는 인터넷 관련 서비스(이하 “서비스”라 한다)를
            이용함에 있어 사이버 몰과 이용자의 권리, 의무 및 책임사항을 규정함을
            목적으로 합니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ※「PC통신, 무선 등을 이용하는 전자상거래에 대해서도 그 성질에 반하지
            않는 한 이 약관을 준용합니다」
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            제2조(정의)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ①“몰” 이란 두곰 회원이 "몰"의 이용자에게 재화 또는 용역(이하
            “재화등”이라 함)을 제공하기 위해 컴퓨터 등 정보통신설비를 이용하여
            설정한 온라인 중개 플랫폼을 말하며, 아울러 사이버몰을 운영하는
            사업자의 의미로도 사용합니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ②“이용자”란 “몰”에 접속하여 이 약관에 따라 “몰”이 제공하는 서비스를
            받는 회원 및 비회원을 말합니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ③ ‘회원’이라 함은 “몰”에 개인정보를 제공하여 회원등록을 한 자로서,
            “몰”의 정보를 지속적으로 제공받으며, “몰”이 제공하는 서비스를
            계속적으로 이용할 수 있는 자를 말합니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ④ ‘비회원’이라 함은 회원에 가입하지 않고 “몰”이 제공하는 서비스를
            이용하는 자를 말합니다.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            제3조 (약관등의 명시와 설명 및 개정)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ① “몰”은 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지
            주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호,
            전자우편주소, 사업자등록번호, 통신판매업신고번호,
            개인정보관리책임자등을 이용자가 쉽게 알 수 있도록 초기
            서비스화면(전면)에 게시합니다. 다만, 약관의 내용은 이용자가
            연결화면을 통하여 볼 수 있도록 할 수 있습니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ② “몰은 이용자가 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중
            청약철회, 배송책임, 환불조건 등과 같은 중요한 내용을 이용자가 이해할
            수 있도록 별도의 연결화면 또는 팝업화면 등을 제공하여 이용자의
            확인을 구하여야 합니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ③ “몰”은 전자상거래등에서의소비자보호에관한법률,
            약관의규제에관한법률, 전자거래기본법, 전자서명법,
            정보통신망이용촉진등에관한법률, 방문판매등에관한법률, 소비자보호법
            등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ④ “몰”이 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여
            현행약관과 함께 몰의 초기화면에 그 적용일자 7일이전부터 적용일자
            전일까지 공지합니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            다만, 이용자에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일
            이상의 사전 유예기간을 두고 공지합니다. 이 경우 “몰“은 개정전 내용과
            개정후 내용을 명확하게 비교하여 이용자가 알기 쉽도록 표시합니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ⑤ “몰”이 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에
            체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는
            개정전의 약관조항이 그대로 적용됩니다. 다만 이미 계약을 체결한
            이용자가 개정약관 조항의 적용을 받기를 원하는 뜻을 제3항에 의한
            개정약관의 공지기간내에 ‘몰“에 송신하여 ”몰“의 동의를 받은 경우에는
            개정약관 조항이 적용됩니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ⑥ 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는
            전자상거래등에서의소비자보호에관한법률, 약관의규제등에관한법률,
            공정거래위원회가 정하는 전자상거래등에서의소비자보호지침 및 관계법령
            또는 상관례에 따릅니다.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            제4조(서비스의 제공 및 변경)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ① “몰”은 다음과 같은 업무를 수행합니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            1. 재화 또는 용역에 대한 중개 및 구매계약의 체결
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            2. 구매계약이 체결된 재화 또는 용역의 배송을 확인할 수 있는 플랫폼
            제공
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            3. 기타 “몰”이 정하는 업무
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ② “몰”은 재화 또는 용역의 품절 또는 기술적 사양의 변경 등의 경우에는
            장차 체결되는 계약에 의해 제공할 재화 또는 용역의 내용을 변경할 수
            있습니다. 이 경우에는 변경된 재화 또는 용역의 내용 및 제공일자를
            명시하여 현재의 재화 또는 용역의 내용을 게시한 곳에 즉시 공지합니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ③ “몰”이 제공하기로 이용자와 계약을 체결한 서비스의 내용을 재화등의
            품절 또는 기술적 사양의 변경 등의 사유로 변경할 경우에는 그 사유를
            이용자에게 통지 가능한 주소로 즉시 통지합니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ④ 전항의 경우 “몰”은 이로 인하여 이용자가 입은 손해를 배상합니다.
            다만, “몰”이 고의 또는 과실이 없음을 입증하는 경우에는 그러하지
            아니합니다.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            제5조(서비스의 중단)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ① “몰”은 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신의
            두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할
            수 있습니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ② “몰”은 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여
            이용자 또는 제3자가 입은 손해에 대하여 배상합니다. 단, “몰”이 고의
            또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ③ 사업종목의 전환, 사업의 포기, 업체간의 통합 등의 이유로 서비스를
            제공할 수 없게 되는 경우에는 “몰”은 제8조에 정한 방법으로 이용자에게
            통지하고 당초 “몰”에서 제시한 조건에 따라 소비자에게 보상합니다.
            다만, “몰”이 보상기준 등을 고지하지 아니한 경우에는 이용자들의
            마일리지 또는 적립금 등을 “몰”에서 통용되는 통화가치에 상응하는 현물
            또는 현금으로 이용자에게 지급합니다.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            제6조(회원가입)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ① 이용자는 “몰”이 정한 가입 양식에 따라 회원정보를 기입한 후 이
            약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ② “몰”은 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음
            각호에 해당하지 않는 한 회원으로 등록합니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            1. 가입신청자가 이 약관 제7조제3항에 의하여 이전에 회원자격을 상실한
            적이 있는 경우, 다만 제7조제3항에 의한 회원자격 상실후 3년이 경과한
            자로서 “몰”의 회원재가입 승낙을 얻은 경우에는 예외로 한다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            2. 등록 내용에 허위, 기재누락, 오기가 있는 경우
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            3. 기타 회원으로 등록하는 것이 “몰”의 기술상 현저히 지장이 있다고
            판단되는 경우
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ③ 회원가입계약의 성립시기는 “몰”의 승낙이 회원에게 도달한 시점으로
            합니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ④ 회원은 제15조제1항에 의한 등록사항에 변경이 있는 경우, 즉시
            전자우편 기타 방법으로 “몰”에 대하여 그 변경사항을 알려야 합니다.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            제7조(회원 탈퇴 및 자격 상실 등)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ① 회원은 “몰”에 언제든지 탈퇴를 요청할 수 있으며 “몰”은 즉시
            회원탈퇴를 처리합니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ② 회원이 다음 각호의 사유에 해당하는 경우, “몰”은 회원자격을 제한 및
            정지시킬 수 있습니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            1. 가입 신청시에 허위 내용을 등록한 경우
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            2. “몰”을 이용하여 구입한 재화등의 대금, 기타 “몰”이용에 관련하여
            회원이 부담하는 채무를 기일에 지급하지 않는 경우
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            3. 다른 사람의 “몰” 이용을 방해하거나 그 정보를 도용하는 등
            전자상거래 질서를 위협하는 경우
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            4. “몰”을 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는
            행위를 하는 경우
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ③ “몰”이 회원 자격을 제한?정지 시킨후, 동일한 행위가 2회이상
            반복되거나 30일이내에 그 사유가 시정되지 아니하는 경우 “몰”은
            회원자격을 상실시킬 수 있습니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ④ “몰”이 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이
            경우 회원에게 이를 통지하고, 회원등록 말소전에 최소한 30일 이상의
            기간을 정하여 소명할 기회를 부여합니다.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            제8조(회원에 대한 통지)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ① “몰”이 회원에 대한 통지를 하는 경우, 회원이 “몰”과 미리 약정하여
            지정한 전자우편 주소로 할 수 있습니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ② “몰”은 불특정다수 회원에 대한 통지의 경우 1주일이상 “몰” 게시판에
            게시함으로서 개별 통지에 갈음할 수 있습니다. 다만, 회원 본인의
            거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별통지를
            합니다.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            제9조(구매신청)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            “몰”이용자는 “몰”상에서 다음 또는 이와 유사한 방법에 의하여 구매를
            신청하며, “몰”은 이용자가 구매신청을 함에 있어서 다음의 각 내용을
            알기 쉽게 제공하여야 합니다. 단, 회원인 경우 제2호 내지 제4호의
            적용을 제외할 수 있습니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            1. 재화등의 검색 및 선택
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            2. 성명, 주소, 전화번호, 전자우편주소(또는 이동전화번호) 등의 입력
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            3. 약관내용, 청약철회권이 제한되는 서비스, 배송료, 설치비 등의
            비용부담과 관련한 내용에 대한 확인
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            4. 이 약관에 동의하고 위 3.호의 사항을 확인하거나 거부하는 표시(예,
            마우스 클릭)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            5. 재화등의 구매신청 및 이에 관한 확인 또는 “몰”의 확인에 대한 동의
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            6. 결제방법의 선택
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            제10조 (계약의 성립)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ① “몰”은 제9조와 같은 구매신청에 대하여 다음 각호에 해당하면
            승낙하지 않을 수 있습니다. 다만, 미성년자와 계약을 체결하는 경우에는
            법정대리인의 동의를 얻지 못하면 미성년자 본인 또는 법정대리인이
            계약을 취소할 수 있다는 내용을 고지하여야 합니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            1. 신청 내용에 허위, 기재누락, 오기가 있는 경우
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            2. 미성년자가 담배, 주류등 청소년보호법에서 금지하는 재화 및 용역을
            구매하는 경우
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            3. 기타 구매신청에 승낙하는 것이 “몰” 기술상 현저히 지장이 있다고
            판단하는 경우
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ② “몰”의 승낙이 제12조제1항의 수신확인통지형태로 이용자에게 도달한
            시점에 계약이 성립한 것으로 봅니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ③ “몰”의 승낙의 의사표시에는 이용자의 구매 신청에 대한 확인 및
            판매가능 여부, 구매신청의 정정 취소등에 관한 정보등을 포함하여야
            합니다.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            제11조(지급방법)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            “몰”에서 구매한 재화 또는 용역에 대한 대금지급방법은 다음 각호의
            방법중 가용한 방법으로 할 수 있습니다. 단, “몰”은 이용자의
            지급방법에 대하여 재화 등의 대금에 어떠한 명목의 수수료도 추가하여
            징수할 수 없습니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            1. 폰뱅킹, 인터넷뱅킹, 메일 뱅킹 등의 각종 계좌이체
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            2. 선불카드, 직불카드, 신용카드 등의 각종 카드 결제
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            3. 온라인무통장입금
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            4. 전자화폐에 의한 결제
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            5. 수령시 대금지급
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            6. 마일리지 등 “몰”이 지급한 포인트에 의한 결제
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            7. “몰”과 계약을 맺었거나 “몰”이 인정한 상품권에 의한 결제
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            8. 기타 전자적 지급 방법에 의한 대금 지급 등
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            제12조(수신확인통지, 구매신청 변경 및 취소)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ① “몰”은 이용자의 구매신청이 있는 경우 이용자에게 수신확인통지를
            합니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ② 수신확인통지를 받은 이용자는 의사표시의 불일치등이 있는 경우에는
            수신확인통지를 받은 후 즉시 구매신청 변경 및 취소를 요청할 수 있고
            “몰”은 배송전에 이용자의 요청이 있는 경우에는 지체없이 그 요청에
            따라 처리하여야 합니다. 다만 이미 대금을 지불한 경우에는 제15조의
            청약철회 등에 관한 규정에 따릅니다.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            제13조(재화등의 공급)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ① “몰”은 이용자와 재화등의 공급시기에 관하여 별도의 약정이 없는
            이상, 이용자가 청약을 한 날부터 7일 이내에 재화 등을 배송할 수
            있도록 주문제작, 포장 등 기타의 필요한 조치를 취합니다. 다만, “몰”이
            이미 재화 등의 대금의 전부 또는 일부를 받은 경우에는 대금의 전부
            또는 일부를 받은 날부터 2영업일 이내에 조치를 취합니다. 이때 “몰”은
            이용자가 재화등의 공급 절차 및 진행 사항을 확인할 수 있도록 적절한
            조치를 합니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ②“몰”은 이용자가 구매한 재화에 대해 배송수단, 수단별 배송비용
            부담자, 수단별 배송기간 등을 명시합니다. 만약 “몰”이 약정 배송기간을
            초과한 경우에는 그로 인한 이용자의 손해를 배상하여야 합니다. 다만
            “몰”이 고의과실이 없음을 입증한 경우에는 그러하지 아니합니다.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            제14조(환급)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            “몰”은 이용자가 구매신청한 재화등이 품절 등의 사유로 인도 또는
            제공을 할 수 없을 때에는 지체없이 그 사유를 이용자에게 통지하고
            사전에 재화 등의 대금을 받은 경우에는 대금을 받은 날부터 2영업일
            이내에 환급하거나 환급에 필요한 조치를 취합니다.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            제15조(청약철회 등)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ①“몰”과 재화등의 구매에 관한 계약을 체결한 이용자는 수신확인의
            통지를 받은 날부터 7일 이내에는 청약의 철회를 할 수 있습니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ② 이용자는 재화등을 배송받은 경우 다음 각호의 1에 해당하는 경우에는
            반품 및 교환을 할 수 없습니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            1. 이용자에게 책임 있는 사유로 재화 등이 멸실 또는 훼손된 경우(다만,
            재화 등의 내용을 확인하기 위하여 포장 등을 훼손한 경우에는
            청약철회를 할 수 있습니다)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            2. 이용자의 사용 또는 일부 소비에 의하여 재화 등의 가치가 현저히
            감소한 경우
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            3. 시간의 경과에 의하여 재판매가 곤란할 정도로 재화등의 가치가
            현저히 감소한 경우
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            4. 같은 성능을 지닌 재화등으로 복제가 가능한 경우 그 원본인 재화
            등의 포장을 훼손한 경우
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ③ 제2항제2호 내지 제4호의 경우에 “몰”이 사전에 청약철회 등이
            제한되는 사실을 소비자가 쉽게 알 수 있는 곳에 명기하거나 시용상품을
            제공하는 등의 조치를 하지 않았다면 이용자의 청약철회등이 제한되지
            않습니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ④ 이용자는 제1항 및 제2항의 규정에 불구하고 재화등의 내용이
            표시·광고 내용과 다르거나 계약내용과 다르게 이행된 때에는 당해
            재화등을 공급받은 날부터 3월이내, 그 사실을 안 날 또는 알 수 있었던
            날부터 30일 이내에 청약철회 등을 할 수 있습니다.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            제16조(청약철회 등의 효과)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ① “몰”은 이용자로부터 재화 등을 반환받은 경우 3영업일 이내에 이미
            지급받은 재화등의 대금을 환급합니다. 이 경우 “몰”이 이용자에게
            재화등의 환급을 지연한 때에는 그 지연기간에 대하여 공정거래위원회가
            정하여 고시하는 지연이자율을 곱하여 산정한 지연이자를 지급합니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ② “몰”은 위 대금을 환급함에 있어서 이용자가 신용카드 또는 전자화폐
            등의 결제수단으로 재화등의 대금을 지급한 때에는 지체없이 당해
            결제수단을 제공한 사업자로 하여금 재화등의 대금의 청구를 정지 또는
            취소하도록 요청합니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ③ 청약철회등의 경우 공급받은 재화등의 반환에 필요한 비용은 이용자가
            부담합니다. “몰”은 이용자에게 청약철회등을 이유로 위약금 또는
            손해배상을 청구하지 않습니다. 다만 재화등의 내용이 표시·광고 내용과
            다르거나 계약내용과 다르게 이행되어 청약철회등을 하는 경우 재화등의
            반환에 필요한 비용은 “몰”이 부담합니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ④ 이용자가 재화등을 제공받을때 발송비를 부담한 경우에 “몰”은
            청약철회시 그 비용을 누가 부담하는지를 이용자가 알기 쉽도록 명확하게
            표시합니다.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            제17조(개인정보보호)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ①“몰”은 이용자의 정보수집시 구매계약 이행에 필요한 최소한의 정보를
            수집합니다. 다음 사항을 필수사항으로 하며 그 외 사항은 선택사항으로
            합니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            1. 성명
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            2. 주소
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            3. 전화번호
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            4. 희망ID(회원의 경우)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            5. 비밀번호(회원의 경우 혹은 비회원의 구매조회 확인 위해)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            6. 전자우편주소(또는 이동전화번호)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ② “몰”이 이용자의 개인식별이 가능한 개인정보를 수집하는 때에는
            반드시 당해 이용자의 동의를 받습니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ③제공된 개인정보는 당해 이용자의 동의없이 목적외의 이용이나
            제3자에게 제공할 수 없으며, 이에 대한 모든 책임은 　몰　이 집니다.
            다만, 다음의 경우에는 예외로 합니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            1. 배송업무상 배송업체에게 배송에 필요한 최소한의 이용자의
            정보(성명, 주소, 전화번호)를 알려주는 경우
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            2. 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정
            개인을 식별할 수 없는 형태로 제공하는 경우
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            3. 재화등의 거래에 따른 대금정산을 위하여 필요한 경우
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            4. 도용방지를 위하여 본인확인에 필요한 경우
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            5. 법률의 규정 또는 법률에 의하여 필요한 불가피한 사유가 있는 경우
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ④“몰”이 제2항과 제3항에 의해 이용자의 동의를 받아야 하는 경우에는
            개인정보관리 책임자의 신원(소속, 성명 및 전화번호, 기타 연락처),
            정보의 수집목적 및 이용목적, 제3자에 대한 정보제공
            관련사항(제공받은자, 제공목적 및 제공할 정보의 내용) 등
            정보통신망이용촉진등에관한법률 제22조제2항이 규정한 사항을 미리
            명시하거나 고지해야 하며 이용자는 언제든지 이 동의를 철회할 수
            있습니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ⑤이용자는 언제든지 “몰”이 가지고 있는 자신의 개인정보에 대해 열람 및
            오류정정을 요구할 수 있으며 “몰”은 이에 대해 지체없이 필요한 조치를
            취할 의무를 집니다. 이용자가 오류의 정정을 요구한 경우에는 “몰”은 그
            오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ⑥ “몰”은 개인정보 보호를 위하여 관리자를 한정하여 그 수를 최소화하며
            신용카드, 은행계좌 등을 포함한 이용자의 개인정보의 분실, 도난, 유출,
            변조 등으로 인한 이용자의 손해에 대하여 모든 책임을 집니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ⑦ “몰” 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적
            또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체없이
            파기합니다.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            제18조(“몰“의 의무)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ① “몰”은 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지
            않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 재화?용역을
            제공하는데 최선을 다하여야 합니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ② “몰”은 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의
            개인정보(신용정보 포함)보호를 위한 보안 시스템을 갖추어야 합니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ③ “몰”이 상품이나 용역에 대하여 「표시광고의공정화에관한법률」 제3조
            소정의 부당한 표시?광고행위를 함으로써 이용자가 손해를 입은 때에는
            이를 배상할 책임을 집니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ④ “몰”은 이용자가 원하지 않는 영리목적의 광고성 전자우편을 발송하지
            않습니다.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            제19조(회원의 ID 및 비밀번호에 대한 의무)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ① 제17조의 경우를 제외한 ID와 비밀번호에 관한 관리책임은 회원에게
            있습니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ② 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ③ 회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고
            있음을 인지한 경우에는 바로 “몰”에 통보하고 “몰”의 안내가 있는
            경우에는 그에 따라야 합니다.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            제20조(이용자의 의무)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            이용자는 다음 행위를 하여서는 안됩니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            1. 신청 또는 변경시 허위 내용의 등록
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            2. 타인의 정보 도용
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            3. “몰”에 게시된 정보의 변경
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            4. “몰”이 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는
            게시
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            5. “몰” 기타 제3자의 저작권 등 지적재산권에 대한 침해
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            6. “몰” 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            7. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는
            정보를 몰에 공개 또는 게시하는 행위
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            제21조(연결“몰”과 피연결“몰” 간의 관계)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ① 상위 “몰”과 하위 “몰”이 하이퍼 링크(예: 하이퍼 링크의 대상에는
            문자, 그림 및 동화상 등이 포함됨)방식 등으로 연결된 경우, 전자를
            연결 “몰”(웹 사이트)이라고 하고 후자를 피연결 “몰”(웹사이트)이라고
            합니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ②연결“몰”은 피연결“몰”이 독자적으로 제공하는 재화등에 의하여
            이용자와 행하는 거래에 대해서 보증책임을 지지 않는다는 뜻을
            연결“몰”의 초기화면 또는 연결되는 시점의 팝업화면으로 명시한
            경우에는 그 거래에 대한 보증책임을 지지 않습니다.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            제22조(저작권의 귀속 및 이용제한)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ① “몰“이 작성한 저작물에 대한 저작권 기타 지적재산권은 ”몰“에
            귀속합니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ② 이용자는 “몰”을 이용함으로써 얻은 정보 중 “몰”에게 지적재산권이
            귀속된 정보를 “몰”의 사전 승낙없이 복제, 송신, 출판, 배포, 방송 기타
            방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는
            안됩니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ③ “몰”은 약정에 따라 이용자에게 귀속된 저작권을 사용하는 경우 당해
            이용자에게 통보하여야 합니다.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            제23조(분쟁해결)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ① “몰”은 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를
            보상처리하기 위하여 피해보상처리기구를 설치, 운영합니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ② “몰”은 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그
            사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그
            사유와 처리일정을 즉시 통보해 드립니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ③“몰”과 이용자간에 발생한 전자상거래 분쟁과 관련하여 이용자의
            피해구제신청이 있는 경우에는 공정거래위원회 또는 시·도지사가
            의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            제24조(재판권 및 준거법)
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ①“몰”과 이용자간에 발생한 전자상거래 분쟁에 관한 소송은 제소 당시의
            이용자의 주소에 의하고, 주소가 없는 경우에는 거소를 관할하는
            지방법원의 전속관할로 합니다. 다만, 제소 당시 이용자의 주소 또는
            거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의
            관할법원에 제기합니다.
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            ②“몰”과 이용자간에 제기된 전자상거래 소송에는 한국법을 적용합니다.
          </div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_medium,
              color: c_112b38,
            }}
          >
            부 칙(시행일) 이 약관은 2011년 5월 11일부터 시행합니다.
          </div>
          <div
            style={{
              height: 50,
            }}
          ></div>
        </ContentBody>
      </div>
    </div>
  )
}

export default StoreTermsAndConditionsPage

const ContentBody = styled.div`
  width: 1280px;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  box-sizing: border-box;

  @media (max-width: 1580px) {
    width: 80%;
  }

  @media (max-width: 768px) {
    width: 90%;
  }
`
