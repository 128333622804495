import { useNavigate, useLocation } from 'react-router-dom'
import { useSubscription } from '../../data_system/context/subscription_context/SubscriptionContext'
import {
  about_us_inner_router_url,
  customer_service_center_inner_router_url,
  faq_inner_router_url,
  forgotten_password_inner_router_url,
  forgotten_password_sent_inner_router_url,
  login_inner_router_url,
  main_guides_create_guide_inner_router_url,
  main_guides_help_support_inner_router_url,
  main_guides_settings_inner_router_url,
  price_inner_router_url,
  privacy_policy_inner_router_url,
  reset_password_inner_router_url,
  show_default_guide_inner_router_url,
  show_guide_inner_router_url,
  sign_up_inner_router_url,
  store_privacy_policy_inner_router_url,
  store_terms_and_conditions_inner_router_url,
  terms_and_conditions_inner_router_url,
} from '../../communication_system/inner_router_url/inner_router_url'
import { useEffect } from 'react'
import { useSelectHeader } from '../../data_system/context/select_header_index_context/SelectHeaderIndexContext'
import { useSelectSettingsSubHeader } from '../../data_system/context/select_settings_sub_header_index_context/SelectSettingsSubHeaderIndexContext'

export function SubscriptionRedirectRoute() {
  const { isClickable } = useSubscription()
  const { setHeaderIndex } = useSelectHeader()
  const { setSettingsSubHeaderIndex } = useSelectSettingsSubHeader()
  const navigate = useNavigate()
  const location = useLocation()

  const allowedPathsForExpiredSubscription = [
    '/',
    login_inner_router_url,
    forgotten_password_inner_router_url,
    forgotten_password_sent_inner_router_url,
    reset_password_inner_router_url,
    sign_up_inner_router_url,
    main_guides_create_guide_inner_router_url,
    about_us_inner_router_url,
    customer_service_center_inner_router_url,
    privacy_policy_inner_router_url,
    store_privacy_policy_inner_router_url,
    price_inner_router_url,
    faq_inner_router_url,
    terms_and_conditions_inner_router_url,
    store_terms_and_conditions_inner_router_url,
    main_guides_help_support_inner_router_url,
    main_guides_settings_inner_router_url,
    show_guide_inner_router_url,
    show_default_guide_inner_router_url,
  ]

  useEffect(() => {
    if (
      !isClickable &&
      !allowedPathsForExpiredSubscription.includes(location.pathname)
    ) {
      console.log(isClickable)
      console.log(location.pathname)
      setHeaderIndex(4)
      setSettingsSubHeaderIndex(1)
      navigate(main_guides_settings_inner_router_url)
    }
  }, [isClickable, navigate, location.pathname])

  return null // 이 컴포넌트는 UI를 렌더링하지 않습니다.
}
