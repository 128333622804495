import PcMainHeader from '../../design_system/components/header/pc_main_header/PcMainHeader'
import { ChangeBtnA } from '../../design_system/components/btn/pc/change_btn_a/ChangeBtnA'
import {
  c_112b38,
  c_353431,
  c_3ed9cc,
  c_b49275,
  c_ddc2ab,
  c_eba55d,
  c_ffcc96,
  c_ffffff,
} from '../../design_system/colors'
import {
  login_inner_router_url,
  sign_up_inner_router_url,
} from '../../communication_system/inner_router_url/inner_router_url'
import styled from 'styled-components'
import {
  noto_sans_kr_bold,
  noto_sans_kr_medium,
  noto_sans_kr_regular,
} from '../../design_system/fonts/fonts'
import { Logo2x } from '../../design_system/icons/icons'

const PricePage = () => {
  return (
    <div
      style={{
        overflow: 'auto',
      }}
    >
      <PcMainHeader>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => {
            const baseUrl = window.location.origin
            const url = `${baseUrl}`
            window.open(url, '_self')
          }}
        >
          <Logo2x></Logo2x>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ChangeBtnA
            href={login_inner_router_url}
            beforeBackgroudColor={c_ffffff}
            beforeBorderColor={c_eba55d}
            beforeFontColor={c_353431}
            afterBackgroudColor={c_b49275}
            afterBorderColor={c_b49275}
            afterFontColor={c_ffffff}
          >
            로그인
          </ChangeBtnA>
          <div
            style={{
              marginLeft: 15,
            }}
          >
            <ChangeBtnA
              href={sign_up_inner_router_url}
              beforeBackgroudColor={c_b49275}
              beforeBorderColor={c_b49275}
              beforeFontColor={c_ffffff}
              afterBackgroudColor={c_eba55d}
              afterBorderColor={c_eba55d}
              afterFontColor={c_353431}
            >
              무료 시작
            </ChangeBtnA>
          </div>
        </div>
      </PcMainHeader>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <ContentBody>
          <div
            style={{
              fontSize: 40,
              fontFamily: noto_sans_kr_bold,
              color: c_112b38,
              marginTop: 50,
              marginLeft: 10,
              marginRight: 10,
            }}
          >
            비용 안내
          </div>
          <br></br>
          <TableDiv>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                border: '1px solid #e0e0e0',
                margin: 10,
              }}
            >
              <div
                style={{
                  width: '100%',
                  backgroundColor: c_ddc2ab,
                  height: 50,
                  color: '#ffffff',
                  fontSize: 24,
                  fontFamily: noto_sans_kr_bold,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                BASIC
              </div>
              <div
                style={{
                  textAlign: 'center',
                }}
              >
                <br></br>
                <span
                  style={{
                    fontSize: 24,
                    fontFamily: noto_sans_kr_medium,
                  }}
                >
                  19,900
                </span>{' '}
                <span
                  style={{
                    fontSize: 18,
                    fontFamily: noto_sans_kr_regular,
                  }}
                >
                  / 월
                </span>
                <br></br>
                <br></br>
                <span
                  style={{
                    fontSize: 18,
                    fontFamily: noto_sans_kr_regular,
                  }}
                >
                  기본 온라인 안내문
                </span>
                <br></br>
                <br></br>
                <span
                  style={{
                    fontSize: 18,
                    fontFamily: noto_sans_kr_regular,
                  }}
                >
                  지도 연동 가이드북 제작
                </span>
                <br></br>
                <br></br>
                <span
                  style={{
                    fontSize: 18,
                    fontFamily: noto_sans_kr_regular,
                  }}
                >
                  URL 공유
                </span>
                <br></br>
                <br></br>
                <span
                  style={{
                    fontSize: 18,
                    fontFamily: noto_sans_kr_regular,
                  }}
                >
                  1330 채팅 시스템
                </span>
                <br></br>
                <br></br>
              </div>
            </div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                border: '1px solid #e0e0e0',
                margin: 10,
              }}
            >
              <div
                style={{
                  width: '100%',
                  backgroundColor: c_b49275,
                  height: 50,
                  color: '#ffffff',
                  fontSize: 24,
                  fontFamily: noto_sans_kr_bold,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                STANDARD
              </div>
              <div
                style={{
                  textAlign: 'center',
                }}
              >
                <br></br>
                <span
                  style={{
                    fontSize: 24,
                    fontFamily: noto_sans_kr_medium,
                  }}
                >
                  29,900
                </span>{' '}
                <span
                  style={{
                    fontSize: 18,
                    fontFamily: noto_sans_kr_regular,
                  }}
                >
                  / 월
                </span>
                <br></br>
                <br></br>
                <span
                  style={{
                    fontSize: 18,
                    fontFamily: noto_sans_kr_regular,
                  }}
                >
                  기본 온라인 안내문
                </span>
                <br></br>
                <br></br>
                <span
                  style={{
                    fontSize: 18,
                    fontFamily: noto_sans_kr_regular,
                  }}
                >
                  지도 연동 가이드북 제작
                </span>
                <br></br>
                <br></br>
                <span
                  style={{
                    fontSize: 18,
                    fontFamily: noto_sans_kr_regular,
                  }}
                >
                  URL 공유
                </span>
                <br></br>
                <br></br>
                <span
                  style={{
                    fontSize: 18,
                    fontFamily: noto_sans_kr_regular,
                  }}
                >
                  1330 채팅 시스템
                </span>
                <br></br>
                <br></br>
                <span
                  style={{
                    fontSize: 18,
                    fontFamily: noto_sans_kr_regular,
                  }}
                >
                  개인 스토어 상품 판매
                </span>
                <br></br>
                <br></br>
              </div>
            </div>
          </TableDiv>
          <br></br>
          <div
            style={{
              fontSize: 18,
              fontFamily: noto_sans_kr_regular,
              marginLeft: 10,
              marginRight: 10,
            }}
          >
            * BASIC과 STANDARD 버전의 가장 큰 차이점은 호스트의 개인 스토어
            여부입니다.
          </div>
          <div
            style={{
              fontSize: 18,
              fontFamily: noto_sans_kr_regular,
              marginLeft: 10,
              marginRight: 10,
            }}
          >
            * 1330 채팅 시스템은 한국 관광공사에서 한국어, 영어, 일본어, 중국어
            24시간, 연중무휴 제공하는 채팅 시스템 입니다. 게스트가 한국 여행에
            문의하고싶은 사항을 손쉽게 24시간 문의할 수 있는 채팅 시스템이 연동
            됩니다.
          </div>
          <div
            style={{
              height: 100,
            }}
          ></div>
          <div
            style={{
              overflowX: 'auto',
            }}
          >
            <table
              style={{
                textAlign: 'center',
                borderCollapse: 'collapse',
                width: '100%',
                minWidth: 800,
              }}
            >
              <tr>
                <th style={{ border: '1px solid #e0e0e0' }}>안내문 수량</th>
                <th style={{ border: '1px solid #e0e0e0' }}>1</th>
                <th style={{ border: '1px solid #e0e0e0' }}>2</th>
                <th style={{ border: '1px solid #e0e0e0' }}>3</th>
                <th style={{ border: '1px solid #e0e0e0' }}>4</th>
                <th style={{ border: '1px solid #e0e0e0' }}>5</th>
                <th style={{ border: '1px solid #e0e0e0' }}>6</th>
                <th style={{ border: '1px solid #e0e0e0' }}>7</th>
                <th style={{ border: '1px solid #e0e0e0' }}>8</th>
                <th style={{ border: '1px solid #e0e0e0' }}>9</th>
                <th style={{ border: '1px solid #e0e0e0' }}>10</th>
                <th style={{ border: '1px solid #e0e0e0' }}>11개 이상</th>
              </tr>
              <tr>
                <td style={{ border: '1px solid #e0e0e0' }}>베이직</td>
                <td style={{ border: '1px solid #e0e0e0' }}>19,900</td>
                <td style={{ border: '1px solid #e0e0e0' }}>18,900</td>
                <td style={{ border: '1px solid #e0e0e0' }}>17,900</td>
                <td style={{ border: '1px solid #e0e0e0' }}>17,400</td>
                <td style={{ border: '1px solid #e0e0e0' }}>16,900</td>
                <td style={{ border: '1px solid #e0e0e0' }}>16,400</td>
                <td style={{ border: '1px solid #e0e0e0' }}>15,900</td>
                <td style={{ border: '1px solid #e0e0e0' }}>15,400</td>
                <td style={{ border: '1px solid #e0e0e0' }}>14,900</td>
                <td style={{ border: '1px solid #e0e0e0' }}>14,500</td>
                <td style={{ border: '1px solid #e0e0e0' }}>14,500</td>
              </tr>
              <tr>
                <td style={{ border: '1px solid #e0e0e0' }}>스탠다드</td>
                <td style={{ border: '1px solid #e0e0e0' }}>29,900</td>
                <td style={{ border: '1px solid #e0e0e0' }}>28,900</td>
                <td style={{ border: '1px solid #e0e0e0' }}>27,900</td>
                <td style={{ border: '1px solid #e0e0e0' }}>27,400</td>
                <td style={{ border: '1px solid #e0e0e0' }}>26,900</td>
                <td style={{ border: '1px solid #e0e0e0' }}>26,400</td>
                <td style={{ border: '1px solid #e0e0e0' }}>26,400</td>
                <td style={{ border: '1px solid #e0e0e0' }}>26,400</td>
                <td style={{ border: '1px solid #e0e0e0' }}>26,400</td>
                <td style={{ border: '1px solid #e0e0e0' }}>26,400</td>
                <td style={{ border: '1px solid #e0e0e0' }}>26,400</td>
              </tr>
            </table>
          </div>

          <div
            style={{
              height: 10,
            }}
          ></div>
          <div
            style={{
              fontSize: 18,
              fontFamily: noto_sans_kr_regular,
              marginLeft: 10,
              marginRight: 10,
            }}
          >
            * 개당 금액입니다.
          </div>
          <div
            style={{
              height: 100,
            }}
          ></div>
        </ContentBody>
      </div>
    </div>
  )
}

export default PricePage

const ContentBody = styled.div`
  width: 1280px;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  box-sizing: border-box;

  @media (max-width: 1580px) {
    width: 80%;
  }

  @media (max-width: 768px) {
    width: 90%;
  }
`

const TableDiv = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`
