import styled from 'styled-components';

export const BigOpacityBtn = ({
  backgroundColor,
  fontColor,
  borderColor,
  onClick,
  children,
}) => {
  return (
    <Btn
      $backgroundColor={backgroundColor}
      $fontColor={fontColor}
      $borderColor={borderColor}
      onClick={onClick}
    >
      {children}
    </Btn>
  );
};

const Btn = styled.div`
  padding: 8px 16px;
  border-radius: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.$fontColor};
  background-color: ${(props) => props.$backgroundColor};
  border-color: ${(props) => props.$borderColor};
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  font-size: 1.25rem;
  transition: 0.3s;

  @media (max-width: 1024px) {
    padding: 8px;
  }

  &:hover {
    opacity: 0.7;
  }
`;
