import styled from 'styled-components';

const IconTextCircleBtn = ({
  children,
  text,
  textColor,
  borderColor,
  backgroundColor,
  onClick,
  disabled,
}) => {
  return (
    <IconTextCircleBtnFrame
      $textColor={textColor}
      $borderColor={borderColor}
      $backgroundColor={backgroundColor}
      onClick={onClick}
      disabled={disabled}
    >
      <IconTextCircleBtnSpan
        style={{
          marginRight: 8,
        }}
        $textColor={textColor}
      >
        {children}
      </IconTextCircleBtnSpan>
      <IconTextCircleBtnTextSpan $textColor={textColor}>
        {text}
      </IconTextCircleBtnTextSpan>
    </IconTextCircleBtnFrame>
  );
};

export default IconTextCircleBtn;

const IconTextCircleBtnFrame = styled.button`
  color: ${(props) => props.$textColor};
  padding: 8px 16px;
  border-radius: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.$backgroundColor};
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  transition: 0.1s;
  font-weight: 500;
  font-size: 14px;
  border: 1px solid ${(props) => props.$borderColor};
  overflow: visible;

  @media (max-width: 1024px) {
    padding: 8px;
    font-size: 10px;
    height: 34px;
    width: 34px;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const IconTextCircleBtnSpan = styled.span`
  color: ${(props) => props.$textColor};
  margin-right: 8px;
  box-sizing: border-box;
  border-width: 0px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1024px) {
    margin: 0;
    padding-left: 8px;
    font-size: 16px;
  }
`;

const IconTextCircleBtnTextSpan = styled.span`
  color: ${(props) => props.$textColor};
  box-sizing: border-box;
  border-width: 0px;

  @media (max-width: 1024px) {
    display: none;
  }
`;
