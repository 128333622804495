import React from 'react'
import logo_2x from './logo/logo_2x.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { faBell } from '@fortawesome/free-solid-svg-icons'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import {
  faCaretUp,
  faPlusCircle,
  faMinusCircle,
  faBars,
  faTimes,
  faQuestion,
  faEnvelope,
  faExternalLinkSquareAlt,
  faEllipsisV,
  faArrowLeft,
  faCamera,
  faMobileAlt,
  faPaperPlane,
  faLink,
  faFolderPlus,
  faTrash,
  faCopy,
  faEye,
  faArrowRight,
  faTh,
  faShareAlt,
  faPrint,
  faHeadset,
  faCircleArrowUp,
  faCircleArrowDown,
} from '@fortawesome/free-solid-svg-icons'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faCog, faPen } from '@fortawesome/free-solid-svg-icons'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import airport_shuttle from './category_icons/airport_shuttle.svg'
import apartment from './category_icons/apartment.svg'
import arrow_circle_up from './category_icons/arrow_circle_up.svg'
import arrow_forward from './category_icons/arrow_forward.svg'
import assistant_direction from './category_icons/assistant_direction.svg'
import assistant_navigation from './category_icons/assistant_navigation.svg'
import beach_access from './category_icons/beach_access.svg'
import bedroom_baby from './category_icons/bedroom_baby.svg'
import bedtime from './category_icons/bedtime.svg'
import call from './category_icons/call.svg'
import campaign from './category_icons/campaign.svg'
import camping from './category_icons/camping.svg'
import cancel from './category_icons/cancel.svg'
import chat_bubble from './category_icons/chat_bubble.svg'
import chat from './category_icons/chat.svg'
import check_circle from './category_icons/check_circle.svg'
import check_in_out from './category_icons/check_in_out.svg'
import checklist from './category_icons/checklist.svg'
import cheer from './category_icons/cheer.svg'
import cleaning_services from './category_icons/cleaning_services.svg'
import computer from './category_icons/computer.svg'
import concierge from './category_icons/concierge.svg'
import cooking from './category_icons/cooking.svg'
import credit_card_heart from './category_icons/credit_card_heart.svg'
import crib from './category_icons/crib.svg'
import delete_category_icon from './category_icons/delete_category_icon.svg'
import dining from './category_icons/dining.svg'
import directions_bus from './category_icons/directions_bus.svg'
import distance from './category_icons/distance.svg'
import door_front from './category_icons/door_front.svg'
import downhill_skiing from './category_icons/downhill_skiing.svg'
import edit from './category_icons/edit.svg'
import enhanced_encryption from './category_icons/enhanced_encryption.svg'
import exclamation from './category_icons/exclamation.svg'
import fact_check from './category_icons/fact_check.svg'
import family_restroom from './category_icons/family_restroom.svg'
import favorite from './category_icons/favorite.svg'
import feed from './category_icons/feed.svg'
import festival from './category_icons/festival.svg'
import fireplace from './category_icons/fireplace.svg'
import fitness_center from './category_icons/fitness_center.svg'
import hail from './category_icons/hail.svg'
import hand_gesture from './category_icons/hand_gesture.svg'
import handshake from './category_icons/handshake.svg'
import help_center from './category_icons/help_center.svg'
import help from './category_icons/help.svg'
import hiking from './category_icons/hiking.svg'
import home_health from './category_icons/home_health.svg'
import home from './category_icons/home.svg'
import house from './category_icons/house.svg'
import import_contacts from './category_icons/import_contacts.svg'
import info from './category_icons/info.svg'
import key from './category_icons/key.svg'
import lightbulb from './category_icons/lightbulb.svg'
import liquor from './category_icons/liquor.svg'
import local_cafe from './category_icons/local_cafe.svg'
import local_dining from './category_icons/local_dining.svg'
import local_hospital from './category_icons/local_hospital.svg'
import local_parking from './category_icons/local_parking.svg'
import location_on from './category_icons/location_on.svg'
import lock from './category_icons/lock.svg'
import luggage from './category_icons/luggage.svg'
import mail from './category_icons/mail.svg'
import map from './category_icons/map.svg'
import menu_book from './category_icons/menu_book.svg'
import nature_people from './category_icons/nature_people.svg'
import notifications from './category_icons/notifications.svg'
import park from './category_icons/park.svg'
import password from './category_icons/password.svg'
import pedal_bike from './category_icons/pedal_bike.svg'
import perm_phone_msg from './category_icons/perm_phone_msg.svg'
import phone_in_talk from './category_icons/phone_in_talk.svg'
import photo_camera from './category_icons/photo_camera.svg'
import push_pin from './category_icons/push_pin.svg'
import recommend from './category_icons/recommend.svg'
import recycling from './category_icons/recycling.svg'
import restaurant from './category_icons/restaurant.svg'
import reviews from './category_icons/reviews.svg'
import schedule from './category_icons/schedule.svg'
import sentiment_sad from './category_icons/sentiment_sad.svg'
import sentiment_satisfied from './category_icons/sentiment_satisfied.svg'
import settings from './category_icons/settings.svg'
import shopping_bag from './category_icons/shopping_bag.svg'
import shopping_cart from './category_icons/shopping_cart.svg'
import shoppingmode from './category_icons/shoppingmode.svg'
import shower from './category_icons/shower.svg'
import signpost from './category_icons/signpost.svg'
import single_bed from './category_icons/single_bed.svg'
import skillet from './category_icons/skillet.svg'
import smartphone from './category_icons/smartphone.svg'
import star from './category_icons/star.svg'
import storefront from './category_icons/storefront.svg'
import support_agent from './category_icons/support_agent.svg'
import thumb_up from './category_icons/thumb_up.svg'
import travel from './category_icons/travel.svg'
import volunteer_activism from './category_icons/volunteer_activism.svg'
import waving_hand from './category_icons/waving_hand.svg'
import wifi_password from './category_icons/wifi_password.svg'
import wifi from './category_icons/wifi.svg'
import woman_2 from './category_icons/woman_2.svg'
import { doogom_com_image_s3_url } from '../../communication_system/communication_router_url/communication_router_url'
import activity1 from './map_pin_icons/activity1.png'
import activity2 from './map_pin_icons/activity2.png'
import activity3 from './map_pin_icons/activity3.png'
import activity4 from './map_pin_icons/activity4.png'
import activity5 from './map_pin_icons/activity5.png'
import activity6 from './map_pin_icons/activity6.png'
import activity7 from './map_pin_icons/activity7.png'
import activity8 from './map_pin_icons/activity8.png'
import activity9 from './map_pin_icons/activity9.png'
import activity10 from './map_pin_icons/activity10.png'
import bars_pub1 from './map_pin_icons/bars_pub1.png'
import bars_pub2 from './map_pin_icons/bars_pub2.png'
import bars_pub3 from './map_pin_icons/bars_pub3.png'
import bars_pub4 from './map_pin_icons/bars_pub4.png'
import bars_pub5 from './map_pin_icons/bars_pub5.png'
import bars_pub6 from './map_pin_icons/bars_pub6.png'
import bars_pub7 from './map_pin_icons/bars_pub7.png'
import bars_pub8 from './map_pin_icons/bars_pub8.png'
import bars_pub9 from './map_pin_icons/bars_pub9.png'
import bars_pub10 from './map_pin_icons/bars_pub10.png'
import cafe1 from './map_pin_icons/cafe1.png'
import cafe2 from './map_pin_icons/cafe2.png'
import cafe3 from './map_pin_icons/cafe3.png'
import cafe4 from './map_pin_icons/cafe4.png'
import cafe5 from './map_pin_icons/cafe5.png'
import cafe6 from './map_pin_icons/cafe6.png'
import cafe7 from './map_pin_icons/cafe7.png'
import cafe8 from './map_pin_icons/cafe8.png'
import cafe9 from './map_pin_icons/cafe9.png'
import cafe10 from './map_pin_icons/cafe10.png'
import gettingaround1 from './map_pin_icons/gettingaround1.png'
import gettingaround2 from './map_pin_icons/gettingaround2.png'
import gettingaround3 from './map_pin_icons/gettingaround3.png'
import gettingaround4 from './map_pin_icons/gettingaround4.png'
import gettingaround5 from './map_pin_icons/gettingaround5.png'
import gettingaround6 from './map_pin_icons/gettingaround6.png'
import gettingaround7 from './map_pin_icons/gettingaround7.png'
import gettingaround8 from './map_pin_icons/gettingaround8.png'
import gettingaround9 from './map_pin_icons/gettingaround9.png'
import gettingaround10 from './map_pin_icons/gettingaround10.png'
import home1 from './map_pin_icons/home1.png'
import home2 from './map_pin_icons/home2.png'
import home3 from './map_pin_icons/home3.png'
import home4 from './map_pin_icons/home4.png'
import home5 from './map_pin_icons/home5.png'
import home6 from './map_pin_icons/home6.png'
import home7 from './map_pin_icons/home7.png'
import home8 from './map_pin_icons/home8.png'
import home9 from './map_pin_icons/home9.png'
import home10 from './map_pin_icons/home10.png'
import medicalfacility1 from './map_pin_icons/medicalfacility1.png'
import medicalfacility2 from './map_pin_icons/medicalfacility2.png'
import medicalfacility3 from './map_pin_icons/medicalfacility3.png'
import medicalfacility4 from './map_pin_icons/medicalfacility4.png'
import medicalfacility5 from './map_pin_icons/medicalfacility5.png'
import medicalfacility6 from './map_pin_icons/medicalfacility6.png'
import medicalfacility7 from './map_pin_icons/medicalfacility7.png'
import medicalfacility8 from './map_pin_icons/medicalfacility8.png'
import medicalfacility9 from './map_pin_icons/medicalfacility9.png'
import medicalfacility10 from './map_pin_icons/medicalfacility10.png'
import restaurant1 from './map_pin_icons/restaurant1.png'
import restaurant2 from './map_pin_icons/restaurant2.png'
import restaurant3 from './map_pin_icons/restaurant3.png'
import restaurant4 from './map_pin_icons/restaurant4.png'
import restaurant5 from './map_pin_icons/restaurant5.png'
import restaurant6 from './map_pin_icons/restaurant6.png'
import restaurant7 from './map_pin_icons/restaurant7.png'
import restaurant8 from './map_pin_icons/restaurant8.png'
import restaurant9 from './map_pin_icons/restaurant9.png'
import restaurant10 from './map_pin_icons/restaurant10.png'
import shopping1 from './map_pin_icons/shopping1.png'
import shopping2 from './map_pin_icons/shopping2.png'
import shopping3 from './map_pin_icons/shopping3.png'
import shopping4 from './map_pin_icons/shopping4.png'
import shopping5 from './map_pin_icons/shopping5.png'
import shopping6 from './map_pin_icons/shopping6.png'
import shopping7 from './map_pin_icons/shopping7.png'
import shopping8 from './map_pin_icons/shopping8.png'
import shopping9 from './map_pin_icons/shopping9.png'
import shopping10 from './map_pin_icons/shopping10.png'
import supermarket1 from './map_pin_icons/supermarket1.png'
import supermarket2 from './map_pin_icons/supermarket2.png'
import supermarket3 from './map_pin_icons/supermarket3.png'
import supermarket4 from './map_pin_icons/supermarket4.png'
import supermarket5 from './map_pin_icons/supermarket5.png'
import supermarket6 from './map_pin_icons/supermarket6.png'
import supermarket7 from './map_pin_icons/supermarket7.png'
import supermarket8 from './map_pin_icons/supermarket8.png'
import supermarket9 from './map_pin_icons/supermarket9.png'
import supermarket10 from './map_pin_icons/supermarket10.png'

export const activity1_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/activity1.png`
export const activity2_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/activity2.png`
export const activity3_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/activity3.png`
export const activity4_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/activity4.png`
export const activity5_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/activity5.png`
export const activity6_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/activity6.png`
export const activity7_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/activity7.png`
export const activity8_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/activity8.png`
export const activity9_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/activity9.png`
export const activity10_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/activity10.png`
export const bars_pub1_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/bars_pub1.png`
export const bars_pub2_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/bars_pub2.png`
export const bars_pub3_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/bars_pub3.png`
export const bars_pub4_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/bars_pub4.png`
export const bars_pub5_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/bars_pub5.png`
export const bars_pub6_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/bars_pub6.png`
export const bars_pub7_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/bars_pub7.png`
export const bars_pub8_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/bars_pub8.png`
export const bars_pub9_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/bars_pub9.png`
export const bars_pub10_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/bars_pub10.png`
export const cafe1_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/cafe1.png`
export const cafe2_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/cafe2.png`
export const cafe3_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/cafe3.png`
export const cafe4_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/cafe4.png`
export const cafe5_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/cafe5.png`
export const cafe6_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/cafe6.png`
export const cafe7_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/cafe7.png`
export const cafe8_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/cafe8.png`
export const cafe9_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/cafe9.png`
export const cafe10_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/cafe10.png`
export const gettingaround1_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/gettingaround1.png`
export const gettingaround2_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/gettingaround2.png`
export const gettingaround3_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/gettingaround3.png`
export const gettingaround4_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/gettingaround4.png`
export const gettingaround5_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/gettingaround5.png`
export const gettingaround6_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/gettingaround6.png`
export const gettingaround7_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/gettingaround7.png`
export const gettingaround8_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/gettingaround8.png`
export const gettingaround9_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/gettingaround9.png`
export const gettingaround10_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/gettingaround10.png`
export const home1_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/home1.png`
export const home2_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/home2.png`
export const home3_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/home3.png`
export const home4_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/home4.png`
export const home5_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/home5.png`
export const home6_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/home6.png`
export const home7_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/home7.png`
export const home8_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/home8.png`
export const home9_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/home9.png`
export const home10_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/home10.png`
export const medicalfacility1_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/medicalfacility1.png`
export const medicalfacility2_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/medicalfacility2.png`
export const medicalfacility3_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/medicalfacility3.png`
export const medicalfacility4_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/medicalfacility4.png`
export const medicalfacility5_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/medicalfacility5.png`
export const medicalfacility6_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/medicalfacility6.png`
export const medicalfacility7_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/medicalfacility7.png`
export const medicalfacility8_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/medicalfacility8.png`
export const medicalfacility9_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/medicalfacility9.png`
export const medicalfacility10_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/medicalfacility10.png`
export const restaurant1_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/restaurant1.png`
export const restaurant2_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/restaurant2.png`
export const restaurant3_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/restaurant3.png`
export const restaurant4_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/restaurant4.png`
export const restaurant5_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/restaurant5.png`
export const restaurant6_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/restaurant6.png`
export const restaurant7_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/restaurant7.png`
export const restaurant8_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/restaurant8.png`
export const restaurant9_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/restaurant9.png`
export const restaurant10_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/restaurant10.png`
export const shopping1_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/shopping1.png`
export const shopping2_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/shopping2.png`
export const shopping3_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/shopping3.png`
export const shopping4_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/shopping4.png`
export const shopping5_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/shopping5.png`
export const shopping6_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/shopping6.png`
export const shopping7_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/shopping7.png`
export const shopping8_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/shopping8.png`
export const shopping9_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/shopping9.png`
export const shopping10_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/shopping10.png`
export const supermarket1_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/supermarket1.png`
export const supermarket2_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/supermarket2.png`
export const supermarket3_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/supermarket3.png`
export const supermarket4_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/supermarket4.png`
export const supermarket5_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/supermarket5.png`
export const supermarket6_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/supermarket6.png`
export const supermarket7_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/supermarket7.png`
export const supermarket8_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/supermarket8.png`
export const supermarket9_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/supermarket9.png`
export const supermarket10_image_s3 = `${doogom_com_image_s3_url}/map_pin_icons/supermarket10.png`
export const airport_shuttle_image_s3 = `${doogom_com_image_s3_url}/category_icons/airport_shuttle.svg`
export const apartment_image_s3 = `${doogom_com_image_s3_url}/category_icons/apartment.svg`
export const arrow_circle_up_image_s3 = `${doogom_com_image_s3_url}/category_icons/arrow_circle_up.svg`
export const arrow_forward_image_s3 = `${doogom_com_image_s3_url}/category_icons/arrow_forward.svg`
export const assistant_direction_image_s3 = `${doogom_com_image_s3_url}/category_icons/assistant_direction.svg`
export const assistant_navigation_image_s3 = `${doogom_com_image_s3_url}/category_icons/assistant_navigation.svg`
export const beach_access_image_s3 = `${doogom_com_image_s3_url}/category_icons/beach_access.svg`
export const bedroom_baby_image_s3 = `${doogom_com_image_s3_url}/category_icons/bedroom_baby.svg`
export const bedtime_image_s3 = `${doogom_com_image_s3_url}/category_icons/bedtime.svg`
export const call_image_s3 = `${doogom_com_image_s3_url}/category_icons/call.svg`
export const campaign_image_s3 = `${doogom_com_image_s3_url}/category_icons/campaign.svg`
export const camping_image_s3 = `${doogom_com_image_s3_url}/category_icons/camping.svg`
export const cancel_image_s3 = `${doogom_com_image_s3_url}/category_icons/cancel.svg`
export const chat_bubble_image_s3 = `${doogom_com_image_s3_url}/category_icons/chat_bubble.svg`
export const chat_image_s3 = `${doogom_com_image_s3_url}/category_icons/chat.svg`
export const check_circle_image_s3 = `${doogom_com_image_s3_url}/category_icons/check_circle.svg`
export const check_in_out_image_s3 = `${doogom_com_image_s3_url}/category_icons/check_in_out.svg`
export const checklist_image_s3 = `${doogom_com_image_s3_url}/category_icons/checklist.svg`
export const cheer_image_s3 = `${doogom_com_image_s3_url}/category_icons/cheer.svg`
export const cleaning_services_image_s3 = `${doogom_com_image_s3_url}/category_icons/cleaning_services.svg`
export const computer_image_s3 = `${doogom_com_image_s3_url}/category_icons/computer.svg`
export const concierge_image_s3 = `${doogom_com_image_s3_url}/category_icons/concierge.svg`
export const cooking_image_s3 = `${doogom_com_image_s3_url}/category_icons/cooking.svg`
export const credit_card_heart_image_s3 = `${doogom_com_image_s3_url}/category_icons/credit_card_heart.svg`
export const crib_image_s3 = `${doogom_com_image_s3_url}/category_icons/crib.svg`
export const delete_category_icon_image_s3 = `${doogom_com_image_s3_url}/category_icons/delete_category_icon.svg`
export const dining_image_s3 = `${doogom_com_image_s3_url}/category_icons/dining.svg`
export const directions_bus_image_s3 = `${doogom_com_image_s3_url}/category_icons/directions_bus.svg`
export const distance_image_s3 = `${doogom_com_image_s3_url}/category_icons/distance.svg`
export const door_front_image_s3 = `${doogom_com_image_s3_url}/category_icons/door_front.svg`
export const downhill_skiing_image_s3 = `${doogom_com_image_s3_url}/category_icons/downhill_skiing.svg`
export const edit_image_s3 = `${doogom_com_image_s3_url}/category_icons/edit.svg`
export const enhanced_encryption_image_s3 = `${doogom_com_image_s3_url}/category_icons/enhanced_encryption.svg`
export const exclamation_image_s3 = `${doogom_com_image_s3_url}/category_icons/exclamation.svg`
export const fact_check_image_s3 = `${doogom_com_image_s3_url}/category_icons/fact_check.svg`
export const family_restroom_image_s3 = `${doogom_com_image_s3_url}/category_icons/family_restroom.svg`
export const favorite_image_s3 = `${doogom_com_image_s3_url}/category_icons/favorite.svg`
export const feed_image_s3 = `${doogom_com_image_s3_url}/category_icons/feed.svg`
export const festival_image_s3 = `${doogom_com_image_s3_url}/category_icons/festival.svg`
export const fireplace_image_s3 = `${doogom_com_image_s3_url}/category_icons/fireplace.svg`
export const fitness_center_image_s3 = `${doogom_com_image_s3_url}/category_icons/fitness_center.svg`
export const hail_image_s3 = `${doogom_com_image_s3_url}/category_icons/hail.svg`
export const hand_gesture_image_s3 = `${doogom_com_image_s3_url}/category_icons/hand_gesture.svg`
export const handshake_image_s3 = `${doogom_com_image_s3_url}/category_icons/handshake.svg`
export const help_center_image_s3 = `${doogom_com_image_s3_url}/category_icons/help_center.svg`
export const help_image_s3 = `${doogom_com_image_s3_url}/category_icons/help.svg`
export const hiking_image_s3 = `${doogom_com_image_s3_url}/category_icons/hiking.svg`
export const home_health_image_s3 = `${doogom_com_image_s3_url}/category_icons/home_health.svg`
export const home_image_s3 = `${doogom_com_image_s3_url}/category_icons/home.svg`
export const house_image_s3 = `${doogom_com_image_s3_url}/category_icons/house.svg`
export const import_contacts_image_s3 = `${doogom_com_image_s3_url}/category_icons/import_contacts.svg`
export const info_image_s3 = `${doogom_com_image_s3_url}/category_icons/info.svg`
export const key_image_s3 = `${doogom_com_image_s3_url}/category_icons/key.svg`
export const lightbulb_image_s3 = `${doogom_com_image_s3_url}/category_icons/lightbulb.svg`
export const liquor_image_s3 = `${doogom_com_image_s3_url}/category_icons/liquor.svg`
export const local_cafe_image_s3 = `${doogom_com_image_s3_url}/category_icons/local_cafe.svg`
export const local_dining_image_s3 = `${doogom_com_image_s3_url}/category_icons/local_dining.svg`
export const local_hospital_image_s3 = `${doogom_com_image_s3_url}/category_icons/local_hospital.svg`
export const local_parking_image_s3 = `${doogom_com_image_s3_url}/category_icons/local_parking.svg`
export const location_on_image_s3 = `${doogom_com_image_s3_url}/category_icons/location_on.svg`
export const lock_image_s3 = `${doogom_com_image_s3_url}/category_icons/lock.svg`
export const luggage_image_s3 = `${doogom_com_image_s3_url}/category_icons/luggage.svg`
export const mail_image_s3 = `${doogom_com_image_s3_url}/category_icons/mail.svg`
export const map_image_s3 = `${doogom_com_image_s3_url}/category_icons/map.svg`
export const menu_book_image_s3 = `${doogom_com_image_s3_url}/category_icons/menu_book.svg`
export const nature_people_image_s3 = `${doogom_com_image_s3_url}/category_icons/nature_people.svg`
export const notifications_image_s3 = `${doogom_com_image_s3_url}/category_icons/notifications.svg`
export const park_image_s3 = `${doogom_com_image_s3_url}/category_icons/park.svg`
export const password_image_s3 = `${doogom_com_image_s3_url}/category_icons/password.svg`
export const pedal_bike_image_s3 = `${doogom_com_image_s3_url}/category_icons/pedal_bike.svg`
export const perm_phone_msg_image_s3 = `${doogom_com_image_s3_url}/category_icons/perm_phone_msg.svg`
export const phone_in_talk_image_s3 = `${doogom_com_image_s3_url}/category_icons/phone_in_talk.svg`
export const photo_camera_image_s3 = `${doogom_com_image_s3_url}/category_icons/photo_camera.svg`
export const push_pin_image_s3 = `${doogom_com_image_s3_url}/category_icons/push_pin.svg`
export const recommend_image_s3 = `${doogom_com_image_s3_url}/category_icons/recommend.svg`
export const recycling_image_s3 = `${doogom_com_image_s3_url}/category_icons/recycling.svg`
export const restaurant_image_s3 = `${doogom_com_image_s3_url}/category_icons/restaurant.svg`
export const reviews_image_s3 = `${doogom_com_image_s3_url}/category_icons/reviews.svg`
export const schedule_image_s3 = `${doogom_com_image_s3_url}/category_icons/schedule.svg`
export const sentiment_sad_image_s3 = `${doogom_com_image_s3_url}/category_icons/sentiment_sad.svg`
export const sentiment_satisfied_image_s3 = `${doogom_com_image_s3_url}/category_icons/sentiment_satisfied.svg`
export const settings_image_s3 = `${doogom_com_image_s3_url}/category_icons/settings.svg`
export const shopping_bag_image_s3 = `${doogom_com_image_s3_url}/category_icons/shopping_bag.svg`
export const shopping_cart_image_s3 = `${doogom_com_image_s3_url}/category_icons/shopping_cart.svg`
export const shoppingmode_image_s3 = `${doogom_com_image_s3_url}/category_icons/shoppingmode.svg`
export const shower_image_s3 = `${doogom_com_image_s3_url}/category_icons/shower.svg`
export const signpost_image_s3 = `${doogom_com_image_s3_url}/category_icons/signpost.svg`
export const single_bed_image_s3 = `${doogom_com_image_s3_url}/category_icons/single_bed.svg`
export const skillet_image_s3 = `${doogom_com_image_s3_url}/category_icons/skillet.svg`
export const smartphone_image_s3 = `${doogom_com_image_s3_url}/category_icons/smartphone.svg`
export const star_image_s3 = `${doogom_com_image_s3_url}/category_icons/star.svg`
export const storefront_image_s3 = `${doogom_com_image_s3_url}/category_icons/storefront.svg`
export const support_agent_image_s3 = `${doogom_com_image_s3_url}/category_icons/support_agent.svg`
export const thumb_up_image_s3 = `${doogom_com_image_s3_url}/category_icons/thumb_up.svg`
export const travel_image_s3 = `${doogom_com_image_s3_url}/category_icons/travel.svg`
export const volunteer_activism_image_s3 = `${doogom_com_image_s3_url}/category_icons/volunteer_activism.svg`
export const waving_hand_image_s3 = `${doogom_com_image_s3_url}/category_icons/waving_hand.svg`
export const wifi_password_image_s3 = `${doogom_com_image_s3_url}/category_icons/wifi_password.svg`
export const wifi_image_s3 = `${doogom_com_image_s3_url}/category_icons/wifi.svg`
export const woman_2_image_s3 = `${doogom_com_image_s3_url}/category_icons/woman_2.svg`

export const Activity1Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={activity1}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Activity2Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={activity2}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Activity3Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={activity3}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Activity4Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={activity4}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Activity5Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={activity5}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Activity6Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={activity6}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Activity7Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={activity7}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Activity8Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={activity8}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Activity9Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={activity9}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Activity10Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={activity10}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const BarsPub1Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={bars_pub1}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const BarsPub2Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={bars_pub2}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const BarsPub3Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={bars_pub3}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const BarsPub4Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={bars_pub4}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const BarsPub5Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={bars_pub5}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const BarsPub6Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={bars_pub6}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const BarsPub7Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={bars_pub7}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const BarsPub8Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={bars_pub8}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const BarsPub9Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={bars_pub9}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const BarsPub10Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={bars_pub10}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Cafe1Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={cafe1}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Cafe2Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={cafe2}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Cafe3Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={cafe3}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Cafe4Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={cafe4}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Cafe5Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={cafe5}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Cafe6Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={cafe6}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Cafe7Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={cafe7}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Cafe8Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={cafe8}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Cafe9Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={cafe9}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Cafe10Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={cafe10}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const GettingAround1Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={gettingaround1}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const GettingAround2Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={gettingaround2}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const GettingAround3Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={gettingaround3}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const GettingAround4Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={gettingaround4}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const GettingAround5Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={gettingaround5}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const GettingAround6Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={gettingaround6}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const GettingAround7Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={gettingaround7}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const GettingAround8Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={gettingaround8}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const GettingAround9Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={gettingaround9}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const GettingAround10Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={gettingaround10}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Home1Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={home1}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Home2Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={home2}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Home3Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={home3}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Home4Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={home4}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Home5Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={home5}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Home6Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={home6}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Home7Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={home7}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Home8Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={home8}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Home9Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={home9}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Home10Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={home10}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const MedicalFacility1Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={medicalfacility1}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const MedicalFacility2Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={medicalfacility2}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const MedicalFacility3Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={medicalfacility3}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const MedicalFacility4Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={medicalfacility4}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const MedicalFacility5Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={medicalfacility5}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const MedicalFacility6Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={medicalfacility6}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const MedicalFacility7Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={medicalfacility7}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const MedicalFacility8Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={medicalfacility8}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const MedicalFacility9Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={medicalfacility9}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const MedicalFacility10Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={medicalfacility10}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Restaurant1Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={restaurant1}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Restaurant2Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={restaurant2}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Restaurant3Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={restaurant3}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Restaurant4Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={restaurant4}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Restaurant5Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={restaurant5}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Restaurant6Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={restaurant6}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Restaurant7Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={restaurant7}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Restaurant8Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={restaurant8}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Restaurant9Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={restaurant9}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Restaurant10Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={restaurant10}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Shopping1Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={shopping1}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Shopping2Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={shopping2}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Shopping3Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={shopping3}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Shopping4Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={shopping4}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Shopping5Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={shopping5}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Shopping6Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={shopping6}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Shopping7Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={shopping7}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Shopping8Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={shopping8}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Shopping9Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={shopping9}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Shopping10Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={shopping10}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Supermarket1Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={supermarket1}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Supermarket2Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={supermarket2}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Supermarket3Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={supermarket3}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Supermarket4Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={supermarket4}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Supermarket5Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={supermarket5}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Supermarket6Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={supermarket6}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Supermarket7Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={supermarket7}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Supermarket8Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={supermarket8}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Supermarket9Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={supermarket9}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const Supermarket10Png = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img
        src={supermarket10}
        alt=""
        style={{
          width: '40px',
          height: '40px',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const AirportShuttleSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={airport_shuttle} alt="" />
    </div>
  )
}

export const ApartmentSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={apartment} alt="" />
    </div>
  )
}

export const ArrowCircleUpSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={arrow_circle_up} alt="" />
    </div>
  )
}

export const ArrowForwardSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={arrow_forward} alt="" />
    </div>
  )
}

export const AssistantDirectionSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={assistant_direction} alt="" />
    </div>
  )
}

export const AssistantNavigationSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={assistant_navigation} alt="" />
    </div>
  )
}

export const BeachAccessSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={beach_access} alt="" />
    </div>
  )
}

export const BedroomBabySvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={bedroom_baby} alt="" />
    </div>
  )
}

export const BedtimeSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={bedtime} alt="" />
    </div>
  )
}

export const CallSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={call} alt="" />
    </div>
  )
}

export const CampaignSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={campaign} alt="" />
    </div>
  )
}

export const CampingSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={camping} alt="" />
    </div>
  )
}

export const CancelSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={cancel} alt="" />
    </div>
  )
}

export const ChatBubbleSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={chat_bubble} alt="" />
    </div>
  )
}

export const ChatSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={chat} alt="" />
    </div>
  )
}

export const CheckCircleSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={check_circle} alt="" />
    </div>
  )
}

export const CheckInOutSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={check_in_out} alt="" />
    </div>
  )
}

export const ChecklistSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={checklist} alt="" />
    </div>
  )
}

export const CheerSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={cheer} alt="" />
    </div>
  )
}

export const CleaningServicesSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={cleaning_services} alt="" />
    </div>
  )
}

export const ComputerSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={computer} alt="" />
    </div>
  )
}

export const ConciergeSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={concierge} alt="" />
    </div>
  )
}

export const CookingSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={cooking} alt="" />
    </div>
  )
}

export const CreditCardHeartSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={credit_card_heart} alt="" />
    </div>
  )
}

export const CribSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={crib} alt="" />
    </div>
  )
}

export const DeleteCategoryIconSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={delete_category_icon} alt="" />
    </div>
  )
}

export const DiningSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={dining} alt="" />
    </div>
  )
}

export const DirectionsBusSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={directions_bus} alt="" />
    </div>
  )
}

export const DistanceSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={distance} alt="" />
    </div>
  )
}

export const DoorFrontSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={door_front} alt="" />
    </div>
  )
}

export const DownhillSkiingSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={downhill_skiing} alt="" />
    </div>
  )
}

export const EditSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={edit} alt="" />
    </div>
  )
}

export const EnhancedEncryptionSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={enhanced_encryption} alt="" />
    </div>
  )
}

export const ExclamationSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={exclamation} alt="" />
    </div>
  )
}

export const FactCheckSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={fact_check} alt="" />
    </div>
  )
}

export const FamilyRestroomSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={family_restroom} alt="" />
    </div>
  )
}

export const FavoriteSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={favorite} alt="" />
    </div>
  )
}

export const FeedSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={feed} alt="" />
    </div>
  )
}

export const FestivalSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={festival} alt="" />
    </div>
  )
}

export const FireplaceSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={fireplace} alt="" />
    </div>
  )
}

export const FitnessCenterSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={fitness_center} alt="" />
    </div>
  )
}

export const HailSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={hail} alt="" />
    </div>
  )
}

export const HandGestureSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={hand_gesture} alt="" />
    </div>
  )
}

export const HandshakeSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={handshake} alt="" />
    </div>
  )
}

export const HelpCenterSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={help_center} alt="" />
    </div>
  )
}

export const HelpSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={help} alt="" />
    </div>
  )
}

export const HikingSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={hiking} alt="" />
    </div>
  )
}

export const HomeHealthSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={home_health} alt="" />
    </div>
  )
}

export const HomeSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={home} alt="" />
    </div>
  )
}

export const HouseSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={house} alt="" />
    </div>
  )
}

export const ImportContactsSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={import_contacts} alt="" />
    </div>
  )
}

export const InfoSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={info} alt="" />
    </div>
  )
}

export const KeySvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={key} alt="" />
    </div>
  )
}

export const LightbulbSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={lightbulb} alt="" />
    </div>
  )
}

export const LiquorSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={liquor} alt="" />
    </div>
  )
}

export const LocalCafeSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={local_cafe} alt="" />
    </div>
  )
}

export const LocalDiningSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={local_dining} alt="" />
    </div>
  )
}

export const LocalHospitalSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={local_hospital} alt="" />
    </div>
  )
}

export const LocalParkingSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={local_parking} alt="" />
    </div>
  )
}

export const LocationOnSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={location_on} alt="" />
    </div>
  )
}

export const LockSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={lock} alt="" />
    </div>
  )
}

export const LuggageSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={luggage} alt="" />
    </div>
  )
}

export const MailSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={mail} alt="" />
    </div>
  )
}

export const MapSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={map} alt="" />
    </div>
  )
}

export const MenuBookSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={menu_book} alt="" />
    </div>
  )
}

export const NaturePeopleSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={nature_people} alt="" />
    </div>
  )
}

export const NotificationsSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={notifications} alt="" />
    </div>
  )
}

export const ParkSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={park} alt="" />
    </div>
  )
}

export const PasswordSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={password} alt="" />
    </div>
  )
}

export const PedalBikeSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={pedal_bike} alt="" />
    </div>
  )
}

export const PermPhoneMsgSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={perm_phone_msg} alt="" />
    </div>
  )
}

export const PhoneInTalkSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={phone_in_talk} alt="" />
    </div>
  )
}

export const PhotoCameraSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={photo_camera} alt="" />
    </div>
  )
}

export const PushPinSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={push_pin} alt="" />
    </div>
  )
}

export const RecommendSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={recommend} alt="" />
    </div>
  )
}

export const RecyclingSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={recycling} alt="" />
    </div>
  )
}

export const RestaurantSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={restaurant} alt="" />
    </div>
  )
}

export const ReviewsSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={reviews} alt="" />
    </div>
  )
}

export const ScheduleSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={schedule} alt="" />
    </div>
  )
}

export const SentimentSadSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={sentiment_sad} alt="" />
    </div>
  )
}

export const SentimentSatisfiedSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={sentiment_satisfied} alt="" />
    </div>
  )
}

export const SettingsSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={settings} alt="" />
    </div>
  )
}

export const ShoppingBagSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={shopping_bag} alt="" />
    </div>
  )
}

export const ShoppingCartSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={shopping_cart} alt="" />
    </div>
  )
}

export const ShoppingmodeSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={shoppingmode} alt="" />
    </div>
  )
}

export const ShowerSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={shower} alt="" />
    </div>
  )
}

export const SignpostSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={signpost} alt="" />
    </div>
  )
}

export const SingleBedSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={single_bed} alt="" />
    </div>
  )
}

export const SkilletSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={skillet} alt="" />
    </div>
  )
}

export const SmartphoneSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={smartphone} alt="" />
    </div>
  )
}

export const StarSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={star} alt="" />
    </div>
  )
}

export const StorefrontSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={storefront} alt="" />
    </div>
  )
}

export const SupportAgentSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={support_agent} alt="" />
    </div>
  )
}

export const ThumbUpSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={thumb_up} alt="" />
    </div>
  )
}

export const TravelSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={travel} alt="" />
    </div>
  )
}

export const VolunteerActivismSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={volunteer_activism} alt="" />
    </div>
  )
}

export const WavingHandSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={waving_hand} alt="" />
    </div>
  )
}

export const WifiPasswordSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={wifi_password} alt="" />
    </div>
  )
}

export const WifiSvg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={wifi} alt="" />
    </div>
  )
}

export const Woman_2Svg = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <img src={woman_2} alt="" />
    </div>
  )
}

const StyledIcon = styled(FontAwesomeIcon)`
  color: #000;
  font-weight: 700;
  box-sizing: border-box;
`

export const Logo2x = () => {
  return <Logo2xStyled src={logo_2x} alt={''}></Logo2xStyled>
}

const Logo2xStyled = styled.img`
  width: 160px;
  height: 160px;

  @media (max-width: 768px) {
    width: 130px;
    height: 130px;
  }
`

export const Logo2xVersion2 = () => {
  return <Logo2xStyled2 src={logo_2x} alt={''}></Logo2xStyled2>
}

const Logo2xStyled2 = styled.img`
  width: 150px;
  height: 150px;

  @media (max-width: 768px) {
    width: 120px;
    height: 120px;
  }
`

export const SearchIcon = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <FontAwesomeIcon
        icon={faSearch}
        style={{
          cursor: 'pointer',
          fontWeight: 700,
          color: '#fff',
          boxSizing: 'border-box',
        }}
      />
    </div>
  )
}

export const SearchIconFixed = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <FontAwesomeIcon
        icon={faSearch}
        style={{
          cursor: 'pointer',
          fontWeight: 700,
          boxSizing: 'border-box',
        }}
      />
    </div>
  )
}

export const FolderPlusIcon = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <FontAwesomeIcon
        icon={faFolderPlus}
        style={{
          cursor: 'pointer',
          fontWeight: 700,
          color: '#244556',
          boxSizing: 'border-box',
        }}
      />
    </div>
  )
}

export const TrashIcon = ({ onClick }) => (
  <div onClick={onClick}>
    <FontAwesomeIcon
      icon={faTrash}
      style={{
        cursor: 'pointer',
        fontWeight: 700,
        color: '#244556',
        boxSizing: 'border-box',
      }}
    />
  </div>
)

export const CopyIcon = ({ onClick }) => (
  <div onClick={onClick}>
    <FontAwesomeIcon
      icon={faCopy}
      style={{
        cursor: 'pointer',
        fontWeight: 700,
        color: '#244556',
        boxSizing: 'border-box',
      }}
    />
  </div>
)

export const CopyIconFixed = ({ onClick }) => (
  <div onClick={onClick}>
    <FontAwesomeIcon
      icon={faCopy}
      style={{
        cursor: 'pointer',
        fontWeight: 700,
        boxSizing: 'border-box',
      }}
    />
  </div>
)

export const CircleArrowUpIconFixed = ({ onClick }) => (
  <div onClick={onClick}>
    <FontAwesomeIcon
      icon={faCircleArrowUp}
      style={{
        cursor: 'pointer',
        fontWeight: 700,
        boxSizing: 'border-box',
      }}
    />
  </div>
)

export const CircleArrowDownIconFixed = ({ onClick }) => (
  <div onClick={onClick}>
    <FontAwesomeIcon
      icon={faCircleArrowDown}
      style={{
        cursor: 'pointer',
        fontWeight: 700,
        boxSizing: 'border-box',
      }}
    />
  </div>
)

export const ShareIconFixed = ({ onClick }) => (
  <div onClick={onClick}>
    <FontAwesomeIcon
      icon={faShareAlt}
      style={{
        cursor: 'pointer',
        fontWeight: 700,
        boxSizing: 'border-box',
      }}
    />
  </div>
)

export const PrintIconFixed = ({ onClick }) => (
  <div onClick={onClick}>
    <FontAwesomeIcon
      icon={faPrint}
      style={{
        cursor: 'pointer',
        fontWeight: 700,
        boxSizing: 'border-box',
      }}
    />
  </div>
)

export const SupportAgentIconFixed = ({ onClick }) => (
  <div onClick={onClick}>
    <FontAwesomeIcon
      icon={faPrint}
      style={{
        cursor: 'pointer',
        fontWeight: 700,
        boxSizing: 'border-box',
      }}
    />
  </div>
)

export const ThIconFixed = ({ onClick }) => (
  <div onClick={onClick}>
    <FontAwesomeIcon
      icon={faTh}
      style={{
        cursor: 'pointer',
        fontWeight: 700,
        boxSizing: 'border-box',
      }}
    />
  </div>
)

export const ArrowUpDownIcon = ({ getArrowColor }) => {
  return (
    <div style={{ cursor: 'pointer', fontSize: '10px' }}>
      <div style={{ color: getArrowColor('up'), marginBottom: '-5.5px' }}>
        ▲
      </div>
      <div style={{ color: getArrowColor('down') }}>▼</div>
    </div>
  )
}

export const NotificationIcon = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <FontAwesomeIcon
        icon={faBell}
        style={{
          cursor: 'pointer',
          fontWeight: 700,
          color: '#fff',
          boxSizing: 'border-box',
        }}
      />
    </div>
  )
}

export const MenuIcon = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <FontAwesomeIcon
        icon={faBars}
        style={{
          cursor: 'pointer',
          fontWeight: 700,
          color: '#fff',
          boxSizing: 'border-box',
        }}
      />
    </div>
  )
}

export const XIconFixed = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <FontAwesomeIcon
        icon={faTimes}
        style={{
          cursor: 'pointer',
          fontWeight: 700,
          boxSizing: 'border-box',
        }}
      />
    </div>
  )
}

export const XIcon = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <FontAwesomeIcon
        icon={faTimes}
        style={{
          cursor: 'pointer',
          fontWeight: 700,
          color: '#fff',
          boxSizing: 'border-box',
        }}
      />
    </div>
  )
}

export const XIconHover = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <XIconHoverContainer icon={faTimes} />
    </div>
  )
}

const XIconHoverContainer = styled(FontAwesomeIcon)`
  cursor: pointer;
  font-weight: 700;
  color: #303133;
  transition: color 0.3s;

  &:hover {
    color: #b49275;
  }
`

export const QuestionMarkInCircle = ({ onClick }) => {
  return (
    <Circle onClick={onClick}>
      <QuestionIcon icon={faQuestion} />
    </Circle>
  )
}

const QuestionIcon = styled(FontAwesomeIcon)`
  color: #fff;
  font-size: 0.75rem;
`

const Circle = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #303133;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  cursor: pointer;
`

export const ArrowDownIcon = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <FontAwesomeIcon
        icon={faCaretDown}
        style={{
          cursor: 'pointer',
          fontWeight: 700,
          color: '#244556',
          boxSizing: 'border-box',
        }}
      />
    </div>
  )
}

export const ArrowDownIconWhite = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <FontAwesomeIcon
        icon={faCaretDown}
        style={{
          cursor: 'pointer',
          fontWeight: 700,
          color: '#fff',
          boxSizing: 'border-box',
        }}
      />
    </div>
  )
}

export const ArrowDownBlackIcon = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <FontAwesomeIcon
        icon={faCaretDown}
        style={{
          cursor: 'pointer',
          fontWeight: 700,
          color: '#000',
          boxSizing: 'border-box',
        }}
      />
    </div>
  )
}

export const ArrowUpBlackIcon = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <FontAwesomeIcon
        icon={faCaretUp}
        style={{
          cursor: 'pointer',
          fontWeight: 700,
          color: '#000',
          boxSizing: 'border-box',
        }}
      />
    </div>
  )
}

export const EnvelopeIcon = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <FontAwesomeIcon
        icon={faEnvelope}
        style={{
          cursor: 'pointer',
          fontWeight: 400,
          color: '#848789',
          boxSizing: 'border-box',
          fontSize: '1.5rem',
        }}
      />
    </div>
  )
}

export const EnvelopeIconBlack = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <FontAwesomeIcon
        icon={faEnvelope}
        style={{
          cursor: 'pointer',
          fontWeight: 400,
          color: '#000',
          boxSizing: 'border-box',
        }}
      />
    </div>
  )
}

export const EnvelopeIconFixed = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <FontAwesomeIcon
        icon={faEnvelope}
        style={{
          cursor: 'pointer',
          fontWeight: 400,
          boxSizing: 'border-box',
        }}
      />
    </div>
  )
}

export const PhoneIconFixed = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <FontAwesomeIcon
        icon={faMobileAlt} // 폰 아이콘
        style={{
          cursor: 'pointer',
          fontWeight: 400,
          boxSizing: 'border-box',
        }}
      />
    </div>
  )
}

export const PhoneIcon = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <FontAwesomeIcon
        icon={faMobileAlt} // 폰 아이콘
        style={{
          cursor: 'pointer',
          fontWeight: 700,
          color: '#000',
          boxSizing: 'border-box',
        }}
      />
    </div>
  )
}

export const PaperPlaneIcon = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <FontAwesomeIcon
        icon={faPaperPlane} // 종이 비행기 아이콘
        style={{
          cursor: 'pointer',
          fontWeight: 700,
          color: '#000',
          boxSizing: 'border-box',
        }}
      />
    </div>
  )
}

export const PaperPlaneIconFixed = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <FontAwesomeIcon
        icon={faPaperPlane} // 종이 비행기 아이콘
        style={{
          cursor: 'pointer',
          fontWeight: 700,
          boxSizing: 'border-box',
        }}
      />
    </div>
  )
}

export const ChainIcon = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <FontAwesomeIcon
        icon={faLink} // 체인 아이콘
        style={{
          cursor: 'pointer',
          fontWeight: 700,
          color: '#000',
          boxSizing: 'border-box',
        }}
      />
    </div>
  )
}

export const ChainIconFixed = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <FontAwesomeIcon
        icon={faLink} // 체인 아이콘
        style={{
          cursor: 'pointer',
          fontWeight: 700,
          boxSizing: 'border-box',
        }}
      />
    </div>
  )
}

export const EyeIconFixed = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <FontAwesomeIcon
        icon={faEye}
        style={{
          cursor: 'pointer',
          fontWeight: 700,
          boxSizing: 'border-box',
        }}
      />
    </div>
  )
}

export const HeadsetFixed = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <FontAwesomeIcon
        icon={faHeadset}
        style={{
          cursor: 'pointer',
          fontWeight: 700,
          boxSizing: 'border-box',
        }}
      />
    </div>
  )
}

export const ChainIconGray = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <FontAwesomeIcon
        icon={faLink} // 체인 아이콘
        style={{
          cursor: 'pointer',
          fontWeight: 700,
          color: '#606266',
          boxSizing: 'border-box',
        }}
      />
    </div>
  )
}

export const PaperPlaneIconGray = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <FontAwesomeIcon
        icon={faPaperPlane} // 종이 비행기 아이콘
        style={{
          cursor: 'pointer',
          fontWeight: 700,
          color: '#606266',
          boxSizing: 'border-box',
        }}
      />
    </div>
  )
}

export const ExternalLinkIcon = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <FontAwesomeIcon
        icon={faExternalLinkSquareAlt}
        style={{
          cursor: 'pointer',
          fontWeight: 700,
          color: '#fff',
          boxSizing: 'border-box',
        }}
      />
    </div>
  )
}

export const CameraIcon = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <StyledFontAwesomeIcon icon={faCamera} />
    </div>
  )
}

export const LocationIconFixed = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <FontAwesomeIcon
        icon={faMapMarkerAlt}
        style={{
          cursor: 'pointer',
          fontWeight: 400,
          boxSizing: 'border-box',
        }}
      />
    </div>
  )
}

export const CameraIconFixed = ({ onClick }) => (
  <div onClick={onClick}>
    <FontAwesomeIcon
      icon={faCamera}
      style={{
        cursor: 'pointer',
        fontWeight: 400,
        boxSizing: 'border-box',
      }}
    />
  </div>
)

export const TrashIconFixed = ({ onClick }) => (
  <div onClick={onClick}>
    <FontAwesomeIcon
      icon={faTrash}
      style={{
        cursor: 'pointer',
        fontWeight: 400,
        boxSizing: 'border-box',
      }}
    />
  </div>
)

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  font-family: 'Material Icons Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  box-sizing: border-box;
`

export const VerticalThreeDotsIcon = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <FontAwesomeIcon
        icon={faEllipsisV}
        style={{
          margin: '0 8px 0 auto',
          cursor: 'pointer',
          fontWeight: 700,
          boxSizing: 'border-box',
        }}
      />
    </div>
  )
}

export const ArrowUpIcon = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <FontAwesomeIcon
        icon={faCaretUp}
        style={{
          margin: '0 8px 0 auto',
          cursor: 'pointer',
          fontWeight: 700,
          color: '#fff',
          boxSizing: 'border-box',
        }}
      />
    </div>
  )
}

export const DownloadIcon = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <FontAwesomeIcon
        icon={faDownload}
        style={{
          cursor: 'pointer',
          fontWeight: 700,
          color: '#000',
          boxSizing: 'border-box',
        }}
      />
    </div>
  )
}

export const SettingsIcon = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <FontAwesomeIcon
        icon={faCog}
        style={{
          cursor: 'pointer',
          fontWeight: 700,
          color: '#fff',
          boxSizing: 'border-box',
        }}
      />
    </div>
  )
}

export const PenIcon = ({ onClick }) => (
  <PenIconWrapper onClick={onClick}>
    <StyledIcon icon={faPen} />
  </PenIconWrapper>
)

const PenIconWrapper = styled.div`
  cursor: pointer;
  font-size: 0.675rem;
`

export const PenIconFixed = ({ onClick }) => (
  <div onClick={onClick}>
    <FontAwesomeIcon
      icon={faPen}
      style={{
        cursor: 'pointer',
        fontWeight: 700,
        color: '#244556',
        boxSizing: 'border-box',
      }}
    />
  </div>
)

export const ArrowLeftIcon = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <FontAwesomeIcon
        icon={faArrowLeft}
        style={{
          cursor: 'pointer',
          fontWeight: 700,
          color: '#000',
          boxSizing: 'border-box',
        }}
      />
    </div>
  )
}

export const ArrowLeftIconFixed = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <FontAwesomeIcon
        icon={faArrowLeft}
        style={{
          cursor: 'pointer',
          fontWeight: 700,
          boxSizing: 'border-box',
        }}
      />
    </div>
  )
}

export const ArrowRightIconFixed = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <FontAwesomeIcon
        icon={faArrowRight}
        style={{
          cursor: 'pointer',
          fontWeight: 700,
          boxSizing: 'border-box',
        }}
      />
    </div>
  )
}

export const PlusIcon = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <FontAwesomeIcon
        icon={faPlus}
        style={{
          cursor: 'pointer',
          fontWeight: 700,
          color: '#fff',
          boxSizing: 'border-box',
        }}
      />
    </div>
  )
}

export const MinusCircleIcon = ({ active, onClick }) => {
  return (
    <div onClick={onClick}>
      <FontAwesomeIcon
        icon={faMinusCircle}
        style={{
          color: active ? '#b49275' : '#c0c4cc',
          fontSize: 16,
        }}
      />
    </div>
  )
}

export const PlusCircleIcon = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <FontAwesomeIcon
        icon={faPlusCircle}
        style={{
          color: '#b49275',
          fontSize: 16,
        }}
      />
    </div>
  )
}
