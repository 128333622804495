import styled from 'styled-components'
import IconTextCircleBtn from '../../../design_system/components/btn/pc/icon_text_circle_btn/IconTextCircleBtn'
import {
  AirportShuttleSvg,
  ApartmentSvg,
  ArrowCircleUpSvg,
  ArrowForwardSvg,
  AssistantDirectionSvg,
  AssistantNavigationSvg,
  BeachAccessSvg,
  BedroomBabySvg,
  BedtimeSvg,
  CallSvg,
  CameraIcon,
  CameraIconFixed,
  CampaignSvg,
  CampingSvg,
  CancelSvg,
  ChatBubbleSvg,
  ChatSvg,
  CheckCircleSvg,
  CheckInOutSvg,
  ChecklistSvg,
  CheerSvg,
  CleaningServicesSvg,
  ComputerSvg,
  ConciergeSvg,
  CookingSvg,
  CreditCardHeartSvg,
  CribSvg,
  DeleteCategoryIconSvg,
  DiningSvg,
  DirectionsBusSvg,
  DistanceSvg,
  DoorFrontSvg,
  DownhillSkiingSvg,
  EditSvg,
  EnhancedEncryptionSvg,
  ExclamationSvg,
  FactCheckSvg,
  FamilyRestroomSvg,
  FavoriteSvg,
  FireplaceSvg,
  FitnessCenterSvg,
  HailSvg,
  HandGestureSvg,
  HandshakeSvg,
  HelpCenterSvg,
  HelpSvg,
  HikingSvg,
  HomeHealthSvg,
  HomeSvg,
  HouseSvg,
  ImportContactsSvg,
  InfoSvg,
  KeySvg,
  LightbulbSvg,
  LiquorSvg,
  LocalCafeSvg,
  LocalDiningSvg,
  LocalHospitalSvg,
  LocalParkingSvg,
  LocationIconFixed,
  LocationOnSvg,
  LockSvg,
  LuggageSvg,
  MailSvg,
  MapSvg,
  TrashIconFixed,
  VerticalThreeDotsIcon,
  MenuBookSvg,
  NaturePeopleSvg,
  NotificationsSvg,
  ParkSvg,
  PasswordSvg,
  PedalBikeSvg,
  PermPhoneMsgSvg,
  PhoneInTalkSvg,
  PhotoCameraSvg,
  PushPinSvg,
  RecommendSvg,
  RecyclingSvg,
  RestaurantSvg,
  ReviewsSvg,
  ScheduleSvg,
  SentimentSadSvg,
  SentimentSatisfiedSvg,
  SettingsSvg,
  ShoppingBagSvg,
  ShoppingCartSvg,
  ShoppingmodeSvg,
  ShowerSvg,
  SignpostSvg,
  SingleBedSvg,
  SkilletSvg,
  SmartphoneSvg,
  StarSvg,
  StorefrontSvg,
  SupportAgentSvg,
  ThumbUpSvg,
  TravelSvg,
  VolunteerActivismSvg,
  WavingHandSvg,
  WifiPasswordSvg,
  WifiSvg,
  Woman_2Svg,
  airport_shuttle_image_s3,
  apartment_image_s3,
  arrow_circle_up_image_s3,
  arrow_forward_image_s3,
  assistant_direction_image_s3,
  assistant_navigation_image_s3,
  beach_access_image_s3,
  bedroom_baby_image_s3,
  bedtime_image_s3,
  call_image_s3,
  FeedSvg,
  campaign_image_s3,
  camping_image_s3,
  cancel_image_s3,
  chat_bubble_image_s3,
  chat_image_s3,
  check_circle_image_s3,
  check_in_out_image_s3,
  checklist_image_s3,
  cheer_image_s3,
  cleaning_services_image_s3,
  computer_image_s3,
  concierge_image_s3,
  cooking_image_s3,
  credit_card_heart_image_s3,
  crib_image_s3,
  delete_category_icon_image_s3,
  dining_image_s3,
  directions_bus_image_s3,
  distance_image_s3,
  door_front_image_s3,
  downhill_skiing_image_s3,
  edit_image_s3,
  enhanced_encryption_image_s3,
  FestivalSvg,
  exclamation_image_s3,
  fact_check_image_s3,
  family_restroom_image_s3,
  favorite_image_s3,
  feed_image_s3,
  festival_image_s3,
  fireplace_image_s3,
  fitness_center_image_s3,
  hail_image_s3,
  hand_gesture_image_s3,
  handshake_image_s3,
  help_center_image_s3,
  help_image_s3,
  hiking_image_s3,
  home_health_image_s3,
  home_image_s3,
  house_image_s3,
  import_contacts_image_s3,
  info_image_s3,
  key_image_s3,
  lightbulb_image_s3,
  liquor_image_s3,
  local_cafe_image_s3,
  local_dining_image_s3,
  local_hospital_image_s3,
  local_parking_image_s3,
  location_on_image_s3,
  lock_image_s3,
  luggage_image_s3,
  mail_image_s3,
  map_image_s3,
  menu_book_image_s3,
  nature_people_image_s3,
  notifications_image_s3,
  park_image_s3,
  password_image_s3,
  pedal_bike_image_s3,
  perm_phone_msg_image_s3,
  phone_in_talk_image_s3,
  photo_camera_image_s3,
  push_pin_image_s3,
  recommend_image_s3,
  recycling_image_s3,
  restaurant_image_s3,
  reviews_image_s3,
  schedule_image_s3,
  sentiment_sad_image_s3,
  sentiment_satisfied_image_s3,
  settings_image_s3,
  shopping_bag_image_s3,
  shopping_cart_image_s3,
  shoppingmode_image_s3,
  shower_image_s3,
  signpost_image_s3,
  single_bed_image_s3,
  skillet_image_s3,
  smartphone_image_s3,
  star_image_s3,
  storefront_image_s3,
  support_agent_image_s3,
  thumb_up_image_s3,
  travel_image_s3,
  volunteer_activism_image_s3,
  waving_hand_image_s3,
  wifi_password_image_s3,
  wifi_image_s3,
  woman_2_image_s3,
  QuestionMarkInCircle,
} from '../../../design_system/icons/icons'
import { useEffect, useRef, useState } from 'react'
import NormalModal from '../../../design_system/components/normal_modal/NormalModal'
import { TitleBigDiv } from '../../../design_system/components/title_big_div/TitleBigDiv'
import { TextInput } from '../../../design_system/components/text_input/TextInput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronDown,
  faChevronUp,
  faLevelDownAlt,
  faCamera,
} from '@fortawesome/free-solid-svg-icons'
import { generate_UUID } from '../../../data_system/data_function/generate_UUID'
import IconTooltip from '../../../design_system/components/icon_tooltip/IconTooltip'
import { SettingsItem } from '../../../design_system/components/settings_item/SettingsItem'
import { Dropdown } from '../../../design_system/components/dropdown/Dropdown'
import StandardModal from '../../../design_system/components/standard_modal/StandardModal'
import {
  deleteDefaultCategoryApi,
  deleteDefaultSubcategoryApi,
  deleteDefaultTopicApi,
  deleteImage,
  postDefaultCategory,
  postDefaultSubcategory,
  postDefaultTopic,
  putDefaultCategory,
  putDefaultCategoryOrder,
  putDefaultSubcategory,
  putDefaultSubcategoryOrder,
  putDefaultTopic,
  putDefaultTopicOrder,
  uploadImage,
} from '../../../communication_system/axios_apis/axios_apis'
import { useLoading } from '../../../data_system/context/loading_context/LoadingContext'
import { generate_static_map_url } from '../../../data_system/function/generate_static_map_url'
import { usePopup } from '../../../data_system/context/popup_context/PopupContext'
import { generate_and_download_QR } from '../../../data_system/data_function/generate_and_download_QR'
import { convertToRaw, convertFromRaw, EditorState, Modifier } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { isMatchingDefaultGuideImageURL } from '../../../data_system/data_function/is_matching_default_guide_image_url'

const MainGuidesDefaultGuideContentTab = ({
  defaultGuide,
  defaultCategories,
  setDefaultCategories,
  addDefaultCategoryModalVisible,
  setAddDefaultCategoryModalVisible,
  selectedCategoryIcon,
  setSelectedCategoryIcon,
  setAddLocationModalVisible,
  setEditLocationModalVisible,
  choosePosition,
  setChoosePosition,
  position,
  setPosition,
  defaultTopicPosition,
  setDefaultTopicPosition,
  mapWidth,
  selectedMapPinIcon,
  setSelectedMapPinIcon,
  address,
  setAddress,
}) => {
  const { showLoading, hideLoading } = useLoading()

  const { show } = usePopup()

  //category

  //category data
  const [openDefaultCategories, setOpenDefaultCategories] = useState({})
  const [defaultCategoryTitleText, setDefaultCategoryTitleText] = useState('')
  const [defaultCategory, setDefaultCategory] = useState(null)

  //category modal
  const [editDefaultCategoryModalVisible, setEditDefaultCategoryModalVisible] =
    useState(false)
  const [
    deleteDefaultCategoryModalVisible,
    setDeleteDefaultCategoryModalVisible,
  ] = useState(false)

  //etc
  const [linksAndQRModalVisible, setLinksAndQRModalVisible] = useState(false)

  //subcategory

  //subcategory data
  const [openDefaultSubcategories, setOpenDefaultSubcategories] = useState({})
  const [defaultSubcategoryTitleText, setDefaultSubcategoryTitleText] =
    useState('')
  const [defaultSubcategory, setDefaultSubcategory] = useState(null)

  //subcategory modal
  const [
    addDefaultSubcategoryModalVisible,
    setAddDefaultSubcategoryModalVisible,
  ] = useState(false)
  const [
    editDefaultSubcategoryModalVisible,
    setEditDefaultSubcategoryModalVisible,
  ] = useState(false)
  const [
    deleteDefaultSubcategoryModalVisible,
    setDeleteDefaultSubcategoryModalVisible,
  ] = useState(false)

  //topic

  //topic data
  const [defaultTopicTitleText, setDefaultTopicTitleText] = useState('')
  const [defaultTopic, setDefaultTopic] = useState(null)
  const [imgFile, setImgFile] = useState(null)
  const [imgUrlText, setImgUrlText] = useState('')
  const [uploadImgUrl, setUploadImgUrl] = useState('')

  //topic modal
  const [addDefaultTopicModalVisible, setAddDefaultTopicModalVisible] =
    useState(false)
  const [editDefaultTopicModalVisible, setEditDefaultTopicModalVisible] =
    useState(false)
  const [deleteDefaultTopicModalVisible, setDeleteDefaultTopicModalVisible] =
    useState(false)
  const [addAddMediaModalVisible, setAddAddMediaModalVisible] = useState(false)
  const [addChangeMediaModalVisible, setAddChangeMediaModalVisible] =
    useState(false)
  const [editAddMediaModalVisible, setEditAddMediaModalVisible] =
    useState(false)
  const [editChangeMediaModalVisible, setEditChangeMediaModalVisible] =
    useState(false)
  const [deleteAddMediaModalVisible, setDeleteAddMediaModalVisible] =
    useState(false)
  const [deleteEditMediaModalVisible, setDeleteEditMediaModalVisible] =
    useState(false)
  const [deleteAddLocationModalVisible, setDeleteAddLocationModalVisible] =
    useState(false)
  const [deleteEditLocationModalVisible, setDeleteEditLocationModalVisible] =
    useState(false)

  const [selectedDefaultGuideIndex, setSelectedDefaultGuideIndex] = useState(0)

  const [showSelectCategoryIconPopup, setShowSelectCategoryIconPopup] =
    useState(false)

  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [isComposing, setIsComposing] = useState(false)

  const handleEditorChange = (state) => {
    if (!isComposing) {
      setEditorState(state)
    }
  }

  const handleCompositionStart = () => {
    setIsComposing(true)
  }

  const handleCompositionEnd = () => {
    setIsComposing(false)
    // Force update the editor state to ensure compositions are rendered correctly.
    const currentContent = editorState.getCurrentContent()
    const selection = editorState.getSelection()
    const newText = ' ' // Temporary space to force refresh
    const newContent = Modifier.replaceText(currentContent, selection, newText)
    const newEditorState = EditorState.push(
      editorState,
      newContent,
      'insert-characters'
    )
    setEditorState(EditorState.forceSelection(newEditorState, selection))
  }

  function imageBlockRenderer(contentBlock) {
    const type = contentBlock.getType()

    // Convert image type to mediaComponent
    if (type === 'atomic') {
      return {
        component: MediaComponent,
        editable: false,
        props: {
          foo: 'bar',
        },
      }
    }
  }

  // 이미지가 입력될 경우 img 태그로 변환
  function MediaComponent({ block, contentState, blockProps }) {
    const data = contentState.getEntity(block.getEntityAt(0)).getData()
    const emptyHtml = ' '
    return (
      <div>
        {emptyHtml}
        <img
          src={data.src}
          alt={data.alt || ''}
          style={{ height: data.height || 'auto', width: data.width || 'auto' }}
        />
      </div>
    )
  }

  const fileInputRef = useRef(null)

  const toggleDefaultCategory = (defaultCategoryId) => {
    setOpenDefaultCategories((prev) => ({
      ...prev,
      [defaultCategoryId]: !prev[defaultCategoryId],
    }))
  }

  const toggleDefaultSubcategory = (defaultSubcategoryId) => {
    setOpenDefaultSubcategories((prev) => ({
      ...prev,
      [defaultSubcategoryId]: !prev[defaultSubcategoryId],
    }))
  }

  const addDefaultCategory = (title, newDefaultCategoryId, order) => {
    const newDefaultCategory = {
      id: newDefaultCategoryId,
      title: title,
      order: order,
      defaultSubcategories: [],
    }
    setDefaultCategories([...defaultCategories, newDefaultCategory])
  }

  const editDefaultCategory = (defaultCategoryId, newTitle) => {
    const updatedDefaultCategories = defaultCategories.map(
      (defaultCategory) => {
        if (defaultCategory.id === defaultCategoryId) {
          return {
            ...defaultCategory,
            title: newTitle,
          }
        }
        return defaultCategory
      }
    )
    setDefaultCategories(updatedDefaultCategories)
  }

  const addDefaultSubcategory = (
    defaultCategoryId,
    title,
    newDefaultSubcategoryId,
    order
  ) => {
    const updatedDefaultCategories = defaultCategories.map(
      (defaultCategory) => {
        if (defaultCategory.id === defaultCategoryId) {
          const newDefaultSubcategory = {
            id: newDefaultSubcategoryId,
            title: title,
            order: order,
            defaultTopics: [],
          }
          return {
            ...defaultCategory,
            defaultSubcategories: [
              ...defaultCategory.defaultSubcategories,
              newDefaultSubcategory,
            ],
          }
        }
        return defaultCategory
      }
    )
    setDefaultCategories(updatedDefaultCategories)
  }

  const editDefaultSubcategory = (
    defaultCategoryId,
    defaultSubcategoryId,
    newTitle
  ) => {
    const updatedDefaultCategories = defaultCategories.map(
      (defaultCategory) => {
        if (defaultCategory.id === defaultCategoryId) {
          const updatedDefaultSubcategories =
            defaultCategory.defaultSubcategories.map((defaultSubcategory) => {
              if (defaultSubcategory.id === defaultSubcategoryId) {
                return {
                  ...defaultSubcategory,
                  title: newTitle,
                }
              }
              return defaultSubcategory
            })
          return {
            ...defaultCategory,
            defaultSubcategories: updatedDefaultSubcategories,
          }
        }
        return defaultCategory
      }
    )
    setDefaultCategories(updatedDefaultCategories)
  }

  const addDefaultTopic = (
    defaultCategoryId,
    defaultSubcategoryId,
    defaultTopicTitle,
    defaultTopicContent,
    defaultTopicImgUrl,
    defaultTopicPosition,
    newTopicId,
    mapPinIconUrl,
    order,
    address
  ) => {
    const updatedDefaultCategories = defaultCategories.map(
      (defaultCategory) => {
        if (defaultCategory.id === defaultCategoryId) {
          const updatedDefaultSubcategories =
            defaultCategory.defaultSubcategories.map((defaultSubcategory) => {
              if (defaultSubcategory.id === defaultSubcategoryId) {
                const newDefaultTopic = {
                  id: newTopicId,
                  title: defaultTopicTitle,
                  content: defaultTopicContent,
                  menuVisible: false,
                  imgUrl: defaultTopicImgUrl,
                  latitude: defaultTopicPosition.latitude,
                  longitude: defaultTopicPosition.longitude,
                  mapPinIconUrl: mapPinIconUrl,
                  order: order,
                  address: address,
                }
                return {
                  ...defaultSubcategory,
                  defaultTopics: [
                    ...defaultSubcategory.defaultTopics,
                    newDefaultTopic,
                  ],
                }
              }
              return defaultSubcategory
            })
          return {
            ...defaultCategory,
            defaultSubcategories: updatedDefaultSubcategories,
          }
        }
        return defaultCategory
      }
    )
    setDefaultCategories(updatedDefaultCategories)
  }

  const editDefaultTopic = (
    defaultCategoryId,
    defaultSubcategoryId,
    defaultTopicId,
    defaultTopicTitle,
    defaultTopicContent,
    defaultTopicImgUrl,
    defaultTopicPosition,
    mapPinIconUrl,
    address
  ) => {
    const updatedDefaultCategories = defaultCategories.map(
      (defaultCategory) => {
        if (defaultCategory.id === defaultCategoryId) {
          const updatedDefaultSubcategories =
            defaultCategory.defaultSubcategories.map((defaultSubcategory) => {
              if (defaultSubcategory.id === defaultSubcategoryId) {
                const updatedDefaultTopics =
                  defaultSubcategory.defaultTopics.map((defaultTopic) => {
                    if (defaultTopic.id === defaultTopicId) {
                      return {
                        ...defaultTopic,
                        title: defaultTopicTitle,
                        content: defaultTopicContent,
                        imgUrl: defaultTopicImgUrl,
                        latitude: defaultTopicPosition.latitude,
                        longitude: defaultTopicPosition.longitude,
                        mapPinIconUrl: mapPinIconUrl,
                        address: address,
                      }
                    }
                    return defaultTopic
                  })
                return {
                  ...defaultSubcategory,
                  defaultTopics: updatedDefaultTopics,
                }
              }
              return defaultSubcategory
            })
          return {
            ...defaultCategory,
            defaultSubcategories: updatedDefaultSubcategories,
          }
        }
        return defaultCategory
      }
    )
    setDefaultCategories(updatedDefaultCategories)
  }

  const toggleDefaultCategoryVisibility = (defaultCategoryId) => {
    setDefaultCategories((prevDefaultCategories) =>
      prevDefaultCategories.map((defaultCategory) => {
        if (defaultCategory.id === defaultCategoryId) {
          return {
            ...defaultCategory,
            menuVisible: !defaultCategory.menuVisible,
            defaultSubcategories: defaultCategory.defaultSubcategories.map(
              (defaultSubcategory) => ({
                ...defaultSubcategory,
                menuVisible: false,
                defaultTopics: defaultSubcategory.defaultTopics.map(
                  (defaultTopic) => ({
                    ...defaultTopic,
                    menuVisible: false,
                  })
                ),
              })
            ),
          }
        }
        return {
          ...defaultCategory,
          menuVisible: false,
          defaultSubcategories: defaultCategory.defaultSubcategories.map(
            (defaultSubcategory) => ({
              ...defaultSubcategory,
              menuVisible: false,
              defaultTopics: defaultSubcategory.defaultTopics.map(
                (defaultTopic) => ({
                  ...defaultTopic,
                  menuVisible: false,
                })
              ),
            })
          ),
        }
      })
    )
  }

  const toggleDefaultSubcategoryVisibility = (
    defaultCategoryId,
    defaultSubcategoryId
  ) => {
    setDefaultCategories((prevDefaultCategories) =>
      prevDefaultCategories.map((defaultCategory) => {
        if (defaultCategory.id === defaultCategoryId) {
          return {
            ...defaultCategory,
            menuVisible: false,
            defaultSubcategories: defaultCategory.defaultSubcategories.map(
              (defaultSubcategory) => {
                if (defaultSubcategory.id === defaultSubcategoryId) {
                  return {
                    ...defaultSubcategory,
                    menuVisible: !defaultSubcategory.menuVisible,
                    defaultTopics: defaultSubcategory.defaultTopics.map(
                      (defaultTopic) => ({
                        ...defaultTopic,
                        menuVisible: false,
                      })
                    ),
                  }
                }
                return { ...defaultSubcategory, menuVisible: false }
              }
            ),
          }
        }
        return {
          ...defaultCategory,
          menuVisible: false,
          defaultSubcategories: defaultCategory.defaultSubcategories.map(
            (defaultSubcategory) => ({
              ...defaultSubcategory,
              menuVisible: false,
              defaultTopics: defaultSubcategory.defaultTopics.map(
                (defaultTopic) => ({
                  ...defaultTopic,
                  menuVisible: false,
                })
              ),
            })
          ),
        }
      })
    )
  }

  const toggleDefaultTopicVisibility = (
    defaultCategoryId,
    defaultSubcategoryId,
    defaultTopicId
  ) => {
    setDefaultCategories((prevDefaultCategories) =>
      prevDefaultCategories.map((defaultCategory) =>
        defaultCategory.id === defaultCategoryId
          ? {
              ...defaultCategory,
              menuVisible: false,
              defaultSubcategories: defaultCategory.defaultSubcategories.map(
                (defaultSubcategory) =>
                  defaultSubcategory.id === defaultSubcategoryId
                    ? {
                        ...defaultSubcategory,
                        menuVisible: false,
                        defaultTopics: defaultSubcategory.defaultTopics.map(
                          (defaultTopic) =>
                            defaultTopic.id === defaultTopicId
                              ? {
                                  ...defaultTopic,
                                  menuVisible: !defaultTopic.menuVisible,
                                }
                              : { ...defaultTopic, menuVisible: false }
                        ),
                      }
                    : { ...defaultSubcategory, menuVisible: false }
              ),
            }
          : { ...defaultCategory, menuVisible: false }
      )
    )
  }

  const deleteDefaultCategory = (defaultCategoryIdToDelete) => {
    const updatedDefaultCategories = defaultCategories.filter(
      (defaultCategory) => defaultCategory.id !== defaultCategoryIdToDelete
    )
    setDefaultCategories(updatedDefaultCategories)
  }

  const deleteDefaultSubcategory = (
    defaultCategoryId,
    defaultSubcategoryId
  ) => {
    const updatedDefaultCategories = defaultCategories.map(
      (defaultCategory) => {
        if (defaultCategory.id === defaultCategoryId) {
          const updatedDefaultSubcategories =
            defaultCategory.defaultSubcategories.filter(
              (defaultSubcategory) =>
                defaultSubcategory.id !== defaultSubcategoryId
            )
          return {
            ...defaultCategory,
            defaultSubcategories: updatedDefaultSubcategories,
          }
        }
        return defaultCategory
      }
    )
    setDefaultCategories(updatedDefaultCategories)
  }

  const deleteDefaultTopic = (
    defaultCategoryId,
    defaultSubcategoryId,
    defaultTopicId
  ) => {
    const updatedDefaultCategories = defaultCategories.map(
      (defaultCategory) => {
        if (defaultCategory.id === defaultCategoryId) {
          const updatedDefaultSubcategories =
            defaultCategory.defaultSubcategories.map((defaultSubcategory) => {
              if (defaultSubcategory.id === defaultSubcategoryId) {
                const updatedDefaultTopics =
                  defaultSubcategory.defaultTopics.filter(
                    (defaultTopic) => defaultTopic.id !== defaultTopicId
                  )
                return {
                  ...defaultSubcategory,
                  defaultTopics: updatedDefaultTopics,
                }
              }
              return defaultSubcategory
            })
          return {
            ...defaultCategory,
            defaultSubcategories: updatedDefaultSubcategories,
          }
        }
        return defaultCategory
      }
    )
    setDefaultCategories(updatedDefaultCategories)
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    setImgFile(file)
    console.log(file)
    if (
      file &&
      (file.type === 'image/png' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/jpg')
    ) {
      const localImageUrl = URL.createObjectURL(file)
      console.log(localImageUrl)
      setImgUrlText(localImageUrl)
    } else {
      alert('Please select a valid image (png, jpg, jpeg).')
    }
  }

  const onDragStart = (e, dragId, dragOrder) => {
    const dragData = JSON.stringify({ dragId, dragOrder })
    e.dataTransfer.setData('application/json', dragData)
    e.target.style.opacity = 0.5
  }

  const reorderItemsByOrder = (items, sourceOrder, destinationOrder) => {
    const newItems = items.map((item) => {
      if (item.order === sourceOrder) {
        return { ...item, order: destinationOrder }
      }
      if (item.order === destinationOrder) {
        return { ...item, order: sourceOrder }
      }
      return item
    })
    return newItems.sort((a, b) => a.order - b.order)
  }

  const reorderSubcategories = (
    categories,
    categoryId,
    sourceOrder,
    destinationOrder
  ) => {
    const newCategories = categories.map((category) => {
      if (category.id === categoryId) {
        const newSubcategories = reorderItemsByOrder(
          category.defaultSubcategories,
          sourceOrder,
          destinationOrder
        )
        return { ...category, defaultSubcategories: newSubcategories }
      }
      return category
    })
    return newCategories
  }

  const reorderTopics = (
    categories,
    categoryId,
    subcategoryId,
    sourceOrder,
    destinationOrder
  ) => {
    const newCategories = categories.map((category) => {
      if (category.id === categoryId) {
        const newSubcategories = category.defaultSubcategories.map(
          (subcategory) => {
            if (subcategory.id === subcategoryId) {
              const newTopics = reorderItemsByOrder(
                subcategory.defaultTopics,
                sourceOrder,
                destinationOrder
              )
              return { ...subcategory, defaultTopics: newTopics }
            }
            return subcategory
          }
        )
        return { ...category, defaultSubcategories: newSubcategories }
      }
      return category
    })
    return newCategories
  }

  const onDropDefaultCategory = async (e, dropId, dropOrder) => {
    const dragData = e.dataTransfer.getData('application/json')
    const { dragId, dragOrder } = JSON.parse(dragData)

    showLoading()
    const dragCategoryResponse = await putDefaultCategoryOrder(
      dragId,
      dropOrder
    )

    if (dragCategoryResponse.status === 200) {
      const dropCategoryResponse = await putDefaultCategoryOrder(
        dropId,
        dragOrder
      )

      if (dropCategoryResponse.status === 200) {
        const newDefaultCategories = reorderItemsByOrder(
          defaultCategories,
          dragOrder,
          dropOrder
        )

        setDefaultCategories(newDefaultCategories)

        hideLoading()
      }
    }
  }

  const onDropDefaultSubcategory = async (e, categoryId, dropId, dropOrder) => {
    const dragData = e.dataTransfer.getData('application/json')
    const { dragId, dragOrder } = JSON.parse(dragData)
    showLoading()

    const dragSubcategoryResponse = await putDefaultSubcategoryOrder(
      dragId,
      dropOrder
    )
    console.log(dragSubcategoryResponse)
    if (dragSubcategoryResponse.status === 200) {
      const dropSubcategoryResponse = await putDefaultSubcategoryOrder(
        dropId,
        dragOrder
      )

      if (dropSubcategoryResponse.status === 200) {
        const newCategories = reorderSubcategories(
          defaultCategories,
          categoryId,
          dragOrder,
          dropOrder
        )
        setDefaultCategories(newCategories)

        hideLoading()
      }
    }
  }

  const onDropDefaultTopic = async (
    e,
    categoryId,
    subcategoryId,
    dropId,
    dropOrder
  ) => {
    const dragData = e.dataTransfer.getData('application/json')
    const { dragId, dragOrder } = JSON.parse(dragData)
    console.log(dragId, dragOrder, dropId, dropOrder)

    showLoading()

    const dragTopicResponse = await putDefaultTopicOrder(dragId, dropOrder)

    if (dragTopicResponse.status === 200) {
      const dropTopicResponse = await putDefaultTopicOrder(dropId, dragOrder)

      if (dropTopicResponse.status === 200) {
        const newCategories = reorderTopics(
          defaultCategories,
          categoryId,
          subcategoryId,
          dragOrder,
          dropOrder
        )
        setDefaultCategories(newCategories)

        hideLoading()
      }
    }
  }

  const onDragOver = (e) => {
    e.preventDefault()
  }

  const onDragEnd = (e) => {
    e.target.style.opacity = ''
  }

  const [tooltipVisible, setTooltipVisible] = useState(false)
  const handleTooltip = () => {
    setTooltipVisible(!tooltipVisible)
  }

  return (
    <>
      <DefaultGuideContentBody2>
        <DefaultGuideContentBody2Inner>
          <Container>
            {defaultCategories.map((defaultCategory) => (
              <div key={defaultCategory.id}>
                <AccordionButton
                  onClick={() => toggleDefaultCategory(defaultCategory.id)}
                  open={openDefaultCategories[defaultCategory.id]}
                  draggable
                  onDragStart={(e) => {
                    onDragStart(e, defaultCategory.id, defaultCategory.order)
                  }}
                  onDrop={(e) =>
                    onDropDefaultCategory(
                      e,
                      defaultCategory.id,
                      defaultCategory.order
                    )
                  }
                  onDragOver={onDragOver}
                  onDragEnd={(e) => onDragEnd(e)}
                >
                  <span>{defaultCategory.title}</span>
                  <IconContainer>
                    <FontAwesomeIcon
                      icon={
                        openDefaultCategories[defaultCategory.id]
                          ? faChevronUp
                          : faChevronDown
                      }
                    />
                    <IconTooltip
                      icon={
                        <VerticalThreeDotsIcon
                          onClick={() => {
                            toggleDefaultCategoryVisibility(defaultCategory.id)
                          }}
                        ></VerticalThreeDotsIcon>
                      }
                      visible={defaultCategory.menuVisible}
                      handleClick={() => {
                        toggleDefaultCategoryVisibility(defaultCategory.id)
                      }}
                      tooltipDefaultWidth={180}
                      tooltipChangeWidth={180}
                      tooltipTop={30}
                      tooltipRight={0}
                    >
                      <SettingsItem
                        style={{
                          padding: '10px 20px',
                        }}
                        onClick={(e) => {
                          setDefaultCategory(defaultCategory)
                          setDefaultCategoryTitleText(defaultCategory.title)
                          setSelectedCategoryIcon(defaultCategory.iconUrl)
                          setEditDefaultCategoryModalVisible(true)
                        }}
                      >
                        카테고리 수정하기
                      </SettingsItem>
                      <SettingsItem
                        style={{
                          padding: '10px 20px',
                        }}
                        onClick={(e) => {
                          setDefaultCategory(defaultCategory)
                          setAddDefaultSubcategoryModalVisible(true)
                        }}
                      >
                        서브카테고리 추가하기
                      </SettingsItem>
                      <SettingsItem
                        style={{
                          padding: '10px 20px',
                        }}
                        onClick={(e) => {
                          setDefaultCategory(defaultCategory)
                          setDeleteDefaultCategoryModalVisible(true)
                        }}
                      >
                        모두 삭제하기
                      </SettingsItem>
                    </IconTooltip>
                  </IconContainer>
                </AccordionButton>
                <Panel $isopen={openDefaultCategories[defaultCategory.id]}>
                  {defaultCategory.defaultSubcategories.map(
                    (defaultSubcategory) => (
                      <div key={defaultSubcategory.id}>
                        <DefaultSubcategoryButton
                          onClick={() =>
                            toggleDefaultSubcategory(defaultSubcategory.id)
                          }
                          open={openDefaultSubcategories[defaultSubcategory.id]}
                          draggable
                          onDragStart={(e) => {
                            onDragStart(
                              e,
                              defaultSubcategory.id,
                              defaultSubcategory.order
                            )
                          }}
                          onDrop={(e) =>
                            onDropDefaultSubcategory(
                              e,
                              defaultCategory.id,
                              defaultSubcategory.id,
                              defaultSubcategory.order
                            )
                          }
                          onDragOver={onDragOver}
                          onDragEnd={(e) => onDragEnd(e)}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <RotatedIcon icon={faLevelDownAlt} />
                            <div
                              style={{
                                marginLeft: 12,
                              }}
                            >
                              <span>{defaultSubcategory.title}</span>
                            </div>
                          </div>
                          <IconContainer>
                            <FontAwesomeIcon
                              icon={
                                openDefaultSubcategories[defaultSubcategory.id]
                                  ? faChevronUp
                                  : faChevronDown
                              }
                            />
                            <IconTooltip
                              icon={
                                <VerticalThreeDotsIcon
                                  onClick={() => {
                                    toggleDefaultSubcategoryVisibility(
                                      defaultCategory.id,
                                      defaultSubcategory.id
                                    )
                                  }}
                                ></VerticalThreeDotsIcon>
                              }
                              visible={defaultSubcategory.menuVisible}
                              handleClick={() => {
                                toggleDefaultSubcategoryVisibility(
                                  defaultCategory.id,
                                  defaultSubcategory.id
                                )
                              }}
                              tooltipDefaultWidth={180}
                              tooltipChangeWidth={180}
                              tooltipTop={30}
                              tooltipRight={0}
                            >
                              <SettingsItem
                                style={{
                                  padding: '10px 20px',
                                }}
                                onClick={(e) => {
                                  setDefaultCategory(defaultCategory)
                                  setDefaultSubcategory(defaultSubcategory)
                                  setDefaultSubcategoryTitleText(
                                    defaultSubcategory.title
                                  )
                                  setEditDefaultSubcategoryModalVisible(true)
                                }}
                              >
                                서브카테고리 수정하기
                              </SettingsItem>
                              <SettingsItem
                                style={{
                                  padding: '10px 20px',
                                }}
                                onClick={(e) => {
                                  setDefaultCategory(defaultCategory)
                                  setDefaultSubcategory(defaultSubcategory)
                                  setImgUrlText('')
                                  setUploadImgUrl('')
                                  setImgFile(null)
                                  setChoosePosition(false)
                                  setAddress('')
                                  setSelectedMapPinIcon(null)
                                  setPosition({
                                    latitude: 37.5665,
                                    longitude: 126.978,
                                  })
                                  setDefaultTopicPosition({
                                    latitude: null,
                                    longitude: null,
                                  })
                                  setSelectedMapPinIcon(null)
                                  setAddDefaultTopicModalVisible(true)
                                }}
                              >
                                주제 추가하기
                              </SettingsItem>
                              <SettingsItem
                                style={{
                                  padding: '10px 20px',
                                }}
                                onClick={(e) => {
                                  setDefaultCategory(defaultCategory)
                                  setDefaultSubcategory(defaultSubcategory)
                                  setDeleteDefaultSubcategoryModalVisible(true)
                                }}
                              >
                                모두 삭제하기
                              </SettingsItem>
                            </IconTooltip>
                          </IconContainer>
                        </DefaultSubcategoryButton>
                        <SubPanel
                          $isopen={
                            openDefaultSubcategories[defaultSubcategory.id]
                          }
                        >
                          {defaultSubcategory.defaultTopics.map(
                            (defaultTopic) => (
                              <DefaultTopic
                                key={defaultTopic.id}
                                draggable
                                onDragStart={(e) => {
                                  onDragStart(
                                    e,
                                    defaultTopic.id,
                                    defaultTopic.order
                                  )
                                }}
                                onDrop={(e) =>
                                  onDropDefaultTopic(
                                    e,
                                    defaultCategory.id,
                                    defaultSubcategory.id,
                                    defaultTopic.id,
                                    defaultTopic.order
                                  )
                                }
                                onDragOver={onDragOver}
                                onDragEnd={(e) => onDragEnd(e)}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  {defaultTopic.imgUrl ? (
                                    <img
                                      src={defaultTopic.imgUrl}
                                      alt="Default topic Thumbnail"
                                      style={{
                                        width: 48,
                                        height: 48,
                                        borderRadius: 5,
                                      }}
                                    />
                                  ) : (
                                    <NoImageIconContainer>
                                      <div
                                        style={{
                                          color: '#c0c4cc',
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faCamera} />
                                      </div>
                                    </NoImageIconContainer>
                                  )}
                                  <div
                                    style={{
                                      marginLeft: 12,
                                    }}
                                  >
                                    <RotatedIcon icon={faLevelDownAlt} />
                                  </div>
                                  <div
                                    style={{
                                      marginLeft: 12,
                                    }}
                                  >
                                    <span>{defaultTopic.title}</span>
                                  </div>
                                </div>
                                <IconContainer>
                                  <IconTooltip
                                    icon={
                                      <VerticalThreeDotsIcon
                                        onClick={() => {
                                          toggleDefaultTopicVisibility(
                                            defaultCategory.id,
                                            defaultSubcategory.id,
                                            defaultTopic.id
                                          )
                                        }}
                                      ></VerticalThreeDotsIcon>
                                    }
                                    visible={defaultTopic.menuVisible}
                                    handleClick={() => {
                                      toggleDefaultTopicVisibility(
                                        defaultCategory.id,
                                        defaultSubcategory.id,
                                        defaultTopic.id
                                      )
                                    }}
                                    tooltipDefaultWidth={180}
                                    tooltipChangeWidth={180}
                                    tooltipTop={30}
                                    tooltipRight={0}
                                  >
                                    <SettingsItem
                                      style={{
                                        padding: '10px 20px',
                                      }}
                                      onClick={(e) => {
                                        setDefaultCategory(defaultCategory)
                                        setDefaultSubcategory(
                                          defaultSubcategory
                                        )
                                        setDefaultTopic(defaultTopic)
                                        setDefaultTopicTitleText(
                                          defaultTopic.title
                                        )
                                        const rawContentFromServer = JSON.parse(
                                          defaultTopic.content
                                        )
                                        const contentState =
                                          convertFromRaw(rawContentFromServer)
                                        const newEditorState =
                                          EditorState.createWithContent(
                                            contentState
                                          )
                                        setEditorState(newEditorState)
                                        setImgUrlText(defaultTopic.imgUrl)
                                        setUploadImgUrl(defaultTopic.imgUrl)
                                        setImgFile(null)
                                        console.log(defaultTopic)
                                        setChoosePosition(
                                          defaultTopic.latitude === null ||
                                            defaultTopic.longitude === null
                                            ? false
                                            : true
                                        )
                                        setAddress(defaultTopic.address)
                                        setSelectedMapPinIcon(
                                          defaultTopic.mapPinIconUrl
                                        )
                                        setPosition({
                                          latitude:
                                            defaultTopic.latitude === null
                                              ? 37.5665
                                              : defaultTopic.latitude,
                                          longitude:
                                            defaultTopic.longitude === null
                                              ? 126.978
                                              : defaultTopic.longitude,
                                        })
                                        setDefaultTopicPosition({
                                          latitude:
                                            defaultTopic.latitude === null
                                              ? null
                                              : defaultTopic.latitude,
                                          longitude:
                                            defaultTopic.longitude === null
                                              ? null
                                              : defaultTopic.longitude,
                                        })
                                        setSelectedMapPinIcon(
                                          defaultTopic.mapPinIconUrl
                                        )
                                        setEditDefaultTopicModalVisible(true)
                                      }}
                                    >
                                      주제 수정하기
                                    </SettingsItem>
                                    <SettingsItem
                                      style={{
                                        padding: '10px 20px',
                                      }}
                                      onClick={(e) => {
                                        setDefaultCategory(defaultCategory)
                                        setDefaultSubcategory(
                                          defaultSubcategory
                                        )
                                        setDefaultTopic(defaultTopic)
                                        setDeleteDefaultTopicModalVisible(true)
                                      }}
                                    >
                                      주제 삭제하기
                                    </SettingsItem>
                                  </IconTooltip>
                                </IconContainer>
                              </DefaultTopic>
                            )
                          )}
                          <SmallDashedButton
                            onClick={() => {
                              setDefaultCategory(defaultCategory)
                              setDefaultSubcategory(defaultSubcategory)
                              setImgUrlText('')
                              setUploadImgUrl('')
                              setImgFile(null)
                              setChoosePosition(false)
                              setAddress('')
                              setSelectedMapPinIcon(null)
                              setPosition({
                                latitude: 37.5665,
                                longitude: 126.978,
                              })
                              setDefaultTopicPosition({
                                latitude: null,
                                longitude: null,
                              })
                              setSelectedMapPinIcon(null)
                              setAddDefaultTopicModalVisible(true)
                            }}
                          >
                            주제 추가하기
                          </SmallDashedButton>
                        </SubPanel>
                      </div>
                    )
                  )}
                  <MiddleDashedButton
                    onClick={() => {
                      setDefaultCategory(defaultCategory)
                      setAddDefaultSubcategoryModalVisible(true)
                    }}
                  >
                    서브카테고리 추가하기
                  </MiddleDashedButton>
                </Panel>
              </div>
            ))}
            <BigDashedButton
              onClick={() => {
                setAddDefaultCategoryModalVisible(true)
              }}
            >
              카테고리 추가하기
            </BigDashedButton>
          </Container>
        </DefaultGuideContentBody2Inner>
      </DefaultGuideContentBody2>
      {addDefaultCategoryModalVisible && (
        <NormalModal
          titleText={`카테고리 추가하기`}
          leftBtnVisible={true}
          modalBodyComponent={
            <div>
              <TitleBigDiv>카테고리 이름 *</TitleBigDiv>
              <TextInput
                value={defaultCategoryTitleText}
                onChange={(e) => {
                  setDefaultCategoryTitleText(e.target.value)
                }}
                backgroundColor={'#ffffff'}
              ></TextInput>
              <TitleBigDiv>카테고리 아이콘 *</TitleBigDiv>
              <DefaultCategoryIconSection>
                <DefaultCategoryIconSectionInner>
                  <DefaultCategoryIconSectionInnerBtn
                    onClick={() => {
                      setShowSelectCategoryIconPopup((pre) => !pre)
                    }}
                  >
                    아이콘 선택
                  </DefaultCategoryIconSectionInnerBtn>
                  <DefaultCategoryIconSectionInnerIcon>
                    {selectedCategoryIcon !== null && (
                      <img src={selectedCategoryIcon} alt=""></img>
                    )}
                  </DefaultCategoryIconSectionInnerIcon>
                </DefaultCategoryIconSectionInner>
                {showSelectCategoryIconPopup && (
                  <DefaultCategoryIconSectionPopup>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(airport_shuttle_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <AirportShuttleSvg></AirportShuttleSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(apartment_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ApartmentSvg></ApartmentSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(arrow_circle_up_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ArrowCircleUpSvg></ArrowCircleUpSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(arrow_forward_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ArrowForwardSvg></ArrowForwardSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(assistant_direction_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <AssistantDirectionSvg></AssistantDirectionSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(assistant_navigation_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <AssistantNavigationSvg></AssistantNavigationSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(beach_access_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <BeachAccessSvg></BeachAccessSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(bedroom_baby_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <BedroomBabySvg></BedroomBabySvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(bedtime_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <BedtimeSvg></BedtimeSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(call_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <CallSvg></CallSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(campaign_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <CampaignSvg></CampaignSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(camping_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <CampingSvg></CampingSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(cancel_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <CancelSvg></CancelSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(chat_bubble_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ChatBubbleSvg></ChatBubbleSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(chat_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ChatSvg></ChatSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(check_circle_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <CheckCircleSvg></CheckCircleSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(check_in_out_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <CheckInOutSvg></CheckInOutSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(checklist_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ChecklistSvg></ChecklistSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(cheer_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <CheerSvg></CheerSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(cleaning_services_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <CleaningServicesSvg></CleaningServicesSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(computer_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ComputerSvg></ComputerSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(concierge_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ConciergeSvg></ConciergeSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(cooking_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <CookingSvg></CookingSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(credit_card_heart_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <CreditCardHeartSvg></CreditCardHeartSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(crib_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <CribSvg></CribSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(delete_category_icon_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <DeleteCategoryIconSvg></DeleteCategoryIconSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(dining_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <DiningSvg></DiningSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(directions_bus_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <DirectionsBusSvg></DirectionsBusSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(distance_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <DistanceSvg></DistanceSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(door_front_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <DoorFrontSvg></DoorFrontSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(downhill_skiing_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <DownhillSkiingSvg></DownhillSkiingSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(edit_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <EditSvg></EditSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(enhanced_encryption_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <EnhancedEncryptionSvg></EnhancedEncryptionSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(exclamation_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ExclamationSvg></ExclamationSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(fact_check_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <FactCheckSvg></FactCheckSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(family_restroom_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <FamilyRestroomSvg></FamilyRestroomSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(favorite_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <FavoriteSvg></FavoriteSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(feed_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <FeedSvg></FeedSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(festival_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <FestivalSvg></FestivalSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(fireplace_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <FireplaceSvg></FireplaceSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(fitness_center_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <FitnessCenterSvg></FitnessCenterSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(hail_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <HailSvg></HailSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(hand_gesture_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <HandGestureSvg></HandGestureSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(handshake_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <HandshakeSvg></HandshakeSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(help_center_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <HelpCenterSvg></HelpCenterSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(help_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <HelpSvg></HelpSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(hiking_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <HikingSvg></HikingSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(home_health_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <HomeHealthSvg></HomeHealthSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(home_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <HomeSvg></HomeSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(house_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <HouseSvg></HouseSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(import_contacts_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ImportContactsSvg></ImportContactsSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(info_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <InfoSvg></InfoSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(key_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <KeySvg></KeySvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(lightbulb_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <LightbulbSvg></LightbulbSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(liquor_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <LiquorSvg></LiquorSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(local_cafe_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <LocalCafeSvg></LocalCafeSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(local_dining_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <LocalDiningSvg></LocalDiningSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(local_hospital_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <LocalHospitalSvg></LocalHospitalSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(local_parking_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <LocalParkingSvg></LocalParkingSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(location_on_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <LocationOnSvg></LocationOnSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(lock_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <LockSvg></LockSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(luggage_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <LuggageSvg></LuggageSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(mail_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <MailSvg></MailSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(map_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <MapSvg></MapSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(menu_book_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <MenuBookSvg></MenuBookSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(nature_people_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <NaturePeopleSvg></NaturePeopleSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(notifications_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <NotificationsSvg></NotificationsSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(park_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ParkSvg></ParkSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(password_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <PasswordSvg></PasswordSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(pedal_bike_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <PedalBikeSvg></PedalBikeSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(perm_phone_msg_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <PermPhoneMsgSvg></PermPhoneMsgSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(phone_in_talk_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <PhoneInTalkSvg></PhoneInTalkSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(photo_camera_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <PhotoCameraSvg></PhotoCameraSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(push_pin_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <PushPinSvg></PushPinSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(recommend_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <RecommendSvg></RecommendSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(recycling_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <RecyclingSvg></RecyclingSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(restaurant_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <RestaurantSvg></RestaurantSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(reviews_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ReviewsSvg></ReviewsSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(schedule_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ScheduleSvg></ScheduleSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(sentiment_sad_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <SentimentSadSvg></SentimentSadSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(sentiment_satisfied_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <SentimentSatisfiedSvg></SentimentSatisfiedSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(settings_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <SettingsSvg></SettingsSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(shopping_bag_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ShoppingBagSvg></ShoppingBagSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(shopping_cart_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ShoppingCartSvg></ShoppingCartSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(shoppingmode_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ShoppingmodeSvg></ShoppingmodeSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(shower_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ShowerSvg></ShowerSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(signpost_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <SignpostSvg></SignpostSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(single_bed_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <SingleBedSvg></SingleBedSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(skillet_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <SkilletSvg></SkilletSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(smartphone_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <SmartphoneSvg></SmartphoneSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(star_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <StarSvg></StarSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(storefront_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <StorefrontSvg></StorefrontSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(support_agent_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <SupportAgentSvg></SupportAgentSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(thumb_up_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ThumbUpSvg></ThumbUpSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(travel_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <TravelSvg></TravelSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(volunteer_activism_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <VolunteerActivismSvg></VolunteerActivismSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(waving_hand_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <WavingHandSvg></WavingHandSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(wifi_password_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <WifiPasswordSvg></WifiPasswordSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(wifi_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <WifiSvg></WifiSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(woman_2_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <Woman_2Svg></Woman_2Svg>
                    </DefaultCategoryIconSectionPopupIcon>
                  </DefaultCategoryIconSectionPopup>
                )}
              </DefaultCategoryIconSection>
            </div>
          }
          onRightBtnClick={async () => {
            showLoading()

            if (
              defaultCategoryTitleText !== '' &&
              selectedCategoryIcon !== null &&
              selectedCategoryIcon !== ''
            ) {
              const response = await postDefaultCategory(
                defaultCategoryTitleText,
                selectedCategoryIcon
              )

              console.log(response)
              if (response.status === 201) {
                addDefaultCategory(
                  defaultCategoryTitleText,
                  response.data.newDefaultCategory.id,
                  response.data.newDefaultCategory.order
                )
                setDefaultCategoryTitleText('')
                setSelectedCategoryIcon(null)
                setShowSelectCategoryIconPopup(false)
                setAddDefaultCategoryModalVisible(false)
              }
            }

            hideLoading()
          }}
          onClose={() => {
            setDefaultCategoryTitleText('')
            setSelectedCategoryIcon(null)
            setShowSelectCategoryIconPopup(false)
            setAddDefaultCategoryModalVisible(false)
          }}
          rightBtnText={'저장'}
          rightBtnDisabled={defaultCategoryTitleText === ''}
          questionSection={<p>---</p>}
          questionDefaultWidth={500}
          questionChangeWidth={180}
        ></NormalModal>
      )}
      {editDefaultCategoryModalVisible && (
        <NormalModal
          titleText={`Edit Category`}
          leftBtnVisible={true}
          modalBodyComponent={
            <div>
              <TitleBigDiv>Category title *</TitleBigDiv>
              <TextInput
                value={defaultCategoryTitleText}
                onChange={(e) => {
                  setDefaultCategoryTitleText(e.target.value)
                }}
                backgroundColor={'#ffffff'}
              ></TextInput>
              <TitleBigDiv>카테고리 아이콘 *</TitleBigDiv>
              <DefaultCategoryIconSection>
                <DefaultCategoryIconSectionInner>
                  <DefaultCategoryIconSectionInnerBtn
                    onClick={() => {
                      setShowSelectCategoryIconPopup((pre) => !pre)
                    }}
                  >
                    아이콘 선택
                  </DefaultCategoryIconSectionInnerBtn>
                  <DefaultCategoryIconSectionInnerIcon>
                    {selectedCategoryIcon !== null && (
                      <img src={selectedCategoryIcon} alt=""></img>
                    )}
                  </DefaultCategoryIconSectionInnerIcon>
                </DefaultCategoryIconSectionInner>
                {showSelectCategoryIconPopup && (
                  <DefaultCategoryIconSectionPopup>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(airport_shuttle_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <AirportShuttleSvg></AirportShuttleSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(apartment_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ApartmentSvg></ApartmentSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(arrow_circle_up_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ArrowCircleUpSvg></ArrowCircleUpSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(arrow_forward_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ArrowForwardSvg></ArrowForwardSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(assistant_direction_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <AssistantDirectionSvg></AssistantDirectionSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(assistant_navigation_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <AssistantNavigationSvg></AssistantNavigationSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(beach_access_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <BeachAccessSvg></BeachAccessSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(bedroom_baby_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <BedroomBabySvg></BedroomBabySvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(bedtime_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <BedtimeSvg></BedtimeSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(call_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <CallSvg></CallSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(campaign_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <CampaignSvg></CampaignSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(camping_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <CampingSvg></CampingSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(cancel_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <CancelSvg></CancelSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(chat_bubble_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ChatBubbleSvg></ChatBubbleSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(chat_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ChatSvg></ChatSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(check_circle_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <CheckCircleSvg></CheckCircleSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(check_in_out_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <CheckInOutSvg></CheckInOutSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(checklist_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ChecklistSvg></ChecklistSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(cheer_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <CheerSvg></CheerSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(cleaning_services_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <CleaningServicesSvg></CleaningServicesSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(computer_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ComputerSvg></ComputerSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(concierge_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ConciergeSvg></ConciergeSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(cooking_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <CookingSvg></CookingSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(credit_card_heart_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <CreditCardHeartSvg></CreditCardHeartSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(crib_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <CribSvg></CribSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(delete_category_icon_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <DeleteCategoryIconSvg></DeleteCategoryIconSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(dining_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <DiningSvg></DiningSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(directions_bus_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <DirectionsBusSvg></DirectionsBusSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(distance_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <DistanceSvg></DistanceSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(door_front_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <DoorFrontSvg></DoorFrontSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(downhill_skiing_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <DownhillSkiingSvg></DownhillSkiingSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(edit_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <EditSvg></EditSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(enhanced_encryption_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <EnhancedEncryptionSvg></EnhancedEncryptionSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(exclamation_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ExclamationSvg></ExclamationSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(fact_check_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <FactCheckSvg></FactCheckSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(family_restroom_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <FamilyRestroomSvg></FamilyRestroomSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(favorite_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <FavoriteSvg></FavoriteSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(feed_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <FeedSvg></FeedSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(festival_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <FestivalSvg></FestivalSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(fireplace_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <FireplaceSvg></FireplaceSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(fitness_center_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <FitnessCenterSvg></FitnessCenterSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(hail_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <HailSvg></HailSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(hand_gesture_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <HandGestureSvg></HandGestureSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(handshake_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <HandshakeSvg></HandshakeSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(help_center_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <HelpCenterSvg></HelpCenterSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(help_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <HelpSvg></HelpSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(hiking_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <HikingSvg></HikingSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(home_health_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <HomeHealthSvg></HomeHealthSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(home_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <HomeSvg></HomeSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(house_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <HouseSvg></HouseSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(import_contacts_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ImportContactsSvg></ImportContactsSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(info_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <InfoSvg></InfoSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(key_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <KeySvg></KeySvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(lightbulb_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <LightbulbSvg></LightbulbSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(liquor_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <LiquorSvg></LiquorSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(local_cafe_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <LocalCafeSvg></LocalCafeSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(local_dining_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <LocalDiningSvg></LocalDiningSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(local_hospital_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <LocalHospitalSvg></LocalHospitalSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(local_parking_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <LocalParkingSvg></LocalParkingSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(location_on_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <LocationOnSvg></LocationOnSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(lock_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <LockSvg></LockSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(luggage_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <LuggageSvg></LuggageSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(mail_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <MailSvg></MailSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(map_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <MapSvg></MapSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(menu_book_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <MenuBookSvg></MenuBookSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(nature_people_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <NaturePeopleSvg></NaturePeopleSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(notifications_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <NotificationsSvg></NotificationsSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(park_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ParkSvg></ParkSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(password_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <PasswordSvg></PasswordSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(pedal_bike_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <PedalBikeSvg></PedalBikeSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(perm_phone_msg_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <PermPhoneMsgSvg></PermPhoneMsgSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(phone_in_talk_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <PhoneInTalkSvg></PhoneInTalkSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(photo_camera_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <PhotoCameraSvg></PhotoCameraSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(push_pin_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <PushPinSvg></PushPinSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(recommend_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <RecommendSvg></RecommendSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(recycling_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <RecyclingSvg></RecyclingSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(restaurant_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <RestaurantSvg></RestaurantSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(reviews_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ReviewsSvg></ReviewsSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(schedule_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ScheduleSvg></ScheduleSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(sentiment_sad_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <SentimentSadSvg></SentimentSadSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(sentiment_satisfied_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <SentimentSatisfiedSvg></SentimentSatisfiedSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(settings_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <SettingsSvg></SettingsSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(shopping_bag_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ShoppingBagSvg></ShoppingBagSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(shopping_cart_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ShoppingCartSvg></ShoppingCartSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(shoppingmode_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ShoppingmodeSvg></ShoppingmodeSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(shower_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ShowerSvg></ShowerSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(signpost_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <SignpostSvg></SignpostSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(single_bed_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <SingleBedSvg></SingleBedSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(skillet_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <SkilletSvg></SkilletSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(smartphone_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <SmartphoneSvg></SmartphoneSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(star_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <StarSvg></StarSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(storefront_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <StorefrontSvg></StorefrontSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(support_agent_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <SupportAgentSvg></SupportAgentSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(thumb_up_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ThumbUpSvg></ThumbUpSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(travel_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <TravelSvg></TravelSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(volunteer_activism_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <VolunteerActivismSvg></VolunteerActivismSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(waving_hand_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <WavingHandSvg></WavingHandSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(wifi_password_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <WifiPasswordSvg></WifiPasswordSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(wifi_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <WifiSvg></WifiSvg>
                    </DefaultCategoryIconSectionPopupIcon>
                    <DefaultCategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(woman_2_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <Woman_2Svg></Woman_2Svg>
                    </DefaultCategoryIconSectionPopupIcon>
                  </DefaultCategoryIconSectionPopup>
                )}
              </DefaultCategoryIconSection>
            </div>
          }
          onRightBtnClick={async () => {
            showLoading()

            if (
              defaultCategoryTitleText !== '' &&
              selectedCategoryIcon !== null &&
              selectedCategoryIcon !== ''
            ) {
              const response = await putDefaultCategory(
                defaultCategory.id,
                defaultCategoryTitleText,
                selectedCategoryIcon,
                defaultCategory.order
              )

              if (response.status === 200) {
                editDefaultCategory(
                  defaultCategory.id,
                  defaultCategoryTitleText
                )
                setDefaultCategoryTitleText('')
                setSelectedCategoryIcon(null)
                setShowSelectCategoryIconPopup(false)
                setEditDefaultCategoryModalVisible(false)
              }
            }

            hideLoading()
          }}
          onClose={() => {
            setDefaultCategoryTitleText('')
            setSelectedCategoryIcon(null)
            setShowSelectCategoryIconPopup(false)
            setEditDefaultCategoryModalVisible(false)
          }}
          rightBtnText={'저장'}
          rightBtnDisabled={defaultCategoryTitleText === ''}
          questionSection={<p>---</p>}
          questionDefaultWidth={500}
          questionChangeWidth={180}
        ></NormalModal>
      )}
      {deleteDefaultCategoryModalVisible && (
        <StandardModal
          titleText={'이 카테고리를 삭제 하시겠습니까?'}
          modalBodyComponent={<div>카테고리의 모든 내용이 삭제 됩니다.</div>}
          onRightBtnClick={async () => {
            showLoading()

            const response = await deleteDefaultCategoryApi(defaultCategory.id)

            if (response.status === 200) {
              deleteDefaultCategory(defaultCategory.id)
              setDeleteDefaultCategoryModalVisible(false)
            }

            hideLoading()
          }}
          onClose={() => {
            setDeleteDefaultCategoryModalVisible(false)
          }}
          rightBtnText={'삭제'}
        ></StandardModal>
      )}
      {addDefaultSubcategoryModalVisible && (
        <NormalModal
          titleText={`서브카테고리 추가하기`}
          leftBtnVisible={true}
          modalBodyComponent={
            <div>
              <TitleBigDiv>서브카테고리 이름 *</TitleBigDiv>
              <TextInput
                value={defaultSubcategoryTitleText}
                onChange={(e) => {
                  setDefaultSubcategoryTitleText(e.target.value)
                }}
                backgroundColor={'#ffffff'}
              ></TextInput>
            </div>
          }
          onRightBtnClick={async () => {
            showLoading()

            const response = await postDefaultSubcategory(
              defaultSubcategoryTitleText,
              defaultCategory.id
            )
            console.log(response)
            if (response.status === 201) {
              addDefaultSubcategory(
                defaultCategory.id,
                defaultSubcategoryTitleText,
                response.data.newDefaultSubcategory.id,
                response.data.newDefaultSubcategory.order
              )
              setDefaultSubcategoryTitleText('')
              setAddDefaultSubcategoryModalVisible(false)
            }

            hideLoading()
          }}
          onClose={() => {
            setAddDefaultSubcategoryModalVisible(false)
          }}
          rightBtnText={'저장'}
          rightBtnDisabled={defaultSubcategoryTitleText === ''}
          questionSection={<p>---</p>}
          questionDefaultWidth={500}
          questionChangeWidth={180}
        ></NormalModal>
      )}
      {editDefaultSubcategoryModalVisible && (
        <NormalModal
          titleText={`서브카테고리 수정`}
          leftBtnVisible={true}
          modalBodyComponent={
            <div>
              <TitleBigDiv>서브카테고리 제목 *</TitleBigDiv>
              <TextInput
                value={defaultSubcategoryTitleText}
                onChange={(e) => {
                  setDefaultSubcategoryTitleText(e.target.value)
                }}
                backgroundColor={'#ffffff'}
              ></TextInput>
            </div>
          }
          onRightBtnClick={async () => {
            showLoading()

            const response = await putDefaultSubcategory(
              defaultSubcategoryTitleText,
              defaultSubcategory.order,
              defaultSubcategory.id
            )

            if (response.status === 200) {
              editDefaultSubcategory(
                defaultCategory.id,
                defaultSubcategory.id,
                defaultSubcategoryTitleText
              )
              setDefaultSubcategoryTitleText('')
              setEditDefaultSubcategoryModalVisible(false)
            }

            hideLoading()
          }}
          onClose={() => {
            setDefaultSubcategoryTitleText('')
            setEditDefaultSubcategoryModalVisible(false)
          }}
          rightBtnText={'수정'}
          rightBtnDisabled={defaultSubcategoryTitleText === ''}
          questionSection={<p>---</p>}
          questionDefaultWidth={500}
          questionChangeWidth={180}
        ></NormalModal>
      )}
      {deleteDefaultSubcategoryModalVisible && (
        <StandardModal
          titleText={'이 서브카테고리를 삭제 하시겠습니까?'}
          modalBodyComponent={
            <div>서브카테고리의 모든 내용이 삭제 됩니다.</div>
          }
          onRightBtnClick={async () => {
            showLoading()

            const response = await deleteDefaultSubcategoryApi(
              defaultSubcategory.id
            )

            if (response.status === 200) {
              deleteDefaultSubcategory(
                defaultCategory.id,
                defaultSubcategory.id
              )
              setDeleteDefaultSubcategoryModalVisible(false)
            }

            hideLoading()
          }}
          onClose={() => {
            setDeleteDefaultSubcategoryModalVisible(false)
          }}
          rightBtnText={'삭제'}
        ></StandardModal>
      )}
      {addDefaultTopicModalVisible && (
        <NormalModal
          titleText={`주제 추가하기`}
          leftBtnVisible={true}
          modalBodyComponent={
            <div>
              <TitleBigDiv>제목</TitleBigDiv>
              <TextInput
                value={defaultTopicTitleText}
                onChange={(e) => {
                  setDefaultTopicTitleText(e.target.value)
                }}
                backgroundColor={'#ffffff'}
              ></TextInput>
              <TitleBigDiv>
                내용
                <div
                  style={{
                    marginLeft: 8,
                  }}
                >
                  {' '}
                  <IconTooltip
                    icon={
                      <QuestionMarkInCircle
                        onClick={handleTooltip}
                      ></QuestionMarkInCircle>
                    }
                    handleClick={handleTooltip}
                    visible={tooltipVisible}
                    tooltipTop={2.5}
                    tooltipLeft={30}
                    tooltipDefaultWidth={500}
                    tooltipChangeWidth={350}
                  >
                    <div
                      style={{
                        padding: 20,
                      }}
                    >
                      사진 아이콘을 클릭하여 이미지 URL 등록 시 세로는 auto,
                      가로는 280 이하로 등록해 주세요.
                    </div>
                  </IconTooltip>
                </div>
              </TitleBigDiv>
              <EditorTotalDiv>
                <Editor
                  editorState={editorState}
                  onEditorStateChange={handleEditorChange}
                  onCompositionStart={handleCompositionStart}
                  onCompositionEnd={handleCompositionEnd}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  customBlockRenderFunc={imageBlockRenderer}
                />
              </EditorTotalDiv>
              <TitleBigDiv>대표 이미지</TitleBigDiv>
              {imgUrlText === '' || imgUrlText === null ? (
                <IconTextCircleBtn
                  text={'이미지 첨부'}
                  borderColor={'#ededed'}
                  backgroundColor={'#ededed'}
                  textColor={'#000'}
                  onClick={() => {
                    setAddAddMediaModalVisible(true)
                  }}
                >
                  <CameraIconFixed></CameraIconFixed>
                </IconTextCircleBtn>
              ) : (
                <div
                  style={{
                    height: 200,
                    color: '#d3d3d3',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 5,
                    border: '1px solid #d3d3d3',
                    position: 'relative',
                  }}
                >
                  {imgUrlText ? (
                    <img
                      src={imgUrlText}
                      alt="Media"
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        borderRadius: 5,
                      }}
                    />
                  ) : (
                    <div>No Image</div>
                  )}
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      position: 'absolute',
                      left: 10,
                      bottom: 10,
                    }}
                  >
                    <IconTextCircleBtn
                      text={'수정하기'}
                      borderColor={'#ededed'}
                      backgroundColor={'#ededed'}
                      textColor={'#000'}
                      onClick={() => {
                        setAddChangeMediaModalVisible(true)
                      }}
                    >
                      <CameraIconFixed></CameraIconFixed>
                    </IconTextCircleBtn>
                    <div
                      style={{
                        marginLeft: 10,
                      }}
                    >
                      <IconTextCircleBtn
                        text={'삭제하기'}
                        borderColor={'#f56c6c'}
                        backgroundColor={'#f56c6c'}
                        textColor={'#fff'}
                        onClick={() => {
                          setDeleteAddMediaModalVisible(true)
                        }}
                      >
                        <TrashIconFixed></TrashIconFixed>
                      </IconTextCircleBtn>
                    </div>
                  </div>
                </div>
              )}
              <TitleBigDiv>장소</TitleBigDiv>
              <>
                {choosePosition ? (
                  <>
                    <div
                      className="map-div"
                      style={{
                        backgroundImage: `url(${generate_static_map_url(
                          mapWidth,
                          400,
                          position.latitude,
                          position.longitude
                        )})`,
                        width: '100%',
                        height: '400px',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center center',
                      }}
                    ></div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: 15,
                      }}
                    >
                      <IconTextCircleBtn
                        text={'핀 위치 수정'}
                        borderColor={'#ededed'}
                        backgroundColor={'#ededed'}
                        textColor={'#000'}
                        onClick={() => {
                          setAddLocationModalVisible(true)
                        }}
                      >
                        <LocationIconFixed></LocationIconFixed>
                      </IconTextCircleBtn>
                      <div
                        style={{
                          marginLeft: 10,
                        }}
                      >
                        <IconTextCircleBtn
                          text={'장소 삭제'}
                          borderColor={'#f56c6c'}
                          backgroundColor={'#f56c6c'}
                          textColor={'#fff'}
                          onClick={() => {
                            setDeleteAddLocationModalVisible(true)
                          }}
                        >
                          <TrashIconFixed></TrashIconFixed>
                        </IconTextCircleBtn>
                      </div>
                    </div>
                  </>
                ) : (
                  <IconTextCircleBtn
                    text={'위치 표시'}
                    borderColor={'#ededed'}
                    backgroundColor={'#ededed'}
                    textColor={'#000'}
                    onClick={() => {
                      setAddress('')
                      setSelectedMapPinIcon(null)
                      setAddLocationModalVisible(true)
                    }}
                  >
                    <LocationIconFixed></LocationIconFixed>
                  </IconTextCircleBtn>
                )}
              </>
            </div>
          }
          onRightBtnClick={async () => {
            showLoading()

            const content = editorState.getCurrentContent()
            const rawContent = convertToRaw(content)
            const contentString = JSON.stringify(rawContent)

            const response = await postDefaultTopic(
              defaultTopicTitleText,
              contentString,
              uploadImgUrl,
              defaultTopicPosition.latitude,
              defaultTopicPosition.longitude,
              selectedMapPinIcon,
              defaultSubcategory.id,
              address
            )
            console.log('address', address)

            console.log(response)
            if (response.status === 201) {
              addDefaultTopic(
                defaultCategory.id,
                defaultSubcategory.id,
                defaultTopicTitleText,
                contentString,
                imgUrlText,
                defaultTopicPosition,
                response.data.newDefaultTopic.id,
                selectedMapPinIcon,
                response.data.newDefaultTopic.order,
                address
              )
              setDefaultTopicTitleText('')
              setEditorState(EditorState.createEmpty())
              setImgUrlText('')
              setUploadImgUrl('')
              setImgFile(null)
              setChoosePosition(false)
              setAddress('')
              setSelectedMapPinIcon(null)
              setPosition({
                latitude: 37.5665,
                longitude: 126.978,
              })
              setDefaultTopicPosition({
                latitude: null,
                longitude: null,
              })
              setSelectedMapPinIcon(null)
              setAddDefaultTopicModalVisible(false)
            }

            hideLoading()
          }}
          onClose={async () => {
            if (
              uploadImgUrl !== '' &&
              uploadImgUrl !== null &&
              !isMatchingDefaultGuideImageURL(uploadImgUrl)
            ) {
              showLoading()

              const deleteImageResponse = await deleteImage(uploadImgUrl)

              if (deleteImageResponse.status === 200) {
                setDefaultTopicTitleText('')
                setEditorState(EditorState.createEmpty())
                setImgUrlText('')
                setUploadImgUrl('')
                setImgFile(null)
                setChoosePosition(false)
                setAddress('')
                setSelectedMapPinIcon(null)
                setPosition({
                  latitude: 37.5665,
                  longitude: 126.978,
                })
                setDefaultTopicPosition({
                  latitude: null,
                  longitude: null,
                })
                setSelectedMapPinIcon(null)
                setAddDefaultTopicModalVisible(false)
              }

              hideLoading()
            } else {
              setDefaultTopicTitleText('')
              setEditorState(EditorState.createEmpty())
              setImgUrlText('')
              setUploadImgUrl('')
              setImgFile(null)
              setChoosePosition(false)
              setAddress('')
              setSelectedMapPinIcon(null)
              setPosition({
                latitude: 37.5665,
                longitude: 126.978,
              })
              setDefaultTopicPosition({
                latitude: null,
                longitude: null,
              })
              setSelectedMapPinIcon(null)
              setAddDefaultTopicModalVisible(false)
            }
          }}
          rightBtnText={'저장'}
          rightBtnDisabled={defaultTopicTitleText === ''}
          questionSection={<p>---</p>}
          questionDefaultWidth={500}
          questionChangeWidth={180}
        ></NormalModal>
      )}
      {editDefaultTopicModalVisible && (
        <NormalModal
          titleText={`주제 수정하기`}
          leftBtnVisible={true}
          modalBodyComponent={
            <div>
              <TitleBigDiv>제목</TitleBigDiv>
              <TextInput
                value={defaultTopicTitleText}
                onChange={(e) => {
                  setDefaultTopicTitleText(e.target.value)
                }}
                backgroundColor={'#ffffff'}
              ></TextInput>
              <TitleBigDiv>
                내용{' '}
                <div
                  style={{
                    marginLeft: 8,
                  }}
                >
                  {' '}
                  <IconTooltip
                    icon={
                      <QuestionMarkInCircle
                        onClick={handleTooltip}
                      ></QuestionMarkInCircle>
                    }
                    handleClick={handleTooltip}
                    visible={tooltipVisible}
                    tooltipTop={2.5}
                    tooltipLeft={30}
                    tooltipDefaultWidth={500}
                    tooltipChangeWidth={350}
                  >
                    <div
                      style={{
                        padding: 20,
                      }}
                    >
                      사진 아이콘을 클릭하여 이미지 URL 등록 시 세로는 auto,
                      가로는 280 이하로 등록해 주세요.
                    </div>
                  </IconTooltip>
                </div>
              </TitleBigDiv>
              <EditorTotalDiv>
                <Editor
                  editorState={editorState}
                  onEditorStateChange={handleEditorChange}
                  onCompositionStart={handleCompositionStart}
                  onCompositionEnd={handleCompositionEnd}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  customBlockRenderFunc={imageBlockRenderer}
                />
              </EditorTotalDiv>
              <TitleBigDiv>대표 이미지</TitleBigDiv>
              {imgUrlText === '' || imgUrlText === null ? (
                <IconTextCircleBtn
                  text={'이미지 첨부'}
                  borderColor={'#ededed'}
                  backgroundColor={'#ededed'}
                  textColor={'#000'}
                  onClick={() => {
                    setEditAddMediaModalVisible(true)
                  }}
                >
                  <CameraIconFixed></CameraIconFixed>
                </IconTextCircleBtn>
              ) : (
                <div
                  style={{
                    height: 200,
                    color: '#d3d3d3',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 5,
                    border: '1px solid #d3d3d3',
                    position: 'relative',
                  }}
                >
                  {imgUrlText ? (
                    <img
                      src={imgUrlText}
                      alt="Media"
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        borderRadius: 5,
                      }}
                      onLoad={() => console.log('Image loaded successfully')}
                      onError={() => console.log('Image loading failed')}
                    />
                  ) : (
                    <div>No Image</div>
                  )}
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      position: 'absolute',
                      left: 10,
                      bottom: 10,
                    }}
                  >
                    <IconTextCircleBtn
                      text={'수정하기'}
                      borderColor={'#ededed'}
                      backgroundColor={'#ededed'}
                      textColor={'#000'}
                      onClick={() => {
                        setEditChangeMediaModalVisible(true)
                      }}
                    >
                      <CameraIconFixed></CameraIconFixed>
                    </IconTextCircleBtn>
                    <div
                      style={{
                        marginLeft: 10,
                      }}
                    >
                      <IconTextCircleBtn
                        text={'삭제하기'}
                        borderColor={'#f56c6c'}
                        backgroundColor={'#f56c6c'}
                        textColor={'#fff'}
                        onClick={() => {
                          setDeleteEditMediaModalVisible(true)
                        }}
                      >
                        <TrashIconFixed></TrashIconFixed>
                      </IconTextCircleBtn>
                    </div>
                  </div>
                </div>
              )}
              <TitleBigDiv>장소</TitleBigDiv>
              <>
                {choosePosition ? (
                  <>
                    <div
                      className="map-div"
                      style={{
                        backgroundImage: `url(${generate_static_map_url(
                          mapWidth,
                          400,
                          position.latitude,
                          position.longitude
                        )})`,
                        width: '100%',
                        height: '400px',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center center',
                      }}
                    ></div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: 15,
                      }}
                    >
                      <IconTextCircleBtn
                        text={'핀 위치 수정'}
                        borderColor={'#ededed'}
                        backgroundColor={'#ededed'}
                        textColor={'#000'}
                        onClick={() => {
                          setAddress(defaultTopic.address)
                          setSelectedMapPinIcon(defaultTopic.mapPinIconUrl)
                          setEditLocationModalVisible(true)
                        }}
                      >
                        <LocationIconFixed></LocationIconFixed>
                      </IconTextCircleBtn>
                      <div
                        style={{
                          marginLeft: 10,
                        }}
                      >
                        <IconTextCircleBtn
                          text={'장소 삭제'}
                          borderColor={'#f56c6c'}
                          backgroundColor={'#f56c6c'}
                          textColor={'#fff'}
                          onClick={() => {
                            setDeleteEditLocationModalVisible(true)
                          }}
                        >
                          <TrashIconFixed></TrashIconFixed>
                        </IconTextCircleBtn>
                      </div>
                    </div>
                  </>
                ) : (
                  <IconTextCircleBtn
                    text={'위치 표시'}
                    borderColor={'#ededed'}
                    backgroundColor={'#ededed'}
                    textColor={'#000'}
                    onClick={() => {
                      setAddress('')
                      setSelectedMapPinIcon(null)
                      setEditLocationModalVisible(true)
                    }}
                  >
                    <LocationIconFixed></LocationIconFixed>
                  </IconTextCircleBtn>
                )}
              </>
            </div>
          }
          onRightBtnClick={async () => {
            showLoading()

            const content = editorState.getCurrentContent()
            const rawContent = convertToRaw(content)
            const contentString = JSON.stringify(rawContent)

            console.log(selectedMapPinIcon)
            const response = await putDefaultTopic(
              defaultTopicTitleText,
              contentString,
              uploadImgUrl,
              defaultTopicPosition.latitude,
              defaultTopicPosition.longitude,
              selectedMapPinIcon,
              defaultTopic.order,
              defaultTopic.id,
              address
            )

            console.log('address', address)

            if (response.status === 200) {
              editDefaultTopic(
                defaultCategory.id,
                defaultSubcategory.id,
                defaultTopic.id,
                defaultTopicTitleText,
                contentString,
                imgUrlText,
                defaultTopicPosition,
                selectedMapPinIcon,
                address
              )
              setDefaultTopicTitleText('')
              setEditorState(EditorState.createEmpty())
              setImgUrlText('')
              setUploadImgUrl('')
              setImgFile(null)
              setChoosePosition(false)
              setAddress('')
              setSelectedMapPinIcon(null)
              setPosition({
                latitude: 37.5665,
                longitude: 126.978,
              })
              setDefaultTopicPosition({
                latitude: null,
                longitude: null,
              })
              setSelectedMapPinIcon(null)
              setEditDefaultTopicModalVisible(false)
            }

            hideLoading()
          }}
          onClose={async () => {
            setDefaultTopicTitleText('')
            setEditorState(EditorState.createEmpty())
            setImgUrlText('')
            setUploadImgUrl('')
            setImgFile(null)
            setChoosePosition(false)
            setAddress('')
            setSelectedMapPinIcon(null)
            setPosition({
              latitude: 37.5665,
              longitude: 126.978,
            })
            setDefaultTopicPosition({
              latitude: null,
              longitude: null,
            })
            setSelectedMapPinIcon(null)
            setEditDefaultTopicModalVisible(false)
          }}
          rightBtnText={'저장'}
          rightBtnDisabled={defaultTopicTitleText === ''}
          questionSection={<p>---</p>}
          questionDefaultWidth={500}
          questionChangeWidth={180}
        ></NormalModal>
      )}
      {deleteDefaultTopicModalVisible && (
        <StandardModal
          titleText={'이 주제를 삭제 하시겠습니까?'}
          modalBodyComponent={<div>주제의 모든 내용이 삭제 됩니다.</div>}
          onRightBtnClick={async () => {
            showLoading()

            const response = await deleteDefaultTopicApi(defaultTopic.id)

            if (response.status === 200) {
              deleteDefaultTopic(
                defaultCategory.id,
                defaultSubcategory.id,
                defaultTopic.id
              )
              setDeleteDefaultTopicModalVisible(false)
            }

            hideLoading()
          }}
          onClose={() => {
            setDeleteDefaultTopicModalVisible(false)
          }}
          rightBtnText={'삭제'}
        ></StandardModal>
      )}
      {addAddMediaModalVisible && (
        <NormalModal
          titleText={`이미지 첨부`}
          leftBtnVisible={true}
          modalBodyComponent={
            <div>
              <div
                style={{
                  height: 175,
                  color: '#d3d3d3',
                  backgroundSize: 'cover',
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundPosition: 'center center',
                  backgroundRepeat: 'no-repeat',
                  alignItems: 'center',
                  justifyContent: 'center',
                  boxSizing: 'border-box',
                  backgroundImage: `url(${imgUrlText || 'unknown'})`,
                }}
              >
                {imgUrlText === '' || imgUrlText === null ? (
                  <CameraIcon></CameraIcon>
                ) : (
                  <></>
                )}
              </div>
              <TitleBigDiv>이미지 삽입</TitleBigDiv>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <input
                  type="file"
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  accept=".png, .jpg, .jpeg"
                />
                <IconTextCircleBtn
                  text={'이미지 첨부'}
                  borderColor={'#ededed'}
                  backgroundColor={'#ededed'}
                  textColor={'#000'}
                  onClick={() => {
                    fileInputRef.current.click()
                  }}
                >
                  <CameraIconFixed></CameraIconFixed>
                </IconTextCircleBtn>
              </div>
            </div>
          }
          onRightBtnClick={async () => {
            showLoading()

            const imageUploadResponse = await uploadImage(imgFile)
            console.log(imageUploadResponse)

            if (imageUploadResponse.status === 200) {
              setImgUrlText(imageUploadResponse.data.imageUrl)
              setUploadImgUrl(imageUploadResponse.data.imageUrl)
              setImgFile(null)
              setAddAddMediaModalVisible(false)
            }

            hideLoading()
          }}
          onClose={() => {
            setImgUrlText('')
            setUploadImgUrl('')
            setImgFile(null)
            setAddAddMediaModalVisible(false)
          }}
          rightBtnText={'저장'}
          rightBtnDisabled={false}
          questionSection={<p>---</p>}
          questionDefaultWidth={500}
          questionChangeWidth={180}
        ></NormalModal>
      )}
      {addChangeMediaModalVisible && (
        <NormalModal
          titleText={`수정하기`}
          leftBtnVisible={true}
          modalBodyComponent={
            <div>
              <div
                style={{
                  height: 175,
                  color: '#d3d3d3',
                  backgroundSize: 'cover',
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundPosition: 'center center',
                  backgroundRepeat: 'no-repeat',
                  alignItems: 'center',
                  justifyContent: 'center',
                  boxSizing: 'border-box',
                  backgroundImage: `url(${imgUrlText || 'unknown'})`,
                }}
              >
                {imgUrlText === '' || imgUrlText === null ? (
                  <CameraIcon></CameraIcon>
                ) : (
                  <></>
                )}
              </div>
              <TitleBigDiv>이미지 삽입</TitleBigDiv>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <input
                  type="file"
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  accept=".png, .jpg, .jpeg"
                />
                <IconTextCircleBtn
                  text={'이미지 첨부'}
                  borderColor={'#ededed'}
                  backgroundColor={'#ededed'}
                  textColor={'#000'}
                  onClick={() => {
                    fileInputRef.current.click()
                  }}
                >
                  <CameraIconFixed></CameraIconFixed>
                </IconTextCircleBtn>
              </div>
            </div>
          }
          onRightBtnClick={async () => {
            if (
              imgFile !== null &&
              !isMatchingDefaultGuideImageURL(uploadImgUrl)
            ) {
              showLoading()

              const imageDeleteResponse = await deleteImage(uploadImgUrl)

              if (imageDeleteResponse.status === 200) {
                const imageUploadResponse = await uploadImage(imgFile)
                console.log(imageUploadResponse)

                if (imageUploadResponse.status === 200) {
                  setImgUrlText(imageUploadResponse.data.imageUrl)
                  setUploadImgUrl(imageUploadResponse.data.imageUrl)
                  setImgFile(null)
                  setAddChangeMediaModalVisible(false)
                }
              }

              hideLoading()
            } else {
              showLoading()

              setImgUrlText('')
              setUploadImgUrl('')
              setImgFile(null)
              setAddChangeMediaModalVisible(false)

              hideLoading()
            }
          }}
          onClose={() => {
            setImgUrlText(uploadImgUrl)
            setImgFile(null)
            setAddChangeMediaModalVisible(false)
          }}
          rightBtnText={'저장'}
          rightBtnDisabled={false}
          questionSection={<p>---</p>}
          questionDefaultWidth={500}
          questionChangeWidth={180}
        ></NormalModal>
      )}
      {editAddMediaModalVisible && (
        <NormalModal
          titleText={`이미지 첨부`}
          leftBtnVisible={true}
          modalBodyComponent={
            <div>
              <div
                style={{
                  height: 200,
                  color: '#d3d3d3',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: 5,
                  border: '1px solid #d3d3d3',
                  position: 'relative',
                }}
              >
                {imgUrlText ? (
                  <img
                    src={imgUrlText}
                    alt="Media"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      borderRadius: 5,
                    }}
                  />
                ) : (
                  <CameraIcon></CameraIcon>
                )}
              </div>
              <TitleBigDiv>이미지 삽입</TitleBigDiv>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <input
                  type="file"
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  accept=".png, .jpg, .jpeg"
                />
                <IconTextCircleBtn
                  text={'이미지 첨부'}
                  borderColor={'#ededed'}
                  backgroundColor={'#ededed'}
                  textColor={'#000'}
                  onClick={() => {
                    fileInputRef.current.click()
                  }}
                >
                  <CameraIconFixed></CameraIconFixed>
                </IconTextCircleBtn>
              </div>
            </div>
          }
          onRightBtnClick={async () => {
            showLoading()

            const imageUploadResponse = await uploadImage(imgFile)
            console.log(imageUploadResponse)

            if (imageUploadResponse.status === 200) {
              setImgUrlText(imageUploadResponse.data.imageUrl)
              setUploadImgUrl(imageUploadResponse.data.imageUrl)
              setImgFile(null)
              setEditAddMediaModalVisible(false)
            }

            hideLoading()
          }}
          onClose={() => {
            setImgUrlText('')
            setUploadImgUrl('')
            setImgFile(null)
            setEditAddMediaModalVisible(false)
          }}
          rightBtnText={'저장'}
          rightBtnDisabled={false}
          questionSection={<p>---</p>}
          questionDefaultWidth={500}
          questionChangeWidth={180}
        ></NormalModal>
      )}
      {editChangeMediaModalVisible && (
        <NormalModal
          titleText={`수정하기`}
          leftBtnVisible={true}
          modalBodyComponent={
            <div>
              <div
                style={{
                  height: 200,
                  color: '#d3d3d3',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: 5,
                  border: '1px solid #d3d3d3',
                  position: 'relative',
                }}
              >
                {imgUrlText ? (
                  <img
                    src={imgUrlText}
                    alt="Media"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      borderRadius: 5,
                    }}
                  />
                ) : (
                  <CameraIcon></CameraIcon>
                )}
              </div>
              <TitleBigDiv>이미지 삽입</TitleBigDiv>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <input
                  type="file"
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  accept=".png, .jpg, .jpeg"
                />
                <IconTextCircleBtn
                  text={'이미지 첨부'}
                  borderColor={'#ededed'}
                  backgroundColor={'#ededed'}
                  textColor={'#000'}
                  onClick={() => {
                    fileInputRef.current.click()
                  }}
                >
                  <CameraIconFixed></CameraIconFixed>
                </IconTextCircleBtn>
              </div>
            </div>
          }
          onRightBtnClick={async () => {
            console.log(imgFile)
            console.log(uploadImgUrl)
            console.log(
              `https://doogomimages3.s3.ap-northeast-2.amazonaws.com/default_guide/${imgFile.name}`
            )
            console.log(
              isMatchingDefaultGuideImageURL(
                `https://doogomimages3.s3.ap-northeast-2.amazonaws.com/default_guide/${imgFile.name}`
              )
            )
            if (
              imgFile !== null &&
              !isMatchingDefaultGuideImageURL(
                `https://doogomimages3.s3.ap-northeast-2.amazonaws.com/default_guide/${imgFile.name}`
              )
            ) {
              showLoading()

              const imageUploadResponse = await uploadImage(imgFile)
              console.log(imageUploadResponse)

              if (imageUploadResponse.status === 200) {
                setImgUrlText(imageUploadResponse.data.imageUrl)
                setUploadImgUrl(imageUploadResponse.data.imageUrl)
                setImgFile(null)
                setEditChangeMediaModalVisible(false)
              }

              hideLoading()
            } else {
              showLoading()

              setImgUrlText(uploadImgUrl)
              setImgFile(null)
              setEditChangeMediaModalVisible(false)

              hideLoading()
            }
          }}
          onClose={() => {
            setImgUrlText(uploadImgUrl)
            setImgFile(null)
            setEditChangeMediaModalVisible(false)
          }}
          rightBtnText={'저장'}
          rightBtnDisabled={false}
          questionSection={<p>---</p>}
          questionDefaultWidth={500}
          questionChangeWidth={180}
        ></NormalModal>
      )}
      {deleteAddMediaModalVisible && (
        <StandardModal
          titleText={'이미지 삭제'}
          modalBodyComponent={<div>정말 삭제하겠습니까?</div>}
          onRightBtnClick={async () => {
            showLoading()

            if (!isMatchingDefaultGuideImageURL(uploadImgUrl)) {
              const imageDeleteResponse = await deleteImage(uploadImgUrl)
              console.log(imageDeleteResponse)

              if (imageDeleteResponse.status === 200) {
                setImgUrlText('')
                setUploadImgUrl('')
                setImgFile(null)
                setDeleteAddMediaModalVisible(false)
              }
            }

            hideLoading()
          }}
          onClose={() => {
            setDeleteAddMediaModalVisible(false)
          }}
          rightBtnText={'삭제'}
        ></StandardModal>
      )}
      {deleteEditMediaModalVisible && (
        <StandardModal
          titleText={'이미지 삭제'}
          modalBodyComponent={<div>정말 삭제하겠습니까?</div>}
          onRightBtnClick={async () => {
            showLoading()

            if (!isMatchingDefaultGuideImageURL(uploadImgUrl)) {
              const imageDeleteResponse = await deleteImage(uploadImgUrl)
              console.log(imageDeleteResponse)

              if (imageDeleteResponse.status === 200) {
                setImgUrlText('')
                setUploadImgUrl('')
                setImgFile(null)
                setDeleteEditMediaModalVisible(false)
              }
            }

            hideLoading()
          }}
          onClose={() => {
            setDeleteEditMediaModalVisible(false)
          }}
          rightBtnText={'삭제'}
        ></StandardModal>
      )}
      {deleteAddLocationModalVisible && (
        <StandardModal
          titleText={'장소 삭제'}
          modalBodyComponent={<div>정말 삭제하겠습니까?</div>}
          onRightBtnClick={() => {
            setChoosePosition(false)
            setDefaultTopicPosition({
              latitude: null,
              longitude: null,
            })
            setAddress('')
            setSelectedMapPinIcon(null)
            setDeleteAddLocationModalVisible(false)
          }}
          onClose={() => {
            setDeleteAddLocationModalVisible(false)
          }}
          rightBtnText={'삭제'}
        ></StandardModal>
      )}
      {deleteEditLocationModalVisible && (
        <StandardModal
          titleText={'장소 삭제'}
          modalBodyComponent={<div>정말 삭제하겠습니까?</div>}
          onRightBtnClick={() => {
            setChoosePosition(false)
            setDefaultTopicPosition({
              latitude: null,
              longitude: null,
            })
            setAddress('')
            setSelectedMapPinIcon(null)
            setDeleteEditLocationModalVisible(false)
          }}
          onClose={() => {
            setDeleteEditLocationModalVisible(false)
          }}
          rightBtnText={'삭제'}
        ></StandardModal>
      )}
      {linksAndQRModalVisible && (
        <NormalModal
          titleText={`링크 & QR 얻기`}
          leftBtnVisible={true}
          middleBtnVisible={true}
          modalBodyComponent={
            <div>
              <TitleBigDiv>링크를 복사하거나 QR을 얻으시겠습니까?</TitleBigDiv>
            </div>
          }
          onRightBtnClick={async () => {
            await generate_and_download_QR(defaultGuide.shareUrl)
            setLinksAndQRModalVisible(false)
          }}
          onMiddleBtnClick={async () => {
            try {
              await navigator.clipboard.writeText(`${defaultGuide.shareUrl}`)
              show('링크가 복사되었습니다.')
            } catch (err) {
              console.error('Failed to copy: ', err)
            }
            setLinksAndQRModalVisible(false)
          }}
          onClose={() => {
            setLinksAndQRModalVisible(false)
          }}
          middleBtnText={'링크 복사하기'}
          rightBtnText={'QR 다운로드'}
          questionSection={<p>---</p>}
          questionDefaultWidth={500}
          questionChangeWidth={180}
        ></NormalModal>
      )}
    </>
  )
}

export default MainGuidesDefaultGuideContentTab

const EditorTotalDiv = styled.div`
  .editorClassName {
    height: 250px; /* 높이 설정 */
    overflow: auto; /* 내용이 넘칠 때 스크롤바 자동 생성 */
    border: 1px solid #ddd; /* 에디터 테두리 (선택사항) */
    padding: 10px; /* 내부 여백 (선택사항) */
    border: 1px solid #ccc;
  }

  .rdw-dropdownoption-default.rdw-fontsize-option {
    font-size: 12px;
    width: 30px;
  }

  .rdw-colorpicker-modal-style-label {
    font-size: 12px;
  }
`

const DefaultCategoryIconSection = styled.div`
  margin-bottom: 20px;
  position: relative;
  box-sizing: border-box;
`

const DefaultCategoryIconSectionInner = styled.div`
  align-items: center;
  display: flex;
  box-sizing: border-box;
`

const DefaultCategoryIconSectionInnerBtn = styled.button`
  padding: 8px 16px;
  border-radius: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #000;
  background-color: #ededed;
  border-color: #ededed;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  border: 1px solid #dcdfe6;

  @media (max-width: 1024px) {
    padding: 8px;
    font-size: 10px;
  }
`

const DefaultCategoryIconSectionInnerIcon = styled.div`
  margin-left: 15px;
  font-size: 1.5rem;
  box-sizing: border-box;
  color: #000;
`

const DefaultCategoryIconSectionPopup = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
  background-color: #fff;
  z-index: 10001;
  box-shadow: 0 1px 9px #00000040;
  width: 100%;
  max-height: 300px;
  overflow: auto;
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`

const DefaultCategoryIconSectionPopupIcon = styled.div`
  cursor: pointer;
  padding: 5px;
  transition: background-color 0.1s ease-in-out;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  box-sizing: border-box;
  word-break: break-all;
`

const RotatedIcon = styled(FontAwesomeIcon)`
  transform: rotateX(360deg) rotateY(180deg) rotateZ(90deg);
`

const NoImageIconContainer = styled.div`
  width: 48px;
  height: 48px;
  background-color: #ededed;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

const Container = styled.div`
  margin-top: 10px;
  width: 100%;
  margin-bottom: 10px;
`

const AccordionButton = styled.div`
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  background-color: ${({ open }) => (open ? '#b49275' : '#b49275')};
  color: ${({ open }) => (open ? 'white' : 'white')};
  padding: ${({ open }) => (open ? '20px 10px' : '20px 10px')};
  border-radius: 5px;
  cursor: pointer;
`

const DefaultSubcategoryButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  background-color: ${({ open }) => (open ? 'white' : 'white')};
  color: ${({ open }) => (open ? 'black' : 'black')};
  padding: ${({ open }) => (open ? '15px 10px' : '15px 10px')};
  border: solid 1px #dedede;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
`

const Panel = styled.div`
  display: ${({ $isopen }) => ($isopen ? 'block' : 'none')};
  margin-top: 10px;
  margin-left: 20px;
`

const SubPanel = styled.div`
  display: ${({ $isopen }) => ($isopen ? 'block' : 'none')};
  margin-top: 10px;
  margin-left: 20px;
`

const DefaultTopic = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  background-color: #f8f8f8;
  padding-right: 10px;
  border-radius: 5px;
  margin-top: 10px;
  height: 50px;
  border: solid 1px #dedede;
`

const BigDashedButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #b49275;
  color: #b49275;
  padding: 20px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: #b49275;
    color: white;
  }
`

const MiddleDashedButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #b49275;
  color: #b49275;
  padding: 15px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: #b49275;
    color: white;
  }
`

const SmallDashedButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #b49275;
  color: #b49275;
  padding: 10px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: #b49275;
    color: white;
  }
`

const DefaultGuideContentBody2 = styled.div`
  position: relative;
  padding: 0 10%;
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-basis: auto;
  overflow-y: auto;
  box-sizing: border-box;
  align-items: center;

  @media (max-width: 1024px) {
    padding: 0;
  }
`

const DefaultGuideContentBody2Inner = styled.div`
  position: absolute;
  top: 0;
  width: 80%;
  padding: 0;

  @media (max-width: 1024px) {
    width: 95%;
  }
`
