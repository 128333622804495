import styled from 'styled-components';

export const TableItem = styled.div`
  width: ${(props) => props.$width}%;
  box-sizing: border-box;
  border-bottom: 1px solid #ebeef5;
  padding: 12px;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: left;
  background-color: #fff;
  cursor: ${(props) => props.$cursor};
  display: flex;
  align-items: center;
`;
