import PcMainHeader from '../../design_system/components/header/pc_main_header/PcMainHeader'
import { ChangeBtnA } from '../../design_system/components/btn/pc/change_btn_a/ChangeBtnA'
import {
  c_112b38,
  c_353431,
  c_3ed9cc,
  c_908d88,
  c_b49275,
  c_ddc2ab,
  c_eba55d,
  c_ffcc96,
  c_ffffff,
} from '../../design_system/colors'
import {
  login_inner_router_url,
  privacy_policy_inner_router_url,
  sign_up_inner_router_url,
  terms_and_conditions_inner_router_url,
} from '../../communication_system/inner_router_url/inner_router_url'
import styled from 'styled-components'
import {
  about_us1,
  about_us2,
  about_us3,
  company_intro_middle_image,
  main_background_phone_image,
  review_background1,
  review_background2,
  review_background3,
  three_phone_background_image,
} from '../../design_system/images/images_settings'
import { PenIconFixed } from '../../design_system/icons/icons'
import { Logo2x } from '../../design_system/icons/icons'
import { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import {
  noto_sans_kr_bold,
  noto_sans_kr_regular,
} from '../../design_system/fonts/fonts'

const AboutUsPage = () => {
  const [isPc, setIsPc] = useState(false)

  useEffect(() => {
    if (isMobile) {
      setIsPc(false)
    } else {
      setIsPc(true)
    }
  }, [])

  return (
    <div
      style={{
        height: '100vh',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      <PcMainHeader>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => {
            const baseUrl = window.location.origin
            const url = `${baseUrl}`
            window.open(url, '_self')
          }}
        >
          <Logo2x></Logo2x>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ChangeBtnA
            href={login_inner_router_url}
            beforeBackgroudColor={c_ffffff}
            beforeBorderColor={c_eba55d}
            beforeFontColor={c_353431}
            afterBackgroudColor={c_b49275}
            afterBorderColor={c_b49275}
            afterFontColor={c_ffffff}
          >
            로그인
          </ChangeBtnA>
          <div
            style={{
              marginLeft: 15,
            }}
          >
            <ChangeBtnA
              href={sign_up_inner_router_url}
              beforeBackgroudColor={c_b49275}
              beforeBorderColor={c_b49275}
              beforeFontColor={c_ffffff}
              afterBackgroudColor={c_eba55d}
              afterBorderColor={c_eba55d}
              afterFontColor={c_353431}
            >
              무료 시작
            </ChangeBtnA>
          </div>
        </div>
      </PcMainHeader>
      <BodyContainer>
        <Banner>
          <BannerWrapper>
            <BannerHeading>Welcome</BannerHeading>
            <br></br>
            <br></br>
            <BannerH1>
              <BannerSpan>
                두곰은 희망합니다, 모든 호스트가 행복하기를.
              </BannerSpan>
              <br></br>
              우리 모두는 호스트이자 여행자입니다.
            </BannerH1>
          </BannerWrapper>
        </Banner>
        <Intro>
          <IntroWrapper>
            <IntroP>
              설레는 마음으로 여행을 시작하는 게스트와 그들을 맞이해주는 호스트
              모두가 행복하기를 바랍니다. 호스트와 게스트의 설렘이 지속될 수
              있도록, 두곰이 함께 합니다.
            </IntroP>
          </IntroWrapper>
        </Intro>
        <OurStory>
          <OurStoryRow1>
            <OurStoryRow1Title>
              <OurStoryRow1TitleHeading1>OUR STORY</OurStoryRow1TitleHeading1>
              <br></br>
              <OurStoryRow1TitleHeading2>두곰 이야기</OurStoryRow1TitleHeading2>
              <br></br>
              <OurStoryRow1TitleContent>포옹,</OurStoryRow1TitleContent>
              <OurStoryRow1TitleContent>
                그리고 여행의 시작
              </OurStoryRow1TitleContent>
            </OurStoryRow1Title>
            <OurStoryRow1Content>
              <OurStoryRow1ContentInner>
                <OurStoryRow1ContentInnerP1>
                  몇 년 전 유럽여행을 할 때 였습니다.<br></br>
                  <br></br>공유숙박업 사이트를 통해 예약한 유럽의 작은 나라에
                  위치한 숙소에 체크인하던 날, 숙소 입구에서 처음 만난 호스트는
                  반갑게 웃으며 환영한다는 말과 함께 따뜻하게 안아주었습니다.
                </OurStoryRow1ContentInnerP1>
                <OurStoryRow1ContentInnerP2>
                  숙소 자체는 사실 썩 훌륭한 컨디션은 아니었지만 호스트의 따뜻한
                  미소와 포옹은 숙소 자체의 단점을 모두 커버할 수 있는
                  것이었습니다.<br></br>
                  <br></br>
                  우리는 단순히 수익 창출만을 위해서 이 일을 하는 것은 아닙니다.
                  게스트가 우리 숙소에서 좋은 경험을 하고 갔으면 하는 호스트의
                  바람, 환한 미소, 그리고 따뜻한 포옹.
                </OurStoryRow1ContentInnerP2>
                <OurStoryRow1ContentInnerP3>
                  우리는 호스트의 이런 따뜻한 포옹의 가치를 알고 있습니다.
                  게스트의 여행은 바로 여기서부터 시작됩니다.<br></br>
                  <br></br>
                  게스트를 위한 그 마음을 공유하고, 게스트를 따뜻하게
                  맞아주겠다고 다짐했던 우리의 첫 마음가짐을 기억하며, 그 다짐이
                  흔들리지 않고 지속될 수 있도록 함께 하기 위해 우리의 여정이
                  시작되었습니다.
                </OurStoryRow1ContentInnerP3>
              </OurStoryRow1ContentInner>
            </OurStoryRow1Content>
          </OurStoryRow1>
          <OurStoryRow2>
            <OurStoryRow2Inner>
              <OurStoryRow2InnerP1>
                호스트가 게스트에게 따뜻한 포옹의 가치를 안겨주듯이, 두곰은
                호스트에게 우리의 핵심 가치를 실천합니다.
              </OurStoryRow2InnerP1>
              <OurStoryRow2InnerP2>
                Less work, More profit, Better experience<br></br>두곰이
                함께합니다.
              </OurStoryRow2InnerP2>
            </OurStoryRow2Inner>
          </OurStoryRow2>
        </OurStory>
      </BodyContainer>
    </div>
  )
}

export default AboutUsPage

const BodyContainer = styled.div`
  font-size: 17px;
  font-weight: 600;
  letter-spacing: -0.008em;
  line-height: 1.23536em;
  min-width: 1020px;
  box-sizing: border-box;
  zoom: 1;
  font-family: ${noto_sans_kr_regular};

  @media only screen and (max-width: 1441px) {
    width: 100%;
    min-width: auto !important;
  }

  @media only screen and (max-width: 768px) {
    position: relative;
    overflow-x: hidden;
  }
`

const Banner = styled.div`
  max-width: 100%;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  zoom: 1;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 5.5rem;

  @media only screen and (max-width: 768px) {
    padding-bottom: 3.5rem;
  }
`

const BannerWrapper = styled.div`
  text-align: center;
  width: 100%;
  max-width: 1100px;
  box-sizing: border-box;
  zoom: 1;
  margin: 0 auto;
`

const BannerHeading = styled.span`
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0;
  color: #000;
  line-height: 1.0625em;
  font-family: ${noto_sans_kr_bold};

  @media only screen and (max-width: 767px) {
    line-height: 1.1em;
  }
`

const BannerH1 = styled.h1`
  font-size: 40px;
  font-weight: 700;
  line-height: 1.05em;
  letter-spacing: -0.015em;
  color: #111;
  margin: 30px 0 25px;
  font-family: ${noto_sans_kr_bold};

  @media only screen and (max-width: 1023px) {
    font-size: 40px;
    line-height: 1.0833em;
    letter-spacing: -0.005em;
  }

  @media only screen and (max-width: 768px) {
    margin-top: 20px;
    font-size: 30px;
  }
`

const BannerSpan = styled.span`
  color: #f65a1d;
  box-sizing: border-box;
  zoom: 1;
  font-family: ${noto_sans_kr_bold};
`

const Intro = styled.div`
  background: #faf5f0;
  padding: 5.5rem 0;
  max-width: 100%;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  zoom: 1;
  font-family: ${noto_sans_kr_regular};

  @media only screen and (max-width: 767px) {
    padding: 4.5rem 0;
  }
`

const IntroWrapper = styled.div`
  text-align: center;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  box-sizing: border-box;
  zoom: 1;

  @media only screen and (max-width: 1440px) {
    max-width: 720px;
  }

  @media only screen and (max-width: 1023px) {
    max-width: none;
  }

  @media only screen and (max-width: 767px) {
    width: 92%;
  }
`

const IntroP = styled.p`
  font-size: 20px;
  font-weight: 600;
  line-height: 1.1667em;
  letter-spacing: 0.009em;
  color: rgba(29, 29, 31, 0.8);
  margin: 1em 0;
  padding-left: 20px;
  padding-right: 20px;

  @media only screen and (max-width: 1440px) {
    font-size: 18px;
    line-height: 1.11904em;
    letter-spacing: 0.011em;
  }

  @media only screen and (max-width: 1023px) {
    font-size: 16px;
    line-height: 1.23536em;
    letter-spacing: -0.008em;
  }

  @media only screen and (max-width: 767px) {
    font-size: 14px;
    line-height: 1.2105em;
    letter-spacing: 0;
    text-align: center;
  }
`

const OurStory = styled.div`
  max-width: 100%;
  width: 100%;
  overflow: hidden;
  padding: 1px 0;
  box-sizing: border-box;
  zoom: 1;
  font-family: ${noto_sans_kr_regular};
`

const OurStoryRow1 = styled.div`
  background: #577194;
  display: flex;
  box-sizing: border-box;
  zoom: 1;
  font-family: ${noto_sans_kr_regular};

  @media only screen and (max-width: 767px) {
    flex-wrap: wrap;
  }
`

const OurStoryRow2 = styled.div`
  height: 529px;
  min-height: 529px;
  background: #e8f0f7;
  flex-wrap: wrap;
  display: flex;
  box-sizing: border-box;
  zoom: 1;
  font-family: ${noto_sans_kr_regular};

  @media only screen and (max-width: 1440px) {
    height: 471px;
    min-height: 471px;
  }

  @media only screen and (max-width: 767px) {
    height: auto;
    min-height: initial;
    padding: 50px 0;
  }
`

const OurStoryRow2Inner = styled.div`
  margin: 0 auto;
  width: calc(9 / 12 * 100%);
  max-width: 768px;
  align-self: center;
  box-sizing: border-box;
  zoom: 1;
  font-family: ${noto_sans_kr_bold};
`

const OurStoryRow2InnerP1 = styled.p`
  letter-spacing: 0;
  color: rgba(25, 54, 86, 0.46);
  text-align: center;
  margin-bottom: 55px;
  margin-top: 0;
  font-size: 34px;
  font-weight: 700;
  line-height: 1.1em;

  @media only screen and (max-width: 1440px) {
    font-size: 30px;
    line-height: 1.125em;
    letter-spacing: 0.004em;
  }

  @media only screen and (max-width: 1023px) {
    font-size: 26px;
    line-height: 1.1667em;
    letter-spacing: 0;
  }

  @media only screen and (max-width: 767px) {
    font-size: 22px;
  }
`

const OurStoryRow2InnerP2 = styled.p`
  letter-spacing: 0;
  color: #193656;
  text-align: center;
  margin-bottom: 0;
  margin-top: 0;
  font-size: 34px;
  font-weight: 700;
  line-height: 1.1em;

  @media only screen and (max-width: 1440px) {
    font-size: 30px;
    line-height: 1.125em;
    letter-spacing: 0.004em;
  }

  @media only screen and (max-width: 1023px) {
    font-size: 26px;
    line-height: 1.1667em;
    letter-spacing: 0;
  }

  @media only screen and (max-width: 767px) {
    font-size: 22px;
  }
`

const OurStoryRow1Content = styled.div`
  width: 50%;
  height: 610px;
  box-sizing: border-box;
  zoom: 1;
  font-family: ${noto_sans_kr_regular};
  display: flex;

  @media only screen and (max-width: 767px) {
    order: 2;
    padding: 50px 0;
    width: 100%;
    height: auto;
  }
`

const OurStoryRow1ContentInner = styled.div`
  align-self: center;
  width: 90%;
  max-width: 560px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  zoom: 1;
  font-family: ${noto_sans_kr_regular};

  @media only screen and (max-width: 767px) {
    text-align: center;
  }
`

const OurStoryRow1ContentInnerP1 = styled.p`
  margin-top: 0;
  margin-bottom: 30px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.2105em;
  font-family: ${noto_sans_kr_regular};

  @media only screen and (max-width: 1023px) {
    font-size: 14px;
    line-height: 1.23536em;
    letter-spacing: -0.008em;
  }
`

const OurStoryRow1ContentInnerP2 = styled.p`
  margin-top: 1em;
  margin-bottom: 30px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.2105em;
  font-family: ${noto_sans_kr_regular};

  @media only screen and (max-width: 1023px) {
    font-size: 14px;
    line-height: 1.23536em;
    letter-spacing: -0.008em;
  }
`

const OurStoryRow1ContentInnerP3 = styled.p`
  margin-top: 1em;
  margin-bottom: 0px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.2105em;
  font-family: ${noto_sans_kr_regular};

  @media only screen and (max-width: 1023px) {
    font-size: 14px;
    line-height: 1.23536em;
    letter-spacing: -0.008em;
  }
`

const OurStoryRow1Title = styled.div`
  background: url(${company_intro_middle_image}) left center no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 50%;
  height: 610px;
  box-sizing: border-box;
  zoom: 1;
  font-family: ${noto_sans_kr_regular};

  @media only screen and (max-width: 767px) {
    width: 100%;
    height: auto;
    padding: 50px 0;
  }
`

const OurStoryRow1TitleHeading1 = styled.div`
  align-self: center;
  margin: 0 auto;
  color: #fff;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: -0.009em;
  line-height: 1.0625em;
  box-sizing: border-box;
  zoom: 1;
  font-family: ${noto_sans_kr_bold};

  @media only screen and (max-width: 1440px) {
    font-size: 28px;
    line-height: 1.0714em;
    letter-spacing: -0.02em;
  }

  @media only screen and (max-width: 1023px) {
    font-size: 24px;
    line-height: 1.0833em;
    letter-spacing: -0.003em;
  }

  @media only screen and (max-width: 767px) {
    font-size: 20px;
    line-height: 1.1em;
    letter-spacing: 0;
  }
`

const OurStoryRow1TitleHeading2 = styled.div`
  align-self: center;
  margin: 0 auto;
  color: #fff;
  font-size: 56px;
  font-weight: 700;
  letter-spacing: -0.009em;
  line-height: 1.0625em;
  box-sizing: border-box;
  zoom: 1;
  font-family: ${noto_sans_kr_bold};

  @media only screen and (max-width: 1440px) {
    font-size: 48px;
    line-height: 1.0714em;
    letter-spacing: -0.02em;
  }

  @media only screen and (max-width: 1023px) {
    font-size: 40px;
    line-height: 1.0833em;
    letter-spacing: -0.003em;
  }

  @media only screen and (max-width: 767px) {
    font-size: 32px;
    line-height: 1.1em;
    letter-spacing: 0;
  }
`

const OurStoryRow1TitleContent = styled.div`
  align-self: center;
  margin: 0 auto;
  color: #fff;
  font-size: 44px;
  font-weight: 700;
  letter-spacing: -0.009em;
  line-height: 1.0625em;
  box-sizing: border-box;
  zoom: 1;
  font-family: ${noto_sans_kr_bold};

  @media only screen and (max-width: 1440px) {
    font-size: 36px;
    line-height: 1.0714em;
    letter-spacing: -0.02em;
  }

  @media only screen and (max-width: 1023px) {
    font-size: 28px;
    line-height: 1.0833em;
    letter-spacing: -0.003em;
  }

  @media only screen and (max-width: 767px) {
    font-size: 20px;
    line-height: 1.1em;
    letter-spacing: 0;
  }
`
