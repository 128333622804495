import styled from 'styled-components'

export const ChangeBtnA = ({
  beforeBackgroudColor,
  beforeBorderColor,
  beforeFontColor,
  afterBackgroudColor,
  afterBorderColor,
  afterFontColor,
  children,
  href,
}) => {
  return (
    <ChangeBtnStyled
      href={href}
      $beforeBackgroudColor={beforeBackgroudColor}
      $beforeBorderColor={beforeBorderColor}
      $beforeFontColor={beforeFontColor}
      $afterBackgroudColor={afterBackgroudColor}
      $afterBorderColor={afterBorderColor}
      $afterFontColor={afterFontColor}
    >
      {children}
    </ChangeBtnStyled>
  )
}

const ChangeBtnStyled = styled.a`
  position: relative;
  padding: 7.5px 12.5px;
  border-radius: 12px;
  font-family: 'paybooc-Light', sans-serif;
  text-decoration: none;
  font-weight: 600;
  transition: 0.25s;
  background-color: ${(props) => props.$beforeBackgroudColor};
  border: 3px solid ${(props) => props.$beforeBorderColor};
  color: ${(props) => props.$beforeFontColor};
  box-sizing: border-box;

  &:hover {
    background-color: ${(props) => props.$afterBackgroudColor};
    border: 3px solid ${(props) => props.$afterBorderColor};
    color: ${(props) => props.$afterFontColor};
  }

  @media (min-width: 1020px) {
    padding: 10px 15px;
  }
`
