import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import {
  ArrowLeftIconFixed,
  ArrowRightIconFixed,
  CircleArrowDownIconFixed,
  CircleArrowUpIconFixed,
  HeadsetFixed,
  LocationIconFixed,
  MinusCircleIcon,
  PaperPlaneIconFixed,
  PhoneIconFixed,
  PlusCircleIcon,
  SearchIconFixed,
  ShareIconFixed,
  XIconFixed,
} from '../../design_system/icons/icons'
import { c_eba55d, c_ffffff } from '../../design_system/colors'
import { SmallChangeBtn } from '../../design_system/components/btn/pc/small_change_btn/SmallChangeBtn'
import { GoogleMap, MarkerF, InfoWindow } from '@react-google-maps/api'
import { generate_UUID } from '../../data_system/data_function/generate_UUID'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { useParams } from 'react-router-dom'
import {
  getShowGuideDecodeToken,
  postInternationalPaymentAuth,
  postShowGuideStorePayment,
} from '../../communication_system/axios_apis/axios_apis'
import { isMobile } from 'react-device-detect'
import { useLoading } from '../../data_system/context/loading_context/LoadingContext'
import { usePopup } from '../../data_system/context/popup_context/PopupContext'
import { convertFromRaw, EditorState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { OpacityBtn } from '../../design_system/components/btn/pc/opacity_btn/OpacityBtn'
import { priceFormatFunction } from '../../data_system/data_function/price_format_function'
import StandardModal from '../../design_system/components/standard_modal/StandardModal'
import { image_1330 } from '../../design_system/images/images_settings'
import { useGoogleMaps } from '../../data_system/context/google_maps_context/GoogleMapsContext'
import { formatPhoneNumber } from '../../data_system/data_function/format_phone_number'
import { isExpired } from '../../data_system/data_function/is_expired'
import { koreanPhoneNumberCheck } from '../../data_system/data_function/korean_phone_number_check_function'
import { emailCheck } from '../../data_system/data_function/email_check_function'
import NormalModal from '../../design_system/components/normal_modal/NormalModal'
import { TitleDiv } from '../../design_system/components/title_div/TitleDiv'
import { TextInput } from '../../design_system/components/text_input/TextInput'
import {
  store_privacy_policy_inner_router_url,
  store_terms_and_conditions_inner_router_url,
} from '../../communication_system/inner_router_url/inner_router_url'
import IndexToggleBtn from '../../design_system/components/btn/pc/index_toggle_btn/IndexToggleBtn'
import CheckBox from '../../design_system/components/check_box/CheckBox'
import { doogom_com_server_router_url } from '../../communication_system/communication_router_url/communication_router_url'

const ShowGuidePage = () => {
  const { isLoaded, loadError } = useGoogleMaps()
  const { show } = usePopup()
  const { showLoading, hideLoading, isLoading } = useLoading()
  const { token } = useParams()

  const [tabIndex, setTabIndex] = useState(0)

  const [tabList, setTabList] = useState([
    {
      id: 0,
      title: 'HOME',
    },
    {
      id: 1,
      title: 'INFO',
    },
    {
      id: 3,
      title: 'MAP',
    },
    {
      id: 4,
      title: 'SEARCH',
    },
  ])

  const [showMoveTo1330Modal, setShowMoveTo1330Modal] = useState(false)

  const [showMobileInfoDetail, setShowMobileInfoDetail] = useState(false)
  const [showMobileSubcategoriesModal, setShowMobileSubcategoriesModal] =
    useState(false)

  const [selectedMarker, setSelectedMarker] = useState(null)

  const [map, setMap] = useState(null)

  const onLoad = useCallback(function callback(map) {
    map.setZoom(zoom)

    setMap(map)
  }, [])

  const onUnmount = useCallback(function callback(map) {
    setMap(null)
  }, [])

  const [mapError, setMapError] = useState(false)

  const [zoom, setZoom] = useState(15)

  const mapRef = useRef()
  const [position, setPosition] = useState({
    latitude: 37.5665,
    longitude: 126.978,
  })
  const [center, setCenter] = useState({
    lat: 37.5665,
    lng: 126.978,
  })
  const tileRefs = useRef([])

  const onMarkerDragEnd = (event) => {
    setPosition({
      latitude: event.latLng.lat(),
      longitude: event.latLng.lng(),
    })
  }
  const [userProducts, setUserProducts] = useState([])

  const [categories, setCategories] = useState([
    {
      id: generate_UUID(),
      title: 'Sample Category',
      menuVisible: false,
      subcategories: [
        {
          id: generate_UUID(),
          title: 'Sample Subcategory 1',
          menuVisible: false,
          topics: [
            {
              id: generate_UUID(),
              title: 'Sample Topic 1',
              content: 'a',
              menuVisible: false,
              imgUrl: '',
              latitude: null,
              longitude: null,
              mapPinIconUrl: null,
            },
            {
              id: generate_UUID(),
              title: 'Sample Topic 2',
              content: 'a',
              menuVisible: false,
              imgUrl: '',
              latitude: null,
              longitude: null,
              mapPinIconUrl: null,
            },
          ],
        },
        {
          id: generate_UUID(),
          title: 'Sample Subcategory 2',
          menuVisible: false,
          topics: [],
        },
      ],
    },
  ])
  const [guide, setGuide] = useState(null)
  const [user, setUser] = useState(null)

  const [isPc, setIsPc] = useState(false)

  const [selectCategoryId, setSelectCategoryId] = useState(null)
  const [selectCategory, setSelectCategory] = useState(null)

  const [selectSubcategoryId, setSelectSubcategoryId] = useState(null)

  const toggleCategoryVisibility = (categoryId) => {
    setSelectCategoryId(categoryId)
    setCategories((prevCategories) =>
      prevCategories.map((category) => {
        if (category.id === categoryId) {
          const updatedCategory = {
            ...category,
            menuVisible: !category.menuVisible,
            subcategories: category.subcategories.map((subcategory, index) => {
              const updatedSubcategory = {
                ...subcategory,
                menuVisible: index === 0,
                topics: subcategory.topics.map((topic) => ({
                  ...topic,
                  menuVisible: false, // 모든 topic의 menuVisible을 false로 설정
                })),
              }

              if (index === 0) {
                setSelectSubcategoryId(updatedSubcategory.id)
              }

              return updatedSubcategory
            }),
          }
          return updatedCategory
        }
        return {
          ...category,
          menuVisible: false,
        }
      })
    )
  }

  const toggleSubcategoryVisibility = (categoryId, subcategoryId) => {
    setSelectCategoryId(categoryId)
    setCategories((prevCategories) =>
      prevCategories.map((category) => {
        if (category.id === categoryId) {
          const updatedSubcategories = category.subcategories.map(
            (subcategory) => {
              const updatedTopics = subcategory.topics.map((topic) => ({
                ...topic,
                menuVisible: false, // 모든 topic의 menuVisible을 false로 설정
              }))

              if (subcategory.id === subcategoryId) {
                setSelectSubcategoryId(subcategory.id)
                return {
                  ...subcategory,
                  menuVisible: true,
                  topics: updatedTopics,
                }
              } else {
                return {
                  ...subcategory,
                  menuVisible: false,
                  topics: updatedTopics,
                }
              }
            }
          )

          return {
            ...category,
            subcategories: updatedSubcategories,
          }
        }
        return category
      })
    )
  }

  const toggleTopicVisibility = (categoryId, subcategoryId, topicId) => {
    setCategories((prevCategories) =>
      prevCategories.map((category) => {
        if (category.id === categoryId) {
          const updatedSubcategories = category.subcategories.map(
            (subcategory) => {
              if (subcategory.id === subcategoryId) {
                const updatedTopics = subcategory.topics.map((topic) => {
                  if (topic.id === topicId) {
                    return {
                      ...topic,
                      menuVisible: !topic.menuVisible,
                    }
                  }
                  return topic
                })
                return {
                  ...subcategory,
                  topics: updatedTopics,
                }
              }
              return subcategory
            }
          )

          return {
            ...category,
            subcategories: updatedSubcategories,
          }
        }
        return category
      })
    )
  }

  const toggleTopicVisibilityOnlyOpen = (
    categoryId,
    subcategoryId,
    topicId
  ) => {
    setCategories((prevCategories) =>
      prevCategories.map((category) => {
        if (category.id === categoryId) {
          const updatedSubcategories = category.subcategories.map(
            (subcategory) => {
              if (subcategory.id === subcategoryId) {
                const updatedTopics = subcategory.topics.map((topic) => {
                  return {
                    ...topic,
                    menuVisible: topic.id === topicId, // 선택한 토픽만 true, 나머지는 false
                  }
                })
                return {
                  ...subcategory,
                  topics: updatedTopics,
                }
              }
              return subcategory
            }
          )

          return {
            ...category,
            subcategories: updatedSubcategories,
          }
        }
        return category
      })
    )
  }

  const openAllTopicsInSubcategory = (categoryId, subcategoryId) => {
    setCategories((prevCategories) =>
      prevCategories.map((category) => {
        if (category.id === categoryId) {
          const updatedSubcategories = category.subcategories.map(
            (subcategory) => {
              if (subcategory.id === subcategoryId) {
                const updatedTopics = subcategory.topics.map((topic) => ({
                  ...topic,
                  menuVisible: true, // 모든 토픽을 열기
                }))
                return {
                  ...subcategory,
                  topics: updatedTopics,
                }
              }
              return subcategory
            }
          )

          return {
            ...category,
            subcategories: updatedSubcategories,
          }
        }
        return category
      })
    )
  }

  const closeAllTopicsInSubcategory = (categoryId, subcategoryId) => {
    setCategories((prevCategories) =>
      prevCategories.map((category) => {
        if (category.id === categoryId) {
          const updatedSubcategories = category.subcategories.map(
            (subcategory) => {
              if (subcategory.id === subcategoryId) {
                const updatedTopics = subcategory.topics.map((topic) => ({
                  ...topic,
                  menuVisible: false, // 모든 토픽을 닫기
                }))
                return {
                  ...subcategory,
                  topics: updatedTopics,
                }
              }
              return subcategory
            }
          )

          return {
            ...category,
            subcategories: updatedSubcategories,
          }
        }
        return category
      })
    )
  }

  const toggleUserProductVisibility = (userProductId) => {
    setUserProducts((prevUserProducts) =>
      prevUserProducts.map((userProduct) => {
        if (userProduct.id === userProductId) {
          return {
            ...userProduct,
            menuVisible: !userProduct.menuVisible, // 'visible' 속성 토글
          }
        }
        return userProduct
      })
    )
  }

  const [searchText, setSearchText] = useState('')

  const [subcategoriesFoundByTitle, setSubcategoriesFoundByTitle] = useState([])
  const [subcategoriesFoundByContent, setSubcategoriesFoundByContent] =
    useState([])

  const searchSubcategories = (searchTerm) => {
    const tempSubcategoriesFoundByTitle = []
    const tempSubcategoriesFoundByContent = []

    categories.forEach((category) => {
      category.subcategories.forEach((subcategory) => {
        if (
          subcategory.title.toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          tempSubcategoriesFoundByTitle.push({
            categoryId: category.id,
            subcategoryId: subcategory.id,
            title: subcategory.title,
          })
        }

        subcategory.topics.forEach((topic) => {
          if (topic.content.toLowerCase().includes(searchTerm.toLowerCase())) {
            const foundByContentIndex =
              tempSubcategoriesFoundByContent.findIndex(
                (item) =>
                  item.categoryId === category.id &&
                  item.subcategoryId === subcategory.id
              )

            if (foundByContentIndex === -1) {
              tempSubcategoriesFoundByContent.push({
                categoryId: category.id,
                subcategoryId: subcategory.id,
                title: subcategory.title,
              })
            }
          }
        })
      })
    })

    return {
      tempSubcategoriesFoundByTitle,
      tempSubcategoriesFoundByContent,
    }
  }

  const openSubcategoryAndItsTopics = (categoryId, subcategoryId) => {
    setCategories((prevCategories) =>
      prevCategories.map((category) => {
        // 해당 카테고리 확인
        if (category.id === categoryId) {
          return {
            ...category,
            menuVisible: true,
            subcategories: category.subcategories.map((subcategory) => {
              // 해당 서브카테고리 확인
              if (subcategory.id === subcategoryId) {
                return {
                  ...subcategory,
                  menuVisible: true,
                  topics: subcategory.topics.map((topic) => ({
                    ...topic,
                    menuVisible: true, // 해당 서브카테고리의 모든 토픽 menuVisible을 true로 설정
                  })),
                }
              }
              return {
                ...subcategory,
                menuVisible: false,
                topics: subcategory.topics.map((topic) => ({
                  ...topic,
                  menuVisible: false,
                })),
              }
            }),
          }
        }
        return {
          ...category,
          menuVisible: false,
          subcategories: category.subcategories.map((subcategory) => ({
            ...subcategory,
            menuVisible: false,
            topics: subcategory.topics.map((topic) => ({
              ...topic,
              menuVisible: false,
            })),
          })),
        }
      })
    )
  }

  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker)
  }

  useEffect(() => {
    // 카테고리 리스트가 있고 첫 번째 카테고리의 서브카테고리 리스트가 있을 경우
    if (categories.length && categories[0].subcategories.length) {
      const firstCategory = categories[0]
      const firstSubcategory = firstCategory.subcategories[0]

      // 첫 번째 카테고리의 첫 번째 서브카테고리를 선택
      setSelectSubcategoryId(firstSubcategory.id)

      // 선택된 서브카테고리의 menuVisible 속성을 true로 설정
      toggleSubcategoryVisibility(firstCategory.id, firstSubcategory.id)
    }
  }, []) // categories가 변경될 때만 실행

  const [newFaviconUrl, setNewFaviconUrl] = useState(
    'https://doogomimages3.s3.ap-northeast-2.amazonaws.com/default/icon.png'
  )
  const [htmlTitle, setHtmlTitle] = useState('DOOGOM')

  // 파비콘 변경 함수
  const changeFavicon = (faviconUrl) => {
    let link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement('link')
    link.type = 'image/x-icon'
    link.rel = 'shortcut icon'
    link.href = faviconUrl
    document.getElementsByTagName('head')[0].appendChild(link)
  }

  const [end, setEnd] = useState(false)

  const [showPaymentModal, setShowPaymentModal] = useState(false)

  const [nameOfPayment, setNameOfPayment] = useState('')
  const [firstNameOfPayment, setFirstNameOfPayment] = useState('')
  const [lastNameOfPayment, setLastNameOfPayment] = useState('')
  const [phoneNumberOfPayment, setPhoneNumberOfPayment] = useState('')
  const [emailOfPayment, setEmailOfPayment] = useState('')

  const [numberOfProducts, setNumberOfProducts] = useState(1)

  const [selectPaymentTypeIndex, setSelectPaymentTypeIndex] = useState(0)

  const [selectPaymentCategoryIndex, setSelectPaymentCategoryIndex] =
    useState(0)

  const [price, setPrice] = useState(0)

  const [userProductId, setUserProductId] = useState(null)

  const [activateAgreeChecked, setActivateAgreeChecked] = useState(false)

  const [payplePaymentContent, setPayplePaymentContent] = useState({
    pcd_pay_type: 'card', // 결제수단 <- 필수
    pcd_payer_name: `${nameOfPayment}`, // 결제자 이름
    pcd_payer_first_name: `${firstNameOfPayment}`, // 결제자 이름
    pcd_payer_last_name: `${lastNameOfPayment}`, // 결제자 성
    pcd_payer_hp: `${phoneNumberOfPayment}`, // 결제자 휴대폰 번호
    pcd_payer_email: `${emailOfPayment}`, // 결제자 Email
    pcd_pay_goods: '두곰 호스트 상품', // 결제 상품
    pcd_pay_total: '', // 결제 금액
    pcd_pay_taxtotal: '', // 부가세(복합과세인 경우 필수)
  })

  const getPaypleDomesticPaymentResult = async (res) => {
    console.log(res)
    if (res.PCD_PAY_RST === 'success') {
      const response = await postShowGuideStorePayment(
        token,
        userProductId,
        selectPaymentTypeIndex === 0 ? 'card' : 'transfer',
        numberOfProducts,
        price * numberOfProducts,
        res.PCD_PAYER_ID,
        res.PCD_PAYER_NAME,
        res.PCD_PAYER_HP,
        res.PCD_PAYER_EMAIL,
        res.PCD_PAY_OID,
        res.PCD_PAY_TAXTOTAL
      )
      if (parseInt(response.status) === 201) {
        show('결제가 완료되었습니다.')
        setShowPaymentModal(false)
      }
    } else {
      // 결제 실패일 경우 알림 메시지
      window.alert(res.PCD_PAY_MSG)
    }
  }

  const paypleDomesticPaymentClick = (e) => {
    e.preventDefault()
    const obj = {}

    obj.PCD_PAY_TYPE = 'card' // (필수) 결제 방법 (transfer | card)
    obj.PCD_PAY_WORK = 'PAY'
    if (payplePaymentContent.pcd_pay_type === 'card') {
      obj.PCD_CARD_VER = '02'
    }
    obj.PCD_PAY_GOODS = payplePaymentContent.pcd_pay_goods
    obj.PCD_PAY_TOTAL = payplePaymentContent.pcd_pay_total
    obj.PCD_RST_URL = `/show-guide/${token}`

    // 테스트 클라이언트 키
    // obj.clientKey = 'tech_93E8EBDE6367803C1249D1D4C3CA89BF'
    // 실결제 파트너 인증 - 클라이언트 키(clientKey)
    obj.clientKey = '5FFF101F02FE08F41E7733B84D591E3C'

    obj.PCD_PAYER_NAME = payplePaymentContent.pcd_payer_name
    obj.PCD_PAYER_HP = payplePaymentContent.pcd_payer_hp
    obj.PCD_PAYER_EMAIL = payplePaymentContent.pcd_payer_email
    obj.PCD_PAY_ISTAX = 'Y'

    obj.callbackFunction = getPaypleDomesticPaymentResult

    console.log(obj)
    window.PaypleCpayAuthCheck(obj)
  }

  useEffect(() => {
    setPayplePaymentContent((pre) => {
      return {
        ...pre,
        pcd_pay_type: selectPaymentTypeIndex === 0 ? 'card' : 'transfer',
        pcd_pay_total: price * numberOfProducts,
        pcd_pay_taxtotal: Math.round((price * numberOfProducts) / 11),
      }
    })
  }, [selectPaymentTypeIndex, numberOfProducts])

  useEffect(() => {
    // 현재 페이지 URL 확인
    const currentPageUrl = window.location.href

    if (currentPageUrl.includes('/show-guide/')) {
      // 파비콘 변경 함수 호출
      document.title = htmlTitle
      changeFavicon(newFaviconUrl)
    }

    // 컴포넌트 언마운트 시 기존 파비콘으로 복원
    return () => {
      document.title = 'DOOGOM'

      // 기본 파비콘 URL 또는 다른 조건에 따른 URL 지정
      const defaultFaviconUrl =
        'https://doogomimages3.s3.ap-northeast-2.amazonaws.com/default/icon.png'
      changeFavicon(defaultFaviconUrl)
    }
  }, [newFaviconUrl, htmlTitle])

  useEffect(() => {
    async function getData() {
      showLoading()
      console.log('token', token)
      const response = await getShowGuideDecodeToken(token)
      console.log('response', response)
      setUserProducts(response.data.userProducts)
      setCategories(response.data.categories)
      setGuide(response.data.guide)
      setNewFaviconUrl(response.data.guide.iconImageUrl)
      setHtmlTitle(response.data.guide.guideName)
      setUser(response.data.user)
      setPosition({
        latitude: response.data.guide.latitude,
        longitude: response.data.guide.longitude,
      })
      setCenter({
        latitude: response.data.guide.latitude,
        longitude: response.data.guide.longitude,
      })
      setMapError(
        response.data.guide.latitude === null ||
          response.data.guide.longitude === null
          ? true
          : false
      )
      if (isMobile) {
        setIsPc(false)
      } else {
        setIsPc(true)
      }
      console.log(isMobile)

      setTabList(
        response.data.user.productId !== 2
          ? [
              {
                id: 0,
                title: response.data.guide.tab1Name,
              },
              {
                id: 1,
                title: response.data.guide.tab2Name,
              },
              {
                id: 2,
                title: response.data.guide.tab3Name,
              },
              {
                id: 3,
                title: response.data.guide.tab4Name,
              },
              {
                id: 4,
                title: 'SEARCH',
              },
            ]
          : [
              {
                id: 0,
                title: response.data.guide.tab1Name,
              },
              {
                id: 1,
                title: response.data.guide.tab2Name,
              },
              {
                id: 3,
                title: response.data.guide.tab4Name,
              },
              {
                id: 4,
                title: 'SEARCH',
              },
            ]
      )

      if (isExpired(response.data.user.totalSubscriptionEndDate)) {
        setEnd(true)
      } else {
        setEnd(false)
      }

      hideLoading()
    }

    getData()
  }, [])

  useEffect(() => {
    const paymentComplete = sessionStorage.getItem('internationalPaymentIn')

    if (paymentComplete === 'true') {
      setTabIndex(2)
      sessionStorage.removeItem('internationalPaymentIn')
    }
  }, [])

  if (end) {
    return (
      <TotalContainer>
        <InnerContainer>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            You cannot view the subscription application page.
          </div>
          <Footer>
            <FooterNav>
              {tabList.map((tab) => {
                return (
                  <FooterNavTab
                    key={tab.id}
                    id={tab.id}
                    onClick={() => {
                      if (end === false) {
                        if (tab.id !== 2) {
                          setPosition({
                            latitude:
                              guide.latitude === null
                                ? 37.5665
                                : guide.latitude,
                            longitude:
                              guide.longitude === null
                                ? 126.978
                                : guide.longitude,
                          })
                          setCenter({
                            latitude:
                              guide.latitude === null
                                ? 37.5665
                                : guide.latitude,
                            longitude:
                              guide.longitude === null
                                ? 126.978
                                : guide.longitude,
                          })
                          setMapError(
                            guide.latitude === null || guide.longitude === null
                              ? true
                              : false
                          )
                        }

                        setTabIndex(tab.id)
                      }
                    }}
                    $tabIndex={tabIndex}
                    $index={tab.id}
                  >
                    {tab.title}
                  </FooterNavTab>
                )
              })}
            </FooterNav>
          </Footer>
        </InnerContainer>
      </TotalContainer>
    )
  }

  return (
    <>
      {!isLoading &&
        guide &&
        user &&
        (isPc ? (
          <TotalContainer>
            <InnerContainer>
              {tabIndex === 0 && (
                <HomeInnerContainerView>
                  <InnerContainerViewOverlay></InnerContainerViewOverlay>
                  <InnerContainerViewCenterDiv>
                    <InnerContainerViewCenterDivImg
                      style={{
                        borderRadius: guide.logoImageRounded ? '50%' : 0,
                      }}
                      src={guide.logoImageUrl}
                    ></InnerContainerViewCenterDivImg>
                    <InnerContainerViewCenterDivP>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {guide.hostName}
                      </span>
                    </InnerContainerViewCenterDivP>
                    <InnerContainerViewCenterDivContactDiv>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <PhoneIconFixed></PhoneIconFixed>
                        <InnerContainerViewCenterDivContactDivSpan>
                          {guide.hostPhoneNumber !== null &&
                          guide.hostPhoneNumber !== '' &&
                          guide.hostPhoneNumber !== undefined
                            ? formatPhoneNumber(guide.hostPhoneNumber)
                            : ''}
                        </InnerContainerViewCenterDivContactDivSpan>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginTop: 10,
                        }}
                      >
                        <PaperPlaneIconFixed></PaperPlaneIconFixed>
                        <InnerContainerViewCenterDivContactDivSpan>
                          {guide.email}
                        </InnerContainerViewCenterDivContactDivSpan>
                      </div>
                    </InnerContainerViewCenterDivContactDiv>
                    <InnerContainerViewCenterDivDivideSpan></InnerContainerViewCenterDivDivideSpan>
                    <InnerContainerViewCenterDivSpan>
                      {guide.guideName}
                    </InnerContainerViewCenterDivSpan>
                    <div
                      style={{
                        marginTop: '1.5rem',
                      }}
                    >
                      <SmallChangeBtn
                        onClick={() => {
                          setTabIndex(1)
                        }}
                        beforeBackgroudColor={c_eba55d}
                        beforeBorderColor={c_eba55d}
                        beforeFontColor={c_ffffff}
                        afterBackgroudColor={'transparent'}
                        afterBorderColor={c_eba55d}
                        afterFontColor={c_ffffff}
                      >
                        START
                      </SmallChangeBtn>
                    </div>
                  </InnerContainerViewCenterDiv>
                  <Overlay></Overlay>
                  <InnerContainerViewBackgroundImg
                    src={isPc ? guide.pcImageUrl : guide.mobileImageUrl}
                  ></InnerContainerViewBackgroundImg>
                </HomeInnerContainerView>
              )}
              {tabIndex === 1 && (
                <InfoInnerContainerView>
                  <InfoInnerContainerViewRow>
                    <InfoInnerContainerViewRowLeft>
                      <InfoInnerContainerViewRowLeftInner>
                        <InfoInnerContainerViewRowLeftInnerHeader>
                          <InfoInnerContainerViewRowLeftInnerHeaderRow>
                            <InfoInnerContainerViewRowLeftInnerHeaderRowLeft>
                              <InfoInnerContainerViewRowLeftInnerHeaderRowLeftImg
                                style={{
                                  borderRadius: guide.logoImageRounded
                                    ? '50%'
                                    : 0,
                                }}
                                src={guide.logoImageUrl}
                              ></InfoInnerContainerViewRowLeftInnerHeaderRowLeftImg>
                              <InfoInnerContainerViewRowLeftInnerHeaderRowIconStyle>
                                <PaperPlaneIconFixed
                                  onClick={() => {
                                    navigator.clipboard.writeText(guide.email)
                                    show('copy email')
                                  }}
                                ></PaperPlaneIconFixed>
                              </InfoInnerContainerViewRowLeftInnerHeaderRowIconStyle>
                              <InfoInnerContainerViewRowLeftInnerHeaderRowIconStyle>
                                <PhoneIconFixed
                                  onClick={() => {
                                    if (isPc) {
                                      navigator.clipboard.writeText(
                                        guide.hostPhoneNumber
                                      )
                                      show('copy phone number')
                                    } else {
                                      window.location.href = `tel:${guide.hostPhoneNumber}`
                                    }
                                  }}
                                ></PhoneIconFixed>
                              </InfoInnerContainerViewRowLeftInnerHeaderRowIconStyle>
                            </InfoInnerContainerViewRowLeftInnerHeaderRowLeft>
                            <InfoInnerContainerViewRowLeftInnerHeaderRowRight>
                              <InfoInnerContainerViewRowLeftInnerHeaderRowIconStyle>
                                <ShareIconFixed
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      guide.shareUrl
                                    )
                                    show('url copied')
                                  }}
                                ></ShareIconFixed>
                              </InfoInnerContainerViewRowLeftInnerHeaderRowIconStyle>
                              <InfoInnerContainerViewRowLeftInnerHeaderRowIconStyle>
                                <HeadsetFixed
                                  onClick={() => {
                                    setShowMoveTo1330Modal(true)
                                  }}
                                ></HeadsetFixed>
                              </InfoInnerContainerViewRowLeftInnerHeaderRowIconStyle>
                            </InfoInnerContainerViewRowLeftInnerHeaderRowRight>
                          </InfoInnerContainerViewRowLeftInnerHeaderRow>
                        </InfoInnerContainerViewRowLeftInnerHeader>
                        <InfoInnerContainerViewRowLeftInnerBody>
                          <InfoInnerContainerViewRowLeftInnerBodyDiv>
                            {categories.map((category, categoryIndex) => {
                              if (!tileRefs.current[categoryIndex]) {
                                tileRefs.current[categoryIndex] =
                                  React.createRef()
                              }

                              return (
                                <InfoInnerContainerViewRowLeftInnerBodyDivTile
                                  key={category.id}
                                  ref={tileRefs.current[categoryIndex]}
                                  style={{
                                    marginTop: categoryIndex > 0 && 15,
                                  }}
                                  onClick={(e) => {
                                    toggleCategoryVisibility(category.id)
                                    const tileElement =
                                      tileRefs.current[categoryIndex].current
                                    if (tileElement) {
                                      setTimeout(() => {
                                        tileElement.scrollIntoView({
                                          behavior: 'smooth',
                                        })
                                      }, 100)
                                    }
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    {category.title}
                                    <FontAwesomeIcon
                                      icon={
                                        category.menuVisible
                                          ? faChevronUp
                                          : faChevronDown
                                      }
                                    />
                                  </div>
                                  {category.menuVisible && (
                                    <div
                                      style={{
                                        marginLeft: 50,
                                      }}
                                    >
                                      {category.subcategories.map(
                                        (subcategory, subcategoryIndex) => {
                                          return (
                                            <div
                                              key={subcategory.id}
                                              style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                paddingTop: 20,
                                                paddingBottom:
                                                  subcategoryIndex + 1 !==
                                                  category.subcategories.length
                                                    ? 20
                                                    : 0,
                                                color: subcategory.menuVisible
                                                  ? '#eba55d'
                                                  : '#000',
                                                borderBottom: `${
                                                  subcategoryIndex + 1 !==
                                                  category.subcategories.length
                                                    ? 1
                                                    : 0
                                                }px solid rgba(0,0,0,.1)`,
                                              }}
                                              onClick={(e) => {
                                                e.stopPropagation()

                                                toggleSubcategoryVisibility(
                                                  category.id,
                                                  subcategory.id
                                                )
                                              }}
                                            >
                                              {subcategory.title}
                                              <ArrowRightIconFixed></ArrowRightIconFixed>
                                            </div>
                                          )
                                        }
                                      )}
                                    </div>
                                  )}
                                </InfoInnerContainerViewRowLeftInnerBodyDivTile>
                              )
                            })}
                          </InfoInnerContainerViewRowLeftInnerBodyDiv>
                        </InfoInnerContainerViewRowLeftInnerBody>
                      </InfoInnerContainerViewRowLeftInner>
                    </InfoInnerContainerViewRowLeft>
                    <InfoInnerContainerViewRowRight>
                      <InfoInnerContainerViewRowRightHeader>
                        {selectSubcategoryId !== null &&
                          categories.map((category) => {
                            if (category.id === selectCategoryId) {
                              return category.subcategories.map(
                                (subcategory) => {
                                  if (subcategory.id === selectSubcategoryId) {
                                    return (
                                      <div
                                        key={subcategory.id}
                                        style={{
                                          width: '100%',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'space-between',
                                        }}
                                      >
                                        {subcategory.title}
                                        <div
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontSize: '25px',
                                            textShadow:
                                              '0px 0px 4px rgba(0, 0, 0, 0.2)',
                                          }}
                                        >
                                          <CircleArrowUpIconFixed
                                            onClick={() => {
                                              closeAllTopicsInSubcategory(
                                                category.id,
                                                subcategory.id
                                              )
                                            }}
                                          ></CircleArrowUpIconFixed>
                                          <div
                                            style={{
                                              marginLeft: '18px',
                                            }}
                                          >
                                            <CircleArrowDownIconFixed
                                              onClick={() => {
                                                openAllTopicsInSubcategory(
                                                  category.id,
                                                  subcategory.id
                                                )
                                              }}
                                            ></CircleArrowDownIconFixed>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  }
                                }
                              )
                            }
                          })}
                      </InfoInnerContainerViewRowRightHeader>
                      <InfoInnerContainerViewRowRightBody>
                        {selectSubcategoryId !== null &&
                          categories.map((category) => {
                            if (category.id === selectCategoryId) {
                              return category.subcategories.map(
                                (subcategory) => {
                                  if (subcategory.id === selectSubcategoryId) {
                                    return subcategory.topics.map((topic) => {
                                      return (
                                        <InfoInnerContainerViewRowRightBodyTile
                                          key={topic.id}
                                        >
                                          <div
                                            style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'space-between',
                                            }}
                                            onClick={() => {
                                              toggleTopicVisibility(
                                                category.id,
                                                subcategory.id,
                                                topic.id
                                              )
                                              console.log(
                                                JSON.parse(topic.content)
                                              )
                                            }}
                                          >
                                            {topic.title}
                                            <FontAwesomeIcon
                                              icon={
                                                topic.menuVisible
                                                  ? faChevronUp
                                                  : faChevronDown
                                              }
                                            />
                                          </div>
                                          {topic.menuVisible && (
                                            <div
                                              style={{
                                                paddingTop: 20,
                                                paddingBottom: 20,
                                              }}
                                            >
                                              {topic.imgUrl !== '' &&
                                                topic.imgUrl !== null && (
                                                  <img
                                                    alt=""
                                                    src={`${topic.imgUrl}`}
                                                    style={{
                                                      maxWidth: '100%',
                                                      width: '100%',
                                                      objectFit: 'contain',
                                                      margin: '15px 0',
                                                      overflowClipMargin:
                                                        'content-box',
                                                      overflow: 'clip',
                                                      boxSizing: 'border-box',
                                                    }}
                                                  ></img>
                                                )}
                                              <InfoInnerContainerViewRowRightBodyTileContent>
                                                <Editor
                                                  editorState={EditorState.createWithContent(
                                                    convertFromRaw(
                                                      JSON.parse(topic.content)
                                                    )
                                                  )}
                                                  toolbarClassName="toolbarClassName"
                                                  wrapperClassName="wrapperClassName"
                                                  editorClassName="editorClassName"
                                                  toolbarHidden={true}
                                                  readOnly={true}
                                                />
                                                {topic.latitude !== null &&
                                                  topic.longitude !== null && (
                                                    <div
                                                      style={{
                                                        margin: '15px 0',
                                                        boxSizing: 'border-box',
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          display: 'flex',
                                                          alignItems: 'center',
                                                          boxSizing:
                                                            'border-box',
                                                        }}
                                                      >
                                                        <div
                                                          style={{
                                                            borderRadius: '50%',
                                                            width: 50,
                                                            height: 50,
                                                            display: 'flex',
                                                            alignItems:
                                                              'center',
                                                            justifyContent:
                                                              'center',
                                                            marginRight: 10,
                                                            backgroundColor:
                                                              '#eba55d',
                                                            cursor: 'pointer',
                                                            color: '#fff',
                                                          }}
                                                          onClick={() => {
                                                            setCenter({
                                                              latitude:
                                                                topic.latitude,
                                                              longitude:
                                                                topic.longitude,
                                                            })
                                                            setMapError(false)
                                                            setSelectCategory(
                                                              category
                                                            )
                                                            setSelectCategoryId(
                                                              category.id
                                                            )
                                                            setSelectSubcategoryId(
                                                              subcategory.id
                                                            )
                                                            handleMarkerClick(
                                                              topic
                                                            )
                                                            setTabIndex(3)
                                                          }}
                                                        >
                                                          <LocationIconFixed></LocationIconFixed>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                              </InfoInnerContainerViewRowRightBodyTileContent>
                                            </div>
                                          )}
                                        </InfoInnerContainerViewRowRightBodyTile>
                                      )
                                    })
                                  }
                                }
                              )
                            }
                          })}
                      </InfoInnerContainerViewRowRightBody>
                    </InfoInnerContainerViewRowRight>
                  </InfoInnerContainerViewRow>
                </InfoInnerContainerView>
              )}
              {tabIndex === 2 && (
                <StoreInnerContainerView>
                  <StoreInnerContainerViewRow>
                    <StoreInnerContainerViewRowRight>
                      <StoreInnerContainerViewRowRightHeader>
                        STORE
                      </StoreInnerContainerViewRowRightHeader>
                      <StoreInnerContainerViewRowRightBody>
                        {userProducts.map((userProduct) => {
                          return (
                            <StoreInnerContainerViewRowRightBodyTile
                              key={userProduct.id}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                }}
                                onClick={() => {
                                  toggleUserProductVisibility(userProduct.id)
                                }}
                              >
                                {userProduct.title}
                                <FontAwesomeIcon
                                  icon={
                                    userProduct.menuVisible
                                      ? faChevronUp
                                      : faChevronDown
                                  }
                                />
                              </div>
                              {userProduct.menuVisible && (
                                <div
                                  style={{
                                    paddingTop: 20,
                                    paddingBottom: 20,
                                  }}
                                >
                                  {userProduct.imgUrl !== '' &&
                                    userProduct.imgUrl !== null && (
                                      <img
                                        alt=""
                                        src={`${userProduct.imgUrl}`}
                                        style={{
                                          maxWidth: '100%',
                                          width: '100%',
                                          objectFit: 'contain',
                                          margin: '15px 0',
                                          overflowClipMargin: 'content-box',
                                          overflow: 'clip',
                                          boxSizing: 'border-box',
                                        }}
                                      ></img>
                                    )}
                                  <StoreInnerContainerViewRowRightBodyTileContent>
                                    <Editor
                                      editorState={EditorState.createWithContent(
                                        convertFromRaw(
                                          JSON.parse(userProduct.content)
                                        )
                                      )}
                                      toolbarClassName="toolbarClassName"
                                      wrapperClassName="wrapperClassName"
                                      editorClassName="editorClassName"
                                      toolbarHidden={true}
                                      readOnly={true}
                                    />
                                    <OpacityBtn
                                      disabled={false}
                                      backgroundColor={c_eba55d}
                                      fontColor={c_ffffff}
                                      onClick={() => {
                                        setUserProductId(userProduct.id)
                                        setPrice(userProduct.price)
                                        setPayplePaymentContent((pre) => {
                                          return {
                                            ...pre,
                                            pcd_pay_goods: userProduct.title,
                                            pcd_pay_total: userProduct.price,
                                            pcd_pay_taxtotal: Math.round(
                                              userProduct.price / 11
                                            ),
                                          }
                                        })
                                        setShowPaymentModal(true)
                                      }}
                                    >
                                      &#8361;
                                      {priceFormatFunction(
                                        userProduct.price
                                      )}{' '}
                                      checkout
                                    </OpacityBtn>
                                  </StoreInnerContainerViewRowRightBodyTileContent>
                                </div>
                              )}
                            </StoreInnerContainerViewRowRightBodyTile>
                          )
                        })}
                      </StoreInnerContainerViewRowRightBody>
                    </StoreInnerContainerViewRowRight>
                  </StoreInnerContainerViewRow>
                </StoreInnerContainerView>
              )}
              {tabIndex === 3 && (
                <MapInnerContainerView>
                  {mapError && (
                    <MapInnerContainerViewError>
                      <MapInnerContainerViewErrorH2>
                        The address needs to be updated.
                      </MapInnerContainerViewErrorH2>
                    </MapInnerContainerViewError>
                  )}
                  {!mapError && isLoaded && (
                    <GoogleMap
                      mapContainerStyle={{ width: '100%', height: '100%' }}
                      center={{
                        lat: center.latitude,
                        lng: center.longitude,
                      }}
                      zoom={zoom}
                      onLoad={onLoad}
                      onUnmount={onUnmount}
                      options={{
                        styles: [
                          {
                            featureType: 'poi',
                            elementType: 'labels',
                            stylers: [{ visibility: 'off' }],
                          },
                        ],
                      }}
                    >
                      {categories.map((category) =>
                        category.subcategories.map((subcategory) =>
                          subcategory.topics.map((topic) => {
                            if (
                              topic.latitude &&
                              topic.longitude &&
                              topic.mapPinIconUrl
                            ) {
                              return (
                                <MarkerF
                                  key={topic.id}
                                  position={{
                                    lat: topic.latitude,
                                    lng: topic.longitude,
                                  }}
                                  draggable={false}
                                  icon={{
                                    url: topic.mapPinIconUrl,
                                    scaledSize: new window.google.maps.Size(
                                      40,
                                      40
                                    ),
                                  }}
                                  onClick={() => {
                                    setCenter({
                                      latitude: topic.latitude,
                                      longitude: topic.longitude,
                                    })
                                    setSelectCategory(category)
                                    setSelectCategoryId(category.id)
                                    setSelectSubcategoryId(subcategory.id)
                                    handleMarkerClick(topic)
                                  }}
                                />
                              )
                            }
                          })
                        )
                      )}
                      {position.latitude && position.longitude && (
                        <MarkerF
                          position={{
                            lat: position.latitude,
                            lng: position.longitude,
                          }}
                          draggable={false}
                          icon={{
                            url: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
                            scaledSize: new window.google.maps.Size(40, 40),
                          }}
                        />
                      )}
                      {selectedMarker && (
                        <InfoWindow
                          position={{
                            lat: selectedMarker.latitude,
                            lng: selectedMarker.longitude,
                          }}
                          onCloseClick={() => {
                            setSelectCategory(null)
                            setSelectCategoryId(null)
                            setSelectSubcategoryId(null)
                            setSelectedMarker(null)
                          }}
                          options={{
                            pixelOffset: new window.google.maps.Size(0, -30),
                          }}
                        >
                          <div
                            style={{
                              minWidth: '100px',
                              minHeight: '50px',
                              overflow: 'hidden',
                              borderRadius: '8px',
                              backgroundColor: 'white',
                              padding: '5px',
                              boxSizing: 'border-box',
                              wordWrap: 'break-word',
                              overflowWrap: 'break-word',
                            }}
                          >
                            <h3>{selectedMarker.title}</h3>
                            <div
                              style={{
                                maxHeight: '150px',
                                overflowY: 'auto',
                                marginTop: '10px',
                              }}
                            >
                              <Editor
                                editorState={EditorState.createWithContent(
                                  convertFromRaw(
                                    JSON.parse(selectedMarker.content)
                                  )
                                )}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                toolbarHidden={true}
                                readOnly={true}
                                mention={{
                                  separator: ' ',
                                  trigger: '@',
                                  suggestions: [
                                    {
                                      text: 'APPLE',
                                      value: 'apple',
                                    },
                                    {
                                      text: 'BANANA',
                                      value: 'banana',
                                      url: 'banana',
                                    },
                                    {
                                      text: 'CHERRY',
                                      value: 'cherry',
                                      url: 'cherry',
                                    },
                                    {
                                      text: 'DURIAN',
                                      value: 'durian',
                                      url: 'durian',
                                    },
                                    {
                                      text: 'EGGFRUIT',
                                      value: 'eggfruit',
                                      url: 'eggfruit',
                                    },
                                    {
                                      text: 'FIG',
                                      value: 'fig',
                                      url: 'fig',
                                    },
                                    {
                                      text: 'GRAPEFRUIT',
                                      value: 'grapefruit',
                                      url: 'grapefruit',
                                    },
                                    {
                                      text: 'HONEYDEW',
                                      value: 'honeydew',
                                      url: 'honeydew',
                                    },
                                  ],
                                }}
                              />
                            </div>
                            <div
                              onClick={() => {
                                console.log(selectedMarker)
                                setSelectCategoryId(selectCategoryId)
                                setSelectSubcategoryId(selectSubcategoryId)
                                toggleTopicVisibilityOnlyOpen(
                                  selectCategoryId,
                                  selectSubcategoryId,
                                  selectedMarker.id
                                )
                                setTabIndex(1)
                                setSelectedMarker(null)
                              }}
                              style={{
                                color: 'blue',
                                marginTop: '10px',
                              }}
                            >
                              More info
                            </div>
                            <div
                              style={{
                                display: 'flex',
                              }}
                            >
                              <div
                                onClick={() => {
                                  console.log(selectedMarker)
                                  const url = `https://www.google.com/maps/dir/?api=1&destination=${selectedMarker.latitude},${selectedMarker.longitude}`
                                  window.open(url, '_blank')
                                }}
                                style={{
                                  color: 'blue',
                                  marginTop: '20px',
                                }}
                              >
                                Google
                              </div>
                              <div
                                style={{
                                  marginTop: '20px',
                                  marginLeft: '10px',
                                  marginRight: '10px',
                                }}
                              >
                                |
                              </div>
                              <div
                                onClick={() => {
                                  console.log(selectedMarker)
                                  // const url = `https://map.naver.com/v5/?c=${selectedMarker.longitude},${selectedMarker.latitude},18,0,0,0,dh`
                                  const url = `https://map.naver.com/p/search/${selectedMarker.address}?c=15.00,0,0,0,dh`
                                  window.open(url, '_blank')
                                }}
                                style={{
                                  color: 'blue',
                                  marginTop: '20px',
                                }}
                              >
                                Naver
                              </div>
                            </div>
                          </div>
                        </InfoWindow>
                      )}
                    </GoogleMap>
                  )}
                </MapInnerContainerView>
              )}
              {tabIndex === 4 && (
                <SearchInnerContainerView>
                  <SearchInnerContainerViewInner
                    style={{
                      top: searchText.length === 0 ? '50%' : '75px',
                    }}
                  >
                    <SearchInnerContainerViewInnerDiv>
                      <SearchInnerContainerViewInnerDivInputWrapper>
                        <SearchInnerContainerViewInnerDivInputWrapperSpan>
                          <SearchIconFixed></SearchIconFixed>
                        </SearchInnerContainerViewInnerDivInputWrapperSpan>
                        <SearchInnerContainerViewInnerDivInputWrapperInput
                          autoComplete="off"
                          placeholder="Search for keywords"
                          value={searchText}
                          onChange={(e) => {
                            setSearchText(e.target.value)
                            let {
                              tempSubcategoriesFoundByTitle,
                              tempSubcategoriesFoundByContent,
                            } = searchSubcategories(e.target.value)
                            setSubcategoriesFoundByTitle(
                              tempSubcategoriesFoundByTitle
                            )
                            setSubcategoriesFoundByContent(
                              tempSubcategoriesFoundByContent
                            )
                          }}
                        ></SearchInnerContainerViewInnerDivInputWrapperInput>
                      </SearchInnerContainerViewInnerDivInputWrapper>
                    </SearchInnerContainerViewInnerDiv>
                    {searchText.length === 0 && (
                      <SearchInnerContainerViewInnerP>
                        Search results will appear featuring matches for all
                        your keywords.
                      </SearchInnerContainerViewInnerP>
                    )}
                    {searchText.length !== 0 &&
                      subcategoriesFoundByTitle.length === 0 &&
                      subcategoriesFoundByContent.length === 0 && (
                        <SearchInnerContainerViewInnerP>
                          No results found. Please try again.
                        </SearchInnerContainerViewInnerP>
                      )}
                    <SearchTileFrame>
                      {searchText.length !== 0 &&
                        subcategoriesFoundByTitle.length !== 0 && (
                          <>
                            <h4
                              style={{
                                fontWeight: 700,
                                fontSize: '1.125rem',
                                opacity: 0.8,
                                margin: '15px 0',
                              }}
                            >
                              Matches in a section heading
                            </h4>
                            <SearchTileWrapper>
                              {subcategoriesFoundByTitle.map((subcategory) => {
                                return (
                                  <SearchTile
                                    key={subcategory.subcategoryId}
                                    onClick={() => {
                                      openSubcategoryAndItsTopics(
                                        subcategory.categoryId,
                                        subcategory.subcategoryId
                                      )
                                      setSelectCategoryId(
                                        subcategory.categoryId
                                      )
                                      setSelectSubcategoryId(
                                        subcategory.subcategoryId
                                      )
                                      setTabIndex(1)
                                    }}
                                  >
                                    {subcategory.title}
                                  </SearchTile>
                                )
                              })}
                            </SearchTileWrapper>
                          </>
                        )}
                      {searchText.length !== 0 &&
                        subcategoriesFoundByContent.length !== 0 && (
                          <>
                            <h4
                              style={{
                                fontWeight: 700,
                                fontSize: '1.125rem',
                                opacity: 0.8,
                                margin: '15px 0',
                              }}
                            >
                              Matches in a section description
                            </h4>
                            <SearchTileWrapper>
                              {subcategoriesFoundByContent.map(
                                (subcategory) => {
                                  return (
                                    <SearchTile
                                      key={subcategory.subcategoryId}
                                      onClick={() => {
                                        openSubcategoryAndItsTopics(
                                          subcategory.categoryId,
                                          subcategory.subcategoryId
                                        )
                                        setTabIndex(1)
                                      }}
                                    >
                                      {subcategory.title}
                                    </SearchTile>
                                  )
                                }
                              )}
                            </SearchTileWrapper>
                          </>
                        )}
                    </SearchTileFrame>
                  </SearchInnerContainerViewInner>
                </SearchInnerContainerView>
              )}
              <Footer>
                <FooterNav>
                  {tabList.map((tab) => {
                    return (
                      <FooterNavTab
                        key={tab.id}
                        id={tab.id}
                        onClick={() => {
                          if (tab.id !== 2) {
                            setPosition({
                              latitude:
                                guide.latitude === null
                                  ? 37.5665
                                  : guide.latitude,
                              longitude:
                                guide.longitude === null
                                  ? 126.978
                                  : guide.longitude,
                            })
                            setCenter({
                              latitude:
                                guide.latitude === null
                                  ? 37.5665
                                  : guide.latitude,
                              longitude:
                                guide.longitude === null
                                  ? 126.978
                                  : guide.longitude,
                            })
                            setMapError(
                              guide.latitude === null ||
                                guide.longitude === null
                                ? true
                                : false
                            )
                          }

                          setTabIndex(tab.id)
                        }}
                        $tabIndex={tabIndex}
                        $index={tab.id}
                      >
                        {tab.title}
                      </FooterNavTab>
                    )
                  })}
                </FooterNav>
              </Footer>
            </InnerContainer>
          </TotalContainer>
        ) : (
          <TotalContainer>
            <InnerContainer>
              {tabIndex === 0 && (
                <HomeInnerContainerView>
                  <InnerContainerViewOverlay></InnerContainerViewOverlay>
                  <InnerContainerViewCenterDiv>
                    <InnerContainerViewCenterDivImg
                      style={{
                        borderRadius: guide.logoImageRounded ? '50%' : 0,
                      }}
                      src={guide.logoImageUrl}
                    ></InnerContainerViewCenterDivImg>
                    <InnerContainerViewCenterDivP>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {guide.hostName}
                      </span>
                    </InnerContainerViewCenterDivP>
                    <InnerContainerViewCenterDivContactDiv>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <PhoneIconFixed></PhoneIconFixed>
                        <InnerContainerViewCenterDivContactDivSpan>
                          {guide.hostPhoneNumber !== null &&
                          guide.hostPhoneNumber !== '' &&
                          guide.hostPhoneNumber !== undefined
                            ? formatPhoneNumber(guide.hostPhoneNumber)
                            : ''}
                        </InnerContainerViewCenterDivContactDivSpan>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginTop: 10,
                        }}
                      >
                        <PaperPlaneIconFixed></PaperPlaneIconFixed>
                        <InnerContainerViewCenterDivContactDivSpan>
                          {guide.email}
                        </InnerContainerViewCenterDivContactDivSpan>
                      </div>
                    </InnerContainerViewCenterDivContactDiv>
                    <InnerContainerViewCenterDivDivideSpan></InnerContainerViewCenterDivDivideSpan>
                    <InnerContainerViewCenterDivSpan>
                      {guide.guideName}
                    </InnerContainerViewCenterDivSpan>
                    <div
                      style={{
                        marginTop: '1.5rem',
                      }}
                    >
                      <SmallChangeBtn
                        onClick={() => {
                          setTabIndex(1)
                        }}
                        beforeBackgroudColor={c_eba55d}
                        beforeBorderColor={c_eba55d}
                        beforeFontColor={c_ffffff}
                        afterBackgroudColor={'transparent'}
                        afterBorderColor={c_eba55d}
                        afterFontColor={c_ffffff}
                      >
                        START
                      </SmallChangeBtn>
                    </div>
                  </InnerContainerViewCenterDiv>
                  <Overlay></Overlay>
                  <InnerContainerViewBackgroundImg
                    src={isPc ? guide.pcImageUrl : guide.mobileImageUrl}
                  ></InnerContainerViewBackgroundImg>
                </HomeInnerContainerView>
              )}
              {tabIndex === 1 && (
                <MobileInfoView>
                  {!showMobileInfoDetail ? (
                    <MobileInfoViewHeader>
                      <MobileInfoViewHeaderInner>
                        <MobileInfoViewHeaderInnerLeft>
                          <InfoInnerContainerViewRowLeftInnerHeaderRowLeftImg
                            style={{
                              borderRadius: guide.logoImageRounded ? '50%' : 0,
                            }}
                            src={guide.logoImageUrl}
                          ></InfoInnerContainerViewRowLeftInnerHeaderRowLeftImg>
                          <InfoInnerContainerViewRowLeftInnerHeaderRowIconStyle>
                            <PhoneIconFixed
                              onClick={() => {
                                if (isPc) {
                                  navigator.clipboard.writeText(
                                    guide.hostPhoneNumber
                                  )
                                  show('copy phone number')
                                } else {
                                  window.location.href = `tel:${guide.hostPhoneNumber}`
                                }
                              }}
                            ></PhoneIconFixed>
                          </InfoInnerContainerViewRowLeftInnerHeaderRowIconStyle>
                        </MobileInfoViewHeaderInnerLeft>
                        <MobileInfoViewHeaderInnerRight>
                          <InfoInnerContainerViewRowLeftInnerHeaderRowIconStyle>
                            <ShareIconFixed
                              onClick={() => {
                                navigator.clipboard.writeText(guide.shareUrl)
                                show('url copied')
                              }}
                            ></ShareIconFixed>
                          </InfoInnerContainerViewRowLeftInnerHeaderRowIconStyle>
                          <InfoInnerContainerViewRowLeftInnerHeaderRowIconStyle>
                            <HeadsetFixed
                              onClick={() => {
                                setShowMoveTo1330Modal(true)
                              }}
                            ></HeadsetFixed>
                          </InfoInnerContainerViewRowLeftInnerHeaderRowIconStyle>
                        </MobileInfoViewHeaderInnerRight>
                      </MobileInfoViewHeaderInner>
                    </MobileInfoViewHeader>
                  ) : (
                    <ShowMobileInfoDetailHeader>
                      <ShowMobileInfoDetailHeaderInner>
                        <ShowMobileInfoDetailHeaderInnerLeft>
                          <ShowMobileInfoDetailHeaderInnerIconStyle
                            onClick={() => {
                              setShowMobileInfoDetail(false)
                              const hasMultipleSubcategories = categories.some(
                                (category) => {
                                  if (category.id === selectCategoryId) {
                                    return category.subcategories.length > 1
                                  }
                                  return false
                                }
                              )

                              if (hasMultipleSubcategories) {
                                setShowMobileSubcategoriesModal(true)
                              } else {
                                setShowMobileSubcategoriesModal(false)
                              }
                            }}
                          >
                            <ArrowLeftIconFixed></ArrowLeftIconFixed>
                          </ShowMobileInfoDetailHeaderInnerIconStyle>
                          <ShowMobileInfoDetailHeaderInnerP>
                            {categories.map((category) => {
                              if (category.id === selectCategoryId) {
                                return category.subcategories.map(
                                  (subcategory) => {
                                    if (
                                      subcategory.id === selectSubcategoryId
                                    ) {
                                      return subcategory.title
                                    }
                                  }
                                )
                              }
                            })}
                          </ShowMobileInfoDetailHeaderInnerP>
                        </ShowMobileInfoDetailHeaderInnerLeft>
                        <ShowMobileInfoDetailHeaderInnerRight>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              fontSize: '25px',
                              textShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)',
                            }}
                          >
                            <CircleArrowUpIconFixed
                              onClick={() => {
                                closeAllTopicsInSubcategory(
                                  selectCategoryId,
                                  selectSubcategoryId
                                )
                              }}
                            ></CircleArrowUpIconFixed>
                            <div
                              style={{
                                marginLeft: '18px',
                              }}
                            >
                              <CircleArrowDownIconFixed
                                onClick={() => {
                                  openAllTopicsInSubcategory(
                                    selectCategoryId,
                                    selectSubcategoryId
                                  )
                                }}
                              ></CircleArrowDownIconFixed>
                            </div>
                          </div>
                        </ShowMobileInfoDetailHeaderInnerRight>
                      </ShowMobileInfoDetailHeaderInner>
                    </ShowMobileInfoDetailHeader>
                  )}
                  <MobileInfoViewBody>
                    {!showMobileInfoDetail ? (
                      <MobileInfoViewMainBody>
                        <MobileInfoViewMainBodyInner>
                          <MobileInfoViewMainBodyInnerInner>
                            <MobileInfoViewMainBodyInnerInnerWrapper>
                              {categories.map((category, categoryIndex) => {
                                return (
                                  <MobileInfoViewMainBodyInnerInnerWrapperCard
                                    key={category.id}
                                    $categoriesLength={categories.length}
                                    onClick={() => {
                                      if (category.subcategories.length === 0) {
                                        show('no subcategories', false)
                                      } else if (
                                        category.subcategories.length !== 1
                                      ) {
                                        setSelectCategoryId(category.id)
                                        setSelectCategory(category)
                                        setShowMobileSubcategoriesModal(true)
                                      } else {
                                        setSelectCategoryId(category.id)
                                        setSelectSubcategoryId(
                                          category.subcategories[0].id
                                        )
                                        setShowMobileInfoDetail(true)
                                      }
                                    }}
                                  >
                                    <MobileInfoViewMainBodyInnerInnerWrapperCardThumbnail>
                                      <MobileInfoViewMainBodyInnerInnerWrapperCardThumbnailIconStyle>
                                        <img
                                          src={category.iconUrl}
                                          alt=""
                                        ></img>
                                      </MobileInfoViewMainBodyInnerInnerWrapperCardThumbnailIconStyle>
                                      <MobileInfoViewMainBodyInnerInnerWrapperCardThumbnailDiv>
                                        {category.title}
                                      </MobileInfoViewMainBodyInnerInnerWrapperCardThumbnailDiv>
                                    </MobileInfoViewMainBodyInnerInnerWrapperCardThumbnail>
                                  </MobileInfoViewMainBodyInnerInnerWrapperCard>
                                )
                              })}
                            </MobileInfoViewMainBodyInnerInnerWrapper>
                          </MobileInfoViewMainBodyInnerInner>
                        </MobileInfoViewMainBodyInner>
                      </MobileInfoViewMainBody>
                    ) : (
                      <MobileInfoViewMainBody>
                        <MobileInfoViewMainBodyInner>
                          <MobileInfoViewMainBodyInnerInner>
                            {selectSubcategoryId !== null &&
                              categories.map((category) => {
                                if (category.id === selectCategoryId) {
                                  return category.subcategories.map(
                                    (subcategory) => {
                                      if (
                                        subcategory.id === selectSubcategoryId
                                      ) {
                                        return subcategory.topics.map(
                                          (topic) => {
                                            return (
                                              <MobileInfoInnerContainerViewRowRightBodyTile
                                                key={topic.id}
                                              >
                                                <div
                                                  style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent:
                                                      'space-between',
                                                  }}
                                                  onClick={() => {
                                                    toggleTopicVisibility(
                                                      category.id,
                                                      subcategory.id,
                                                      topic.id
                                                    )
                                                  }}
                                                >
                                                  {topic.title}
                                                  <FontAwesomeIcon
                                                    icon={
                                                      topic.menuVisible
                                                        ? faChevronUp
                                                        : faChevronDown
                                                    }
                                                  />
                                                </div>
                                                {topic.menuVisible && (
                                                  <div
                                                    style={{
                                                      paddingTop: 20,
                                                      paddingBottom: 20,
                                                    }}
                                                  >
                                                    {topic.imgUrl !== '' &&
                                                      topic.imgUrl !== null && (
                                                        <img
                                                          alt=""
                                                          src={`${topic.imgUrl}`}
                                                          style={{
                                                            maxWidth: '100%',
                                                            width: '100%',
                                                            objectFit:
                                                              'contain',
                                                            margin: '15px 0',
                                                            overflowClipMargin:
                                                              'content-box',
                                                            overflow: 'clip',
                                                            boxSizing:
                                                              'border-box',
                                                          }}
                                                        ></img>
                                                      )}
                                                    <MobileInfoInnerContainerViewRowRightBodyTileContent>
                                                      <Editor
                                                        editorState={EditorState.createWithContent(
                                                          convertFromRaw(
                                                            JSON.parse(
                                                              topic.content
                                                            )
                                                          )
                                                        )}
                                                        toolbarClassName="toolbarClassName"
                                                        wrapperClassName="wrapperClassName"
                                                        editorClassName="editorClassName"
                                                        toolbarHidden={true}
                                                        readOnly={true}
                                                      />
                                                      {topic.latitude !==
                                                        null &&
                                                        topic.longitude !==
                                                          null && (
                                                          <div
                                                            style={{
                                                              margin: '15px 0',
                                                              boxSizing:
                                                                'border-box',
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                display: 'flex',
                                                                alignItems:
                                                                  'center',
                                                                boxSizing:
                                                                  'border-box',
                                                              }}
                                                            >
                                                              <div
                                                                style={{
                                                                  borderRadius:
                                                                    '50%',
                                                                  width: 50,
                                                                  height: 50,
                                                                  display:
                                                                    'flex',
                                                                  alignItems:
                                                                    'center',
                                                                  justifyContent:
                                                                    'center',
                                                                  marginRight: 10,
                                                                  backgroundColor:
                                                                    '#eba55d',
                                                                  cursor:
                                                                    'pointer',
                                                                  color: '#fff',
                                                                }}
                                                                onClick={() => {
                                                                  setCenter({
                                                                    latitude:
                                                                      topic.latitude,
                                                                    longitude:
                                                                      topic.longitude,
                                                                  })
                                                                  setMapError(
                                                                    false
                                                                  )
                                                                  setSelectCategory(
                                                                    category
                                                                  )
                                                                  setSelectCategoryId(
                                                                    category.id
                                                                  )
                                                                  setSelectSubcategoryId(
                                                                    subcategory.id
                                                                  )
                                                                  handleMarkerClick(
                                                                    topic
                                                                  )
                                                                  setTabIndex(3)
                                                                }}
                                                              >
                                                                <LocationIconFixed></LocationIconFixed>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        )}
                                                    </MobileInfoInnerContainerViewRowRightBodyTileContent>
                                                  </div>
                                                )}
                                              </MobileInfoInnerContainerViewRowRightBodyTile>
                                            )
                                          }
                                        )
                                      }
                                    }
                                  )
                                }
                              })}
                          </MobileInfoViewMainBodyInnerInner>
                        </MobileInfoViewMainBodyInner>
                      </MobileInfoViewMainBody>
                    )}
                  </MobileInfoViewBody>
                </MobileInfoView>
              )}
              {tabIndex === 2 && (
                <MobileInfoView>
                  <ShowMobileInfoDetailHeader>
                    <ShowMobileInfoDetailHeaderInner>
                      <ShowMobileInfoDetailHeaderInnerLeft>
                        <ShowMobileInfoDetailHeaderInnerP>
                          STORE
                        </ShowMobileInfoDetailHeaderInnerP>
                      </ShowMobileInfoDetailHeaderInnerLeft>
                      <ShowMobileInfoDetailHeaderInnerRight></ShowMobileInfoDetailHeaderInnerRight>
                    </ShowMobileInfoDetailHeaderInner>
                  </ShowMobileInfoDetailHeader>
                  <MobileInfoViewBody>
                    <MobileInfoViewMainBody>
                      <MobileInfoViewMainBodyInner>
                        <MobileInfoViewMainBodyInnerInner>
                          {userProducts.map((userProduct) => {
                            return (
                              <MobileInfoInnerContainerViewRowRightBodyTile
                                key={userProduct.id}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                  }}
                                  onClick={() => {
                                    toggleUserProductVisibility(userProduct.id)
                                  }}
                                >
                                  {userProduct.title}
                                  <FontAwesomeIcon
                                    icon={
                                      userProduct.menuVisible
                                        ? faChevronUp
                                        : faChevronDown
                                    }
                                  />
                                </div>
                                {userProduct.menuVisible && (
                                  <div
                                    style={{
                                      paddingTop: 20,
                                      paddingBottom: 20,
                                    }}
                                  >
                                    {userProduct.imgUrl !== '' &&
                                      userProduct.imgUrl !== null && (
                                        <img
                                          alt=""
                                          src={`${userProduct.imgUrl}`}
                                          style={{
                                            maxWidth: '100%',
                                            width: '100%',
                                            objectFit: 'contain',
                                            margin: '15px 0',
                                            overflowClipMargin: 'content-box',
                                            overflow: 'clip',
                                            boxSizing: 'border-box',
                                          }}
                                        ></img>
                                      )}
                                    <MobileInfoInnerContainerViewRowRightBodyTileContent>
                                      <Editor
                                        editorState={EditorState.createWithContent(
                                          convertFromRaw(
                                            JSON.parse(userProduct.content)
                                          )
                                        )}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        toolbarHidden={true}
                                        readOnly={true}
                                      />
                                      <OpacityBtn
                                        disabled={false}
                                        backgroundColor={c_eba55d}
                                        fontColor={c_ffffff}
                                        onClick={() => {
                                          setUserProductId(userProduct.id)
                                          setPrice(userProduct.price)
                                          setPayplePaymentContent((pre) => {
                                            return {
                                              ...pre,
                                              pcd_pay_goods: userProduct.title,
                                              pcd_pay_total: userProduct.price,
                                              pcd_pay_taxtotal: Math.round(
                                                userProduct.price / 11
                                              ),
                                            }
                                          })
                                          setShowPaymentModal(true)
                                        }}
                                      >
                                        &#8361;
                                        {priceFormatFunction(
                                          userProduct.price
                                        )}{' '}
                                        checkout
                                      </OpacityBtn>
                                    </MobileInfoInnerContainerViewRowRightBodyTileContent>
                                  </div>
                                )}
                              </MobileInfoInnerContainerViewRowRightBodyTile>
                            )
                          })}
                        </MobileInfoViewMainBodyInnerInner>
                      </MobileInfoViewMainBodyInner>
                    </MobileInfoViewMainBody>
                  </MobileInfoViewBody>
                </MobileInfoView>
              )}
              {tabIndex === 3 && (
                <MapInnerContainerView>
                  {mapError && (
                    <MapInnerContainerViewError>
                      <MapInnerContainerViewErrorH2>
                        The address needs to be updated.
                      </MapInnerContainerViewErrorH2>
                    </MapInnerContainerViewError>
                  )}
                  {!mapError && isLoaded && (
                    <GoogleMap
                      mapContainerStyle={{ width: '100%', height: '100%' }}
                      center={{
                        lat: center.latitude,
                        lng: center.longitude,
                      }}
                      zoom={zoom}
                      onLoad={onLoad}
                      onUnmount={onUnmount}
                      options={{
                        styles: [
                          {
                            featureType: 'poi',
                            elementType: 'labels',
                            stylers: [{ visibility: 'off' }],
                          },
                        ],
                      }}
                    >
                      {categories.map((category) =>
                        category.subcategories.map((subcategory) =>
                          subcategory.topics.map((topic) => {
                            console.log(
                              'topic:',
                              topic.latitude,
                              topic.longitude,
                              topic.mapPinIconUrl
                            )
                            if (
                              topic.latitude &&
                              topic.longitude &&
                              topic.mapPinIconUrl
                            ) {
                              return (
                                <MarkerF
                                  key={topic.id}
                                  position={{
                                    lat: topic.latitude,
                                    lng: topic.longitude,
                                  }}
                                  draggable={false}
                                  icon={{
                                    url: topic.mapPinIconUrl,
                                    scaledSize: new window.google.maps.Size(
                                      40,
                                      40
                                    ),
                                  }}
                                  onClick={() => {
                                    setCenter({
                                      latitude: topic.latitude,
                                      longitude: topic.longitude,
                                    })
                                    setSelectCategory(category)
                                    setSelectCategoryId(category.id)
                                    setSelectSubcategoryId(subcategory.id)
                                    handleMarkerClick(topic)
                                  }}
                                />
                              )
                            }
                          })
                        )
                      )}
                      {console.log('position:', position)}
                      {position.latitude && position.longitude && (
                        <MarkerF
                          position={{
                            lat: position.latitude,
                            lng: position.longitude,
                          }}
                          draggable={false}
                          icon={{
                            url: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
                            scaledSize: new window.google.maps.Size(40, 40),
                          }}
                        />
                      )}
                      {selectedMarker && (
                        <InfoWindow
                          position={{
                            lat: selectedMarker.latitude,
                            lng: selectedMarker.longitude,
                          }}
                          onCloseClick={() => {
                            setSelectCategory(null)
                            setSelectCategoryId(null)
                            setSelectSubcategoryId(null)
                            setSelectedMarker(null)
                          }}
                          options={{
                            pixelOffset: new window.google.maps.Size(0, -30),
                          }}
                        >
                          <div
                            style={{
                              minWidth: '100px',
                              minHeight: '50px',
                              overflow: 'hidden',
                              borderRadius: '8px',
                              backgroundColor: 'white',
                              padding: '5px',
                              boxSizing: 'border-box',
                              wordWrap: 'break-word',
                              overflowWrap: 'break-word',
                            }}
                          >
                            <h3>{selectedMarker.title}</h3>
                            <div
                              style={{
                                maxHeight: '150px',
                                overflowY: 'auto',
                                marginTop: '10px',
                              }}
                            >
                              <Editor
                                editorState={EditorState.createWithContent(
                                  convertFromRaw(
                                    JSON.parse(selectedMarker.content)
                                  )
                                )}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                toolbarHidden={true}
                                readOnly={true}
                                mention={{
                                  separator: ' ',
                                  trigger: '@',
                                  suggestions: [
                                    {
                                      text: 'APPLE',
                                      value: 'apple',
                                    },
                                    {
                                      text: 'BANANA',
                                      value: 'banana',
                                      url: 'banana',
                                    },
                                    {
                                      text: 'CHERRY',
                                      value: 'cherry',
                                      url: 'cherry',
                                    },
                                    {
                                      text: 'DURIAN',
                                      value: 'durian',
                                      url: 'durian',
                                    },
                                    {
                                      text: 'EGGFRUIT',
                                      value: 'eggfruit',
                                      url: 'eggfruit',
                                    },
                                    {
                                      text: 'FIG',
                                      value: 'fig',
                                      url: 'fig',
                                    },
                                    {
                                      text: 'GRAPEFRUIT',
                                      value: 'grapefruit',
                                      url: 'grapefruit',
                                    },
                                    {
                                      text: 'HONEYDEW',
                                      value: 'honeydew',
                                      url: 'honeydew',
                                    },
                                  ],
                                }}
                              />
                            </div>
                            <div
                              onClick={() => {
                                setSelectCategoryId(selectCategoryId)
                                setSelectSubcategoryId(selectSubcategoryId)
                                setShowMobileInfoDetail(true)
                                toggleTopicVisibilityOnlyOpen(
                                  selectCategoryId,
                                  selectSubcategoryId,
                                  selectedMarker.id
                                )
                                setTabIndex(1)
                                setSelectedMarker(null)
                              }}
                              style={{
                                color: 'blue',
                                marginTop: '10px',
                              }}
                            >
                              More info
                            </div>
                            <div
                              style={{
                                display: 'flex',
                              }}
                            >
                              <div
                                onClick={() => {
                                  console.log(selectedMarker)
                                  const url = `https://www.google.com/maps/dir/?api=1&destination=${selectedMarker.latitude},${selectedMarker.longitude}`
                                  window.open(url, '_blank')
                                }}
                                style={{
                                  color: 'blue',
                                  marginTop: '20px',
                                }}
                              >
                                Google
                              </div>
                              <div
                                style={{
                                  marginTop: '20px',
                                  marginLeft: '10px',
                                  marginRight: '10px',
                                }}
                              >
                                |
                              </div>
                              <div
                                onClick={() => {
                                  console.log(selectedMarker)
                                  // const url = `https://map.naver.com/v5/?c=${selectedMarker.longitude},${selectedMarker.latitude},18,0,0,0,dh`
                                  const url = `https://map.naver.com/p/search/${selectedMarker.address}?c=15.00,0,0,0,dh`
                                  window.open(url, '_blank')
                                }}
                                style={{
                                  color: 'blue',
                                  marginTop: '20px',
                                }}
                              >
                                Naver
                              </div>
                            </div>
                          </div>
                        </InfoWindow>
                      )}
                    </GoogleMap>
                  )}
                </MapInnerContainerView>
              )}
              {tabIndex === 4 && (
                <SearchInnerContainerView>
                  <SearchInnerContainerViewInner
                    style={{
                      top: searchText.length === 0 ? '50%' : '75px',
                    }}
                  >
                    <SearchInnerContainerViewInnerDiv>
                      <SearchInnerContainerViewInnerDivInputWrapper>
                        <SearchInnerContainerViewInnerDivInputWrapperSpan>
                          <SearchIconFixed></SearchIconFixed>
                        </SearchInnerContainerViewInnerDivInputWrapperSpan>
                        <SearchInnerContainerViewInnerDivInputWrapperInput
                          autoComplete="off"
                          placeholder="Search for keywords"
                          value={searchText}
                          onChange={(e) => {
                            setSearchText(e.target.value)
                            let {
                              tempSubcategoriesFoundByTitle,
                              tempSubcategoriesFoundByContent,
                            } = searchSubcategories(e.target.value)
                            setSubcategoriesFoundByTitle(
                              tempSubcategoriesFoundByTitle
                            )
                            setSubcategoriesFoundByContent(
                              tempSubcategoriesFoundByContent
                            )
                          }}
                        ></SearchInnerContainerViewInnerDivInputWrapperInput>
                      </SearchInnerContainerViewInnerDivInputWrapper>
                    </SearchInnerContainerViewInnerDiv>
                    {searchText.length === 0 && (
                      <SearchInnerContainerViewInnerP>
                        Search results will appear featuring matches for all
                        your keywords.
                      </SearchInnerContainerViewInnerP>
                    )}
                    {searchText.length !== 0 &&
                      subcategoriesFoundByTitle.length === 0 &&
                      subcategoriesFoundByContent.length === 0 && (
                        <SearchInnerContainerViewInnerP>
                          No results found. Please try again.
                        </SearchInnerContainerViewInnerP>
                      )}
                    <SearchTileFrame>
                      {searchText.length !== 0 &&
                        subcategoriesFoundByTitle.length !== 0 && (
                          <>
                            <h4
                              style={{
                                fontWeight: 700,
                                fontSize: '1.125rem',
                                opacity: 0.8,
                                margin: '15px 0',
                              }}
                            >
                              Matches in a section heading
                            </h4>
                            <SearchTileWrapper>
                              {subcategoriesFoundByTitle.map((subcategory) => {
                                return (
                                  <SearchTile
                                    key={subcategory.subcategoryId}
                                    onClick={() => {
                                      setSelectCategoryId(
                                        subcategory.categoryId
                                      )
                                      setSelectSubcategoryId(
                                        subcategory.subcategoryId
                                      )
                                      setShowMobileInfoDetail(true)
                                      setTabIndex(1)
                                    }}
                                  >
                                    {subcategory.title}
                                  </SearchTile>
                                )
                              })}
                            </SearchTileWrapper>
                          </>
                        )}
                      {searchText.length !== 0 &&
                        subcategoriesFoundByContent.length !== 0 && (
                          <>
                            <h4
                              style={{
                                fontWeight: 700,
                                fontSize: '1.125rem',
                                opacity: 0.8,
                                margin: '15px 0',
                              }}
                            >
                              Matches in a section description
                            </h4>
                            <SearchTileWrapper>
                              {subcategoriesFoundByContent.map(
                                (subcategory) => {
                                  return (
                                    <SearchTile
                                      key={subcategory.subcategoryId}
                                      onClick={() => {
                                        setSelectCategoryId(
                                          subcategory.categoryId
                                        )
                                        setSelectSubcategoryId(
                                          subcategory.subcategoryId
                                        )
                                        setShowMobileInfoDetail(true)
                                        setTabIndex(1)
                                      }}
                                    >
                                      {subcategory.title}
                                    </SearchTile>
                                  )
                                }
                              )}
                            </SearchTileWrapper>
                          </>
                        )}
                    </SearchTileFrame>
                  </SearchInnerContainerViewInner>
                </SearchInnerContainerView>
              )}
              <Footer>
                <FooterNav>
                  {tabList.map((tab) => {
                    return (
                      <FooterNavTab
                        key={tab.id}
                        id={tab.id}
                        onClick={() => {
                          if (tab.id !== 2) {
                            setPosition({
                              latitude:
                                guide.latitude === null
                                  ? 37.5665
                                  : guide.latitude,
                              longitude:
                                guide.longitude === null
                                  ? 126.978
                                  : guide.longitude,
                            })
                            setCenter({
                              latitude:
                                guide.latitude === null
                                  ? 37.5665
                                  : guide.latitude,
                              longitude:
                                guide.longitude === null
                                  ? 126.978
                                  : guide.longitude,
                            })
                            setMapError(
                              guide.latitude === null ||
                                guide.longitude === null
                                ? true
                                : false
                            )
                          }
                          setShowMobileSubcategoriesModal(false)
                          setTabIndex(tab.id)
                        }}
                        $tabIndex={tabIndex}
                        $index={tab.id}
                      >
                        {tab.title}
                      </FooterNavTab>
                    )
                  })}
                </FooterNav>
              </Footer>
            </InnerContainer>
          </TotalContainer>
        ))}
      {showMobileSubcategoriesModal && (
        <ShowMobileSubcategoriesModalContainer
          onClick={() => {
            setShowMobileSubcategoriesModal(false)
          }}
        >
          <ShowMobileSubcategoriesModal
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <ShowMobileSubcategoriesModalCircleIconPart>
              <ShowMobileSubcategoriesModalCircleIconPartIcon
                src={selectCategory.iconUrl}
                alt=""
              ></ShowMobileSubcategoriesModalCircleIconPartIcon>
            </ShowMobileSubcategoriesModalCircleIconPart>
            <ShowMobileSubcategoriesModalXIcon
              onClick={() => {
                setShowMobileSubcategoriesModal(false)
              }}
            >
              <XIconFixed></XIconFixed>
            </ShowMobileSubcategoriesModalXIcon>
            <ShowMobileSubcategoriesModalTitle>
              {categories.map((category) => {
                if (category.id === selectCategoryId) {
                  return category.title
                }
              })}
            </ShowMobileSubcategoriesModalTitle>
            <ShowMobileSubcategoriesModalBody>
              {categories.map((category) => {
                if (category.id === selectCategoryId) {
                  return category.subcategories.map((subcategory) => {
                    return (
                      <ShowMobileSubcategoriesModalBodyTile
                        key={subcategory.id}
                        onClick={() => {
                          setSelectSubcategoryId(subcategory.id)
                          setShowMobileSubcategoriesModal(false)
                          setShowMobileInfoDetail(true)
                        }}
                      >
                        {subcategory.title}
                        <ArrowRightIconFixed></ArrowRightIconFixed>
                      </ShowMobileSubcategoriesModalBodyTile>
                    )
                  })
                }
              })}
            </ShowMobileSubcategoriesModalBody>
          </ShowMobileSubcategoriesModal>
        </ShowMobileSubcategoriesModalContainer>
      )}
      {showMoveTo1330Modal && (
        <StandardModal
          titleText={'Korea Travel Helpline'}
          modalBodyComponent={
            <div>
              <img
                src={image_1330}
                alt=""
                style={{
                  width: '100%',
                  height: 'auto',
                }}
              ></img>
              <div
                style={{
                  textAlign: 'center',
                }}
              >
                1330 Korea Travel Helpline is a real-time chat service.<br></br>
                <br></br>
                <br></br>English, Korean, Japanese, Chinese<br></br>24 hours a
                day, all year round<br></br>
                <br></br>We provide tourists from within and outside Korea with
                information about traveling in Korea.<br></br>
                <br></br>CLICK TO START LIVE CHAT
              </div>
            </div>
          }
          onRightBtnClick={() => {
            window.open(
              'https://1330chat.visitkorea.or.kr:3000/#/ttalk_main/CHAT1330_160635739001093018/_0300_0100_main.do',
              '_blank'
            )
          }}
          onClose={() => {
            setShowMoveTo1330Modal(false)
          }}
          leftBtnText={'CANCEL'}
          rightBtnText={'MOVE TO 1330'}
        ></StandardModal>
      )}
      {showPaymentModal && (
        <NormalModal
          titleText={'Payment'}
          modalBodyComponent={
            <ActivateModalBody>
              <ActivateModalDivSection>
                <ActivateModalTitleDiv>
                  <ActivateModalTitle>Payment Category</ActivateModalTitle>
                </ActivateModalTitleDiv>
                <IndexToggleBtn
                  index_toggle_data={[
                    { id: 0, title: 'International(국제) Card' },
                    { id: 1, title: 'Domestic(국내) Card' },
                  ]}
                  frameWidth={'66.66667%'}
                  btnPadding={'12px 20px'}
                  btnWidth={'50%'}
                  selectIndex={selectPaymentCategoryIndex}
                  setSelectIndex={setSelectPaymentCategoryIndex}
                ></IndexToggleBtn>
              </ActivateModalDivSection>
              {selectPaymentCategoryIndex === 0 && (
                <>
                  <TitleDiv>First Name *</TitleDiv>
                  <TextInput
                    backgroundColor="#fff"
                    value={firstNameOfPayment}
                    onChange={(e) => {
                      setFirstNameOfPayment(e.target.value)
                      setPayplePaymentContent((pre) => {
                        return {
                          ...pre,
                          pcd_payer_first_name: e.target.value,
                        }
                      })
                    }}
                  ></TextInput>
                  <TitleDiv>Last Name *</TitleDiv>
                  <TextInput
                    backgroundColor="#fff"
                    value={lastNameOfPayment}
                    onChange={(e) => {
                      setLastNameOfPayment(e.target.value)
                      setPayplePaymentContent((pre) => {
                        return {
                          ...pre,
                          pcd_payer_last_name: e.target.value,
                        }
                      })
                    }}
                  ></TextInput>
                </>
              )}
              {selectPaymentCategoryIndex === 1 && (
                <>
                  <TitleDiv>이름 *</TitleDiv>
                  <TextInput
                    backgroundColor="#fff"
                    value={nameOfPayment}
                    onChange={(e) => {
                      setNameOfPayment(e.target.value)
                      setPayplePaymentContent((pre) => {
                        return {
                          ...pre,
                          pcd_payer_name: e.target.value,
                        }
                      })
                    }}
                  ></TextInput>
                </>
              )}

              <>
                <TitleDiv>
                  {' '}
                  {selectPaymentCategoryIndex === 0
                    ? 'Phone Number *'
                    : '핸드폰 번호 *'}
                </TitleDiv>
                <TextInput
                  backgroundColor="#fff"
                  placeholder={
                    selectPaymentCategoryIndex === 0
                      ? 'Please write without -'
                      : '- 없이 작성해주세요.'
                  }
                  value={phoneNumberOfPayment}
                  onChange={(e) => {
                    setPhoneNumberOfPayment(e.target.value)
                    setPayplePaymentContent((pre) => {
                      return {
                        ...pre,
                        pcd_payer_hp: e.target.value,
                      }
                    })
                  }}
                ></TextInput>
              </>
              <>
                <TitleDiv>
                  {selectPaymentCategoryIndex === 0
                    ? 'Email *'
                    : '이메일 주소 *'}
                </TitleDiv>
                <div
                  style={{
                    paddingBottom: '15px',
                    display: 'flex',
                    color: '#000 !important',
                    fontFamily: 'Circular Std Book Bold, Tahoma, SansSerif',
                    boxSizing: 'border-box',
                  }}
                >
                  {selectPaymentCategoryIndex === 0
                    ? 'The receipt will be sent to you via email.'
                    : '이메일로 결제 내역이 전송됩니다.'}
                </div>
                <TextInput
                  backgroundColor="#fff"
                  value={emailOfPayment}
                  onChange={(e) => {
                    setEmailOfPayment(e.target.value)
                    setPayplePaymentContent((pre) => {
                      return {
                        ...pre,
                        pcd_payer_email: e.target.value,
                      }
                    })
                  }}
                ></TextInput>
              </>

              <ActivateModalDivSection>
                <ActivateModalTitleDiv>
                  <ActivateModalTitle>
                    {selectPaymentCategoryIndex === 0
                      ? 'Payment Method'
                      : '결제 방법'}
                  </ActivateModalTitle>
                </ActivateModalTitleDiv>
                <IndexToggleBtn
                  index_toggle_data={[{ id: 0, title: 'Card' }]}
                  frameWidth={'66.66667%'}
                  btnPadding={'12px 20px'}
                  btnWidth={'50%'}
                  selectIndex={selectPaymentTypeIndex}
                  setSelectIndex={setSelectPaymentTypeIndex}
                ></IndexToggleBtn>
              </ActivateModalDivSection>
              <ActivateModalDivSection>
                <ActivateModalTitle2Div>
                  <ActivateModalTitle>
                    {selectPaymentCategoryIndex === 0
                      ? 'Number Of Products'
                      : '수량'}
                  </ActivateModalTitle>
                </ActivateModalTitle2Div>
                <NumberInputFrame>
                  <MinusCircleIcon
                    onClick={() => {
                      if (numberOfProducts > 1) {
                        setNumberOfProducts((pre) => pre - 1)
                      }
                    }}
                    active={numberOfProducts > 1}
                  ></MinusCircleIcon>
                  {numberOfProducts}
                  <PlusCircleIcon
                    onClick={() => {
                      setNumberOfProducts((pre) => pre + 1)
                    }}
                  ></PlusCircleIcon>
                </NumberInputFrame>
              </ActivateModalDivSection>
              <PriceText>
                {selectPaymentCategoryIndex === 0 ? 'Total Price' : '합계'}:{' '}
                {priceFormatFunction(price * numberOfProducts)}
                {selectPaymentCategoryIndex === 0 ? ' won' : '원'}
              </PriceText>
              <AgreeDiv>
                <CheckBox
                  checked={activateAgreeChecked}
                  onClick={() => {
                    setActivateAgreeChecked((pre) => !pre)
                  }}
                ></CheckBox>
                {selectPaymentCategoryIndex === 0 && (
                  <AgreeText>
                    I agree to the payment for this service, and I consent that
                    any refunds and cancellations will be processed according to
                    the regulations specified in{' '}
                    <a
                      href={store_terms_and_conditions_inner_router_url}
                      target="_blank"
                      style={{
                        color: '#eba55d',
                        textDecoration: 'none',
                      }}
                      rel="noreferrer"
                    >
                      the terms and conditions.
                    </a>{' '}
                    and{' '}
                    <a
                      href={store_privacy_policy_inner_router_url}
                      target="_blank"
                      style={{
                        color: '#eba55d',
                        textDecoration: 'none',
                      }}
                      rel="noreferrer"
                    >
                      the non-member personal information processing policy.
                    </a>
                  </AgreeText>
                )}
                {selectPaymentCategoryIndex === 1 && (
                  <AgreeText>
                    본 서비스의 결제 내역을 확인했으며,{' '}
                    <a
                      href={store_terms_and_conditions_inner_router_url}
                      target="_blank"
                      style={{
                        color: '#eba55d',
                        textDecoration: 'none',
                      }}
                      rel="noreferrer"
                    >
                      이용 약관
                    </a>
                    에 명시된 규정 및{' '}
                    <a
                      href={store_privacy_policy_inner_router_url}
                      target="_blank"
                      style={{
                        color: '#eba55d',
                        textDecoration: 'none',
                      }}
                      rel="noreferrer"
                    >
                      비회원 개인정보 처리방침
                    </a>
                    에 동의합니다.
                  </AgreeText>
                )}
              </AgreeDiv>
            </ActivateModalBody>
          }
          onRightBtnClick={async (e) => {
            showLoading()

            if (selectPaymentCategoryIndex === 0) {
              if (firstNameOfPayment === '') {
                show('Please write the first name')
              } else {
                if (lastNameOfPayment === '') {
                  show('Please write the last name')
                } else {
                  if (phoneNumberOfPayment === '') {
                    show('Please write the phone number')
                  } else {
                    e.preventDefault()
                    const response = await postInternationalPaymentAuth()
                    console.log(response.data.data)

                    const obj = {}

                    obj.Authorization = response.data.data.access_token
                    // 테스트 service_id
                    // obj.service_id = 'demo'
                    // 실결제 service_id
                    obj.service_id = 'doogomkr'
                    obj.comments = payplePaymentContent.pcd_pay_goods
                    obj.totalAmount = parseInt(
                      payplePaymentContent.pcd_pay_total
                    )
                    obj.currency = 'KRW'
                    obj.firstName = payplePaymentContent.pcd_payer_first_name
                    obj.lastName = payplePaymentContent.pcd_payer_last_name
                    obj.email = payplePaymentContent.pcd_payer_email
                    obj.resultUrl = `${doogom_com_server_router_url}/show-guide/post-show-guide-store-international-payment-result/${token}/${userProductId}/${
                      selectPaymentCategoryIndex === 1
                        ? 'card'
                        : 'international card'
                    }/${numberOfProducts}/${price * numberOfProducts}/${
                      payplePaymentContent.pcd_payer_first_name
                    }/${payplePaymentContent.pcd_payer_last_name}/${
                      payplePaymentContent.pcd_payer_email
                    }/${payplePaymentContent.pcd_payer_hp}`
                    // obj.payCls = 'demo'
                    console.log(obj)

                    await window.paypleGpayPaymentRequest(obj)

                    sessionStorage.setItem('internationalPaymentIn', 'true')
                  }
                }
              }
            } else if (selectPaymentCategoryIndex === 1) {
              if (phoneNumberOfPayment === '') {
                show('Please write the phone number')
              } else {
                if (
                  koreanPhoneNumberCheck.test(phoneNumberOfPayment) === false
                ) {
                  show('Please write the correct phone number')
                } else {
                  if (nameOfPayment === '') {
                    show('Please write the name')
                  } else {
                    paypleDomesticPaymentClick(e)
                  }
                }
              }
            }

            hideLoading()
          }}
          onClose={() => {
            setSelectPaymentCategoryIndex(0)
            setNameOfPayment('')
            setFirstNameOfPayment('')
            setLastNameOfPayment('')
            setPhoneNumberOfPayment('')
            setEmailOfPayment('')
            setNumberOfProducts(1)
            setSelectPaymentTypeIndex(0)
            setActivateAgreeChecked(false)
            setPayplePaymentContent((pre) => {
              return {
                ...pre,
                pcd_payer_name: '',
                pcd_payer_hp: '',
                pcd_payer_email: '',
                pcd_pay_type: 'card',
                pcd_pay_total: 0,
                pcd_pay_taxtotal: 0,
              }
            })
            setShowPaymentModal(false)
          }}
          leftBtnText={selectPaymentCategoryIndex === 0 ? 'Cancel' : '취소'}
          rightBtnText={selectPaymentCategoryIndex === 0 ? 'Pay' : '결제'}
          rightBtnDisabled={
            !activateAgreeChecked ||
            emailOfPayment === '' ||
            emailCheck.test(emailOfPayment) === false
          }
          questionSection={<p>---</p>}
          questionDefaultWidth={500}
          questionChangeWidth={180}
        ></NormalModal>
      )}
    </>
  )
}

export default ShowGuidePage

const ShowMobileInfoDetailHeader = styled.header`
  background-color: #b49275;
  width: 100%;
  color: #fff;
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  box-sizing: border-box;
`

const ShowMobileInfoDetailHeaderInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
`

const ShowMobileInfoDetailHeaderInnerLeft = styled.div`
  max-width: 75%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
`

const ShowMobileInfoDetailHeaderInnerIconStyle = styled.div`
  font-size: 25px;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  margin-left: 18;
`

const ShowMobileInfoDetailHeaderInnerP = styled.p`
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  padding-left: 15px;
  text-overflow: ellipsis;
  font-size: 1.25rem;
  font-weight: 700;
  overflow: hidden;
  white-space: nowrap;
  line-height: 1.375;
`

const ShowMobileInfoDetailHeaderInnerRight = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
`

const ShowMobileSubcategoriesModalContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.5);
`

const ShowMobileSubcategoriesModal = styled.div`
  position: fixed;
  background-color: #fff;
  min-height: 100px;
  width: 95%;
  border-radius: 4px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99999999;
  max-width: 500px;
  padding-top: 45px;
  box-sizing: border-box;
`

const ShowMobileSubcategoriesModalCircleIconPart = styled.div`
  position: absolute;
  left: 50%;
  top: 0;
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 10px #0000001a;
  box-sizing: border-box;
`

const ShowMobileSubcategoriesModalCircleIconPartIcon = styled.img`
  color: #eba55d;
  font-size: 3.125rem;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
`

const ShowMobileSubcategoriesModalXIcon = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 1.5625rem;
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  box-sizing: border-box;
`

const ShowMobileSubcategoriesModalTitle = styled.div`
  font-size: 1.25rem;
  margin-top: 20px;
  text-align: center;
  box-sizing: border-box;
`

const ShowMobileSubcategoriesModalBody = styled.div`
  padding: 20px;
  max-height: 300px;
  overflow: auto;
  box-sizing: border-box;
`

const ShowMobileSubcategoriesModalBodyTile = styled.div`
  text-align: left;
  font-size: 0.9375rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px 0;
  box-sizing: border-box;
  cursor: pointer;
`

const MobileInfoView = styled.div`
  height: 100%;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  box-sizing: border-box;

  @media (max-width: 1200px) {
    padding: 0;
  }
`

const MobileInfoViewHeader = styled.header`
  background-color: #b49275;
  width: 100%;
  color: #fff;
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  box-sizing: border-box;
`

const MobileInfoViewHeaderInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
`

const MobileInfoViewHeaderInnerLeft = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
`

const MobileInfoViewHeaderInnerRight = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
`

const MobileInfoViewBody = styled.div`
  position: relative;
  background-color: #f3f3f3;
  box-sizing: border-box;

  @media (max-width: 1200px) {
    display: block;
    height: calc(100% - 70px);
    padding: 8px 8px 0;
  }
`

const MobileInfoViewMainBody = styled.div`
  overflow: auto;
  position: relative;
  box-sizing: border-box;

  @media (max-width: 1200px) {
    box-shadow: 0 0 10px #0000001a;
    width: 100%;
    height: 100%;
    max-width: 100%;
    padding: 0;
  }
`

const MobileInfoViewMainBodyInner = styled.div`
  position: relative;
  height: 100%;
  border-radius: 4px;
  box-shadow: 0 0 10px #0000001a;
  box-sizing: border-box;
  display: block;
`

const MobileInfoViewMainBodyInnerInner = styled.aside`
  height: 100%;
  background-color: #f9f9f9;
  overflow: auto;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
`

const MobileInfoViewMainBodyInnerInnerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-radius: 4px;
  box-sizing: border-box;
`

const MobileInfoViewMainBodyInnerInnerWrapperCard = styled.div`
  width: 50%;
  height: 33%;
  max-height: 140px;
  min-height: 140px;
  padding: 20px;
  z-index: 0;
  position: relative;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  // 오른쪽 테두리 생성
  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 5%; // 위쪽에서 10%의 여백을 둠 (테두리 길이를 80%로 조절)
    height: 90%; // 테두리 길이를 카드 높이의 80%로 설정
    border-right: 1px solid #0000001a;
  }

  // 아래쪽 테두리 생성
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 5%; // 왼쪽에서 10%의 여백을 둠 (테두리 길이를 80%로 조절)
    width: 90%; // 테두리 길이를 카드 너비의 80%로 설정
    border-bottom: 1px solid #0000001a;
  }

  // 각 줄의 마지막 카드에서 오른쪽 테두리 제거
  &:nth-child(2n)::after {
    border-right: none;
  }

  /* 전체 항목 수가 짝수일 때 마지막 두 항목에서 아래쪽 테두리 제거 */
  ${({ $categoriesLength }) =>
    $categoriesLength % 2 === 0 &&
    `
    &:nth-last-child(-n+2):before {
      border-bottom: none;
    }
  `}

  /* 전체 항목 수가 홀수일 때 마지막 항목에서 아래쪽 테두리 제거 */
  ${({ $categoriesLength }) =>
    $categoriesLength % 2 !== 0 &&
    `
    &:nth-last-child(1):before {
      border-bottom: none;
    }
  `}
`

const MobileInfoViewMainBodyInnerInnerWrapperCardThumbnail = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  min-height: 100%;
  box-sizing: border-box;
`

const MobileInfoViewMainBodyInnerInnerWrapperCardThumbnailIconStyle = styled.div`
  color: #eba55d;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  @media (max-width: 1200px) {
    margin-bottom: 15px !important;
    font-size: 35px !important;
  }
`

const MobileInfoViewMainBodyInnerInnerWrapperCardThumbnailDiv = styled.div`
  user-select: none;
  max-width: 100%;
  line-height: 1.3;
  overflow: hidden;
  box-sizing: border-box;
`

const TotalContainer = styled.div`
  font-family: 'Circular Book', Arial, 'Helvetica Neue', Helvetica, sans-serif;
  height: calc(var(--vh, 1vh) * 100) !important;
  position: relative;
  box-sizing: border-box;
`

const InnerContainer = styled.div`
  position: relative;
  width: 100vw;
  height: calc(100% - 70px);
  background-color: #f3f3f3;
  box-sizing: border-box;
`

const HomeInnerContainerView = styled.div`
  width: 100vw;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  touch-action: auto !important;
  user-select: auto !important;
  box-sizing: border-box;
`

const InnerContainerViewOverlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: calc(100% - 70px);
  z-index: 50;
  background-color: var(--20cedac2);
  opacity: calc(var(--4a1e31ce) / 100);
  box-sizing: border-box;
`

const InnerContainerViewCenterDiv = styled.div`
  color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  flex-direction: column;
  animation: blurred 1s cubic-bezier(0.23, 1, 0.32, 1) both;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 99;
  filter: blur(0);
  font-size: 1.25rem;
  text-align: center;
  font-family: var(--51fc11a8);
  box-sizing: border-box;
`

const InnerContainerViewCenterDivImg = styled.img`
  border-radius: 0;
  border: none;
  object-fit: contain;
  width: 110px;
  height: 110px;
  margin-bottom: 1.5rem;
  color: inherit;
  box-sizing: border-box;
  overflow-clip-margin: content-box;
  overflow: clip;
`

const InnerContainerViewCenterDivP = styled.p`
  font-size: 1.5rem;
  white-space: nowrap;
  line-height: 1.5;
  margin: 0 0 1.5rem;
  box-sizing: border-box;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
`

const InnerContainerViewCenterDivContactDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 700;
  font-size: 1.0625rem;
  box-sizing: border-box;
`

const InnerContainerViewCenterDivContactDivSpan = styled.span`
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  font-weight: 400;
  margin-left: 1rem;
  box-sizing: border-box;
`

const InnerContainerViewCenterDivDivideSpan = styled.span`
  background-color: #eba55d;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  width: 2.5rem;
  height: 2px;
  margin: 1rem 0;
  display: inline-block;
  box-sizing: border-box;
`

const InnerContainerViewCenterDivSpan = styled.span`
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
`

const InnerContainerViewBackgroundImg = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  box-sizing: border-box;
  overflow-clip-margin: content-box;
  overflow: clip;
`

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3); // 반투명 검은색 오버레이
  z-index: 2;
`

const Footer = styled.div`
  bottom: 0;
  position: fixed;
  width: 100%;
  height: 70px;
  overflow: hidden;
  z-index: 500;
  box-shadow: 0 -10px 51px #00000040;
  transition: all 0.3s ease-in-out;
  box-sizing: border-box;
`

const FooterNav = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 70px;
  box-sizing: border-box;
`

const FooterNavTab = styled.div`
  background-color: ${(props) =>
    props.$tabIndex === props.$index ? '#eba55d' : '#b49275'};
  flex: 1 1 0;
  height: 100%;
  transition: all 0.3s ease-in-out;
  font-weight: 700;
  letter-spacing: 0.125rem;
  display: flex;
  font-size: 12px;
  text-transform: uppercase;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #fff;
  box-sizing: border-box;
`

const InfoInnerContainerView = styled.div`
  height: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  box-sizing: border-box;

  @media (min-width: 1200px) {
    width: 1170px;
  }

  @media (max-width: 1200px) {
    padding: 0;
  }
`

const InfoInnerContainerViewRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  background-color: #f3f3f3;
  box-sizing: border-box;

  @media (min-width: 1200px) {
    height: 100%;
  }

  @media (max-width: 1200px) {
    display: block;
    height: calc(100% - 70px);
    padding: 8px 8px 0;
  }
`

const InfoInnerContainerViewRowLeft = styled.div`
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
  overflow: auto;
  position: relative;
  padding: 15px 15px 0;
  height: 100%;
  box-sizing: border-box;

  @media (max-width: 1200px) {
    width: 100%;
    height: 100%;
    max-width: 100%;
    padding: 0;
    box-shadow: 0 0 10px #0000001a;
    display: none;
  }
`

const InfoInnerContainerViewRowLeftInner = styled.div`
  position: relative;
  height: 100%;
  border-radius: 4px;
  box-shadow: 0 0 10px #0000001a;
  box-sizing: border-box;

  @media (min-width: 1200px) {
    box-shadow: 0 0 10px #0000001a;
    min-height: unset;
    height: 100%;
    border-radius: 0;
  }
`

const InfoInnerContainerViewRowLeftInnerHeader = styled.div`
  background-color: #b49275;
  width: 100%;
  color: #fff;
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  box-sizing: border-box;

  @media (min-width: 1200px) {
    border-radius: 4px 4px 0 0;
  }
`

const InfoInnerContainerViewRowLeftInnerHeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
`

const InfoInnerContainerViewRowLeftInnerHeaderRowLeft = styled.div`
  max-width: 75%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
`

const InfoInnerContainerViewRowLeftInnerHeaderRowLeftImg = styled.img`
  max-width: 50%;
  min-width: 45px;
  height: 45px;
  object-fit: contain;
  box-sizing: border-box;
  overflow-clip-margin: content-box;
  overflow: clip;
`

const InfoInnerContainerViewRowLeftInnerHeaderRowIconStyle = styled.div`
  margin-left: 18px;
  font-size: 25px;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
`

const InfoInnerContainerViewRowLeftInnerHeaderRowRight = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
`

const InfoInnerContainerViewRowLeftInnerBody = styled.div`
  height: 100%;
  background-color: #f9f9f9;
  overflow: auto;
  border-radius: 4px;
  box-sizing: border-box;

  @media (min-width: 1200px) {
    height: calc(100% - 70px);
  }
`

const InfoInnerContainerViewRowLeftInnerBodyDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-radius: 4px;
  box-sizing: border-box;

  @media (min-width: 1200px) {
    display: block;
    padding: 14px;
  }
`

const InfoInnerContainerViewRowLeftInnerBodyDivTile = styled.div`
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  width: 100%;
  background-color: #fff;
  max-width: 100%;
  height: unset;
  max-height: unset;
  min-height: unset;
  border-radius: 4px;
  padding: 14px;
  box-shadow: 0 0 5px 1px #0000001a;
  font-size: 1rem;
`

const InfoInnerContainerViewRowRight = styled.div`
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
  position: relative;
  padding: 15px 15px 0;
  height: 100%;
  box-sizing: border-box;

  @media (max-width: 1200px) {
    width: 100%;
    height: 100%;
    max-width: 100%;
    padding: 0;
  }
`

const InfoInnerContainerViewRowRightHeader = styled.div`
  background-color: #b49275;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  color: #fff;
  padding: 0 21px 0 14px;
  border-radius: 4px 4px 0 0;
  box-shadow: 0 10px 51px #00000026;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  box-sizing: border-box;

  @media (max-width: 1200px) {
    display: none;
  }
`

const InfoInnerContainerViewRowRightBody = styled.div`
  box-sizing: border-box;
  padding: 14px 14px 0;
  position: relative;
  background-color: #f9f9f9;
  height: calc(100% - 70px);
  overflow: auto;
  box-shadow: 0 0 10px #0000001a;

  @media (max-width: 1200px) {
    height: 100%;
    overflow: auto;
    scroll-behavior: smooth;
  }
`

const MobileInfoInnerContainerViewRowRightBodyTile = styled.div`
  box-shadow: 0 0 5px 1px #0000001a;
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 1rem;
  margin-bottom: 14px;
  box-sizing: border-box;
  max-width: 100%;
  cursor: pointer;

  @media (min-width: 1200px) {
    padding: 14px;
  }
`

const MobileInfoInnerContainerViewRowRightBodyTileContent = styled.div`
  font-size: 1rem;
  box-sizing: border-box;
  padding: 8px 16px;

  @media (min-width: 1200px) {
    padding: 8px 16px 8px 24px;
  }
`

const InfoInnerContainerViewRowRightBodyTile = styled.div`
  box-shadow: 0 0 5px 1px #0000001a;
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 1rem;
  margin-bottom: 14px;
  box-sizing: border-box;
  max-width: 100%;
  cursor: pointer;

  @media (min-width: 1200px) {
    padding: 14px;
  }
`

const InfoInnerContainerViewRowRightBodyTileContent = styled.div`
  font-size: 1rem;
  box-sizing: border-box;
  padding: 8px 16px;

  @media (min-width: 1200px) {
    padding: 8px 16px 8px 24px;
  }
`

const MapInnerContainerView = styled.div`
  height: 100%;
  box-sizing: border-box;
`

const MapInnerContainerViewError = styled.div`
  width: 100vw;
  height: 100%;
  display: flex;
  position: absolute;
  z-index: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 0 10%;
  box-sizing: border-box;
`

const MapInnerContainerViewErrorH2 = styled.div`
  line-height: 1.222;
  font-size: 1.5rem;
  font-weight: 400;
  color: #16293c;
  box-sizing: border-box;
`

const SearchInnerContainerView = styled.div`
  height: 100%;
  overflow: auto;
  background-color: #f8f8f8;
  padding: 15px;
  font-size: 1rem;
  position: relative;
  box-sizing: border-box;

  @media (min-width: 1200px) {
    width: 50%;
    margin: 0 auto;
  }
`

const SearchInnerContainerViewInner = styled.div`
  width: calc(100% - 30px);
  left: 0;
  position: absolute;
  margin: 0 15px;
  transform: translateY(-50%);
  transition: top 0.5s ease-in-out;
  box-sizing: border-box;
`

const SearchInnerContainerViewInnerDiv = styled.div`
  font-family: 'Circular Book', Arial, 'Helvetica Neue', Helvetica, sans-serif;
  font-weight: 700;
  position: relative;
  font-size: 14px;
  display: inline-flex;
  width: 100%;
  line-height: 32px;
  box-sizing: border-box;
  vertical-align: middle;
`

const SearchInnerContainerViewInnerP = styled.p`
  text-align: justify;
  font-size: 1.125rem;
  line-height: 1.5;
  padding: 0 10px;

  @media (min-width: 1200px) {
    font-size: 1.25rem !important;
  }
`

const SearchInnerContainerViewInnerDivInputWrapper = styled.div`
  display: inline-flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: 1px 11px;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  transition: box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transform: translateZ(0);
  box-shadow: 0 0 0 1px #dcdfe6 inset;
  box-sizing: border-box;
`

const SearchInnerContainerViewInnerDivInputWrapperSpan = styled.span`
  display: flex;
  align-items: center;
  white-space: nowrap;
  flex-shrink: 0;
  flex-wrap: nowrap;
  height: 100%;
  text-align: center;
  color: #a8abb2;
  transition: all 0.3s;
  pointer-events: none;
  margin-right: 11px;
`

const SearchInnerContainerViewInnerDivInputWrapperInput = styled.input`
  border: 1px solid transparent;
  color: #303133;
  height: 60px;
  font-size: 1.25rem;
  font-family: inherit;
  box-shadow: none;
  width: 100%;
  flex-grow: 1;
  -webkit-appearance: none;
  line-height: calc(32px - 2px);
  padding: 0;
  outline: 0;
  background: 0 0;
  box-sizing: border-box;
`

const SearchTileFrame = styled.div`
  transform: translateY(0);
  position: absolute;
  left: 0;
  width: 100%;
  top: 75px;
  padding: 0 15px;
  transition: all 0.5s ease-in-out;
  box-sizing: border-box;
`

const SearchTileWrapper = styled.ul`
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  @media (min-width: 1200px) {
    display: flex;
    flex-wrap: wrap;
  }
`

const SearchTile = styled.li`
  box-shadow: 0 0 5px 1px #0000001a;
  border-radius: 4px;
  background-color: #fff;
  padding: 15px;
  font-weight: 700;
  font-size: 1rem;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 15px;
  box-sizing: border-box;
  cursor: pointer;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 10px;
  margin-inline-end: 10px;
  padding-inline-start: 40px;

  @media (min-width: 1200px) {
    flex: 1 0 45%;
  }
`

const StoreInnerContainerView = styled.div`
  height: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  box-sizing: border-box;

  @media (min-width: 1200px) {
    width: 1170px;
  }

  @media (max-width: 1200px) {
    padding: 0;
  }
`

const StoreInnerContainerViewRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  background-color: #f3f3f3;
  box-sizing: border-box;
  justify-content: center;

  @media (min-width: 1200px) {
    height: 100%;
  }

  @media (max-width: 1200px) {
    display: block;
    height: calc(100% - 70px);
    padding: 8px 8px 0;
  }
`

const StoreInnerContainerViewRowRight = styled.div`
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
  position: relative;
  padding: 15px 15px 0;
  height: 100%;
  box-sizing: border-box;

  @media (max-width: 1200px) {
    width: 100%;
    height: 100%;
    max-width: 100%;
    padding: 0;
  }
`

const StoreInnerContainerViewRowRightHeader = styled.div`
  background-color: #b49275;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  color: #fff;
  padding: 0 21px 0 14px;
  border-radius: 4px 4px 0 0;
  box-shadow: 0 10px 51px #00000026;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  box-sizing: border-box;

  @media (max-width: 1200px) {
    display: none;
  }
`

const StoreInnerContainerViewRowRightBody = styled.div`
  box-sizing: border-box;
  padding: 14px 14px 0;
  position: relative;
  background-color: #f9f9f9;
  height: calc(100% - 70px);
  overflow: auto;
  box-shadow: 0 0 10px #0000001a;

  @media (max-width: 1200px) {
    height: 100%;
    overflow: auto;
    scroll-behavior: smooth;
  }
`

const StoreInnerContainerViewRowRightBodyTile = styled.div`
  box-shadow: 0 0 5px 1px #0000001a;
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 1rem;
  margin-bottom: 14px;
  box-sizing: border-box;
  max-width: 100%;
  cursor: pointer;

  @media (min-width: 1200px) {
    padding: 14px;
  }
`

const StoreInnerContainerViewRowRightBodyTileContent = styled.div`
  font-size: 1rem;
  box-sizing: border-box;
  padding: 8px 16px;

  @media (min-width: 1200px) {
    padding: 8px 16px 8px 24px;
  }
`

const ActivateModalBody = styled.div`
  color: #000;
  font-size: 14px;
  word-break: break-all;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    width: 100%;
    padding: 0 10px;
  }
`

const ActivateModalDivSection = styled.div`
  padding-top: 15px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    padding-top: 0px;
    flex-direction: column;
  }
`

const ActivateModalTitleDiv = styled.div`
  width: 33.33333%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    width: 100%;
  }
`

const ActivateModalTitle = styled.span`
  font-size: 1rem;
  padding: 15px 0;
  display: flex;
  color: #000 !important;
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  box-sizing: border-box;
`

const ActivateModalTitle2Div = styled.div`
  width: 33.33333%;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    width: 100%;
  }
`

const NumberInputFrame = styled.div`
  width: 66.66667%;
  position: relative;
  display: inline-block;
  line-height: 38px;
  box-sizing: border-box;
  font-size: 13px;
  color: #303133;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 8px;
  padding: 0 8px;
  border: 1px solid #dcdfe6;
  border-radius: 8px;

  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
`

const PriceText = styled.div`
  font-size: 1.25rem;
  padding: 15px 0;
  color: #000 !important;
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  box-sizing: border-box;
`

const AgreeDiv = styled.div`
  word-break: break-word;
  padding: 15px 0;
  color: #000;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  box-sizing: border-box;
`

const AgreeText = styled.span`
  white-space: normal;
  color: #000;
  font-family: Circular Std Book, Tahoma, SansSerif;
  word-break: normal;
  display: inline-block;
  padding-left: 10px;
  line-height: 19px;
  font-size: 14px;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    font-size: 0.75rem;
  }
`
