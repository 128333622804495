import styled from 'styled-components'
import RootModal from '../root_modal/RootModal'
import { XIconHover } from '../../icons/icons'
import { c_ffcc96, rgba_62_217_204_0d1 } from '../../colors'

const StandardModal = ({
  onClose,
  titleText,
  modalBodyComponent,
  leftBtnText = '취소',
  rightBtnText,
  onRightBtnClick,
  useLeftBtn = true,
}) => {
  return (
    <RootModal onClose={onClose}>
      <ModalContent>
        <ModalHeader>
          <ModalHeaderTitle>{titleText}</ModalHeaderTitle>
          <XIconHover onClick={onClose}></XIconHover>
        </ModalHeader>
        <ModalBody>{modalBodyComponent}</ModalBody>
        <ModalBottom>
          {useLeftBtn && <CancelBtn onClick={onClose}>{leftBtnText}</CancelBtn>}
          <div
            style={{
              marginLeft: 10,
            }}
          >
            <TakeMeThereBtn onClick={onRightBtnClick}>
              {rightBtnText}
            </TakeMeThereBtn>
          </div>
        </ModalBottom>
      </ModalContent>
    </RootModal>
  )
}

export default StandardModal

const ModalContent = styled.div`
  margin: auto;
  border-radius: 8px !important;
  font-family: Circular Std Book, Tahoma, SansSerif !important;
  display: inline-block;
  width: 420px;
  padding-bottom: 10px;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #ebeef5;
  font-size: 18px;
  box-shadow: 0 2px 12px #0000001a;
  text-align: left;
  backface-visibility: hidden;
  overflow: hidden;
  box-sizing: border-box;
  text-rendering: optimizeLegibility;

  @media (max-width: 650px) {
    width: 90vw;
  }
`

const ModalHeader = styled.div`
  position: relative;
  padding: 15px 15px 10px;
  box-sizing: border-box;
  font-size: 18px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
`

const ModalHeaderTitle = styled.div`
  padding-left: 0;
  margin-bottom: 0;
  font-size: 18px;
  line-height: 1;
  color: #303133;
  box-sizing: border-box;
`

const ModalBody = styled.div`
  padding: 10px 15px;
  color: #606266;
  font-size: 14px;
  box-sizing: border-box;
  text-align: left;
`

const ModalBottom = styled.div`
  padding: 5px 15px 0;
  text-align: right;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: right;
`

const CancelBtn = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 9px 15px;
  font-size: 12px;
  border-radius: 3px;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: #fff;
  color: #606266;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  transition: 0.1s;
  font-weight: 500;
  border: 1px solid #dcdfe6;
  overflow: visible;

  &:hover {
    background-color: ${c_ffcc96};
    border: 1px solid #b49275;
    color: #000;
  }
`

const TakeMeThereBtn = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 9px 15px;
  font-size: 12px;
  border-radius: 3px;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: #b49275;
  color: #fff;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  transition: 0.1s;
  font-weight: 500;
  border: 1px solid #b49275;
  overflow: visible;

  &:hover {
    opacity: 0.7;
  }
`
