import PcMainHeader from '../../design_system/components/header/pc_main_header/PcMainHeader'
import { ChangeBtnA } from '../../design_system/components/btn/pc/change_btn_a/ChangeBtnA'
import {
  c_112b38,
  c_353431,
  c_3ed9cc,
  c_b49275,
  c_eba55d,
  c_ffffff,
} from '../../design_system/colors'
import {
  login_inner_router_url,
  sign_up_inner_router_url,
} from '../../communication_system/inner_router_url/inner_router_url'
import styled from 'styled-components'
import {
  noto_sans_kr_bold,
  noto_sans_kr_medium,
  noto_sans_kr_regular,
} from '../../design_system/fonts/fonts'
import { Logo2x } from '../../design_system/icons/icons'

const FAQPage = () => {
  return (
    <div>
      <PcMainHeader>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => {
            const baseUrl = window.location.origin
            const url = `${baseUrl}`
            window.open(url, '_self')
          }}
        >
          <Logo2x></Logo2x>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ChangeBtnA
            href={login_inner_router_url}
            beforeBackgroudColor={c_ffffff}
            beforeBorderColor={c_eba55d}
            beforeFontColor={c_353431}
            afterBackgroudColor={c_b49275}
            afterBorderColor={c_b49275}
            afterFontColor={c_ffffff}
          >
            로그인
          </ChangeBtnA>
          <div
            style={{
              marginLeft: 15,
            }}
          >
            <ChangeBtnA
              href={sign_up_inner_router_url}
              beforeBackgroudColor={c_b49275}
              beforeBorderColor={c_b49275}
              beforeFontColor={c_ffffff}
              afterBackgroudColor={c_eba55d}
              afterBorderColor={c_eba55d}
              afterFontColor={c_353431}
            >
              무료 시작
            </ChangeBtnA>
          </div>
        </div>
      </PcMainHeader>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <ContentBody>
          <div
            style={{
              fontSize: 40,
              fontFamily: noto_sans_kr_bold,
              color: c_112b38,
              marginTop: 50,
            }}
          >
            두곰 FAQ
          </div>
          <br></br>
          <div
            style={{
              fontSize: 32,
              fontFamily: noto_sans_kr_bold,
              color: c_112b38,
              marginTop: 50,
            }}
          >
            1. 두곰은 어떤 서비스인가요?
          </div>
          <br></br>
          <div
            style={{
              height: 2,
              backgroundColor: c_112b38,
            }}
          ></div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            두곰은 호스트가 직접 숙소의 안내문을 만들 수 있는 플랫폼입니다.
            이미지, 동영상 등을 삽입해 숙소 정보를 제공해 줄 수 있고, 수정 및
            업데이트가 필요한 경우에도 바로 반영이 가능합니다.
            <br></br>
            <br></br>기존의 공유숙박업 안내문은 종이로 인쇄하여 책자로 만들어
            비치하거나(게스트가 몇 번 보면 낡고 너덜거리고, 수정사항이 있는 경우
            다시 프린트해서 교체해야 하는 번거로움), 카카오톡/왓츠앱/위챗/라인
            등의 채팅앱을 이용해서 게스트의 핸드폰 번호를 등록하여 친구로
            추가하여 안내문 텍스트를 복사하여 전송하거나(국가별 SNS 채팅 앱을
            설치해야 하고, 게스트를 친구로 등록해야 하는 번거로움), PDF 파일로
            만들어 이메일로 전송해 주거나(첨부 파일을 아예 읽지 않거나), 구글
            드라이브에 올려 파일 공유(중국 게스트는 구글이 열리지 않음)를 해주는
            방식이었습니다.<br></br>
            <br></br>이러한 방식의 숙소 안내는 세로로 길어질 수 밖에 없어
            가독성이 떨어져 게스트는 안내문을 읽지 않게 되고, 안내문에 모두 있는
            내용임에도 불구하고 호스트에게 질문을 하게 됩니다. 비슷한 질문들을
            계속해서 받다보면 호스트의 게스트 응대 시간이 길어지게 되고,
            반복되는 응대에 호스트는 점점 지쳐가며, 그로 인해 게스트에게도
            조금씩 불친절해지면서 말투도 의도치 않게 딱딱하게 바뀌게 됩니다.
            <br></br>
            <br></br>두곰은 이런 호스트의 일상에 깊이 공감하며, 깔끔한
            레이아웃으로 가독성이 뛰어난 웬앱 형태의 온라인 안내문을 제공합니다.
          </div>
          <div
            style={{
              fontSize: 32,
              fontFamily: noto_sans_kr_bold,
              color: c_112b38,
              marginTop: 50,
            }}
          >
            2. 두곰 온라인 안내문에는 어떤 것들이 포함되나요?
          </div>
          <br></br>
          <div
            style={{
              height: 2,
              backgroundColor: c_112b38,
            }}
          ></div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            숙소를 운영하면서 게스트에게 안내해야하는 모든 내용들이 포함됩니다.
            체크인 방법부터 교통 안내, 근처 맛집 안내, 숙소 규칙, 체크아웃 방법
            등 게스트들에게 필요한 정보들을 제공해 주세요. 기본 안내문에
            카테고리를 추가하여 쉽고 빠르게 수정이 가능합니다. 정형화 된 형식이
            있는 것이 아닌, 우리 숙소만의 개성있는 안내문을 만들 수 있습니다.
          </div>
          <div
            style={{
              fontSize: 32,
              fontFamily: noto_sans_kr_bold,
              color: c_112b38,
              marginTop: 50,
            }}
          >
            3. 두곰에서 온라인 안내문을 만들어 줄 수 있나요?
          </div>
          <br></br>
          <div
            style={{
              height: 2,
              backgroundColor: c_112b38,
            }}
          ></div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            네, 추가 비용을 결제해 주시면 숙소의 온라인 안내문을 두곰에서 만들어
            드립니다. 숙소 안내문에 들어갈 내용을 전달해 주시면 됩니다.
          </div>
          <div
            style={{
              fontSize: 32,
              fontFamily: noto_sans_kr_bold,
              color: c_112b38,
              marginTop: 50,
            }}
          >
            4. 어떤 언어로 작성해야 하나요?
          </div>
          <br></br>
          <div
            style={{
              height: 2,
              backgroundColor: c_112b38,
            }}
          ></div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            숙소에 방문하는 게스트에 맞게 작성하시면 됩니다. 내국인이 많은
            숙소라면 한국어로, 외국인이 많은 숙소라면 영어로 작성하시는걸
            추천드립니다.
          </div>
          <div
            style={{
              fontSize: 32,
              fontFamily: noto_sans_kr_bold,
              color: c_112b38,
              marginTop: 50,
            }}
          >
            5. 온라인 안내문은 게스트에게 어떻게 전달해 줄 수 있나요?
          </div>
          <br></br>
          <div
            style={{
              height: 2,
              backgroundColor: c_112b38,
            }}
          ></div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            온라인 안내문은 간단하게 링크 URL을 복사하셔서 전달만 해주시면
            됩니다.<br></br>
            안내문&gt;안내문 링크 공유 메뉴에서 클릭 한 번으로 링크 URL 복사가
            가능합니다.<br></br>이 링크를 게스트에게 메신저, 이메일 등으로
            전달만 해주시면 됩니다.<br></br>또는, QR코드 생성이 가능하기 때문에
            QR코드를 전송해 주셔도 됩니다.
          </div>
          <div
            style={{
              fontSize: 32,
              fontFamily: noto_sans_kr_bold,
              color: c_112b38,
              marginTop: 50,
            }}
          >
            6. 온라인 안내문을 통해 추가 수익을 창출할 수 있나요?
          </div>
          <br></br>
          <div
            style={{
              height: 2,
              backgroundColor: c_112b38,
            }}
          ></div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            네, 스탠다드 버전의 스토어 기능을 통해서 추가적인 수익을 창출할 수
            있습니다. 호스트가 직접 상품, 서비스를 등록하여 게스트에게 판매할 수
            있습니다. 두곰에서도 호스트의 수익을 올릴 수 있는 업체와의 제휴를
            계속해서 추진하고 있어서 연계된 업체의 상품, 서비스를 판매하여
            호스트 수익을 올릴 수도 있습니다.
          </div>
          <div
            style={{
              fontSize: 32,
              fontFamily: noto_sans_kr_bold,
              color: c_112b38,
              marginTop: 50,
            }}
          >
            7. 비용은 어떻게 되나요?
          </div>
          <br></br>
          <div
            style={{
              height: 2,
              backgroundColor: c_112b38,
            }}
          ></div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            베이직 버전 19,900원/월, 스탠다드 버전 29,900원/월 입니다. 자세한
            내용은 홈페이지 하단의 비용 참조 부탁드립니다. 베이직 버전에서는
            스토어 기능을 제외한 모든 기능 사용이 가능하고, 스탠다드 버전은
            스토어 기능을 추가로 사용 가능합니다.
          </div>
          <div
            style={{
              fontSize: 32,
              fontFamily: noto_sans_kr_bold,
              color: c_112b38,
              marginTop: 50,
            }}
          >
            8. 무료 버전이 있나요?
          </div>
          <br></br>
          <div
            style={{
              height: 2,
              backgroundColor: c_112b38,
            }}
          ></div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            네, 처음 1달간은 무료로 사용이 가능합니다.<br></br>
            무료 사용 기간 이후에는 정기결제를 신청하여 사용하시면 됩니다.
            <br></br>
            정기 결제 신청을 바로 하지 않으시더라도,<br></br>
            무료 사용 기간에 작업해두신 내용물은 일정 기간 동안 삭제되지 않으니
            걱정하지 않으셔도 됩니다.
          </div>
          <div
            style={{
              fontSize: 32,
              fontFamily: noto_sans_kr_bold,
              color: c_112b38,
              marginTop: 50,
            }}
          >
            9. 취소 방법 및 취소 수수료가 궁금합니다.
          </div>
          <br></br>
          <div
            style={{
              height: 2,
              backgroundColor: c_112b38,
            }}
          ></div>
          <br></br>
          <div
            style={{
              fontSize: 15,
              fontFamily: noto_sans_kr_regular,
              color: c_112b38,
            }}
          >
            정기 결제를 취소하시려면 doogomcs@doogom.com 으로 이메일 주소,
            성함을 첨부하여 취소 신청을 해주시면 됩니다.<br></br>
            취소 신청 이후 결제가 진행되지 않으면 해당 온라인 안내문은 더 이상
            사용하실 수 없습니다. 다만, 해당 매뉴얼은 1년간은 보관이 되며,
            이후에는 삭제 됩니다.<br></br>
            취소 수수료는 없습니다.
          </div>
          <div
            style={{
              height: 100,
            }}
          ></div>
        </ContentBody>
      </div>
    </div>
  )
}

export default FAQPage

const ContentBody = styled.div`
  width: 1280px;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  box-sizing: border-box;

  @media (max-width: 1580px) {
    width: 80%;
  }

  @media (max-width: 768px) {
    width: 90%;
  }
`
