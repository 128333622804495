import { createContext, useContext, useState } from 'react';

const SelectHelpAndSupportHeaderIndexContext = createContext();

export const useSelectHelpAndSupportHeader = () => {
  return useContext(SelectHelpAndSupportHeaderIndexContext);
};

export function SelectHelpAndSupportHeaderProvider({ children }) {
  const [selectHelpAndSupportHeaderIndex, setSelectHelpAndSupportHeaderIndex] =
    useState(
      parseInt(
        window.localStorage.getItem('selectHelpAndSupportHeaderIndex'),
      ) || 0,
    );

  const setHelpAndSupportHeaderIndex = (index) => {
    window.localStorage.setItem('selectHelpAndSupportHeaderIndex', index);
    setSelectHelpAndSupportHeaderIndex(index);
  };

  const value = {
    selectHelpAndSupportHeaderIndex,
    setHelpAndSupportHeaderIndex,
  };

  return (
    <SelectHelpAndSupportHeaderIndexContext.Provider value={value}>
      {children}
    </SelectHelpAndSupportHeaderIndexContext.Provider>
  );
}
