import {
  c_244556,
  c_3ed9cc,
  c_b49275,
  c_ffffff,
} from '../../design_system/colors'
import { Footer } from '../../design_system/components/footer/Footer'
import PcSubHeader from '../../design_system/components/header/pc_sub_header/PcSubHeader'
import { Section } from '../../design_system/components/section/Section'
import { Container } from '../../design_system/components/container/Container'
import { Body } from '../../design_system/components/body/Body'
import { TitleH1 } from '../../design_system/components/title_h1/TitleH1'
import { OpacityBtn } from '../../design_system/components/btn/pc/opacity_btn/OpacityBtn'
import { ContentText } from '../../design_system/components/content_text/ContentText'
import { login_inner_router_url } from '../../communication_system/inner_router_url/inner_router_url'
import { useNavigate } from 'react-router-dom'

const ForgottenPasswordSentPage = () => {
  let navigate = useNavigate()

  return (
    <>
      <PcSubHeader>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        ></div>
        <div></div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div></div>
        </div>
      </PcSubHeader>
      <Section>
        <Container>
          <div>
            <Body>
              <TitleH1>비밀번호 변경 이메일 발송완료</TitleH1>
              <div
                style={{
                  paddingBottom: 20,
                }}
              >
                <ContentText>
                  가입한 이메일 주소로 비밀번호 변경 이메일을 발송했습니다.
                </ContentText>
              </div>
              <div
                style={{
                  paddingTop: 30,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <OpacityBtn
                  fontColor={c_ffffff}
                  backgroundColor={c_b49275}
                  disabled={false}
                  onClick={async () => {
                    navigate(login_inner_router_url)
                  }}
                >
                  뒤로가기
                </OpacityBtn>
              </div>
            </Body>
          </div>
        </Container>
        <Footer></Footer>
      </Section>
    </>
  )
}

export default ForgottenPasswordSentPage
