import axios from 'axios'
import { doogom_com_server_router_url } from '../../communication_router_url/communication_router_url'

const axiosMiddleware = axios.create({
  baseURL: doogom_com_server_router_url, // 서버의 주소
  withCredentials: true,
})

axiosMiddleware.interceptors.request.use((config) => {
  const accessToken = window.localStorage.getItem('accessToken')
  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`
  }
  return config
})

axiosMiddleware.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const originalRequest = error.config

    if (
      error.response.status === 401 &&
      originalRequest &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true
      const refreshToken = window.localStorage.getItem('refreshToken')

      try {
        // 여기서 리프래쉬 토큰을 사용하여 새 액세스 토큰을 받아옵니다.
        const response = await axios.post(
          `${doogom_com_server_router_url}/auth/refresh-token`,
          {
            refreshToken,
          }
        )

        const newAccessToken = response.data.accessToken

        window.localStorage.setItem('accessToken', newAccessToken)
        axiosMiddleware.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${newAccessToken}`

        return axiosMiddleware(originalRequest)
      } catch (refreshError) {
        console.error('Failed to refresh access token', refreshError)
        window.localStorage.removeItem('accessToken')
        window.localStorage.removeItem('refreshToken')
        window.localStorage.setItem('isLoggedIn', false)

        // "/show-guide/:token" 형태의 URL인 경우에는 로그인 페이지로 이동하지 않음
        const currentUrl = window.location.href
        const showGuideUrlPattern = /\/show-guide\/[\w-]+$/
        if (!showGuideUrlPattern.test(currentUrl)) {
          window.location.href = '/login'
        }

        return Promise.reject(refreshError)
      }
    }

    return Promise.reject(error)
  }
)

export default axiosMiddleware
