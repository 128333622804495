import QRCode from 'qrcode'

export const generate_and_download_QR = async (text) => {
  try {
    const qrDataURL = await QRCode.toDataURL(text)

    // Blob으로 QR 데이터 변환
    const byteCharacters = atob(qrDataURL.split(',')[1])
    const byteNumbers = Array.from(byteCharacters, (char) => char.charCodeAt(0))
    const byteArray = new Uint8Array(byteNumbers)
    const blob = new Blob([byteArray], { type: 'image/png' })

    // Blob을 다운로드 가능한 링크로 변환
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = 'QRCode.png'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  } catch (err) {
    console.error(err)
  }
}
