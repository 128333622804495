import styled from 'styled-components';

export const HeaderFixedBtn = ({
  backgroundColor,
  borderColor,
  fontColor,
  children,
  href,
}) => {
  return (
    <FixedBtn
      href={href}
      $backgroundColor={backgroundColor}
      $borderColor={borderColor}
      $fontColor={fontColor}
    >
      {children}
    </FixedBtn>
  );
};

const FixedBtn = styled.a`
  position: relative;
  padding: 8px 16px;
  border-radius: 8px;
  font-family: 'paybooc-Light', sans-serif;
  text-decoration: none;
  font-weight: 600;
  transition: opacity 0.3s ease-in-out;
  background-color: ${(props) => props.$backgroundColor};
  border: 3px solid ${(props) => props.$borderColor};
  color: ${(props) => props.$fontColor};
  box-sizing: border-box;
  font-size: 1rem;

  @media (max-width: 1024px) {
    padding: 8px;
  }
`;
