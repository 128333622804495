import { createContext, useContext, useState } from 'react';

const SelectSettingsSubHeaderIndexContext = createContext();

export const useSelectSettingsSubHeader = () => {
  return useContext(SelectSettingsSubHeaderIndexContext);
};

export function SelectSettingsSubHeaderProvider({ children }) {
  const [selectSettingsSubHeaderIndex, setSelectSettingsSubHeaderIndex] =
    useState(
      parseInt(window.localStorage.getItem('selectSettingsSubHeaderIndex')) ||
        0,
    );

  const setSettingsSubHeaderIndex = (index) => {
    window.localStorage.setItem('selectSettingsSubHeaderIndex', index);
    setSelectSettingsSubHeaderIndex(index);
  };

  const value = {
    selectSettingsSubHeaderIndex,
    setSettingsSubHeaderIndex,
  };

  return (
    <SelectSettingsSubHeaderIndexContext.Provider value={value}>
      {children}
    </SelectSettingsSubHeaderIndexContext.Provider>
  );
}
