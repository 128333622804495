import styled from 'styled-components'

const ProgressBar = ({ pageNumber }) => {
  return (
    <ProgressBarTotalFrame>
      <ProgressBarInnerFrame>
        <ProgressBarOuter>
          <ProgressBarInner $pageNumber={pageNumber}></ProgressBarInner>
        </ProgressBarOuter>
      </ProgressBarInnerFrame>
    </ProgressBarTotalFrame>
  )
}

export default ProgressBar

const ProgressBarTotalFrame = styled.div`
  position: absolute;
  bottom: 60px;
  width: 100%;
  border-radius: 0;
  line-height: 1;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    display: none;
  }
`

const ProgressBarInnerFrame = styled.div`
  border: none;
  padding: 0;
  display: block;
  border-radius: 0;
  vertical-align: middle;
  width: 100%;
`

const ProgressBarOuter = styled.div`
  height: 10px;
  background-color: rgb(235, 238, 245);
  border-radius: 0;
  overflow: hidden;
  position: relative;
  vertical-align: middle;
  box-sizing: border-box;
`

const ProgressBarInner = styled.div`
  width: ${(props) =>
    props.$pageNumber === 1
      ? '25'
      : props.$pageNumber === 2
      ? '50'
      : props.$pageNumber === 3
      ? '75'
      : '100'}%;
  border-radius: 0;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-color: #eba55d;
  text-align: right;
  line-height: 1;
  white-space: nowrap;
  box-sizing: border-box;
`
