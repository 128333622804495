import styled from 'styled-components'

const IndexToggleBtn = ({
  index_toggle_data = [
    {
      id: 0,
      title: 'Yes',
    },
    {
      id: 1,
      title: 'No',
    },
  ],
  frameWidth = null,
  btnWidth = null,
  btnPadding = '8px 20px',
  selectIndex,
  setSelectIndex,
}) => {
  return (
    <IndexToggleBtnFrame $frameWidth={frameWidth}>
      {index_toggle_data.map((toggle_btn) => {
        return (
          <IndexToggleSectionBtn
            key={toggle_btn.id}
            $index={toggle_btn.id}
            $selectIndex={selectIndex}
            $length={index_toggle_data.length}
            $btnWidth={btnWidth}
            $btnPadding={btnPadding}
            onClick={() => {
              setSelectIndex(toggle_btn.id)
            }}
          >
            {toggle_btn.title}
          </IndexToggleSectionBtn>
        )
      })}
    </IndexToggleBtnFrame>
  )
}

export default IndexToggleBtn

const IndexToggleBtnFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  width: ${(props) => props.$frameWidth};

  @media (max-width: 1024px) {
    width: 100%;
  }
`

const IndexToggleSectionBtn = styled.div`
  border-top-left-radius: ${(props) => (props.$index === 0 ? '8px' : '0px')};
  border-bottom-left-radius: ${(props) => (props.$index === 0 ? '8px' : '0px')};
  border-top-right-radius: ${(props) =>
    props.$length === props.$index + 1 ? '8px' : '0px'};
  border-bottom-right-radius: ${(props) =>
    props.$length === props.$index + 1 ? '8px' : '0px'};
  padding: ${(props) => props.$btnPadding};
  border-left: ${(props) =>
      props.$length === props.$index + 1 ? '0px' : '1px'}
    solid
    ${(props) => (props.$selectIndex === props.$index ? '#b49275' : '#dcdfe6')};
  border-right: ${(props) => (props.$index === 0 ? '0px' : '1px')} solid
    ${(props) => (props.$selectIndex === props.$index ? '#b49275' : '#dcdfe6')};
  border-top: 1px solid
    ${(props) => (props.$selectIndex === props.$index ? '#b49275' : '#dcdfe6')};
  border-bottom: 1px solid
    ${(props) => (props.$selectIndex === props.$index ? '#b49275' : '#dcdfe6')};
  box-shadow: none !important;
  display: inline-block;
  line-height: 1;
  vertical-align: middle;
  background: ${(props) =>
    props.$selectIndex === props.$index ? '#b49275' : '#fff'};
  font-weight: 500;
  color: ${(props) =>
    props.$selectIndex === props.$index ? '#fff' : '#606266'};
  text-align: center;
  box-sizing: border-box;
  margin: 0;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: ${(props) => props.$btnWidth};

  &:hover {
    color: ${(props) =>
      props.$selectIndex === props.$index ? '#fff' : '#b49275'};
  }
`
