import { createContext, useContext, useState } from 'react'

const SelectPaymentHistoryHeaderIndexContext = createContext()

export const useSelectPaymentHistoryHeader = () => {
  return useContext(SelectPaymentHistoryHeaderIndexContext)
}

export function SelectPaymentHistoryHeaderProvider({ children }) {
  const [selectPaymentHistoryHeaderIndex, setSelectPaymentHistoryHeaderIndex] =
    useState(
      parseInt(
        window.localStorage.getItem('selectPaymentHistoryHeaderIndex')
      ) || 0
    )

  const setPaymentHistoryHeaderIndex = (index) => {
    window.localStorage.setItem('selectPaymentHistoryHeaderIndex', index)
    setSelectPaymentHistoryHeaderIndex(index)
  }

  const value = {
    selectPaymentHistoryHeaderIndex,
    setPaymentHistoryHeaderIndex,
  }

  return (
    <SelectPaymentHistoryHeaderIndexContext.Provider value={value}>
      {children}
    </SelectPaymentHistoryHeaderIndexContext.Provider>
  )
}
