import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import {
  ArrowLeftIconFixed,
  ArrowRightIconFixed,
  CircleArrowDownIconFixed,
  CircleArrowUpIconFixed,
  HeadsetFixed,
  LocationIconFixed,
  PaperPlaneIconFixed,
  PhoneIconFixed,
  SearchIconFixed,
  ShareIconFixed,
  XIconFixed,
} from '../../design_system/icons/icons'
import { c_3ed9cc, c_eba55d, c_ffffff } from '../../design_system/colors'
import { SmallChangeBtn } from '../../design_system/components/btn/pc/small_change_btn/SmallChangeBtn'
import { GoogleMap, MarkerF, InfoWindow } from '@react-google-maps/api'
import { generate_UUID } from '../../data_system/data_function/generate_UUID'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { useParams } from 'react-router-dom'
import { getShowDefaultGuideDecodeToken } from '../../communication_system/axios_apis/axios_apis'
import { googleMapsApiKey } from '../../communication_system/keys/keys'
import { isMobile } from 'react-device-detect'
import { useLoading } from '../../data_system/context/loading_context/LoadingContext'
import { usePopup } from '../../data_system/context/popup_context/PopupContext'
import { convertFromRaw, EditorState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { OpacityBtn } from '../../design_system/components/btn/pc/opacity_btn/OpacityBtn'
import { priceFormatFunction } from '../../data_system/data_function/price_format_function'
import StandardModal from '../../design_system/components/standard_modal/StandardModal'
import { image_1330 } from '../../design_system/images/images_settings'
import { useGoogleMaps } from '../../data_system/context/google_maps_context/GoogleMapsContext'
import { formatPhoneNumber } from '../../data_system/data_function/format_phone_number'
import { isExpired } from '../../data_system/data_function/is_expired'

const ShowDefaultGuidePage = () => {
  const { isLoaded, loadError } = useGoogleMaps()
  console.log('isLoaded:', isLoaded, 'loadError:', loadError)
  const { show } = usePopup()
  const { showLoading, hideLoading, isLoading } = useLoading()
  const { token } = useParams()

  const [tabIndex, setTabIndex] = useState(0)

  const [tabList, setTabList] = useState([
    {
      id: 0,
      title: 'HOME',
    },
    {
      id: 1,
      title: 'INFO',
    },
    {
      id: 3,
      title: 'MAP',
    },
    {
      id: 4,
      title: 'SEARCH',
    },
  ])

  const [showMoveTo1330Modal, setShowMoveTo1330Modal] = useState(false)

  const [showMobileInfoDetail, setShowMobileInfoDetail] = useState(false)
  const [
    showMobileDefaultSubcategoriesModal,
    setShowMobileDefaultSubcategoriesModal,
  ] = useState(false)

  const [selectedMarker, setSelectedMarker] = useState(null)

  const [map, setMap] = useState(null)

  const onLoad = useCallback(function callback(map) {
    map.setZoom(zoom)

    setMap(map)
  }, [])

  const onUnmount = useCallback(function callback(map) {
    setMap(null)
  }, [])

  const [zoom, setZoom] = useState(15)

  const [mapError, setMapError] = useState(false)

  const mapRef = useRef()
  const [position, setPosition] = useState({
    latitude: 37.5665,
    longitude: 126.978,
  })
  const [center, setCenter] = useState({
    latitude: 37.5665,
    longitude: 126.978,
  })
  const tileRefs = useRef([])

  const onMarkerDragEnd = (event) => {
    setPosition({
      latitude: event.latLng.lat(),
      longitude: event.latLng.lng(),
    })
  }
  const [userProducts, setUserProducts] = useState([])

  const [defaultCategories, setDefaultCategories] = useState([
    {
      id: generate_UUID(),
      title: 'Sample Category',
      menuVisible: false,
      defaultSubcategories: [
        {
          id: generate_UUID(),
          title: 'Sample Subcategory 1',
          menuVisible: false,
          defaultTopics: [
            {
              id: generate_UUID(),
              title: 'Sample Topic 1',
              content: 'a',
              menuVisible: false,
              imgUrl: '',
              latitude: null,
              longitude: null,
              mapPinIconUrl: null,
            },
            {
              id: generate_UUID(),
              title: 'Sample Topic 2',
              content: 'a',
              menuVisible: false,
              imgUrl: '',
              latitude: null,
              longitude: null,
              mapPinIconUrl: null,
            },
          ],
        },
        {
          id: generate_UUID(),
          title: 'Sample Subcategory 2',
          menuVisible: false,
          defaultTopics: [],
        },
      ],
    },
  ])
  const [defaultGuide, setDefaultGuide] = useState(null)
  const [user, setUser] = useState(null)

  const [isPc, setIsPc] = useState(false)

  const [selectDefaultCategoryId, setSelectDefaultCategoryId] = useState(null)
  const [selectDefaultCategory, setSelectDefaultCategory] = useState(null)

  const [selectDefaultSubcategoryId, setSelectDefaultSubcategoryId] =
    useState(null)

  const toggleDefaultCategoryVisibility = (defaultCategoryId) => {
    setSelectDefaultCategoryId(defaultCategoryId)
    setDefaultCategories((prevDefaultCategories) =>
      prevDefaultCategories.map((defaultCategory) => {
        if (defaultCategory.id === defaultCategoryId) {
          const updatedDefaultCategory = {
            ...defaultCategory,
            menuVisible: !defaultCategory.menuVisible,
            defaultSubcategories: defaultCategory.defaultSubcategories.map(
              (defaultSubcategory, index) => {
                const updatedDefaultSubcategory = {
                  ...defaultSubcategory,
                  menuVisible: index === 0,
                  defaultTopics: defaultSubcategory.defaultTopics.map(
                    (defaultTopic) => ({
                      ...defaultTopic,
                      menuVisible: false, // 모든 topic의 menuVisible을 false로 설정
                    })
                  ),
                }

                if (index === 0) {
                  setSelectDefaultSubcategoryId(updatedDefaultSubcategory.id)
                }

                return updatedDefaultSubcategory
              }
            ),
          }
          return updatedDefaultCategory
        }
        return {
          ...defaultCategory,
          menuVisible: false,
        }
      })
    )
  }

  const toggleDefaultSubcategoryVisibility = (
    defaultCategoryId,
    defaultSubcategoryId
  ) => {
    setSelectDefaultCategoryId(defaultCategoryId)
    setDefaultCategories((prevDefaultCategories) =>
      prevDefaultCategories.map((defaultCategory) => {
        if (defaultCategory.id === defaultCategoryId) {
          const updatedDefaultSubcategories =
            defaultCategory.defaultSubcategories.map((defaultSubcategory) => {
              const updatedDefaultTopics = defaultSubcategory.defaultTopics.map(
                (defaultTopic) => ({
                  ...defaultTopic,
                  menuVisible: false, // 모든 topic의 menuVisible을 false로 설정
                })
              )

              if (defaultSubcategory.id === defaultSubcategoryId) {
                setSelectDefaultSubcategoryId(defaultSubcategory.id)
                return {
                  ...defaultSubcategory,
                  menuVisible: true,
                  defaultTopics: updatedDefaultTopics,
                }
              } else {
                return {
                  ...defaultSubcategory,
                  menuVisible: false,
                  defaultTopics: updatedDefaultTopics,
                }
              }
            })

          return {
            ...defaultCategory,
            defaultSubcategories: updatedDefaultSubcategories,
          }
        }
        return defaultCategory
      })
    )
  }

  const toggleDefaultTopicVisibility = (
    defaultCategoryId,
    defaultSubcategoryId,
    defaultTopicId
  ) => {
    setDefaultCategories((prevDefaultCategories) =>
      prevDefaultCategories.map((defaultCategory) => {
        if (defaultCategory.id === defaultCategoryId) {
          const updatedDefaultSubcategories =
            defaultCategory.defaultSubcategories.map((defaultSubcategory) => {
              if (defaultSubcategory.id === defaultSubcategoryId) {
                const updatedDefaultTopics =
                  defaultSubcategory.defaultTopics.map((defaultTopic) => {
                    if (defaultTopic.id === defaultTopicId) {
                      return {
                        ...defaultTopic,
                        menuVisible: !defaultTopic.menuVisible,
                      }
                    }
                    return defaultTopic
                  })
                return {
                  ...defaultSubcategory,
                  defaultTopics: updatedDefaultTopics,
                }
              }
              return defaultSubcategory
            })

          return {
            ...defaultCategory,
            defaultSubcategories: updatedDefaultSubcategories,
          }
        }
        return defaultCategory
      })
    )
  }

  const toggleDefaultTopicVisibilityOnlyOpen = (
    defaultCategoryId,
    defaultSubcategoryId,
    defaultTopicId
  ) => {
    setDefaultCategories((prevDefaultCategories) =>
      prevDefaultCategories.map((defaultCategory) => {
        if (defaultCategory.id === defaultCategoryId) {
          const updatedDefaultSubcategories =
            defaultCategory.defaultSubcategories.map((defaultSubcategory) => {
              if (defaultSubcategory.id === defaultSubcategoryId) {
                const updatedDefaultTopics =
                  defaultSubcategory.defaultTopics.map((defaultTopic) => {
                    return {
                      ...defaultTopic,
                      menuVisible: defaultTopic.id === defaultTopicId, // 선택한 토픽만 true, 나머지는 false
                    }
                  })
                return {
                  ...defaultSubcategory,
                  defaultTopics: updatedDefaultTopics,
                }
              }
              return defaultSubcategory
            })

          return {
            ...defaultCategory,
            defaultSubcategories: updatedDefaultSubcategories,
          }
        }
        return defaultCategory
      })
    )
  }

  const openAllDefaultTopicsInSubcategory = (
    defaultCategoryId,
    defaultSubcategoryId
  ) => {
    setDefaultCategories((prevDefaultCategories) =>
      prevDefaultCategories.map((defaultCategory) => {
        if (defaultCategory.id === defaultCategoryId) {
          const updatedDefaultSubcategories =
            defaultCategory.defaultSubcategories.map((defaultSubcategory) => {
              if (defaultSubcategory.id === defaultSubcategoryId) {
                const updatedDefaultTopics =
                  defaultSubcategory.defaultTopics.map((defaultTopic) => ({
                    ...defaultTopic,
                    menuVisible: true, // 모든 토픽을 열기
                  }))
                return {
                  ...defaultSubcategory,
                  defaultTopics: updatedDefaultTopics,
                }
              }
              return defaultSubcategory
            })

          return {
            ...defaultCategory,
            defaultSubcategories: updatedDefaultSubcategories,
          }
        }
        return defaultCategory
      })
    )
  }

  const closeAllDefaultTopicsInSubcategory = (
    defaultCategoryId,
    defaultSubcategoryId
  ) => {
    setDefaultCategories((prevDefaultCategories) =>
      prevDefaultCategories.map((defaultCategory) => {
        if (defaultCategory.id === defaultCategoryId) {
          const updatedDefaultSubcategories =
            defaultCategory.defaultSubcategories.map((defaultSubcategory) => {
              if (defaultSubcategory.id === defaultSubcategoryId) {
                const updatedDefaultTopics =
                  defaultSubcategory.defaultTopics.map((defaultTopic) => ({
                    ...defaultTopic,
                    menuVisible: false, // 모든 토픽을 닫기
                  }))
                return {
                  ...defaultSubcategory,
                  defaultTopics: updatedDefaultTopics,
                }
              }
              return defaultSubcategory
            })

          return {
            ...defaultCategory,
            defaultSubcategories: updatedDefaultSubcategories,
          }
        }
        return defaultCategory
      })
    )
  }

  const toggleUserProductVisibility = (userProductId) => {
    setUserProducts((prevUserProducts) =>
      prevUserProducts.map((userProduct) => {
        if (userProduct.id === userProductId) {
          return {
            ...userProduct,
            menuVisible: !userProduct.menuVisible, // 'visible' 속성 토글
          }
        }
        return userProduct
      })
    )
  }

  const [searchText, setSearchText] = useState('')

  const [
    defaultSubcategoriesFoundByTitle,
    setDefaultSubcategoriesFoundByTitle,
  ] = useState([])
  const [
    defaultSubcategoriesFoundByContent,
    setDefaultSubcategoriesFoundByContent,
  ] = useState([])

  const searchDefaultSubcategories = (searchTerm) => {
    const tempDefaultSubcategoriesFoundByTitle = []
    const tempDefaultSubcategoriesFoundByContent = []

    defaultCategories.forEach((defaultCategory) => {
      defaultCategory.defaultSubcategories.forEach((defaultSubcategory) => {
        if (
          defaultSubcategory.title
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        ) {
          tempDefaultSubcategoriesFoundByTitle.push({
            defaultCategoryId: defaultCategory.id,
            defaultSubcategoryId: defaultSubcategory.id,
            title: defaultSubcategory.title,
          })
        }

        defaultSubcategory.defaultTopics.forEach((defaultTopic) => {
          if (
            defaultTopic.content
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
          ) {
            const foundByContentIndex =
              tempDefaultSubcategoriesFoundByContent.findIndex(
                (item) =>
                  item.defaultCategoryId === defaultCategory.id &&
                  item.defaultSubcategoryId === defaultSubcategory.id
              )

            if (foundByContentIndex === -1) {
              tempDefaultSubcategoriesFoundByContent.push({
                defaultCategoryId: defaultCategory.id,
                defaultSubcategoryId: defaultSubcategory.id,
                title: defaultSubcategory.title,
              })
            }
          }
        })
      })
    })

    return {
      tempDefaultSubcategoriesFoundByTitle,
      tempDefaultSubcategoriesFoundByContent,
    }
  }

  const openDefaultSubcategoryAndItsDefaultTopics = (
    defaultCategoryId,
    defaultSubcategoryId
  ) => {
    setDefaultCategories((prevDefaultCategories) =>
      prevDefaultCategories.map((defaultCategory) => {
        // 해당 카테고리 확인
        if (defaultCategory.id === defaultCategoryId) {
          return {
            ...defaultCategory,
            menuVisible: true,
            defaultSubcategories: defaultCategory.defaultSubcategories.map(
              (defaultSubcategory) => {
                // 해당 서브카테고리 확인
                if (defaultSubcategory.id === defaultSubcategoryId) {
                  return {
                    ...defaultSubcategory,
                    menuVisible: true,
                    defaultTopics: defaultSubcategory.defaultTopics.map(
                      (defaultTopic) => ({
                        ...defaultTopic,
                        menuVisible: true, // 해당 서브카테고리의 모든 토픽 menuVisible을 true로 설정
                      })
                    ),
                  }
                }
                return {
                  ...defaultSubcategory,
                  menuVisible: false,
                  defaultTopics: defaultSubcategory.defaultTopics.map(
                    (defaultTopic) => ({
                      ...defaultTopic,
                      menuVisible: false,
                    })
                  ),
                }
              }
            ),
          }
        }
        return {
          ...defaultCategory,
          menuVisible: false,
          defaultSubcategories: defaultCategory.defaultSubcategories.map(
            (defaultSubcategory) => ({
              ...defaultSubcategory,
              menuVisible: false,
              defaultTopics: defaultSubcategory.defaultTopics.map(
                (defaultTopic) => ({
                  ...defaultTopic,
                  menuVisible: false,
                })
              ),
            })
          ),
        }
      })
    )
  }

  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker)
  }

  useEffect(() => {
    // 카테고리 리스트가 있고 첫 번째 카테고리의 서브카테고리 리스트가 있을 경우
    if (
      defaultCategories.length &&
      defaultCategories[0].defaultSubcategories.length
    ) {
      const firstDefaultCategory = defaultCategories[0]
      const firstDefaultSubcategory =
        firstDefaultCategory.defaultSubcategories[0]

      // 첫 번째 카테고리의 첫 번째 서브카테고리를 선택
      setSelectDefaultSubcategoryId(firstDefaultSubcategory.id)

      // 선택된 서브카테고리의 menuVisible 속성을 true로 설정
      toggleDefaultSubcategoryVisibility(
        firstDefaultCategory.id,
        firstDefaultSubcategory.id
      )
    }
  }, []) // categories가 변경될 때만 실행

  const [newFaviconUrl, setNewFaviconUrl] = useState(
    'https://doogomimages3.s3.ap-northeast-2.amazonaws.com/default/icon.png'
  )
  const [htmlTitle, setHtmlTitle] = useState('DOOGOM')

  // 파비콘 변경 함수
  const changeFavicon = (faviconUrl) => {
    let link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement('link')
    link.type = 'image/x-icon'
    link.rel = 'shortcut icon'
    link.href = faviconUrl
    document.getElementsByTagName('head')[0].appendChild(link)
  }

  const [end, setEnd] = useState(false)

  useEffect(() => {
    // 현재 페이지 URL 확인
    const currentPageUrl = window.location.href

    if (currentPageUrl.includes('/show-default-guide/')) {
      // 파비콘 변경 함수 호출
      document.title = htmlTitle
      changeFavicon(newFaviconUrl)
    }

    // 컴포넌트 언마운트 시 기존 파비콘으로 복원
    return () => {
      document.title = 'DOOGOM'

      // 기본 파비콘 URL 또는 다른 조건에 따른 URL 지정
      const defaultFaviconUrl =
        'https://doogomimages3.s3.ap-northeast-2.amazonaws.com/default/icon.png'
      changeFavicon(defaultFaviconUrl)
    }
  }, [newFaviconUrl, htmlTitle])

  useEffect(() => {
    async function getData() {
      showLoading()
      console.log('token', token)
      const response = await getShowDefaultGuideDecodeToken(token)
      console.log(response)
      setUserProducts(response.data.userProducts)
      setDefaultCategories(response.data.defaultCategories)
      setDefaultGuide(response.data.defaultGuide)
      setNewFaviconUrl(response.data.defaultGuide.iconImageUrl)
      setHtmlTitle(response.data.defaultGuide.guideName)
      setUser(response.data.user)
      setPosition({
        latitude: response.data.defaultGuide.latitude,
        longitude: response.data.defaultGuide.longitude,
      })
      setCenter({
        latitude: response.data.defaultGuide.latitude,
        longitude: response.data.defaultGuide.longitude,
      })
      setMapError(
        response.data.defaultGuide.latitude === null ||
          response.data.defaultGuide.longitude === null
          ? true
          : false
      )
      if (isMobile) {
        setIsPc(false)
      } else {
        setIsPc(true)
      }

      setTabList(
        response.data.user.productId !== 2
          ? [
              {
                id: 0,
                title: response.data.defaultGuide.tab1Name,
              },
              {
                id: 1,
                title: response.data.defaultGuide.tab2Name,
              },
              {
                id: 2,
                title: response.data.defaultGuide.tab3Name,
              },
              {
                id: 3,
                title: response.data.defaultGuide.tab4Name,
              },
              {
                id: 4,
                title: 'SEARCH',
              },
            ]
          : [
              {
                id: 0,
                title: response.data.defaultGuide.tab1Name,
              },
              {
                id: 1,
                title: response.data.defaultGuide.tab2Name,
              },
              {
                id: 3,
                title: response.data.defaultGuide.tab4Name,
              },
              {
                id: 4,
                title: 'SEARCH',
              },
            ]
      )

      if (isExpired(response.data.user.totalSubscriptionEndDate)) {
        setEnd(true)
      } else {
        setEnd(false)
      }

      hideLoading()
    }

    getData()
  }, [])

  if (end) {
    return (
      <TotalContainer>
        <InnerContainer>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            You cannot view the subscription application page.
          </div>
          <Footer>
            <FooterNav>
              {tabList.map((tab) => {
                return (
                  <FooterNavTab
                    key={tab.id}
                    id={tab.id}
                    onClick={() => {
                      if (end === false) {
                        if (tab.id !== 2) {
                          setPosition({
                            latitude:
                              defaultGuide.latitude === null
                                ? 37.5665
                                : defaultGuide.latitude,
                            longitude:
                              defaultGuide.longitude === null
                                ? 126.978
                                : defaultGuide.longitude,
                          })
                          setCenter({
                            latitude:
                              defaultGuide.latitude === null
                                ? 37.5665
                                : defaultGuide.latitude,
                            longitude:
                              defaultGuide.longitude === null
                                ? 126.978
                                : defaultGuide.longitude,
                          })
                          setMapError(
                            defaultGuide.latitude === null ||
                              defaultGuide.longitude === null
                              ? true
                              : false
                          )
                        }

                        setTabIndex(tab.id)
                      }
                    }}
                    $tabIndex={tabIndex}
                    $index={tab.id}
                  >
                    {tab.title}
                  </FooterNavTab>
                )
              })}
            </FooterNav>
          </Footer>
        </InnerContainer>
      </TotalContainer>
    )
  }

  return (
    <>
      {!isLoading &&
        defaultGuide &&
        user &&
        (isPc ? (
          <TotalContainer>
            <InnerContainer>
              {tabIndex === 0 && (
                <HomeInnerContainerView>
                  <InnerContainerViewOverlay></InnerContainerViewOverlay>
                  <InnerContainerViewCenterDiv>
                    <InnerContainerViewCenterDivImg
                      style={{
                        borderRadius: defaultGuide.logoImageRounded ? '50%' : 0,
                      }}
                      src={defaultGuide.logoImageUrl}
                    ></InnerContainerViewCenterDivImg>
                    <InnerContainerViewCenterDivP>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {defaultGuide.hostName}
                      </span>
                    </InnerContainerViewCenterDivP>
                    <InnerContainerViewCenterDivContactDiv>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <PhoneIconFixed></PhoneIconFixed>
                        <InnerContainerViewCenterDivContactDivSpan>
                          {defaultGuide.hostPhoneNumber !== null &&
                          defaultGuide.hostPhoneNumber !== '' &&
                          defaultGuide.hostPhoneNumber !== undefined
                            ? formatPhoneNumber(defaultGuide.hostPhoneNumber)
                            : ''}
                        </InnerContainerViewCenterDivContactDivSpan>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginTop: 10,
                        }}
                      >
                        <PaperPlaneIconFixed></PaperPlaneIconFixed>
                        <InnerContainerViewCenterDivContactDivSpan>
                          {defaultGuide.email}
                        </InnerContainerViewCenterDivContactDivSpan>
                      </div>
                    </InnerContainerViewCenterDivContactDiv>
                    <InnerContainerViewCenterDivDivideSpan></InnerContainerViewCenterDivDivideSpan>
                    <InnerContainerViewCenterDivSpan>
                      {defaultGuide.guideName}
                    </InnerContainerViewCenterDivSpan>
                    <div
                      style={{
                        marginTop: '1.5rem',
                      }}
                    >
                      <SmallChangeBtn
                        onClick={() => {
                          setTabIndex(1)
                        }}
                        beforeBackgroudColor={c_eba55d}
                        beforeBorderColor={c_eba55d}
                        beforeFontColor={c_ffffff}
                        afterBackgroudColor={'transparent'}
                        afterBorderColor={c_eba55d}
                        afterFontColor={c_ffffff}
                      >
                        START
                      </SmallChangeBtn>
                    </div>
                  </InnerContainerViewCenterDiv>
                  <Overlay></Overlay>
                  <InnerContainerViewBackgroundImg
                    src={
                      isPc
                        ? defaultGuide.pcImageUrl
                        : defaultGuide.mobileImageUrl
                    }
                  ></InnerContainerViewBackgroundImg>
                </HomeInnerContainerView>
              )}
              {tabIndex === 1 && (
                <InfoInnerContainerView>
                  <InfoInnerContainerViewRow>
                    <InfoInnerContainerViewRowLeft>
                      <InfoInnerContainerViewRowLeftInner>
                        <InfoInnerContainerViewRowLeftInnerHeader>
                          <InfoInnerContainerViewRowLeftInnerHeaderRow>
                            <InfoInnerContainerViewRowLeftInnerHeaderRowLeft>
                              <InfoInnerContainerViewRowLeftInnerHeaderRowLeftImg
                                style={{
                                  borderRadius: defaultGuide.logoImageRounded
                                    ? '50%'
                                    : 0,
                                }}
                                src={defaultGuide.logoImageUrl}
                              ></InfoInnerContainerViewRowLeftInnerHeaderRowLeftImg>
                              <InfoInnerContainerViewRowLeftInnerHeaderRowIconStyle>
                                <PaperPlaneIconFixed
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      defaultGuide.email
                                    )
                                    show('copy email')
                                  }}
                                ></PaperPlaneIconFixed>
                              </InfoInnerContainerViewRowLeftInnerHeaderRowIconStyle>
                              <InfoInnerContainerViewRowLeftInnerHeaderRowIconStyle>
                                <PhoneIconFixed
                                  onClick={() => {
                                    if (isPc) {
                                      navigator.clipboard.writeText(
                                        defaultGuide.hostPhoneNumber
                                      )
                                      show('copy phone number')
                                    } else {
                                      window.location.href = `tel:${defaultGuide.hostPhoneNumber}`
                                    }
                                  }}
                                ></PhoneIconFixed>
                              </InfoInnerContainerViewRowLeftInnerHeaderRowIconStyle>
                            </InfoInnerContainerViewRowLeftInnerHeaderRowLeft>
                            <InfoInnerContainerViewRowLeftInnerHeaderRowRight>
                              <InfoInnerContainerViewRowLeftInnerHeaderRowIconStyle>
                                <ShareIconFixed
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      defaultGuide.shareUrl
                                    )
                                    show('url copied')
                                  }}
                                ></ShareIconFixed>
                              </InfoInnerContainerViewRowLeftInnerHeaderRowIconStyle>
                              <InfoInnerContainerViewRowLeftInnerHeaderRowIconStyle
                                style={{
                                  color: c_ffffff,
                                }}
                              >
                                <HeadsetFixed
                                  onClick={() => {
                                    setShowMoveTo1330Modal(true)
                                  }}
                                ></HeadsetFixed>
                              </InfoInnerContainerViewRowLeftInnerHeaderRowIconStyle>
                            </InfoInnerContainerViewRowLeftInnerHeaderRowRight>
                          </InfoInnerContainerViewRowLeftInnerHeaderRow>
                        </InfoInnerContainerViewRowLeftInnerHeader>
                        <InfoInnerContainerViewRowLeftInnerBody>
                          <InfoInnerContainerViewRowLeftInnerBodyDiv>
                            {defaultCategories.map(
                              (defaultCategory, defaultCategoryIndex) => {
                                if (!tileRefs.current[defaultCategoryIndex]) {
                                  tileRefs.current[defaultCategoryIndex] =
                                    React.createRef()
                                }

                                return (
                                  <InfoInnerContainerViewRowLeftInnerBodyDivTile
                                    key={defaultCategory.id}
                                    ref={tileRefs.current[defaultCategoryIndex]}
                                    style={{
                                      marginTop: defaultCategoryIndex > 0 && 15,
                                    }}
                                    onClick={(e) => {
                                      toggleDefaultCategoryVisibility(
                                        defaultCategory.id
                                      )
                                      const tileElement =
                                        tileRefs.current[defaultCategoryIndex]
                                          .current
                                      if (tileElement) {
                                        setTimeout(() => {
                                          tileElement.scrollIntoView({
                                            behavior: 'smooth',
                                          })
                                        }, 100)
                                      }
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                      }}
                                    >
                                      {defaultCategory.title}
                                      <FontAwesomeIcon
                                        icon={
                                          defaultCategory.menuVisible
                                            ? faChevronUp
                                            : faChevronDown
                                        }
                                      />
                                    </div>
                                    {defaultCategory.menuVisible && (
                                      <div
                                        style={{
                                          marginLeft: 50,
                                        }}
                                      >
                                        {defaultCategory.defaultSubcategories.map(
                                          (
                                            defaultSubcategory,
                                            defaultSubcategoryIndex
                                          ) => {
                                            return (
                                              <div
                                                key={defaultSubcategory.id}
                                                style={{
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  justifyContent:
                                                    'space-between',
                                                  paddingTop: 20,
                                                  paddingBottom:
                                                    defaultSubcategoryIndex +
                                                      1 !==
                                                    defaultCategory
                                                      .defaultSubcategories
                                                      .length
                                                      ? 20
                                                      : 0,
                                                  color:
                                                    defaultSubcategory.menuVisible
                                                      ? '#eba55d'
                                                      : '#000',
                                                  borderBottom: `${
                                                    defaultSubcategoryIndex +
                                                      1 !==
                                                    defaultCategory
                                                      .defaultSubcategories
                                                      .length
                                                      ? 1
                                                      : 0
                                                  }px solid rgba(0,0,0,.1)`,
                                                }}
                                                onClick={(e) => {
                                                  e.stopPropagation()

                                                  toggleDefaultSubcategoryVisibility(
                                                    defaultCategory.id,
                                                    defaultSubcategory.id
                                                  )
                                                }}
                                              >
                                                {defaultSubcategory.title}
                                                <ArrowRightIconFixed></ArrowRightIconFixed>
                                              </div>
                                            )
                                          }
                                        )}
                                      </div>
                                    )}
                                  </InfoInnerContainerViewRowLeftInnerBodyDivTile>
                                )
                              }
                            )}
                          </InfoInnerContainerViewRowLeftInnerBodyDiv>
                        </InfoInnerContainerViewRowLeftInnerBody>
                      </InfoInnerContainerViewRowLeftInner>
                    </InfoInnerContainerViewRowLeft>
                    <InfoInnerContainerViewRowRight>
                      <InfoInnerContainerViewRowRightHeader>
                        {selectDefaultSubcategoryId !== null &&
                          defaultCategories.map((defaultCategory) => {
                            if (
                              defaultCategory.id === selectDefaultCategoryId
                            ) {
                              return defaultCategory.defaultSubcategories.map(
                                (defaultSubcategory) => {
                                  if (
                                    defaultSubcategory.id ===
                                    selectDefaultSubcategoryId
                                  ) {
                                    return (
                                      <div
                                        key={defaultSubcategory.id}
                                        style={{
                                          width: '100%',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'space-between',
                                        }}
                                      >
                                        {defaultSubcategory.title}
                                        <div
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontSize: '25px',
                                            textShadow:
                                              '0px 0px 4px rgba(0, 0, 0, 0.2)',
                                          }}
                                        >
                                          <CircleArrowUpIconFixed
                                            onClick={() => {
                                              closeAllDefaultTopicsInSubcategory(
                                                defaultCategory.id,
                                                defaultSubcategory.id
                                              )
                                            }}
                                          ></CircleArrowUpIconFixed>
                                          <div
                                            style={{
                                              marginLeft: '18px',
                                            }}
                                          >
                                            <CircleArrowDownIconFixed
                                              onClick={() => {
                                                openAllDefaultTopicsInSubcategory(
                                                  defaultCategory.id,
                                                  defaultSubcategory.id
                                                )
                                              }}
                                            ></CircleArrowDownIconFixed>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  }
                                }
                              )
                            }
                          })}
                      </InfoInnerContainerViewRowRightHeader>
                      <InfoInnerContainerViewRowRightBody>
                        {selectDefaultSubcategoryId !== null &&
                          defaultCategories.map((defaultCategory) => {
                            if (
                              defaultCategory.id === selectDefaultCategoryId
                            ) {
                              return defaultCategory.defaultSubcategories.map(
                                (defaultSubcategory) => {
                                  if (
                                    defaultSubcategory.id ===
                                    selectDefaultSubcategoryId
                                  ) {
                                    return defaultSubcategory.defaultTopics.map(
                                      (defaultTopic) => {
                                        return (
                                          <InfoInnerContainerViewRowRightBodyTile
                                            key={defaultTopic.id}
                                            onClick={() => {
                                              console.log(
                                                'defaultTopic',
                                                defaultTopic
                                              )
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                              }}
                                              onClick={() => {
                                                toggleDefaultTopicVisibility(
                                                  defaultCategory.id,
                                                  defaultSubcategory.id,
                                                  defaultTopic.id
                                                )
                                              }}
                                            >
                                              {defaultTopic.title}
                                              <FontAwesomeIcon
                                                icon={
                                                  defaultTopic.menuVisible
                                                    ? faChevronUp
                                                    : faChevronDown
                                                }
                                              />
                                            </div>
                                            {defaultTopic.menuVisible && (
                                              <div
                                                style={{
                                                  paddingTop: 20,
                                                  paddingBottom: 20,
                                                }}
                                              >
                                                {defaultTopic.imgUrl !== '' &&
                                                  defaultTopic.imgUrl !==
                                                    null && (
                                                    <img
                                                      alt=""
                                                      src={`${defaultTopic.imgUrl}`}
                                                      style={{
                                                        maxWidth: '100%',
                                                        width: '100%',
                                                        objectFit: 'contain',
                                                        margin: '15px 0',
                                                        overflowClipMargin:
                                                          'content-box',
                                                        overflow: 'clip',
                                                        boxSizing: 'border-box',
                                                      }}
                                                    ></img>
                                                  )}
                                                <InfoInnerContainerViewRowRightBodyTileContent>
                                                  <Editor
                                                    editorState={EditorState.createWithContent(
                                                      convertFromRaw(
                                                        JSON.parse(
                                                          defaultTopic.content
                                                        )
                                                      )
                                                    )}
                                                    toolbarClassName="toolbarClassName"
                                                    wrapperClassName="wrapperClassName"
                                                    editorClassName="editorClassName"
                                                    toolbarHidden={true}
                                                    readOnly={true}
                                                    mention={{
                                                      separator: ' ',
                                                      trigger: '@',
                                                      suggestions: [
                                                        {
                                                          text: 'APPLE',
                                                          value: 'apple',
                                                        },
                                                        {
                                                          text: 'BANANA',
                                                          value: 'banana',
                                                          url: 'banana',
                                                        },
                                                        {
                                                          text: 'CHERRY',
                                                          value: 'cherry',
                                                          url: 'cherry',
                                                        },
                                                        {
                                                          text: 'DURIAN',
                                                          value: 'durian',
                                                          url: 'durian',
                                                        },
                                                        {
                                                          text: 'EGGFRUIT',
                                                          value: 'eggfruit',
                                                          url: 'eggfruit',
                                                        },
                                                        {
                                                          text: 'FIG',
                                                          value: 'fig',
                                                          url: 'fig',
                                                        },
                                                        {
                                                          text: 'GRAPEFRUIT',
                                                          value: 'grapefruit',
                                                          url: 'grapefruit',
                                                        },
                                                        {
                                                          text: 'HONEYDEW',
                                                          value: 'honeydew',
                                                          url: 'honeydew',
                                                        },
                                                      ],
                                                    }}
                                                  />
                                                  {defaultTopic.latitude !==
                                                    null &&
                                                    defaultTopic.longitude !==
                                                      null && (
                                                      <div
                                                        style={{
                                                          margin: '15px 0',
                                                          boxSizing:
                                                            'border-box',
                                                        }}
                                                      >
                                                        <div
                                                          style={{
                                                            display: 'flex',
                                                            alignItems:
                                                              'center',
                                                            boxSizing:
                                                              'border-box',
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              borderRadius:
                                                                '50%',
                                                              width: 50,
                                                              height: 50,
                                                              display: 'flex',
                                                              alignItems:
                                                                'center',
                                                              justifyContent:
                                                                'center',
                                                              marginRight: 10,
                                                              backgroundColor:
                                                                '#eba55d',
                                                              cursor: 'pointer',
                                                              color: '#fff',
                                                            }}
                                                            onClick={() => {
                                                              setCenter({
                                                                latitude:
                                                                  defaultTopic.latitude,
                                                                longitude:
                                                                  defaultTopic.longitude,
                                                              })
                                                              setMapError(false)
                                                              setSelectDefaultCategory(
                                                                defaultCategory
                                                              )
                                                              setSelectDefaultCategoryId(
                                                                defaultCategory.id
                                                              )
                                                              setSelectDefaultSubcategoryId(
                                                                defaultSubcategory.id
                                                              )
                                                              handleMarkerClick(
                                                                defaultTopic
                                                              )
                                                              setTabIndex(3)
                                                            }}
                                                          >
                                                            <LocationIconFixed></LocationIconFixed>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    )}
                                                </InfoInnerContainerViewRowRightBodyTileContent>
                                              </div>
                                            )}
                                          </InfoInnerContainerViewRowRightBodyTile>
                                        )
                                      }
                                    )
                                  }
                                }
                              )
                            }
                          })}
                      </InfoInnerContainerViewRowRightBody>
                    </InfoInnerContainerViewRowRight>
                  </InfoInnerContainerViewRow>
                </InfoInnerContainerView>
              )}
              {tabIndex === 2 && (
                <StoreInnerContainerView>
                  <StoreInnerContainerViewRow>
                    <StoreInnerContainerViewRowRight>
                      <StoreInnerContainerViewRowRightHeader>
                        STORE
                      </StoreInnerContainerViewRowRightHeader>
                      <StoreInnerContainerViewRowRightBody>
                        {userProducts.map((userProduct) => {
                          return (
                            <StoreInnerContainerViewRowRightBodyTile
                              key={userProduct.id}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                }}
                                onClick={() => {
                                  toggleUserProductVisibility(userProduct.id)
                                }}
                              >
                                {userProduct.title}
                                <FontAwesomeIcon
                                  icon={
                                    userProduct.menuVisible
                                      ? faChevronUp
                                      : faChevronDown
                                  }
                                />
                              </div>
                              {userProduct.menuVisible && (
                                <div
                                  style={{
                                    paddingTop: 20,
                                    paddingBottom: 20,
                                  }}
                                >
                                  {userProduct.imgUrl !== '' &&
                                    userProduct.imgUrl !== null && (
                                      <img
                                        alt=""
                                        src={`${userProduct.imgUrl}`}
                                        style={{
                                          maxWidth: '100%',
                                          width: '100%',
                                          objectFit: 'contain',
                                          margin: '15px 0',
                                          overflowClipMargin: 'content-box',
                                          overflow: 'clip',
                                          boxSizing: 'border-box',
                                        }}
                                      ></img>
                                    )}
                                  <StoreInnerContainerViewRowRightBodyTileContent>
                                    <Editor
                                      editorState={EditorState.createWithContent(
                                        convertFromRaw(
                                          JSON.parse(userProduct.content)
                                        )
                                      )}
                                      toolbarClassName="toolbarClassName"
                                      wrapperClassName="wrapperClassName"
                                      editorClassName="editorClassName"
                                      toolbarHidden={true}
                                      readOnly={true}
                                      mention={{
                                        separator: ' ',
                                        trigger: '@',
                                        suggestions: [
                                          {
                                            text: 'APPLE',
                                            value: 'apple',
                                          },
                                          {
                                            text: 'BANANA',
                                            value: 'banana',
                                            url: 'banana',
                                          },
                                          {
                                            text: 'CHERRY',
                                            value: 'cherry',
                                            url: 'cherry',
                                          },
                                          {
                                            text: 'DURIAN',
                                            value: 'durian',
                                            url: 'durian',
                                          },
                                          {
                                            text: 'EGGFRUIT',
                                            value: 'eggfruit',
                                            url: 'eggfruit',
                                          },
                                          {
                                            text: 'FIG',
                                            value: 'fig',
                                            url: 'fig',
                                          },
                                          {
                                            text: 'GRAPEFRUIT',
                                            value: 'grapefruit',
                                            url: 'grapefruit',
                                          },
                                          {
                                            text: 'HONEYDEW',
                                            value: 'honeydew',
                                            url: 'honeydew',
                                          },
                                        ],
                                      }}
                                    />
                                    <OpacityBtn
                                      disabled={false}
                                      backgroundColor={c_eba55d}
                                      fontColor={c_ffffff}
                                      onClick={() => {}}
                                    >
                                      &#8361;
                                      {priceFormatFunction(
                                        userProduct.price
                                      )}{' '}
                                      checkout
                                    </OpacityBtn>
                                  </StoreInnerContainerViewRowRightBodyTileContent>
                                </div>
                              )}
                            </StoreInnerContainerViewRowRightBodyTile>
                          )
                        })}
                      </StoreInnerContainerViewRowRightBody>
                    </StoreInnerContainerViewRowRight>
                  </StoreInnerContainerViewRow>
                </StoreInnerContainerView>
              )}
              {tabIndex === 3 && (
                <MapInnerContainerView>
                  {mapError && (
                    <MapInnerContainerViewError>
                      <MapInnerContainerViewErrorH2>
                        The address needs to be updated.
                      </MapInnerContainerViewErrorH2>
                    </MapInnerContainerViewError>
                  )}
                  {!mapError && isLoaded && (
                    <GoogleMap
                      mapContainerStyle={{ width: '100%', height: '100%' }}
                      center={{
                        lat: center.latitude,
                        lng: center.longitude,
                      }}
                      zoom={zoom}
                      onLoad={onLoad}
                      onUnmount={onUnmount}
                      options={{
                        styles: [
                          {
                            featureType: 'poi',
                            elementType: 'labels',
                            stylers: [{ visibility: 'off' }],
                          },
                        ],
                      }}
                    >
                      {defaultCategories.map((defaultCategory) =>
                        defaultCategory.defaultSubcategories.map(
                          (defaultSubcategory) =>
                            defaultSubcategory.defaultTopics.map(
                              (defaultTopic) => {
                                console.log(
                                  'topic:',
                                  defaultTopic.latitude,
                                  defaultTopic.longitude,
                                  defaultTopic.mapPinIconUrl
                                )
                                if (
                                  defaultTopic.latitude &&
                                  defaultTopic.longitude &&
                                  defaultTopic.mapPinIconUrl
                                ) {
                                  return (
                                    <MarkerF
                                      key={defaultTopic.id}
                                      position={{
                                        lat: defaultTopic.latitude,
                                        lng: defaultTopic.longitude,
                                      }}
                                      draggable={false}
                                      icon={{
                                        url: defaultTopic.mapPinIconUrl,
                                        scaledSize: new window.google.maps.Size(
                                          40,
                                          40
                                        ),
                                      }}
                                      onClick={() => {
                                        setCenter({
                                          latitude: defaultTopic.latitude,
                                          longitude: defaultTopic.longitude,
                                        })
                                        setSelectDefaultCategory(
                                          defaultCategory
                                        )
                                        setSelectDefaultCategoryId(
                                          defaultCategory.id
                                        )
                                        setSelectDefaultSubcategoryId(
                                          defaultSubcategory.id
                                        )
                                        handleMarkerClick(defaultTopic)
                                      }}
                                    />
                                  )
                                }
                              }
                            )
                        )
                      )}
                      {console.log('position:', position)}
                      {position.latitude && position.longitude && (
                        <MarkerF
                          position={{
                            lat: position.latitude,
                            lng: position.longitude,
                          }}
                          draggable={false}
                          icon={{
                            url: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
                            scaledSize: new window.google.maps.Size(40, 40),
                          }}
                        />
                      )}
                      {selectedMarker && (
                        <InfoWindow
                          position={{
                            lat: selectedMarker.latitude,
                            lng: selectedMarker.longitude,
                          }}
                          onCloseClick={() => {
                            setSelectDefaultCategory(null)
                            setSelectDefaultCategoryId(null)
                            setSelectDefaultSubcategoryId(null)
                            setSelectedMarker(null)
                          }}
                          options={{
                            pixelOffset: new window.google.maps.Size(0, -30),
                          }}
                        >
                          <div
                            style={{
                              minWidth: '100px',
                              minHeight: '50px',
                              overflow: 'hidden',
                              borderRadius: '8px',
                              backgroundColor: 'white',
                              padding: '5px',
                              boxSizing: 'border-box',
                              wordWrap: 'break-word',
                              overflowWrap: 'break-word',
                            }}
                          >
                            <h3>{selectedMarker.title}</h3>
                            <div
                              style={{
                                maxHeight: '150px',
                                overflowY: 'auto',
                                marginTop: '10px',
                              }}
                            >
                              <Editor
                                editorState={EditorState.createWithContent(
                                  convertFromRaw(
                                    JSON.parse(selectedMarker.content)
                                  )
                                )}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                toolbarHidden={true}
                                readOnly={true}
                                mention={{
                                  separator: ' ',
                                  trigger: '@',
                                  suggestions: [
                                    {
                                      text: 'APPLE',
                                      value: 'apple',
                                    },
                                    {
                                      text: 'BANANA',
                                      value: 'banana',
                                      url: 'banana',
                                    },
                                    {
                                      text: 'CHERRY',
                                      value: 'cherry',
                                      url: 'cherry',
                                    },
                                    {
                                      text: 'DURIAN',
                                      value: 'durian',
                                      url: 'durian',
                                    },
                                    {
                                      text: 'EGGFRUIT',
                                      value: 'eggfruit',
                                      url: 'eggfruit',
                                    },
                                    {
                                      text: 'FIG',
                                      value: 'fig',
                                      url: 'fig',
                                    },
                                    {
                                      text: 'GRAPEFRUIT',
                                      value: 'grapefruit',
                                      url: 'grapefruit',
                                    },
                                    {
                                      text: 'HONEYDEW',
                                      value: 'honeydew',
                                      url: 'honeydew',
                                    },
                                  ],
                                }}
                              />
                            </div>
                            <div
                              onClick={() => {
                                setSelectDefaultCategoryId(
                                  selectDefaultCategoryId
                                )
                                setSelectDefaultSubcategoryId(
                                  selectDefaultSubcategoryId
                                )
                                toggleDefaultTopicVisibilityOnlyOpen(
                                  selectDefaultCategoryId,
                                  selectDefaultSubcategoryId,
                                  selectedMarker.id
                                )
                                setTabIndex(1)
                                setSelectedMarker(null)
                              }}
                              style={{
                                color: 'blue',
                                marginTop: '10px',
                              }}
                            >
                              More info
                            </div>
                            <div
                              style={{
                                display: 'flex',
                              }}
                            >
                              <div
                                onClick={() => {
                                  console.log(selectedMarker)
                                  const url = `https://www.google.com/maps/dir/?api=1&destination=${selectedMarker.latitude},${selectedMarker.longitude}`
                                  window.open(url, '_blank')
                                }}
                                style={{
                                  color: 'blue',
                                  marginTop: '20px',
                                }}
                              >
                                Google
                              </div>
                              <div
                                style={{
                                  marginTop: '20px',
                                  marginLeft: '10px',
                                  marginRight: '10px',
                                }}
                              >
                                |
                              </div>
                              <div
                                onClick={() => {
                                  console.log(selectedMarker)
                                  // const url = `https://map.naver.com/v5/?c=${selectedMarker.longitude},${selectedMarker.latitude},18,0,0,0,dh`
                                  const url = `https://map.naver.com/p/search/${selectedMarker.address}?c=15.00,0,0,0,dh`
                                  window.open(url, '_blank')
                                }}
                                style={{
                                  color: 'blue',
                                  marginTop: '20px',
                                }}
                              >
                                Naver
                              </div>
                            </div>
                          </div>
                        </InfoWindow>
                      )}
                    </GoogleMap>
                  )}
                </MapInnerContainerView>
              )}
              {tabIndex === 4 && (
                <SearchInnerContainerView>
                  <SearchInnerContainerViewInner
                    style={{
                      top: searchText.length === 0 ? '50%' : '75px',
                    }}
                  >
                    <SearchInnerContainerViewInnerDiv>
                      <SearchInnerContainerViewInnerDivInputWrapper>
                        <SearchInnerContainerViewInnerDivInputWrapperSpan>
                          <SearchIconFixed></SearchIconFixed>
                        </SearchInnerContainerViewInnerDivInputWrapperSpan>
                        <SearchInnerContainerViewInnerDivInputWrapperInput
                          autoComplete="off"
                          placeholder="Search for keywords"
                          value={searchText}
                          onChange={(e) => {
                            setSearchText(e.target.value)
                            let {
                              tempDefaultSubcategoriesFoundByTitle,
                              tempDefaultSubcategoriesFoundByContent,
                            } = searchDefaultSubcategories(e.target.value)
                            setDefaultSubcategoriesFoundByTitle(
                              tempDefaultSubcategoriesFoundByTitle
                            )
                            setDefaultSubcategoriesFoundByContent(
                              tempDefaultSubcategoriesFoundByContent
                            )
                          }}
                        ></SearchInnerContainerViewInnerDivInputWrapperInput>
                      </SearchInnerContainerViewInnerDivInputWrapper>
                    </SearchInnerContainerViewInnerDiv>
                    {searchText.length === 0 && (
                      <SearchInnerContainerViewInnerP>
                        Search results will appear featuring matches for all
                        your keywords.
                      </SearchInnerContainerViewInnerP>
                    )}
                    {searchText.length !== 0 &&
                      defaultSubcategoriesFoundByTitle.length === 0 &&
                      defaultSubcategoriesFoundByContent.length === 0 && (
                        <SearchInnerContainerViewInnerP>
                          No results found. Please try again.
                        </SearchInnerContainerViewInnerP>
                      )}
                    <SearchTileFrame>
                      {searchText.length !== 0 &&
                        defaultSubcategoriesFoundByTitle.length !== 0 && (
                          <>
                            <h4
                              style={{
                                fontWeight: 700,
                                fontSize: '1.125rem',
                                opacity: 0.8,
                                margin: '15px 0',
                              }}
                            >
                              Matches in a section heading
                            </h4>
                            <SearchTileWrapper>
                              {defaultSubcategoriesFoundByTitle.map(
                                (defaultSubcategory) => {
                                  return (
                                    <SearchTile
                                      key={
                                        defaultSubcategory.defaultSubcategoryId
                                      }
                                      onClick={() => {
                                        openDefaultSubcategoryAndItsDefaultTopics(
                                          defaultSubcategory.defaultCategoryId,
                                          defaultSubcategory.defaultSubcategoryId
                                        )
                                        setSelectDefaultCategoryId(
                                          defaultSubcategory.defaultCategoryId
                                        )
                                        setSelectDefaultSubcategoryId(
                                          defaultSubcategory.defaultSubcategoryId
                                        )
                                        setTabIndex(1)
                                      }}
                                    >
                                      {defaultSubcategory.title}
                                    </SearchTile>
                                  )
                                }
                              )}
                            </SearchTileWrapper>
                          </>
                        )}
                      {searchText.length !== 0 &&
                        defaultSubcategoriesFoundByContent.length !== 0 && (
                          <>
                            <h4
                              style={{
                                fontWeight: 700,
                                fontSize: '1.125rem',
                                opacity: 0.8,
                                margin: '15px 0',
                              }}
                            >
                              Matches in a section description
                            </h4>
                            <SearchTileWrapper>
                              {defaultSubcategoriesFoundByContent.map(
                                (defaultSubcategory) => {
                                  return (
                                    <SearchTile
                                      key={
                                        defaultSubcategory.defaultSubcategoryId
                                      }
                                      onClick={() => {
                                        openDefaultSubcategoryAndItsDefaultTopics(
                                          defaultSubcategory.defaultCategoryId,
                                          defaultSubcategory.defaultSubcategoryId
                                        )
                                        setTabIndex(1)
                                      }}
                                    >
                                      {defaultSubcategory.title}
                                    </SearchTile>
                                  )
                                }
                              )}
                            </SearchTileWrapper>
                          </>
                        )}
                    </SearchTileFrame>
                  </SearchInnerContainerViewInner>
                </SearchInnerContainerView>
              )}
              <Footer>
                <FooterNav>
                  {tabList.map((tab) => {
                    return (
                      <FooterNavTab
                        key={tab.id}
                        id={tab.id}
                        onClick={() => {
                          if (end === false) {
                            if (tab.id !== 2) {
                              setPosition({
                                latitude:
                                  defaultGuide.latitude === null
                                    ? 37.5665
                                    : defaultGuide.latitude,
                                longitude:
                                  defaultGuide.longitude === null
                                    ? 126.978
                                    : defaultGuide.longitude,
                              })
                              setCenter({
                                latitude:
                                  defaultGuide.latitude === null
                                    ? 37.5665
                                    : defaultGuide.latitude,
                                longitude:
                                  defaultGuide.longitude === null
                                    ? 126.978
                                    : defaultGuide.longitude,
                              })
                              setMapError(
                                defaultGuide.latitude === null ||
                                  defaultGuide.longitude === null
                                  ? true
                                  : false
                              )
                            }

                            setTabIndex(tab.id)
                          }
                        }}
                        $tabIndex={tabIndex}
                        $index={tab.id}
                      >
                        {tab.title}
                      </FooterNavTab>
                    )
                  })}
                </FooterNav>
              </Footer>
            </InnerContainer>
          </TotalContainer>
        ) : (
          <TotalContainer>
            <InnerContainer>
              {tabIndex === 0 && (
                <HomeInnerContainerView>
                  <InnerContainerViewOverlay></InnerContainerViewOverlay>
                  <InnerContainerViewCenterDiv>
                    <InnerContainerViewCenterDivImg
                      style={{
                        borderRadius: defaultGuide.logoImageRounded ? '50%' : 0,
                      }}
                      src={defaultGuide.logoImageUrl}
                    ></InnerContainerViewCenterDivImg>
                    <InnerContainerViewCenterDivP>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {defaultGuide.hostName}
                      </span>
                    </InnerContainerViewCenterDivP>
                    <InnerContainerViewCenterDivContactDiv>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <PhoneIconFixed></PhoneIconFixed>
                        <InnerContainerViewCenterDivContactDivSpan>
                          {defaultGuide.hostPhoneNumber !== null &&
                          defaultGuide.hostPhoneNumber !== '' &&
                          defaultGuide.hostPhoneNumber !== undefined
                            ? formatPhoneNumber(defaultGuide.hostPhoneNumber)
                            : ''}
                        </InnerContainerViewCenterDivContactDivSpan>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginTop: 10,
                        }}
                      >
                        <PaperPlaneIconFixed></PaperPlaneIconFixed>
                        <InnerContainerViewCenterDivContactDivSpan>
                          {defaultGuide.email}
                        </InnerContainerViewCenterDivContactDivSpan>
                      </div>
                    </InnerContainerViewCenterDivContactDiv>
                    <InnerContainerViewCenterDivDivideSpan></InnerContainerViewCenterDivDivideSpan>
                    <InnerContainerViewCenterDivSpan>
                      {defaultGuide.guideName}
                    </InnerContainerViewCenterDivSpan>
                    <div
                      style={{
                        marginTop: '1.5rem',
                      }}
                    >
                      <SmallChangeBtn
                        onClick={() => {
                          setTabIndex(1)
                        }}
                        beforeBackgroudColor={c_eba55d}
                        beforeBorderColor={c_eba55d}
                        beforeFontColor={c_ffffff}
                        afterBackgroudColor={'transparent'}
                        afterBorderColor={c_eba55d}
                        afterFontColor={c_ffffff}
                      >
                        START
                      </SmallChangeBtn>
                    </div>
                  </InnerContainerViewCenterDiv>
                  <Overlay></Overlay>
                  <InnerContainerViewBackgroundImg
                    src={
                      isPc
                        ? defaultGuide.pcImageUrl
                        : defaultGuide.mobileImageUrl
                    }
                  ></InnerContainerViewBackgroundImg>
                </HomeInnerContainerView>
              )}
              {tabIndex === 1 && (
                <MobileInfoView>
                  {!showMobileInfoDetail ? (
                    <MobileInfoViewHeader>
                      <MobileInfoViewHeaderInner>
                        <MobileInfoViewHeaderInnerLeft>
                          <InfoInnerContainerViewRowLeftInnerHeaderRowLeftImg
                            style={{
                              borderRadius: defaultGuide.logoImageRounded
                                ? '50%'
                                : 0,
                            }}
                            src={defaultGuide.logoImageUrl}
                          ></InfoInnerContainerViewRowLeftInnerHeaderRowLeftImg>
                          <InfoInnerContainerViewRowLeftInnerHeaderRowIconStyle>
                            <PhoneIconFixed
                              onClick={() => {
                                if (isPc) {
                                  navigator.clipboard.writeText(
                                    defaultGuide.hostPhoneNumber
                                  )
                                  show('copy phone number')
                                } else {
                                  window.location.href = `tel:${defaultGuide.hostPhoneNumber}`
                                }
                              }}
                            ></PhoneIconFixed>
                          </InfoInnerContainerViewRowLeftInnerHeaderRowIconStyle>
                        </MobileInfoViewHeaderInnerLeft>
                        <MobileInfoViewHeaderInnerRight>
                          <InfoInnerContainerViewRowLeftInnerHeaderRowIconStyle>
                            <ShareIconFixed
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  defaultGuide.shareUrl
                                )
                                show('url copied')
                              }}
                            ></ShareIconFixed>
                          </InfoInnerContainerViewRowLeftInnerHeaderRowIconStyle>
                          <InfoInnerContainerViewRowLeftInnerHeaderRowIconStyle>
                            <HeadsetFixed
                              onClick={() => {
                                setShowMoveTo1330Modal(true)
                              }}
                            ></HeadsetFixed>
                          </InfoInnerContainerViewRowLeftInnerHeaderRowIconStyle>
                        </MobileInfoViewHeaderInnerRight>
                      </MobileInfoViewHeaderInner>
                    </MobileInfoViewHeader>
                  ) : (
                    <ShowMobileInfoDetailHeader>
                      <ShowMobileInfoDetailHeaderInner>
                        <ShowMobileInfoDetailHeaderInnerLeft>
                          <ShowMobileInfoDetailHeaderInnerIconStyle
                            onClick={() => {
                              setShowMobileInfoDetail(false)
                              const hasMultipleSubcategories =
                                defaultCategories.some((defaultCategory) => {
                                  if (
                                    defaultCategory.id ===
                                    selectDefaultCategoryId
                                  ) {
                                    return (
                                      defaultCategory.defaultSubcategories
                                        .length > 1
                                    )
                                  }
                                  return false
                                })

                              if (hasMultipleSubcategories) {
                                setShowMobileDefaultSubcategoriesModal(true)
                              } else {
                                setShowMobileDefaultSubcategoriesModal(false)
                              }
                            }}
                          >
                            <ArrowLeftIconFixed></ArrowLeftIconFixed>
                          </ShowMobileInfoDetailHeaderInnerIconStyle>
                          <ShowMobileInfoDetailHeaderInnerP>
                            {defaultCategories.map((defaultCategory) => {
                              if (
                                defaultCategory.id === selectDefaultCategoryId
                              ) {
                                return defaultCategory.defaultSubcategories.map(
                                  (defaultSubcategory) => {
                                    if (
                                      defaultSubcategory.id ===
                                      selectDefaultSubcategoryId
                                    ) {
                                      return defaultSubcategory.title
                                    }
                                  }
                                )
                              }
                            })}
                          </ShowMobileInfoDetailHeaderInnerP>
                        </ShowMobileInfoDetailHeaderInnerLeft>
                        <ShowMobileInfoDetailHeaderInnerRight>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              fontSize: '25px',
                              textShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)',
                            }}
                          >
                            <CircleArrowUpIconFixed
                              onClick={() => {
                                closeAllDefaultTopicsInSubcategory(
                                  selectDefaultCategoryId,
                                  selectDefaultSubcategoryId
                                )
                              }}
                            ></CircleArrowUpIconFixed>
                            <div
                              style={{
                                marginLeft: '18px',
                              }}
                            >
                              <CircleArrowDownIconFixed
                                onClick={() => {
                                  openAllDefaultTopicsInSubcategory(
                                    selectDefaultCategoryId,
                                    selectDefaultSubcategoryId
                                  )
                                }}
                              ></CircleArrowDownIconFixed>
                            </div>
                          </div>
                        </ShowMobileInfoDetailHeaderInnerRight>
                      </ShowMobileInfoDetailHeaderInner>
                    </ShowMobileInfoDetailHeader>
                  )}
                  <MobileInfoViewBody>
                    {!showMobileInfoDetail ? (
                      <MobileInfoViewMainBody>
                        <MobileInfoViewMainBodyInner>
                          <MobileInfoViewMainBodyInnerInner>
                            <MobileInfoViewMainBodyInnerInnerWrapper>
                              {defaultCategories.map(
                                (defaultCategory, defaultCategoryIndex) => {
                                  return (
                                    <MobileInfoViewMainBodyInnerInnerWrapperCard
                                      key={defaultCategory.id}
                                      $defaultCategoriesLength={
                                        defaultCategories.length
                                      }
                                      onClick={() => {
                                        if (
                                          defaultCategory.defaultSubcategories
                                            .length === 0
                                        ) {
                                          show('no subcategories', false)
                                        } else if (
                                          defaultCategory.defaultSubcategories
                                            .length !== 1
                                        ) {
                                          setSelectDefaultCategoryId(
                                            defaultCategory.id
                                          )
                                          setSelectDefaultCategory(
                                            defaultCategory
                                          )
                                          setShowMobileDefaultSubcategoriesModal(
                                            true
                                          )
                                        } else {
                                          setSelectDefaultCategoryId(
                                            defaultCategory.id
                                          )
                                          setSelectDefaultSubcategoryId(
                                            defaultCategory
                                              .defaultSubcategories[0].id
                                          )
                                          setShowMobileInfoDetail(true)
                                        }
                                      }}
                                    >
                                      <MobileInfoViewMainBodyInnerInnerWrapperCardThumbnail>
                                        <MobileInfoViewMainBodyInnerInnerWrapperCardThumbnailIconStyle>
                                          <img
                                            src={defaultCategory.iconUrl}
                                            alt=""
                                          ></img>
                                        </MobileInfoViewMainBodyInnerInnerWrapperCardThumbnailIconStyle>
                                        <MobileInfoViewMainBodyInnerInnerWrapperCardThumbnailDiv>
                                          {defaultCategory.title}
                                        </MobileInfoViewMainBodyInnerInnerWrapperCardThumbnailDiv>
                                      </MobileInfoViewMainBodyInnerInnerWrapperCardThumbnail>
                                    </MobileInfoViewMainBodyInnerInnerWrapperCard>
                                  )
                                }
                              )}
                            </MobileInfoViewMainBodyInnerInnerWrapper>
                          </MobileInfoViewMainBodyInnerInner>
                        </MobileInfoViewMainBodyInner>
                      </MobileInfoViewMainBody>
                    ) : (
                      <MobileInfoViewMainBody>
                        <MobileInfoViewMainBodyInner>
                          <MobileInfoViewMainBodyInnerInner>
                            {selectDefaultSubcategoryId !== null &&
                              defaultCategories.map((defaultCategory) => {
                                if (
                                  defaultCategory.id === selectDefaultCategoryId
                                ) {
                                  return defaultCategory.defaultSubcategories.map(
                                    (defaultSubcategory) => {
                                      if (
                                        defaultSubcategory.id ===
                                        selectDefaultSubcategoryId
                                      ) {
                                        return defaultSubcategory.defaultTopics.map(
                                          (defaultTopic) => {
                                            return (
                                              <MobileInfoInnerContainerViewRowRightBodyTile
                                                key={defaultTopic.id}
                                              >
                                                <div
                                                  style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent:
                                                      'space-between',
                                                  }}
                                                  onClick={() => {
                                                    toggleDefaultTopicVisibility(
                                                      defaultCategory.id,
                                                      defaultSubcategory.id,
                                                      defaultTopic.id
                                                    )
                                                  }}
                                                >
                                                  {defaultTopic.title}
                                                  <FontAwesomeIcon
                                                    icon={
                                                      defaultTopic.menuVisible
                                                        ? faChevronUp
                                                        : faChevronDown
                                                    }
                                                  />
                                                </div>
                                                {defaultTopic.menuVisible && (
                                                  <div
                                                    style={{
                                                      paddingTop: 20,
                                                      paddingBottom: 20,
                                                    }}
                                                  >
                                                    {defaultTopic.imgUrl !==
                                                      '' &&
                                                      defaultTopic.imgUrl !==
                                                        null && (
                                                        <img
                                                          alt=""
                                                          src={`${defaultTopic.imgUrl}`}
                                                          style={{
                                                            maxWidth: '100%',
                                                            width: '100%',
                                                            objectFit:
                                                              'contain',
                                                            margin: '15px 0',
                                                            overflowClipMargin:
                                                              'content-box',
                                                            overflow: 'clip',
                                                            boxSizing:
                                                              'border-box',
                                                          }}
                                                        ></img>
                                                      )}
                                                    <MobileInfoInnerContainerViewRowRightBodyTileContent>
                                                      <Editor
                                                        editorState={EditorState.createWithContent(
                                                          convertFromRaw(
                                                            JSON.parse(
                                                              defaultTopic.content
                                                            )
                                                          )
                                                        )}
                                                        toolbarClassName="toolbarClassName"
                                                        wrapperClassName="wrapperClassName"
                                                        editorClassName="editorClassName"
                                                        toolbarHidden={true}
                                                        readOnly={true}
                                                        mention={{
                                                          separator: ' ',
                                                          trigger: '@',
                                                          suggestions: [
                                                            {
                                                              text: 'APPLE',
                                                              value: 'apple',
                                                            },
                                                            {
                                                              text: 'BANANA',
                                                              value: 'banana',
                                                              url: 'banana',
                                                            },
                                                            {
                                                              text: 'CHERRY',
                                                              value: 'cherry',
                                                              url: 'cherry',
                                                            },
                                                            {
                                                              text: 'DURIAN',
                                                              value: 'durian',
                                                              url: 'durian',
                                                            },
                                                            {
                                                              text: 'EGGFRUIT',
                                                              value: 'eggfruit',
                                                              url: 'eggfruit',
                                                            },
                                                            {
                                                              text: 'FIG',
                                                              value: 'fig',
                                                              url: 'fig',
                                                            },
                                                            {
                                                              text: 'GRAPEFRUIT',
                                                              value:
                                                                'grapefruit',
                                                              url: 'grapefruit',
                                                            },
                                                            {
                                                              text: 'HONEYDEW',
                                                              value: 'honeydew',
                                                              url: 'honeydew',
                                                            },
                                                          ],
                                                        }}
                                                      />
                                                      {defaultTopic.latitude !==
                                                        null &&
                                                        defaultTopic.longitude !==
                                                          null && (
                                                          <div
                                                            style={{
                                                              margin: '15px 0',
                                                              boxSizing:
                                                                'border-box',
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                display: 'flex',
                                                                alignItems:
                                                                  'center',
                                                                boxSizing:
                                                                  'border-box',
                                                              }}
                                                            >
                                                              <div
                                                                style={{
                                                                  borderRadius:
                                                                    '50%',
                                                                  width: 50,
                                                                  height: 50,
                                                                  display:
                                                                    'flex',
                                                                  alignItems:
                                                                    'center',
                                                                  justifyContent:
                                                                    'center',
                                                                  marginRight: 10,
                                                                  backgroundColor:
                                                                    '#eba55d',
                                                                  cursor:
                                                                    'pointer',
                                                                  color: '#fff',
                                                                }}
                                                                onClick={() => {
                                                                  setCenter({
                                                                    latitude:
                                                                      defaultTopic.latitude,
                                                                    longitude:
                                                                      defaultTopic.longitude,
                                                                  })
                                                                  setMapError(
                                                                    false
                                                                  )
                                                                  setSelectDefaultCategory(
                                                                    defaultCategory
                                                                  )
                                                                  setSelectDefaultCategoryId(
                                                                    defaultCategory.id
                                                                  )
                                                                  setSelectDefaultSubcategoryId(
                                                                    defaultSubcategory.id
                                                                  )
                                                                  handleMarkerClick(
                                                                    defaultTopic
                                                                  )
                                                                  setTabIndex(3)
                                                                }}
                                                              >
                                                                <LocationIconFixed></LocationIconFixed>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        )}
                                                    </MobileInfoInnerContainerViewRowRightBodyTileContent>
                                                  </div>
                                                )}
                                              </MobileInfoInnerContainerViewRowRightBodyTile>
                                            )
                                          }
                                        )
                                      }
                                    }
                                  )
                                }
                              })}
                          </MobileInfoViewMainBodyInnerInner>
                        </MobileInfoViewMainBodyInner>
                      </MobileInfoViewMainBody>
                    )}
                  </MobileInfoViewBody>
                </MobileInfoView>
              )}
              {tabIndex === 2 && (
                <MobileInfoView>
                  <ShowMobileInfoDetailHeader>
                    <ShowMobileInfoDetailHeaderInner>
                      <ShowMobileInfoDetailHeaderInnerLeft>
                        <ShowMobileInfoDetailHeaderInnerP>
                          STORE
                        </ShowMobileInfoDetailHeaderInnerP>
                      </ShowMobileInfoDetailHeaderInnerLeft>
                      <ShowMobileInfoDetailHeaderInnerRight></ShowMobileInfoDetailHeaderInnerRight>
                    </ShowMobileInfoDetailHeaderInner>
                  </ShowMobileInfoDetailHeader>
                  <MobileInfoViewBody>
                    <MobileInfoViewMainBody>
                      <MobileInfoViewMainBodyInner>
                        <MobileInfoViewMainBodyInnerInner>
                          {userProducts.map((userProduct) => {
                            return (
                              <MobileInfoInnerContainerViewRowRightBodyTile
                                key={userProduct.id}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                  }}
                                  onClick={() => {
                                    toggleUserProductVisibility(userProduct.id)
                                  }}
                                >
                                  {userProduct.title}
                                  <FontAwesomeIcon
                                    icon={
                                      userProduct.menuVisible
                                        ? faChevronUp
                                        : faChevronDown
                                    }
                                  />
                                </div>
                                {userProduct.menuVisible && (
                                  <div
                                    style={{
                                      paddingTop: 20,
                                      paddingBottom: 20,
                                    }}
                                  >
                                    {userProduct.imgUrl !== '' &&
                                      userProduct.imgUrl !== null && (
                                        <img
                                          alt=""
                                          src={`${userProduct.imgUrl}`}
                                          style={{
                                            maxWidth: '100%',
                                            width: '100%',
                                            objectFit: 'contain',
                                            margin: '15px 0',
                                            overflowClipMargin: 'content-box',
                                            overflow: 'clip',
                                            boxSizing: 'border-box',
                                          }}
                                        ></img>
                                      )}
                                    <MobileInfoInnerContainerViewRowRightBodyTileContent>
                                      <Editor
                                        editorState={EditorState.createWithContent(
                                          convertFromRaw(
                                            JSON.parse(userProduct.content)
                                          )
                                        )}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        toolbarHidden={true}
                                        readOnly={true}
                                        mention={{
                                          separator: ' ',
                                          trigger: '@',
                                          suggestions: [
                                            {
                                              text: 'APPLE',
                                              value: 'apple',
                                            },
                                            {
                                              text: 'BANANA',
                                              value: 'banana',
                                              url: 'banana',
                                            },
                                            {
                                              text: 'CHERRY',
                                              value: 'cherry',
                                              url: 'cherry',
                                            },
                                            {
                                              text: 'DURIAN',
                                              value: 'durian',
                                              url: 'durian',
                                            },
                                            {
                                              text: 'EGGFRUIT',
                                              value: 'eggfruit',
                                              url: 'eggfruit',
                                            },
                                            {
                                              text: 'FIG',
                                              value: 'fig',
                                              url: 'fig',
                                            },
                                            {
                                              text: 'GRAPEFRUIT',
                                              value: 'grapefruit',
                                              url: 'grapefruit',
                                            },
                                            {
                                              text: 'HONEYDEW',
                                              value: 'honeydew',
                                              url: 'honeydew',
                                            },
                                          ],
                                        }}
                                      />
                                      <OpacityBtn
                                        disabled={false}
                                        backgroundColor={c_eba55d}
                                        fontColor={c_ffffff}
                                        onClick={() => {}}
                                      >
                                        &#8361;
                                        {priceFormatFunction(
                                          userProduct.price
                                        )}{' '}
                                        checkout
                                      </OpacityBtn>
                                    </MobileInfoInnerContainerViewRowRightBodyTileContent>
                                  </div>
                                )}
                              </MobileInfoInnerContainerViewRowRightBodyTile>
                            )
                          })}
                        </MobileInfoViewMainBodyInnerInner>
                      </MobileInfoViewMainBodyInner>
                    </MobileInfoViewMainBody>
                  </MobileInfoViewBody>
                </MobileInfoView>
              )}
              {tabIndex === 3 && (
                <MapInnerContainerView>
                  {mapError && (
                    <MapInnerContainerViewError>
                      <MapInnerContainerViewErrorH2>
                        The address needs to be updated.
                      </MapInnerContainerViewErrorH2>
                    </MapInnerContainerViewError>
                  )}
                  {!mapError && isLoaded && (
                    <GoogleMap
                      mapContainerStyle={{ width: '100%', height: '100%' }}
                      center={{
                        lat: center.latitude,
                        lng: center.longitude,
                      }}
                      zoom={zoom}
                      onLoad={onLoad}
                      onUnmount={onUnmount}
                      options={{
                        styles: [
                          {
                            featureType: 'poi',
                            elementType: 'labels',
                            stylers: [{ visibility: 'off' }],
                          },
                        ],
                      }}
                    >
                      {defaultCategories.map((defaultCategory) =>
                        defaultCategory.defaultSubcategories.map(
                          (defaultSubcategory) =>
                            defaultSubcategory.defaultTopics.map(
                              (defaultTopic) => {
                                console.log(
                                  'topic:',
                                  defaultTopic.latitude,
                                  defaultTopic.longitude,
                                  defaultTopic.mapPinIconUrl
                                )
                                if (
                                  defaultTopic.latitude &&
                                  defaultTopic.longitude &&
                                  defaultTopic.mapPinIconUrl
                                ) {
                                  return (
                                    <MarkerF
                                      key={defaultTopic.id}
                                      position={{
                                        lat: defaultTopic.latitude,
                                        lng: defaultTopic.longitude,
                                      }}
                                      draggable={false}
                                      icon={{
                                        url: defaultTopic.mapPinIconUrl,
                                        scaledSize: new window.google.maps.Size(
                                          40,
                                          40
                                        ),
                                      }}
                                      onClick={() => {
                                        setCenter({
                                          latitude: defaultTopic.latitude,
                                          longitude: defaultTopic.longitude,
                                        })
                                        setSelectDefaultCategory(
                                          defaultCategory
                                        )
                                        setSelectDefaultCategoryId(
                                          defaultCategory.id
                                        )
                                        setSelectDefaultSubcategoryId(
                                          defaultSubcategory.id
                                        )
                                        handleMarkerClick(defaultTopic)
                                      }}
                                    />
                                  )
                                }
                              }
                            )
                        )
                      )}
                      {console.log('position:', position)}
                      {position.latitude && position.longitude && (
                        <MarkerF
                          position={{
                            lat: position.latitude,
                            lng: position.longitude,
                          }}
                          draggable={false}
                          icon={{
                            url: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
                            scaledSize: new window.google.maps.Size(40, 40),
                          }}
                        />
                      )}
                      {selectedMarker && (
                        <InfoWindow
                          position={{
                            lat: selectedMarker.latitude,
                            lng: selectedMarker.longitude,
                          }}
                          onCloseClick={() => {
                            setSelectDefaultCategory(null)
                            setSelectDefaultCategoryId(null)
                            setSelectDefaultSubcategoryId(null)
                            setSelectedMarker(null)
                          }}
                          options={{
                            pixelOffset: new window.google.maps.Size(0, -30),
                          }}
                        >
                          <div
                            style={{
                              minWidth: '100px',
                              minHeight: '50px',
                              overflow: 'hidden',
                              borderRadius: '8px',
                              backgroundColor: 'white',
                              padding: '5px',
                              boxSizing: 'border-box',
                              wordWrap: 'break-word',
                              overflowWrap: 'break-word',
                            }}
                          >
                            <h3>{selectedMarker.title}</h3>
                            <div
                              style={{
                                maxHeight: '150px',
                                overflowY: 'auto',
                                marginTop: '10px',
                              }}
                            >
                              <Editor
                                editorState={EditorState.createWithContent(
                                  convertFromRaw(
                                    JSON.parse(selectedMarker.content)
                                  )
                                )}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                toolbarHidden={true}
                                readOnly={true}
                                mention={{
                                  separator: ' ',
                                  trigger: '@',
                                  suggestions: [
                                    {
                                      text: 'APPLE',
                                      value: 'apple',
                                    },
                                    {
                                      text: 'BANANA',
                                      value: 'banana',
                                      url: 'banana',
                                    },
                                    {
                                      text: 'CHERRY',
                                      value: 'cherry',
                                      url: 'cherry',
                                    },
                                    {
                                      text: 'DURIAN',
                                      value: 'durian',
                                      url: 'durian',
                                    },
                                    {
                                      text: 'EGGFRUIT',
                                      value: 'eggfruit',
                                      url: 'eggfruit',
                                    },
                                    {
                                      text: 'FIG',
                                      value: 'fig',
                                      url: 'fig',
                                    },
                                    {
                                      text: 'GRAPEFRUIT',
                                      value: 'grapefruit',
                                      url: 'grapefruit',
                                    },
                                    {
                                      text: 'HONEYDEW',
                                      value: 'honeydew',
                                      url: 'honeydew',
                                    },
                                  ],
                                }}
                              />
                            </div>
                            <div
                              onClick={() => {
                                setSelectDefaultCategoryId(
                                  selectDefaultCategoryId
                                )
                                setSelectDefaultSubcategoryId(
                                  selectDefaultSubcategoryId
                                )
                                setShowMobileInfoDetail(true)
                                toggleDefaultTopicVisibilityOnlyOpen(
                                  selectDefaultCategoryId,
                                  selectDefaultSubcategoryId,
                                  selectedMarker.id
                                )
                                setTabIndex(1)
                                setSelectedMarker(null)
                              }}
                              style={{
                                color: 'blue',
                                marginTop: '10px',
                              }}
                            >
                              More info
                            </div>
                            <div
                              style={{
                                display: 'flex',
                              }}
                            >
                              <div
                                onClick={() => {
                                  console.log(selectedMarker)
                                  const url = `https://www.google.com/maps/dir/?api=1&destination=${selectedMarker.latitude},${selectedMarker.longitude}`
                                  window.open(url, '_blank')
                                }}
                                style={{
                                  color: 'blue',
                                  marginTop: '20px',
                                }}
                              >
                                Google
                              </div>
                              <div
                                style={{
                                  marginTop: '20px',
                                  marginLeft: '10px',
                                  marginRight: '10px',
                                }}
                              >
                                |
                              </div>
                              <div
                                onClick={() => {
                                  console.log(selectedMarker)
                                  // const url = `https://map.naver.com/v5/?c=${selectedMarker.longitude},${selectedMarker.latitude},18,0,0,0,dh`
                                  const url = `https://map.naver.com/p/search/${selectedMarker.address}?c=15.00,0,0,0,dh`
                                  window.open(url, '_blank')
                                }}
                                style={{
                                  color: 'blue',
                                  marginTop: '20px',
                                }}
                              >
                                Naver
                              </div>
                            </div>
                          </div>
                        </InfoWindow>
                      )}
                    </GoogleMap>
                  )}
                </MapInnerContainerView>
              )}
              {tabIndex === 4 && (
                <SearchInnerContainerView>
                  <SearchInnerContainerViewInner
                    style={{
                      top: searchText.length === 0 ? '50%' : '75px',
                    }}
                  >
                    <SearchInnerContainerViewInnerDiv>
                      <SearchInnerContainerViewInnerDivInputWrapper>
                        <SearchInnerContainerViewInnerDivInputWrapperSpan>
                          <SearchIconFixed></SearchIconFixed>
                        </SearchInnerContainerViewInnerDivInputWrapperSpan>
                        <SearchInnerContainerViewInnerDivInputWrapperInput
                          autoComplete="off"
                          placeholder="Search for keywords"
                          value={searchText}
                          onChange={(e) => {
                            setSearchText(e.target.value)
                            let {
                              tempDefaultSubcategoriesFoundByTitle,
                              tempDefaultSubcategoriesFoundByContent,
                            } = searchDefaultSubcategories(e.target.value)
                            setDefaultSubcategoriesFoundByTitle(
                              tempDefaultSubcategoriesFoundByTitle
                            )
                            setDefaultSubcategoriesFoundByContent(
                              tempDefaultSubcategoriesFoundByContent
                            )
                          }}
                        ></SearchInnerContainerViewInnerDivInputWrapperInput>
                      </SearchInnerContainerViewInnerDivInputWrapper>
                    </SearchInnerContainerViewInnerDiv>
                    {searchText.length === 0 && (
                      <SearchInnerContainerViewInnerP>
                        Search results will appear featuring matches for all
                        your keywords.
                      </SearchInnerContainerViewInnerP>
                    )}
                    {searchText.length !== 0 &&
                      defaultSubcategoriesFoundByTitle.length === 0 &&
                      defaultSubcategoriesFoundByContent.length === 0 && (
                        <SearchInnerContainerViewInnerP>
                          No results found. Please try again.
                        </SearchInnerContainerViewInnerP>
                      )}
                    <SearchTileFrame>
                      {searchText.length !== 0 &&
                        defaultSubcategoriesFoundByTitle.length !== 0 && (
                          <>
                            <h4
                              style={{
                                fontWeight: 700,
                                fontSize: '1.125rem',
                                opacity: 0.8,
                                margin: '15px 0',
                              }}
                            >
                              Matches in a section heading
                            </h4>
                            <SearchTileWrapper>
                              {defaultSubcategoriesFoundByTitle.map(
                                (defaultSubcategory) => {
                                  return (
                                    <SearchTile
                                      key={
                                        defaultSubcategory.defaultSubcategoryId
                                      }
                                      onClick={() => {
                                        setSelectDefaultCategoryId(
                                          defaultSubcategory.defaultCategoryId
                                        )
                                        setSelectDefaultSubcategoryId(
                                          defaultSubcategory.defaultSubcategoryId
                                        )
                                        setShowMobileInfoDetail(true)
                                        setTabIndex(1)
                                      }}
                                    >
                                      {defaultSubcategory.title}
                                    </SearchTile>
                                  )
                                }
                              )}
                            </SearchTileWrapper>
                          </>
                        )}
                      {searchText.length !== 0 &&
                        defaultSubcategoriesFoundByContent.length !== 0 && (
                          <>
                            <h4
                              style={{
                                fontWeight: 700,
                                fontSize: '1.125rem',
                                opacity: 0.8,
                                margin: '15px 0',
                              }}
                            >
                              Matches in a section description
                            </h4>
                            <SearchTileWrapper>
                              {defaultSubcategoriesFoundByContent.map(
                                (defaultSubcategory) => {
                                  return (
                                    <SearchTile
                                      key={
                                        defaultSubcategory.defaultSubcategoryId
                                      }
                                      onClick={() => {
                                        setSelectDefaultCategoryId(
                                          defaultSubcategory.defaultCategoryId
                                        )
                                        setSelectDefaultSubcategoryId(
                                          defaultSubcategory.defaultSubcategoryId
                                        )
                                        setShowMobileInfoDetail(true)
                                        setTabIndex(1)
                                      }}
                                    >
                                      {defaultSubcategory.title}
                                    </SearchTile>
                                  )
                                }
                              )}
                            </SearchTileWrapper>
                          </>
                        )}
                    </SearchTileFrame>
                  </SearchInnerContainerViewInner>
                </SearchInnerContainerView>
              )}
              <Footer>
                <FooterNav>
                  {tabList.map((tab) => {
                    return (
                      <FooterNavTab
                        key={tab.id}
                        id={tab.id}
                        onClick={() => {
                          if (tab.id !== 2) {
                            setPosition({
                              latitude:
                                defaultGuide.latitude === null
                                  ? 37.5665
                                  : defaultGuide.latitude,
                              longitude:
                                defaultGuide.longitude === null
                                  ? 126.978
                                  : defaultGuide.longitude,
                            })
                            setCenter({
                              latitude:
                                defaultGuide.latitude === null
                                  ? 37.5665
                                  : defaultGuide.latitude,
                              longitude:
                                defaultGuide.longitude === null
                                  ? 126.978
                                  : defaultGuide.longitude,
                            })
                            setMapError(
                              defaultGuide.latitude === null ||
                                defaultGuide.longitude === null
                                ? true
                                : false
                            )
                          }
                          setShowMobileDefaultSubcategoriesModal(false)
                          setTabIndex(tab.id)
                        }}
                        $tabIndex={tabIndex}
                        $index={tab.id}
                      >
                        {tab.title}
                      </FooterNavTab>
                    )
                  })}
                </FooterNav>
              </Footer>
            </InnerContainer>
          </TotalContainer>
        ))}
      {showMobileDefaultSubcategoriesModal && (
        <ShowMobileSubcategoriesModalContainer
          onClick={() => {
            setShowMobileDefaultSubcategoriesModal(false)
          }}
        >
          <ShowMobileSubcategoriesModal
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <ShowMobileSubcategoriesModalCircleIconPart>
              <ShowMobileSubcategoriesModalCircleIconPartIcon
                src={selectDefaultCategory.iconUrl}
                alt=""
              ></ShowMobileSubcategoriesModalCircleIconPartIcon>
            </ShowMobileSubcategoriesModalCircleIconPart>
            <ShowMobileSubcategoriesModalXIcon
              onClick={() => {
                setShowMobileDefaultSubcategoriesModal(false)
              }}
            >
              <XIconFixed></XIconFixed>
            </ShowMobileSubcategoriesModalXIcon>
            <ShowMobileSubcategoriesModalTitle>
              {defaultCategories.map((defaultCategory) => {
                if (defaultCategory.id === selectDefaultCategoryId) {
                  return defaultCategory.title
                }
              })}
            </ShowMobileSubcategoriesModalTitle>
            <ShowMobileSubcategoriesModalBody>
              {defaultCategories.map((defaultCategory) => {
                if (defaultCategory.id === selectDefaultCategoryId) {
                  return defaultCategory.defaultSubcategories.map(
                    (defaultSubcategory) => {
                      return (
                        <ShowMobileSubcategoriesModalBodyTile
                          key={defaultSubcategory.id}
                          onClick={() => {
                            setSelectDefaultSubcategoryId(defaultSubcategory.id)
                            setShowMobileDefaultSubcategoriesModal(false)
                            setShowMobileInfoDetail(true)
                          }}
                        >
                          {defaultSubcategory.title}
                          <ArrowRightIconFixed></ArrowRightIconFixed>
                        </ShowMobileSubcategoriesModalBodyTile>
                      )
                    }
                  )
                }
              })}
            </ShowMobileSubcategoriesModalBody>
          </ShowMobileSubcategoriesModal>
        </ShowMobileSubcategoriesModalContainer>
      )}
      {showMoveTo1330Modal && (
        <StandardModal
          titleText={'Korea Travel Helpline'}
          modalBodyComponent={
            <div>
              <img
                src={image_1330}
                alt=""
                style={{
                  width: '100%',
                  height: 'auto',
                }}
              ></img>
              <div
                style={{
                  textAlign: 'center',
                }}
              >
                1330 Korea Travel Helpline is a real-time chat service.<br></br>
                <br></br>
                <br></br>English, Korean, Japanese, Chinese<br></br>24 hours a
                day, all year round<br></br>
                <br></br>We provide tourists from within and outside Korea with
                information about traveling in Korea.<br></br>
                <br></br>CLICK TO START LIVE CHAT
              </div>
            </div>
          }
          onRightBtnClick={() => {
            window.open(
              'https://1330chat.visitkorea.or.kr:3000/#/ttalk_main/CHAT1330_160635739001093018/_0300_0100_main.do',
              '_blank'
            )
          }}
          onClose={() => {
            setShowMoveTo1330Modal(false)
          }}
          leftBtnText={'CANCEL'}
          rightBtnText={'MOVE TO 1330'}
        ></StandardModal>
      )}
    </>
  )
}

export default ShowDefaultGuidePage

const ShowMobileInfoDetailHeader = styled.header`
  background-color: #b49275;
  width: 100%;
  color: #fff;
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  box-sizing: border-box;
`

const ShowMobileInfoDetailHeaderInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
`

const ShowMobileInfoDetailHeaderInnerLeft = styled.div`
  max-width: 75%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
`

const ShowMobileInfoDetailHeaderInnerIconStyle = styled.div`
  font-size: 25px;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  margin-left: 18;
`

const ShowMobileInfoDetailHeaderInnerP = styled.p`
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  padding-left: 15px;
  text-overflow: ellipsis;
  font-size: 1.25rem;
  font-weight: 700;
  overflow: hidden;
  white-space: nowrap;
  line-height: 1.375;
`

const ShowMobileInfoDetailHeaderInnerRight = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
`

const ShowMobileSubcategoriesModalContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.5);
`

const ShowMobileSubcategoriesModal = styled.div`
  position: fixed;
  background-color: #fff;
  min-height: 100px;
  width: 95%;
  border-radius: 4px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99999999;
  max-width: 500px;
  padding-top: 45px;
  box-sizing: border-box;
`

const ShowMobileSubcategoriesModalCircleIconPart = styled.div`
  position: absolute;
  left: 50%;
  top: 0;
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 10px #0000001a;
  box-sizing: border-box;
`

const ShowMobileSubcategoriesModalCircleIconPartIcon = styled.img`
  color: #eba55d;
  font-size: 3.125rem;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
`

const ShowMobileSubcategoriesModalXIcon = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 1.5625rem;
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  box-sizing: border-box;
`

const ShowMobileSubcategoriesModalTitle = styled.div`
  font-size: 1.25rem;
  margin-top: 20px;
  text-align: center;
  box-sizing: border-box;
`

const ShowMobileSubcategoriesModalBody = styled.div`
  padding: 20px;
  max-height: 300px;
  overflow: auto;
  box-sizing: border-box;
`

const ShowMobileSubcategoriesModalBodyTile = styled.div`
  text-align: left;
  font-size: 0.9375rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px 0;
  box-sizing: border-box;
  cursor: pointer;
`

const MobileInfoView = styled.div`
  height: 100%;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  box-sizing: border-box;

  @media (max-width: 1200px) {
    padding: 0;
  }
`

const MobileInfoViewHeader = styled.header`
  background-color: #b49275;
  width: 100%;
  color: #fff;
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  box-sizing: border-box;
`

const MobileInfoViewHeaderInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
`

const MobileInfoViewHeaderInnerLeft = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
`

const MobileInfoViewHeaderInnerRight = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
`

const MobileInfoViewBody = styled.div`
  position: relative;
  background-color: #f3f3f3;
  box-sizing: border-box;

  @media (max-width: 1200px) {
    display: block;
    height: calc(100% - 70px);
    padding: 8px 8px 0;
  }
`

const MobileInfoViewMainBody = styled.div`
  overflow: auto;
  position: relative;
  box-sizing: border-box;

  @media (max-width: 1200px) {
    box-shadow: 0 0 10px #0000001a;
    width: 100%;
    height: 100%;
    max-width: 100%;
    padding: 0;
  }
`

const MobileInfoViewMainBodyInner = styled.div`
  position: relative;
  height: 100%;
  border-radius: 4px;
  box-shadow: 0 0 10px #0000001a;
  box-sizing: border-box;
  display: block;
`

const MobileInfoViewMainBodyInnerInner = styled.aside`
  height: 100%;
  background-color: #f9f9f9;
  overflow: auto;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
`

const MobileInfoViewMainBodyInnerInnerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-radius: 4px;
  box-sizing: border-box;
`

const MobileInfoViewMainBodyInnerInnerWrapperCard = styled.div`
  width: 50%;
  height: 33%;
  max-height: 140px;
  min-height: 140px;
  padding: 20px;
  z-index: 0;
  position: relative;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  // 오른쪽 테두리 생성
  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 5%; // 위쪽에서 10%의 여백을 둠 (테두리 길이를 80%로 조절)
    height: 90%; // 테두리 길이를 카드 높이의 80%로 설정
    border-right: 1px solid #0000001a;
  }

  // 아래쪽 테두리 생성
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 5%; // 왼쪽에서 10%의 여백을 둠 (테두리 길이를 80%로 조절)
    width: 90%; // 테두리 길이를 카드 너비의 80%로 설정
    border-bottom: 1px solid #0000001a;
  }

  // 각 줄의 마지막 카드에서 오른쪽 테두리 제거
  &:nth-child(2n)::after {
    border-right: none;
  }

  /* 전체 항목 수가 짝수일 때 마지막 두 항목에서 아래쪽 테두리 제거 */
  ${({ $categoriesLength }) =>
    $categoriesLength % 2 === 0 &&
    `
    &:nth-last-child(-n+2):before {
      border-bottom: none;
    }
  `}

  /* 전체 항목 수가 홀수일 때 마지막 항목에서 아래쪽 테두리 제거 */
  ${({ $categoriesLength }) =>
    $categoriesLength % 2 !== 0 &&
    `
    &:nth-last-child(1):before {
      border-bottom: none;
    }
  `}
`

const MobileInfoViewMainBodyInnerInnerWrapperCardThumbnail = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  min-height: 100%;
  box-sizing: border-box;
`

const MobileInfoViewMainBodyInnerInnerWrapperCardThumbnailIconStyle = styled.div`
  color: #eba55d;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  @media (max-width: 1200px) {
    margin-bottom: 15px !important;
    font-size: 35px !important;
  }
`

const MobileInfoViewMainBodyInnerInnerWrapperCardThumbnailDiv = styled.div`
  user-select: none;
  max-width: 100%;
  line-height: 1.3;
  overflow: hidden;
  box-sizing: border-box;
`

const TotalContainer = styled.div`
  font-family: 'Circular Book', Arial, 'Helvetica Neue', Helvetica, sans-serif;
  height: calc(var(--vh, 1vh) * 100) !important;
  position: relative;
  box-sizing: border-box;
`

const InnerContainer = styled.div`
  position: relative;
  width: 100vw;
  height: calc(100% - 70px);
  background-color: #f3f3f3;
  box-sizing: border-box;
`

const HomeInnerContainerView = styled.div`
  width: 100vw;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  touch-action: auto !important;
  user-select: auto !important;
  box-sizing: border-box;
`

const InnerContainerViewOverlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: calc(100% - 70px);
  z-index: 50;
  background-color: var(--20cedac2);
  opacity: calc(var(--4a1e31ce) / 100);
  box-sizing: border-box;
`

const InnerContainerViewCenterDiv = styled.div`
  color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  flex-direction: column;
  animation: blurred 1s cubic-bezier(0.23, 1, 0.32, 1) both;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 99;
  filter: blur(0);
  font-size: 1.25rem;
  text-align: center;
  font-family: var(--51fc11a8);
  box-sizing: border-box;
`

const InnerContainerViewCenterDivImg = styled.img`
  border-radius: 0;
  border: none;
  object-fit: contain;
  width: 110px;
  height: 110px;
  margin-bottom: 1.5rem;
  color: inherit;
  box-sizing: border-box;
  overflow-clip-margin: content-box;
  overflow: clip;
`

const InnerContainerViewCenterDivP = styled.p`
  font-size: 1.5rem;
  white-space: nowrap;
  line-height: 1.5;
  margin: 0 0 1.5rem;
  box-sizing: border-box;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
`

const InnerContainerViewCenterDivContactDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 700;
  font-size: 1.0625rem;
  box-sizing: border-box;
`

const InnerContainerViewCenterDivContactDivSpan = styled.span`
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  font-weight: 400;
  margin-left: 1rem;
  box-sizing: border-box;
`

const InnerContainerViewCenterDivDivideSpan = styled.span`
  background-color: #eba55d;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  width: 2.5rem;
  height: 2px;
  margin: 1rem 0;
  display: inline-block;
  box-sizing: border-box;
`

const InnerContainerViewCenterDivSpan = styled.span`
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
`

const InnerContainerViewBackgroundImg = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  box-sizing: border-box;
  overflow-clip-margin: content-box;
  overflow: clip;
`

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3); // 반투명 검은색 오버레이
  z-index: 2;
`

const Footer = styled.div`
  bottom: 0;
  position: fixed;
  width: 100%;
  height: 70px;
  overflow: hidden;
  z-index: 500;
  box-shadow: 0 -10px 51px #00000040;
  transition: all 0.3s ease-in-out;
  box-sizing: border-box;
`

const FooterNav = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 70px;
  box-sizing: border-box;
`

const FooterNavTab = styled.div`
  background-color: ${(props) =>
    props.$tabIndex === props.$index ? '#eba55d' : '#b49275'};
  flex: 1 1 0;
  height: 100%;
  transition: all 0.3s ease-in-out;
  font-weight: 700;
  letter-spacing: 0.125rem;
  display: flex;
  font-size: 12px;
  text-transform: uppercase;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #fff;
  box-sizing: border-box;
`

const InfoInnerContainerView = styled.div`
  height: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  box-sizing: border-box;

  @media (min-width: 1200px) {
    width: 1170px;
  }

  @media (max-width: 1200px) {
    padding: 0;
  }
`

const InfoInnerContainerViewRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  background-color: #f3f3f3;
  box-sizing: border-box;

  @media (min-width: 1200px) {
    height: 100%;
  }

  @media (max-width: 1200px) {
    display: block;
    height: calc(100% - 70px);
    padding: 8px 8px 0;
  }
`

const InfoInnerContainerViewRowLeft = styled.div`
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
  overflow: auto;
  position: relative;
  padding: 15px 15px 0;
  height: 100%;
  box-sizing: border-box;

  @media (max-width: 1200px) {
    width: 100%;
    height: 100%;
    max-width: 100%;
    padding: 0;
    box-shadow: 0 0 10px #0000001a;
    display: none;
  }
`

const InfoInnerContainerViewRowLeftInner = styled.div`
  position: relative;
  height: 100%;
  border-radius: 4px;
  box-shadow: 0 0 10px #0000001a;
  box-sizing: border-box;

  @media (min-width: 1200px) {
    box-shadow: 0 0 10px #0000001a;
    min-height: unset;
    height: 100%;
    border-radius: 0;
  }
`

const InfoInnerContainerViewRowLeftInnerHeader = styled.div`
  background-color: #b49275;
  width: 100%;
  color: #fff;
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  box-sizing: border-box;

  @media (min-width: 1200px) {
    border-radius: 4px 4px 0 0;
  }
`

const InfoInnerContainerViewRowLeftInnerHeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
`

const InfoInnerContainerViewRowLeftInnerHeaderRowLeft = styled.div`
  max-width: 75%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
`

const InfoInnerContainerViewRowLeftInnerHeaderRowLeftImg = styled.img`
  max-width: 50%;
  min-width: 45px;
  height: 45px;
  object-fit: contain;
  box-sizing: border-box;
  overflow-clip-margin: content-box;
  overflow: clip;
`

const InfoInnerContainerViewRowLeftInnerHeaderRowIconStyle = styled.div`
  margin-left: 18px;
  font-size: 25px;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
`

const InfoInnerContainerViewRowLeftInnerHeaderRowRight = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
`

const InfoInnerContainerViewRowLeftInnerBody = styled.div`
  height: 100%;
  background-color: #f9f9f9;
  overflow: auto;
  border-radius: 4px;
  box-sizing: border-box;

  @media (min-width: 1200px) {
    height: calc(100% - 70px);
  }
`

const InfoInnerContainerViewRowLeftInnerBodyDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-radius: 4px;
  box-sizing: border-box;

  @media (min-width: 1200px) {
    display: block;
    padding: 14px;
  }
`

const InfoInnerContainerViewRowLeftInnerBodyDivTile = styled.div`
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  width: 100%;
  background-color: #fff;
  max-width: 100%;
  height: unset;
  max-height: unset;
  min-height: unset;
  border-radius: 4px;
  padding: 14px;
  box-shadow: 0 0 5px 1px #0000001a;
  font-size: 1rem;
`

const InfoInnerContainerViewRowRight = styled.div`
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
  position: relative;
  padding: 15px 15px 0;
  height: 100%;
  box-sizing: border-box;

  @media (max-width: 1200px) {
    width: 100%;
    height: 100%;
    max-width: 100%;
    padding: 0;
  }
`

const InfoInnerContainerViewRowRightHeader = styled.div`
  background-color: #b49275;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  color: #fff;
  padding: 0 21px 0 14px;
  border-radius: 4px 4px 0 0;
  box-shadow: 0 10px 51px #00000026;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  box-sizing: border-box;

  @media (max-width: 1200px) {
    display: none;
  }
`

const InfoInnerContainerViewRowRightBody = styled.div`
  box-sizing: border-box;
  padding: 14px 14px 0;
  position: relative;
  background-color: #f9f9f9;
  height: calc(100% - 70px);
  overflow: auto;
  box-shadow: 0 0 10px #0000001a;

  @media (max-width: 1200px) {
    height: 100%;
    overflow: auto;
    scroll-behavior: smooth;
  }
`

const MobileInfoInnerContainerViewRowRightBodyTile = styled.div`
  box-shadow: 0 0 5px 1px #0000001a;
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 1rem;
  margin-bottom: 14px;
  box-sizing: border-box;
  max-width: 100%;
  cursor: pointer;

  @media (min-width: 1200px) {
    padding: 14px;
  }
`

const MobileInfoInnerContainerViewRowRightBodyTileContent = styled.div`
  font-size: 1rem;
  box-sizing: border-box;
  padding: 8px 16px;

  @media (min-width: 1200px) {
    padding: 8px 16px 8px 24px;
  }
`

const InfoInnerContainerViewRowRightBodyTile = styled.div`
  box-shadow: 0 0 5px 1px #0000001a;
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 1rem;
  margin-bottom: 14px;
  box-sizing: border-box;
  max-width: 100%;
  cursor: pointer;

  @media (min-width: 1200px) {
    padding: 14px;
  }
`

const InfoInnerContainerViewRowRightBodyTileContent = styled.div`
  font-size: 1rem;
  box-sizing: border-box;
  padding: 8px 16px;

  @media (min-width: 1200px) {
    padding: 8px 16px 8px 24px;
  }
`

const MapInnerContainerView = styled.div`
  height: 100%;
  box-sizing: border-box;
`

const MapInnerContainerViewError = styled.div`
  width: 100vw;
  height: 100%;
  display: flex;
  position: absolute;
  z-index: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 0 10%;
  box-sizing: border-box;
`

const MapInnerContainerViewErrorH2 = styled.div`
  line-height: 1.222;
  font-size: 1.5rem;
  font-weight: 400;
  color: #16293c;
  box-sizing: border-box;
`

const SearchInnerContainerView = styled.div`
  height: 100%;
  overflow: auto;
  background-color: #f8f8f8;
  padding: 15px;
  font-size: 1rem;
  position: relative;
  box-sizing: border-box;

  @media (min-width: 1200px) {
    width: 50%;
    margin: 0 auto;
  }
`

const SearchInnerContainerViewInner = styled.div`
  width: calc(100% - 30px);
  left: 0;
  position: absolute;
  margin: 0 15px;
  transform: translateY(-50%);
  transition: top 0.5s ease-in-out;
  box-sizing: border-box;
`

const SearchInnerContainerViewInnerDiv = styled.div`
  font-family: 'Circular Book', Arial, 'Helvetica Neue', Helvetica, sans-serif;
  font-weight: 700;
  position: relative;
  font-size: 14px;
  display: inline-flex;
  width: 100%;
  line-height: 32px;
  box-sizing: border-box;
  vertical-align: middle;
`

const SearchInnerContainerViewInnerP = styled.p`
  text-align: justify;
  font-size: 1.125rem;
  line-height: 1.5;
  padding: 0 10px;

  @media (min-width: 1200px) {
    font-size: 1.25rem !important;
  }
`

const SearchInnerContainerViewInnerDivInputWrapper = styled.div`
  display: inline-flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: 1px 11px;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  transition: box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transform: translateZ(0);
  box-shadow: 0 0 0 1px #dcdfe6 inset;
  box-sizing: border-box;
`

const SearchInnerContainerViewInnerDivInputWrapperSpan = styled.span`
  display: flex;
  align-items: center;
  white-space: nowrap;
  flex-shrink: 0;
  flex-wrap: nowrap;
  height: 100%;
  text-align: center;
  color: #a8abb2;
  transition: all 0.3s;
  pointer-events: none;
  margin-right: 11px;
`

const SearchInnerContainerViewInnerDivInputWrapperInput = styled.input`
  border: 1px solid transparent;
  color: #303133;
  height: 60px;
  font-size: 1.25rem;
  font-family: inherit;
  box-shadow: none;
  width: 100%;
  flex-grow: 1;
  -webkit-appearance: none;
  line-height: calc(32px - 2px);
  padding: 0;
  outline: 0;
  background: 0 0;
  box-sizing: border-box;
`

const SearchTileFrame = styled.div`
  transform: translateY(0);
  position: absolute;
  left: 0;
  width: 100%;
  top: 75px;
  padding: 0 15px;
  transition: all 0.5s ease-in-out;
  box-sizing: border-box;
`

const SearchTileWrapper = styled.ul`
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  @media (min-width: 1200px) {
    display: flex;
    flex-wrap: wrap;
  }
`

const SearchTile = styled.li`
  box-shadow: 0 0 5px 1px #0000001a;
  border-radius: 4px;
  background-color: #fff;
  padding: 15px;
  font-weight: 700;
  font-size: 1rem;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 15px;
  box-sizing: border-box;
  cursor: pointer;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 10px;
  margin-inline-end: 10px;
  padding-inline-start: 40px;

  @media (min-width: 1200px) {
    flex: 1 0 45%;
  }
`

const StoreInnerContainerView = styled.div`
  height: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  box-sizing: border-box;

  @media (min-width: 1200px) {
    width: 1170px;
  }

  @media (max-width: 1200px) {
    padding: 0;
  }
`

const StoreInnerContainerViewRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  background-color: #f3f3f3;
  box-sizing: border-box;
  justify-content: center;

  @media (min-width: 1200px) {
    height: 100%;
  }

  @media (max-width: 1200px) {
    display: block;
    height: calc(100% - 70px);
    padding: 8px 8px 0;
  }
`

const StoreInnerContainerViewRowRight = styled.div`
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
  position: relative;
  padding: 15px 15px 0;
  height: 100%;
  box-sizing: border-box;

  @media (max-width: 1200px) {
    width: 100%;
    height: 100%;
    max-width: 100%;
    padding: 0;
  }
`

const StoreInnerContainerViewRowRightHeader = styled.div`
  background-color: #b49275;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  color: #fff;
  padding: 0 21px 0 14px;
  border-radius: 4px 4px 0 0;
  box-shadow: 0 10px 51px #00000026;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  box-sizing: border-box;

  @media (max-width: 1200px) {
    display: none;
  }
`

const StoreInnerContainerViewRowRightBody = styled.div`
  box-sizing: border-box;
  padding: 14px 14px 0;
  position: relative;
  background-color: #f9f9f9;
  height: calc(100% - 70px);
  overflow: auto;
  box-shadow: 0 0 10px #0000001a;

  @media (max-width: 1200px) {
    height: 100%;
    overflow: auto;
    scroll-behavior: smooth;
  }
`

const StoreInnerContainerViewRowRightBodyTile = styled.div`
  box-shadow: 0 0 5px 1px #0000001a;
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 1rem;
  margin-bottom: 14px;
  box-sizing: border-box;
  max-width: 100%;
  cursor: pointer;

  @media (min-width: 1200px) {
    padding: 14px;
  }
`

const StoreInnerContainerViewRowRightBodyTileContent = styled.div`
  font-size: 1rem;
  box-sizing: border-box;
  padding: 8px 16px;

  @media (min-width: 1200px) {
    padding: 8px 16px 8px 24px;
  }
`
