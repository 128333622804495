import { AuthProvider } from './data_system/context/auth_context/AuthContext'
import { LoadingProvider } from './data_system/context/loading_context/LoadingContext'
import { PopupProvider } from './data_system/context/popup_context/PopupContext'
import { SelectDefaultGuideHeaderProvider } from './data_system/context/select_default_guide_header_index_context/SelectDefaultGuideHeaderIndexContext'
import { SelectGuideDetailHeaderProvider } from './data_system/context/select_guide_detail_header_index_context/SelectGuideDetailHeaderIndexContext'
import { SelectHeaderProvider } from './data_system/context/select_header_index_context/SelectHeaderIndexContext'
import { SelectHelpAndSupportHeaderProvider } from './data_system/context/select_help_and_support_header_index_context/SelectHelpAndSupportHeaderIndexContext'
import { SelectSettingsSubHeaderProvider } from './data_system/context/select_settings_sub_header_index_context/SelectSettingsSubHeaderIndexContext'
import { SubscriptionProvider } from './data_system/context/subscription_context/SubscriptionContext'
import Popup from '../src/design_system/components/popup/Popup'
import TotalRouter from './router/TotalRouter'
import { SelectStoreHeaderProvider } from './data_system/context/select_store_header_index_context/SelectStoreHeaderIndexContext'
import axios from 'axios'
import { GoogleMapsProvider } from './data_system/context/google_maps_context/GoogleMapsContext'
import { googleMapsApiKey } from './communication_system/keys/keys'
import { SelectPaymentHistoryHeaderProvider } from './data_system/context/select_payment_history_header_index_context/SelectPaymentHistoryHeaderIndexContext'

const App = () => {
  axios.defaults.withCredentials = true

  return (
    <GoogleMapsProvider googleMapsApiKey={googleMapsApiKey}>
      <AuthProvider>
        <SubscriptionProvider>
          <SelectHeaderProvider>
            <SelectSettingsSubHeaderProvider>
              <SelectHelpAndSupportHeaderProvider>
                <SelectGuideDetailHeaderProvider>
                  <SelectDefaultGuideHeaderProvider>
                    <SelectStoreHeaderProvider>
                      <SelectPaymentHistoryHeaderProvider>
                        <LoadingProvider>
                          <PopupProvider>
                            <TotalRouter></TotalRouter>
                            <Popup></Popup>
                          </PopupProvider>
                        </LoadingProvider>
                      </SelectPaymentHistoryHeaderProvider>
                    </SelectStoreHeaderProvider>
                  </SelectDefaultGuideHeaderProvider>
                </SelectGuideDetailHeaderProvider>
              </SelectHelpAndSupportHeaderProvider>
            </SelectSettingsSubHeaderProvider>
          </SelectHeaderProvider>
        </SubscriptionProvider>
      </AuthProvider>
    </GoogleMapsProvider>
  )
}

export default App
