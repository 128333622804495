import { useNavigate, useLocation } from 'react-router-dom'
import { useAuth } from '../../data_system/context/auth_context/AuthContext'
import { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import {
  login_inner_router_url,
  main_guides_create_guide_inner_router_url,
  main_guides_default_guide_inner_router_url,
  main_guides_guides_inner_router_url,
  main_guides_help_support_inner_router_url,
  main_guides_settings_inner_router_url,
  main_guides_store_inner_router_url,
} from '../../communication_system/inner_router_url/inner_router_url'
import styled from 'styled-components'
import { Footer } from '../../design_system/components/footer/Footer'
import { useSelectHeader } from '../../data_system/context/select_header_index_context/SelectHeaderIndexContext'
import {
  Logo2x,
  Logo2xVersion2,
  MenuIcon,
  NotificationIcon,
  SearchIcon,
  SettingsIcon,
  XIcon,
} from '../../design_system/icons/icons'
import { Section } from '../../design_system/components/section/Section'
import IconTooltip from '../../design_system/components/icon_tooltip/IconTooltip'
import { rgba_62_217_204_0d1 } from '../../design_system/colors'
import { useSelectSettingsSubHeader } from '../../data_system/context/select_settings_sub_header_index_context/SelectSettingsSubHeaderIndexContext'
import { useSubscription } from '../../data_system/context/subscription_context/SubscriptionContext'
import StandardModal from '../../design_system/components/standard_modal/StandardModal'

export function PrivateRoute() {
  const [showSearchBar, setShowSearchBar] = useState(false)
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const [settingVisible, setSettingVisible] = useState(false)
  const [showTrialExpiredModal, setShowTrialExpiredModal] = useState(false)
  const [showUpgradeModal, setShowUpgradeModal] = useState(false)

  const handleSettingClick = () => {
    setSettingVisible((prev) => !prev)
  }

  const handleMenuClick = () => {
    setIsSidebarOpen((prev) => !prev)
  }

  const { isLoggedIn, isNewUser, logout } = useAuth()
  const { isClickable, productId } = useSubscription()

  let navigate = useNavigate()
  let location = useLocation()

  const { selectHeaderIndex, setHeaderIndex } = useSelectHeader()
  const { setSettingsSubHeaderIndex } = useSelectSettingsSubHeader()

  const main_header_left_data = [
    {
      id: 0,
      title: '안내문',
      href: main_guides_guides_inner_router_url,
    },
    {
      id: 1,
      title: '기본 안내문',
      href: main_guides_default_guide_inner_router_url,
    },
    {
      id: 2,
      title: '스토어',
      href: main_guides_store_inner_router_url,
    },
  ]

  const main_header_right_data = {
    id: 3,
    title: '고객센터',
    href: main_guides_help_support_inner_router_url,
  }

  useEffect(() => {
    if (!isLoggedIn) {
      navigate(login_inner_router_url, { state: { from: location.pathname } })
    } else if (isNewUser) {
      navigate(main_guides_create_guide_inner_router_url)
    }
  }, [isLoggedIn, isNewUser])

  if (!isLoggedIn) {
    return null
  }

  return !isNewUser ? (
    <>
      <Header>
        <SectionHeader>
          <SectionLeftSide>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                paddingRight: 30,
              }}
              onClick={() => {
                console.log(productId)
                const baseUrl = window.location.origin
                const url = `${baseUrl}/main-guides/guides`
                window.open(url, '_self')
              }}
            >
              <Logo2xVersion2></Logo2xVersion2>
            </div>
            <LeftSideButtonSection>
              {main_header_left_data.map((header_data) => {
                return (
                  <ButtonA
                    key={header_data.id}
                    $id={header_data.id}
                    $selectHeaderIndex={selectHeaderIndex}
                    onClick={(e) => {
                      console.log(productId)
                      console.log(isClickable)

                      if (
                        !isClickable &&
                        (header_data.id === 0 ||
                          header_data.id === 1 ||
                          header_data.id === 2)
                      ) {
                        e.preventDefault()
                        setSettingsSubHeaderIndex(1)
                        setHeaderIndex(4)
                        navigate(main_guides_settings_inner_router_url)
                        setShowTrialExpiredModal(true)
                        return
                      }

                      if (productId === 2 && header_data.id === 2) {
                        e.preventDefault()
                        setSettingsSubHeaderIndex(1)
                        setHeaderIndex(4)
                        navigate(main_guides_settings_inner_router_url)
                        setShowUpgradeModal(true)
                        return
                      }

                      setHeaderIndex(header_data.id)
                    }}
                    href={header_data.href}
                    $isClickable={isClickable}
                  >
                    {header_data.title}
                  </ButtonA>
                )
              })}
            </LeftSideButtonSection>
          </SectionLeftSide>
          <SectionRightSide>
            <ButtonA
              $id={main_header_right_data.id}
              $selectHeaderIndex={selectHeaderIndex}
              onClick={() => {
                setHeaderIndex(main_header_right_data.id)
              }}
              href={main_header_right_data.href}
              $isClickable={isClickable}
            >
              고객센터
            </ButtonA>
            {/* {!showSearchBar ? (
              <div
                onClick={() => {
                  setShowSearchBar(true)
                }}
              >
                <div
                  style={{
                    margin: '0 18px',
                  }}
                >
                  <SearchIcon></SearchIcon>
                </div>
              </div>
            ) : (
              <SearchTotalFrame>
                <SearchFrame>
                  <SearchTextInput
                    type="text"
                    autoComplete="off"
                    placeholder="Search guide name"
                  ></SearchTextInput>
                  <SearchIconInFrame>
                    <SearchIcon></SearchIcon>
                  </SearchIconInFrame>
                  <XIconInFrame
                    onClick={() => {
                      setShowSearchBar(false)
                    }}
                  >
                    <XIcon></XIcon>
                  </XIconInFrame>
                </SearchFrame>
              </SearchTotalFrame>
            )} */}
            <DisplayMaxNone>
              <div
                style={{
                  margin: '0 18px',
                }}
                onClick={() => {
                  setHeaderIndex(4)
                  setSettingsSubHeaderIndex(2)
                  navigate(main_guides_settings_inner_router_url)
                }}
              >
                <NotificationIcon></NotificationIcon>
              </div>
            </DisplayMaxNone>
            <IconTooltip
              icon={
                <SettingIconDiv
                  $select={selectHeaderIndex === 4}
                  onClick={handleSettingClick}
                >
                  <SettingsIcon></SettingsIcon>
                </SettingIconDiv>
              }
              visible={settingVisible}
              handleClick={handleSettingClick}
              tooltipDefaultWidth={140}
              tooltipChangeWidth={140}
              tooltipTop={30}
              tooltipRight={0}
            >
              <SettingItem
                onClick={() => {
                  setHeaderIndex(4)
                  setSettingsSubHeaderIndex(0)
                  navigate(main_guides_settings_inner_router_url)
                }}
              >
                계정 정보
              </SettingItem>
              <SettingItem
                onClick={() => {
                  setHeaderIndex(4)
                  setSettingsSubHeaderIndex(1)
                  navigate(main_guides_settings_inner_router_url)
                }}
              >
                결제 정보
              </SettingItem>
              <SettingItem
                onClick={() => {
                  setHeaderIndex(4)
                  setSettingsSubHeaderIndex(2)
                  navigate(main_guides_settings_inner_router_url)
                }}
              >
                공지 및 알림
              </SettingItem>
              <SettingItem
                onClick={() => {
                  logout()
                }}
              >
                로그아웃
              </SettingItem>
            </IconTooltip>
            <DisplayMinNone>
              <div
                style={{
                  margin: '0 18px',
                }}
                onClick={handleMenuClick}
              >
                <MenuIcon></MenuIcon>
              </div>
            </DisplayMinNone>
          </SectionRightSide>
        </SectionHeader>
      </Header>
      <SidebarBackdrop open={isSidebarOpen} onClick={handleMenuClick} />
      <Sidebar open={isSidebarOpen} onClose={handleMenuClick}>
        <SidebarHeader>
          <div></div>
          <div
            onClick={() => {
              setHeaderIndex(4)
              setSettingsSubHeaderIndex(2)
              navigate(main_guides_settings_inner_router_url)
              setIsSidebarOpen(false)
            }}
          >
            <NotificationIcon></NotificationIcon>
          </div>
        </SidebarHeader>
        <SidebarNavBody>
          {main_header_left_data.map((header_data) => {
            return (
              <SidebarButtonA
                key={header_data.id}
                $id={header_data.id}
                $selectHeaderIndex={selectHeaderIndex}
                onClick={(e) => {
                  if (
                    !isClickable &&
                    (header_data.id === 0 ||
                      header_data.id === 1 ||
                      header_data.id === 2)
                  ) {
                    e.preventDefault()
                    setSettingsSubHeaderIndex(1)
                    setHeaderIndex(4)
                    navigate(main_guides_settings_inner_router_url)
                    setShowTrialExpiredModal(true)
                    return
                  }

                  if (productId === 2 && header_data.id === 2) {
                    e.preventDefault()
                    setSettingsSubHeaderIndex(1)
                    setHeaderIndex(4)
                    navigate(main_guides_settings_inner_router_url)
                    setShowUpgradeModal(true)
                    return
                  }

                  setHeaderIndex(header_data.id)
                }}
                href={header_data.href}
              >
                {header_data.title}
              </SidebarButtonA>
            )
          })}
          <SidebarButtonA
            $id={main_header_right_data.id}
            $selectHeaderIndex={selectHeaderIndex}
            onClick={() => {
              setHeaderIndex(main_header_right_data.id)
            }}
            href={main_header_right_data.href}
          >
            고객센터
          </SidebarButtonA>
        </SidebarNavBody>
      </Sidebar>
      <Section>
        <Outlet />
        <Footer></Footer>
      </Section>
      {showTrialExpiredModal && (
        <StandardModal
          titleText={'구독 만료'}
          modalBodyComponent={
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              구독이 만료되었습니다. 구독을 갱신해주세요.
            </div>
          }
          useLeftBtn={false}
          onRightBtnClick={() => {
            setShowTrialExpiredModal(false)
          }}
          onClose={() => {
            setShowTrialExpiredModal(false)
          }}
          rightBtnText={'OK'}
        ></StandardModal>
      )}
      {showUpgradeModal && (
        <StandardModal
          titleText={'안내문 업그레이드'}
          modalBodyComponent={
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              스탠다드로 업그레이드 하시면 스토어 기능을 사용할 수 있습니다.
            </div>
          }
          useLeftBtn={false}
          onRightBtnClick={() => {
            setShowUpgradeModal(false)
          }}
          onClose={() => {
            setShowUpgradeModal(false)
          }}
          rightBtnText={'OK'}
        ></StandardModal>
      )}
    </>
  ) : (
    <>
      <Outlet />
    </>
  )
}

const Header = styled.header`
  position: fixed;
  width: 100%;
  height: 75px !important;
  display: flex;
  background-color: #b49275;
  border-bottom: 8px solid #ffcc96;
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  padding: 0;
  z-index: 2;
  font-size: 16px;
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  line-height: 18px;
  box-sizing: border-box;
`

const SectionHeader = styled.section`
  width: 100%;
  padding: 0 10%;
  margin: 0 auto;
  flex: initial;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  min-width: 0;

  @media (max-width: 1024px) {
    width: 95%;
    padding: 0;
  }
`

const SectionLeftSide = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  width: 50%;
  float: left;
  box-sizing: border-box;
`

const SectionRightSide = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  width: 50%;
  float: left;
  box-sizing: border-box;
  justify-content: flex-end;
`

const LeftSideButtonSection = styled.div`
  padding-left: 20px;
  display: flex;
  box-sizing: border-box;
  position: relative;

  @media (max-width: 1500px) {
    padding-left: 10px;
  }
`

const ButtonA = styled.a`
  min-width: max-content;
  padding: 8px 20px;
  font-size: 1rem;
  background-color: ${(props) =>
    props.$id === props.$selectHeaderIndex ? '#eba55d' : 'transparent'};
  border-radius: 40px;
  color: #fff;
  text-decoration: none;
  margin: 0 18px;
  box-sizing: border-box;
  cursor: pointer;
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  text-rendering: optimizeLegibility;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(props) =>
    (props.$id === 0 || props.$id === 1 || props.$id === 2) &&
    !props.$isClickable
      ? '0.5'
      : '1'};
  cursor: ${(props) =>
    (props.$id === 0 || props.$id === 1 || props.$id === 2) &&
    !props.$isClickable
      ? 'not-allowed'
      : 'pointer'};

  @media (max-width: 1500px) {
    font-size: 0.875rem;
    padding: 8px 10px;
    margin: 0 5px;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`

const DisplayMaxNone = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
`

const DisplayMinNone = styled.div`
  @media (min-width: 1024px) {
    display: none;
  }
`

const SearchTotalFrame = styled.div`
  position: relative;
  display: inline-block;
  width: 40% !important;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    width: 100% !important;
  }
`

const SearchFrame = styled.div`
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
`

const SearchTextInput = styled.input`
  background-color: #333e4d;
  font-size: 0.875rem;
  color: #fff;
  padding-left: 30px;
  padding-right: 30px;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  outline: 0;
  width: 100%;
  overflow: visible;
  font-family: inherit;
  margin: 0;
`

const SearchIconInFrame = styled.span`
  display: flex;
  align-items: center;
  position: absolute;
  top: 1px;
  left: 8px;
  height: 100%;
  color: #c0c4cc;
  text-align: center;
  box-sizing: border-box;
  font-size: 14px;
  pointer-events: none;
`

const XIconInFrame = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 1px;
  right: 8px;
  height: 100%;
  color: #c0c4cc;
  text-align: center;
  box-sizing: border-box;
  font-size: 14px;
`

const SidebarBackdrop = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background-color: #00000080;
  display: ${(props) => (props.open ? 'block' : 'none')};
`

const Sidebar = styled.div`
  width: 300px;
  height: 100vh;
  width: 250px;
  position: fixed;
  top: 0;
  left: ${(props) => (props.open ? '0' : '-250px')};
  background-color: #b49275;
  z-index: 99;
  overflow: auto;
  transition: all 0.3s ease-in-out;
  box-sizing: border-box;
`

const SidebarHeader = styled.div`
  height: 67px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
`

const SidebarNavBody = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 75px);
  padding-bottom: 20px;
  box-sizing: border-box;
  padding-top: 15px;
`

const SidebarButtonA = styled.a`
  font-size: 16px;
  background-color: ${(props) =>
    props.$id === props.$selectHeaderIndex ? '#eba55d' : 'transparent'};
  border-radius: 40px;
  color: #fff;
  text-decoration: none;
  box-sizing: border-box;
  cursor: pointer;
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  text-rendering: optimizeLegibility;
  display: flex;
  // align-items: center;
  // justify-content: center;

  @media (max-width: 1024px) {
    padding: 8px 10px;
    margin: 0 10px;
  }
`

const SettingIconDiv = styled.div`
  background-color: ${(props) => (props.$select ? '#eba55d' : 'transparent')};
  border-radius: 40px;
  height: 30px;
  width: 30px;
  margin: 0 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`

const SettingItem = styled.div`
  padding: 0 20px;
  display: flex;
  color: #000;
  position: relative;
  line-height: 36px;
  margin: 0;
  font-size: 14px;
  cursor: pointer;
  outline: 0;
  box-sizing: border-box;
  text-decoration: none;

  &:hover {
    background-color: ${rgba_62_217_204_0d1};
  }
`
