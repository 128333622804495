import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import LoginPage from '../pages/login_page/LoginPage'
import SignUpPage from '../pages/sign_up_page/SignUpPage'
import PrivacyPolicyPage from '../pages/privacy_policy_page/PrivacyPolicyPage'
import TermsAndConditionsPage from '../pages/terms_and_conditions_page/TermsAndConditionsPage'
import { PrivateRoute } from './private_route/PrivateRoute'
import MainPage from '../pages/main_page/MainPage'
import {
  about_us_inner_router_url,
  create_guide_inner_router_url,
  customer_service_center_inner_router_url,
  default_guide_inner_router_url,
  downloads_inner_router_url,
  faq_inner_router_url,
  forgotten_password_inner_router_url,
  forgotten_password_sent_inner_router_url,
  guides_inner_router_url,
  help_support_inner_router_url,
  login_inner_router_url,
  main_guides_all_inner_router_url,
  payment_history_inner_router_url,
  price_inner_router_url,
  privacy_policy_inner_router_url,
  reset_password_inner_router_url,
  settings_inner_router_url,
  show_default_guide_inner_router_url,
  show_guide_inner_router_url,
  sign_up_inner_router_url,
  store_inner_router_url,
  store_privacy_policy_inner_router_url,
  store_terms_and_conditions_inner_router_url,
  terms_and_conditions_inner_router_url,
} from '../communication_system/inner_router_url/inner_router_url'
import MainGuidesGuidesPage from '../pages/main_guides_guides_page/MainGuidesGuidesPage'
import MainGuidesHelpSupportPage from '../pages/main_guides_help_support_page/MainGuidesHelpSupportPage'
import { useAuth } from '../data_system/context/auth_context/AuthContext'
import MainGuidesCreateGuidePage from '../pages/main_guides_create_guide_page/MainGuidesCreateGuidePage'
import MainGuidesDownloadsPage from '../pages/main_guides_downloads_page/MainGuidesDownloadsPage'
import MainGuidesSettingsPage from '../pages/main_guides_settings_page/MainGuidesSettingsPage'
import { SubscriptionRedirectRoute } from './subscription_redirect_route/SubscriptionRedirectRoute'
import ShowGuidePage from '../pages/show_guide_page/ShowGuidePage'
import ForgottenPasswordPage from '../pages/forgotten_password_page/ForgottenPasswordPage'
import ForgottenPasswordSentPage from '../pages/forgotten_password_sent_page/ForgottenPasswordSentPage'
import ResetPasswordPage from '../pages/reset_password_page/ResetPasswordPage'
import AboutUsPage from '../pages/about_us_page/AboutUsPage'
import CustomerServiceCenterPage from '../pages/customer_service_center_page/CustomerServiceCenterPage'
import PricePage from '../pages/price_page/PricePage'
import FAQPage from '../pages/FAQ_page/FAQPage'
import MainGuidesStorePage from '../pages/main_guides_store_page/MainGuidesStorePage'
import MainGuidesDefaultGuidePage from '../pages/main_guides_default_guide_page/MainGuidesDefaultGuidePage'
import ShowDefaultGuidePage from '../pages/show_default_guide_page/ShowDefaultGuidePage'
import PaymentHistoryPage from '../pages/payment_history_page/PaymentHistoryPage'
import StoreTermsAndConditionsPage from '../pages/store_terms_and_conditions_page/StoreTermsAndConditionsPage'
import StorePrivacyPolicyPage from '../pages/store_privacy_policy_page/StorePrivacyPolicyPage'

const TotalRouter = () => {
  const { isNewUser } = useAuth()

  return (
    <Router>
      <SubscriptionRedirectRoute></SubscriptionRedirectRoute>
      <Routes>
        <Route path="/" element={<MainPage></MainPage>}></Route>
        <Route
          path={about_us_inner_router_url}
          element={<AboutUsPage></AboutUsPage>}
        ></Route>
        <Route
          path={customer_service_center_inner_router_url}
          element={<CustomerServiceCenterPage></CustomerServiceCenterPage>}
        ></Route>
        <Route
          path={price_inner_router_url}
          element={<PricePage></PricePage>}
        ></Route>
        <Route path={login_inner_router_url} element={<LoginPage />}></Route>
        <Route path={sign_up_inner_router_url} element={<SignUpPage />}></Route>
        <Route
          path={forgotten_password_inner_router_url}
          element={<ForgottenPasswordPage></ForgottenPasswordPage>}
        ></Route>
        <Route
          path={forgotten_password_sent_inner_router_url}
          element={<ForgottenPasswordSentPage></ForgottenPasswordSentPage>}
        ></Route>
        <Route
          path={reset_password_inner_router_url}
          element={<ResetPasswordPage></ResetPasswordPage>}
        ></Route>
        <Route
          path={privacy_policy_inner_router_url}
          element={<PrivacyPolicyPage />}
        ></Route>
        <Route
          path={store_privacy_policy_inner_router_url}
          element={<StorePrivacyPolicyPage />}
        ></Route>
        <Route
          path={terms_and_conditions_inner_router_url}
          element={<TermsAndConditionsPage />}
        ></Route>
        <Route
          path={store_terms_and_conditions_inner_router_url}
          element={<StoreTermsAndConditionsPage />}
        ></Route>
        <Route
          path={show_guide_inner_router_url}
          element={<ShowGuidePage />}
        ></Route>
        <Route
          path={show_default_guide_inner_router_url}
          element={<ShowDefaultGuidePage />}
        ></Route>
        <Route path={faq_inner_router_url} element={<FAQPage />}></Route>
        <Route
          path={main_guides_all_inner_router_url}
          element={<PrivateRoute />}
        >
          {isNewUser && (
            <Route
              path={create_guide_inner_router_url}
              element={<MainGuidesCreateGuidePage />}
            />
          )}
          {!isNewUser && (
            <>
              <Route
                path={guides_inner_router_url}
                element={<MainGuidesGuidesPage />}
              />
              <Route
                path={downloads_inner_router_url}
                element={<MainGuidesDownloadsPage />}
              />
              <Route
                path={default_guide_inner_router_url}
                element={<MainGuidesDefaultGuidePage />}
              />
              <Route
                path={store_inner_router_url}
                element={<MainGuidesStorePage />}
              />
              <Route
                path={help_support_inner_router_url}
                element={<MainGuidesHelpSupportPage />}
              />
              <Route
                path={settings_inner_router_url}
                element={<MainGuidesSettingsPage />}
              />
              <Route
                path={payment_history_inner_router_url}
                element={<PaymentHistoryPage />}
              />
            </>
          )}
        </Route>
      </Routes>
    </Router>
  )
}

export default TotalRouter
