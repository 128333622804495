import { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'

const RootModal = ({ onClose, children }) => {
  const [modalHeight, setModalHeight] = useState(0)
  const modalRef = useRef(null)

  const stopPropagation = (event) => {
    event.stopPropagation()
  }

  useEffect(() => {
    if (modalRef.current) {
      setModalHeight(modalRef.current.clientHeight)
    }
  }, [])

  return ReactDOM.createPortal(
    <ModalBackground>
      <ModalWrapper
        ref={modalRef}
        $modalHeight={modalHeight}
        onClick={stopPropagation}
      >
        {children}
      </ModalWrapper>
    </ModalBackground>,
    document.getElementById('root-modal')
  )
}

export default RootModal

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow-y: auto;
`

const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  max-height: 100vh;
  position: relative;
  top: 50.5%;
  transform: translateY(-49.5%);
`
