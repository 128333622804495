import styled from 'styled-components'
import { Container } from '../../../design_system/components/container/Container'
import { TextInput } from '../../../design_system/components/text_input/TextInput'
import { OpacityBtn } from '../../../design_system/components/btn/pc/opacity_btn/OpacityBtn'
import { c_b49275, c_eba55d, c_ffffff } from '../../../design_system/colors'
import { useEffect, useState } from 'react'
import { useAuth } from '../../../data_system/context/auth_context/AuthContext'
import { useSelectHeader } from '../../../data_system/context/select_header_index_context/SelectHeaderIndexContext'
import { useNavigate } from 'react-router-dom'
import { main_guides_guides_inner_router_url } from '../../../communication_system/inner_router_url/inner_router_url'
import { freePaymentFirst } from '../../../communication_system/axios_apis/axios_apis'
import { TitleBigDiv } from '../../../design_system/components/title_big_div/TitleBigDiv'
import {
  default_icon_image_url,
  default_logo_image_url,
  default_mobile_image_url,
  default_pc_image_url,
} from '../../../data_system/static_data/image_static_data'
import { useLoading } from '../../../data_system/context/loading_context/LoadingContext'
import { useGoogleMaps } from '../../../data_system/context/google_maps_context/GoogleMapsContext'

const Page3 = ({
  setPageNumber,
  guideName,
  phoneNumber,
  address,
  setAddress,
  additionalAddress,
  setAdditionalAddress,
  position,
  setPosition,
}) => {
  const { isLoaded, loadError } = useGoogleMaps()
  console.log('isLoaded:', isLoaded, 'loadError:', loadError)
  let navigate = useNavigate()
  const { completeInitialSetup } = useAuth()
  const { setHeaderIndex } = useSelectHeader()
  const [suggestions, setSuggestions] = useState([])
  const [submitDisabledCheck, setSubmitDisabledCheck] = useState(true)
  const [userInput, setUserInput] = useState(false) // 사용자 입력을 추적하는 상태
  const { showLoading, hideLoading } = useLoading()

  const handleInputChange = (e) => {
    setAddress(e.target.value)
    setUserInput(true) // 사용자 입력이 변경되면 true로 설정
  }

  const getPlaceDetails = (placeId) => {
    const placesService = new window.google.maps.places.PlacesService(
      document.createElement('div')
    )

    placesService.getDetails(
      {
        placeId: placeId,
        fields: ['geometry'],
      },
      (place, status) => {
        if (
          status === window.google.maps.places.PlacesServiceStatus.OK &&
          place.geometry
        ) {
          setPosition({
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng(),
          })
          setSuggestions([]) // 해당 위치를 선택한 후에는 제안 목록을 초기화
        }
      }
    )
  }

  const handleSuggestionClick = (description, placeId) => {
    setAddress(description)
    setUserInput(false)
    getPlaceDetails(placeId) // 제안 클릭시에는 getPlaceDetails를 직접 호출
  }

  useEffect(() => {
    if (!address || !userInput) {
      setSuggestions([])
      return
    }

    if (window.google && window.google.maps && window.google.maps.places) {
      const autocompleteService =
        new window.google.maps.places.AutocompleteService()

      autocompleteService.getPlacePredictions(
        {
          input: address,
          componentRestrictions: { country: 'kr' },
        },
        (predictions, status) => {
          if (
            status === window.google.maps.places.PlacesServiceStatus.OK &&
            predictions
          ) {
            setSuggestions(predictions)
          }
        }
      )
    }
  }, [address, userInput])

  useEffect(() => {
    if (address !== '' && userInput === false) {
      setSubmitDisabledCheck(false)
    } else {
      setSubmitDisabledCheck(true)
    }
  }, [address])

  return (
    <Container>
      <div>
        <Page3Body>
          <Page3PStepText>Step 2</Page3PStepText>
          <Page3TitleH1>숙소 위치 추가하기</Page3TitleH1>
          <Page3PContentText>
            숙소 위치는 나중에 추가할 수 있습니다.
          </Page3PContentText>
          <div
            style={{
              marginTop: 15,
            }}
          >
            {isLoaded && (
              <div>
                <TitleBigDiv>주소</TitleBigDiv>
                <TextInput
                  value={address}
                  onChange={handleInputChange}
                ></TextInput>
                {suggestions.length !== 0 && (
                  <div
                    style={{
                      border: '1px solid #dcdfe6',
                      borderRadius: '8px',
                      boxSizing: 'border-box',
                      maxHeight: '200px',
                      overflowY: 'auto',
                      width: '100%',
                      backgroundColor: '#ffffff',
                      marginTop: 15,
                    }}
                  >
                    {suggestions.map((suggestion) => (
                      <div
                        key={suggestion.place_id}
                        style={{
                          padding: '8px 15px',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          handleSuggestionClick(
                            suggestion.description,
                            suggestion.place_id
                          )
                        }}
                      >
                        {suggestion.description}
                      </div>
                    ))}
                  </div>
                )}
                <TitleBigDiv>상세 주소</TitleBigDiv>
                <TextInput
                  value={additionalAddress}
                  onChange={(e) => {
                    setAdditionalAddress(e.target.value)
                  }}
                ></TextInput>
              </div>
            )}
          </div>
          <div
            style={{
              paddingTop: 30,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                display: 'flex',
              }}
            >
              <OpacityBtn
                fontColor={c_ffffff}
                backgroundColor={c_b49275}
                onClick={() => {
                  setPageNumber(2)
                }}
              >
                뒤로가기
              </OpacityBtn>
              <div
                style={{
                  marginLeft: 10,
                }}
              >
                <OpacityBtn
                  fontColor={c_ffffff}
                  backgroundColor={c_eba55d}
                  disabled={submitDisabledCheck}
                  onClick={async () => {
                    showLoading()

                    const response = await freePaymentFirst({
                      guideName: guideName,
                      hostPhoneNumber: phoneNumber,
                      logoImageUrl: default_logo_image_url,
                      iconImageUrl: default_icon_image_url,
                      logoIconImageRounded: true,
                      pcImageUrl: default_pc_image_url,
                      mobileImageUrl: default_mobile_image_url,
                      address: address === '' ? null : address,
                      additionalAddress:
                        additionalAddress === '' ? null : additionalAddress,
                      latitude:
                        position.latitude === null ? null : position.latitude,
                      longitude:
                        position.longitude === null ? null : position.longitude,
                    })

                    if (response.status === 201) {
                      setPageNumber(4)
                      completeInitialSetup()
                      setHeaderIndex(0)
                      navigate(main_guides_guides_inner_router_url)
                    }

                    hideLoading()

                    // setPageNumber(4);
                    // completeInitialSetup();
                    // setHeaderIndex(0);
                    // navigate(main_guides_guides_inner_router_url);
                  }}
                >
                  제출하기
                </OpacityBtn>
              </div>
            </div>
            <OpacityBtn
              fontColor={c_ffffff}
              backgroundColor={c_eba55d}
              onClick={async () => {
                showLoading()

                const response = await freePaymentFirst({
                  guideName: guideName,
                  hostPhoneNumber: phoneNumber,
                  logoImageUrl: default_logo_image_url,
                  iconImageUrl: default_icon_image_url,
                  logoIconImageRounded: true,
                  pcImageUrl: default_pc_image_url,
                  mobileImageUrl: default_mobile_image_url,
                  address: null,
                  additionalAddress: null,
                  latitude: null,
                  longitude: null,
                })

                if (response.status === 201) {
                  setPageNumber(4)
                  completeInitialSetup()
                  setHeaderIndex(0)
                  navigate(main_guides_guides_inner_router_url)
                }

                hideLoading()
                // setPageNumber(4);
                // completeInitialSetup();
                // setHeaderIndex(0);
                // navigate(main_guides_guides_inner_router_url);
              }}
            >
              넘어가기
            </OpacityBtn>
          </div>
        </Page3Body>
      </div>
    </Container>
  )
}

export default Page3

const Page3PStepText = styled.p`
  font-size: 1.25rem;
  line-height: 1.5;
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  letter-spacing: 1px;
  color: #244556;
  width: max-content;
  box-sizing: border-box;
  margin: 0;
`

const Page3Body = styled.div`
  width: 100%;
  height: 60vh;
  box-sizing: border-box;
  float: left;

  @media only screen and (min-width: 992px) {
    width: 54.16667%;
  }

  @media only screen and (min-width: 1200px) {
    width: 50%;
  }
`

const Page3TitleH1 = styled.h1`
  width: auto;
  line-height: 3.375rem;
  font-size: 3rem;
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  letter-spacing: 1px;
  color: #eba55d;
  margin: 10px 0;
  box-sizing: border-box;

  @media (max-width: 1600px) {
    font-size: 2.25rem;
  }

  @media (max-width: 1024px) {
    padding: 10px 50px 20px 0;
  }

  @media (max-width: 650px) {
    font-size: 2rem;
    max-width: 375px;
  }
`

const Page3PContentText = styled.p`
  line-height: 1.5;
  font-size: 1rem;
  color: #244556;
  font-family: Circular Std Book, Tahoma, SansSerif;
  margin: 0;
  box-sizing: border-box;
`
