import styled from 'styled-components';

export const Section = ({ overflow = 'hidden', children }) => {
  return <SectionContainer $overflow={overflow}>{children}</SectionContainer>;
};

const SectionContainer = styled.section`
  position: absolute;
  height: calc(100% - 75px);
  width: 100%;
  top: 75px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  overflow: ${(props) => props.$overflow};
`;
