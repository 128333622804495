import styled from 'styled-components'

export const TextInput = ({
  backgroundColor = '#f2f2f2',
  value,
  onChange,
  placeholder = '',
  disabled = false,
}) => {
  return (
    <TextInputFrame
      $backgroundColor={backgroundColor}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      disabled={disabled}
    ></TextInputFrame>
  )
}

const TextInputFrame = styled.input`
  height: 45px;
  border-radius: 8px;
  color: #353431;
  background-color: ${(props) => props.$backgroundColor};
  font-size: 1rem;
  line-height: normal;
  background-image: none;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  display: inline-block;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;

  @media (max-width: 650px) {
    height: 40px;
  }

  &:hover {
    border-color: #c5c4c3;
    outline: 0;
  }

  &:focus {
    border-color: #ddc2ab;
    outline: 0;
  }

  &:disabled {
    cursor: not-allowed;
  }
`
