import styled from 'styled-components'
import { c_244556, c_353431 } from '../../colors'

export const TextDiv = styled.div`
  padding: 8px 0;
  margin-top: 0px;
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  color: ${c_353431};
  font-size: 16px;
  font-weight: 500;
`
