import React from 'react';
import RadioBtn from '../radio_btn/RadioBtn';

const RadioBtnGroup = ({ radioItems, selectedId, setSelectedId }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {radioItems.map((item) => (
        <div
          key={item.id}
          style={{
            display: 'flex',
            alignItems: 'center',
            marginRight: 30,
          }}
        >
          <RadioBtn
            id={`radio${item.id}`}
            value={item.id.toString()}
            name="group1"
            selectedId={selectedId}
            setSelectedId={setSelectedId}
          />
          <div
            style={{
              marginLeft: 10,
            }}
          >
            <label htmlFor={`radio${item.id}`}>{item.title}</label>
          </div>
        </div>
      ))}
    </div>
  );
};

export default RadioBtnGroup;
