import React, { useEffect, useRef } from 'react';
import ExplainTooltip from '../explain_tooltip/ExplainTooltip';

const IconTooltip = ({
  visible,
  handleClick,
  children,
  icon,
  tooltipMarginLeft,
  tooltipMarginRight,
  tooltipTop,
  tooltipLeft,
  tooltipRight,
  tooltipDefaultWidth,
  tooltipChangeWidth,
}) => {
  const tooltipRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        visible &&
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target)
      ) {
        handleClick();
      }
    };

    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [visible, handleClick]);

  const handleTooltipClick = (event) => {
    event.stopPropagation();
  };

  return (
    <div
      ref={tooltipRef}
      style={{
        position: 'relative',
      }}
      onClick={handleTooltipClick}
    >
      <ExplainTooltip
        visible={visible}
        onClick={handleClick}
        marginLeft={tooltipMarginLeft}
        marginRight={tooltipMarginRight}
        top={tooltipTop}
        left={tooltipLeft}
        right={tooltipRight}
        defaultWidth={tooltipDefaultWidth}
        changeWidth={tooltipChangeWidth}
      >
        {children}
      </ExplainTooltip>
      {icon}
    </div>
  );
};

export default IconTooltip;
