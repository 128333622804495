import styled from 'styled-components';

export const Container = styled.div`
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  padding: 0 10%;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    overflow-x: hidden;
  }

  @media (max-width: 650px) {
    justify-content: start;
    padding: 0 20px;
    overflow: auto;
  }
`;
