import React, { createContext, useContext, useState, useEffect } from 'react'
import { getAccountSubscriptionInformation } from '../../../communication_system/axios_apis/axios_apis'
import { useAuth } from '../auth_context/AuthContext'
import moment from 'moment-timezone'

// Context 생성
const SubscriptionContext = createContext()

export const useSubscription = () => {
  return useContext(SubscriptionContext)
}

export function SubscriptionProvider({ children }) {
  const { isLoggedIn, isNewUser } = useAuth()

  // 상태 초기 값 로딩
  const [isClickable, setIsClickable] = useState(
    JSON.parse(localStorage.getItem('isClickable') || 'true')
  )
  const [daysLeft, setDaysLeft] = useState(
    Number(localStorage.getItem('daysLeft')) || null
  )
  const [maxGuideNumber, setMaxGuideNumber] = useState(
    Number(localStorage.getItem('maxGuideNumber')) || null
  )
  const [subscriptionPrice, setSubscriptionPrice] = useState(
    Number(localStorage.getItem('subscriptionPrice')) || 0
  )
  const [subscriptionStartDate, setSubscriptionStartDate] = useState(
    moment.utc(localStorage.getItem('subscriptionStartDate') || undefined)
  )
  const [subscriptionEndDate, setSubscriptionEndDate] = useState(
    moment.utc(localStorage.getItem('subscriptionEndDate') || undefined)
  )
  const [nextPaymentDate, setNextPaymentDate] = useState(
    moment.utc(localStorage.getItem('nextPaymentDate') || undefined)
  )
  const [startPayment, setStartPayment] = useState(
    JSON.parse(localStorage.getItem('startPayment') || 'false')
  )

  const [productId, setProductId] = useState(
    Number(localStorage.getItem('productId')) || 1
  )

  const [productType, setProductType] = useState(
    localStorage.getItem('productType') || ''
  )

  const [paymentTypeId, setPaymentTypeId] = useState(
    Number(localStorage.getItem('paymentTypeId')) || 1
  )

  const [currentGuideNumber, setCurrentGuideNumber] = useState(
    Number(localStorage.getItem('currentGuideNumber')) || 0
  )

  const initialLastFetched = localStorage.getItem('lastFetched')
  const [lastFetched, setLastFetched] = useState(
    initialLastFetched ? Number(initialLastFetched) : null
  )

  async function fetchSubscriptionDataDirectly() {
    const now = moment().tz('Asia/Seoul').valueOf()

    try {
      const response = await getAccountSubscriptionInformation()
      console.log(response)
      setLastFetched(now)
      localStorage.setItem('lastFetched', now.toString())

      const startTimestamp = moment().tz('Asia/Seoul').valueOf()
      const endTimestamp = moment(response.data.user.totalSubscriptionEndDate)
        .tz('Asia/Seoul')
        .add(-9, 'hours')
        .valueOf()
      const millisecondsLeft = endTimestamp - startTimestamp
      const daysRemaining = Math.ceil(millisecondsLeft / (24 * 60 * 60 * 1000))
      console.log(`daysRemaining: ${daysRemaining}`)

      // 상태 설정
      setIsClickable(daysRemaining > 0)
      setDaysLeft(daysRemaining)
      setMaxGuideNumber(response.data.user.maxGuideNumber)
      setSubscriptionPrice(response.data.user.subscriptionPrice)
      setSubscriptionStartDate(
        moment(response.data.user.totalSubscriptionStartDate)
          .tz('Asia/Seoul')
          .add(-9, 'hours')
      )
      setSubscriptionEndDate(
        moment(response.data.user.totalSubscriptionEndDate)
          .tz('Asia/Seoul')
          .add(-9, 'hours')
      )
      const newNextPaymentDate = moment(
        response.data.user.totalSubscriptionEndDate
      )
        .tz('Asia/Seoul')
        .add(-9, 'hours')
      setNextPaymentDate(newNextPaymentDate)
      setStartPayment(response.data.user.startPayment)
      setProductId(response.data.user.productId)
      setProductType(response.data.user.productType)
      setPaymentTypeId(response.data.payment.paymentTypeId)
      setCurrentGuideNumber(response.data.currentGuideNumber)

      // localStorage에 상태 저장
      localStorage.setItem('isClickable', daysRemaining > 0 ? 'true' : 'false')
      localStorage.setItem('daysLeft', daysRemaining.toString())
      localStorage.setItem(
        'maxGuideNumber',
        response.data.user.maxGuideNumber.toString()
      )
      localStorage.setItem(
        'subscriptionPrice',
        response.data.user.subscriptionPrice.toString()
      )
      localStorage.setItem(
        'subscriptionStartDate',
        moment.utc(response.data.user.totalSubscriptionStartDate).toISOString()
      )
      localStorage.setItem(
        'subscriptionEndDate',
        moment.utc(response.data.user.totalSubscriptionEndDate).toISOString()
      )
      localStorage.setItem('nextPaymentDate', newNextPaymentDate.toISOString())
      localStorage.setItem(
        'startPayment',
        response.data.user.startPayment.toString()
      )
      localStorage.setItem('isNewUser', response.data.user.isNewUser.toString())
      localStorage.setItem('productId', response.data.user.productId.toString())
      localStorage.setItem(
        'productType',
        response.data.user.productType.toString()
      )
      localStorage.setItem(
        'paymentTypeId',
        response.data.payment.paymentTypeId.toString()
      )
      localStorage.setItem(
        'currentGuideNumber',
        response.data.currentGuideNumber.toString()
      )
    } catch (error) {
      console.error('Failed to fetch subscription data', error)
    }
  }

  useEffect(() => {
    async function fetchSubscriptionData() {
      const now = moment().tz('Asia/Seoul').valueOf()

      // if (lastFetched && now - lastFetched < 2 * 60 * 60 * 1000) return
      // console.log('fetching subscription data...')

      try {
        const response = await getAccountSubscriptionInformation()
        console.log(response)
        setLastFetched(now)
        localStorage.setItem('lastFetched', now.toString())

        const startTimestamp = moment().tz('Asia/Seoul').valueOf()
        const endTimestamp = moment(response.data.user.totalSubscriptionEndDate)
          .tz('Asia/Seoul')
          .add(-9, 'hours')
          .valueOf()
        const millisecondsLeft = endTimestamp - startTimestamp
        const daysRemaining = Math.ceil(
          millisecondsLeft / (24 * 60 * 60 * 1000)
        )
        // 로그를 통해 타임스탬프 확인
        console.log(`Current timestamp (Asia/Seoul): ${startTimestamp}`)
        console.log(`End timestamp (UTC): ${endTimestamp}`)
        // 로그를 통해 밀리초 차이 확인
        console.log(`Milliseconds left: ${millisecondsLeft}`)
        // 남은 일 수 로그 출력
        console.log(`daysRemaining: ${daysRemaining}`)

        // 상태 설정
        setIsClickable(daysRemaining > 0)
        setDaysLeft(daysRemaining)
        setMaxGuideNumber(response.data.user.maxGuideNumber)
        setSubscriptionPrice(response.data.user.subscriptionPrice)
        setSubscriptionStartDate(
          moment(response.data.user.totalSubscriptionStartDate)
            .tz('Asia/Seoul')
            .add(-9, 'hours')
        )
        setSubscriptionEndDate(
          moment(response.data.user.totalSubscriptionEndDate)
            .tz('Asia/Seoul')
            .add(-9, 'hours')
        )
        const newNextPaymentDate = moment(
          response.data.user.totalSubscriptionEndDate
        )
          .tz('Asia/Seoul')
          .add(-9, 'hours')
        setNextPaymentDate(newNextPaymentDate)
        setStartPayment(response.data.user.startPayment)
        setProductId(response.data.user.productId)
        setProductType(response.data.user.productType)
        setPaymentTypeId(response.data.payment.paymentTypeId)
        setCurrentGuideNumber(response.data.currentGuideNumber)
        // localStorage에 상태 저장
        localStorage.setItem(
          'isClickable',
          daysRemaining > 0 ? 'true' : 'false'
        )
        localStorage.setItem('daysLeft', daysRemaining.toString())
        localStorage.setItem(
          'maxGuideNumber',
          response.data.user.maxGuideNumber.toString()
        )
        localStorage.setItem(
          'subscriptionPrice',
          response.data.user.subscriptionPrice.toString()
        )
        localStorage.setItem(
          'subscriptionStartDate',
          moment
            .utc(response.data.user.totalSubscriptionStartDate)
            .toISOString()
        )
        localStorage.setItem(
          'subscriptionEndDate',
          moment.utc(response.data.user.totalSubscriptionEndDate).toISOString()
        )
        localStorage.setItem(
          'nextPaymentDate',
          newNextPaymentDate.toISOString()
        )
        localStorage.setItem(
          'startPayment',
          response.data.user.startPayment.toString()
        )
        localStorage.setItem(
          'isNewUser',
          response.data.user.isNewUser.toString()
        )
        localStorage.setItem(
          'productId',
          response.data.user.productId.toString()
        )
        localStorage.setItem(
          'productType',
          response.data.user.productType.toString()
        )
        localStorage.setItem(
          'paymentTypeId',
          response.data.payment.paymentTypeId.toString()
        )
        localStorage.setItem(
          'currentGuideNumber',
          response.data.currentGuideNumber.toString()
        )
      } catch (error) {
        console.error('Failed to fetch subscription data', error)
      }
    }

    if (isLoggedIn === true && isNewUser === false) {
      fetchSubscriptionData()
    }
  }, [isLoggedIn, isNewUser])

  //lastFetched

  const value = {
    isClickable,
    daysLeft,
    maxGuideNumber,
    subscriptionPrice,
    subscriptionStartDate,
    subscriptionEndDate,
    startPayment,
    setStartPayment,
    nextPaymentDate,
    fetchSubscriptionDataDirectly,
    productId,
    productType,
    paymentTypeId,
    currentGuideNumber,
    setCurrentGuideNumber,
  }

  return (
    <SubscriptionContext.Provider value={value}>
      {children}
    </SubscriptionContext.Provider>
  )
}
