import styled from 'styled-components';

const ExplainTooltip = ({
  visible,
  marginLeft,
  marginRight,
  top,
  left,
  right,
  defaultWidth,
  changeWidth,
  onClick,
  children,
}) => {
  return (
    <ExplainTooltipFrame
      $visible={visible}
      $marginLeft={marginLeft}
      $marginRight={marginRight}
      $top={top}
      $left={left}
      $right={right}
      $defaultWidth={defaultWidth}
      $changeWidth={changeWidth}
      onClick={onClick}
    >
      {children}
    </ExplainTooltipFrame>
  );
};

export default ExplainTooltip;

const ExplainTooltipFrame = styled.div`
  visibility: ${(props) => (props.$visible ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.$visible ? '1' : '0')};
  transition: opacity 0.3s;
  margin-left: ${(props) => props.$marginLeft}px;
  margin-right: ${(props) => props.$marginRight}px;
  position: absolute;
  top: ${(props) => props.$top}px;
  right: ${(props) => props.$right}px;
  left: ${(props) => props.$left}px;
  font-family: Circular Std Book, Tahoma, SansSerif !important;
  color: #000 !important;
  border-radius: 8px;
  border: none;
  box-shadow: 0 4px 24px #00000026;
  display: inline-block;
  width: ${(props) => props.$defaultWidth}px;
  line-height: 1.125rem;
  font-size: 0.875rem;
  word-break: break-word;
  background: #fff;
  box-sizing: border-box;
  word-break: break-word;
  transform-origin: right center;
  text-rendering: optimizeLegibility;
  overflow: hidden;
  z-index: 200;

  @media (max-width: 1024px) {
    width: ${(props) => props.$changeWidth}px;
  }
`;
