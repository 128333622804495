import React, { useEffect, useState } from 'react'
import {
  CopyIcon,
  CopyIconFixed,
  PaperPlaneIcon,
  PaperPlaneIconFixed,
  PaperPlaneIconGray,
  PenIconFixed,
  ThIconFixed,
  TrashIcon,
  VerticalThreeDotsIcon,
} from '../../../design_system/icons/icons'
import { usePopup } from '../../../data_system/context/popup_context/PopupContext'
import IconTextCircleBtn from '../../../design_system/components/btn/pc/icon_text_circle_btn/IconTextCircleBtn'
import { Dropdown } from '../../../design_system/components/dropdown/Dropdown'
import { generate_and_download_QR } from '../../../data_system/data_function/generate_and_download_QR'
import { TableItem } from '../../../design_system/components/table_item/TableItem'
import TableArrowItem from '../../../design_system/components/table_arrow_item/TableArrowItem'
import styled from 'styled-components'
import StandardModal from '../../../design_system/components/standard_modal/StandardModal'
import IconTooltip from '../../../design_system/components/icon_tooltip/IconTooltip'
import { useLoading } from '../../../data_system/context/loading_context/LoadingContext'
import { SettingsItem } from '../../../design_system/components/settings_item/SettingsItem'
import NormalModal from '../../../design_system/components/normal_modal/NormalModal'
import { TitleBigDiv } from '../../../design_system/components/title_big_div/TitleBigDiv'
import { TitleSubDiv } from '../../../design_system/components/title_sub_div/TitleSubDiv'
import { TextInput } from '../../../design_system/components/text_input/TextInput'
import { dateFormatFunction } from '../../../data_system/data_function/date_format_function'
import { dateAndTimeFormatFunction } from '../../../data_system/data_function/date_and_time_format_function'
import { TextArea } from '../../../design_system/components/text_area/TextArea'
import { emailCheck } from '../../../data_system/data_function/email_check_function'
import {
  deleteAllInvitations,
  deleteInvitation,
  invitationSendNow,
  postInvitation,
  putInvitation,
} from '../../../communication_system/axios_apis/axios_apis'

const MainGuidesGuidesShareTab = ({
  oneGuide,
  invitationsList,
  setInvitationsList,
  guideViewNumber,
  selectedGuideViewNumberIndex,
  setSelectedGuideViewNumberIndex,
}) => {
  const { showLoading, hideLoading } = useLoading()
  const { show } = usePopup()

  const [addNewInvitationByEmailSMSModal, setAddNewInvitationByEmailSMSModal] =
    useState(false)

  const [deleteInvitationModal, setDeleteInvitationModal] = useState(false)

  const [
    viewEditNewInvitationByEmailSMSModal,
    setViewEditNewInvitationByEmailSMSModal,
  ] = useState(false)
  const [
    tableHeaderInvitationsSettingsVisible,
    setTableHeaderInvitationsSettingsVisible,
  ] = useState(false)

  const toggleInvitationsTooltipForItem = (id) => {
    setInvitationsList((prevInvitations) =>
      prevInvitations.map((template) =>
        template.id === id
          ? { ...template, visibleTooltip: !template.visibleTooltip }
          : template
      )
    )
  }

  const removeInvitationById = (id) => {
    setInvitationsList((prevInvitations) =>
      prevInvitations.filter((invitation) => invitation.id !== id)
    )
  }

  const editInvitation = (id, newInvitation) => {
    setInvitationsList((prevInvitations) =>
      prevInvitations.map((invitation) =>
        invitation.id === id
          ? {
              ...invitation,
              invitationName: newInvitation.invitationName,
              guestName: newInvitation.guestName,
              guestPhoneNumber: newInvitation.guestPhoneNumber,
              guestEmail: newInvitation.guestEmail,
              emailTitle: newInvitation.emailTitle,
              emailContent: newInvitation.emailContent,
            }
          : invitation
      )
    )
  }

  const editInvitationLastSendTime = (id, newInvitation) => {
    setInvitationsList((prevInvitations) =>
      prevInvitations.map((invitation) =>
        invitation.id === id
          ? {
              ...invitation,
              lastSendTime: newInvitation.lastSendTime,
            }
          : invitation
      )
    )
  }

  const [invitationNameText, setInvitationNameText] = useState('')
  const [guestNameText, setGuestNameText] = useState('')
  const [guestEmailText, setGuestEmailText] = useState('')
  const [guestPhoneNumberText, setGuestPhoneNumberText] = useState('')
  const [invitationEmailTitleText, setInvitationEmailTitleText] = useState('')
  const [invitationEmailContentText, setInvitationEmailContentText] =
    useState('')

  const [selectInvitation, setSelectInvitation] = useState(null)
  const [selectInvitationId, setSelectInvitationId] = useState(null)

  const [deleteAllInvitationsModal, setDeleteAllInvitationsModal] =
    useState(false)

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <>
      <InviteShareContainer>
        <QuickShareLinkTile>
          <QuickShareLinkTileHeader>
            <div>
              안내문 링크 공유
              <div
                style={{
                  fontSize: 12,
                }}
              >
                링크를 복사하여 게스트에게 전달해 주세요.(URL주소를 클릭하면
                복사됩니다.)
              </div>
            </div>
          </QuickShareLinkTileHeader>
          <QuickShareLinkTileBody>
            <QuickShareLinkTilePart1>
              <CopyLink>
                <CopyIconFixed
                  onClick={() => {
                    navigator.clipboard.writeText(oneGuide.shareUrl)
                    show('복사 완료', true)
                  }}
                ></CopyIconFixed>
                <div
                  style={{
                    marginLeft: 8,
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    width: '85%',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText(oneGuide.shareUrl)
                    show('복사 완료', true)
                  }}
                >
                  {oneGuide.shareUrl}
                </div>
              </CopyLink>
              <div
                style={{
                  marginLeft: 10,
                }}
              >
                <IconTextCircleBtn
                  text={'QR코드 생성하기'}
                  borderColor={'#ededed'}
                  backgroundColor={'#ededed'}
                  textColor={'#000'}
                  onClick={async () => {
                    await generate_and_download_QR(oneGuide.shareUrl)
                  }}
                >
                  <ThIconFixed></ThIconFixed>
                </IconTextCircleBtn>
              </div>
            </QuickShareLinkTilePart1>
            {/* <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: 15,
              }}
            >
              {guideViewNumber} views
              <div
                style={{
                  marginLeft: 30,
                  width: 150,
                }}
              >
                <Dropdown
                  options={[
                    {
                      id: 0,
                      title: 'All Time',
                    },
                    {
                      id: 1,
                      title: 'Last 7 days',
                    },
                    {
                      id: 2,
                      title: 'Last 4 weeks',
                    },
                    {
                      id: 3,
                      title: 'Last 3 months',
                    },
                    {
                      id: 4,
                      title: 'Last 12 months',
                    },
                  ]}
                  selectIndex={selectedGuideViewNumberIndex}
                  setSelectIndex={setSelectedGuideViewNumberIndex}
                ></Dropdown>
              </div>
            </div> */}
          </QuickShareLinkTileBody>
        </QuickShareLinkTile>
        {/* <BottomSectionHeaderFrame>
          <BottomSectionHeaderTitle>
            안내문 공유 리스트
          </BottomSectionHeaderTitle>
          <BottomSectionHeaderBottom>
            <IconTextCircleBtn
              text={'이메일로 보내기'}
              borderColor={'#dcdfe6'}
              backgroundColor={'#dcdfe6'}
              textColor={'#000'}
              onClick={() => {
                setAddNewInvitationByEmailSMSModal(true)
              }}
            >
              <PaperPlaneIcon></PaperPlaneIcon>
            </IconTextCircleBtn>
          </BottomSectionHeaderBottom>
        </BottomSectionHeaderFrame>
        <TableInner>
          <TableHeaderWrapper>
            <TableItem $width={20}></TableItem>
            <TableArrowItem width={20} title={'안내문 이름'}></TableArrowItem>
            <TableItem $width={20}>게스트</TableItem>
            <TableItem $width={20}>이메일</TableItem>
            <TableItem $width={20}>연락처</TableItem>
            <TableArrowItem width={20} title={'조회'}></TableArrowItem>
            <TableArrowItem width={20} title={'생성일'}></TableArrowItem>
            <TableArrowItem width={20} title={'최근발송일시'}></TableArrowItem>
            <TableItem $width={2}>
              <IconTooltip
                icon={
                  <VerticalThreeDotsIcon
                    onClick={(e) => {
                      setTableHeaderInvitationsSettingsVisible((pre) => !pre)
                    }}
                  ></VerticalThreeDotsIcon>
                }
                visible={tableHeaderInvitationsSettingsVisible}
                handleClick={() => {
                  setTableHeaderInvitationsSettingsVisible((pre) => !pre)
                }}
                tooltipDefaultWidth={160}
                tooltipChangeWidth={160}
                tooltipTop={30}
                tooltipRight={0}
              >
                <SettingsItem
                  style={{
                    padding: '10px 20px',
                  }}
                  onClick={() => {
                    setDeleteAllInvitationsModal(true)
                  }}
                >
                  전부 삭제
                </SettingsItem>
              </IconTooltip>
            </TableItem>
          </TableHeaderWrapper>
          {invitationsList.length === 0 ? (
            <TableBodyWrapper>
              <TableBodyNot>No Invitations</TableBodyNot>
            </TableBodyWrapper>
          ) : windowWidth > 1024 ? (
            invitationsList.map((invitation) => {
              return (
                <TableBodyWrapper key={invitation.id}>
                  <TableItem $width={20}>
                    <PaperPlaneIconGray></PaperPlaneIconGray>
                  </TableItem>
                  <TableItem $width={20}>{invitation.invitationName}</TableItem>
                  <TableItem $width={20}>{invitation.guestName}</TableItem>
                  <TableItem $width={20}>{invitation.guestEmail}</TableItem>
                  <TableItem $width={20}>
                    {invitation.guestPhoneNumber}
                  </TableItem>
                  <TableItem $width={20}>{invitation.views}</TableItem>
                  <TableItem $width={20}>
                    {dateFormatFunction(new Date(invitation.createdAt))}
                  </TableItem>
                  <TableItem $width={20}>
                    {dateAndTimeFormatFunction(
                      new Date(invitation.lastSendTime)
                    )}
                  </TableItem>
                  <TableItem $width={2}>
                    <IconTooltip
                      icon={
                        <VerticalThreeDotsIcon
                          onClick={() => {
                            setSelectInvitation(invitation)
                            toggleInvitationsTooltipForItem(invitation.id)
                          }}
                        ></VerticalThreeDotsIcon>
                      }
                      visible={invitation.visibleTooltip}
                      handleClick={() => {
                        setSelectInvitation(invitation)
                        toggleInvitationsTooltipForItem(invitation.id)
                      }}
                      tooltipDefaultWidth={160}
                      tooltipChangeWidth={160}
                      tooltipTop={30}
                      tooltipRight={0}
                    >
                      <SettingsItem
                        style={{
                          padding: '10px 20px',
                        }}
                        onClick={() => {
                          setInvitationNameText(invitation.invitationName)
                          setGuestNameText(invitation.guestName)
                          setGuestPhoneNumberText(invitation.guestPhoneNumber)
                          setGuestEmailText(invitation.guestEmail)
                          setInvitationEmailTitleText(invitation.emailTitle)
                          setInvitationEmailContentText(invitation.emailContent)
                          setSelectInvitationId(invitation.id)
                          setViewEditNewInvitationByEmailSMSModal(true)
                        }}
                      >
                        보기/수정하기
                      </SettingsItem>
                      <SettingsItem
                        style={{
                          padding: '10px 20px',
                        }}
                        onClick={async () => {
                          const invitationSendNowResponse =
                            await invitationSendNow(oneGuide.id, invitation.id)

                          if (invitationSendNowResponse.status === 200) {
                            editInvitationLastSendTime(invitation.id, {
                              lastSendTime: new Date(),
                            })
                            show('Invitation sent', true)
                          }
                        }}
                      >
                        지금 발송하기
                      </SettingsItem>
                      <SettingsItem
                        style={{
                          padding: '10px 20px',
                        }}
                        onClick={() => {
                          console.log(invitation)
                          navigator.clipboard.writeText(
                            invitation.invitationShareUrl
                          )
                          show('복사 완료', true)
                        }}
                      >
                        링크 복사
                      </SettingsItem>
                      <SettingsItem
                        style={{
                          padding: '10px 20px',
                        }}
                        onClick={(e) => {
                          e.preventDefault()

                          setSelectInvitationId(invitation.id)
                          setDeleteInvitationModal(true)
                        }}
                      >
                        삭제하기
                      </SettingsItem>
                    </IconTooltip>
                  </TableItem>
                </TableBodyWrapper>
              )
            })
          ) : (
            <></>
          )}
        </TableInner>
        {windowWidth <= 1024 &&
          invitationsList.map((invitation) => {
            return (
              <InvitationCard key={invitation.id}>
                <InvitationCardTop>
                  <InvitationCardTopLeft>
                    <div
                      style={{
                        padding: '8px 0',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          marginRight: 15,
                        }}
                      >
                        <PaperPlaneIconFixed></PaperPlaneIconFixed>
                      </div>
                      {invitation.invitationName}
                    </div>
                  </InvitationCardTopLeft>
                  <InvitationCardTopRight>
                    <div
                      style={{
                        display: 'flex',
                        fontSize: 25,
                        justifyContent: 'flex-end',
                      }}
                    >
                      <PenIconFixed
                        onClick={() => {
                          setInvitationNameText(invitation.invitationName)
                          setGuestNameText(invitation.guestName)
                          setGuestPhoneNumberText(invitation.guestPhoneNumber)
                          setGuestEmailText(invitation.guestEmail)
                          setInvitationEmailTitleText(invitation.emailTitle)
                          setInvitationEmailContentText(invitation.emailContent)
                          setSelectInvitationId(invitation.id)
                          setViewEditNewInvitationByEmailSMSModal(true)
                        }}
                      ></PenIconFixed>
                      <div
                        style={{
                          marginLeft: 15,
                        }}
                      >
                        <CopyIcon
                          onClick={() => {
                            console.log(invitation)
                            navigator.clipboard.writeText(
                              invitation.invitationShareUrl
                            )
                            show('링크 복사 완료', true)
                          }}
                        ></CopyIcon>
                      </div>
                      <div
                        style={{
                          marginLeft: 15,
                        }}
                      >
                        <TrashIcon
                          onClick={(e) => {
                            e.preventDefault()

                            setSelectInvitationId(invitation.id)
                            setDeleteInvitationModal(true)
                          }}
                        ></TrashIcon>
                      </div>
                    </div>
                  </InvitationCardTopRight>
                </InvitationCardTop>
                <InvitationCardMiddle>
                  <InvitationCardMiddleLeft>
                    Last Send Time:{' '}
                    {dateAndTimeFormatFunction(
                      new Date(invitation.lastSendTime)
                    )}
                  </InvitationCardMiddleLeft>
                  <InvitationCardMiddleRight>
                    Views: {invitation.views}
                  </InvitationCardMiddleRight>
                </InvitationCardMiddle>
                <InvitationCardBottom>
                  <InvitationCardBottomInner>
                    {invitation.guestName}
                  </InvitationCardBottomInner>
                </InvitationCardBottom>
              </InvitationCard>
            )
          })} */}
      </InviteShareContainer>
      {deleteAllInvitationsModal && (
        <StandardModal
          titleText={'경고!'}
          modalBodyComponent={
            <div>삭제 후 복구되지 않습니다. 정말 삭제하시겠습니까?</div>
          }
          onRightBtnClick={async () => {
            showLoading()

            const deleteAllInvitationsResponse = await deleteAllInvitations(
              oneGuide.id
            )

            if (deleteAllInvitationsResponse.status === 200) {
              setInvitationsList([])
              setDeleteAllInvitationsModal(false)
            }

            hideLoading()
          }}
          onClose={() => {
            setDeleteAllInvitationsModal(false)
          }}
          rightBtnText={'삭제'}
        ></StandardModal>
      )}
      {addNewInvitationByEmailSMSModal && (
        <NormalModal
          titleText={'이메일로 안내문 링크 보내기'}
          modalBodyComponent={
            <div>
              <TitleBigDiv>안내문 디테일</TitleBigDiv>
              <TitleSubDiv>안내문 이름 *</TitleSubDiv>
              <TextInput
                backgroundColor="#fff"
                value={invitationNameText}
                onChange={(e) => {
                  setInvitationNameText(e.target.value)
                }}
              ></TextInput>
              <div
                style={{
                  height: 22,
                }}
              ></div>
              <TitleBigDiv>게스트 상세정보</TitleBigDiv>
              <TitleSubDiv>이름 *</TitleSubDiv>
              <TextInput
                backgroundColor="#fff"
                value={guestNameText}
                onChange={(e) => {
                  setGuestNameText(e.target.value)
                }}
              ></TextInput>
              <div
                style={{
                  height: 22,
                }}
              ></div>
              <TitleSubDiv>전화번호</TitleSubDiv>
              <TextInput
                backgroundColor="#fff"
                placeholder="- 없이 작성해주세요"
                value={guestPhoneNumberText}
                onChange={(e) => {
                  setGuestPhoneNumberText(e.target.value)
                }}
              ></TextInput>
              <div
                style={{
                  height: 22,
                }}
              ></div>
              <TitleSubDiv>이메일 주소 *</TitleSubDiv>
              <TextInput
                backgroundColor="#fff"
                value={guestEmailText}
                onChange={(e) => {
                  setGuestEmailText(e.target.value)
                }}
              ></TextInput>
              <div
                style={{
                  height: 22,
                }}
              ></div>
              <TitleBigDiv>이메일 내용</TitleBigDiv>
              <TitleSubDiv>제목 *</TitleSubDiv>
              <TextInput
                backgroundColor="#fff"
                value={invitationEmailTitleText}
                onChange={(e) => {
                  setInvitationEmailTitleText(e.target.value)
                }}
              ></TextInput>
              <div
                style={{
                  height: 22,
                }}
              ></div>
              <TitleSubDiv>내용 *</TitleSubDiv>
              <TextArea
                backgroundColor="#fff"
                value={invitationEmailContentText}
                onChange={(e) => {
                  setInvitationEmailContentText(e.target.value)
                }}
              ></TextArea>
              <div
                style={{
                  height: 22,
                }}
              ></div>
              <div
                style={{
                  height: 22,
                }}
              ></div>
              <div
                style={{
                  height: 22,
                }}
              ></div>
              <div
                style={{
                  height: 22,
                }}
              ></div>
              <div
                style={{
                  height: 22,
                }}
              ></div>
            </div>
          }
          onRightBtnClick={async () => {
            if (
              invitationNameText !== '' &&
              guestNameText !== '' &&
              guestEmailText !== '' &&
              invitationEmailTitleText !== '' &&
              invitationEmailContentText !== '' &&
              emailCheck.test(guestEmailText)
            ) {
              showLoading()

              const postInvitationResponse = await postInvitation({
                invitationName: invitationNameText,
                guestName: guestNameText,
                guestPhoneNumber:
                  guestPhoneNumberText !== '' || guestPhoneNumberText !== null
                    ? guestPhoneNumberText
                    : '',
                guestEmail: guestEmailText,
                emailTitle: invitationEmailTitleText,
                emailContent: invitationEmailContentText,
                guideId: oneGuide.id,
              })

              console.log(postInvitationResponse)
              if (postInvitationResponse.status === 201) {
                setInvitationsList([
                  ...invitationsList,
                  postInvitationResponse.data.newInvitation,
                ])
                setInvitationNameText('')
                setGuestNameText('')
                setGuestPhoneNumberText('')
                setGuestEmailText('')
                setInvitationEmailTitleText('')
                setInvitationEmailContentText('')
                setAddNewInvitationByEmailSMSModal(false)
              }

              hideLoading()
            } else {
              show('모든 부분을 확인하고 채워주세요.', false)
            }
          }}
          onClose={() => {
            setAddNewInvitationByEmailSMSModal(false)
          }}
          rightBtnText={'발송하기'}
          questionSection={
            <p>
              Create a new invitation without sending any messages. A unique
              link will be generated which you can then share with people using
              your own communication method such as Airbnb, iMessage or
              WhatsApp.
            </p>
          }
          questionDefaultWidth={500}
          questionChangeWidth={180}
        ></NormalModal>
      )}
      {viewEditNewInvitationByEmailSMSModal && (
        <NormalModal
          titleText={'View/Edit Invitation'}
          modalBodyComponent={
            <div>
              <TitleBigDiv>Invitation Details</TitleBigDiv>
              <TitleSubDiv>Invitation Name *</TitleSubDiv>
              <TextInput
                backgroundColor="#fff"
                value={invitationNameText}
                onChange={(e) => {
                  setInvitationNameText(e.target.value)
                }}
              ></TextInput>
              <div
                style={{
                  height: 22,
                }}
              ></div>
              <TitleBigDiv>Guest Details</TitleBigDiv>
              <TitleSubDiv>Name *</TitleSubDiv>
              <TextInput
                backgroundColor="#fff"
                value={guestNameText}
                onChange={(e) => {
                  setGuestNameText(e.target.value)
                }}
              ></TextInput>
              <div
                style={{
                  height: 22,
                }}
              ></div>
              <TitleSubDiv>Phone Number *</TitleSubDiv>
              <TextInput
                backgroundColor="#fff"
                value={guestPhoneNumberText}
                onChange={(e) => {
                  setGuestPhoneNumberText(e.target.value)
                }}
              ></TextInput>
              <div
                style={{
                  height: 22,
                }}
              ></div>
              <TitleSubDiv>Email *</TitleSubDiv>
              <TextInput
                backgroundColor="#fff"
                value={guestEmailText}
                onChange={(e) => {
                  setGuestEmailText(e.target.value)
                }}
              ></TextInput>
              <div
                style={{
                  height: 22,
                }}
              ></div>
              <TitleBigDiv>Email Content</TitleBigDiv>
              <TitleSubDiv>Title *</TitleSubDiv>
              <TextInput
                backgroundColor="#fff"
                value={invitationEmailTitleText}
                onChange={(e) => {
                  setInvitationEmailTitleText(e.target.value)
                }}
              ></TextInput>
              <div
                style={{
                  height: 22,
                }}
              ></div>
              <TitleSubDiv>Content *</TitleSubDiv>
              <TextArea
                backgroundColor="#fff"
                value={invitationEmailContentText}
                onChange={(e) => {
                  setInvitationEmailContentText(e.target.value)
                }}
              ></TextArea>
              <div
                style={{
                  height: 22,
                }}
              ></div>
              <div
                style={{
                  height: 22,
                }}
              ></div>
              <div
                style={{
                  height: 22,
                }}
              ></div>
              <div
                style={{
                  height: 22,
                }}
              ></div>
              <div
                style={{
                  height: 22,
                }}
              ></div>
            </div>
          }
          onRightBtnClick={async () => {
            showLoading()

            const putInvitationResponse = await putInvitation(
              selectInvitationId,
              {
                invitationName: invitationNameText,
                guestName: guestNameText,
                guestPhoneNumber: guestPhoneNumberText,
                guestEmail: guestEmailText,
                emailTitle: invitationEmailTitleText,
                emailContent: invitationEmailContentText,
              }
            )

            if (putInvitationResponse.status === 200) {
              editInvitation(selectInvitationId, {
                invitationName: invitationNameText,
                guestName: guestNameText,
                guestPhoneNumber: guestPhoneNumberText,
                guestEmail: guestEmailText,
                emailTitle: invitationEmailTitleText,
                emailContent: invitationEmailContentText,
              })
              setInvitationNameText('')
              setGuestNameText('')
              setGuestPhoneNumberText('')
              setGuestEmailText('')
              setInvitationEmailTitleText('')
              setInvitationEmailContentText('')
              setViewEditNewInvitationByEmailSMSModal(false)
            }

            hideLoading()
          }}
          onClose={() => {
            setInvitationNameText('')
            setGuestNameText('')
            setGuestPhoneNumberText('')
            setGuestEmailText('')
            setInvitationEmailTitleText('')
            setInvitationEmailContentText('')
            setViewEditNewInvitationByEmailSMSModal(false)
          }}
          rightBtnText={'Edit'}
          questionSection={
            <p>
              Create a new invitation without sending any messages. A unique
              link will be generated which you can then share with people using
              your own communication method such as Airbnb, iMessage or
              WhatsApp.
            </p>
          }
          questionDefaultWidth={500}
          questionChangeWidth={180}
        ></NormalModal>
      )}
      {deleteInvitationModal && (
        <StandardModal
          titleText={'삭제하십니까?'}
          modalBodyComponent={
            <div>삭제 후 복구 되지 않습니다. 정말 삭제하시겠습니까?</div>
          }
          onRightBtnClick={() => {
            showLoading()

            const deleteInvitationResponse =
              deleteInvitation(selectInvitationId)

            if (deleteInvitationResponse.status === 200) {
              removeInvitationById(selectInvitationId)
              setDeleteInvitationModal(false)
            }

            hideLoading()
          }}
          onClose={() => {
            setDeleteInvitationModal(false)
          }}
          rightBtnText={'삭제'}
        ></StandardModal>
      )}
    </>
  )
}

export default MainGuidesGuidesShareTab

const InviteShareContainer = styled.div`
  padding: 20px 10%;
  flex-direction: column;
  display: flex;
  flex: 1;
  flex-basis: auto;
  box-sizing: border-box;
  min-width: 0;
  overflow-y: auto;
  height: calc(100vh - 315px);

  @media (max-width: 1024px) {
    padding: 10px 5px;
  }
`

const QuickShareLinkTile = styled.div`
  flex: 0 1 auto;
  background-color: #fff;
  margin-bottom: 24px;
  border-radius: 8px;
  flex-direction: column;
  display: flex;
  box-sizing: border-box;
  min-width: 0;
`

const QuickShareLinkTileHeader = styled.div`
  height: 60px;
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  font-size: 1.125rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
  flex-shrink: 0;

  @media (max-width: 1024px) {
    font-size: 0.825rem;
    padding: 15px;
    height: 50px !important;
  }
`

const QuickShareLinkTileBody = styled.div`
  padding: 30px;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    padding: 15px;
  }
`

const QuickShareLinkTilePart1 = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;
`

const CopyLink = styled.div`
  color: #17293c;
  background-color: #f8f8f8;
  border-radius: 8px;
  width: 62.5%;
  height: 70px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  float: left;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    font-size: 12px;
  }
`

const BottomSectionHeaderFrame = styled.div`
  background-color: #fff;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    border-radius: 8px;
  }
`

const BottomSectionHeaderTitle = styled.div`
  margin: 30px 20px 0;
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  font-size: 24px;
  color: #244556;
  align-items: center;
  display: flex;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    font-size: 18px;
  }
`

const BottomSectionHeaderBottom = styled.div`
  padding: 20px;
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  border-bottom: 1px solid #e1e1e1;
  display: flex;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    border-width: 0px;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    padding: 15px;
  }
`

const TableInner = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  font-size: 14px;
  color: #606266;
  background-color: #fff;

  @media (max-width: 1024px) {
    display: none;
  }
`

const TableHeaderWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;

  @media (max-width: 1024px) {
    display: none;
  }
`

const TableBodyWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;

  @media (max-width: 1024px) {
    display: none;
  }
`

const InvitationCard = styled.div`
  color: #244556;
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  margin-top: 20px;
  background: white;
  border-radius: 8px;
  padding: 15px;
`

const InvitationCardTop = styled.div`
  box-sizing: border-box;
  display: flex;
`

const InvitationCardTopLeft = styled.div`
  width: 58.33333%;
  float: left;
  box-sizing: border-box;
`

const InvitationCardTopRight = styled.div`
  width: 41.66667%;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
`

const InvitationCardMiddle = styled.div`
  box-sizing: border-box;
  display: flex;
  padding: 10px 0;
`

const InvitationCardMiddleLeft = styled.div`
  width: 58.33333%;
  float: left;
  box-sizing: border-box;
`

const InvitationCardMiddleRight = styled.div`
  width: 41.66667%;
  float: left;
  box-sizing: border-box;
`

const InvitationCardBottom = styled.div`
  box-sizing: border-box;
  display: flex;
  margin: 8px 0;
`

const InvitationCardBottomInner = styled.div`
  width: 58.33333%;
  float: left;
  box-sizing: border-box;
`

const TableBodyNot = styled.div`
  min-height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #606266;
`
