import { v4 as uuidv4 } from 'uuid'

// 세션 ID 생성 및 저장
export const generateSessionId = () => {
  let sessionId = localStorage.getItem('sessionId')
  if (!sessionId) {
    sessionId = uuidv4()
    localStorage.setItem('sessionId', sessionId)
  }
  return sessionId
}
