import styled from 'styled-components'
import { MainGuidesContainer } from '../../design_system/components/main_guides_container/MainGuidesContainer'
import {
  ArrowLeftIcon,
  QuestionMarkInCircle,
} from '../../design_system/icons/icons'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { main_guides_guides_inner_router_url } from '../../communication_system/inner_router_url/inner_router_url'
import IconTooltip from '../../design_system/components/icon_tooltip/IconTooltip'
import DownloadsTile from '../../design_system/components/downloads_tile/DownloadsTile'

const MainGuidesDownloadsPage = () => {
  console.log('MainGuidesPage is being rendered')
  let navigate = useNavigate()

  const [headerVisible, setHeaderVisible] = useState(false)

  const handleHeaderQClick = () => setHeaderVisible(!headerVisible)

  const downloads_data = [
    {
      id: 0,
      title: 'Get Quick Share Links to your Guides',
    },
    {
      id: 1,
      title: 'Get Invitations for your Guides',
    },
  ]

  return (
    <MainGuidesContainer>
      <GuidesHeader>
        <GuidesHeaderTitle>
          <div
            style={{
              marginRight: 12,
            }}
          >
            <ArrowLeftIcon
              onClick={() => {
                navigate(main_guides_guides_inner_router_url)
              }}
            ></ArrowLeftIcon>
          </div>
          <div>Downloads</div>
          <div
            style={{
              marginLeft: 12,
              marginBottom: 10,
            }}
          >
            <IconTooltip
              icon={
                <QuestionMarkInCircle
                  onClick={handleHeaderQClick}
                ></QuestionMarkInCircle>
              }
              handleClick={handleHeaderQClick}
              visible={headerVisible}
              tooltipTop={20}
              tooltipLeft={30}
              tooltipDefaultWidth={140}
              tooltipChangeWidth={140}
            >
              asfsa
            </IconTooltip>
          </div>
        </GuidesHeaderTitle>
      </GuidesHeader>
      <GuidesBody>
        {downloads_data.map((download) => {
          return (
            <div
              key={download.id}
              style={{
                marginTop: 30,
              }}
            >
              <DownloadsTile download={download}></DownloadsTile>
            </div>
          )
        })}
      </GuidesBody>
    </MainGuidesContainer>
  )
}

export default MainGuidesDownloadsPage

const GuidesHeader = styled.div`
  padding-left: 10%;
  padding-right: 10%;
  background-color: #fff;
  height: 100px;
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  align-items: center;
  display: flex;
  box-sizing: border-box;
  justify-content: left;

  @media (max-width: 1024px) {
    padding-left: 15px;
    padding-right: 15px;
    height: 70px;
  }
`

const GuidesHeaderTitle = styled.div`
  font-size: 2.25rem;
  width: 50%;
  float: left;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  @media only screen and (min-width: 992px) {
    width: 33.33333%;
  }
  @media (max-width: 1024px) {
    font-size: 1.5rem;
    line-height: 3.75rem;
  }
`

const GuidesBody = styled.div`
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 24px;
  flex: 1 0 auto;
  flex-direction: column;
  display: flex;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`
