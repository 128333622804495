import styled from 'styled-components'
import {
  c_244556,
  c_3ed9cc,
  c_b49275,
  c_ffffff,
} from '../../design_system/colors'
import { useEffect, useState } from 'react'
import { emailCheck } from '../../data_system/data_function/email_check_function'
import CheckBox from '../../design_system/components/check_box/CheckBox'
import { Footer } from '../../design_system/components/footer/Footer'
import { HeaderFixedBtn } from '../../design_system/components/btn/pc/header_fixed_btn/HeaderFixedBtn'
import PcSubHeader from '../../design_system/components/header/pc_sub_header/PcSubHeader'
import { Section } from '../../design_system/components/section/Section'
import { Container } from '../../design_system/components/container/Container'
import { Body } from '../../design_system/components/body/Body'
import { TitleH1 } from '../../design_system/components/title_h1/TitleH1'
import { TextDiv } from '../../design_system/components/text_div/TextDiv'
import { TextInput } from '../../design_system/components/text_input/TextInput'
import { PasswordInput } from '../../design_system/components/password_input/PasswordInput'
import { OpacityBtn } from '../../design_system/components/btn/pc/opacity_btn/OpacityBtn'
import {
  login_inner_router_url,
  main_guides_inner_router_url,
  privacy_policy_inner_router_url,
  s_guides_inner_router_url,
  terms_and_conditions_inner_router_url,
} from '../../communication_system/inner_router_url/inner_router_url'
import { useAuth } from '../../data_system/context/auth_context/AuthContext'
import { useNavigate } from 'react-router-dom'
import { useSelectHeader } from '../../data_system/context/select_header_index_context/SelectHeaderIndexContext'
import { Logo2x } from '../../design_system/icons/icons'
import { usePopup } from '../../data_system/context/popup_context/PopupContext'

const SignUpPage = () => {
  const { show } = usePopup()

  const [passwordVisible, setPasswordVisible] = useState(false)
  const [createAccountBtnDisabled, setCreateAccountBtnDisabled] = useState(true)

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [agree, setAgree] = useState(false)

  let navigate = useNavigate()
  const { handleSignUp } = useAuth()

  const { setHeaderIndex } = useSelectHeader()

  const totalHandleSignUp = async (email, name, password) => {
    const response = await handleSignUp(email, name, password)

    if (parseInt(response.status) === 201) {
      setHeaderIndex(0)
      navigate(main_guides_inner_router_url + s_guides_inner_router_url)
    } else if (parseInt(response.status) === 400) {
      show('이미 존재하는 이메일입니다.', false)
    }
  }

  useEffect(() => {
    if (
      name.length > 0 &&
      emailCheck.test(email) &&
      password.length > 0 &&
      agree
    ) {
      setCreateAccountBtnDisabled(false)
    } else {
      setCreateAccountBtnDisabled(true)
    }
  }, [name, email, password, agree])

  return (
    <>
      <PcSubHeader>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => {
            const baseUrl = window.location.origin
            const url = `${baseUrl}`
            window.open(url, '_self')
          }}
        >
          <Logo2x></Logo2x>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <HeaderFixedBtn
            href={login_inner_router_url}
            backgroundColor={c_b49275}
            borderColor={c_b49275}
            fontColor={c_ffffff}
          >
            로그인
          </HeaderFixedBtn>
        </div>
      </PcSubHeader>
      <Section>
        <Container>
          <Body>
            <TitleH1>우리 숙소의 온라인 안내문을 무료로 만들어 보세요</TitleH1>
            <TextDiv>이름*</TextDiv>
            <TextInput
              type="text"
              autoComplete="off"
              value={name}
              onChange={(e) => {
                setName(e.target.value)
              }}
            ></TextInput>
            <div
              style={{
                marginTop: 15,
              }}
            >
              <TextDiv>이메일*</TextDiv>
            </div>
            <TextInput
              type="text"
              autoComplete="off"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value)
              }}
            ></TextInput>
            <div
              style={{
                marginTop: 15,
              }}
            >
              <TextDiv>비밀번호*</TextDiv>
            </div>
            <PasswordInput
              password={password}
              setPassword={setPassword}
              passwordVisible={passwordVisible}
              setPasswordVisible={setPasswordVisible}
            ></PasswordInput>
            <CreateBottomSection>
              <OpacityBtn
                disabled={createAccountBtnDisabled}
                backgroundColor={c_b49275}
                fontColor={c_ffffff}
                onClick={() => {
                  totalHandleSignUp(email, password, name)
                }}
              >
                계정 생성하기
              </OpacityBtn>
              <CheckBoxSection>
                <CheckBox
                  onClick={() => {
                    setAgree(!agree)
                  }}
                  checked={agree}
                ></CheckBox>
                <CheckBoxSectionRightSide>
                  만 14세 이상입니다.
                </CheckBoxSectionRightSide>
              </CheckBoxSection>
            </CreateBottomSection>
            <CheckTermsConditionsPrivacy>
              계정을 생성함으로써 귀하는 당사의 사이트{' '}
              <LinkText
                // href={terms_and_conditions_inner_router_url}
                // target="_blank"
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  const baseUrl = window.location.origin
                  const path = `${terms_and_conditions_inner_router_url}`
                  const url = `${baseUrl}${path}`

                  window.open(url, '_blank')
                }}
              >
                이용약관
              </LinkText>{' '}
              및{' '}
              <LinkText
                // href={privacy_policy_inner_router_url}
                // target="_blank"
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  const baseUrl = window.location.origin
                  const path = `${privacy_policy_inner_router_url}`
                  const url = `${baseUrl}${path}`

                  window.open(url, '_blank')
                }}
              >
                개인정보 보호정책
              </LinkText>
              에 동의합니다.
            </CheckTermsConditionsPrivacy>
          </Body>
        </Container>
        <Footer></Footer>
      </Section>
    </>
  )
}

export default SignUpPage

const CreateBottomSection = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 30px 0;

  @media (max-width: 1024px) {
    padding-top: 15px;
  }

  @media (max-width: 650px) {
    flex-wrap: wrap;
    margin-top: 25px;
  }
`

const CheckBoxSection = styled.label`
  display: flex;
  align-items: center;
  margin-left: 20px;
  padding: 0;
  font-size: 0.875rem;
  line-height: 1.3rem;
  margin-right: 0;
  white-space: nowrap;
  box-sizing: border-box;

  @media (max-width: 650px) {
    font-size: 0.85rem;
    flex-basis: 100%;
    margin: 35px 0;
  }
`

const CheckBoxSectionRightSide = styled.span`
  font-size: 1rem;
  color: #244556;
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  padding-left: 10px;
  line-height: 19px;
  box-sizing: border-box;
  font-weight: 500;
  white-space: normal;
  word-break: normal;
`

const CheckTermsConditionsPrivacy = styled.div`
  font-size: 1.125rem;
  line-height: 1.5;
  text-align: left;
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  color: #244556;

  @media (max-width: 650px) {
    font-size: 0.85rem;
    padding-bottom: 20px;
  }
`

const LinkText = styled.a`
  text-decoration: underline;
  color: #eba55d;
`
