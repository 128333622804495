import styled from 'styled-components'
import IconTextCircleBtn from '../../../design_system/components/btn/pc/icon_text_circle_btn/IconTextCircleBtn'
import {
  AirportShuttleSvg,
  ApartmentSvg,
  ArrowCircleUpSvg,
  ArrowForwardSvg,
  AssistantDirectionSvg,
  AssistantNavigationSvg,
  BeachAccessSvg,
  BedroomBabySvg,
  BedtimeSvg,
  CallSvg,
  CameraIcon,
  CameraIconFixed,
  CampaignSvg,
  CampingSvg,
  CancelSvg,
  ChatBubbleSvg,
  ChatSvg,
  CheckCircleSvg,
  CheckInOutSvg,
  ChecklistSvg,
  CheerSvg,
  CleaningServicesSvg,
  ComputerSvg,
  ConciergeSvg,
  CookingSvg,
  CreditCardHeartSvg,
  CribSvg,
  DeleteCategoryIconSvg,
  DiningSvg,
  DirectionsBusSvg,
  DistanceSvg,
  DoorFrontSvg,
  DownhillSkiingSvg,
  EditSvg,
  EnhancedEncryptionSvg,
  ExclamationSvg,
  FactCheckSvg,
  FamilyRestroomSvg,
  FavoriteSvg,
  FireplaceSvg,
  FitnessCenterSvg,
  HailSvg,
  HandGestureSvg,
  HandshakeSvg,
  HelpCenterSvg,
  HelpSvg,
  HikingSvg,
  HomeHealthSvg,
  HomeSvg,
  HouseSvg,
  ImportContactsSvg,
  InfoSvg,
  KeySvg,
  LightbulbSvg,
  LiquorSvg,
  LocalCafeSvg,
  LocalDiningSvg,
  LocalHospitalSvg,
  LocalParkingSvg,
  LocationIconFixed,
  LocationOnSvg,
  LockSvg,
  LuggageSvg,
  MailSvg,
  MapSvg,
  TrashIconFixed,
  VerticalThreeDotsIcon,
  MenuBookSvg,
  NaturePeopleSvg,
  NotificationsSvg,
  ParkSvg,
  PasswordSvg,
  PedalBikeSvg,
  PermPhoneMsgSvg,
  PhoneInTalkSvg,
  PhotoCameraSvg,
  PushPinSvg,
  RecommendSvg,
  RecyclingSvg,
  RestaurantSvg,
  ReviewsSvg,
  ScheduleSvg,
  SentimentSadSvg,
  SentimentSatisfiedSvg,
  SettingsSvg,
  ShoppingBagSvg,
  ShoppingCartSvg,
  ShoppingmodeSvg,
  ShowerSvg,
  SignpostSvg,
  SingleBedSvg,
  SkilletSvg,
  SmartphoneSvg,
  StarSvg,
  StorefrontSvg,
  SupportAgentSvg,
  ThumbUpSvg,
  TravelSvg,
  VolunteerActivismSvg,
  WavingHandSvg,
  WifiPasswordSvg,
  WifiSvg,
  Woman_2Svg,
  airport_shuttle_image_s3,
  apartment_image_s3,
  arrow_circle_up_image_s3,
  arrow_forward_image_s3,
  assistant_direction_image_s3,
  assistant_navigation_image_s3,
  beach_access_image_s3,
  bedroom_baby_image_s3,
  bedtime_image_s3,
  call_image_s3,
  FeedSvg,
  campaign_image_s3,
  camping_image_s3,
  cancel_image_s3,
  chat_bubble_image_s3,
  chat_image_s3,
  check_circle_image_s3,
  check_in_out_image_s3,
  checklist_image_s3,
  cheer_image_s3,
  cleaning_services_image_s3,
  computer_image_s3,
  concierge_image_s3,
  cooking_image_s3,
  credit_card_heart_image_s3,
  crib_image_s3,
  delete_category_icon_image_s3,
  dining_image_s3,
  directions_bus_image_s3,
  distance_image_s3,
  door_front_image_s3,
  downhill_skiing_image_s3,
  edit_image_s3,
  enhanced_encryption_image_s3,
  FestivalSvg,
  exclamation_image_s3,
  fact_check_image_s3,
  family_restroom_image_s3,
  favorite_image_s3,
  feed_image_s3,
  festival_image_s3,
  fireplace_image_s3,
  fitness_center_image_s3,
  hail_image_s3,
  hand_gesture_image_s3,
  handshake_image_s3,
  help_center_image_s3,
  help_image_s3,
  hiking_image_s3,
  home_health_image_s3,
  home_image_s3,
  house_image_s3,
  import_contacts_image_s3,
  info_image_s3,
  key_image_s3,
  lightbulb_image_s3,
  liquor_image_s3,
  local_cafe_image_s3,
  local_dining_image_s3,
  local_hospital_image_s3,
  local_parking_image_s3,
  location_on_image_s3,
  lock_image_s3,
  luggage_image_s3,
  mail_image_s3,
  map_image_s3,
  menu_book_image_s3,
  nature_people_image_s3,
  notifications_image_s3,
  park_image_s3,
  password_image_s3,
  pedal_bike_image_s3,
  perm_phone_msg_image_s3,
  phone_in_talk_image_s3,
  photo_camera_image_s3,
  push_pin_image_s3,
  recommend_image_s3,
  recycling_image_s3,
  restaurant_image_s3,
  reviews_image_s3,
  schedule_image_s3,
  sentiment_sad_image_s3,
  sentiment_satisfied_image_s3,
  settings_image_s3,
  shopping_bag_image_s3,
  shopping_cart_image_s3,
  shoppingmode_image_s3,
  shower_image_s3,
  signpost_image_s3,
  single_bed_image_s3,
  skillet_image_s3,
  smartphone_image_s3,
  star_image_s3,
  storefront_image_s3,
  support_agent_image_s3,
  thumb_up_image_s3,
  travel_image_s3,
  volunteer_activism_image_s3,
  waving_hand_image_s3,
  wifi_password_image_s3,
  wifi_image_s3,
  woman_2_image_s3,
  QuestionMarkInCircle,
} from '../../../design_system/icons/icons'
import { useEffect, useRef, useState } from 'react'
import NormalModal from '../../../design_system/components/normal_modal/NormalModal'
import { TitleBigDiv } from '../../../design_system/components/title_big_div/TitleBigDiv'
import { TextInput } from '../../../design_system/components/text_input/TextInput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronDown,
  faChevronUp,
  faLevelDownAlt,
  faCamera,
} from '@fortawesome/free-solid-svg-icons'
import { generate_UUID } from '../../../data_system/data_function/generate_UUID'
import IconTooltip from '../../../design_system/components/icon_tooltip/IconTooltip'
import { SettingsItem } from '../../../design_system/components/settings_item/SettingsItem'
import { Dropdown } from '../../../design_system/components/dropdown/Dropdown'
import StandardModal from '../../../design_system/components/standard_modal/StandardModal'
import {
  deleteCategoryApi,
  deleteSubcategoryApi,
  deleteTopicApi,
  deleteImage,
  postCategory,
  postSubcategory,
  postTopic,
  putCategory,
  putCategoryOrder,
  putSubcategory,
  putSubcategoryOrder,
  putTopic,
  putTopicOrder,
  uploadImage,
} from '../../../communication_system/axios_apis/axios_apis'
import { useLoading } from '../../../data_system/context/loading_context/LoadingContext'
import { generate_static_map_url } from '../../../data_system/function/generate_static_map_url'
import { usePopup } from '../../../data_system/context/popup_context/PopupContext'
import { generate_and_download_QR } from '../../../data_system/data_function/generate_and_download_QR'
import { convertToRaw, convertFromRaw, EditorState, Modifier } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { isMatchingDefaultGuideImageURL } from '../../../data_system/data_function/is_matching_default_guide_image_url'

const MainGuidesGuidesContentTab = ({
  guide,
  categories,
  setCategories,
  addCategoryModalVisible,
  setAddCategoryModalVisible,
  selectedCategoryIcon,
  setSelectedCategoryIcon,
  setAddLocationModalVisible,
  setEditLocationModalVisible,
  choosePosition,
  setChoosePosition,
  position,
  setPosition,
  topicPosition,
  setTopicPosition,
  mapWidth,
  selectedMapPinIcon,
  setSelectedMapPinIcon,
  address,
  setAddress,
}) => {
  const { showLoading, hideLoading } = useLoading()

  const { show } = usePopup()

  //category

  //category data
  const [openCategories, setOpenCategories] = useState({})
  const [categoryTitleText, setCategoryTitleText] = useState('')
  const [category, setCategory] = useState(null)

  //category modal
  const [editCategoryModalVisible, setEditCategoryModalVisible] =
    useState(false)
  const [deleteCategoryModalVisible, setDeleteCategoryModalVisible] =
    useState(false)

  //etc
  const [linksAndQRModalVisible, setLinksAndQRModalVisible] = useState(false)

  //subcategory

  //subcategory data
  const [openSubcategories, setOpenSubcategories] = useState({})
  const [subcategoryTitleText, setSubcategoryTitleText] = useState('')
  const [subcategory, setSubcategory] = useState(null)

  //subcategory modal
  const [addSubcategoryModalVisible, setAddSubcategoryModalVisible] =
    useState(false)
  const [editSubcategoryModalVisible, setEditSubcategoryModalVisible] =
    useState(false)
  const [deleteSubcategoryModalVisible, setDeleteSubcategoryModalVisible] =
    useState(false)

  //topic

  //topic data
  const [topicTitleText, setTopicTitleText] = useState('')
  const [topic, setTopic] = useState(null)
  const [imgFile, setImgFile] = useState(null)
  const [imgUrlText, setImgUrlText] = useState('')
  const [uploadImgUrl, setUploadImgUrl] = useState('')

  //topic modal
  const [addTopicModalVisible, setAddTopicModalVisible] = useState(false)
  const [editTopicModalVisible, setEditTopicModalVisible] = useState(false)
  const [deleteTopicModalVisible, setDeleteTopicModalVisible] = useState(false)
  const [addAddMediaModalVisible, setAddAddMediaModalVisible] = useState(false)
  const [addChangeMediaModalVisible, setAddChangeMediaModalVisible] =
    useState(false)
  const [editAddMediaModalVisible, setEditAddMediaModalVisible] =
    useState(false)
  const [editChangeMediaModalVisible, setEditChangeMediaModalVisible] =
    useState(false)
  const [deleteAddMediaModalVisible, setDeleteAddMediaModalVisible] =
    useState(false)
  const [deleteEditMediaModalVisible, setDeleteEditMediaModalVisible] =
    useState(false)
  const [deleteAddLocationModalVisible, setDeleteAddLocationModalVisible] =
    useState(false)
  const [deleteEditLocationModalVisible, setDeleteEditLocationModalVisible] =
    useState(false)

  const [selectedGuideIndex, setSelectedGuideIndex] = useState(0)

  const [showSelectCategoryIconPopup, setShowSelectCategoryIconPopup] =
    useState(false)

  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [isComposing, setIsComposing] = useState(false)

  const handleEditorChange = (state) => {
    // console.log(state)
    if (!isComposing) {
      setEditorState(state)
    }
  }

  const handleCompositionStart = () => {
    setIsComposing(true)
  }

  const handleCompositionEnd = () => {
    setIsComposing(false)
    // Force update the editor state to ensure compositions are rendered correctly.
    const currentContent = editorState.getCurrentContent()
    const selection = editorState.getSelection()
    const newText = ' ' // Temporary space to force refresh
    const newContent = Modifier.replaceText(currentContent, selection, newText)
    const newEditorState = EditorState.push(
      editorState,
      newContent,
      'insert-characters'
    )
    setEditorState(EditorState.forceSelection(newEditorState, selection))
  }

  function imageBlockRenderer(contentBlock) {
    console.log(contentBlock)
    const type = contentBlock.getType()

    // Convert image type to mediaComponent
    if (type === 'atomic') {
      return {
        component: MediaComponent,
        editable: false,
        props: {
          foo: 'bar',
        },
      }
    }
  }

  // 이미지가 입력될 경우 img 태그로 변환
  function MediaComponent({ block, contentState, blockProps }) {
    const data = contentState.getEntity(block.getEntityAt(0)).getData()
    const emptyHtml = ' '
    return (
      <div>
        {emptyHtml}
        <img
          src={data.src}
          alt={data.alt || ''}
          style={{ height: data.height || 'auto', width: data.width || 'auto' }}
        />
      </div>
    )
  }

  const fileInputRef = useRef(null)

  const toggleCategory = (categoryId) => {
    setOpenCategories((prev) => ({
      ...prev,
      [categoryId]: !prev[categoryId],
    }))
  }

  const toggleSubcategory = (subcategoryId) => {
    setOpenSubcategories((prev) => ({
      ...prev,
      [subcategoryId]: !prev[subcategoryId],
    }))
  }

  const addCategory = (title, iconUrl, newCategoryId, order) => {
    const newCategory = {
      id: newCategoryId,
      title: title,
      iconUrl: iconUrl,
      order: order,
      subcategories: [],
    }
    setCategories([...categories, newCategory])
  }

  const editCategory = (categoryId, newTitle) => {
    const updatedCategories = categories.map((category) => {
      if (category.id === categoryId) {
        return {
          ...category,
          title: newTitle,
        }
      }
      return category
    })
    setCategories(updatedCategories)
  }

  const addSubcategory = (categoryId, title, newSubcategoryId, order) => {
    const updatedCategories = categories.map((category) => {
      if (category.id === categoryId) {
        const newSubcategory = {
          id: newSubcategoryId,
          title: title,
          order: order,
          topics: [],
        }
        return {
          ...category,
          subcategories: [...category.subcategories, newSubcategory],
        }
      }
      return category
    })
    setCategories(updatedCategories)
  }

  const editSubcategory = (categoryId, subcategoryId, newTitle) => {
    const updatedCategories = categories.map((category) => {
      if (category.id === categoryId) {
        const updatedSubcategories = category.subcategories.map(
          (subcategory) => {
            if (subcategory.id === subcategoryId) {
              return {
                ...subcategory,
                title: newTitle,
              }
            }
            return subcategory
          }
        )
        return {
          ...category,
          subcategories: updatedSubcategories,
        }
      }
      return category
    })
    setCategories(updatedCategories)
  }

  const addTopic = (
    categoryId,
    subcategoryId,
    topicTitle,
    topicContent,
    topicImgUrl,
    topicPosition,
    newTopicId,
    mapPinIconUrl,
    order,
    address
  ) => {
    const updatedCategories = categories.map((category) => {
      if (category.id === categoryId) {
        const updatedSubcategories = category.subcategories.map(
          (subcategory) => {
            if (subcategory.id === subcategoryId) {
              const newTopic = {
                id: newTopicId,
                title: topicTitle,
                content: topicContent,
                menuVisible: false,
                imgUrl: topicImgUrl,
                latitude: topicPosition.latitude,
                longitude: topicPosition.longitude,
                mapPinIconUrl: mapPinIconUrl,
                order: order,
                address: address,
              }
              return {
                ...subcategory,
                topics: [...subcategory.topics, newTopic],
              }
            }
            return subcategory
          }
        )
        return {
          ...category,
          subcategories: updatedSubcategories,
        }
      }
      return category
    })
    setCategories(updatedCategories)
  }

  const editTopic = (
    categoryId,
    subcategoryId,
    topicId,
    topicTitle,
    topicContent,
    topicImgUrl,
    topicPosition,
    mapPinIconUrl,
    address
  ) => {
    const updatedCategories = categories.map((category) => {
      if (category.id === categoryId) {
        const updatedSubcategories = category.subcategories.map(
          (subcategory) => {
            if (subcategory.id === subcategoryId) {
              const updatedTopics = subcategory.topics.map((topic) => {
                if (topic.id === topicId) {
                  return {
                    ...topic,
                    title: topicTitle,
                    content: topicContent,
                    imgUrl: topicImgUrl,
                    latitude: topicPosition.latitude,
                    longitude: topicPosition.longitude,
                    mapPinIconUrl: mapPinIconUrl,
                    address: address,
                  }
                }
                return topic
              })
              return {
                ...subcategory,
                topics: updatedTopics,
              }
            }
            return subcategory
          }
        )
        return {
          ...category,
          subcategories: updatedSubcategories,
        }
      }
      return category
    })
    setCategories(updatedCategories)
  }

  const toggleCategoryVisibility = (categoryId) => {
    setCategories((prevCategories) =>
      prevCategories.map((category) => {
        if (category.id === categoryId) {
          return {
            ...category,
            menuVisible: !category.menuVisible,
            subcategories: category.subcategories.map((subcategory) => ({
              ...subcategory,
              menuVisible: false,
              topics: subcategory.topics.map((topic) => ({
                ...topic,
                menuVisible: false,
              })),
            })),
          }
        }
        return {
          ...category,
          menuVisible: false,
          subcategories: category.subcategories.map((subcategory) => ({
            ...subcategory,
            menuVisible: false,
            topics: subcategory.topics.map((topic) => ({
              ...topic,
              menuVisible: false,
            })),
          })),
        }
      })
    )
  }

  const toggleSubcategoryVisibility = (categoryId, subcategoryId) => {
    setCategories((prevCategories) =>
      prevCategories.map((category) => {
        if (category.id === categoryId) {
          return {
            ...category,
            menuVisible: false,
            subcategories: category.subcategories.map((subcategory) => {
              if (subcategory.id === subcategoryId) {
                return {
                  ...subcategory,
                  menuVisible: !subcategory.menuVisible,
                  topics: subcategory.topics.map((topic) => ({
                    ...topic,
                    menuVisible: false,
                  })),
                }
              }
              return { ...subcategory, menuVisible: false }
            }),
          }
        }
        return {
          ...category,
          menuVisible: false,
          subcategories: category.subcategories.map((subcategory) => ({
            ...subcategory,
            menuVisible: false,
            topics: subcategory.topics.map((topic) => ({
              ...topic,
              menuVisible: false,
            })),
          })),
        }
      })
    )
  }

  const toggleTopicVisibility = (categoryId, subcategoryId, topicId) => {
    setCategories((prevCategories) =>
      prevCategories.map((category) =>
        category.id === categoryId
          ? {
              ...category,
              menuVisible: false,
              subcategories: category.subcategories.map((subcategory) =>
                subcategory.id === subcategoryId
                  ? {
                      ...subcategory,
                      menuVisible: false,
                      topics: subcategory.topics.map((topic) =>
                        topic.id === topicId
                          ? {
                              ...topic,
                              menuVisible: !topic.menuVisible,
                            }
                          : { ...topic, menuVisible: false }
                      ),
                    }
                  : { ...subcategory, menuVisible: false }
              ),
            }
          : { ...category, menuVisible: false }
      )
    )
  }

  const deleteCategory = (categoryIdToDelete) => {
    const updatedCategories = categories.filter(
      (category) => category.id !== categoryIdToDelete
    )
    setCategories(updatedCategories)
  }

  const deleteSubcategory = (categoryId, subcategoryId) => {
    const updatedCategories = categories.map((category) => {
      if (category.id === categoryId) {
        const updatedSubcategories = category.subcategories.filter(
          (subcategory) => subcategory.id !== subcategoryId
        )
        return {
          ...category,
          subcategories: updatedSubcategories,
        }
      }
      return category
    })
    setCategories(updatedCategories)
  }

  const deleteTopic = (categoryId, subcategoryId, topicId) => {
    const updatedCategories = categories.map((category) => {
      if (category.id === categoryId) {
        const updatedSubcategories = category.subcategories.map(
          (subcategory) => {
            if (subcategory.id === subcategoryId) {
              const updatedTopics = subcategory.topics.filter(
                (topic) => topic.id !== topicId
              )
              return {
                ...subcategory,
                topics: updatedTopics,
              }
            }
            return subcategory
          }
        )
        return {
          ...category,
          subcategories: updatedSubcategories,
        }
      }
      return category
    })
    setCategories(updatedCategories)
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    setImgFile(file)
    console.log(file)
    if (
      file &&
      (file.type === 'image/png' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/jpg')
    ) {
      const localImageUrl = URL.createObjectURL(file)
      console.log(localImageUrl)
      setImgUrlText(localImageUrl)
    } else {
      alert('Please select a valid image (png, jpg, jpeg).')
    }
  }

  const onDragStart = (e, dragId, dragOrder) => {
    const dragData = JSON.stringify({ dragId, dragOrder })
    e.dataTransfer.setData('application/json', dragData)
    e.target.style.opacity = 0.5
  }

  const reorderItemsByOrder = (items, sourceOrder, destinationOrder) => {
    const newItems = items.map((item) => {
      if (item.order === sourceOrder) {
        return { ...item, order: destinationOrder }
      }
      if (item.order === destinationOrder) {
        return { ...item, order: sourceOrder }
      }
      return item
    })
    return newItems.sort((a, b) => a.order - b.order)
  }

  const reorderSubcategories = (
    categories,
    categoryId,
    sourceOrder,
    destinationOrder
  ) => {
    const newCategories = categories.map((category) => {
      if (category.id === categoryId) {
        const newSubcategories = reorderItemsByOrder(
          category.subcategories,
          sourceOrder,
          destinationOrder
        )
        return { ...category, subcategories: newSubcategories }
      }
      return category
    })
    return newCategories
  }

  const reorderTopics = (
    categories,
    categoryId,
    subcategoryId,
    sourceOrder,
    destinationOrder
  ) => {
    const newCategories = categories.map((category) => {
      if (category.id === categoryId) {
        const newSubcategories = category.subcategories.map((subcategory) => {
          if (subcategory.id === subcategoryId) {
            const newTopics = reorderItemsByOrder(
              subcategory.topics,
              sourceOrder,
              destinationOrder
            )
            return { ...subcategory, topics: newTopics }
          }
          return subcategory
        })
        return { ...category, subcategories: newSubcategories }
      }
      return category
    })
    return newCategories
  }

  const onDropCategory = async (e, dropId, dropOrder) => {
    const dragData = e.dataTransfer.getData('application/json')
    const { dragId, dragOrder } = JSON.parse(dragData)

    showLoading()
    const dragCategoryResponse = await putCategoryOrder(dragId, dropOrder)

    if (dragCategoryResponse.status === 200) {
      const dropCategoryResponse = await putCategoryOrder(dropId, dragOrder)

      if (dropCategoryResponse.status === 200) {
        const newCategories = reorderItemsByOrder(
          categories,
          dragOrder,
          dropOrder
        )

        setCategories(newCategories)

        hideLoading()
      }
    }
  }

  const onDropSubcategory = async (e, categoryId, dropId, dropOrder) => {
    const dragData = e.dataTransfer.getData('application/json')
    const { dragId, dragOrder } = JSON.parse(dragData)
    showLoading()

    const dragSubcategoryResponse = await putSubcategoryOrder(dragId, dropOrder)
    console.log(dragSubcategoryResponse)
    if (dragSubcategoryResponse.status === 200) {
      const dropSubcategoryResponse = await putSubcategoryOrder(
        dropId,
        dragOrder
      )

      if (dropSubcategoryResponse.status === 200) {
        const newCategories = reorderSubcategories(
          categories,
          categoryId,
          dragOrder,
          dropOrder
        )
        setCategories(newCategories)

        hideLoading()
      }
    }
  }

  const onDropTopic = async (
    e,
    categoryId,
    subcategoryId,
    dropId,
    dropOrder
  ) => {
    const dragData = e.dataTransfer.getData('application/json')
    const { dragId, dragOrder } = JSON.parse(dragData)
    console.log(dragId, dragOrder, dropId, dropOrder)

    showLoading()

    const dragTopicResponse = await putTopicOrder(dragId, dropOrder)

    if (dragTopicResponse.status === 200) {
      const dropTopicResponse = await putTopicOrder(dropId, dragOrder)

      if (dropTopicResponse.status === 200) {
        const newCategories = reorderTopics(
          categories,
          categoryId,
          subcategoryId,
          dragOrder,
          dropOrder
        )
        setCategories(newCategories)

        hideLoading()
      }
    }
  }

  const onDragOver = (e) => {
    e.preventDefault()
  }

  const onDragEnd = (e) => {
    e.target.style.opacity = ''
  }

  const [tooltipVisible, setTooltipVisible] = useState(false)
  const handleTooltip = () => {
    setTooltipVisible(!tooltipVisible)
  }

  useEffect(() => {
    console.log('Image URL changed:', imgUrlText)
  }, [imgUrlText])

  return (
    <>
      <GuideContentBody2>
        <GuideContentBody2Inner>
          <Container>
            {categories.map((category) => (
              <div key={category.id}>
                <AccordionButton
                  onClick={() => toggleCategory(category.id)}
                  open={openCategories[category.id]}
                  draggable
                  onDragStart={(e) => {
                    onDragStart(e, category.id, category.order)
                  }}
                  onDrop={(e) => onDropCategory(e, category.id, category.order)}
                  onDragOver={onDragOver}
                  onDragEnd={(e) => onDragEnd(e)}
                >
                  <span>{category.title}</span>
                  <IconContainer>
                    <FontAwesomeIcon
                      icon={
                        openCategories[category.id]
                          ? faChevronUp
                          : faChevronDown
                      }
                    />
                    <IconTooltip
                      icon={
                        <VerticalThreeDotsIcon
                          onClick={() => {
                            toggleCategoryVisibility(category.id)
                          }}
                        ></VerticalThreeDotsIcon>
                      }
                      visible={category.menuVisible}
                      handleClick={() => {
                        toggleCategoryVisibility(category.id)
                      }}
                      tooltipWidth={180}
                      tooltipChangeWidth={180}
                      tooltipTop={30}
                      tooltipRight={0}
                    >
                      <SettingsItem
                        style={{
                          padding: '10px 20px',
                        }}
                        onClick={(e) => {
                          setCategory(category)
                          setCategoryTitleText(category.title)
                          setSelectedCategoryIcon(category.iconUrl)
                          setEditCategoryModalVisible(true)
                        }}
                      >
                        카테고리 수정하기
                      </SettingsItem>
                      <SettingsItem
                        style={{
                          padding: '10px 20px',
                        }}
                        onClick={(e) => {
                          setCategory(category)
                          setAddSubcategoryModalVisible(true)
                        }}
                      >
                        서브카테고리 추가하기
                      </SettingsItem>
                      <SettingsItem
                        style={{
                          padding: '10px 20px',
                        }}
                        onClick={(e) => {
                          setCategory(category)
                          setDeleteCategoryModalVisible(true)
                        }}
                      >
                        모두 삭제하기
                      </SettingsItem>
                    </IconTooltip>
                  </IconContainer>
                </AccordionButton>
                <Panel $isopen={openCategories[category.id]}>
                  {category.subcategories.map((subcategory) => (
                    <div key={subcategory.id}>
                      <SubcategoryButton
                        onClick={() => toggleSubcategory(subcategory.id)}
                        open={openSubcategories[subcategory.id]}
                        draggable
                        onDragStart={(e) => {
                          onDragStart(e, subcategory.id, subcategory.order)
                        }}
                        onDrop={(e) =>
                          onDropSubcategory(
                            e,
                            category.id,
                            subcategory.id,
                            subcategory.order
                          )
                        }
                        onDragOver={onDragOver}
                        onDragEnd={(e) => onDragEnd(e)}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <RotatedIcon icon={faLevelDownAlt} />
                          <div
                            style={{
                              marginLeft: 12,
                            }}
                          >
                            <span>{subcategory.title}</span>
                          </div>
                        </div>
                        <IconContainer>
                          <FontAwesomeIcon
                            icon={
                              openSubcategories[subcategory.id]
                                ? faChevronUp
                                : faChevronDown
                            }
                          />
                          <IconTooltip
                            icon={
                              <VerticalThreeDotsIcon
                                onClick={() => {
                                  toggleSubcategoryVisibility(
                                    category.id,
                                    subcategory.id
                                  )
                                }}
                              ></VerticalThreeDotsIcon>
                            }
                            visible={subcategory.menuVisible}
                            handleClick={() => {
                              toggleSubcategoryVisibility(
                                category.id,
                                subcategory.id
                              )
                            }}
                            tooltipWidth={180}
                            tooltipChangeWidth={180}
                            tooltipTop={30}
                            tooltipRight={0}
                          >
                            <SettingsItem
                              style={{
                                padding: '10px 20px',
                              }}
                              onClick={(e) => {
                                setCategory(category)
                                setSubcategory(subcategory)
                                setSubcategoryTitleText(subcategory.title)
                                setEditSubcategoryModalVisible(true)
                              }}
                            >
                              서브카테고리 수정하기
                            </SettingsItem>
                            <SettingsItem
                              style={{
                                padding: '10px 20px',
                              }}
                              onClick={(e) => {
                                setCategory(category)
                                setSubcategory(subcategory)
                                setImgUrlText('')
                                setUploadImgUrl('')
                                setImgFile(null)
                                setChoosePosition(false)
                                setAddress('')
                                setSelectedMapPinIcon(null)
                                setPosition({
                                  latitude: 37.5665,
                                  longitude: 126.978,
                                })
                                setTopicPosition({
                                  latitude: null,
                                  longitude: null,
                                })
                                setSelectedMapPinIcon(null)
                                setAddTopicModalVisible(true)
                              }}
                            >
                              주제 추가하기
                            </SettingsItem>
                            <SettingsItem
                              style={{
                                padding: '10px 20px',
                              }}
                              onClick={(e) => {
                                setCategory(category)
                                setSubcategory(subcategory)
                                setDeleteSubcategoryModalVisible(true)
                              }}
                            >
                              모두 삭제하기
                            </SettingsItem>
                          </IconTooltip>
                        </IconContainer>
                      </SubcategoryButton>
                      <SubPanel $isopen={openSubcategories[subcategory.id]}>
                        {subcategory.topics.map((topic) => (
                          <Topic
                            key={topic.id}
                            draggable
                            onDragStart={(e) => {
                              onDragStart(e, topic.id, topic.order)
                            }}
                            onDrop={(e) =>
                              onDropTopic(
                                e,
                                category.id,
                                subcategory.id,
                                topic.id,
                                topic.order
                              )
                            }
                            onDragOver={onDragOver}
                            onDragEnd={(e) => onDragEnd(e)}
                          >
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              {topic.imgUrl ? (
                                <img
                                  src={topic.imgUrl}
                                  alt=" topic Thumbnail"
                                  style={{
                                    width: 48,
                                    height: 48,
                                    borderRadius: 5,
                                  }}
                                />
                              ) : (
                                <NoImageIconContainer>
                                  <div
                                    style={{
                                      color: '#c0c4cc',
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faCamera} />
                                  </div>
                                </NoImageIconContainer>
                              )}
                              <div
                                style={{
                                  marginLeft: 12,
                                }}
                              >
                                <RotatedIcon icon={faLevelDownAlt} />
                              </div>
                              <div
                                style={{
                                  marginLeft: 12,
                                }}
                              >
                                <span>{topic.title}</span>
                              </div>
                            </div>
                            <IconContainer>
                              <IconTooltip
                                icon={
                                  <VerticalThreeDotsIcon
                                    onClick={() => {
                                      toggleTopicVisibility(
                                        category.id,
                                        subcategory.id,
                                        topic.id
                                      )
                                    }}
                                  ></VerticalThreeDotsIcon>
                                }
                                visible={topic.menuVisible}
                                handleClick={() => {
                                  toggleTopicVisibility(
                                    category.id,
                                    subcategory.id,
                                    topic.id
                                  )
                                }}
                                tooltipWidth={180}
                                tooltipChangeWidth={180}
                                tooltipTop={30}
                                tooltipRight={0}
                              >
                                <SettingsItem
                                  style={{
                                    padding: '10px 20px',
                                  }}
                                  onClick={(e) => {
                                    setCategory(category)
                                    setSubcategory(subcategory)
                                    setTopic(topic)
                                    setTopicTitleText(topic.title)
                                    const rawContentFromServer = JSON.parse(
                                      topic.content
                                    )
                                    const contentState =
                                      convertFromRaw(rawContentFromServer)
                                    const newEditorState =
                                      EditorState.createWithContent(
                                        contentState
                                      )
                                    setEditorState(newEditorState)
                                    setImgUrlText(topic.imgUrl)
                                    setUploadImgUrl(topic.imgUrl)
                                    setImgFile(null)
                                    console.log(topic.imgUrl)
                                    setChoosePosition(
                                      topic.latitude === null ||
                                        topic.longitude === null
                                        ? false
                                        : true
                                    )
                                    setAddress(topic.address)
                                    setSelectedMapPinIcon(topic.mapPinIconUrl)
                                    setPosition({
                                      latitude:
                                        topic.latitude === null
                                          ? 37.5665
                                          : topic.latitude,
                                      longitude:
                                        topic.longitude === null
                                          ? 126.978
                                          : topic.longitude,
                                    })
                                    setTopicPosition({
                                      latitude:
                                        topic.latitude === null
                                          ? null
                                          : topic.latitude,
                                      longitude:
                                        topic.longitude === null
                                          ? null
                                          : topic.longitude,
                                    })
                                    setSelectedMapPinIcon(topic.mapPinIconUrl)
                                    setEditTopicModalVisible(true)
                                  }}
                                >
                                  주제 수정하기
                                </SettingsItem>
                                <SettingsItem
                                  style={{
                                    padding: '10px 20px',
                                  }}
                                  onClick={(e) => {
                                    setCategory(category)
                                    setSubcategory(subcategory)
                                    setTopic(topic)
                                    setDeleteTopicModalVisible(true)
                                  }}
                                >
                                  주제 삭제하기
                                </SettingsItem>
                              </IconTooltip>
                            </IconContainer>
                          </Topic>
                        ))}
                        <SmallDashedButton
                          onClick={() => {
                            setCategory(category)
                            setSubcategory(subcategory)
                            setImgUrlText('')
                            setUploadImgUrl('')
                            setImgFile(null)
                            setChoosePosition(false)
                            setAddress('')
                            setSelectedMapPinIcon(null)
                            setPosition({
                              latitude: 37.5665,
                              longitude: 126.978,
                            })
                            setTopicPosition({
                              latitude: null,
                              longitude: null,
                            })
                            setSelectedMapPinIcon(null)
                            setAddTopicModalVisible(true)
                          }}
                        >
                          주제 추가하기
                        </SmallDashedButton>
                      </SubPanel>
                    </div>
                  ))}
                  <MiddleDashedButton
                    onClick={() => {
                      setCategory(category)
                      setAddSubcategoryModalVisible(true)
                    }}
                  >
                    서브카테고리 추가하기
                  </MiddleDashedButton>
                </Panel>
              </div>
            ))}
            <BigDashedButton
              onClick={() => {
                setAddCategoryModalVisible(true)
              }}
            >
              카테고리 추가하기
            </BigDashedButton>
          </Container>
        </GuideContentBody2Inner>
      </GuideContentBody2>
      {addCategoryModalVisible && (
        <NormalModal
          titleText={`카테고리 추가하기`}
          leftBtnVisible={true}
          modalBodyComponent={
            <div>
              <TitleBigDiv>카테고리 이름 *</TitleBigDiv>
              <TextInput
                value={categoryTitleText}
                onChange={(e) => {
                  setCategoryTitleText(e.target.value)
                }}
                backgroundColor={'#ffffff'}
              ></TextInput>
              <TitleBigDiv>카테고리 아이콘 *</TitleBigDiv>
              <CategoryIconSection>
                <CategoryIconSectionInner>
                  <CategoryIconSectionInnerBtn
                    onClick={() => {
                      setShowSelectCategoryIconPopup((pre) => !pre)
                    }}
                  >
                    아이콘 선택
                  </CategoryIconSectionInnerBtn>
                  <CategoryIconSectionInnerIcon>
                    {selectedCategoryIcon !== null && (
                      <img src={selectedCategoryIcon} alt=""></img>
                    )}
                  </CategoryIconSectionInnerIcon>
                </CategoryIconSectionInner>
                {showSelectCategoryIconPopup && (
                  <CategoryIconSectionPopup>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(airport_shuttle_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <AirportShuttleSvg></AirportShuttleSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(apartment_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ApartmentSvg></ApartmentSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(arrow_circle_up_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ArrowCircleUpSvg></ArrowCircleUpSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(arrow_forward_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ArrowForwardSvg></ArrowForwardSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(assistant_direction_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <AssistantDirectionSvg></AssistantDirectionSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(assistant_navigation_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <AssistantNavigationSvg></AssistantNavigationSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(beach_access_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <BeachAccessSvg></BeachAccessSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(bedroom_baby_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <BedroomBabySvg></BedroomBabySvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(bedtime_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <BedtimeSvg></BedtimeSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(call_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <CallSvg></CallSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(campaign_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <CampaignSvg></CampaignSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(camping_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <CampingSvg></CampingSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(cancel_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <CancelSvg></CancelSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(chat_bubble_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ChatBubbleSvg></ChatBubbleSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(chat_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ChatSvg></ChatSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(check_circle_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <CheckCircleSvg></CheckCircleSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(check_in_out_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <CheckInOutSvg></CheckInOutSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(checklist_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ChecklistSvg></ChecklistSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(cheer_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <CheerSvg></CheerSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(cleaning_services_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <CleaningServicesSvg></CleaningServicesSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(computer_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ComputerSvg></ComputerSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(concierge_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ConciergeSvg></ConciergeSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(cooking_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <CookingSvg></CookingSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(credit_card_heart_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <CreditCardHeartSvg></CreditCardHeartSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(crib_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <CribSvg></CribSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(delete_category_icon_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <DeleteCategoryIconSvg></DeleteCategoryIconSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(dining_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <DiningSvg></DiningSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(directions_bus_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <DirectionsBusSvg></DirectionsBusSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(distance_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <DistanceSvg></DistanceSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(door_front_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <DoorFrontSvg></DoorFrontSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(downhill_skiing_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <DownhillSkiingSvg></DownhillSkiingSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(edit_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <EditSvg></EditSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(enhanced_encryption_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <EnhancedEncryptionSvg></EnhancedEncryptionSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(exclamation_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ExclamationSvg></ExclamationSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(fact_check_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <FactCheckSvg></FactCheckSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(family_restroom_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <FamilyRestroomSvg></FamilyRestroomSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(favorite_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <FavoriteSvg></FavoriteSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(feed_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <FeedSvg></FeedSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(festival_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <FestivalSvg></FestivalSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(fireplace_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <FireplaceSvg></FireplaceSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(fitness_center_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <FitnessCenterSvg></FitnessCenterSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(hail_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <HailSvg></HailSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(hand_gesture_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <HandGestureSvg></HandGestureSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(handshake_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <HandshakeSvg></HandshakeSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(help_center_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <HelpCenterSvg></HelpCenterSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(help_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <HelpSvg></HelpSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(hiking_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <HikingSvg></HikingSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(home_health_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <HomeHealthSvg></HomeHealthSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(home_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <HomeSvg></HomeSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(house_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <HouseSvg></HouseSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(import_contacts_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ImportContactsSvg></ImportContactsSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(info_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <InfoSvg></InfoSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(key_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <KeySvg></KeySvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(lightbulb_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <LightbulbSvg></LightbulbSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(liquor_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <LiquorSvg></LiquorSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(local_cafe_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <LocalCafeSvg></LocalCafeSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(local_dining_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <LocalDiningSvg></LocalDiningSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(local_hospital_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <LocalHospitalSvg></LocalHospitalSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(local_parking_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <LocalParkingSvg></LocalParkingSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(location_on_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <LocationOnSvg></LocationOnSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(lock_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <LockSvg></LockSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(luggage_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <LuggageSvg></LuggageSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(mail_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <MailSvg></MailSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(map_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <MapSvg></MapSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(menu_book_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <MenuBookSvg></MenuBookSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(nature_people_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <NaturePeopleSvg></NaturePeopleSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(notifications_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <NotificationsSvg></NotificationsSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(park_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ParkSvg></ParkSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(password_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <PasswordSvg></PasswordSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(pedal_bike_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <PedalBikeSvg></PedalBikeSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(perm_phone_msg_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <PermPhoneMsgSvg></PermPhoneMsgSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(phone_in_talk_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <PhoneInTalkSvg></PhoneInTalkSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(photo_camera_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <PhotoCameraSvg></PhotoCameraSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(push_pin_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <PushPinSvg></PushPinSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(recommend_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <RecommendSvg></RecommendSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(recycling_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <RecyclingSvg></RecyclingSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(restaurant_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <RestaurantSvg></RestaurantSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(reviews_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ReviewsSvg></ReviewsSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(schedule_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ScheduleSvg></ScheduleSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(sentiment_sad_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <SentimentSadSvg></SentimentSadSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(sentiment_satisfied_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <SentimentSatisfiedSvg></SentimentSatisfiedSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(settings_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <SettingsSvg></SettingsSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(shopping_bag_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ShoppingBagSvg></ShoppingBagSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(shopping_cart_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ShoppingCartSvg></ShoppingCartSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(shoppingmode_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ShoppingmodeSvg></ShoppingmodeSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(shower_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ShowerSvg></ShowerSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(signpost_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <SignpostSvg></SignpostSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(single_bed_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <SingleBedSvg></SingleBedSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(skillet_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <SkilletSvg></SkilletSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(smartphone_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <SmartphoneSvg></SmartphoneSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(star_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <StarSvg></StarSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(storefront_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <StorefrontSvg></StorefrontSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(support_agent_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <SupportAgentSvg></SupportAgentSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(thumb_up_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ThumbUpSvg></ThumbUpSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(travel_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <TravelSvg></TravelSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(volunteer_activism_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <VolunteerActivismSvg></VolunteerActivismSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(waving_hand_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <WavingHandSvg></WavingHandSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(wifi_password_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <WifiPasswordSvg></WifiPasswordSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(wifi_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <WifiSvg></WifiSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(woman_2_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <Woman_2Svg></Woman_2Svg>
                    </CategoryIconSectionPopupIcon>
                  </CategoryIconSectionPopup>
                )}
              </CategoryIconSection>
            </div>
          }
          onRightBtnClick={async () => {
            showLoading()

            if (
              categoryTitleText !== '' &&
              selectedCategoryIcon !== null &&
              selectedCategoryIcon !== ''
            ) {
              const response = await postCategory(
                categoryTitleText,
                selectedCategoryIcon,
                guide.id
              )

              if (response.status === 201) {
                addCategory(
                  categoryTitleText,
                  response.data.newCategory.iconUrl,
                  response.data.newCategory.id,
                  response.data.newCategory.order
                )
                setCategoryTitleText('')
                setSelectedCategoryIcon(null)
                setAddCategoryModalVisible(false)
              }
            }

            hideLoading()
          }}
          onClose={() => {
            setCategoryTitleText('')
            setSelectedCategoryIcon(null)
            setAddCategoryModalVisible(false)
          }}
          rightBtnText={'저장'}
          rightBtnDisabled={categoryTitleText === ''}
          questionSection={<p>---</p>}
          questionWidth={500}
          questionChangeWidth={180}
        ></NormalModal>
      )}
      {editCategoryModalVisible && (
        <NormalModal
          titleText={`Edit Category`}
          leftBtnVisible={true}
          modalBodyComponent={
            <div>
              <TitleBigDiv>Category title *</TitleBigDiv>
              <TextInput
                value={categoryTitleText}
                onChange={(e) => {
                  setCategoryTitleText(e.target.value)
                }}
                backgroundColor={'#ffffff'}
              ></TextInput>
              <TitleBigDiv>카테고리 아이콘 *</TitleBigDiv>
              <CategoryIconSection>
                <CategoryIconSectionInner>
                  <CategoryIconSectionInnerBtn
                    onClick={() => {
                      setShowSelectCategoryIconPopup((pre) => !pre)
                    }}
                  >
                    아이콘 선택
                  </CategoryIconSectionInnerBtn>
                  <CategoryIconSectionInnerIcon>
                    {selectedCategoryIcon !== null && (
                      <img src={selectedCategoryIcon} alt=""></img>
                    )}
                  </CategoryIconSectionInnerIcon>
                </CategoryIconSectionInner>
                {showSelectCategoryIconPopup && (
                  <CategoryIconSectionPopup>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(airport_shuttle_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <AirportShuttleSvg></AirportShuttleSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(apartment_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ApartmentSvg></ApartmentSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(arrow_circle_up_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ArrowCircleUpSvg></ArrowCircleUpSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(arrow_forward_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ArrowForwardSvg></ArrowForwardSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(assistant_direction_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <AssistantDirectionSvg></AssistantDirectionSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(assistant_navigation_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <AssistantNavigationSvg></AssistantNavigationSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(beach_access_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <BeachAccessSvg></BeachAccessSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(bedroom_baby_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <BedroomBabySvg></BedroomBabySvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(bedtime_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <BedtimeSvg></BedtimeSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(call_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <CallSvg></CallSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(campaign_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <CampaignSvg></CampaignSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(camping_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <CampingSvg></CampingSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(cancel_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <CancelSvg></CancelSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(chat_bubble_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ChatBubbleSvg></ChatBubbleSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(chat_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ChatSvg></ChatSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(check_circle_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <CheckCircleSvg></CheckCircleSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(check_in_out_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <CheckInOutSvg></CheckInOutSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(checklist_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ChecklistSvg></ChecklistSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(cheer_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <CheerSvg></CheerSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(cleaning_services_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <CleaningServicesSvg></CleaningServicesSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(computer_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ComputerSvg></ComputerSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(concierge_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ConciergeSvg></ConciergeSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(cooking_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <CookingSvg></CookingSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(credit_card_heart_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <CreditCardHeartSvg></CreditCardHeartSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(crib_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <CribSvg></CribSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(delete_category_icon_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <DeleteCategoryIconSvg></DeleteCategoryIconSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(dining_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <DiningSvg></DiningSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(directions_bus_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <DirectionsBusSvg></DirectionsBusSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(distance_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <DistanceSvg></DistanceSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(door_front_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <DoorFrontSvg></DoorFrontSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(downhill_skiing_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <DownhillSkiingSvg></DownhillSkiingSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(edit_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <EditSvg></EditSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(enhanced_encryption_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <EnhancedEncryptionSvg></EnhancedEncryptionSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(exclamation_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ExclamationSvg></ExclamationSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(fact_check_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <FactCheckSvg></FactCheckSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(family_restroom_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <FamilyRestroomSvg></FamilyRestroomSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(favorite_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <FavoriteSvg></FavoriteSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(feed_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <FeedSvg></FeedSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(festival_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <FestivalSvg></FestivalSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(fireplace_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <FireplaceSvg></FireplaceSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(fitness_center_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <FitnessCenterSvg></FitnessCenterSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(hail_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <HailSvg></HailSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(hand_gesture_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <HandGestureSvg></HandGestureSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(handshake_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <HandshakeSvg></HandshakeSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(help_center_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <HelpCenterSvg></HelpCenterSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(help_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <HelpSvg></HelpSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(hiking_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <HikingSvg></HikingSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(home_health_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <HomeHealthSvg></HomeHealthSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(home_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <HomeSvg></HomeSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(house_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <HouseSvg></HouseSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(import_contacts_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ImportContactsSvg></ImportContactsSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(info_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <InfoSvg></InfoSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(key_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <KeySvg></KeySvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(lightbulb_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <LightbulbSvg></LightbulbSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(liquor_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <LiquorSvg></LiquorSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(local_cafe_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <LocalCafeSvg></LocalCafeSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(local_dining_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <LocalDiningSvg></LocalDiningSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(local_hospital_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <LocalHospitalSvg></LocalHospitalSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(local_parking_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <LocalParkingSvg></LocalParkingSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(location_on_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <LocationOnSvg></LocationOnSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(lock_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <LockSvg></LockSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(luggage_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <LuggageSvg></LuggageSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(mail_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <MailSvg></MailSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(map_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <MapSvg></MapSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(menu_book_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <MenuBookSvg></MenuBookSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(nature_people_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <NaturePeopleSvg></NaturePeopleSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(notifications_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <NotificationsSvg></NotificationsSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(park_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ParkSvg></ParkSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(password_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <PasswordSvg></PasswordSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(pedal_bike_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <PedalBikeSvg></PedalBikeSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(perm_phone_msg_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <PermPhoneMsgSvg></PermPhoneMsgSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(phone_in_talk_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <PhoneInTalkSvg></PhoneInTalkSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(photo_camera_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <PhotoCameraSvg></PhotoCameraSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(push_pin_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <PushPinSvg></PushPinSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(recommend_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <RecommendSvg></RecommendSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(recycling_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <RecyclingSvg></RecyclingSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(restaurant_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <RestaurantSvg></RestaurantSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(reviews_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ReviewsSvg></ReviewsSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(schedule_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ScheduleSvg></ScheduleSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(sentiment_sad_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <SentimentSadSvg></SentimentSadSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(sentiment_satisfied_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <SentimentSatisfiedSvg></SentimentSatisfiedSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(settings_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <SettingsSvg></SettingsSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(shopping_bag_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ShoppingBagSvg></ShoppingBagSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(shopping_cart_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ShoppingCartSvg></ShoppingCartSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(shoppingmode_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ShoppingmodeSvg></ShoppingmodeSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(shower_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ShowerSvg></ShowerSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(signpost_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <SignpostSvg></SignpostSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(single_bed_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <SingleBedSvg></SingleBedSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(skillet_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <SkilletSvg></SkilletSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(smartphone_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <SmartphoneSvg></SmartphoneSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(star_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <StarSvg></StarSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(storefront_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <StorefrontSvg></StorefrontSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(support_agent_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <SupportAgentSvg></SupportAgentSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(thumb_up_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <ThumbUpSvg></ThumbUpSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(travel_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <TravelSvg></TravelSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(volunteer_activism_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <VolunteerActivismSvg></VolunteerActivismSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(waving_hand_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <WavingHandSvg></WavingHandSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(wifi_password_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <WifiPasswordSvg></WifiPasswordSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(wifi_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <WifiSvg></WifiSvg>
                    </CategoryIconSectionPopupIcon>
                    <CategoryIconSectionPopupIcon
                      onClick={() => {
                        setSelectedCategoryIcon(woman_2_image_s3)
                        setShowSelectCategoryIconPopup(false)
                      }}
                    >
                      <Woman_2Svg></Woman_2Svg>
                    </CategoryIconSectionPopupIcon>
                  </CategoryIconSectionPopup>
                )}
              </CategoryIconSection>
            </div>
          }
          onRightBtnClick={async () => {
            showLoading()

            if (
              categoryTitleText !== '' &&
              selectedCategoryIcon !== null &&
              selectedCategoryIcon !== ''
            ) {
              const response = await putCategory(
                category.id,
                categoryTitleText,
                selectedCategoryIcon,
                category.order
              )

              if (response.status === 200) {
                editCategory(category.id, categoryTitleText)
                setCategoryTitleText('')
                setSelectedCategoryIcon(null)
                setEditCategoryModalVisible(false)
              }
            }

            hideLoading()
          }}
          onClose={() => {
            setCategoryTitleText('')
            setSelectedCategoryIcon(null)
            setEditCategoryModalVisible(false)
          }}
          rightBtnText={'저장'}
          rightBtnDisabled={categoryTitleText === ''}
          questionSection={<p>---</p>}
          questionWidth={500}
          questionChangeWidth={180}
        ></NormalModal>
      )}
      {deleteCategoryModalVisible && (
        <StandardModal
          titleText={'이 카테고리를 삭제 하시겠습니까?'}
          modalBodyComponent={<div>카테고리의 모든 내용이 삭제 됩니다.</div>}
          onRightBtnClick={async () => {
            showLoading()

            const response = await deleteCategoryApi(category.id)

            if (response.status === 200) {
              deleteCategory(category.id)
              setDeleteCategoryModalVisible(false)
            }

            hideLoading()
          }}
          onClose={() => {
            setDeleteCategoryModalVisible(false)
          }}
          rightBtnText={'삭제'}
        ></StandardModal>
      )}
      {addSubcategoryModalVisible && (
        <NormalModal
          titleText={`서브카테고리 추가하기`}
          leftBtnVisible={true}
          modalBodyComponent={
            <div>
              <TitleBigDiv>서브카테고리 이름 *</TitleBigDiv>
              <TextInput
                value={subcategoryTitleText}
                onChange={(e) => {
                  setSubcategoryTitleText(e.target.value)
                }}
                backgroundColor={'#ffffff'}
              ></TextInput>
            </div>
          }
          onRightBtnClick={async () => {
            showLoading()

            const response = await postSubcategory(
              subcategoryTitleText,
              category.id
            )
            console.log(response.data)
            if (response.status === 201) {
              addSubcategory(
                category.id,
                subcategoryTitleText,
                response.data.newSubcategory.id,
                response.data.newSubcategory.order
              )
              setSubcategoryTitleText('')
              setAddSubcategoryModalVisible(false)
            }

            hideLoading()
          }}
          onClose={() => {
            setAddSubcategoryModalVisible(false)
          }}
          rightBtnText={'저장'}
          rightBtnDisabled={subcategoryTitleText === ''}
          questionSection={<p>---</p>}
          questionWidth={500}
          questionChangeWidth={180}
        ></NormalModal>
      )}
      {editSubcategoryModalVisible && (
        <NormalModal
          titleText={`서브카테고리 수정`}
          leftBtnVisible={true}
          modalBodyComponent={
            <div>
              <TitleBigDiv>서브카테고리 제목 *</TitleBigDiv>
              <TextInput
                value={subcategoryTitleText}
                onChange={(e) => {
                  setSubcategoryTitleText(e.target.value)
                }}
                backgroundColor={'#ffffff'}
              ></TextInput>
            </div>
          }
          onRightBtnClick={async () => {
            showLoading()

            const response = await putSubcategory(
              subcategoryTitleText,
              subcategory.order,
              subcategory.id
            )

            if (response.status === 200) {
              editSubcategory(category.id, subcategory.id, subcategoryTitleText)
              setSubcategoryTitleText('')
              setEditSubcategoryModalVisible(false)
            }

            hideLoading()
          }}
          onClose={() => {
            setSubcategoryTitleText('')
            setEditSubcategoryModalVisible(false)
          }}
          rightBtnText={'수정'}
          rightBtnDisabled={subcategoryTitleText === ''}
          questionSection={<p>---</p>}
          questionWidth={500}
          questionChangeWidth={180}
        ></NormalModal>
      )}
      {deleteSubcategoryModalVisible && (
        <StandardModal
          titleText={'이 서브카테고리를 삭제 하시겠습니까?'}
          modalBodyComponent={
            <div>서브카테고리의 모든 내용이 삭제 됩니다.</div>
          }
          onRightBtnClick={async () => {
            showLoading()

            const response = await deleteSubcategoryApi(subcategory.id)

            if (response.status === 200) {
              deleteSubcategory(category.id, subcategory.id)
              setDeleteSubcategoryModalVisible(false)
            }

            hideLoading()
          }}
          onClose={() => {
            setDeleteSubcategoryModalVisible(false)
          }}
          rightBtnText={'삭제'}
        ></StandardModal>
      )}
      {addTopicModalVisible && (
        <NormalModal
          titleText={`주제 추가하기`}
          leftBtnVisible={true}
          modalBodyComponent={
            <div>
              <TitleBigDiv>제목</TitleBigDiv>
              <TextInput
                value={topicTitleText}
                onChange={(e) => {
                  setTopicTitleText(e.target.value)
                }}
                backgroundColor={'#ffffff'}
              ></TextInput>
              <TitleBigDiv>
                내용{' '}
                <div
                  style={{
                    marginLeft: 8,
                  }}
                >
                  {' '}
                  <IconTooltip
                    icon={
                      <QuestionMarkInCircle
                        onClick={handleTooltip}
                      ></QuestionMarkInCircle>
                    }
                    handleClick={handleTooltip}
                    visible={tooltipVisible}
                    tooltipTop={2.5}
                    tooltipLeft={30}
                    tooltipDefaultWidth={500}
                    tooltipChangeWidth={350}
                  >
                    <div
                      style={{
                        padding: 20,
                      }}
                    >
                      사진 아이콘을 클릭하여 이미지 URL 등록 시 세로는 auto,
                      가로는 280 이하로 등록해 주세요.
                    </div>
                  </IconTooltip>
                </div>
              </TitleBigDiv>
              <EditorTotalDiv>
                <Editor
                  editorState={editorState}
                  onEditorStateChange={handleEditorChange}
                  onCompositionStart={handleCompositionStart}
                  onCompositionEnd={handleCompositionEnd}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  customBlockRenderFunc={imageBlockRenderer}
                />
              </EditorTotalDiv>
              <TitleBigDiv>대표 이미지</TitleBigDiv>
              {imgUrlText === '' || imgUrlText === null ? (
                <IconTextCircleBtn
                  text={'이미지 첨부'}
                  borderColor={'#ededed'}
                  backgroundColor={'#ededed'}
                  textColor={'#000'}
                  onClick={() => {
                    setAddAddMediaModalVisible(true)
                  }}
                >
                  <CameraIconFixed></CameraIconFixed>
                </IconTextCircleBtn>
              ) : (
                <div
                  style={{
                    height: 200,
                    color: '#d3d3d3',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 5,
                    border: '1px solid #d3d3d3',
                    position: 'relative',
                  }}
                >
                  {imgUrlText ? (
                    <img
                      src={imgUrlText}
                      alt="Media"
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        borderRadius: 5,
                      }}
                    />
                  ) : (
                    <div>No Image</div>
                  )}
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      position: 'absolute',
                      left: 10,
                      bottom: 10,
                    }}
                  >
                    <IconTextCircleBtn
                      text={'수정하기'}
                      borderColor={'#ededed'}
                      backgroundColor={'#ededed'}
                      textColor={'#000'}
                      onClick={() => {
                        setAddChangeMediaModalVisible(true)
                      }}
                    >
                      <CameraIconFixed></CameraIconFixed>
                    </IconTextCircleBtn>
                    <div
                      style={{
                        marginLeft: 10,
                      }}
                    >
                      <IconTextCircleBtn
                        text={'삭제하기'}
                        borderColor={'#f56c6c'}
                        backgroundColor={'#f56c6c'}
                        textColor={'#fff'}
                        onClick={() => {
                          setDeleteAddMediaModalVisible(true)
                        }}
                      >
                        <TrashIconFixed></TrashIconFixed>
                      </IconTextCircleBtn>
                    </div>
                  </div>
                </div>
              )}
              <TitleBigDiv>장소</TitleBigDiv>
              <>
                {choosePosition ? (
                  <>
                    <div
                      className="map-div"
                      style={{
                        backgroundImage: `url(${generate_static_map_url(
                          mapWidth,
                          400,
                          position.latitude,
                          position.longitude
                        )})`,
                        width: '100%',
                        height: '400px',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center center',
                      }}
                    ></div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: 15,
                      }}
                    >
                      <IconTextCircleBtn
                        text={'핀 위치 수정'}
                        borderColor={'#ededed'}
                        backgroundColor={'#ededed'}
                        textColor={'#000'}
                        onClick={() => {
                          setAddLocationModalVisible(true)
                        }}
                      >
                        <LocationIconFixed></LocationIconFixed>
                      </IconTextCircleBtn>
                      <div
                        style={{
                          marginLeft: 10,
                        }}
                      >
                        <IconTextCircleBtn
                          text={'장소 삭제'}
                          borderColor={'#f56c6c'}
                          backgroundColor={'#f56c6c'}
                          textColor={'#fff'}
                          onClick={() => {
                            setDeleteAddLocationModalVisible(true)
                          }}
                        >
                          <TrashIconFixed></TrashIconFixed>
                        </IconTextCircleBtn>
                      </div>
                    </div>
                  </>
                ) : (
                  <IconTextCircleBtn
                    text={'위치 표시'}
                    borderColor={'#ededed'}
                    backgroundColor={'#ededed'}
                    textColor={'#000'}
                    onClick={() => {
                      setAddLocationModalVisible(true)
                    }}
                  >
                    <LocationIconFixed></LocationIconFixed>
                  </IconTextCircleBtn>
                )}
              </>
            </div>
          }
          onRightBtnClick={async () => {
            showLoading()

            const content = editorState.getCurrentContent()
            const rawContent = convertToRaw(content)
            const contentString = JSON.stringify(rawContent)

            const response = await postTopic(
              topicTitleText,
              contentString,
              uploadImgUrl,
              topicPosition.latitude,
              topicPosition.longitude,
              selectedMapPinIcon,
              subcategory.id,
              address
            )
            console.log(response.data)
            if (response.status === 201) {
              console.log(topicPosition)
              addTopic(
                category.id,
                subcategory.id,
                topicTitleText,
                contentString,
                imgUrlText,
                topicPosition,
                response.data.newTopic.id,
                selectedMapPinIcon,
                response.data.newTopic.order,
                address
              )
              setTopicTitleText('')
              setEditorState(EditorState.createEmpty())
              setImgUrlText('')
              setUploadImgUrl('')
              setImgFile(null)
              setChoosePosition(false)
              setAddress('')
              setSelectedMapPinIcon(null)
              setPosition({
                latitude: 37.5665,
                longitude: 126.978,
              })
              setTopicPosition({
                latitude: null,
                longitude: null,
              })
              setSelectedMapPinIcon(null)
              setAddTopicModalVisible(false)
            }

            hideLoading()
          }}
          onClose={async () => {
            if (
              uploadImgUrl !== '' &&
              uploadImgUrl !== null &&
              !isMatchingDefaultGuideImageURL(uploadImgUrl)
            ) {
              showLoading()

              const deleteImageResponse = await deleteImage(uploadImgUrl)

              if (deleteImageResponse.status === 200) {
                setTopicTitleText('')
                setEditorState(EditorState.createEmpty())
                setImgUrlText('')
                setUploadImgUrl('')
                setImgFile(null)
                setChoosePosition(false)
                setAddress('')
                setSelectedMapPinIcon(null)
                setPosition({
                  latitude: 37.5665,
                  longitude: 126.978,
                })
                setTopicPosition({
                  latitude: null,
                  longitude: null,
                })
                setSelectedMapPinIcon(null)
                setAddTopicModalVisible(false)
              }

              hideLoading()
            } else {
              setTopicTitleText('')
              setEditorState(EditorState.createEmpty())
              setImgUrlText('')
              setUploadImgUrl('')
              setImgFile(null)
              setChoosePosition(false)
              setAddress('')
              setSelectedMapPinIcon(null)
              setPosition({
                latitude: 37.5665,
                longitude: 126.978,
              })
              setTopicPosition({
                latitude: null,
                longitude: null,
              })
              setSelectedMapPinIcon(null)
              setAddTopicModalVisible(false)
            }
          }}
          rightBtnText={'저장'}
          rightBtnDisabled={topicTitleText === ''}
          questionSection={<p>---</p>}
          questionWidth={500}
          questionChangeWidth={180}
        ></NormalModal>
      )}
      {editTopicModalVisible && (
        <NormalModal
          titleText={`주제 수정하기`}
          leftBtnVisible={true}
          modalBodyComponent={
            <div>
              <TitleBigDiv>제목</TitleBigDiv>
              <TextInput
                value={topicTitleText}
                onChange={(e) => {
                  setTopicTitleText(e.target.value)
                }}
                backgroundColor={'#ffffff'}
              ></TextInput>
              <TitleBigDiv>
                내용{' '}
                <div
                  style={{
                    marginLeft: 8,
                  }}
                >
                  {' '}
                  <IconTooltip
                    icon={
                      <QuestionMarkInCircle
                        onClick={handleTooltip}
                      ></QuestionMarkInCircle>
                    }
                    handleClick={handleTooltip}
                    visible={tooltipVisible}
                    tooltipTop={2.5}
                    tooltipLeft={30}
                    tooltipDefaultWidth={500}
                    tooltipChangeWidth={350}
                  >
                    <div
                      style={{
                        padding: 20,
                      }}
                    >
                      사진 아이콘을 클릭하여 이미지 URL 등록 시 세로는 auto,
                      가로는 280 이하로 등록해 주세요.
                    </div>
                  </IconTooltip>
                </div>
              </TitleBigDiv>
              <EditorTotalDiv>
                <Editor
                  editorState={editorState}
                  onEditorStateChange={handleEditorChange}
                  onCompositionStart={handleCompositionStart}
                  onCompositionEnd={handleCompositionEnd}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  customBlockRenderFunc={imageBlockRenderer}
                />
              </EditorTotalDiv>
              <TitleBigDiv>대표 이미지</TitleBigDiv>
              {imgUrlText === '' || imgUrlText === null ? (
                <IconTextCircleBtn
                  text={'이미지 첨부'}
                  borderColor={'#ededed'}
                  backgroundColor={'#ededed'}
                  textColor={'#000'}
                  onClick={() => {
                    setEditAddMediaModalVisible(true)
                  }}
                >
                  <CameraIconFixed></CameraIconFixed>
                </IconTextCircleBtn>
              ) : (
                <div
                  style={{
                    height: 200,
                    color: '#d3d3d3',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 5,
                    border: '1px solid #d3d3d3',
                    position: 'relative',
                  }}
                >
                  {imgUrlText ? (
                    <img
                      src={imgUrlText}
                      alt="Media"
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        borderRadius: 5,
                      }}
                      onLoad={() => console.log('Image loaded successfully')}
                      onError={() => console.log('Image loading failed')}
                    />
                  ) : (
                    <div>No Image</div>
                  )}
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      position: 'absolute',
                      left: 10,
                      bottom: 10,
                    }}
                  >
                    <IconTextCircleBtn
                      text={'수정하기'}
                      borderColor={'#ededed'}
                      backgroundColor={'#ededed'}
                      textColor={'#000'}
                      onClick={() => {
                        setEditChangeMediaModalVisible(true)
                      }}
                    >
                      <CameraIconFixed></CameraIconFixed>
                    </IconTextCircleBtn>
                    <div
                      style={{
                        marginLeft: 10,
                      }}
                    >
                      <IconTextCircleBtn
                        text={'삭제하기'}
                        borderColor={'#f56c6c'}
                        backgroundColor={'#f56c6c'}
                        textColor={'#fff'}
                        onClick={() => {
                          setDeleteEditMediaModalVisible(true)
                        }}
                      >
                        <TrashIconFixed></TrashIconFixed>
                      </IconTextCircleBtn>
                    </div>
                  </div>
                </div>
              )}
              <TitleBigDiv>장소</TitleBigDiv>
              <>
                {choosePosition ? (
                  <>
                    <div
                      className="map-div"
                      style={{
                        backgroundImage: `url(${generate_static_map_url(
                          mapWidth,
                          400,
                          position.latitude,
                          position.longitude
                        )})`,
                        width: '100%',
                        height: '400px',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center center',
                      }}
                    ></div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: 15,
                      }}
                    >
                      <IconTextCircleBtn
                        text={'핀 위치 수정'}
                        borderColor={'#ededed'}
                        backgroundColor={'#ededed'}
                        textColor={'#000'}
                        onClick={() => {
                          setAddress(topic.address)
                          setSelectedMapPinIcon(topic.mapPinIconUrl)
                          setEditLocationModalVisible(true)
                        }}
                      >
                        <LocationIconFixed></LocationIconFixed>
                      </IconTextCircleBtn>
                      <div
                        style={{
                          marginLeft: 10,
                        }}
                      >
                        <IconTextCircleBtn
                          text={'장소 삭제'}
                          borderColor={'#f56c6c'}
                          backgroundColor={'#f56c6c'}
                          textColor={'#fff'}
                          onClick={() => {
                            setDeleteEditLocationModalVisible(true)
                          }}
                        >
                          <TrashIconFixed></TrashIconFixed>
                        </IconTextCircleBtn>
                      </div>
                    </div>
                  </>
                ) : (
                  <IconTextCircleBtn
                    text={'위치 표시'}
                    borderColor={'#ededed'}
                    backgroundColor={'#ededed'}
                    textColor={'#000'}
                    onClick={() => {
                      setEditLocationModalVisible(true)
                    }}
                  >
                    <LocationIconFixed></LocationIconFixed>
                  </IconTextCircleBtn>
                )}
              </>
            </div>
          }
          onRightBtnClick={async () => {
            showLoading()

            const content = editorState.getCurrentContent()
            const rawContent = convertToRaw(content)
            const contentString = JSON.stringify(rawContent)

            const response = await putTopic(
              topicTitleText,
              contentString,
              uploadImgUrl,
              topicPosition.latitude,
              topicPosition.longitude,
              selectedMapPinIcon,
              topic.order,
              topic.id,
              address
            )

            if (response.status === 200) {
              editTopic(
                category.id,
                subcategory.id,
                topic.id,
                topicTitleText,
                contentString,
                imgUrlText,
                topicPosition,
                selectedMapPinIcon,
                address
              )
              setTopicTitleText('')
              setEditorState(EditorState.createEmpty())
              setImgUrlText('')
              setUploadImgUrl('')
              setImgFile(null)
              setChoosePosition(false)
              setAddress('')
              setSelectedMapPinIcon(null)
              setPosition({
                latitude: 37.5665,
                longitude: 126.978,
              })
              setTopicPosition({
                latitude: null,
                longitude: null,
              })
              setSelectedMapPinIcon(null)
              setEditTopicModalVisible(false)
            }

            hideLoading()
          }}
          onClose={async () => {
            setTopicTitleText('')
            setEditorState(EditorState.createEmpty())
            setImgUrlText('')
            setUploadImgUrl('')
            setImgFile(null)
            setChoosePosition(false)
            setAddress('')
            setSelectedMapPinIcon(null)
            setPosition({
              latitude: 37.5665,
              longitude: 126.978,
            })
            setTopicPosition({
              latitude: null,
              longitude: null,
            })
            setSelectedMapPinIcon(null)
            setEditTopicModalVisible(false)
          }}
          rightBtnText={'저장'}
          rightBtnDisabled={topicTitleText === ''}
          questionSection={<p>---</p>}
          questionWidth={500}
          questionChangeWidth={180}
        ></NormalModal>
      )}
      {deleteTopicModalVisible && (
        <StandardModal
          titleText={'이 주제를 삭제 하시겠습니까?'}
          modalBodyComponent={<div>주제의 모든 내용이 삭제 됩니다.</div>}
          onRightBtnClick={async () => {
            showLoading()

            const response = await deleteTopicApi(topic.id)

            if (response.status === 200) {
              deleteTopic(category.id, subcategory.id, topic.id)
              setDeleteTopicModalVisible(false)
            }

            hideLoading()
          }}
          onClose={() => {
            setDeleteTopicModalVisible(false)
          }}
          rightBtnText={'삭제'}
        ></StandardModal>
      )}
      {addAddMediaModalVisible && (
        <NormalModal
          titleText={`이미지 첨부`}
          leftBtnVisible={true}
          modalBodyComponent={
            <div>
              <div
                style={{
                  height: 175,
                  color: '#d3d3d3',
                  backgroundSize: 'cover',
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundPosition: 'center center',
                  backgroundRepeat: 'no-repeat',
                  alignItems: 'center',
                  justifyContent: 'center',
                  boxSizing: 'border-box',
                  backgroundImage: `url(${imgUrlText || 'unknown'})`,
                }}
              >
                {imgUrlText === '' || imgUrlText === null ? (
                  <CameraIcon></CameraIcon>
                ) : (
                  <></>
                )}
              </div>
              <TitleBigDiv>이미지 삽입</TitleBigDiv>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <input
                  type="file"
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  accept=".png, .jpg, .jpeg"
                />
                <IconTextCircleBtn
                  text={'이미지 첨부'}
                  borderColor={'#ededed'}
                  backgroundColor={'#ededed'}
                  textColor={'#000'}
                  onClick={() => {
                    fileInputRef.current.click()
                  }}
                >
                  <CameraIconFixed></CameraIconFixed>
                </IconTextCircleBtn>
              </div>
            </div>
          }
          onRightBtnClick={async () => {
            showLoading()

            const imageUploadResponse = await uploadImage(imgFile)
            console.log(imageUploadResponse)

            if (imageUploadResponse.status === 200) {
              setImgUrlText(imageUploadResponse.data.imageUrl)
              setUploadImgUrl(imageUploadResponse.data.imageUrl)
              setImgFile(null)
              setAddAddMediaModalVisible(false)
            }

            hideLoading()
          }}
          onClose={() => {
            setImgUrlText('')
            setUploadImgUrl('')
            setImgFile(null)
            setAddAddMediaModalVisible(false)
          }}
          rightBtnText={'저장'}
          rightBtnDisabled={false}
          questionSection={<p>---</p>}
          questionWidth={500}
          questionChangeWidth={180}
        ></NormalModal>
      )}
      {addChangeMediaModalVisible && (
        <NormalModal
          titleText={`수정하기`}
          leftBtnVisible={true}
          modalBodyComponent={
            <div>
              <div
                style={{
                  height: 200,
                  color: '#d3d3d3',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: 5,
                  border: '1px solid #d3d3d3',
                  position: 'relative',
                }}
              >
                {imgUrlText ? (
                  <img
                    src={imgUrlText}
                    alt="Media"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      borderRadius: 5,
                    }}
                  />
                ) : (
                  <CameraIcon></CameraIcon>
                )}
              </div>
              <TitleBigDiv>이미지 삽입</TitleBigDiv>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <input
                  type="file"
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  accept=".png, .jpg, .jpeg"
                />
                <IconTextCircleBtn
                  text={'이미지 첨부'}
                  borderColor={'#ededed'}
                  backgroundColor={'#ededed'}
                  textColor={'#000'}
                  onClick={() => {
                    fileInputRef.current.click()
                  }}
                >
                  <CameraIconFixed></CameraIconFixed>
                </IconTextCircleBtn>
              </div>
            </div>
          }
          onRightBtnClick={async () => {
            if (
              imgFile !== null &&
              !isMatchingDefaultGuideImageURL(uploadImgUrl)
            ) {
              showLoading()

              const imageDeleteResponse = await deleteImage(uploadImgUrl)

              if (imageDeleteResponse.status === 200) {
                const imageUploadResponse = await uploadImage(imgFile)
                console.log(imageUploadResponse)

                if (imageUploadResponse.status === 200) {
                  setImgUrlText(imageUploadResponse.data.imageUrl)
                  setUploadImgUrl(imageUploadResponse.data.imageUrl)
                  setImgFile(null)
                  setAddChangeMediaModalVisible(false)
                }
              }

              hideLoading()
            } else {
              showLoading()

              setImgUrlText('')
              setUploadImgUrl('')
              setImgFile(null)
              setAddChangeMediaModalVisible(false)

              hideLoading()
            }
          }}
          onClose={() => {
            setImgUrlText(uploadImgUrl)
            setImgFile(null)
            setAddChangeMediaModalVisible(false)
          }}
          rightBtnText={'저장'}
          rightBtnDisabled={false}
          questionSection={<p>---</p>}
          questionWidth={500}
          questionChangeWidth={180}
        ></NormalModal>
      )}
      {editAddMediaModalVisible && (
        <NormalModal
          titleText={`이미지 첨부`}
          leftBtnVisible={true}
          modalBodyComponent={
            <div>
              <div
                style={{
                  height: 175,
                  color: '#d3d3d3',
                  backgroundSize: 'cover',
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundPosition: 'center center',
                  backgroundRepeat: 'no-repeat',
                  alignItems: 'center',
                  justifyContent: 'center',
                  boxSizing: 'border-box',
                  backgroundImage: `url(${imgUrlText || 'unknown'})`,
                }}
              >
                {imgUrlText === '' || imgUrlText === null ? (
                  <CameraIcon></CameraIcon>
                ) : (
                  <></>
                )}
              </div>
              <TitleBigDiv>이미지 삽입</TitleBigDiv>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <input
                  type="file"
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  accept=".png, .jpg, .jpeg"
                />
                <IconTextCircleBtn
                  text={'이미지 첨부'}
                  borderColor={'#ededed'}
                  backgroundColor={'#ededed'}
                  textColor={'#000'}
                  onClick={() => {
                    fileInputRef.current.click()
                  }}
                >
                  <CameraIconFixed></CameraIconFixed>
                </IconTextCircleBtn>
              </div>
            </div>
          }
          onRightBtnClick={async () => {
            showLoading()

            const imageUploadResponse = await uploadImage(imgFile)
            console.log(imageUploadResponse)

            if (imageUploadResponse.status === 200) {
              setImgUrlText(imageUploadResponse.data.imageUrl)
              setUploadImgUrl(imageUploadResponse.data.imageUrl)
              setImgFile(null)
              setEditAddMediaModalVisible(false)
            }

            hideLoading()
          }}
          onClose={() => {
            setImgUrlText('')
            setUploadImgUrl('')
            setImgFile(null)
            setEditAddMediaModalVisible(false)
          }}
          rightBtnText={'저장'}
          rightBtnDisabled={false}
          questionSection={<p>---</p>}
          questionWidth={500}
          questionChangeWidth={180}
        ></NormalModal>
      )}
      {editChangeMediaModalVisible && (
        <NormalModal
          titleText={`수정하기`}
          leftBtnVisible={true}
          modalBodyComponent={
            <div>
              <div
                style={{
                  height: 200,
                  color: '#d3d3d3',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: 5,
                  border: '1px solid #d3d3d3',
                  position: 'relative',
                }}
              >
                {imgUrlText ? (
                  <img
                    src={imgUrlText}
                    alt="Media"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      borderRadius: 5,
                    }}
                  />
                ) : (
                  <CameraIcon></CameraIcon>
                )}
              </div>
              <TitleBigDiv>이미지 삽입</TitleBigDiv>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <input
                  type="file"
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  accept=".png, .jpg, .jpeg"
                />
                <IconTextCircleBtn
                  text={'이미지 첨부'}
                  borderColor={'#ededed'}
                  backgroundColor={'#ededed'}
                  textColor={'#000'}
                  onClick={() => {
                    fileInputRef.current.click()
                  }}
                >
                  <CameraIconFixed></CameraIconFixed>
                </IconTextCircleBtn>
              </div>
            </div>
          }
          onRightBtnClick={async () => {
            console.log(imgFile)
            console.log(uploadImgUrl)
            console.log(
              `https://doogomimages3.s3.ap-northeast-2.amazonaws.com/default_guide/${imgFile.name}`
            )
            console.log(
              isMatchingDefaultGuideImageURL(
                `https://doogomimages3.s3.ap-northeast-2.amazonaws.com/default_guide/${imgFile.name}`
              )
            )
            if (
              imgFile !== null &&
              !isMatchingDefaultGuideImageURL(
                `https://doogomimages3.s3.ap-northeast-2.amazonaws.com/default_guide/${imgFile.name}`
              )
            ) {
              showLoading()

              const imageUploadResponse = await uploadImage(imgFile)
              console.log(imageUploadResponse)

              if (imageUploadResponse.status === 200) {
                setImgUrlText(imageUploadResponse.data.imageUrl)
                setUploadImgUrl(imageUploadResponse.data.imageUrl)
                setImgFile(null)
                setEditChangeMediaModalVisible(false)
              }

              hideLoading()
            } else {
              showLoading()

              setImgUrlText(uploadImgUrl)
              setImgFile(null)
              setEditChangeMediaModalVisible(false)

              hideLoading()
            }
          }}
          onClose={() => {
            setImgUrlText(uploadImgUrl)
            setImgFile(null)
            setEditChangeMediaModalVisible(false)
          }}
          rightBtnText={'저장'}
          rightBtnDisabled={false}
          questionSection={<p>---</p>}
          questionWidth={500}
          questionChangeWidth={180}
        ></NormalModal>
      )}
      {deleteAddMediaModalVisible && (
        <StandardModal
          titleText={'이미지 삭제'}
          modalBodyComponent={<div>정말 삭제하겠습니까?</div>}
          onRightBtnClick={async () => {
            showLoading()

            if (!isMatchingDefaultGuideImageURL(uploadImgUrl)) {
              const imageDeleteResponse = await deleteImage(uploadImgUrl)
              console.log(imageDeleteResponse)

              if (imageDeleteResponse.status === 200) {
                setImgUrlText('')
                setUploadImgUrl('')
                setImgFile(null)
                setDeleteAddMediaModalVisible(false)
              }
            }

            hideLoading()
          }}
          onClose={() => {
            setDeleteAddMediaModalVisible(false)
          }}
          rightBtnText={'삭제'}
        ></StandardModal>
      )}
      {deleteEditMediaModalVisible && (
        <StandardModal
          titleText={'이미지 삭제'}
          modalBodyComponent={<div>정말 삭제하겠습니까?</div>}
          onRightBtnClick={async () => {
            showLoading()

            if (!isMatchingDefaultGuideImageURL(uploadImgUrl)) {
              const imageDeleteResponse = await deleteImage(uploadImgUrl)
              console.log(imageDeleteResponse)

              if (imageDeleteResponse.status === 200) {
                setImgUrlText('')
                setUploadImgUrl('')
                setImgFile(null)
                setDeleteEditMediaModalVisible(false)
              }
            }

            hideLoading()
          }}
          onClose={() => {
            setDeleteEditMediaModalVisible(false)
          }}
          rightBtnText={'삭제'}
        ></StandardModal>
      )}
      {deleteAddLocationModalVisible && (
        <StandardModal
          titleText={'장소 삭제'}
          modalBodyComponent={<div>정말 삭제하겠습니까??</div>}
          onRightBtnClick={() => {
            setChoosePosition(false)
            setTopicPosition({
              latitude: null,
              longitude: null,
            })
            setDeleteAddLocationModalVisible(false)
          }}
          onClose={() => {
            setDeleteAddLocationModalVisible(false)
          }}
          rightBtnText={'삭제'}
        ></StandardModal>
      )}
      {deleteEditLocationModalVisible && (
        <StandardModal
          titleText={'장소 삭제'}
          modalBodyComponent={<div>정말 삭제하겠습니까?</div>}
          onRightBtnClick={() => {
            setChoosePosition(false)
            setTopicPosition({
              latitude: null,
              longitude: null,
            })
            setDeleteEditLocationModalVisible(false)
          }}
          onClose={() => {
            setDeleteEditLocationModalVisible(false)
          }}
          rightBtnText={'삭제'}
        ></StandardModal>
      )}
      {linksAndQRModalVisible && (
        <NormalModal
          titleText={`링크 & QR 얻기`}
          leftBtnVisible={true}
          middleBtnVisible={true}
          modalBodyComponent={
            <div>
              <TitleBigDiv>링크를 복사하거나 QR을 얻으시겠습니까?</TitleBigDiv>
            </div>
          }
          onRightBtnClick={async () => {
            await generate_and_download_QR(guide.shareUrl)
            setLinksAndQRModalVisible(false)
          }}
          onMiddleBtnClick={async () => {
            try {
              await navigator.clipboard.writeText(`${guide.shareUrl}`)
              show('링크가 복사되었습니다.')
            } catch (err) {
              console.error('Failed to copy: ', err)
            }
            setLinksAndQRModalVisible(false)
          }}
          onClose={() => {
            setLinksAndQRModalVisible(false)
          }}
          middleBtnText={'링크 복사하기'}
          rightBtnText={'QR 다운로드'}
          questionSection={<p>---</p>}
          questionWidth={500}
          questionChangeWidth={180}
        ></NormalModal>
      )}
    </>
  )
}

export default MainGuidesGuidesContentTab

const EditorTotalDiv = styled.div`
  .editorClassName {
    height: 250px; /* 높이 설정 */
    overflow: auto; /* 내용이 넘칠 때 스크롤바 자동 생성 */
    border: 1px solid #ddd; /* 에디터 테두리 (선택사항) */
    padding: 10px; /* 내부 여백 (선택사항) */
    border: 1px solid #ccc;
  }

  .rdw-dropdownoption-default.rdw-fontsize-option {
    font-size: 12px;
    width: 30px;
  }

  .rdw-colorpicker-modal-style-label {
    font-size: 12px;
  }
`

const CategoryIconSection = styled.div`
  margin-bottom: 20px;
  position: relative;
  box-sizing: border-box;
`

const CategoryIconSectionInner = styled.div`
  align-items: center;
  display: flex;
  box-sizing: border-box;
`

const CategoryIconSectionInnerBtn = styled.button`
  padding: 8px 16px;
  border-radius: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #000;
  background-color: #ededed;
  border-color: #ededed;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  border: 1px solid #dcdfe6;

  @media (max-width: 1024px) {
    padding: 8px;
    font-size: 10px;
  }
`

const CategoryIconSectionInnerIcon = styled.div`
  margin-left: 15px;
  font-size: 1.5rem;
  box-sizing: border-box;
  color: #000;
`

const CategoryIconSectionPopup = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
  background-color: #fff;
  z-index: 10001;
  box-shadow: 0 1px 9px #00000040;
  width: 100%;
  max-height: 300px;
  overflow: auto;
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`

const CategoryIconSectionPopupIcon = styled.div`
  cursor: pointer;
  padding: 5px;
  transition: background-color 0.1s ease-in-out;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  box-sizing: border-box;
  word-break: break-all;
`

const RotatedIcon = styled(FontAwesomeIcon)`
  transform: rotateX(360deg) rotateY(180deg) rotateZ(90deg);
`

const NoImageIconContainer = styled.div`
  width: 48px;
  height: 48px;
  background-color: #ededed;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

const Container = styled.div`
  margin-top: 10px;
  width: 100%;
  margin-bottom: 10px;
`

const AccordionButton = styled.div`
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  background-color: ${({ open }) => (open ? '#b49275' : '#b49275')};
  color: ${({ open }) => (open ? 'white' : 'white')};
  padding: ${({ open }) => (open ? '20px 10px' : '20px 10px')};
  border-radius: 5px;
  cursor: pointer;
`

const SubcategoryButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  background-color: ${({ open }) => (open ? 'white' : 'white')};
  color: ${({ open }) => (open ? 'black' : 'black')};
  padding: ${({ open }) => (open ? '15px 10px' : '15px 10px')};
  border: solid 1px #dedede;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
`

const Panel = styled.div`
  display: ${({ $isopen }) => ($isopen ? 'block' : 'none')};
  margin-top: 10px;
  margin-left: 20px;
`

const SubPanel = styled.div`
  display: ${({ $isopen }) => ($isopen ? 'block' : 'none')};
  margin-top: 10px;
  margin-left: 20px;
`

const Topic = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  background-color: #f8f8f8;
  padding-right: 10px;
  border-radius: 5px;
  margin-top: 10px;
  height: 50px;
  border: solid 1px #dedede;
`

const BigDashedButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #b49275;
  color: #b49275;
  padding: 20px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: #b49275;
    color: white;
  }
`

const MiddleDashedButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #b49275;
  color: #b49275;
  padding: 15px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: #b49275;
    color: white;
  }
`

const SmallDashedButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #b49275;
  color: #b49275;
  padding: 10px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: #b49275;
    color: white;
  }
`

const GuideContentBody2 = styled.div`
  position: relative;
  padding: 0 10%;
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-basis: auto;
  overflow-y: auto;
  box-sizing: border-box;
  align-items: center;

  @media (max-width: 1024px) {
    padding: 0;
  }
`

const GuideContentBody2Inner = styled.div`
  position: absolute;
  top: 0;
  width: 80%;
  padding: 0;

  @media (max-width: 1024px) {
    width: 95%;
  }
`
