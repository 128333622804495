import { createContext, useContext, useState } from 'react'

// Context 이름 변경
const SelectStoreHeaderContext = createContext()

// Hook 이름 변경
export const useSelectStoreHeader = () => {
  return useContext(SelectStoreHeaderContext)
}

export function SelectStoreHeaderProvider({ children }) {
  const [selectStoreHeaderIndex, setSelectStoreHeaderIndex] = useState(
    parseInt(window.localStorage.getItem('selectStoreHeaderIndex')) || 0
  )

  // 함수명 변경
  const setStoreHeaderIndex = (index) => {
    window.localStorage.setItem('selectStoreHeaderIndex', index)
    setSelectStoreHeaderIndex(index)
  }

  const value = {
    selectStoreHeaderIndex,
    setStoreHeaderIndex,
  }

  return (
    <SelectStoreHeaderContext.Provider value={value}>
      {children}
    </SelectStoreHeaderContext.Provider>
  )
}
