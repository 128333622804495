export function generate_static_map_url(
  width = 600,
  height = 400,
  latitude,
  longitude,
) {
  console.log(
    'generate_static_map_url: width, height, latitude, longitude',
    width,
    height,
  );
  const baseUrl = 'https://maps.googleapis.com/maps/api/staticmap';
  const center = `center=${latitude},${longitude}`;
  const zoom = 'zoom=15';
  const size = `size=${width}x${height}`;
  const key = 'key=AIzaSyDBzwhnMMky4OXEgk6aF1oT1n9yei-roOo';
  const markers = `markers=color:red|${latitude},${longitude}`;

  return `${baseUrl}?${center}&${zoom}&${size}&${key}&${markers}`;
}
