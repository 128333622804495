import {
  c_244556,
  c_353431,
  c_3ed9cc,
  c_b49275,
  c_ffcc96,
  c_ffffff,
} from '../../design_system/colors'
import { useEffect, useState } from 'react'
import { Footer } from '../../design_system/components/footer/Footer'
import { TextInput } from '../../design_system/components/text_input/TextInput'
import { emailCheck } from '../../data_system/data_function/email_check_function'
import PcSubHeader from '../../design_system/components/header/pc_sub_header/PcSubHeader'
import { Section } from '../../design_system/components/section/Section'
import { Container } from '../../design_system/components/container/Container'
import { Body } from '../../design_system/components/body/Body'
import { TitleH1 } from '../../design_system/components/title_h1/TitleH1'
import { OpacityBtnA } from '../../design_system/components/btn/pc/opacity_btn_a/OpacityBtnA'
import { OpacityBtn } from '../../design_system/components/btn/pc/opacity_btn/OpacityBtn'
import { ContentText } from '../../design_system/components/content_text/ContentText'
import {
  forgotten_password_sent_inner_router_url,
  login_inner_router_url,
} from '../../communication_system/inner_router_url/inner_router_url'
import { forgotPassword } from '../../communication_system/axios_apis/axios_apis'
import { useNavigate } from 'react-router-dom'

const ForgottenPasswordPage = () => {
  let navigate = useNavigate()

  const [createAccountBtnDisabled, setCreateAccountBtnDisablede] =
    useState(true)

  const [email, setEmail] = useState('')

  useEffect(() => {
    if (emailCheck.test(email)) {
      setCreateAccountBtnDisablede(false)
    } else {
      setCreateAccountBtnDisablede(true)
    }
  }, [email])

  return (
    <>
      <PcSubHeader>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        ></div>
        <div></div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div></div>
        </div>
      </PcSubHeader>
      <Section>
        <Container>
          <div>
            <Body>
              <TitleH1>비밀번호 찾기</TitleH1>
              <div
                style={{
                  paddingBottom: 20,
                }}
              >
                <ContentText>
                  가입 시 등록한 이메일 주소를 적어주시면 비밀번호 변경 링크를
                  이메일로 보내드립니다.
                </ContentText>
              </div>
              <TextInput
                type="text"
                autoComplete="off"
                placeholder="Email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
              ></TextInput>
              <div
                style={{
                  paddingTop: 30,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <OpacityBtnA
                  fontColor={c_ffffff}
                  backgroundColor={c_b49275}
                  href={login_inner_router_url}
                >
                  뒤로가기
                </OpacityBtnA>
                <OpacityBtn
                  fontColor={c_353431}
                  backgroundColor={c_ffcc96}
                  disabled={createAccountBtnDisabled}
                  onClick={async () => {
                    console.log('Send password reset email')
                    const response = await forgotPassword(email)

                    console.log(response)
                    if (response.status === 200) {
                      navigate(forgotten_password_sent_inner_router_url)
                    } else {
                      console.error('Error during sending password reset email')
                    }
                  }}
                >
                  제출하기
                </OpacityBtn>
              </div>
            </Body>
          </div>
        </Container>
        <Footer></Footer>
      </Section>
    </>
  )
}

export default ForgottenPasswordPage
