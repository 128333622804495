import React, { createContext, useContext, useState } from 'react'
import { useJsApiLoader } from '@react-google-maps/api'

const GoogleMapsContext = createContext()

export const useGoogleMaps = () => useContext(GoogleMapsContext)

const libraries = ['places']

export const GoogleMapsProvider = ({ children, googleMapsApiKey }) => {
  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: googleMapsApiKey,
    libraries, // 예를 들어 places 라이브러리를 사용
  })

  const value = {
    isLoaded,
    loadError,
  }

  return (
    <GoogleMapsContext.Provider value={value}>
      {children}
    </GoogleMapsContext.Provider>
  )
}
