import { useState } from 'react';
import { ArrowDownBlackIcon, QuestionMarkInCircle } from '../../icons/icons';
import IconTooltip from '../icon_tooltip/IconTooltip';
import styled from 'styled-components';
import { TooltipItem } from '../tooltip_item/TooltipItem';

const DownloadsTile = ({ download }) => {
  const [downloadQVisible, setDownloadQVisible] = useState(false);

  const handleDownloadQClick = () => setDownloadQVisible(!downloadQVisible);

  const [downloadDropdownVisible, setDownloadDropdownVisible] = useState(false);

  const handleDownloadDropdownClick = () =>
    setDownloadDropdownVisible(!downloadDropdownVisible);

  return (
    <DownloadsTileFrame>
      <DownloadsTileLeftPart>
        {download.title}
        <div
          style={{
            marginLeft: 8,
          }}
        >
          <IconTooltip
            icon={
              <QuestionMarkInCircle
                onClick={handleDownloadQClick}
              ></QuestionMarkInCircle>
            }
            handleClick={handleDownloadQClick}
            visible={downloadQVisible}
            tooltipTop={2.5}
            tooltipLeft={30}
            tooltipDefaultWidth={140}
            tooltipChangeWidth={140}
          >
            asfsa
          </IconTooltip>
        </div>
      </DownloadsTileLeftPart>
      <DownloadsTileRightPart>
        <IconTooltip
          icon={
            <DownloadBtn onClick={handleDownloadDropdownClick}>
              Download{' '}
              <div
                style={{
                  marginLeft: 8,
                }}
              >
                <ArrowDownBlackIcon></ArrowDownBlackIcon>
              </div>
            </DownloadBtn>
          }
          handleClick={handleDownloadDropdownClick}
          visible={downloadDropdownVisible}
          tooltipTop={30}
          tooltipRight={0}
          tooltipDefaultWidth={140}
          tooltipChangeWidth={140}
        >
          <TooltipItem onClick={handleDownloadDropdownClick}>
            Download CSV
          </TooltipItem>
          <TooltipItem onClick={handleDownloadDropdownClick}>
            Download XLSX
          </TooltipItem>
        </IconTooltip>
      </DownloadsTileRightPart>
    </DownloadsTileFrame>
  );
};

export default DownloadsTile;

const DownloadsTileFrame = styled.div`
  height: 75px;
  border-radius: 8px;
  background-color: #fff;
  padding: 10px 30px;
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    padding: 10px;
  }
`;

const DownloadsTileLeftPart = styled.div`
  font-size: 1.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    font-size: 1rem;
  }
`;

const DownloadsTileRightPart = styled.div`
  color: #000 !important;
  display: inline-block;
  position: relative;
  font-size: 14px;
  box-sizing: border-box;
`;

const DownloadBtn = styled.button`
  padding: 8px 16px;
  border-radius: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #ededed;
  border-color: #ededed;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  font-weight: 500;
  font-size: 14px;
`;
