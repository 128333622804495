import styled from 'styled-components'
import { c_ffffff } from '../../../colors'
import React from 'react'

const PcSubUpHeader = ({ children }) => {
  return <HeaderFrame>{children}</HeaderFrame>
}

export default React.memo(PcSubUpHeader)

const HeaderFrame = styled.div`
  width: 100%;
  height: 75px;
  box-sizing: border-box;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  background-color: ${c_ffffff};
  overflow: hidden;

  @media (min-width: 650px) {
    padding: 20px 40px;
  }
`
