import {
  c_244556,
  c_353431,
  c_3ed9cc,
  c_b49275,
  c_ffcc96,
  c_ffffff,
} from '../../design_system/colors'
import { useEffect, useState } from 'react'
import { Footer } from '../../design_system/components/footer/Footer'
import PcSubHeader from '../../design_system/components/header/pc_sub_header/PcSubHeader'
import { Section } from '../../design_system/components/section/Section'
import { Container } from '../../design_system/components/container/Container'
import { Body } from '../../design_system/components/body/Body'
import { TitleH1 } from '../../design_system/components/title_h1/TitleH1'
import { OpacityBtnA } from '../../design_system/components/btn/pc/opacity_btn_a/OpacityBtnA'
import { OpacityBtn } from '../../design_system/components/btn/pc/opacity_btn/OpacityBtn'
import { login_inner_router_url } from '../../communication_system/inner_router_url/inner_router_url'
import { TextDiv } from '../../design_system/components/text_div/TextDiv'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { resetPassword } from '../../communication_system/axios_apis/axios_apis'
import { PasswordInput } from '../../design_system/components/password_input/PasswordInput'

const ResetPasswordPage = () => {
  const { token } = useParams()
  let navigate = useNavigate()

  const [changeMyPasswordBtnDisabled, setChangeMyPasswordBtnDisabled] =
    useState(true)

  const [newPassword, setNewPassword] = useState('')
  const [newPasswordVisible, setNewPasswordVisible] = useState(false)
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('')
  const [newPasswordConfirmationVisible, setNewPasswordConfirmationVisible] =
    useState(false)

  useEffect(() => {
    if (
      newPassword !== '' &&
      newPasswordConfirmation !== '' &&
      newPassword === newPasswordConfirmation
    ) {
      setChangeMyPasswordBtnDisabled(false)
    } else {
      setChangeMyPasswordBtnDisabled(true)
    }
  }, [newPassword, newPasswordConfirmation])

  return (
    <>
      <PcSubHeader>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        ></div>
        <div></div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div></div>
        </div>
      </PcSubHeader>
      <Section>
        <Container>
          <div>
            <Body>
              <TitleH1>비밀번호 변경하기</TitleH1>
              <div
                style={{
                  marginTop: 15,
                }}
              >
                <TextDiv>새로운 비밀번호*</TextDiv>
              </div>
              <PasswordInput
                password={newPassword}
                setPassword={setNewPassword}
                passwordVisible={newPasswordVisible}
                setPasswordVisible={setNewPasswordVisible}
              ></PasswordInput>
              <div
                style={{
                  marginTop: 15,
                }}
              >
                <TextDiv>비밀번호 확인*</TextDiv>
              </div>
              <PasswordInput
                password={newPasswordConfirmation}
                setPassword={setNewPasswordConfirmation}
                passwordVisible={newPasswordConfirmationVisible}
                setPasswordVisible={setNewPasswordConfirmationVisible}
              ></PasswordInput>
              <div
                style={{
                  paddingTop: 30,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <OpacityBtnA
                  fontColor={c_ffffff}
                  backgroundColor={c_b49275}
                  href={login_inner_router_url}
                >
                  Back
                </OpacityBtnA>
                <OpacityBtn
                  fontColor={c_353431}
                  backgroundColor={c_ffcc96}
                  disabled={changeMyPasswordBtnDisabled}
                  onClick={async () => {
                    console.log('Send password reset')
                    const response = await resetPassword(token, newPassword)

                    if (response.status === 200) {
                      navigate(login_inner_router_url)
                    } else {
                      console.error('Error during resetting password')
                    }
                  }}
                >
                  비밀번호 변경하기
                </OpacityBtn>
              </div>
            </Body>
          </div>
        </Container>
        <Footer></Footer>
      </Section>
    </>
  )
}

export default ResetPasswordPage
