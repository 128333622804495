import React, { useState, useEffect, forwardRef } from 'react'
import { useSpring, animated } from 'react-spring'
import styled from 'styled-components'

const MainAnimatedView = forwardRef(
  (
    {
      children,
      translateYFirstNum,
      translateYLastNum,
      scaleFirstNum,
      scaleLastNum,
      delay = 0,
      threshold = 0.5,
    },
    ref
  ) => {
    const [isVisible, setIsVisible] = useState(false)
    const [lastScrollTop, setLastScrollTop] = useState(0)

    const checkVisibility = () => {
      if (!ref.current) return

      const elementTop = ref.current.getBoundingClientRect().top
      const viewportHeight = window.innerHeight
      const isScrollingDown = document.documentElement.scrollTop > lastScrollTop

      if (isScrollingDown && elementTop <= viewportHeight * threshold) {
        setIsVisible(true)
      } else if (!isScrollingDown && elementTop > viewportHeight * threshold) {
        setIsVisible(false)
      }

      setLastScrollTop(document.documentElement.scrollTop)
    }

    useEffect(() => {
      window.addEventListener('scroll', checkVisibility)
      return () => window.removeEventListener('scroll', checkVisibility)
    }, [lastScrollTop])

    const animationStyle = useSpring({
      opacity: isVisible ? 1 : 0,
      transform: isVisible
        ? `scale(${scaleFirstNum}) translateY(${translateYFirstNum}px)`
        : `scale(${scaleLastNum}) translateY(${translateYLastNum}px)`,
      delay: isVisible ? delay : 0,
    })

    return (
      <animated.div ref={ref} style={animationStyle}>
        {children}
      </animated.div>
    )
  }
)

export default MainAnimatedView
