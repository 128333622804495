import styled from 'styled-components'
import {
  about_us_inner_router_url,
  customer_service_center_inner_router_url,
  faq_inner_router_url,
  price_inner_router_url,
  privacy_policy_inner_router_url,
  terms_and_conditions_inner_router_url,
} from '../../../communication_system/inner_router_url/inner_router_url'

export const MainFooter = () => {
  return (
    <>
      <FooterContainer>
        <FooterContainerInner>
          <FooterContainerInnerFirst>
            <FooterContainerInnerFirstTitle>
              DOOGOM
            </FooterContainerInnerFirstTitle>
            <div
              style={{
                fontSize: 15,
              }}
            >
              프로페셔널 호스트를 위한 필수 아이템<br></br>
              <br></br>Less work,<br></br>More profit,<br></br>Better
              experience.
            </div>
          </FooterContainerInnerFirst>
          <FooterContainerInnerSecond>
            <FooterContainerInnerSecondTitle>
              SITE
            </FooterContainerInnerSecondTitle>
            <div
              style={{
                display: 'flex',
              }}
            >
              <FooterContainerInnerSecondSection>
                <span
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    const baseUrl = window.location.origin
                    const path = `${about_us_inner_router_url}`
                    const url = `${baseUrl}${path}`

                    window.open(url, '_blank')
                  }}
                >
                  회사소개
                </span>
                <br></br>
                <span
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    const baseUrl = window.location.origin
                    const path = `${customer_service_center_inner_router_url}`
                    const url = `${baseUrl}${path}`

                    window.open(url, '_blank')
                  }}
                >
                  고객센터
                </span>
                <br></br>
                <span
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    const baseUrl = window.location.origin
                    const path = `${customer_service_center_inner_router_url}`
                    const url = `${baseUrl}${path}`

                    window.open(url, '_blank')
                  }}
                >
                  제휴문의
                </span>
                <br></br>
                <span
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    const baseUrl = window.location.origin
                    const path = `${privacy_policy_inner_router_url}`
                    const url = `${baseUrl}${path}`

                    window.open(url, '_blank')
                  }}
                >
                  개인정보처리방침
                </span>
              </FooterContainerInnerSecondSection>
              <div
                style={{
                  fontSize: 14,
                }}
              >
                <span
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    const baseUrl = window.location.origin
                    const path = `${price_inner_router_url}`
                    const url = `${baseUrl}${path}`

                    window.open(url, '_blank')
                  }}
                >
                  비용
                </span>
                <br></br>
                <span
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    const baseUrl = window.location.origin
                    const path = `${'/show-guide/6350f2b1-16dc-42e7-8d5b-8abb9c0ffdd1'}`
                    const url = `${baseUrl}${path}`

                    window.open(url, '_blank')
                  }}
                >
                  데모 보기
                </span>
                <br></br>
                <span
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    const baseUrl = window.location.origin
                    const path = `${faq_inner_router_url}`
                    const url = `${baseUrl}${path}`

                    window.open(url, '_blank')
                  }}
                >
                  자주 묻는 질문
                </span>
                <br></br>
                <span
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    const baseUrl = window.location.origin
                    const path = `${terms_and_conditions_inner_router_url}`
                    const url = `${baseUrl}${path}`

                    window.open(url, '_blank')
                  }}
                >
                  이용약관
                </span>
              </div>
            </div>
          </FooterContainerInnerSecond>
        </FooterContainerInner>
      </FooterContainer>
      <BottomContainer>
        <BottomContent>&#9426; DOOGOM / All rights reserved</BottomContent>
        <br></br>
        <BottomContentSection>
          <div>
            두곰&nbsp;&nbsp;/&nbsp;&nbsp;444-14-02165&nbsp;&nbsp;/&nbsp;&nbsp;서울특별시
            마포구 만리재로 83 나경빌딩 13층&nbsp;&nbsp;/&nbsp;&nbsp;대표
            김진호&nbsp;&nbsp;/&nbsp;&nbsp;고객센터:
            070-8121-3625&nbsp;&nbsp;/&nbsp;&nbsp;doogom@doogom.com
          </div>
        </BottomContentSection>
      </BottomContainer>
    </>
  )
}

const BottomContainer = styled.div`
  width: 100%;
  background-color: #f4f4f4;
  padding: 30px 0px 30px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 991px) {
    padding: 30px 0px 30px 0px;
  }
`

const BottomContent = styled.div`
  font-size: 12px;
  line-height: 1.2 !important;
  font-family: noto_sans_kr_regular;
  color: #757575;
`

const BottomContentSection = styled.div`
  font-size: 12px;
  line-height: 1.2 !important;
  font-family: noto_sans_kr_regular;
  color: #757575;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media (max-width: 991px) {
    max-width: 80%;
  }
`

const FooterContainer = styled.div`
  background: #2d2520;
  color: #9d8c81;
  font-size: 15px;
  line-height: 21px;
  box-sizing: border-box;
`

const FooterContainerInner = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: 80%;
  padding: 24px;
  box-sizing: inherit;
  justify-content: center;

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: center;
  }
`

const FooterContainerInnerFirst = styled.div`
  box-sizing: inherit;
  margin-right: 280px;

  @media (max-width: 991px) {
    margin-right: 0px;
  }
`

const FooterContainerInnerFirstTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  letter-spacing: normal;
  margin-bottom: 28px;
  text-transform: none;
  color: #fff;
  line-height: 21px;
  box-sizing: inherit;

  @media (max-width: 991px) {
    margin-bottom: 16px;
  }
`

const FooterContainerInnerSecond = styled.div`
  box-sizing: inherit;
  text-align: left;
  line-height: 28px;

  @media (max-width: 991px) {
    margin-top: 40px;
  }
`

const FooterContainerInnerSecondTitle = styled.div`
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.08em;
  line-height: 21px;
  margin-bottom: 26px;
  box-sizing: inherit;

  @media (max-width: 991px) {
    margin-bottom: 16px;
  }
`

const FooterContainerInnerSecondSection = styled.div`
  margin-right: 50px;
  font-size: 14px;
`
