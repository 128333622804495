export const sign_up_inner_router_url = '/sign-up'
export const login_inner_router_url = '/login'
export const about_us_inner_router_url = '/about-us'
export const customer_service_center_inner_router_url =
  '/customer-service-center'
export const price_inner_router_url = '/price'
export const forgotten_password_inner_router_url = '/forgotten-password'
export const forgotten_password_sent_inner_router_url =
  '/forgotten-password-sent'
export const reset_password_inner_router_url = '/reset-password/:token'
export const terms_and_conditions_inner_router_url = '/terms-and-conditions'
export const store_terms_and_conditions_inner_router_url =
  '/store-terms-and-conditions'
export const faq_inner_router_url = '/faq'
export const privacy_policy_inner_router_url = '/privacy-policy'
export const store_privacy_policy_inner_router_url = '/store-privacy-policy'
export const show_guide_inner_router_url = '/show-guide/:token'
export const show_default_guide_inner_router_url = '/show-default-guide/:token'
export const main_guides_inner_router_url = '/main-guides'
export const main_guides_all_inner_router_url = '/main-guides/*'
export const s_guides_inner_router_url = '/guides'
export const guides_inner_router_url = 'guides'
export const default_guide_inner_router_url = 'default-guide'
export const store_inner_router_url = 'store'
export const help_support_inner_router_url = 'help-support'
export const create_guide_inner_router_url = 'create-guide'
export const downloads_inner_router_url = 'downloads'
export const main_guides_guides_inner_router_url = '/main-guides/guides'
export const main_guides_default_guide_inner_router_url =
  '/main-guides/default-guide'
export const main_guides_store_inner_router_url = '/main-guides/store'
export const main_guides_help_support_inner_router_url =
  '/main-guides/help-support'
export const main_guides_create_guide_inner_router_url =
  '/main-guides/create-guide'
export const main_guides_downloads_inner_router_url = '/main-guides/downloads'
export const main_guides_settings_inner_router_url = '/main-guides/settings'
export const settings_inner_router_url = 'settings'
export const main_guides_payment_history_inner_router_url =
  '/main-guides/payment-history'
export const payment_history_inner_router_url = 'payment-history'
