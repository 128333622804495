import styled from 'styled-components'
import { Container } from '../../../design_system/components/container/Container'
import { TextInput } from '../../../design_system/components/text_input/TextInput'
import { OpacityBtn } from '../../../design_system/components/btn/pc/opacity_btn/OpacityBtn'
import {
  c_244556,
  c_3ed9cc,
  c_b49275,
  c_eba55d,
  c_ffffff,
} from '../../../design_system/colors'
import { useEffect, useState } from 'react'
import { koreanPhoneNumberCheck } from '../../../data_system/data_function/korean_phone_number_check_function'

const Page2 = ({
  setPageNumber,
  guideName,
  setGuideName,
  phoneNumber,
  setPhoneNumber,
}) => {
  const [submitDisabledCheck, setSubmitDisabledCheck] = useState(true)

  useEffect(() => {
    if (guideName !== '' && koreanPhoneNumberCheck.test(phoneNumber)) {
      setSubmitDisabledCheck(false)
    } else {
      setSubmitDisabledCheck(true)
    }
  }, [guideName, phoneNumber])

  return (
    <Container>
      <div>
        <Page2Body>
          <Page2PStepText>Step 1</Page2PStepText>
          <Page2TitleH1>온라인 안내문 이름 정하기</Page2TitleH1>
          <Page2PContentText>추후에 변경할 수 있습니다.</Page2PContentText>
          <div
            style={{
              marginTop: 15,
            }}
          >
            <Page2TextDiv>숙소 이름을 적어주세요. *</Page2TextDiv>
          </div>
          <TextInput
            type="text"
            autoComplete="off"
            placeholder="ex) Seoul Stay"
            value={guideName}
            onChange={(e) => {
              setGuideName(e.target.value)
            }}
          ></TextInput>
          <div
            style={{
              marginTop: 15,
            }}
          >
            <Page2TextDiv>핸드폰 번호를 적어주세요. *</Page2TextDiv>
          </div>
          <TextInput
            type="text"
            autoComplete="off"
            placeholder="- 없이 입력해주세요"
            value={phoneNumber}
            onChange={(e) => {
              setPhoneNumber(e.target.value)
            }}
          ></TextInput>
          <div
            style={{
              paddingTop: 30,
              display: 'flex',
            }}
          >
            <OpacityBtn
              fontColor={c_ffffff}
              backgroundColor={c_b49275}
              onClick={() => {
                setPageNumber(1)
              }}
            >
              뒤로가기
            </OpacityBtn>
            <div
              style={{
                marginLeft: 10,
              }}
            >
              <OpacityBtn
                fontColor={c_ffffff}
                backgroundColor={c_eba55d}
                disabled={submitDisabledCheck}
                onClick={() => {
                  setPageNumber(3)
                }}
              >
                제출하기
              </OpacityBtn>
            </div>
          </div>
        </Page2Body>
      </div>
    </Container>
  )
}

export default Page2

const Page2PStepText = styled.p`
  font-size: 1.25rem;
  line-height: 1.5;
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  letter-spacing: 1px;
  color: ${c_244556};
  width: max-content;
  box-sizing: border-box;
  margin: 0;
`

const Page2Body = styled.div`
  width: 100%;
  height: 60vh;
  box-sizing: border-box;
  float: left;

  @media only screen and (min-width: 992px) {
    width: 54.16667%;
  }

  @media only screen and (min-width: 1200px) {
    width: 50%;
  }
`

const Page2TitleH1 = styled.h1`
  width: auto;
  line-height: 3.375rem;
  font-size: 3rem;
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  letter-spacing: 1px;
  color: #eba55d;
  margin: 10px 0;
  box-sizing: border-box;

  @media (max-width: 1600px) {
    font-size: 2.25rem;
  }

  @media (max-width: 1024px) {
    padding: 10px 50px 20px 0;
  }

  @media (max-width: 650px) {
    font-size: 2rem;
    max-width: 375px;
  }
`

const Page2PContentText = styled.p`
  line-height: 1.5;
  font-size: 1rem;
  color: ${c_244556};
  font-family: Circular Std Book, Tahoma, SansSerif;
  margin: 0;
  box-sizing: border-box;
`

const Page2TextDiv = styled.div`
  padding: 8px 0;
  margin-top: 0px;
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  color: ${c_244556};
  font-size: 16px;
  font-weight: 700;
`
