import styled from 'styled-components'
import IconTextCircleBtn from '../btn/pc/icon_text_circle_btn/IconTextCircleBtn'
import { ArrowLeftIconFixed } from '../../icons/icons'

export const MiddleHeader = ({
  selectHeaderIndex,
  headerContent,
  setHeaderIndex,
  rightSideVisible = false,
  rightSideComponents,
}) => {
  return (
    <MiddleHeaderFrame>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {headerContent.map((content) => {
          return (
            <MiddleSectionBtn
              key={content.id}
              $select={selectHeaderIndex === content.id}
              onClick={(e) => {
                e.preventDefault()

                setHeaderIndex(content.id)
              }}
            >
              {content.title}
            </MiddleSectionBtn>
          )
        })}
      </div>
      {rightSideVisible && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {rightSideComponents}
        </div>
      )}
    </MiddleHeaderFrame>
  )
}

const MiddleHeaderFrame = styled.div`
  padding: 30px 10% 0px 10%;
  background-color: #f8f8f8;
  font-family: Circular Std Book Bold, Tahoma, SansSerif;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1024px) {
    padding: 30px 20px 0px 20px;
  }

  @media (max-width: 650px) {
    padding: 30px 10% 0px 10%;
  }
`

const MiddleSectionBtn = styled.button`
  padding-bottom: 20px;
  border-bottom: 4px solid
    ${(props) => (props.$select ? '#b49275' : 'transparent')};
  margin-right: 30px;
  color: #244556;
  text-decoration: none;
  background-color: transparent;
  box-sizing: border-box;
  cursor: pointer;
  line-height: 18px;
  font-size: 16px;
  text-rendering: optimizeLegibility;
`
