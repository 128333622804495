import React from 'react'
import ReactDOM from 'react-dom'
import styled, { keyframes } from 'styled-components'
import { usePopup } from '../../../data_system/context/popup_context/PopupContext'

const slideDown = keyframes`
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
`

const PopupWrapper = styled.div`
  position: fixed;
  top: 10px;
  left: 0;
  right: 0;
  background-color: ${(props) =>
    props.$yesOrNotState ? '#e1f3d8' : '#f05650'};
  color: ${(props) => (props.$yesOrNotState ? '#67c23a' : '#fff')};
  width: 30%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  animation: ${slideDown} 0.5s forwards;
  z-index: 1000;
  margin: auto;
  font-size: 1rem;
  border-radius: 5px;
`

const Popup = () => {
  const { showPopup, message, yesOrNotState } = usePopup()

  if (!showPopup) return null

  return ReactDOM.createPortal(
    <PopupWrapper $yesOrNotState={yesOrNotState}>{message}</PopupWrapper>,
    document.getElementById('root-popup')
  )
}

export default Popup
