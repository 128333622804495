import React, { createContext, useState, useContext } from 'react'
import {
  loginUser,
  signUp,
} from '../../../communication_system/axios_apis/axios_apis'
import axiosMiddleware from '../../../communication_system/axios_apis/axios_middleware/axios_middleware'

const AuthContext = createContext()

export const useAuth = () => {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    const storedValue = window.localStorage.getItem('isLoggedIn')
    return storedValue !== null ? JSON.parse(storedValue) : false
  })
  const [isNewUser, setIsNewUser] = useState(() => {
    const storedValue = window.localStorage.getItem('isNewUser')
    return storedValue !== null ? JSON.parse(storedValue) : true
  })

  const handleSignUp = async (email, password, name) => {
    const response = await signUp(email, password, name)
    console.log(response)
    if (parseInt(response.status) === 201) {
      isNewUserSetup()
      login(response.accessToken, response.refreshToken)

      return {
        status: response.status,
      }
    } else if (parseInt(response.status) === 400) {
      return {
        status: response.status,
      }
    }
  }

  const handleLogin = async (email, password) => {
    const response = await loginUser(email, password)
    console.log(response)
    if (parseInt(response.status) === 200) {
      login(response.accessToken, response.refreshToken)

      if (response.user.isNewUser) {
        isNewUserSetup()
      } else {
        completeInitialSetup()
      }
      return {
        status: response.status,
      }
    } else if (parseInt(response.status) === 400) {
      return {
        status: response.status,
      }
    } else if (parseInt(response.status) === 401) {
      return {
        status: response.status,
      }
    }
  }

  const login = (accessToken, refreshToken) => {
    console.log(accessToken, refreshToken)
    window.localStorage.setItem('accessToken', accessToken)
    window.localStorage.setItem('refreshToken', refreshToken)
    window.localStorage.setItem('isLoggedIn', true)

    axiosMiddleware.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${accessToken}`

    setIsLoggedIn(true)
  }

  const logout = () => {
    window.localStorage.setItem('isLoggedIn', false)
    setIsLoggedIn(false)
  }

  const completeInitialSetup = () => {
    setIsNewUser(false)
    window.localStorage.setItem('isNewUser', false)
  }

  const isNewUserSetup = () => {
    setIsNewUser(true)
    window.localStorage.setItem('isNewUser', true)
  }

  const value = {
    isLoggedIn,
    handleSignUp,
    handleLogin,
    logout,
    isNewUser,
    completeInitialSetup,
    isNewUserSetup,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
