import axios from 'axios'
import axiosMiddleware from './axios_middleware/axios_middleware'
import { doogom_com_server_router_url } from '../communication_router_url/communication_router_url'
import { generateSessionId } from '../../data_system/data_function/generate_session_id'

export const signUp = async (email, password, name) => {
  try {
    const response = await axios.post(
      `${doogom_com_server_router_url}/auth/signup`,
      {
        email,
        password,
        name,
      }
    )
    console.log(response)
    if (response.data.accessToken && response.data.refreshToken) {
      return {
        accessToken: response.data.accessToken,
        refreshToken: response.data.refreshToken,
        status: response.status,
      }
    } else {
      console.error('Error during signup: Tokens not provided')
      return null
    }
  } catch (error) {
    console.log('Error during signup:', error)
    return {
      status: error.response.status,
    }
  }
}

export const loginUser = async (email, password) => {
  try {
    const response = await axios.post(
      `${doogom_com_server_router_url}/auth/login`,
      {
        email,
        password,
      }
    )

    if (response.data.accessToken && response.data.refreshToken) {
      return {
        accessToken: response.data.accessToken,
        refreshToken: response.data.refreshToken,
        status: response.status,
        user: response.data.user,
      }
    }
  } catch (error) {
    console.log('Error during login:', error.response.status)
    return {
      status: error.response.status,
    }
  }
}

export const freePaymentFirst = async ({
  guideName,
  email,
  hostName,
  hostPhoneNumber,
  logoImageUrl,
  iconImageUrl,
  logoImageRounded,
  pcImageUrl,
  mobileImageUrl,
  address,
  additionalAddress,
  latitude,
  longitude,
  staticAddressImageUrl,
  tab1Name,
  tab2Name,
  tab3Name,
  tab4Name,
}) => {
  try {
    const response = await axiosMiddleware.post('/payment/free-payment-first', {
      guideName,
      email,
      hostName,
      hostPhoneNumber,
      logoImageUrl,
      iconImageUrl,
      logoImageRounded,
      pcImageUrl,
      mobileImageUrl,
      address,
      additionalAddress,
      latitude,
      longitude,
      staticAddressImageUrl,
      tab1Name,
      tab2Name,
      tab3Name,
      tab4Name,
    })

    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const uploadImage = async (imageFile) => {
  const formData = new FormData()
  formData.append('image', imageFile)
  console.log(imageFile)
  try {
    const response = await axiosMiddleware.post('/image/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    return response
  } catch (error) {
    console.error('Error uploading the image:', error)
    throw error
  }
}

export const deleteImage = async (imageUrl) => {
  try {
    console.log('deleteImage', imageUrl)
    const response = await axiosMiddleware.post('/image/delete', {
      imageUrl: imageUrl,
    })

    return response
  } catch (error) {
    console.error('Error uploading the image:', error)
    throw error
  }
}

export const forgotPassword = async (email) => {
  try {
    const response = await axios.post(
      `${doogom_com_server_router_url}/auth/forgot-password`,
      {
        email: email,
      }
    )

    return response
  } catch (error) {
    console.error('Error during the forgot password API call:', error)
    throw error
  }
}

export const resetPassword = async (token, newPassword) => {
  try {
    const response = await axios.post(
      `${doogom_com_server_router_url}/auth/reset-password/${token}`,
      { password: newPassword }
    )
    return response
  } catch (error) {
    console.error('Error resetting password:', error)
    throw error
  }
}

export const changePassword = async (oldPassword, newPassword) => {
  try {
    const response = await axiosMiddleware.post(
      `${doogom_com_server_router_url}/settings/change-password`,
      {
        oldPassword,
        newPassword,
      }
    )
    console.log(response)
    return response
  } catch (error) {
    console.error('Error during the forgot password API call:', error)
    throw error
  }
}

export const getAccountEmailAndName = async () => {
  try {
    const response = await axiosMiddleware.get(
      '/settings/account-email-and-name'
    )

    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const getAccountSubscriptionInformation = async () => {
  try {
    const response = await axiosMiddleware.get(
      '/account/account-subscription-information'
    )

    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const getDefaultGuide = async () => {
  try {
    const response = await axiosMiddleware.get(
      '/default-guide/get-default-guide'
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const putAccountUserName = async (name) => {
  try {
    const response = await axiosMiddleware.put(
      '/account/account-user-information',
      {
        name,
      }
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const putAccountUserBankInfo = async (
  bankTypeId,
  bankAccountNumber,
  bankAccountName
) => {
  try {
    const response = await axiosMiddleware.put(
      '/account/account-user-information',
      {
        bankTypeId,
        bankAccountNumber,
        bankAccountName,
      }
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const postHelpAndSupport = async (subject, message) => {
  try {
    const response = await axiosMiddleware.post(
      '/help-and-support/send-email',
      {
        subject,
        message,
      }
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const getPaymentTokenCheck = async () => {
  try {
    const response = await axiosMiddleware.get(
      '/payment/get-payment-token-check'
    )

    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const postAdditionalSubscriptionPayment = async (
  paymentType,
  number,
  totalPrice,
  adminProductType,
  subscriptionMonthPeriod,
  billingKey,
  payerName,
  payerHp,
  payerEmail,
  payOid,
  payTaxTotal
) => {
  try {
    const response = await axiosMiddleware.post(
      '/payment/additional-subscription-payment',
      {
        paymentType,
        number,
        totalPrice,
        adminProductType,
        subscriptionMonthPeriod,
        billingKey: billingKey,
        payerName: payerName,
        payerHp: payerHp,
        payerEmail: payerEmail,
        payOid: payOid,
        payTaxTotal: payTaxTotal,
      }
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const postChangeSubscriptionPayment = async (
  paymentType,
  number,
  totalPrice,
  adminProductType,
  subscriptionMonthPeriod,
  billingKey,
  payerName,
  payerHp,
  payerEmail,
  payOid,
  payTaxTotal
) => {
  try {
    const response = await axiosMiddleware.post(
      '/payment/change-Subscription-payment',
      {
        paymentType,
        number,
        totalPrice,
        adminProductType,
        subscriptionMonthPeriod,
        billingKey: billingKey,
        payerName: payerName,
        payerHp: payerHp,
        payerEmail: payerEmail,
        payOid: payOid,
        payTaxTotal: payTaxTotal,
      }
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const postShowGuideStorePayment = async (
  token,
  userProductId,
  paymentType,
  number,
  totalPrice,
  billingKey,
  payerName,
  payerHp,
  payerEmail,
  payOid,
  payTaxTotal
) => {
  try {
    const response = await axios.post(
      `${doogom_com_server_router_url}/show-guide/show-guide-store-payment`,
      {
        token,
        userProductId,
        paymentType,
        number,
        totalPrice,
        billingKey: billingKey,
        payerName: payerName,
        payerHp: payerHp,
        payerEmail: payerEmail,
        payOid: payOid,
        payTaxTotal: payTaxTotal,
      }
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const postDefaultCategory = async (title, iconUrl) => {
  try {
    const response = await axiosMiddleware.post(
      '/default-guide/post-default-category',
      {
        title: title,
        iconUrl: iconUrl,
      }
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const putDefaultCategory = async (
  defaultCategoryId,
  title,
  iconUrl,
  order
) => {
  try {
    const response = await axiosMiddleware.put(
      `/default-guide/put-default-category/${defaultCategoryId}`,
      {
        title: title,
        iconUrl: iconUrl,
        order: order,
      }
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const putDefaultCategoryOrder = async (defaultCategoryId, order) => {
  try {
    const response = await axiosMiddleware.put(
      `/default-guide/put-default-category/${defaultCategoryId}`,
      {
        order: order,
      }
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const deleteDefaultCategoryApi = async (defaultCategoryId) => {
  try {
    const response = await axiosMiddleware.delete(
      `/default-guide/delete-default-category/${defaultCategoryId}`
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const postDefaultSubcategory = async (title, defaultCategoryId) => {
  try {
    const response = await axiosMiddleware.post(
      '/default-guide/post-default-subcategory',
      {
        title,
        defaultCategoryId,
      }
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const putDefaultSubcategory = async (
  title,
  order,
  defaultSubcategoryId
) => {
  try {
    const response = await axiosMiddleware.put(
      `/default-guide/put-default-subcategory/${defaultSubcategoryId}`,
      {
        title: title,
        order: order,
      }
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const putDefaultSubcategoryOrder = async (
  defaultSubcategoryId,
  order
) => {
  try {
    const response = await axiosMiddleware.put(
      `/default-guide/put-default-subcategory/${defaultSubcategoryId}`,
      {
        order: order,
      }
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const deleteDefaultSubcategoryApi = async (defaultSubcategoryId) => {
  try {
    const response = await axiosMiddleware.delete(
      `/default-guide/delete-default-subcategory/${defaultSubcategoryId}`
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const postDefaultTopic = async (
  title,
  content,
  imgUrl,
  latitude,
  longitude,
  mapPinIconUrl,
  defaultSubcategoryId,
  address
) => {
  try {
    const response = await axiosMiddleware.post(
      '/default-guide/post-default-topic',
      {
        title,
        content,
        imgUrl,
        latitude,
        longitude,
        mapPinIconUrl,
        defaultSubcategoryId,
        address,
      }
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const deleteDefaultTopicApi = async (defaultTopicId) => {
  try {
    const response = await axiosMiddleware.delete(
      `/default-guide/delete-default-topic/${defaultTopicId}`
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const putDefaultTopic = async (
  title,
  content,
  imgUrl,
  latitude,
  longitude,
  mapPinIconUrl,
  order,
  defaultTopicId,
  address
) => {
  try {
    const response = await axiosMiddleware.put(
      `/default-guide/put-default-topic/${defaultTopicId}`,
      {
        title,
        content,
        imgUrl,
        latitude,
        longitude,
        mapPinIconUrl,
        order: order,
        address: address,
      }
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const putDefaultTopicOrder = async (defaultTopicId, order) => {
  try {
    const response = await axiosMiddleware.put(
      `/default-guide/put-default-topic/${defaultTopicId}`,
      {
        order: order,
      }
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const putDefaultTopicContent = async (content, defaultTopicId) => {
  try {
    const response = await axiosMiddleware.put(
      `/default-guide/put-default-topic/${defaultTopicId}`,
      {
        content,
      }
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const putDefaultTopicImgUrl = async (imgUrl, defaultTopicId) => {
  try {
    const response = await axiosMiddleware.put(
      `/default-guide/put-default-topic/${defaultTopicId}`,
      {
        imgUrl,
      }
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const getShowGuideDecodeToken = async (token) => {
  try {
    console.log('getShowGuideDecodeToken', token)
    const response = await axios.get(
      `${doogom_com_server_router_url}/show-guide/get-show-guide-decode-token/${token}`
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const getShowDefaultGuideDecodeToken = async (token) => {
  try {
    console.log('getShowGuideDecodeToken', token)
    const response = await axios.get(
      `${doogom_com_server_router_url}/show-guide/get-show-default-guide-decode-token/${token}`
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const getUserAndGuides = async () => {
  try {
    const response = await axiosMiddleware.get(
      `/guides/get-user-and-all-guides`
    )

    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const putIncrementGuideViewNumber = async (guideId) => {
  try {
    const response = await axiosMiddleware.put(
      `/guides/put-increment-guide-view-number/${guideId}`
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const getBase64OfImageFromS3 = async (imageUrl) => {
  try {
    const response = await axiosMiddleware.post(
      `/image/get-base64-of-image-from-s3`,
      {
        imageUrl: imageUrl,
      }
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const deleteGuide = async (guideId) => {
  try {
    const response = await axiosMiddleware.delete(
      `/guides/delete-guide/${guideId}`
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const postGuide = async (guideDetails) => {
  try {
    const response = await axiosMiddleware.post(`/guides/post-guide`, {
      guideName: guideDetails.guideName,
    })
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const putGuideLogoImageUrl = async (guideId, guideDetails) => {
  console.log('guideDetails', guideDetails)
  try {
    const response = await axiosMiddleware.post(
      `/guides/put-guide/${guideId}`,
      {
        logoImageUrl: guideDetails.logoImageUrl,
      }
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const putGuideIconImageUrl = async (guideId, guideDetails) => {
  console.log('guideDetails', guideDetails)
  try {
    const response = await axiosMiddleware.post(
      `/guides/put-guide/${guideId}`,
      {
        iconImageUrl: guideDetails.iconImageUrl,
      }
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const putGuideLogoImageRounded = async (guideId, guideDetails) => {
  console.log('guideDetails', guideDetails)
  try {
    const response = await axiosMiddleware.post(
      `/guides/put-guide/${guideId}`,
      {
        logoImageRounded: guideDetails.logoImageRounded,
      }
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const putGuidePcImageUrl = async (guideId, guideDetails) => {
  try {
    const response = await axiosMiddleware.post(
      `/guides/put-guide/${guideId}`,
      {
        pcImageUrl: guideDetails.pcImageUrl,
      }
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const putGuideMobileImageUrl = async (guideId, guideDetails) => {
  try {
    const response = await axiosMiddleware.post(
      `/guides/put-guide/${guideId}`,
      {
        mobileImageUrl: guideDetails.mobileImageUrl,
      }
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const putEditGuideCoverDetails = async (guideId, guideDetails) => {
  try {
    const response = await axiosMiddleware.post(
      `/guides/put-guide/${guideId}`,
      {
        guideName: guideDetails.guideName,
        hostName: guideDetails.hostName,
        email: guideDetails.email,
        hostPhoneNumber: guideDetails.hostPhoneNumber,
      }
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const putEditGuidePinLocation = async (guideId, guideDetails) => {
  try {
    const response = await axiosMiddleware.post(
      `/guides/put-guide/${guideId}`,
      {
        address: guideDetails.address,
        additionalAddress: guideDetails.additionalAddress,
        latitude: guideDetails.latitude,
        longitude: guideDetails.longitude,
      }
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const putGuideRenameTabs = async (guideId, guideDetails) => {
  try {
    const response = await axiosMiddleware.post(
      `/guides/put-guide/${guideId}`,
      {
        tab1Name: guideDetails.tab1Name,
        tab2Name: guideDetails.tab2Name,
        tab3Name: guideDetails.tab3Name,
        tab4Name: guideDetails.tab4Name,
      }
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const putDefaultGuideLogoImageUrl = async (
  defaultGuideId,
  defaultGuideDetails
) => {
  console.log('defaultGuideDetails', defaultGuideDetails)
  try {
    const response = await axiosMiddleware.post(
      `/default-guide/put-default-guide/${defaultGuideId}`,
      {
        logoImageUrl: defaultGuideDetails.logoImageUrl,
      }
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const putDefaultGuideIconImageUrl = async (
  defaultGuideId,
  defaultGuideDetails
) => {
  console.log('defaultGuideDetails', defaultGuideDetails)
  try {
    const response = await axiosMiddleware.post(
      `/default-guide/put-default-guide/${defaultGuideId}`,
      {
        iconImageUrl: defaultGuideDetails.iconImageUrl,
      }
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const putDefaultGuideLogoImageRounded = async (
  defaultGuideId,
  defaultGuideDetails
) => {
  console.log('defaultGuideDetails', defaultGuideDetails)
  try {
    const response = await axiosMiddleware.post(
      `/default-guide/put-default-guide/${defaultGuideId}`,
      {
        logoImageRounded: defaultGuideDetails.logoImageRounded,
      }
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const putDefaultGuidePcImageUrl = async (
  defaultGuideId,
  defaultGuideDetails
) => {
  try {
    const response = await axiosMiddleware.post(
      `/default-guide/put-default-guide/${defaultGuideId}`,
      {
        pcImageUrl: defaultGuideDetails.pcImageUrl,
      }
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const putDefaultGuideMobileImageUrl = async (
  defaultGuideId,
  defaultGuideDetails
) => {
  try {
    console.log('defaultGuideDetails', defaultGuideDetails)
    const response = await axiosMiddleware.post(
      `/default-guide/put-default-guide/${defaultGuideId}`,
      {
        mobileImageUrl: defaultGuideDetails.mobileImageUrl,
      }
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const putEditDefaultGuideCoverDetails = async (
  defaultGuideId,
  defaultGuideDetails
) => {
  try {
    const response = await axiosMiddleware.post(
      `/default-guide/put-default-guide/${defaultGuideId}`,
      {
        guideName: defaultGuideDetails.guideName,
        hostName: defaultGuideDetails.hostName,
        email: defaultGuideDetails.email,
        hostPhoneNumber: defaultGuideDetails.hostPhoneNumber,
      }
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const putEditDefaultGuidePinLocation = async (
  defaultGuideId,
  defaultGuideDetails
) => {
  try {
    const response = await axiosMiddleware.post(
      `/default-guide/put-default-guide/${defaultGuideId}`,
      {
        address: defaultGuideDetails.address,
        additionalAddress: defaultGuideDetails.additionalAddress,
        latitude: defaultGuideDetails.latitude,
        longitude: defaultGuideDetails.longitude,
      }
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const putDefaultGuideRenameTabs = async (
  defaultGuideId,
  defaultGuideDetails
) => {
  try {
    const response = await axiosMiddleware.post(
      `/default-guide/put-default-guide/${defaultGuideId}`,
      {
        tab1Name: defaultGuideDetails.tab1Name,
        tab2Name: defaultGuideDetails.tab2Name,
        tab3Name: defaultGuideDetails.tab3Name,
        tab4Name: defaultGuideDetails.tab4Name,
      }
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const postTopicBlocklist = async (guideId, topicId) => {
  try {
    const response = await axiosMiddleware.post(
      `/guides/post-topic-blocklist`,
      {
        guideId,
        topicId,
      }
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const deleteTopicBlocklist = async (topicId) => {
  try {
    const response = await axiosMiddleware.delete(
      `/guides/delete-topic-blocklist/${topicId}`
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const getGuideViewNumber = async (guideId, queryIndex) => {
  try {
    const response = await axiosMiddleware.post(
      `/guides/get-guide-view-number`,
      {
        guideId,
        queryIndex,
      }
    )

    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const postInvitation = async (invitationDetails) => {
  try {
    const response = await axiosMiddleware.post(`/guides/post-invitation`, {
      invitationName: invitationDetails.invitationName,
      guestName: invitationDetails.guestName,
      guestPhoneNumber: invitationDetails.guestPhoneNumber,
      guestEmail: invitationDetails.guestEmail,
      emailTitle: invitationDetails.emailTitle,
      emailContent: invitationDetails.emailContent,
      guideId: invitationDetails.guideId,
    })
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const deleteAllInvitations = async (guideId) => {
  try {
    const response = await axiosMiddleware.delete(
      `/guides/delete-all-invitations/${guideId}`
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const deleteInvitation = async (invitationId) => {
  try {
    const response = await axiosMiddleware.delete(
      `/guides/delete-invitation/${invitationId}`
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const putInvitation = async (invitationId, invitationDetails) => {
  try {
    const response = await axiosMiddleware.post(
      `/guides/put-invitation/${invitationId}`,
      {
        invitationName: invitationDetails.invitationName,
        guestName: invitationDetails.guestName,
        guestPhoneNumber: invitationDetails.guestPhoneNumber,
        guestEmail: invitationDetails.guestEmail,
        emailTitle: invitationDetails.emailTitle,
        emailContent: invitationDetails.emailContent,
      }
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const invitationSendNow = async (guideId, invitationId) => {
  try {
    const response = await axiosMiddleware.post(`/guides/invitation-send-now`, {
      guideId,
      invitationId,
    })
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const getStore = async () => {
  try {
    const response = await axiosMiddleware.get('/store/get-store')
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const postUserProduct = async (userProductDetails) => {
  try {
    const response = await axiosMiddleware.post(`/store/post-user-product`, {
      title: userProductDetails.title,
      content: userProductDetails.content,
      price: userProductDetails.price,
      imgUrl: userProductDetails.imgUrl,
    })
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const putUserProduct = async (userProductId, userProductDetails) => {
  try {
    const response = await axiosMiddleware.post(
      `/store/put-user-product/${userProductId}`,
      {
        title: userProductDetails.title,
        content: userProductDetails.content,
        price: userProductDetails.price,
        imgUrl: userProductDetails.imgUrl,
      }
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const deleteUserProductByUserProductId = async (userProductId) => {
  try {
    const response = await axiosMiddleware.delete(
      `/store/delete-user-product-by-user-product-id/${userProductId}`
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const putCategoryOrder = async (categoryId, order) => {
  try {
    const response = await axiosMiddleware.put(
      `/guides/put-category/${categoryId}`,
      {
        order: order,
      }
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const putSubcategoryOrder = async (subcategoryId, order) => {
  try {
    const response = await axiosMiddleware.put(
      `/guides/put-subcategory/${subcategoryId}`,
      {
        order: order,
      }
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const putTopicOrder = async (topicId, order) => {
  try {
    const response = await axiosMiddleware.put(`/guides/put-topic/${topicId}`, {
      order: order,
    })
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const postCategory = async (title, iconUrl, guideId) => {
  try {
    const response = await axiosMiddleware.post('/guides/post-category', {
      title: title,
      iconUrl: iconUrl,
      guideId: guideId,
    })
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const putCategory = async (categoryId, title, iconUrl, order) => {
  try {
    const response = await axiosMiddleware.put(
      `/guides/put-category/${categoryId}`,
      {
        title: title,
        iconUrl: iconUrl,
        order: order,
      }
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const deleteCategoryApi = async (categoryId) => {
  try {
    const response = await axiosMiddleware.delete(
      `/guides/delete-category/${categoryId}`
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const postSubcategory = async (title, categoryId) => {
  try {
    const response = await axiosMiddleware.post('/guides/post-subcategory', {
      title,
      categoryId,
    })
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const putSubcategory = async (title, order, subcategoryId) => {
  try {
    const response = await axiosMiddleware.put(
      `/guides/put-subcategory/${subcategoryId}`,
      {
        title: title,
        order: order,
      }
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const deleteSubcategoryApi = async (subcategoryId) => {
  try {
    const response = await axiosMiddleware.delete(
      `/guides/delete-subcategory/${subcategoryId}`
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const postTopic = async (
  title,
  content,
  imgUrl,
  latitude,
  longitude,
  mapPinIconUrl,
  subcategoryId,
  address
) => {
  try {
    const response = await axiosMiddleware.post('/guides/post-topic', {
      title,
      content,
      imgUrl,
      latitude,
      longitude,
      mapPinIconUrl,
      subcategoryId,
      address,
    })
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const deleteTopicApi = async (topicId) => {
  try {
    const response = await axiosMiddleware.delete(
      `/guides/delete-topic/${topicId}`
    )
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const putTopic = async (
  title,
  content,
  imgUrl,
  latitude,
  longitude,
  mapPinIconUrl,
  order,
  topicId,
  address
) => {
  try {
    const response = await axiosMiddleware.put(`/guides/put-topic/${topicId}`, {
      title,
      content,
      imgUrl,
      latitude,
      longitude,
      mapPinIconUrl,
      order: order,
      address: address,
    })
    console.log(response)
    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const getSubscribePaymentsWithUserByUserId = async () => {
  try {
    const response = await axiosMiddleware.get(
      '/payment/get-subscribe-payments-with-user-by-user-id'
    )

    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const getStorePaymentHistories = async () => {
  try {
    const response = await axiosMiddleware.get(
      `/store/get-store-payment-histories`
    )

    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const getStorePaymentHistoriesByUserIdForExcel = async (year, month) => {
  const response = await axiosMiddleware.post(
    `/store/get-store-payment-histories-for-excel`,
    {
      year: year,
      month: month,
    }
  )

  return response
}

export const postFindOrCreateVisitorSessionOrUpdate = async () => {
  const sessionId = generateSessionId()
  try {
    const response = await axios.post(
      `${doogom_com_server_router_url}/visitor-session/post-find-or-create-visitor-session-or-update`,
      { sessionId }, // 세션 ID를 데이터로 전송
      {
        headers: {
          'Content-Type': 'application/json', // JSON 형식으로 데이터 전송
        },
      }
    )

    console.log(response.data)
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export const postInternationalPaymentAuth = async () => {
  try {
    const response = await axios.post(
      `${doogom_com_server_router_url}/international-payment-auth/post-international-payment-auth`
    )

    return response
  } catch (error) {
    console.error(
      'API Error:',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}
